import { Key } from 'react'
import { ColumnsVisibilityState } from './ColumnsVisibility.state'
import {
  SET_VISIBLE_COLUMNS,
  SetVisibleColumnsAction,
  SetLockersAction,
  SET_LOCKERS,
  AddLayoutAction,
  ADD_LAYOUT,
} from './ColumnsVisibility.actions'
import { ColumnsLayout } from '../../model/ColumnLayouts'
import {
  DefaultVisibleBoQResourcesColumns,
  DefaultVisibleBoQTakeoffsColumns,
} from '../../components/domain/bill-of-quantities/BillOfQuantities.columns'
import { BillOfQuantitiesDefaultLayouts } from '../../components/domain/bill-of-quantities/BillOfQuantitiesDefaultLayouts'
import { WorkPackagesDefaultLayouts } from '../../components/domain/work-packages/WorkPackagesDefaultLayouts'

const initialState: ColumnsVisibilityState = {
  visibleColumnsKeys: new Map<string, Key[]>(),
  lockerStateKeys: new Map<string, Key[]>(),
  layouts: new Map<string, ColumnsLayout[]>(),
  builtinLayouts: new Map<string, ColumnsLayout[]>(),
}
initialState.visibleColumnsKeys.set('boq-resources', DefaultVisibleBoQResourcesColumns)
initialState.visibleColumnsKeys.set('boq-take-off', DefaultVisibleBoQTakeoffsColumns)

initialState.builtinLayouts.set('billOfQuantities', BillOfQuantitiesDefaultLayouts)
initialState.builtinLayouts.set('workPackages', WorkPackagesDefaultLayouts)

type ColumnsVisibilityAction = SetVisibleColumnsAction | SetLockersAction | AddLayoutAction

export function columnsVisibilityReducer(
  state: ColumnsVisibilityState = initialState,
  action: ColumnsVisibilityAction
): ColumnsVisibilityState {
  switch (action.type) {
    case SET_VISIBLE_COLUMNS:
      const actionObjectCol = action as SetVisibleColumnsAction
      const mapToUpdateCol = new Map(state.visibleColumnsKeys)
      mapToUpdateCol.set(actionObjectCol.tableKey, actionObjectCol.visibleColumnsKeys)
      return Object.assign({}, state, {
        visibleColumnsKeys: mapToUpdateCol,
      })
    case SET_LOCKERS:
      const actionObjectLock = action as SetLockersAction
      const mapToUpdateLock = new Map(state.lockerStateKeys)
      mapToUpdateLock.set(actionObjectLock.tableKey, actionObjectLock.lockerStateKeys)
      return Object.assign({}, state, {
        lockerStateKeys: mapToUpdateLock,
      })
    case ADD_LAYOUT:
      const actionObjectLayout = action as AddLayoutAction
      const newLayout = (action as AddLayoutAction).layout
      const mapToUpdateLayout = new Map(state.layouts)
      const existingLayouts = state.layouts.get(actionObjectLayout.id)
      mapToUpdateLayout.set(actionObjectLayout.id, (existingLayouts || []).concat(newLayout))
      return Object.assign({}, state, {
        layouts: mapToUpdateLayout,
      })
    default:
      return state
  }
}
