import React, { Key } from 'react'
import { Button } from 'antd'
import './TableSelectionToolbar.scss'

export interface TableSelectionToolbarProps {
  selectedItemsNumber: number
  selectedItems: Key[]
  children?: React.ReactNode | any[]
}

export default function TableSelectionToolbar({ selectedItemsNumber, children }: TableSelectionToolbarProps) {
  if (selectedItemsNumber > 0) {
    return (
      <div style={{ display: 'flex', padding: '8px' }} className={'table-selection-toolbar-container'}>
        <div style={{ flexGrow: 1 }}>
          <Button type="text" style={{ color: 'white' }}>
            {selectedItemsNumber} items selected
          </Button>
        </div>
        <div>{children} </div>
      </div>
    )
  } else {
    return <></>
  }
}
