import { blue, gold, lime, magenta, orange, purple } from '@ant-design/colors'

export interface Project {
  key?: number | string
  id: string
  name: string
  type: string
  division: string
  region: string
  status: ProjectStatus
  value: string
  client: string
  manager: string
  start: string
  end: string
  lat?: string
  lng?: string
}

export type ProjectType = 'Office' | 'Road' | 'Residential' | 'Bridge' | 'Rail' | 'Tunnel' | string // TODO: Temporary Workaround
export const ProjectTypes: ProjectType[] = ['Office', 'Road', 'Residential', 'Bridge', 'Rail', 'Tunnel'] // TODO: Temporary Workaround
export const ProjectTypeToColor = (text: ProjectType) => {
  switch (text) {
    case 'Office':
      return purple.primary
    case 'Residential':
      return orange.primary
    case 'Road':
      return magenta.primary
    case 'Rail':
      return blue.primary
    case 'Bridge':
      return gold.primary
    default:
      return lime.primary
  }
}

export type ProjectStatus = 'Tender' | 'Pre-con' | 'Construction' | 'Archive' | string
export const ProjectStatuses: ProjectStatus[] = ['Tender', 'Pre-con', 'Construction', 'Archive']
export const statusToTagColor = (text: ProjectStatus) => {
  switch (text) {
    case 'Tender':
      return 'geekblue'
    case 'Pre-con':
      return 'gold'
    case 'Construction':
      return 'green'
    default:
      return 'orange'
  }
}
