import React, { FunctionComponent } from 'react'
import Button from 'antd/lib/button'

interface ReplacementPaneProps {
  style?: React.CSSProperties
  onHide: () => void
  onWindowShow: () => void
}

const container: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}

const ReplacementPane: FunctionComponent<ReplacementPaneProps> = ({ style, onHide, onWindowShow }) => {
  return (
    <div style={{ ...style, ...container }}>
      <h3>This view is shown in a floating window</h3>
      <Button type={'link'} onClick={onWindowShow}>
        Show floating window
      </Button>
      <Button type={'link'} onClick={onHide}>
        Dock it here
      </Button>
    </div>
  )
}

export default ReplacementPane
