import React, { FunctionComponent } from 'react'
import { Button, Divider, Dropdown, Menu, Tooltip } from 'antd'
import {
  ImportOutlined,
  ExportOutlined,
  ColumnHeightOutlined,
  SyncOutlined,
  PlusSquareOutlined,
  MoreOutlined,
  ArrowsAltOutlined,
} from '@ant-design/icons/lib'
import TableBreakdownManager from '../../../basic/table/managers/breakdown/TableBreakdownManager'
import { emptyBoQEntry } from '../../../../data/BillOfQuantities.data'
import { Entry } from '../../../basic/table/sterling-table/SterlingTable'
import { ColumnType } from 'antd/lib/table/interface'
import ColumnGroupingManager from '../../../basic/table/managers/column-grouping/ColumnGroupingManager'
import { useDispatch, useSelector } from 'react-redux'
import { setLayouts } from '../../../../context/layouts/Layouts.actions'
import {
  LayoutBottomCustom,
  LayoutRightCustom,
  SpellCheckCustom,
  TextWrappingCustom,
} from '../../../../assets/icons/icons'
import { BoQColumns } from '../BillOfQuantities.columns'
import {
  SterlingTableHeader,
  SterlingTableHeaderProps,
} from '../../../basic/table/sterling-table/header/SterlingTableHeader'
import { RootState } from '../../../../context/reducer'
import NewWindow from '../../../basic/new-window/NewWindow'
import { setOpenWindowState } from '../../../../context/open-windows/OpenWindows.actions'
import { CSVLink } from 'react-csv'
import { billOfQuantitiesReport } from '../BillOfQuantities.xlsx'

interface BillOfQuantitiesHeaderProps {
  data: Entry[]
  columns: ColumnType<any>[]
  tableId: string
  sterlingHeaderProps: SterlingTableHeaderProps
  noLayoutManagers?: boolean
  openWindow?: () => void
  dataToDownload?: any
  headersToDownload?: any
  filename?: string
}

function getGroupingRules() {
  const BoQGroupingRules = {} as any
  BoQColumns.filter((b) => b.groupingRule).forEach((gr) => (gr.key ? (BoQGroupingRules[gr.key] = gr.groupingRule) : {}))
  return BoQGroupingRules
}

const BillOfQuantitiesHeader: FunctionComponent<BillOfQuantitiesHeaderProps> = ({
  data,
  columns,
  tableId,
  sterlingHeaderProps,
  noLayoutManagers,
  openWindow,
  dataToDownload,
  headersToDownload,
  filename,
}) => {
  const { bottomComponentOpened, rightSideComponentOpened } = useSelector((state: RootState) =>
    state.layoutsReducer.layouts.get('cost-plan')
  )
  const dispatch = useDispatch()
  const isOpen = useSelector((state: RootState) => state.openWindows.windows.get('boqResourcesNew'))

  return (
    <SterlingTableHeader
      {...sterlingHeaderProps}
      columnsLayoutManagerVisibility={true}
      rightSideChildren={
        <>
          {!noLayoutManagers && (
            <Tooltip title="Show Details">
              <Button
                type="text"
                shape="circle"
                icon={<LayoutBottomCustom />}
                onClick={() => {
                  dispatch(
                    setLayouts('cost-plan', { rightSideComponentOpened, bottomComponentOpened: !bottomComponentOpened })
                  )
                }}
              />
            </Tooltip>
          )}
          {!noLayoutManagers && (
            <Tooltip title="Show Viewer">
              <Button
                type="text"
                shape="circle"
                icon={<LayoutRightCustom />}
                onClick={() => {
                  dispatch(
                    setLayouts('cost-plan', {
                      rightSideComponentOpened: !rightSideComponentOpened,
                      bottomComponentOpened,
                    })
                  )
                }}
              />
            </Tooltip>
          )}
          {openWindow !== undefined && (
            <Button type={'text'} shape="circle" onClick={openWindow} icon={<ArrowsAltOutlined />} />
          )}
        </>
      }
    >
      <Tooltip title="Expand rows">
        <Button type="text" shape="circle" icon={<ColumnHeightOutlined />} />
      </Tooltip>
      <TableBreakdownManager data={data} columns={columns} tableId={tableId} groupingRules={getGroupingRules()} />
      <ColumnGroupingManager
        columns={columns}
        tableId={tableId}
        emptyEntryGenerator={emptyBoQEntry}
        groupingRules={getGroupingRules()}
      />
      <Tooltip title="Refresh">
        <Button type="text" shape="circle" icon={<SyncOutlined />} />
      </Tooltip>
      <Divider type={'vertical'} />
      <Tooltip title="Import">
        <Button type="text" shape="circle" icon={<ImportOutlined />} />
      </Tooltip>
      <Tooltip title="Export">
        <CSVLink headers={headersToDownload} data={dataToDownload} filename={`${filename}.csv`} separator={';'}>
          <Button type="text" shape="circle" icon={<ExportOutlined />} />
        </CSVLink>
      </Tooltip>
      <Divider type={'vertical'} />
      <Tooltip title="Wrap text">
        <Button type="text" shape="circle" icon={<TextWrappingCustom />} />
      </Tooltip>
      <Tooltip title="Check spelling">
        <Button type="text" shape="circle" icon={<SpellCheckCustom />} />
      </Tooltip>
      <Tooltip title="Add row">
        <Button type="text" shape="circle" icon={<PlusSquareOutlined />} />
      </Tooltip>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key={0} onClick={() => dispatch(setOpenWindowState('boqResourcesNew', true))}>
              Resource Overview
            </Menu.Item>
            <Menu.Item key={1}>
              <Button
                type={'text'}
                style={{ textAlign: 'left' }}
                download={'BoQReport.xlsx'}
                href={billOfQuantitiesReport}
              >
                Generate Report
              </Button>
            </Menu.Item>
          </Menu>
        }
      >
        <Tooltip title="More">
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Tooltip>
      </Dropdown>
      <NewWindow
        height={800}
        width={800}
        isOpen={isOpen || false}
        onPortalClose={() => dispatch(setOpenWindowState('boqResourcesNew', false))}
        replacementPane={<></>}
      >
        {isOpen ? (
          <iframe
            width="100%"
            height="100%"
            src="https://app.powerbi.com/view?r=eyJrIjoiZmE2NmJiZjQtM2UzMC00MjI4LWExNGItODhiYzk4ZjlhNWFjIiwidCI6IjE4OWExNmU1LTlkM2YtNDEwNC1iZTAyLTQ4NDYxMmQ0ZjY0ZiJ9"
            frameBorder="0"
            allowFullScreen={true}
          />
        ) : (
          <> </>
        )}
      </NewWindow>
    </SterlingTableHeader>
  )
}
export default BillOfQuantitiesHeader
