import { DocumentationDetailsEntry } from '../model/Documentation'

export const status = ['For Information', ' For Comment', ' For Take-off']
export const ribaStage = [
  '0 - Strategic Definition',
  ' 1 - Preparation of Brief',
  ' 2 - Concept Design',
  ' 3 - Spatial Coordination',
  ' 4 - Technical Design',
  ' 5 - Manufacturing and Construction',
  ' 6 - Handover',
  ' 7 - Use',
]
export const discipline = [
  'A - Architecture',
  ' C - Civil Engineering',
  ' S - Structure',
  ' M - Mechanical',
  ' E - Electrical',
  ' P - Plumbing',
  ' T - Temporary Works',
]
export const designPackage = [
  'A - Buildings',
  ' A - Urban Realm',
  ' C - Earth Works',
  ' C - Tunnels',
  ' C - Highways',
  ' S - Concrete',
  ' S - Bridge',
  ' M - Ventilation',
  ' M - Heating/Cooling',
  ' E - Lighting',
  ' E - Teletechnical',
  ' P - Drainage',
  ' P - Grey Water',
  ' T - Geoengineering',
  ' T - Site set-up',
]

export const DocumentationDetails: DocumentationDetailsEntry[] = [
  {
    id: 2,
    document_name: 'M25_ARC_ZZ_ZZ_CM_C_200000',
    internal_doc_reference_code: 'ABC-132-000001',
    document_title: 'M25 Coordination model',
    file_type: '.ifc',
    file_size: 4,
    file_name: 'M25_ARC_ZZ_ZZ_CM_C_200000_M25 Coordination model_.ifc',
    folder: '3D models',
    note: 'Sample',
    revision: '03',
    status: 'For Take-off',
    location: 'General',
    riba_stage: '4 - Technical Design',
    discipline: 'C - Civil Engineering',
    design_package: 'C - Highways',
    author: 'John Brown',
    organisation: 'ArcDesign',
    project: 'M25',
    originator: 'ARC',
    volume___system: 'ZZ',
    level___location: 'ZZ',
    document_type: 'CM',
    role: 'C',
    number: 200000,
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },
  {
    id: 3,
    document_name: 'M25_ARC_PT_ZZ_CM_C_200001',
    internal_doc_reference_code: 'ABC-132-000002',
    document_title: 'Temp works model',
    file_type: '.ifc',
    file_size: 2,
    file_name: 'M25_ARC_PT_ZZ_CM_C_200001_Temp works model_.ifc',
    folder: '3D models',
    note: 'Sample',
    revision: '02',
    status: 'For Take-off',
    location: 'General',
    riba_stage: '4 - Technical Design',
    discipline: 'C - Civil Engineering',
    design_package: 'C - Highways',
    author: 'John Brown',
    organisation: 'ArcDesign',
    project: 'M25',
    originator: 'ARC',
    volume___system: 'PT',
    level___location: 'ZZ',
    document_type: 'CM',
    role: 'C',
    number: 200001,
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },
  {
    id: 4,
    document_name: 'M25_ARC_PT_ZZ_CM_C_200002',
    internal_doc_reference_code: 'ABC-132-000003',
    document_title: 'Highways coordination model',
    file_type: '.ifc',
    file_size: 3,
    file_name: 'M25_ARC_PT_ZZ_CM_C_200002_Highways coordination model_.ifc',
    folder: '3D models',
    note: 'Sample',
    revision: '03',
    status: 'For Take-off',
    location: 'General',
    riba_stage: '4 - Technical Design',
    discipline: 'C - Civil Engineering',
    design_package: 'C - Highways',
    author: 'John Brown',
    organisation: 'ArcDesign',
    project: 'M25',
    originator: 'ARC',
    volume___system: 'PT',
    level___location: 'ZZ',
    document_type: 'CM',
    role: 'C',
    number: 200002,
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },
  {
    id: 5,
    document_name: 'M25_ARC_DR_R1_M3_C_200003',
    internal_doc_reference_code: 'ABC-132-000004',
    document_title: '0+045.98 do 1+313.98 main highway',
    file_type: '.dwg',
    file_size: 12,
    file_name: 'M25_ARC_DR_R1_M3_C_200003_0+045.98 do 1+313.98 main highway_.dwg',
    folder: '2D Drawings',
    note: 'Sample',
    revision: '03',
    status: 'For Take-off',
    location: 'Road section 1',
    riba_stage: '4 - Technical Design',
    discipline: 'C - Civil Engineering',
    design_package: 'C - Highways',
    author: 'John Brown',
    organisation: 'ArcDesign',
    project: 'M25',
    originator: 'ARC',
    volume___system: 'DR',
    level___location: 'R1',
    document_type: 'M3',
    role: 'C',
    number: 200003,
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },
  {
    id: 6,
    document_name: 'M25_ARC_DR_R2_M3_C_200004',
    internal_doc_reference_code: 'ABC-132-000005',
    document_title: '1+593.00 do 1+991.64 main highway',
    file_type: '.dwg',
    file_size: 8,
    file_name: 'M25_ARC_DR_R2_M3_C_200004_1+593.00 do 1+991.64 main highway_.dwg',
    folder: '2D Drawings',
    note: 'Sample',
    revision: '03',
    status: 'For Take-off',
    location: 'Road section 2',
    riba_stage: '4 - Technical Design',
    discipline: 'C - Civil Engineering',
    design_package: 'C - Highways',
    author: 'John Brown',
    organisation: 'ArcDesign',
    project: 'M25',
    originator: 'ARC',
    volume___system: 'DR',
    level___location: 'R2',
    document_type: 'M3',
    role: 'C',
    number: 200004,
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },
  {
    id: 7,
    document_name: 'M25_ARC_DR_R3_M3_C_200005',
    internal_doc_reference_code: 'ABC-132-000006',
    document_title: '1+313.98 do 1+593.00 roundabout',
    file_type: '.dwg',
    file_size: 5,
    file_name: 'M25_ARC_DR_R3_M3_C_200005_1+313.98 do 1+593.00 roundabout_.dwg',
    folder: '2D Drawings',
    note: 'Sample',
    revision: '03',
    status: 'For Take-off',
    location: 'Road section 3',
    riba_stage: '4 - Technical Design',
    discipline: 'C - Civil Engineering',
    design_package: 'C - Highways',
    author: 'John Brown',
    organisation: 'ArcDesign',
    project: 'M25',
    originator: 'ARC',
    volume___system: 'DR',
    level___location: 'R3',
    document_type: 'M3',
    role: 'C',
    number: 200005,
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },
  {
    id: 8,
    document_name: 'M25_ARC_OI_V1_M3_S_200015',
    internal_doc_reference_code: 'ABC-132-000007',
    document_title: 'Viaduct no 28',
    file_type: '.rvt',
    file_size: 19,
    file_name: 'M25_ARC_OI_V1_M3_S_200015_Viaduct no 28_.rvt',
    folder: '3D models',
    note: 'Sample',
    revision: '04',
    status: 'For Take-off',
    location: 'Viaduct 1',
    riba_stage: '4 - Technical Design',
    discipline: 'S - Structure',
    design_package: 'S - Bridge',
    author: 'John Brown',
    organisation: 'ArcDesign',
    project: 'M25',
    originator: 'ARC',
    volume___system: 'OI',
    level___location: 'V1',
    document_type: 'M3',
    role: 'S',
    number: 200015,
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },
  {
    id: 9,
    document_name: 'M25_ARC_DR_R3_DR_C_100005',
    internal_doc_reference_code: 'ABC-132-000008',
    document_title: '0+045.98 do 1+313.98 main highway sections',
    file_type: '.pdf',
    file_size: 12,
    file_name: 'M25_ARC_DR_R3_DR_C_100005_0+045.98 do 1+313.98 main highway sections_.pdf',
    folder: '2D Drawings',
    note: 'Sample',
    revision: '03',
    status: 'For Take-off',
    location: '',
    riba_stage: '4 - Technical Design',
    discipline: 'C - Civil Engineering',
    design_package: 'C - Highways',
    author: 'John Brown',
    organisation: 'ArcDesign',
    project: 'M25',
    originator: 'ARC',
    volume___system: 'DR',
    level___location: 'R3',
    document_type: 'DR',
    role: 'C',
    number: 100005,
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },
  {
    id: 10,
    document_name: 'M25_ARC_OI_V1_DR_S_100011',
    internal_doc_reference_code: 'ABC-132-000009',
    document_title: 'Viaduct no 28 foundations layout',
    file_type: '.pdf',
    file_size: 19,
    file_name: 'M25_ARC_OI_V1_DR_S_100011_Viaduct no 28 foundations layout_.pdf',
    folder: '2D Drawings',
    note: 'Sample',
    revision: '04',
    status: 'For Take-off',
    location: '',
    riba_stage: '4 - Technical Design',
    discipline: 'S - Structure',
    design_package: 'S - Bridge',
    author: 'John Brown',
    organisation: 'ArcDesign',
    project: 'M25',
    originator: 'ARC',
    volume___system: 'OI',
    level___location: 'V1',
    document_type: 'DR',
    role: 'S',
    number: 100011,
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },
  {
    id: 11,
    document_name: 'M25_ARC_DR_R3_PP_D_100109',
    internal_doc_reference_code: 'ABC-132-000010',
    document_title: 'Schedule of signage',
    file_type: '.pdf',
    file_size: 12,
    file_name: 'M25_ARC_DR_R3_PP_D_100109_Schedule of signage_.pdf',
    folder: 'Documentation',
    note: 'Sample',
    revision: '03',
    status: 'For Take-off',
    location: '',
    riba_stage: '4 - Technical Design',
    discipline: 'C - Civil Engineering',
    design_package: 'C - Highways',
    author: 'John Brown',
    organisation: 'ArcDesign',
    project: 'M25',
    originator: 'ARC',
    volume___system: 'DR',
    level___location: 'R3',
    document_type: 'PP',
    role: 'D',
    number: 100109,
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },
  {
    id: 12,
    document_name: 'M25_ARC_DR_V1_PP_D_100110',
    internal_doc_reference_code: 'ABC-132-000011',
    document_title: 'Road specification',
    file_type: '.pdf',
    file_size: 19,
    file_name: 'M25_ARC_DR_V1_PP_D_100110_Road specification_.pdf',
    folder: 'Documentation',
    note: 'Sample',
    revision: '04',
    status: 'For Take-off',
    location: '',
    riba_stage: '4 - Technical Design',
    discipline: 'S - Structure',
    design_package: 'S - Bridge',
    author: 'John Brown',
    organisation: 'ArcDesign',
    project: 'M25',
    originator: 'ARC',
    volume___system: 'DR',
    level___location: 'V1',
    document_type: 'PP',
    role: 'D',
    number: 100110,
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },
]
