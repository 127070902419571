import { Project } from '../model/Projects'

export const portfolioData: Project[] = [
  {
    key: 1,
    id: 'OF123456',
    name: 'Trafalgar Sq',
    type: 'Office',
    division: 'Buildings',
    region: 'London',
    status: 'Tender',
    value: '£ 125,400,000.00',
    client: 'X_Fund',
    manager: 'Adam Smith',
    start: '2020-12-01',
    end: '2024-10-01',
    lat: "51'30'14",
    lng: "0'07'43",
  },
  {
    key: 2,
    id: 'RS123457',
    name: 'Oak Street',
    type: 'Residential',
    division: 'Buildings',
    region: 'London',
    status: 'Pre-con',
    value: '£ 22,500,000.00',
    client: 'R_Fund',
    manager: 'Adam Smith',
    start: '2020-06-01',
    end: '2021-12-01',
    lat: "51'31'41",
    lng: "0'10'15",
  },
  {
    key: 3,
    id: 'RS123458',
    name: 'Pine Street',
    type: 'Residential',
    division: 'Buildings',
    region: 'London',
    status: 'Construction',
    value: '£ 78,300,000.00',
    client: 'R_Fund',
    manager: 'Adam Smith',
    start: '2019-05-01',
    end: '2024-02-01',
    lat: "51'31'30",
    lng: "0'06'36",
  },
  {
    key: 4,
    id: 'OF123459',
    name: 'Piccadilly',
    type: 'Office',
    division: 'Buildings',
    region: 'London',
    status: 'Pre-con',
    value: '£ 94,500,000.00',
    client: 'X_Fund',
    manager: 'Adam Smith',
    start: '2020-09-01',
    end: '2023-09-01',
    lat: "51'32'33",
    lng: "0'07'23",
  },
  {
    key: 5,
    id: 'OF123460',
    name: 'Hart Foundation',
    type: 'Office',
    division: 'Buildings',
    region: 'London',
    status: 'Tender',
    value: '£ 41,000,000.00',
    client: 'X_Fund',
    manager: 'Adam Smith',
    start: '2020-11-01',
    end: '2022-10-01',
    lat: "51'27'46",
    lng: "0'00'37",
  },
  {
    key: 6,
    id: 'RD123461',
    name: 'M31 M5 Junction',
    type: 'Road',
    division: 'Infrastructure',
    region: 'South West',
    status: 'Construction',
    value: '£ 182,000,000.00',
    client: 'HE',
    manager: 'Ben Flin',
    start: '2019-01-01',
    end: '2023-09-01',
    lat: "51'33'00",
    lng: "2'33'06",
  },
  {
    key: 7,
    id: 'RD123462',
    name: 'A6 Extention',
    type: 'Road',
    division: 'Infrastructure',
    region: 'North West',
    status: 'Construction',
    value: '£ 34,800,000.00',
    client: 'HE',
    manager: 'Ben Flin',
    start: '2019-07-01',
    end: '2021-10-01',
    lat: "53'18'40",
    lng: "1'53'10",
  },
  {
    key: 8,
    id: 'RD123463',
    name: 'M25 Upgrade',
    type: 'Road',
    division: 'Infrastructure',
    region: 'London',
    status: 'Pre-con',
    value: '£ 76,800,000.00',
    client: 'HE',
    manager: 'Ben Flin',
    start: '2021-04-01',
    end: '2024-03-01',
    lat: "51'41'07",
    lng: "0'10'10",
  },
  {
    key: 9,
    id: 'RD123464',
    name: 'M69 Bramcote Bridge',
    type: 'Road',
    division: 'Infrastructure',
    region: 'West Midlands',
    status: 'Pre-con',
    value: '£ 18,600,000.00',
    client: 'HE',
    manager: 'Ben Flin',
    start: '2020-09-01',
    end: '2022-05-01',
    lat: "52'29'21",
    lng: "1'23'17",
  },
  {
    key: 10,
    id: 'RL123465',
    name: 'Wessex Junction',
    type: 'Rail',
    division: 'Infrastructure',
    region: 'South East',
    status: 'Tender',
    value: '£ 346,400,000.00',
    client: 'NR',
    manager: 'Jack Brown',
    start: '2021-09-01',
    end: '2025-12-01',
    lat: "51'16'09",
    lng: "2'11'33",
  },
  {
    key: 11,
    id: 'RL123466',
    name: 'Northern Hub',
    type: 'Rail',
    division: 'Infrastructure',
    region: 'East Midlands',
    status: 'Construction',
    value: '£ 234,000,000.00',
    client: 'NR',
    manager: 'Jack Brown',
    start: '2019-08-01',
    end: '2024-01-01',
    lat: "53'29'12",
    lng: "2'14'29",
  },
  {
    key: 12,
    id: 'RL123467',
    name: 'HS2 Crew Interchange',
    type: 'Rail',
    division: 'Infrastructure',
    region: 'North West',
    status: 'Tender',
    value: '£ 436,500,000.00',
    client: 'HS2',
    manager: 'Jack Brown',
    start: '2021-10-01',
    end: '2026-04-01',
    lat: "53'05'16",
    lng: "2'25'53",
  },
  {
    key: 13,
    id: 'RL123468',
    name: 'Camden Upgrade',
    type: 'Rail',
    division: 'Infrastructure',
    region: 'London',
    status: 'Pre-con',
    value: '£ 15,400,000.00',
    client: 'NR',
    manager: 'Jack Brown',
    start: '2020-09-01',
    end: '2021-03-01',
    lat: "51'32'32",
    lng: "0'08'59",
  },
  {
    key: 14,
    id: 'BR123469',
    name: 'East Thames Crossing',
    type: 'Bridge',
    division: 'Infrastructure',
    region: 'East of England',
    status: 'Construction',
    value: '£ 1,050,000,000.00',
    client: 'HE',
    manager: 'Ben Flin',
    start: '2018-01-01',
    end: '2025-09-01',
    lat: "51'26'58",
    lng: "0'22'33",
  },
  {
    key: 15,
    id: 'TN123470',
    name: 'West Thames Crossing',
    type: 'Tunnel',
    division: 'Infrastructure',
    region: 'South East',
    status: 'Construction',
    value: '£ 783,000,000.00',
    client: 'NR',
    manager: 'Jack Brown',
    start: '2019-06-01',
    end: '2024-10-01',
    lat: "53'17'34",
    lng: "2'51'05",
  },
]
