import { numberSorter, stringSorter } from '../../../../../utils/Sorters'
import { ExtendedColumnType } from '../../../../basic/table/sterling-table/SterlingTable'
import { currency, renderDecimal } from '../../../../../utils/rendering/Rendering'

export const labourColumns: ExtendedColumnType[] = [
  {
    title: 'Item Code',
    dataIndex: 'itemCode',
    key: 'itemCode',
    fixed: 'left',
    width: 240,
    sorter: (a, b) => stringSorter(a.itemCode, b.itemCode),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'ascend',
    editable: true,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    fixed: 'left',
    key: 'title',
    width: 240,
    sorter: (a, b) => stringSorter(a.title, b.title),
    sortDirections: ['ascend', 'descend'],
    editable: true,
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    width: 130,
    sorter: (a, b) => stringSorter(a.notes, b.notes),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 130,
    sorter: (a, b) => stringSorter(a.description, b.description),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    width: 130,
    sorter: (a, b) => stringSorter(a.unit, b.unit),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    width: 130,
    sorter: (a, b) => stringSorter(a.currency, b.currency),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Total Unit Rate',
    dataIndex: 'totalUnitRate',
    key: 'totalUnitRate',
    width: 130,
    align: 'right',
    sorter: (a, b) => numberSorter(a.unitRate, b.unitRate),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    render: currency,
  },
  {
    title: 'CO2 Unit Rate',
    dataIndex: 'co2UnitRate',
    key: 'co2UnitRate',
    width: 130,
    align: 'right',
    sorter: (a, b) => numberSorter(a.unitRate, b.unitRate),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    render: renderDecimal,
  },
  {
    title: 'Company',
    dataIndex: 'company',
    key: 'company',
    width: 130,
    sorter: (a, b) => stringSorter(a.company, b.company),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Contact Person',
    dataIndex: 'contactPerson',
    key: 'contactPerson',
    width: 130,
    sorter: (a, b) => stringSorter(a.contactPerson, b.contactPerson),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Phone Number',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    width: 130,
    sorter: (a, b) => stringSorter(a.phoneNumber, b.phoneNumber),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Mobile Number',
    dataIndex: 'mobileNumber',
    key: 'mobileNumber',
    width: 130,
    sorter: (a, b) => stringSorter(a.mobileNumber, b.mobileNumber),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email',
    width: 130,
    sorter: (a, b) => stringSorter(a.email, b.email),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    width: 130,
    sorter: (a, b) => stringSorter(a.address, b.address),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'City/Residence',
    dataIndex: 'cityResidence',
    key: 'cityResidence',
    width: 130,
    sorter: (a, b) => stringSorter(a.cityResidence, b.cityResidence),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'State/Province',
    dataIndex: 'stateProvince',
    key: 'stateProvince',
    width: 130,
    sorter: (a, b) => stringSorter(a.stateProvince, b.stateProvince),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Post Code',
    dataIndex: 'postCode',
    key: 'postCode',
    width: 130,
    sorter: (a, b) => stringSorter(a.postCode, b.postCode),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    width: 130,
    sorter: (a, b) => stringSorter(a.country, b.country),
    sortDirections: ['ascend', 'descend'],
  },
]
