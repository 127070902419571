import React from 'react'
import { FunctionComponent } from 'react'

const WorkPackagesDashboard: FunctionComponent = () => {
  return (
    <iframe
      width="100%"
      height="612"
      src="https://app.powerbi.com/view?r=eyJrIjoiMzRjYTczMTgtNjcyYi00YzYzLWExMjQtZTQ1ODIzMTJiZDRhIiwidCI6IjE4OWExNmU1LTlkM2YtNDEwNC1iZTAyLTQ4NDYxMmQ0ZjY0ZiJ9"
      frameBorder="0"
      allowFullScreen={true}
    />
  )
}

export default WorkPackagesDashboard
