import React, { FunctionComponent } from 'react'
import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'

const ActivitiesBreadcrumb: FunctionComponent = () => {
  return (
    <Breadcrumb style={{ paddingTop: '16px', paddingBottom: '16px' }}>
      <Breadcrumb.Item>
        <Link to={'/'}>Portfolio</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Sample Project</Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to={'/activities'}>Activities</Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  )
}

export default ActivitiesBreadcrumb
