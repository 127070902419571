import React, { FunctionComponent } from 'react'
import { ActivitiesHeader } from '../header/ActivitiesHeader'
import { activitiesColumns } from '../../library/table/columns/Activities.columns'
import SterlingTable from '../../../basic/table/sterling-table/SterlingTable'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../context/reducer'

interface ActivitiesTableProps {
  tableHeight: number
}

const ActivitiesTable: FunctionComponent<ActivitiesTableProps> = ({ tableHeight }) => {
  const data = useSelector((state: RootState) => state.sterlingTable.parsedData.activities)

  return (
    <SterlingTable
      renderHeader={(props) => <ActivitiesHeader sterlingTableHeaderProps={props} />}
      tableId={'Valuation'}
      dataSource={data}
      columns={activitiesColumns}
      tableHeight={tableHeight}
      defaultPagination={false}
    />
  )
}

export default ActivitiesTable
