import React, { useState } from 'react'
import _ from 'lodash'
import { ProjectCategorizations } from '../../../../data/Projects.data'
import { Col, DatePicker, Form, Input, Row, Select } from 'antd'
import AddProjectFormPart from './AddProjectFormPart'

const GeneralInformation: AddProjectFormPart = ({ form }) => {
  const [category, setCategory] = useState('')
  const [type, setType] = useState('')

  const categorizationByCategory = _.groupBy(ProjectCategorizations, 'category')

  const selectCategory = (cat: any) => setCategory(cat.toString())
  const selectType = (tpe: any) => setType(tpe.toString())

  return (
    <Form form={form} layout={'vertical'}>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item name="name" label={'Name'} rules={[{ required: true, message: 'Enter project name!' }]}>
            <Input placeholder={'Enter project name'} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="code" label={'Code'} rules={[{ required: true, message: 'Enter project code!' }]}>
            <Input placeholder={'Enter project code'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item
            name="projectDates"
            label={'Project dates'}
            rules={[{ required: true, message: 'Enter project dates!' }]}
          >
            <DatePicker.RangePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={'category'}
            label={'Category'}
            rules={[{ required: true, message: 'Select project category!' }]}
          >
            <Select showSearch={true} placeholder={'Select project category'} onSelect={selectCategory}>
              {Object.keys(categorizationByCategory).map((cat) => (
                <Select.Option key={cat} value={cat}>
                  {cat}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item
            name={'type'}
            style={{ display: category !== '' ? 'block' : 'none' }}
            label={'Type'}
            rules={[{ required: true, message: 'Select project type!' }]}
          >
            <Select showSearch={true} placeholder={'Select project type'} onSelect={selectType}>
              {categorizationByCategory[category]?.map((t) => (
                <Select.Option key={t?.type} value={t!.type}>
                  {t?.type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            style={{ display: category === 'Infrastructure' ? 'block' : 'none' }}
            name={'subtype'}
            label={'Subtype'}
            rules={[{ required: category === 'Infrastructure', message: 'Select project subtype!' }]}
          >
            <Select showSearch={true} placeholder={'Select project subtype'}>
              {categorizationByCategory[category]
                ?.find((t) => type && t?.type === type)
                ?.subtypes?.map((s) => (
                  <Select.Option value={s} key={s}>
                    {s}
                  </Select.Option>
                )) || []}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        {(categorizationByCategory[category]?.find((t) => type && t?.type === type)?.fields || []).map((f) => (
          <Col span={12} key={f.label.toString()}>
            <Form.Item name={f.label.toLowerCase()} label={f.label}>
              <Input placeholder={`Enter ${f.label}`} />
            </Form.Item>
          </Col>
        ))}
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name={'description'} label={'Description'}>
            <Input.TextArea placeholder={'Enter project description'} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default GeneralInformation
