import { ModelNode } from '../../components/domain/forge-viewer/ForgeViewer.model'
import { parseTakeOffData, TakeOffItem } from '../../components/domain/take-off/helpers/TakeOffDataParser'
import { convertToTableItem } from '../../components/domain/take-off/helpers/TakeOffConverter'
import { TakeOffTableItem } from '../../components/domain/take-off/TakeOffTables.model'

export const ModelSuperstructureData: ModelNode = {
  id: 1,
  name: 'BHR-R5D-401-00-M3-X-0004-D1-08_Entrance Building - Superstructure.ifc',
  children: [
    {
      id: 2,
      name: '<PRJ NUMBER>',
      children: [
        {
          id: 3,
          name: 'Default',
          children: [
            {
              id: 4,
              name: 'IFCBUILDING',
              children: [
                {
                  id: 5,
                  name: 'ENTRANCE-0-GROUND',
                  children: [
                    {
                      id: 6,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:793653',
                      children: [
                        {
                          id: 7,
                          name: 'Body',
                          children: [
                            {
                              id: 8,
                              name: 'Body',
                              children: [
                                {
                                  id: 9,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 10,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a612c3',
                    },
                    {
                      id: 11,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:793870',
                      children: [
                        {
                          id: 12,
                          name: 'Body',
                          children: [
                            {
                              id: 13,
                              name: 'Body',
                              children: [
                                {
                                  id: 14,
                                  name: '#460',
                                  children: [
                                    {
                                      id: 15,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a613f8',
                    },
                    {
                      id: 16,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:793899',
                      children: [
                        {
                          id: 17,
                          name: 'Body',
                          children: [
                            {
                              id: 18,
                              name: 'Body',
                              children: [
                                {
                                  id: 19,
                                  name: '#504',
                                  children: [
                                    {
                                      id: 20,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a613dd',
                    },
                    {
                      id: 21,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:793926',
                      children: [
                        {
                          id: 22,
                          name: 'Body',
                          children: [
                            {
                              id: 23,
                              name: 'Body',
                              children: [
                                {
                                  id: 24,
                                  name: '#548',
                                  children: [
                                    {
                                      id: 25,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a613b0',
                    },
                    {
                      id: 26,
                      name: 'Concrete-Square-Column:03.02.01.01.04.010_Reinf Conc Column C2 400x400 E10/135:793962',
                      children: [
                        {
                          id: 27,
                          name: 'Body',
                          children: [
                            {
                              id: 28,
                              name: 'Body',
                              children: [
                                {
                                  id: 29,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 30,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a6139c',
                    },
                    {
                      id: 31,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:794007',
                      children: [
                        {
                          id: 32,
                          name: 'Body',
                          children: [
                            {
                              id: 33,
                              name: 'Body',
                              children: [
                                {
                                  id: 34,
                                  name: '#676',
                                  children: [
                                    {
                                      id: 35,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a61361',
                    },
                    {
                      id: 36,
                      name: 'Concrete-Square-Column:03.02.01.01.04.010_Reinf Conc Column C2 400x400 E10/135:794050',
                      children: [
                        {
                          id: 37,
                          name: 'Body',
                          children: [
                            {
                              id: 38,
                              name: 'Body',
                              children: [
                                {
                                  id: 39,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 40,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4850.00000000529,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4850.00000000529,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a61334',
                    },
                    {
                      id: 41,
                      name: 'Concrete-Square-Column:03.02.01.01.04.010_Reinf Conc Column C2 400x400 E10/135:794153',
                      children: [
                        {
                          id: 42,
                          name: 'Body',
                          children: [
                            {
                              id: 43,
                              name: 'Body',
                              children: [
                                {
                                  id: 44,
                                  name: '#798',
                                  children: [
                                    {
                                      id: 45,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4850.00000000529,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4850.00000000529,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a610df',
                    },
                    {
                      id: 46,
                      name: 'Concrete-Square-Column:03.02.01.01.04.010_Reinf Conc Column C2 400x400 E10/135:794186',
                      children: [
                        {
                          id: 47,
                          name: 'Body',
                          children: [
                            {
                              id: 48,
                              name: 'Body',
                              children: [
                                {
                                  id: 49,
                                  name: '#842',
                                  children: [
                                    {
                                      id: 50,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4850.00000000529,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4850.00000000529,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a610bc',
                    },
                    {
                      id: 51,
                      name: 'Concrete-Square-Column:03.02.01.01.04.010_Reinf Conc Column C2 400x400 E10/135:794225',
                      children: [
                        {
                          id: 52,
                          name: 'Body',
                          children: [
                            {
                              id: 53,
                              name: 'Body',
                              children: [
                                {
                                  id: 54,
                                  name: '#886',
                                  children: [
                                    {
                                      id: 55,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4850.00000000529,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4850.00000000529,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a61087',
                    },
                    {
                      id: 56,
                      name: 'Concrete-Square-Column:03.02.01.01.04.010_Reinf Conc Column C2 400x400 E10/135:794266',
                      children: [
                        {
                          id: 57,
                          name: 'Body',
                          children: [
                            {
                              id: 58,
                              name: 'Body',
                              children: [
                                {
                                  id: 59,
                                  name: '#930',
                                  children: [
                                    {
                                      id: 60,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4850.00000000529,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4850.00000000529,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a6106c',
                    },
                    {
                      id: 61,
                      name: 'Concrete-Square-Column:03.02.01.01.04.010_Reinf Conc Column C2 400x400 E10/135:794292',
                      children: [
                        {
                          id: 62,
                          name: 'Body',
                          children: [
                            {
                              id: 63,
                              name: 'Body',
                              children: [
                                {
                                  id: 64,
                                  name: '#974',
                                  children: [
                                    {
                                      id: 65,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4850.00000000529,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4850.00000000529,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a61042',
                    },
                    {
                      id: 66,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:794622',
                      children: [
                        {
                          id: 67,
                          name: 'Body',
                          children: [
                            {
                              id: 68,
                              name: 'Body',
                              children: [
                                {
                                  id: 69,
                                  name: '#1018',
                                  children: [
                                    {
                                      id: 70,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a61108',
                    },
                    {
                      id: 71,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:794646',
                      children: [
                        {
                          id: 72,
                          name: 'Body',
                          children: [
                            {
                              id: 73,
                              name: 'Body',
                              children: [
                                {
                                  id: 74,
                                  name: '#1062',
                                  children: [
                                    {
                                      id: 75,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62ee0',
                    },
                    {
                      id: 76,
                      name:
                        'Round Column:03.02.01.01.04.040_Reinf Conc Column C4 CiReinf Concular 400mm diameter E10/135:794893',
                      children: [
                        {
                          id: 77,
                          name: 'Body',
                          children: [
                            {
                              id: 78,
                              name: 'Body',
                              children: [
                                {
                                  id: 79,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 80,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62ffb',
                    },
                    {
                      id: 81,
                      name:
                        'Round Column:03.02.01.01.04.040_Reinf Conc Column C4 CiReinf Concular 400mm diameter E10/135:795033',
                      children: [
                        {
                          id: 82,
                          name: 'Body',
                          children: [
                            {
                              id: 83,
                              name: 'Body',
                              children: [
                                {
                                  id: 84,
                                  name: '#1190',
                                  children: [
                                    {
                                      id: 85,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62f6f',
                    },
                    {
                      id: 86,
                      name:
                        'Round Column:03.02.01.01.04.040_Reinf Conc Column C4 CiReinf Concular 400mm diameter E10/135:795057',
                      children: [
                        {
                          id: 87,
                          name: 'Body',
                          children: [
                            {
                              id: 88,
                              name: 'Body',
                              children: [
                                {
                                  id: 89,
                                  name: '#1234',
                                  children: [
                                    {
                                      id: 90,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62f47',
                    },
                    {
                      id: 91,
                      name:
                        'Round Column:03.02.01.01.04.040_Reinf Conc Column C4 CiReinf Concular 400mm diameter E10/135:795082',
                      children: [
                        {
                          id: 92,
                          name: 'Body',
                          children: [
                            {
                              id: 93,
                              name: 'Body',
                              children: [
                                {
                                  id: 94,
                                  name: '#1278',
                                  children: [
                                    {
                                      id: 95,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62f3c',
                    },
                    {
                      id: 96,
                      name:
                        'Round Column:03.02.01.01.04.040_Reinf Conc Column C4 CiReinf Concular 400mm diameter E10/135:795185',
                      children: [
                        {
                          id: 97,
                          name: 'Body',
                          children: [
                            {
                              id: 98,
                              name: 'Body',
                              children: [
                                {
                                  id: 99,
                                  name: '#1322',
                                  children: [
                                    {
                                      id: 100,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62cc7',
                    },
                    {
                      id: 101,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:795245',
                      children: [
                        {
                          id: 102,
                          name: 'Body',
                          children: [
                            {
                              id: 103,
                              name: 'Body',
                              children: [
                                {
                                  id: 104,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 105,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62c9b',
                    },
                    {
                      id: 106,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:795370',
                      children: [
                        {
                          id: 107,
                          name: 'Body',
                          children: [
                            {
                              id: 108,
                              name: 'Body',
                              children: [
                                {
                                  id: 109,
                                  name: '#1450',
                                  children: [
                                    {
                                      id: 110,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62c1c',
                    },
                    {
                      id: 111,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:795405',
                      children: [
                        {
                          id: 112,
                          name: 'Body',
                          children: [
                            {
                              id: 113,
                              name: 'Body',
                              children: [
                                {
                                  id: 114,
                                  name: '#1494',
                                  children: [
                                    {
                                      id: 115,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62dfb',
                    },
                    {
                      id: 116,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:795444',
                      children: [
                        {
                          id: 117,
                          name: 'Body',
                          children: [
                            {
                              id: 118,
                              name: 'Body',
                              children: [
                                {
                                  id: 119,
                                  name: '#1538',
                                  children: [
                                    {
                                      id: 120,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62dc2',
                    },
                    {
                      id: 121,
                      name:
                        'Round Column:03.02.01.01.04.040_Reinf Conc Column C4 CiReinf Concular 400mm diameter E10/135:795494',
                      children: [
                        {
                          id: 122,
                          name: 'Body',
                          children: [],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62d90',
                    },
                    {
                      id: 123,
                      name:
                        'Round Column:03.02.01.01.04.040_Reinf Conc Column C4 CiReinf Concular 400mm diameter E10/135:795645',
                      children: [
                        {
                          id: 124,
                          name: 'Body',
                          children: [],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62d0b',
                    },
                    {
                      id: 125,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:795672',
                      children: [
                        {
                          id: 126,
                          name: 'Body',
                          children: [
                            {
                              id: 127,
                              name: 'Body',
                              children: [
                                {
                                  id: 128,
                                  name: '#1738',
                                  children: [
                                    {
                                      id: 129,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62aee',
                    },
                    {
                      id: 130,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:795705',
                      children: [
                        {
                          id: 131,
                          name: 'Body',
                          children: [
                            {
                              id: 132,
                              name: 'Body',
                              children: [
                                {
                                  id: 133,
                                  name: '#1782',
                                  children: [
                                    {
                                      id: 134,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62acf',
                    },
                    {
                      id: 135,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:795751',
                      children: [
                        {
                          id: 136,
                          name: 'Body',
                          children: [
                            {
                              id: 137,
                              name: 'Body',
                              children: [
                                {
                                  id: 138,
                                  name: '#1826',
                                  children: [
                                    {
                                      id: 139,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62a91',
                    },
                    {
                      id: 140,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:795812',
                      children: [
                        {
                          id: 141,
                          name: 'Body',
                          children: [
                            {
                              id: 142,
                              name: 'Body',
                              children: [
                                {
                                  id: 143,
                                  name: '#1870',
                                  children: [
                                    {
                                      id: 144,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62a52',
                    },
                    {
                      id: 145,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:795843',
                      children: [
                        {
                          id: 146,
                          name: 'Body',
                          children: [
                            {
                              id: 147,
                              name: 'Body',
                              children: [
                                {
                                  id: 148,
                                  name: '#1914',
                                  children: [
                                    {
                                      id: 149,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62a35',
                    },
                    {
                      id: 150,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:795945',
                      children: [
                        {
                          id: 151,
                          name: 'Body',
                          children: [
                            {
                              id: 152,
                              name: 'Body',
                              children: [
                                {
                                  id: 153,
                                  name: '#1958',
                                  children: [
                                    {
                                      id: 154,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62bdf',
                    },
                    {
                      id: 155,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:795984',
                      children: [
                        {
                          id: 156,
                          name: 'Body',
                          children: [
                            {
                              id: 157,
                              name: 'Body',
                              children: [
                                {
                                  id: 158,
                                  name: '#2002',
                                  children: [
                                    {
                                      id: 159,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62ba6',
                    },
                    {
                      id: 160,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:796059',
                      children: [
                        {
                          id: 161,
                          name: 'Body',
                          children: [
                            {
                              id: 162,
                              name: 'Body',
                              children: [
                                {
                                  id: 163,
                                  name: '#2046',
                                  children: [
                                    {
                                      id: 164,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62b6d',
                    },
                    {
                      id: 165,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:796135',
                      children: [
                        {
                          id: 166,
                          name: 'Body',
                          children: [
                            {
                              id: 167,
                              name: 'Body',
                              children: [
                                {
                                  id: 168,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 169,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4850.00000000529,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4850.00000000529,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62b11',
                    },
                    {
                      id: 170,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:796221',
                      children: [
                        {
                          id: 171,
                          name: 'Body',
                          children: [
                            {
                              id: 172,
                              name: 'Body',
                              children: [
                                {
                                  id: 173,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 174,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4850.00000000529,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4850.00000000529,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a628cb',
                    },
                    {
                      id: 175,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:796280',
                      children: [
                        {
                          id: 176,
                          name: 'Body',
                          children: [
                            {
                              id: 177,
                              name: 'Body',
                              children: [
                                {
                                  id: 178,
                                  name: '#2246',
                                  children: [
                                    {
                                      id: 179,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4850.00000000529,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4850.00000000529,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a6288e',
                    },
                    {
                      id: 180,
                      name: 'Concrete-Square-Column:03.02.01.01.04.070_Reinf Conc Column C6 300x300 E10/135:796398',
                      children: [
                        {
                          id: 181,
                          name: 'Body',
                          children: [
                            {
                              id: 182,
                              name: 'Body',
                              children: [
                                {
                                  id: 183,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 184,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62818',
                    },
                    {
                      id: 185,
                      name: 'Concrete-Square-Column:03.02.01.01.04.010_Reinf Conc Column C2 400x400 E10/135:796526',
                      children: [
                        {
                          id: 186,
                          name: 'Body',
                          children: [
                            {
                              id: 187,
                              name: 'Body',
                              children: [
                                {
                                  id: 188,
                                  name: '#2374',
                                  children: [
                                    {
                                      id: 189,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62998',
                    },
                    {
                      id: 190,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:796577',
                      children: [
                        {
                          id: 191,
                          name: 'Body',
                          children: [
                            {
                              id: 192,
                              name: 'Body',
                              children: [
                                {
                                  id: 193,
                                  name: '#2418',
                                  children: [
                                    {
                                      id: 194,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62957',
                    },
                    {
                      id: 195,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:796682',
                      children: [
                        {
                          id: 196,
                          name: 'Body',
                          children: [
                            {
                              id: 197,
                              name: 'Body',
                              children: [
                                {
                                  id: 198,
                                  name: '#2462',
                                  children: [
                                    {
                                      id: 199,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a626fc',
                    },
                    {
                      id: 200,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:796731',
                      children: [
                        {
                          id: 201,
                          name: 'Body',
                          children: [
                            {
                              id: 202,
                              name: 'Body',
                              children: [
                                {
                                  id: 203,
                                  name: '#2506',
                                  children: [
                                    {
                                      id: 204,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a626cd',
                    },
                    {
                      id: 205,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:796792',
                      children: [
                        {
                          id: 206,
                          name: 'Body',
                          children: [
                            {
                              id: 207,
                              name: 'Body',
                              children: [
                                {
                                  id: 208,
                                  name: '#2550',
                                  children: [
                                    {
                                      id: 209,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a6268e',
                    },
                    {
                      id: 210,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:796858',
                      children: [
                        {
                          id: 211,
                          name: 'Body',
                          children: [
                            {
                              id: 212,
                              name: 'Body',
                              children: [
                                {
                                  id: 213,
                                  name: '#2594',
                                  children: [
                                    {
                                      id: 214,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a6264c',
                    },
                    {
                      id: 215,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:796929',
                      children: [
                        {
                          id: 216,
                          name: 'Body',
                          children: [
                            {
                              id: 217,
                              name: 'Body',
                              children: [
                                {
                                  id: 218,
                                  name: '#2638',
                                  children: [
                                    {
                                      id: 219,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4850.00000000529,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4850.00000000529,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a627f7',
                    },
                    {
                      id: 220,
                      name: 'Concrete-Square-Column:03.02.01.01.04.070_Reinf Conc Column C6 300x300 E10/135:796975',
                      children: [
                        {
                          id: 221,
                          name: 'Body',
                          children: [
                            {
                              id: 222,
                              name: 'Body',
                              children: [
                                {
                                  id: 223,
                                  name: '#2682',
                                  children: [
                                    {
                                      id: 224,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4575.00000000528,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4575.00000000528,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a627d9',
                    },
                    {
                      id: 225,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:797039',
                      children: [
                        {
                          id: 226,
                          name: 'Body',
                          children: [
                            {
                              id: 227,
                              name: 'Body',
                              children: [
                                {
                                  id: 228,
                                  name: '#2726',
                                  children: [
                                    {
                                      id: 229,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4850.00000000529,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4850.00000000529,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62799',
                    },
                    {
                      id: 230,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:797124',
                      children: [
                        {
                          id: 231,
                          name: 'Body',
                          children: [
                            {
                              id: 232,
                              name: 'Body',
                              children: [
                                {
                                  id: 233,
                                  name: '#2770',
                                  children: [
                                    {
                                      id: 234,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4850.00000000529,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4850.00000000529,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62732',
                    },
                    {
                      id: 235,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:797319',
                      children: [
                        {
                          id: 236,
                          name: 'Body',
                          children: [
                            {
                              id: 237,
                              name: 'Axis',
                              children: [
                                {
                                  id: 238,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 239,
                                      name: '#2822',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 240,
                              name: 'Body',
                              children: [
                                {
                                  id: 241,
                                  name: '#2832',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 5625.00000711406,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5625.00000711406,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62471',
                    },
                    {
                      id: 242,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:797609',
                      children: [
                        {
                          id: 243,
                          name: 'Body',
                          children: [
                            {
                              id: 244,
                              name: 'Axis',
                              children: [
                                {
                                  id: 245,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 246,
                                      name: '#3035',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 247,
                              name: 'Body',
                              children: [
                                {
                                  id: 248,
                                  name: '#3044',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 6624.95514909705,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 6624.95514909705,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a6255f',
                    },
                    {
                      id: 249,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:797807',
                      children: [
                        {
                          id: 250,
                          name: 'Body',
                          children: [
                            {
                              id: 251,
                              name: 'Axis',
                              children: [
                                {
                                  id: 252,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 253,
                                      name: '#3098',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 254,
                              name: 'Body',
                              children: [
                                {
                                  id: 255,
                                  name: '#3107',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5149.98981104171,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62299',
                    },
                    {
                      id: 256,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:797890',
                      children: [
                        {
                          id: 257,
                          name: 'Body',
                          children: [
                            {
                              id: 258,
                              name: 'Axis',
                              children: [
                                {
                                  id: 259,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 260,
                                      name: '#3204',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 261,
                              name: 'Body',
                              children: [
                                {
                                  id: 262,
                                  name: '#3213',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 4549.99464173038,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62234',
                    },
                    {
                      id: 263,
                      name: 'Basic Wall:03.02.01.01.04.100_200 Reinf Conc  Wall E05:798029',
                      children: [
                        {
                          id: 264,
                          name: 'Body',
                          children: [
                            {
                              id: 265,
                              name: 'Axis',
                              children: [
                                {
                                  id: 266,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 267,
                                      name: '#3308',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 268,
                              name: 'Body',
                              children: [
                                {
                                  id: 269,
                                  name: '#3317',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 9199.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 3375,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 200,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 9199.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 3375,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 200,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a623bb',
                    },
                    {
                      id: 270,
                      name: 'Basic Wall:03.02.01.01.04.100_200 Reinf Conc  Wall E05:798224',
                      children: [
                        {
                          id: 271,
                          name: 'Body',
                          children: [
                            {
                              id: 272,
                              name: 'Axis',
                              children: [
                                {
                                  id: 273,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 274,
                                      name: '#3390',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 275,
                              name: 'Body',
                              children: [
                                {
                                  id: 276,
                                  name: '#3399',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 9199.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 7724.99999879808,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 200,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 9199.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 7724.99999879808,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 200,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a620e6',
                    },
                    {
                      id: 277,
                      name: 'Basic Wall:03.02.01.01.04.100_200 Reinf Conc  Wall E05:798328',
                      children: [
                        {
                          id: 278,
                          name: 'Body',
                          children: [
                            {
                              id: 279,
                              name: 'Axis',
                              children: [
                                {
                                  id: 280,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 281,
                                      name: '#3453',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 282,
                              name: 'Body',
                              children: [
                                {
                                  id: 283,
                                  name: '#3462',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 9199.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 3174.99999999996,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 200,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a6208e',
                    },
                    {
                      id: 284,
                      name: 'Basic Wall:03.02.01.01.04.100_200 Reinf Conc  Wall E05:798402',
                      children: [
                        {
                          id: 285,
                          name: 'Body',
                          children: [
                            {
                              id: 286,
                              name: 'Axis',
                              children: [
                                {
                                  id: 287,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 288,
                                      name: '#3596',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 289,
                              name: 'Body',
                              children: [
                                {
                                  id: 290,
                                  name: '#3605',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 9199.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 7524.99999879805,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 200,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62034',
                    },
                    {
                      id: 291,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:798469',
                      children: [
                        {
                          id: 292,
                          name: 'Body',
                          children: [
                            {
                              id: 293,
                              name: 'Axis',
                              children: [
                                {
                                  id: 294,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 295,
                                      name: '#3700',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 296,
                              name: 'Body',
                              children: [
                                {
                                  id: 297,
                                  name: '#3709',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 424.985300588071,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 424.985300588071,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a621f3',
                    },
                    {
                      id: 298,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:798679',
                      children: [
                        {
                          id: 299,
                          name: 'Body',
                          children: [
                            {
                              id: 300,
                              name: 'Axis',
                              children: [
                                {
                                  id: 301,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 302,
                                      name: '#3763',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 303,
                              name: 'Body',
                              children: [
                                {
                                  id: 304,
                                  name: '#3772',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 4135.01911837583,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 4135.01911837583,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a62121',
                    },
                    {
                      id: 305,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:798917',
                      children: [
                        {
                          id: 306,
                          name: 'Body',
                          children: [
                            {
                              id: 307,
                              name: 'Axis',
                              children: [
                                {
                                  id: 308,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 309,
                                      name: '#3826',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 310,
                              name: 'Body',
                              children: [
                                {
                                  id: 311,
                                  name: '#3835',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 4185.01951962942,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 4185.01951962942,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a63e33',
                    },
                    {
                      id: 312,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:799063',
                      children: [
                        {
                          id: 313,
                          name: 'Body',
                          children: [
                            {
                              id: 314,
                              name: 'Axis',
                              children: [
                                {
                                  id: 315,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 316,
                                      name: '#3889',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 317,
                              name: 'Body',
                              children: [
                                {
                                  id: 318,
                                  name: '#3898',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 2079.90354714524,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2079.90354714524,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a63fa1',
                    },
                    {
                      id: 319,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:799278',
                      children: [
                        {
                          id: 320,
                          name: 'Body',
                          children: [
                            {
                              id: 321,
                              name: 'Axis',
                              children: [
                                {
                                  id: 322,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 323,
                                      name: '#3952',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 324,
                              name: 'Body',
                              children: [
                                {
                                  id: 325,
                                  name: '#3961',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 2205.09690280003,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2205.09690280003,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '5c375109-d579-4cc5-bf89-9c0037a63cd8',
                    },
                    {
                      id: 326,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:799667',
                      children: [
                        {
                          id: 327,
                          name: 'Body',
                          children: [
                            {
                              id: 328,
                              name: 'Axis',
                              children: [
                                {
                                  id: 329,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 330,
                                      name: '#4015',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 331,
                              name: 'Body',
                              children: [
                                {
                                  id: 332,
                                  name: '#4024',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 625.178416083199,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 625.178416083199,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a23aa',
                    },
                    {
                      id: 333,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:799807',
                      children: [
                        {
                          id: 334,
                          name: 'Body',
                          children: [
                            {
                              id: 335,
                              name: 'Axis',
                              children: [
                                {
                                  id: 336,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 337,
                                      name: '#4078',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 338,
                              name: 'Body',
                              children: [
                                {
                                  id: 339,
                                  name: '#4087',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 3364.8215839168,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 3364.8215839168,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a2426',
                    },
                    {
                      id: 340,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:800163',
                      children: [
                        {
                          id: 341,
                          name: 'Body',
                          children: [
                            {
                              id: 342,
                              name: 'Axis',
                              children: [
                                {
                                  id: 343,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 344,
                                      name: '#4141',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 345,
                              name: 'Body',
                              children: [
                                {
                                  id: 346,
                                  name: '#4150',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 146.845014421038,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 146.845014421038,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a25ba',
                    },
                    {
                      id: 347,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:800401',
                      children: [
                        {
                          id: 348,
                          name: 'Body',
                          children: [
                            {
                              id: 349,
                              name: 'Axis',
                              children: [
                                {
                                  id: 350,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 351,
                                      name: '#4204',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 352,
                              name: 'Body',
                              children: [
                                {
                                  id: 353,
                                  name: '#4213',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 1760.00000228695,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1760.00000228695,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a2688',
                    },
                    {
                      id: 354,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:800617',
                      children: [
                        {
                          id: 355,
                          name: 'Body',
                          children: [
                            {
                              id: 356,
                              name: 'Axis',
                              children: [
                                {
                                  id: 357,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 358,
                                      name: '#4267',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 359,
                              name: 'Body',
                              children: [
                                {
                                  id: 360,
                                  name: '#4276',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2525.00382317392,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a2770',
                    },
                    {
                      id: 361,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:800758',
                      children: [
                        {
                          id: 362,
                          name: 'Body',
                          children: [
                            {
                              id: 363,
                              name: 'Axis',
                              children: [
                                {
                                  id: 364,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 365,
                                      name: '#4371',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 366,
                              name: 'Body',
                              children: [
                                {
                                  id: 367,
                                  name: '#4380',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5500.00000714667,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a27ef',
                    },
                    {
                      id: 368,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:800879',
                      children: [
                        {
                          id: 369,
                          name: 'Body',
                          children: [
                            {
                              id: 370,
                              name: 'Axis',
                              children: [
                                {
                                  id: 371,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 372,
                                      name: '#4475',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 373,
                              name: 'Body',
                              children: [
                                {
                                  id: 374,
                                  name: '#4484',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5575.02089235354,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a2876',
                    },
                    {
                      id: 375,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:800979',
                      children: [
                        {
                          id: 376,
                          name: 'Body',
                          children: [
                            {
                              id: 377,
                              name: 'Axis',
                              children: [
                                {
                                  id: 378,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 379,
                                      name: '#4618',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 380,
                              name: 'Body',
                              children: [
                                {
                                  id: 381,
                                  name: '#4627',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5649.97148253702,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a28ca',
                    },
                    {
                      id: 382,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:801080',
                      children: [
                        {
                          id: 383,
                          name: 'Body',
                          children: [
                            {
                              id: 384,
                              name: 'Axis',
                              children: [
                                {
                                  id: 385,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 386,
                                      name: '#4722',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 387,
                              name: 'Body',
                              children: [
                                {
                                  id: 388,
                                  name: '#4731',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5575.00383723987,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a2921',
                    },
                    {
                      id: 389,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:801187',
                      children: [
                        {
                          id: 390,
                          name: 'Body',
                          children: [
                            {
                              id: 391,
                              name: 'Axis',
                              children: [
                                {
                                  id: 392,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 393,
                                      name: '#4865',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 394,
                              name: 'Body',
                              children: [
                                {
                                  id: 395,
                                  name: '#4874',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2524.96304392593,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a29ba',
                    },
                    {
                      id: 396,
                      name: 'Basic Wall:03.02.01.01.04.100_200 Reinf Conc  Wall E05:801255',
                      children: [
                        {
                          id: 397,
                          name: 'Body',
                          children: [
                            {
                              id: 398,
                              name: 'Axis',
                              children: [
                                {
                                  id: 399,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 400,
                                      name: '#4969',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 401,
                              name: 'Body',
                              children: [
                                {
                                  id: 402,
                                  name: '#4978',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 7800.01858733449,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 200,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a29fe',
                    },
                    {
                      id: 403,
                      name: 'Basic Wall:03.02.01.01.04.100_200 Reinf Conc  Wall E05:801586',
                      children: [
                        {
                          id: 404,
                          name: 'Body',
                          children: [
                            {
                              id: 405,
                              name: 'Axis',
                              children: [
                                {
                                  id: 406,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 407,
                                      name: '#5073',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 408,
                              name: 'Body',
                              children: [
                                {
                                  id: 409,
                                  name: '#5082',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 9199.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 3175.04715095077,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 200,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a2b2b',
                    },
                    {
                      id: 410,
                      name: 'Basic Wall:03.02.01.01.04.100_200 Reinf Conc  Wall E05:801843',
                      children: [
                        {
                          id: 411,
                          name: 'Body',
                          children: [
                            {
                              id: 412,
                              name: 'Axis',
                              children: [
                                {
                                  id: 413,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 414,
                                      name: '#5216',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 415,
                              name: 'Body',
                              children: [
                                {
                                  id: 416,
                                  name: '#5225',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 9199.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 10174.8969624366,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 200,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 9199.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 10174.8969624366,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 200,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a2c2a',
                    },
                    {
                      id: 417,
                      name: 'Basic Wall:03.02.01.01.04.100_200 Reinf Conc  Wall E05:802129',
                      children: [
                        {
                          id: 418,
                          name: 'Body',
                          children: [
                            {
                              id: 419,
                              name: 'Axis',
                              children: [
                                {
                                  id: 420,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 421,
                                      name: '#5279',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 422,
                              name: 'Body',
                              children: [
                                {
                                  id: 423,
                                  name: '#5288',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 9199.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 3349.44327997697,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 200,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a2d48',
                    },
                    {
                      id: 424,
                      name: 'Basic Wall:03.02.01.01.04.100_200 Reinf Conc  Wall E05:802294',
                      children: [
                        {
                          id: 425,
                          name: 'Body',
                          children: [
                            {
                              id: 426,
                              name: 'Axis',
                              children: [
                                {
                                  id: 427,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 428,
                                      name: '#5422',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 429,
                              name: 'Body',
                              children: [
                                {
                                  id: 430,
                                  name: '#5431',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 9199.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 3349.44327997717,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 200,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a2def',
                    },
                    {
                      id: 431,
                      name: 'Basic Wall:03.02.01.01.04.100_200 Reinf Conc  Wall E05:802524',
                      children: [
                        {
                          id: 432,
                          name: 'Body',
                          children: [
                            {
                              id: 433,
                              name: 'Axis',
                              children: [
                                {
                                  id: 434,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 435,
                                      name: '#5565',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 436,
                              name: 'Body',
                              children: [
                                {
                                  id: 437,
                                  name: '#5574',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 9199.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 2174.71141944988,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 200,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 9199.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2174.71141944988,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 200,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a2ec5',
                    },
                    {
                      id: 438,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:802809',
                      children: [
                        {
                          id: 439,
                          name: 'Body',
                          children: [
                            {
                              id: 440,
                              name: 'Axis',
                              children: [
                                {
                                  id: 441,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 442,
                                      name: '#5628',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 443,
                              name: 'Body',
                              children: [
                                {
                                  id: 444,
                                  name: '#5637',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 150.100336001351,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 150.100336001351,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a2fe0',
                    },
                    {
                      id: 445,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:803036',
                      children: [
                        {
                          id: 446,
                          name: 'Body',
                          children: [
                            {
                              id: 447,
                              name: 'Axis',
                              children: [
                                {
                                  id: 448,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 449,
                                      name: '#5691',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 450,
                              name: 'Body',
                              children: [
                                {
                                  id: 451,
                                  name: '#5700',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 149.993510013466,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 149.993510013466,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a50c5',
                    },
                    {
                      id: 452,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:803140',
                      children: [
                        {
                          id: 453,
                          name: 'Body',
                          children: [
                            {
                              id: 454,
                              name: 'Axis',
                              children: [
                                {
                                  id: 455,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 456,
                                      name: '#5754',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 457,
                              name: 'Body',
                              children: [
                                {
                                  id: 458,
                                  name: '#5763',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 300.077337421496,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 300.077337421496,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a515d',
                    },
                    {
                      id: 459,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:803420',
                      children: [
                        {
                          id: 460,
                          name: 'Body',
                          children: [
                            {
                              id: 461,
                              name: 'Axis',
                              children: [
                                {
                                  id: 462,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 463,
                                      name: '#5817',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 464,
                              name: 'Body',
                              children: [
                                {
                                  id: 465,
                                  name: '#5826',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 1999.99999999998,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1999.99999999998,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a5245',
                    },
                    {
                      id: 466,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:803568',
                      children: [
                        {
                          id: 467,
                          name: 'Body',
                          children: [
                            {
                              id: 468,
                              name: 'Axis',
                              children: [
                                {
                                  id: 469,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 470,
                                      name: '#5880',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 471,
                              name: 'Body',
                              children: [
                                {
                                  id: 472,
                                  name: '#5889',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 399.930116825739,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 399.930116825739,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a52e9',
                    },
                    {
                      id: 473,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:803678',
                      children: [
                        {
                          id: 474,
                          name: 'Body',
                          children: [
                            {
                              id: 475,
                              name: 'Axis',
                              children: [
                                {
                                  id: 476,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 477,
                                      name: '#5943',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 478,
                              name: 'Body',
                              children: [
                                {
                                  id: 479,
                                  name: '#5952',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 550.039092257365,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 550.039092257365,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a5347',
                    },
                    {
                      id: 480,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:803719',
                      children: [
                        {
                          id: 481,
                          name: 'Body',
                          children: [
                            {
                              id: 482,
                              name: 'Axis',
                              children: [
                                {
                                  id: 483,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 484,
                                      name: '#6006',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 485,
                              name: 'Body',
                              children: [
                                {
                                  id: 486,
                                  name: '#6015',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 678.003824249738,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 678.003824249738,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a539e',
                    },
                    {
                      id: 487,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:803825',
                      children: [
                        {
                          id: 488,
                          name: 'Body',
                          children: [
                            {
                              id: 489,
                              name: 'Axis',
                              children: [
                                {
                                  id: 490,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 491,
                                      name: '#6069',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 492,
                              name: 'Body',
                              children: [
                                {
                                  id: 493,
                                  name: '#6078',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5774.99993859732,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a53e8',
                    },
                    {
                      id: 494,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:808827',
                      children: [
                        {
                          id: 495,
                          name: 'Body',
                          children: [
                            {
                              id: 496,
                              name: 'Axis',
                              children: [
                                {
                                  id: 497,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 498,
                                      name: '#6173',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 499,
                              name: 'Body',
                              children: [
                                {
                                  id: 500,
                                  name: '#6182',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 9199.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 6104.99492855914,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a4762',
                    },
                    {
                      id: 501,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:808995',
                      children: [
                        {
                          id: 502,
                          name: 'Body',
                          children: [
                            {
                              id: 503,
                              name: 'Axis',
                              children: [
                                {
                                  id: 504,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 505,
                                      name: '#6277',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 506,
                              name: 'Body',
                              children: [
                                {
                                  id: 507,
                                  name: '#6286',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 9199.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 5045.0050872285,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 9199.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5045.0050872285,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a483a',
                    },
                    {
                      id: 508,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:810293',
                      children: [
                        {
                          id: 509,
                          name: 'Body',
                          children: [
                            {
                              id: 510,
                              name: 'Axis',
                              children: [
                                {
                                  id: 511,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 512,
                                      name: '#6340',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 513,
                              name: 'Body',
                              children: [
                                {
                                  id: 514,
                                  name: '#6349',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 9199.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 6105.00130090533,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a4d2c',
                    },
                    {
                      id: 515,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:810294',
                      children: [
                        {
                          id: 516,
                          name: 'Body',
                          children: [
                            {
                              id: 517,
                              name: 'Axis',
                              children: [
                                {
                                  id: 518,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 519,
                                      name: '#6444',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 520,
                              name: 'Body',
                              children: [
                                {
                                  id: 521,
                                  name: '#6453',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 9199.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 5045.00508722848,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 9199.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5045.00508722848,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a4d2f',
                    },
                    {
                      id: 522,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:810379',
                      children: [
                        {
                          id: 523,
                          name: 'Body',
                          children: [
                            {
                              id: 524,
                              name: 'Axis',
                              children: [
                                {
                                  id: 525,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 526,
                                      name: '#6507',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 527,
                              name: 'Body',
                              children: [
                                {
                                  id: 528,
                                  name: '#6516',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 9199.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 1450.00000188417,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 9199.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1450.00000188417,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a4d92',
                    },
                    {
                      id: 529,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:810516',
                      children: [
                        {
                          id: 530,
                          name: 'Body',
                          children: [
                            {
                              id: 531,
                              name: 'Axis',
                              children: [
                                {
                                  id: 532,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 533,
                                      name: '#6570',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 534,
                              name: 'Body',
                              children: [
                                {
                                  id: 535,
                                  name: '#6579',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 4600.00000000002,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a4e0d',
                    },
                    {
                      id: 536,
                      name: 'Basic Wall:03.02.01.01.04.100_200 Reinf Conc  Wall E05:810711',
                      children: [
                        {
                          id: 537,
                          name: 'Body',
                          children: [
                            {
                              id: 538,
                              name: 'Axis',
                              children: [
                                {
                                  id: 539,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 540,
                                      name: '#6674',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 541,
                              name: 'Body',
                              children: [
                                {
                                  id: 542,
                                  name: '#6683',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 9199.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5550.00510536149,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 200,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a4ece',
                    },
                    {
                      id: 543,
                      name: 'Basic Wall:03.02.01.01.04.100_200 Reinf Conc  Wall E05:810954',
                      children: [
                        {
                          id: 544,
                          name: 'Body',
                          children: [
                            {
                              id: 545,
                              name: 'Axis',
                              children: [
                                {
                                  id: 546,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 547,
                                      name: '#6934',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 548,
                              name: 'Body',
                              children: [
                                {
                                  id: 549,
                                  name: '#6943',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 9199.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5550.00510536146,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 200,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a4fd3',
                    },
                    {
                      id: 550,
                      name: 'Basic Wall:03.02.01.01.04.100_200 Reinf Conc  Wall E05:811000',
                      children: [
                        {
                          id: 551,
                          name: 'Body',
                          children: [
                            {
                              id: 552,
                              name: 'Axis',
                              children: [
                                {
                                  id: 553,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 554,
                                      name: '#7194',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 555,
                              name: 'Body',
                              children: [
                                {
                                  id: 556,
                                  name: '#7203',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 9199.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2399.16247113218,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 200,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a4fe1',
                    },
                    {
                      id: 557,
                      name: 'Basic Wall:03.02.01.01.04.100_200 Reinf Conc  Wall E05:811038',
                      children: [
                        {
                          id: 558,
                          name: 'Body',
                          children: [
                            {
                              id: 559,
                              name: 'Axis',
                              children: [
                                {
                                  id: 560,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 561,
                                      name: '#7298',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 562,
                              name: 'Body',
                              children: [
                                {
                                  id: 563,
                                  name: '#7307',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 9199.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 2799.99261802873,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 200,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 9199.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2799.99261802873,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 200,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a7007',
                    },
                    {
                      id: 564,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:811592',
                      children: [
                        {
                          id: 565,
                          name: 'Body',
                          children: [
                            {
                              id: 566,
                              name: 'Axis',
                              children: [
                                {
                                  id: 567,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 568,
                                      name: '#7361',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 569,
                              name: 'Body',
                              children: [
                                {
                                  id: 570,
                                  name: '#7370',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 3225.84898543338,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a7251',
                    },
                    {
                      id: 571,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:811692',
                      children: [
                        {
                          id: 572,
                          name: 'Body',
                          children: [
                            {
                              id: 573,
                              name: 'Axis',
                              children: [
                                {
                                  id: 574,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 575,
                                      name: '#7465',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 576,
                              name: 'Body',
                              children: [
                                {
                                  id: 577,
                                  name: '#7474',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 4474.99997336871,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a72b5',
                    },
                    {
                      id: 578,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:811860',
                      children: [
                        {
                          id: 579,
                          name: 'Body',
                          children: [
                            {
                              id: 580,
                              name: 'Axis',
                              children: [
                                {
                                  id: 581,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 582,
                                      name: '#7569',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 583,
                              name: 'Body',
                              children: [
                                {
                                  id: 584,
                                  name: '#7578',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 4600.01122249156,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a734d',
                    },
                    {
                      id: 585,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:811981',
                      children: [
                        {
                          id: 586,
                          name: 'Body',
                          children: [
                            {
                              id: 587,
                              name: 'Axis',
                              children: [
                                {
                                  id: 588,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 589,
                                      name: '#7673',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 590,
                              name: 'Body',
                              children: [
                                {
                                  id: 591,
                                  name: '#7682',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 9199.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 1449.98878666905,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 9199.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1449.98878666905,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a73d4',
                    },
                    {
                      id: 592,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:813447',
                      children: [
                        {
                          id: 593,
                          name: 'Body',
                          children: [
                            {
                              id: 594,
                              name: 'Axis',
                              children: [
                                {
                                  id: 595,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 596,
                                      name: '#7736',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 597,
                              name: 'Body',
                              children: [
                                {
                                  id: 598,
                                  name: '#7745',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 5250.00000682181,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5250.00000682181,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a799e',
                    },
                    {
                      id: 599,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:813578',
                      children: [
                        {
                          id: 600,
                          name: 'Body',
                          children: [
                            {
                              id: 601,
                              name: 'Axis',
                              children: [
                                {
                                  id: 602,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 603,
                                      name: '#7799',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 604,
                              name: 'Body',
                              children: [
                                {
                                  id: 605,
                                  name: '#7808',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 4625.00000600958,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 4625.00000600958,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a7a13',
                    },
                    {
                      id: 606,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:813730',
                      children: [
                        {
                          id: 607,
                          name: 'Body',
                          children: [
                            {
                              id: 608,
                              name: 'Axis',
                              children: [
                                {
                                  id: 609,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 610,
                                      name: '#7862',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 611,
                              name: 'Body',
                              children: [
                                {
                                  id: 612,
                                  name: '#7871',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 4474.95845129723,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 4474.95845129723,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a7abb',
                    },
                    {
                      id: 613,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:813836',
                      children: [
                        {
                          id: 614,
                          name: 'Body',
                          children: [
                            {
                              id: 615,
                              name: 'Axis',
                              children: [
                                {
                                  id: 616,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 617,
                                      name: '#7925',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 618,
                              name: 'Body',
                              children: [
                                {
                                  id: 619,
                                  name: '#7934',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 1374.93595920343,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1374.93595920343,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a7b15',
                    },
                    {
                      id: 620,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:814080',
                      children: [
                        {
                          id: 621,
                          name: 'Body',
                          children: [
                            {
                              id: 622,
                              name: 'Axis',
                              children: [
                                {
                                  id: 623,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 624,
                                      name: '#7988',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 625,
                              name: 'Body',
                              children: [
                                {
                                  id: 626,
                                  name: '#7997',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 2100.14655793361,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2100.14655793361,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a7c19',
                    },
                    {
                      id: 627,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:814125',
                      children: [
                        {
                          id: 628,
                          name: 'Body',
                          children: [
                            {
                              id: 629,
                              name: 'Axis',
                              children: [
                                {
                                  id: 630,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 631,
                                      name: '#8051',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 632,
                              name: 'Body',
                              children: [
                                {
                                  id: 633,
                                  name: '#8060',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 825.07509838559,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 825.07509838559,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a7c34',
                    },
                    {
                      id: 634,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:814241',
                      children: [
                        {
                          id: 635,
                          name: 'Body',
                          children: [
                            {
                              id: 636,
                              name: 'Axis',
                              children: [
                                {
                                  id: 637,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 638,
                                      name: '#8114',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 639,
                              name: 'Body',
                              children: [
                                {
                                  id: 640,
                                  name: '#8123',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 875.000000000048,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 875.000000000048,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a7cb8',
                    },
                    {
                      id: 641,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:814405',
                      children: [
                        {
                          id: 642,
                          name: 'Body',
                          children: [
                            {
                              id: 643,
                              name: 'Axis',
                              children: [
                                {
                                  id: 644,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 645,
                                      name: '#8177',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 646,
                              name: 'Body',
                              children: [
                                {
                                  id: 647,
                                  name: '#8186',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 4625.05608208469,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 4625.05608208469,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a7d5c',
                    },
                    {
                      id: 648,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:814514',
                      children: [
                        {
                          id: 649,
                          name: 'Body',
                          children: [
                            {
                              id: 650,
                              name: 'Axis',
                              children: [
                                {
                                  id: 651,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 652,
                                      name: '#8240',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 653,
                              name: 'Body',
                              children: [
                                {
                                  id: 654,
                                  name: '#8249',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 5250.20391982183,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5250.20391982183,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a7dab',
                    },
                    {
                      id: 655,
                      name: 'UB Flange:03.02.01.01.01.040_Structural framing B8_UB254x146x31_G10/117:841967',
                      children: [
                        {
                          id: 656,
                          name: 'Axis',
                          children: [
                            {
                              id: 657,
                              name: 'Body',
                              children: [
                                {
                                  id: 658,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 659,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 660,
                              name: 'Axis',
                              children: [
                                {
                                  id: 661,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 662,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 663,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 8900.0000142933,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 8900.0000142933,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f97608962bf',
                    },
                    {
                      id: 664,
                      name: 'UB Flange:03.02.01.01.01.040_Structural framing B8_UB254x146x31_G10/117:841969',
                      children: [
                        {
                          id: 665,
                          name: 'Axis',
                          children: [
                            {
                              id: 666,
                              name: 'Body',
                              children: [
                                {
                                  id: 667,
                                  name: '#19800',
                                  children: [
                                    {
                                      id: 668,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 669,
                              name: 'Axis',
                              children: [
                                {
                                  id: 670,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 671,
                                      name: '#19803',
                                      children: [
                                        {
                                          id: 672,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 8900.0000142933,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 8900.0000142933,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f97608962a1',
                    },
                    {
                      id: 673,
                      name: 'UB Flange:03.02.01.01.01.040_Structural framing B8_UB254x146x31_G10/117:842145',
                      children: [
                        {
                          id: 674,
                          name: 'Axis',
                          children: [
                            {
                              id: 675,
                              name: 'Body',
                              children: [
                                {
                                  id: 676,
                                  name: '#19849',
                                  children: [
                                    {
                                      id: 677,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 678,
                              name: 'Axis',
                              children: [
                                {
                                  id: 679,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 680,
                                      name: '#19852',
                                      children: [
                                        {
                                          id: 681,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 8900.0000142933,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 8900.0000142933,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f97608963f1',
                    },
                    {
                      id: 682,
                      name: 'UB Flange:03.02.01.01.01.040_Structural framing B8_UB254x146x31_G10/117:842193',
                      children: [
                        {
                          id: 683,
                          name: 'Axis',
                          children: [
                            {
                              id: 684,
                              name: 'Body',
                              children: [
                                {
                                  id: 685,
                                  name: '#19898',
                                  children: [
                                    {
                                      id: 686,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 687,
                              name: 'Axis',
                              children: [
                                {
                                  id: 688,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 689,
                                      name: '#19901',
                                      children: [
                                        {
                                          id: 690,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 8900.0000142933,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 8900.0000142933,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f9760896381',
                    },
                    {
                      id: 691,
                      name: 'UB Flange:03.02.01.01.01.050_Structural framing B1_UB203x133x25_G10/117:844013',
                      children: [
                        {
                          id: 692,
                          name: 'Axis',
                          children: [
                            {
                              id: 693,
                              name: 'Body',
                              children: [
                                {
                                  id: 694,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 695,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 696,
                                          name: '#23766',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 697,
                              name: 'Axis',
                              children: [
                                {
                                  id: 698,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 699,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 700,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 701,
                                              name: '#23780',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5950.00000756893,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5950.00000756893,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f9760895abd',
                    },
                    {
                      id: 702,
                      name: 'UB Flange:03.02.01.01.01.050_Structural framing B1_UB203x133x25_G10/117:844326',
                      children: [
                        {
                          id: 703,
                          name: 'Axis',
                          children: [
                            {
                              id: 704,
                              name: 'Body',
                              children: [
                                {
                                  id: 705,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 706,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 707,
                                          name: '#23876',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 708,
                              name: 'Axis',
                              children: [
                                {
                                  id: 709,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 710,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 711,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 712,
                                              name: '#23890',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1008.20922193122,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1008.20922193122,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f9760895876',
                    },
                    {
                      id: 713,
                      name: 'UB Flange:03.02.01.01.01.050_Structural framing B1_UB203x133x25_G10/117:844372',
                      children: [
                        {
                          id: 714,
                          name: 'Axis',
                          children: [
                            {
                              id: 715,
                              name: 'Body',
                              children: [
                                {
                                  id: 716,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 717,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 718,
                                          name: '#23980',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 719,
                              name: 'Axis',
                              children: [
                                {
                                  id: 720,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 721,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 722,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 723,
                                              name: '#23994',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1008.20922193165,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1008.20922193165,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f9760895804',
                    },
                    {
                      id: 724,
                      name: 'UB Flange:03.02.01.01.01.050_Structural framing B1_UB203x133x25_G10/117:844424',
                      children: [
                        {
                          id: 725,
                          name: 'Axis',
                          children: [
                            {
                              id: 726,
                              name: 'Body',
                              children: [
                                {
                                  id: 727,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 728,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 729,
                                          name: '#24084',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 730,
                              name: 'Axis',
                              children: [
                                {
                                  id: 731,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 732,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 733,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 734,
                                              name: '#24098',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1008.2092219316,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1008.2092219316,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f97608958d8',
                    },
                    {
                      id: 735,
                      name: 'UB Flange:03.02.01.01.01.050_Structural framing B1_UB203x133x25_G10/117:844454',
                      children: [
                        {
                          id: 736,
                          name: 'Axis',
                          children: [
                            {
                              id: 737,
                              name: 'Body',
                              children: [
                                {
                                  id: 738,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 739,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 740,
                                          name: '#24188',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 741,
                              name: 'Axis',
                              children: [
                                {
                                  id: 742,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 743,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 744,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 745,
                                              name: '#24202',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1008.20922193177,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1008.20922193177,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f97608958f6',
                    },
                    {
                      id: 746,
                      name: 'UB Flange:03.02.01.01.01.050_Structural framing B1_UB203x133x25_G10/117:844490',
                      children: [
                        {
                          id: 747,
                          name: 'Axis',
                          children: [
                            {
                              id: 748,
                              name: 'Body',
                              children: [
                                {
                                  id: 749,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 750,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 751,
                                          name: '#24292',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 752,
                              name: 'Axis',
                              children: [
                                {
                                  id: 753,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 754,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 755,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 756,
                                              name: '#24306',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1008.20922193182,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1008.20922193182,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f976089589a',
                    },
                    {
                      id: 757,
                      name: 'UB Flange:03.02.01.01.01.050_Structural framing B1_UB203x133x25_G10/117:844579',
                      children: [
                        {
                          id: 758,
                          name: 'Axis',
                          children: [
                            {
                              id: 759,
                              name: 'Body',
                              children: [
                                {
                                  id: 760,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 761,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 762,
                                          name: '#24396',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 763,
                              name: 'Axis',
                              children: [
                                {
                                  id: 764,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 765,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 766,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 767,
                                              name: '#24410',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1424.96160173664,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1424.96160173664,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f9760895973',
                    },
                    {
                      id: 768,
                      name: 'UB Flange:03.02.01.01.01.050_Structural framing B1_UB203x133x25_G10/117:844672',
                      children: [
                        {
                          id: 769,
                          name: 'Axis',
                          children: [
                            {
                              id: 770,
                              name: 'Body',
                              children: [
                                {
                                  id: 771,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 772,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 773,
                                          name: '#24500',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 774,
                              name: 'Axis',
                              children: [
                                {
                                  id: 775,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 776,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 777,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 778,
                                              name: '#24514',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5999.84976925657,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5999.84976925657,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f97608959d0',
                    },
                    {
                      id: 779,
                      name: 'UB Flange:03.02.01.01.01.050_Structural framing B1_UB203x133x25_G10/117:844703',
                      children: [
                        {
                          id: 780,
                          name: 'Axis',
                          children: [
                            {
                              id: 781,
                              name: 'Body',
                              children: [
                                {
                                  id: 782,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 783,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 784,
                                          name: '#24604',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 785,
                              name: 'Axis',
                              children: [
                                {
                                  id: 786,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 787,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 788,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 789,
                                              name: '#24618',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5999.85675996715,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5999.85675996715,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f97608959cf',
                    },
                    {
                      id: 790,
                      name: 'UB Flange:03.02.01.01.01.050_Structural framing B1_UB203x133x25_G10/117:844726',
                      children: [
                        {
                          id: 791,
                          name: 'Axis',
                          children: [
                            {
                              id: 792,
                              name: 'Body',
                              children: [
                                {
                                  id: 793,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 794,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 795,
                              name: 'Axis',
                              children: [
                                {
                                  id: 796,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 797,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 798,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 6000.00000779634,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 6000.00000779634,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f97608959e6',
                    },
                    {
                      id: 799,
                      name: 'UB Flange:03.02.01.01.01.050_Structural framing B1_UB203x133x25_G10/117:844766',
                      children: [
                        {
                          id: 800,
                          name: 'Axis',
                          children: [
                            {
                              id: 801,
                              name: 'Body',
                              children: [
                                {
                                  id: 802,
                                  name: '#24803',
                                  children: [
                                    {
                                      id: 803,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 804,
                              name: 'Axis',
                              children: [
                                {
                                  id: 805,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 806,
                                      name: '#24806',
                                      children: [
                                        {
                                          id: 807,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 6000.00000779656,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 6000.00000779656,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f976089598e',
                    },
                    {
                      id: 808,
                      name: 'UB Flange:03.02.01.01.01.050_Structural framing B1_UB203x133x25_G10/117:844984',
                      children: [
                        {
                          id: 809,
                          name: 'Axis',
                          children: [
                            {
                              id: 810,
                              name: 'Body',
                              children: [
                                {
                                  id: 811,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 812,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 813,
                                          name: '#24861',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 814,
                              name: 'Axis',
                              children: [
                                {
                                  id: 815,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 816,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 817,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 818,
                                              name: '#24875',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1007.9046268867301,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1007.9046268867301,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f9760895ee8',
                    },
                    {
                      id: 819,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:875465',
                      children: [
                        {
                          id: 820,
                          name: 'Axis',
                          children: [
                            {
                              id: 821,
                              name: 'Body',
                              children: [
                                {
                                  id: 822,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 823,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 824,
                              name: 'Axis',
                              children: [
                                {
                                  id: 825,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 826,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 827,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 31467.3971215938,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 31467.3971215938,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad1847',
                    },
                    {
                      id: 828,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:875968',
                      children: [
                        {
                          id: 829,
                          name: 'Axis',
                          children: [
                            {
                              id: 830,
                              name: 'Body',
                              children: [
                                {
                                  id: 831,
                                  name: '#55409',
                                  children: [
                                    {
                                      id: 832,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 833,
                              name: 'Axis',
                              children: [
                                {
                                  id: 834,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 835,
                                      name: '#55412',
                                      children: [
                                        {
                                          id: 836,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 31467.3971215938,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 31467.3971215938,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad1e4e',
                    },
                    {
                      id: 837,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:875988',
                      children: [
                        {
                          id: 838,
                          name: 'Axis',
                          children: [
                            {
                              id: 839,
                              name: 'Body',
                              children: [
                                {
                                  id: 840,
                                  name: '#55461',
                                  children: [
                                    {
                                      id: 841,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 842,
                              name: 'Axis',
                              children: [
                                {
                                  id: 843,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 844,
                                      name: '#55464',
                                      children: [
                                        {
                                          id: 845,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 31467.3971215938,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 31467.3971215938,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad1e5a',
                    },
                    {
                      id: 846,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:876153',
                      children: [
                        {
                          id: 847,
                          name: 'Axis',
                          children: [
                            {
                              id: 848,
                              name: 'Body',
                              children: [
                                {
                                  id: 849,
                                  name: '#55513',
                                  children: [
                                    {
                                      id: 850,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 851,
                              name: 'Axis',
                              children: [
                                {
                                  id: 852,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 853,
                                      name: '#55516',
                                      children: [
                                        {
                                          id: 854,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 31467.3971215938,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 31467.3971215938,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad1df7',
                    },
                    {
                      id: 855,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:876188',
                      children: [
                        {
                          id: 856,
                          name: 'Axis',
                          children: [
                            {
                              id: 857,
                              name: 'Body',
                              children: [
                                {
                                  id: 858,
                                  name: '#55561',
                                  children: [
                                    {
                                      id: 859,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 860,
                              name: 'Axis',
                              children: [
                                {
                                  id: 861,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 862,
                                      name: '#55564',
                                      children: [
                                        {
                                          id: 863,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 31467.3971215938,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 31467.3971215938,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad1d12',
                    },
                    {
                      id: 864,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:877993',
                      children: [
                        {
                          id: 865,
                          name: 'Body',
                          children: [
                            {
                              id: 866,
                              name: '#55617',
                              children: [],
                              properties: [],
                            },
                            {
                              id: 867,
                              name: 'Composite Part',
                              children: [],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb3300c88',
                    },
                    {
                      id: 868,
                      name: 'SHS-Column:03.02.01.01.01.130_SHS150x8.0 G10:878772',
                      children: [
                        {
                          id: 869,
                          name: 'Body',
                          children: [
                            {
                              id: 870,
                              name: 'Body',
                              children: [
                                {
                                  id: 871,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 872,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb3300195',
                    },
                    {
                      id: 873,
                      name: 'SHS-Column:03.02.01.01.01.130_SHS150x8.0 G10:878798',
                      children: [
                        {
                          id: 874,
                          name: 'Body',
                          children: [
                            {
                              id: 875,
                              name: 'Body',
                              children: [
                                {
                                  id: 876,
                                  name: '#55968',
                                  children: [
                                    {
                                      id: 877,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb33001ef',
                    },
                    {
                      id: 878,
                      name: 'SHS-Column:03.02.01.01.01.130_SHS150x8.0 G10:878831',
                      children: [
                        {
                          id: 879,
                          name: 'Body',
                          children: [
                            {
                              id: 880,
                              name: 'Body',
                              children: [
                                {
                                  id: 881,
                                  name: '#56010',
                                  children: [
                                    {
                                      id: 882,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb33001ce',
                    },
                    {
                      id: 883,
                      name: 'SHS-Column:03.02.01.01.01.130_SHS150x8.0 G10:878859',
                      children: [
                        {
                          id: 884,
                          name: 'Body',
                          children: [
                            {
                              id: 885,
                              name: 'Body',
                              children: [
                                {
                                  id: 886,
                                  name: '#56052',
                                  children: [
                                    {
                                      id: 887,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb330002a',
                    },
                    {
                      id: 888,
                      name: 'SHS-Column:03.02.01.01.01.130_SHS150x8.0 G10:878885',
                      children: [
                        {
                          id: 889,
                          name: 'Body',
                          children: [
                            {
                              id: 890,
                              name: 'Body',
                              children: [
                                {
                                  id: 891,
                                  name: '#56094',
                                  children: [
                                    {
                                      id: 892,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb3300004',
                    },
                    {
                      id: 893,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:879359',
                      children: [
                        {
                          id: 894,
                          name: 'Body',
                          children: [
                            {
                              id: 895,
                              name: '#56144',
                              children: [],
                              properties: [],
                            },
                            {
                              id: 896,
                              name: 'Composite Part',
                              children: [],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb33003de',
                    },
                    {
                      id: 897,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:879669',
                      children: [
                        {
                          id: 898,
                          name: 'Body',
                          children: [
                            {
                              id: 899,
                              name: '#56206',
                              children: [],
                              properties: [],
                            },
                            {
                              id: 900,
                              name: 'Composite Part',
                              children: [],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb3300514',
                    },
                    {
                      id: 901,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:879793',
                      children: [
                        {
                          id: 902,
                          name: 'Body',
                          children: [
                            {
                              id: 903,
                              name: '#56268',
                              children: [],
                              properties: [],
                            },
                            {
                              id: 904,
                              name: 'Composite Part',
                              children: [],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb3300590',
                    },
                    {
                      id: 905,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:879959',
                      children: [
                        {
                          id: 906,
                          name: 'Body',
                          children: [
                            {
                              id: 907,
                              name: '#56330',
                              children: [],
                              properties: [],
                            },
                            {
                              id: 908,
                              name: 'Composite Part',
                              children: [],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb3300476',
                    },
                    {
                      id: 909,
                      name: 'Floor:03.02.01.02.01.030_150mm Reinf Conc Slab Spec NBS E10/135:881145',
                      children: [
                        {
                          id: 910,
                          name: 'FootPrint',
                          children: [
                            {
                              id: 911,
                              name: 'Body',
                              children: [
                                {
                                  id: 912,
                                  name: '#56408',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 913,
                              name: 'FootPrint',
                              children: [
                                {
                                  id: 914,
                                  name: 'FootPrint',
                                  children: [
                                    {
                                      id: 915,
                                      name: '#56421',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Width',
                              displayValue: 150.000000000001,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Width',
                          displayValue: 150.000000000001,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb33018d8',
                    },
                    {
                      id: 916,
                      name: 'SHS-Column:03.02.01.01.01.130_SHS150x8.0 G10:884572',
                      children: [
                        {
                          id: 917,
                          name: 'Body',
                          children: [
                            {
                              id: 918,
                              name: 'Body',
                              children: [
                                {
                                  id: 919,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 920,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb330167d',
                    },
                    {
                      id: 921,
                      name: 'SHS-Column:03.02.01.01.01.130_SHS150x8.0 G10:884994',
                      children: [
                        {
                          id: 922,
                          name: 'Body',
                          children: [
                            {
                              id: 923,
                              name: 'Body',
                              children: [
                                {
                                  id: 924,
                                  name: '#56778',
                                  children: [
                                    {
                                      id: 925,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb330e823',
                    },
                    {
                      id: 926,
                      name: 'SHS-Column:03.02.01.01.01.130_SHS150x8.0 G10:885156',
                      children: [
                        {
                          id: 927,
                          name: 'Body',
                          children: [
                            {
                              id: 928,
                              name: 'Body',
                              children: [
                                {
                                  id: 929,
                                  name: '#56820',
                                  children: [
                                    {
                                      id: 930,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb330e885',
                    },
                    {
                      id: 931,
                      name: 'SHS-Column:03.02.01.01.01.130_SHS150x8.0 G10:885241',
                      children: [
                        {
                          id: 932,
                          name: 'Body',
                          children: [
                            {
                              id: 933,
                              name: 'Body',
                              children: [
                                {
                                  id: 934,
                                  name: '#56862',
                                  children: [
                                    {
                                      id: 935,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb330e8d8',
                    },
                    {
                      id: 936,
                      name: 'SHS-Column:03.02.01.01.01.130_SHS150x8.0 G10:885269',
                      children: [
                        {
                          id: 937,
                          name: 'Body',
                          children: [
                            {
                              id: 938,
                              name: 'Body',
                              children: [
                                {
                                  id: 939,
                                  name: '#56904',
                                  children: [
                                    {
                                      id: 940,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb330eb34',
                    },
                    {
                      id: 941,
                      name: 'SHS-Column:03.02.01.01.01.130_SHS150x8.0 G10:885325',
                      children: [
                        {
                          id: 942,
                          name: 'Body',
                          children: [
                            {
                              id: 943,
                              name: 'Body',
                              children: [
                                {
                                  id: 944,
                                  name: '#56946',
                                  children: [
                                    {
                                      id: 945,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb330eb6c',
                    },
                    {
                      id: 946,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:885620',
                      children: [
                        {
                          id: 947,
                          name: 'Body',
                          children: [
                            {
                              id: 948,
                              name: '#56996',
                              children: [],
                              properties: [],
                            },
                            {
                              id: 949,
                              name: 'Composite Part',
                              children: [],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb330ea55',
                    },
                    {
                      id: 950,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:885783',
                      children: [
                        {
                          id: 951,
                          name: 'Body',
                          children: [
                            {
                              id: 952,
                              name: '#57058',
                              children: [],
                              properties: [],
                            },
                            {
                              id: 953,
                              name: 'Composite Part',
                              children: [],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb330ed36',
                    },
                    {
                      id: 954,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:886265',
                      children: [
                        {
                          id: 955,
                          name: 'Body',
                          children: [
                            {
                              id: 956,
                              name: '#57120',
                              children: [],
                              properties: [],
                            },
                            {
                              id: 957,
                              name: 'Composite Part',
                              children: [],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb330ecd8',
                    },
                    {
                      id: 958,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:886515',
                      children: [
                        {
                          id: 959,
                          name: 'Body',
                          children: [
                            {
                              id: 960,
                              name: '#57182',
                              children: [],
                              properties: [],
                            },
                            {
                              id: 961,
                              name: 'Composite Part',
                              children: [],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb330efd2',
                    },
                    {
                      id: 962,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:886877',
                      children: [
                        {
                          id: 963,
                          name: 'Body',
                          children: [
                            {
                              id: 964,
                              name: 'Axis',
                              children: [
                                {
                                  id: 965,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 966,
                                      name: '#57244',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 967,
                              name: 'Body',
                              children: [
                                {
                                  id: 968,
                                  name: '#57259',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 1815.00000000213,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 890.000000000007,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 1815.00000000213,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 890.000000000007,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'd1740767-de2b-4005-a29d-8bdfb330e17c',
                    },
                    {
                      id: 969,
                      name: 'Basic Wall:03.02.01.01.04.020_Stair Plinth 660mmx1315mm E05:892967',
                      children: [
                        {
                          id: 970,
                          name: 'Body',
                          children: [
                            {
                              id: 971,
                              name: 'Axis',
                              children: [
                                {
                                  id: 972,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 973,
                                      name: '#57313',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 974,
                              name: 'Body',
                              children: [
                                {
                                  id: 975,
                                  name: '#57322',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 386.999999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 1314.99492646184,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 660,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 386.999999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1314.99492646184,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 660,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '173db39f-b178-4355-86f3-a266ee702d96',
                    },
                    {
                      id: 976,
                      name: 'Floor:03.02.03.04.01.000_Concrete Stair Landing Spec NBS E60/182:897829',
                      children: [
                        {
                          id: 977,
                          name: 'FootPrint',
                          children: [
                            {
                              id: 978,
                              name: 'Body',
                              children: [
                                {
                                  id: 979,
                                  name: '#57411',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 980,
                              name: 'FootPrint',
                              children: [
                                {
                                  id: 981,
                                  name: 'FootPrint',
                                  children: [
                                    {
                                      id: 982,
                                      name: '#57424',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Width',
                              displayValue: 249.999999999995,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Width',
                          displayValue: 249.999999999995,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '173db39f-b178-4355-86f3-a266ee703e94',
                    },
                    {
                      id: 983,
                      name: 'Floor:03.02.03.04.01.000_Concrete Stair Landing Spec NBS E60/182:903235',
                      children: [
                        {
                          id: 984,
                          name: 'FootPrint',
                          children: [
                            {
                              id: 985,
                              name: 'Body',
                              children: [
                                {
                                  id: 986,
                                  name: '#57503',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 987,
                              name: 'FootPrint',
                              children: [
                                {
                                  id: 988,
                                  name: 'FootPrint',
                                  children: [
                                    {
                                      id: 989,
                                      name: '#57512',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Width',
                              displayValue: 249.999999999995,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Width',
                          displayValue: 249.999999999995,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'd2454313-258e-4945-ab47-268313550965',
                    },
                    {
                      id: 990,
                      name: 'Basic Wall:03.02.01.01.04.030_Stair Plinth 500mmx1000mm E05:903776',
                      children: [
                        {
                          id: 991,
                          name: 'Body',
                          children: [
                            {
                              id: 992,
                              name: 'Axis',
                              children: [
                                {
                                  id: 993,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 994,
                                      name: '#57580',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 995,
                              name: 'Body',
                              children: [
                                {
                                  id: 996,
                                  name: '#57589',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 225.000000000001,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 1000,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 500,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 225.000000000001,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1000,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 500,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'c225c44a-0bfd-4e12-8d45-17fcb8d72250',
                    },
                    {
                      id: 997,
                      name: 'Basic Wall:03.02.01.01.04.020_Stair Plinth 660mmx1315mm E05:915103',
                      children: [
                        {
                          id: 998,
                          name: 'Body',
                          children: [
                            {
                              id: 999,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1000,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1001,
                                      name: '#57662',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1002,
                              name: 'Body',
                              children: [
                                {
                                  id: 1003,
                                  name: '#57671',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 386.999999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 1314.99999999965,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 660,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 386.999999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1314.99999999965,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 660,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'c225c44a-0bfd-4e12-8d45-17fcb8d71eaf',
                    },
                    {
                      id: 1004,
                      name: 'Floor:03.02.03.04.01.000_Concrete Stair Landing Spec NBS E60/182:924288',
                      children: [
                        {
                          id: 1005,
                          name: 'FootPrint',
                          children: [
                            {
                              id: 1006,
                              name: 'Body',
                              children: [
                                {
                                  id: 1007,
                                  name: '#57741',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1008,
                              name: 'FootPrint',
                              children: [
                                {
                                  id: 1009,
                                  name: 'FootPrint',
                                  children: [
                                    {
                                      id: 1010,
                                      name: '#57754',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Width',
                              displayValue: 249.999999999995,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Width',
                          displayValue: 249.999999999995,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fb852277-5012-4a6c-b8e4-eb09291ce7d3',
                    },
                    {
                      id: 1011,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:930653',
                      children: [
                        {
                          id: 1012,
                          name: 'Body',
                          children: [
                            {
                              id: 1013,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1014,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1015,
                                      name: '#57822',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1016,
                              name: 'Body',
                              children: [
                                {
                                  id: 1017,
                                  name: '#57831',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 865.103021255946,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 865.103021255946,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '67e7e160-e839-4767-a3b5-33b3904efb2d',
                    },
                    {
                      id: 1018,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:930723',
                      children: [
                        {
                          id: 1019,
                          name: 'Body',
                          children: [
                            {
                              id: 1020,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1021,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1022,
                                      name: '#57885',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1023,
                              name: 'Body',
                              children: [
                                {
                                  id: 1024,
                                  name: '#57894',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4325.00000000534,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 3892.00267089632,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4325.00000000534,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 3892.00267089632,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '67e7e160-e839-4767-a3b5-33b3904efbd3',
                    },
                    {
                      id: 1025,
                      name: 'Stair:03.02.03.04.01.010_Concrete Stair E60/182:948782',
                      children: [
                        {
                          id: 1026,
                          name: 'Body',
                          children: [],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: '46a278e2-ec89-4bba-8da7-ee2846d3aa10',
                    },
                    {
                      id: 1027,
                      name: 'Stair:03.02.03.04.01.010_Concrete Stair E60/182:949079',
                      children: [
                        {
                          id: 1028,
                          name: 'FootPrint',
                          children: [
                            {
                              id: 1029,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1030,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1031,
                                      name: '#58111',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1032,
                              name: 'FootPrint',
                              children: [
                                {
                                  id: 1033,
                                  name: '#58139',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: '46a278e2-ec89-4bba-8da7-ee2846d3ab69',
                    },
                    {
                      id: 1034,
                      name: 'Stair:03.02.03.04.01.010_Concrete Stair E60/182:949283',
                      children: [
                        {
                          id: 1035,
                          name: 'Body',
                          children: [
                            {
                              id: 1036,
                              name: 'Composite Part',
                              children: [],
                              properties: [],
                            },
                            {
                              id: 1037,
                              name: '#58791',
                              children: [],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: '46a278e2-ec89-4bba-8da7-ee2846d3ac1d',
                    },
                    {
                      id: 1038,
                      name: 'Stair:03.02.03.04.01.010_Concrete Stair E60/182:949871',
                      children: [
                        {
                          id: 1039,
                          name: 'Body',
                          children: [],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: '46a278e2-ec89-4bba-8da7-ee2846d3ae51',
                    },
                    {
                      id: 1040,
                      name: 'Stair:03.02.03.04.01.010_Concrete Stair E60/182:949933',
                      children: [
                        {
                          id: 1041,
                          name: 'FootPrint',
                          children: [
                            {
                              id: 1042,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1043,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1044,
                                      name: '#59045',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1045,
                              name: 'FootPrint',
                              children: [
                                {
                                  id: 1046,
                                  name: '#59073',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: '46a278e2-ec89-4bba-8da7-ee2846d3ae93',
                    },
                    {
                      id: 1047,
                      name: 'Stair:03.02.03.04.01.010_Concrete Stair E60/182:949943',
                      children: [
                        {
                          id: 1048,
                          name: 'Body',
                          children: [
                            {
                              id: 1049,
                              name: 'Composite Part',
                              children: [],
                              properties: [],
                            },
                            {
                              id: 1050,
                              name: '#59725',
                              children: [],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: '46a278e2-ec89-4bba-8da7-ee2846d3ae89',
                    },
                    {
                      id: 1051,
                      name: 'Stair:03.02.03.04.01.010_Concrete Stair E60/182:953280',
                      children: [
                        {
                          id: 1052,
                          name: 'Body',
                          children: [
                            {
                              id: 1053,
                              name: 'Composite Part',
                              children: [],
                              properties: [],
                            },
                            {
                              id: 1054,
                              name: '#59922',
                              children: [],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: '46a278e2-ec89-4bba-8da7-ee2846d35bfe',
                    },
                    {
                      id: 1055,
                      name: 'Stair:03.02.03.04.01.010_Concrete Stair E60/182:953545',
                      children: [
                        {
                          id: 1056,
                          name: 'Body',
                          children: [],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: '46a278e2-ec89-4bba-8da7-ee2846d35cf7',
                    },
                    {
                      id: 1057,
                      name: 'Stair:03.02.03.04.01.010_Concrete Stair E60/182:953625',
                      children: [
                        {
                          id: 1058,
                          name: 'Body',
                          children: [],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: '46a278e2-ec89-4bba-8da7-ee2846d35d27',
                    },
                  ],
                  properties: [],
                  guid: 'f29c2cb3-6068-4200-8801-a9e6b069464f',
                },
                {
                  id: 1059,
                  name: 'ENTRANCE-1-FIRST',
                  children: [
                    {
                      id: 1060,
                      name: 'Floor:03.02.01.02.01.020_275 Reinf Conc Slab E05:816295',
                      children: [
                        {
                          id: 1061,
                          name: 'FootPrint',
                          children: [
                            {
                              id: 1062,
                              name: 'Body',
                              children: [
                                {
                                  id: 1063,
                                  name: '#8403',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1064,
                              name: 'FootPrint',
                              children: [
                                {
                                  id: 1065,
                                  name: 'FootPrint',
                                  children: [
                                    {
                                      id: 1066,
                                      name: '#8446',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: 'fbf96b50-48a9-499e-a4ee-11035c1a64be',
                    },
                    {
                      id: 1067,
                      name: 'Basic Wall:03.02.01.01.04.100_200 Reinf Conc  Wall E05:817584',
                      children: [
                        {
                          id: 1068,
                          name: 'Body',
                          children: [
                            {
                              id: 1069,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1070,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1071,
                                      name: '#9054',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1072,
                              name: 'Body',
                              children: [
                                {
                                  id: 1073,
                                  name: '#9063',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999473,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 7800.01858733448,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 200,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5649aad',
                    },
                    {
                      id: 1074,
                      name: 'Floor:03.02.01.02.01.020_275 Reinf Conc Slab E05:819839',
                      children: [
                        {
                          id: 1075,
                          name: 'FootPrint',
                          children: [
                            {
                              id: 1076,
                              name: 'Body',
                              children: [
                                {
                                  id: 1077,
                                  name: '#9233',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1078,
                              name: 'FootPrint',
                              children: [
                                {
                                  id: 1079,
                                  name: 'FootPrint',
                                  children: [
                                    {
                                      id: 1080,
                                      name: '#9264',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646162',
                    },
                    {
                      id: 1081,
                      name: 'Floor:03.02.01.02.01.020_275 Reinf Conc Slab E05:820387',
                      children: [
                        {
                          id: 1082,
                          name: 'FootPrint',
                          children: [
                            {
                              id: 1083,
                              name: 'Body',
                              children: [
                                {
                                  id: 1084,
                                  name: '#9514',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1085,
                              name: 'FootPrint',
                              children: [
                                {
                                  id: 1086,
                                  name: 'FootPrint',
                                  children: [
                                    {
                                      id: 1087,
                                      name: '#9545',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56467be',
                    },
                    {
                      id: 1088,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:821212',
                      children: [
                        {
                          id: 1089,
                          name: 'Body',
                          children: [],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56464c1',
                    },
                    {
                      id: 1090,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:821214',
                      children: [
                        {
                          id: 1091,
                          name: 'Body',
                          children: [],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56464c3',
                    },
                    {
                      id: 1092,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:821216',
                      children: [
                        {
                          id: 1093,
                          name: 'Body',
                          children: [],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56464fd',
                    },
                    {
                      id: 1094,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:821218',
                      children: [
                        {
                          id: 1095,
                          name: 'Body',
                          children: [
                            {
                              id: 1096,
                              name: 'Body',
                              children: [
                                {
                                  id: 1097,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1098,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56464ff',
                    },
                    {
                      id: 1099,
                      name: 'Concrete-Square-Column:03.02.01.01.04.010_Reinf Conc Column C2 400x400 E10/135:821220',
                      children: [
                        {
                          id: 1100,
                          name: 'Body',
                          children: [
                            {
                              id: 1101,
                              name: 'Body',
                              children: [
                                {
                                  id: 1102,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1103,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56464f9',
                    },
                    {
                      id: 1104,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:821222',
                      children: [
                        {
                          id: 1105,
                          name: 'Body',
                          children: [
                            {
                              id: 1106,
                              name: 'Body',
                              children: [
                                {
                                  id: 1107,
                                  name: '#10067',
                                  children: [
                                    {
                                      id: 1108,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56464fb',
                    },
                    {
                      id: 1109,
                      name: 'Concrete-Square-Column:03.02.01.01.04.010_Reinf Conc Column C2 400x400 E10/135:821224',
                      children: [
                        {
                          id: 1110,
                          name: 'Body',
                          children: [
                            {
                              id: 1111,
                              name: 'Body',
                              children: [
                                {
                                  id: 1112,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1113,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3249.99999999472,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3249.99999999472,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56464f5',
                    },
                    {
                      id: 1114,
                      name: 'Concrete-Square-Column:03.02.01.01.04.010_Reinf Conc Column C2 400x400 E10/135:821226',
                      children: [
                        {
                          id: 1115,
                          name: 'Body',
                          children: [
                            {
                              id: 1116,
                              name: 'Body',
                              children: [
                                {
                                  id: 1117,
                                  name: '#10189',
                                  children: [
                                    {
                                      id: 1118,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3249.99999999472,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3249.99999999472,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56464f7',
                    },
                    {
                      id: 1119,
                      name: 'Concrete-Square-Column:03.02.01.01.04.010_Reinf Conc Column C2 400x400 E10/135:821228',
                      children: [
                        {
                          id: 1120,
                          name: 'Body',
                          children: [],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3249.99999999472,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3249.99999999472,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56464f1',
                    },
                    {
                      id: 1121,
                      name: 'Concrete-Square-Column:03.02.01.01.04.010_Reinf Conc Column C2 400x400 E10/135:821230',
                      children: [
                        {
                          id: 1122,
                          name: 'Body',
                          children: [],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3524.99999999471,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3524.99999999471,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56464f3',
                    },
                    {
                      id: 1123,
                      name: 'Concrete-Square-Column:03.02.01.01.04.010_Reinf Conc Column C2 400x400 E10/135:821232',
                      children: [
                        {
                          id: 1124,
                          name: 'Body',
                          children: [],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3524.99999999471,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3524.99999999471,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56464ed',
                    },
                    {
                      id: 1125,
                      name: 'Concrete-Square-Column:03.02.01.01.04.010_Reinf Conc Column C2 400x400 E10/135:821234',
                      children: [
                        {
                          id: 1126,
                          name: 'Body',
                          children: [],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3524.99999999471,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3524.99999999471,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56464ef',
                    },
                    {
                      id: 1127,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:821236',
                      children: [
                        {
                          id: 1128,
                          name: 'Body',
                          children: [
                            {
                              id: 1129,
                              name: 'Body',
                              children: [
                                {
                                  id: 1130,
                                  name: '#10545',
                                  children: [
                                    {
                                      id: 1131,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56464e9',
                    },
                    {
                      id: 1132,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:821238',
                      children: [
                        {
                          id: 1133,
                          name: 'Body',
                          children: [
                            {
                              id: 1134,
                              name: 'Body',
                              children: [
                                {
                                  id: 1135,
                                  name: '#10589',
                                  children: [
                                    {
                                      id: 1136,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56464eb',
                    },
                    {
                      id: 1137,
                      name:
                        'Round Column:03.02.01.01.04.040_Reinf Conc Column C4 CiReinf Concular 400mm diameter E10/135:821240',
                      children: [
                        {
                          id: 1138,
                          name: 'Body',
                          children: [],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4599.99999999471,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4599.99999999471,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56464e5',
                    },
                    {
                      id: 1139,
                      name:
                        'Round Column:03.02.01.01.04.040_Reinf Conc Column C4 CiReinf Concular 400mm diameter E10/135:821241',
                      children: [
                        {
                          id: 1140,
                          name: 'Body',
                          children: [],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4599.99999999471,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4599.99999999471,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56464e4',
                    },
                    {
                      id: 1141,
                      name:
                        'Round Column:03.02.01.01.04.040_Reinf Conc Column C4 CiReinf Concular 400mm diameter E10/135:821242',
                      children: [
                        {
                          id: 1142,
                          name: 'Body',
                          children: [],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4599.99999999471,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4599.99999999471,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56464e7',
                    },
                    {
                      id: 1143,
                      name:
                        'Round Column:03.02.01.01.04.040_Reinf Conc Column C4 CiReinf Concular 400mm diameter E10/135:821243',
                      children: [
                        {
                          id: 1144,
                          name: 'Body',
                          children: [],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4599.99999999471,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4599.99999999471,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56464e6',
                    },
                    {
                      id: 1145,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:821245',
                      children: [
                        {
                          id: 1146,
                          name: 'Body',
                          children: [
                            {
                              id: 1147,
                              name: 'Body',
                              children: [
                                {
                                  id: 1148,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1149,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56464e0',
                    },
                    {
                      id: 1150,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:821247',
                      children: [
                        {
                          id: 1151,
                          name: 'Body',
                          children: [
                            {
                              id: 1152,
                              name: 'Body',
                              children: [
                                {
                                  id: 1153,
                                  name: '#11023',
                                  children: [
                                    {
                                      id: 1154,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56464e2',
                    },
                    {
                      id: 1155,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:821251',
                      children: [
                        {
                          id: 1156,
                          name: 'Body',
                          children: [
                            {
                              id: 1157,
                              name: 'Body',
                              children: [
                                {
                                  id: 1158,
                                  name: '#11067',
                                  children: [
                                    {
                                      id: 1159,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b1e',
                    },
                    {
                      id: 1160,
                      name:
                        'Round Column:03.02.01.01.04.040_Reinf Conc Column C4 CiReinf Concular 400mm diameter E10/135:821253',
                      children: [
                        {
                          id: 1161,
                          name: 'Body',
                          children: [],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4599.99999999471,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4599.99999999471,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b18',
                    },
                    {
                      id: 1162,
                      name:
                        'Round Column:03.02.01.01.04.040_Reinf Conc Column C4 CiReinf Concular 400mm diameter E10/135:821254',
                      children: [
                        {
                          id: 1163,
                          name: 'Body',
                          children: [],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4599.99999999471,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4599.99999999471,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b1b',
                    },
                    {
                      id: 1164,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:821255',
                      children: [
                        {
                          id: 1165,
                          name: 'Body',
                          children: [
                            {
                              id: 1166,
                              name: 'Body',
                              children: [
                                {
                                  id: 1167,
                                  name: '#11267',
                                  children: [
                                    {
                                      id: 1168,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b1a',
                    },
                    {
                      id: 1169,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:821257',
                      children: [
                        {
                          id: 1170,
                          name: 'Body',
                          children: [
                            {
                              id: 1171,
                              name: 'Body',
                              children: [
                                {
                                  id: 1172,
                                  name: '#11311',
                                  children: [
                                    {
                                      id: 1173,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b14',
                    },
                    {
                      id: 1174,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:821259',
                      children: [
                        {
                          id: 1175,
                          name: 'Body',
                          children: [
                            {
                              id: 1176,
                              name: 'Body',
                              children: [
                                {
                                  id: 1177,
                                  name: '#11355',
                                  children: [
                                    {
                                      id: 1178,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b16',
                    },
                    {
                      id: 1179,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:821261',
                      children: [
                        {
                          id: 1180,
                          name: 'Body',
                          children: [
                            {
                              id: 1181,
                              name: 'Body',
                              children: [
                                {
                                  id: 1182,
                                  name: '#11399',
                                  children: [
                                    {
                                      id: 1183,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b10',
                    },
                    {
                      id: 1184,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:821263',
                      children: [
                        {
                          id: 1185,
                          name: 'Body',
                          children: [
                            {
                              id: 1186,
                              name: 'Body',
                              children: [
                                {
                                  id: 1187,
                                  name: '#11443',
                                  children: [
                                    {
                                      id: 1188,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b12',
                    },
                    {
                      id: 1189,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:821265',
                      children: [
                        {
                          id: 1190,
                          name: 'Body',
                          children: [
                            {
                              id: 1191,
                              name: 'Body',
                              children: [
                                {
                                  id: 1192,
                                  name: '#11487',
                                  children: [
                                    {
                                      id: 1193,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b0c',
                    },
                    {
                      id: 1194,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:821267',
                      children: [
                        {
                          id: 1195,
                          name: 'Body',
                          children: [
                            {
                              id: 1196,
                              name: 'Body',
                              children: [
                                {
                                  id: 1197,
                                  name: '#11531',
                                  children: [
                                    {
                                      id: 1198,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b0e',
                    },
                    {
                      id: 1199,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:821269',
                      children: [
                        {
                          id: 1200,
                          name: 'Body',
                          children: [
                            {
                              id: 1201,
                              name: 'Body',
                              children: [
                                {
                                  id: 1202,
                                  name: '#11575',
                                  children: [
                                    {
                                      id: 1203,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b08',
                    },
                    {
                      id: 1204,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:821271',
                      children: [
                        {
                          id: 1205,
                          name: 'Body',
                          children: [
                            {
                              id: 1206,
                              name: 'Body',
                              children: [
                                {
                                  id: 1207,
                                  name: '#11619',
                                  children: [
                                    {
                                      id: 1208,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b0a',
                    },
                    {
                      id: 1209,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:821273',
                      children: [
                        {
                          id: 1210,
                          name: 'Body',
                          children: [
                            {
                              id: 1211,
                              name: 'Body',
                              children: [
                                {
                                  id: 1212,
                                  name: '#11663',
                                  children: [
                                    {
                                      id: 1213,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b04',
                    },
                    {
                      id: 1214,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:821275',
                      children: [
                        {
                          id: 1215,
                          name: 'Body',
                          children: [
                            {
                              id: 1216,
                              name: 'Body',
                              children: [
                                {
                                  id: 1217,
                                  name: '#11707',
                                  children: [
                                    {
                                      id: 1218,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b06',
                    },
                    {
                      id: 1219,
                      name: 'Concrete-Square-Column:03.02.01.01.04.070_Reinf Conc Column C6 300x300 E10/135:821277',
                      children: [
                        {
                          id: 1220,
                          name: 'Body',
                          children: [
                            {
                              id: 1221,
                              name: 'Body',
                              children: [
                                {
                                  id: 1222,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1223,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4599.99999999471,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4599.99999999471,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b00',
                    },
                    {
                      id: 1224,
                      name: 'Concrete-Square-Column:03.02.01.01.04.010_Reinf Conc Column C2 400x400 E10/135:821279',
                      children: [
                        {
                          id: 1225,
                          name: 'Body',
                          children: [
                            {
                              id: 1226,
                              name: 'Body',
                              children: [
                                {
                                  id: 1227,
                                  name: '#11829',
                                  children: [
                                    {
                                      id: 1228,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b02',
                    },
                    {
                      id: 1229,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:821281',
                      children: [
                        {
                          id: 1230,
                          name: 'Body',
                          children: [
                            {
                              id: 1231,
                              name: 'Body',
                              children: [
                                {
                                  id: 1232,
                                  name: '#11873',
                                  children: [
                                    {
                                      id: 1233,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b3c',
                    },
                    {
                      id: 1234,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:821283',
                      children: [
                        {
                          id: 1235,
                          name: 'Body',
                          children: [
                            {
                              id: 1236,
                              name: 'Body',
                              children: [
                                {
                                  id: 1237,
                                  name: '#11917',
                                  children: [
                                    {
                                      id: 1238,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b3e',
                    },
                    {
                      id: 1239,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:821285',
                      children: [
                        {
                          id: 1240,
                          name: 'Body',
                          children: [
                            {
                              id: 1241,
                              name: 'Body',
                              children: [
                                {
                                  id: 1242,
                                  name: '#11961',
                                  children: [
                                    {
                                      id: 1243,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b38',
                    },
                    {
                      id: 1244,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:821287',
                      children: [
                        {
                          id: 1245,
                          name: 'Body',
                          children: [
                            {
                              id: 1246,
                              name: 'Body',
                              children: [
                                {
                                  id: 1247,
                                  name: '#12005',
                                  children: [
                                    {
                                      id: 1248,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b3a',
                    },
                    {
                      id: 1249,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:821289',
                      children: [
                        {
                          id: 1250,
                          name: 'Body',
                          children: [
                            {
                              id: 1251,
                              name: 'Body',
                              children: [
                                {
                                  id: 1252,
                                  name: '#12049',
                                  children: [
                                    {
                                      id: 1253,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b34',
                    },
                    {
                      id: 1254,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:821291',
                      children: [
                        {
                          id: 1255,
                          name: 'Body',
                          children: [
                            {
                              id: 1256,
                              name: 'Body',
                              children: [
                                {
                                  id: 1257,
                                  name: '#12093',
                                  children: [
                                    {
                                      id: 1258,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b36',
                    },
                    {
                      id: 1259,
                      name: 'Concrete-Square-Column:03.02.01.01.04.070_Reinf Conc Column C6 300x300 E10/135:821293',
                      children: [
                        {
                          id: 1260,
                          name: 'Body',
                          children: [
                            {
                              id: 1261,
                              name: 'Body',
                              children: [
                                {
                                  id: 1262,
                                  name: '#12137',
                                  children: [
                                    {
                                      id: 1263,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4599.99999999471,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4599.99999999471,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b30',
                    },
                    {
                      id: 1264,
                      name: 'Concrete-Square-Column:03.02.01.01.04.060_Reinf Conc Column C5 200x500 E10/135:821295',
                      children: [
                        {
                          id: 1265,
                          name: 'Body',
                          children: [
                            {
                              id: 1266,
                              name: 'Body',
                              children: [
                                {
                                  id: 1267,
                                  name: '#12181',
                                  children: [
                                    {
                                      id: 1268,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b32',
                    },
                    {
                      id: 1269,
                      name: 'Concrete-Square-Column:03.02.01.01.04.000_Reinf Conc Column C1 350x350 E10/135:821297',
                      children: [
                        {
                          id: 1270,
                          name: 'Body',
                          children: [
                            {
                              id: 1271,
                              name: 'Body',
                              children: [
                                {
                                  id: 1272,
                                  name: '#12225',
                                  children: [
                                    {
                                      id: 1273,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646b2c',
                    },
                    {
                      id: 1274,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:821522',
                      children: [
                        {
                          id: 1275,
                          name: 'Body',
                          children: [
                            {
                              id: 1276,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1277,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1278,
                                      name: '#12277',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1279,
                              name: 'Body',
                              children: [
                                {
                                  id: 1280,
                                  name: '#12286',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4599.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 5625.00000711408,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5625.00000711408,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646a0f',
                    },
                    {
                      id: 1281,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:821523',
                      children: [
                        {
                          id: 1282,
                          name: 'Body',
                          children: [
                            {
                              id: 1283,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1284,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1285,
                                      name: '#12340',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1286,
                              name: 'Body',
                              children: [
                                {
                                  id: 1287,
                                  name: '#12349',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4600.0000000053,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 6624.95514909705,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4600.0000000053,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 6624.95514909705,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646a0e',
                    },
                    {
                      id: 1288,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:821524',
                      children: [
                        {
                          id: 1289,
                          name: 'Body',
                          children: [
                            {
                              id: 1290,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1291,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1292,
                                      name: '#12403',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1293,
                              name: 'Body',
                              children: [
                                {
                                  id: 1294,
                                  name: '#12412',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5149.98981104171,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646a09',
                    },
                    {
                      id: 1295,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:821525',
                      children: [
                        {
                          id: 1296,
                          name: 'Body',
                          children: [
                            {
                              id: 1297,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1298,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1299,
                                      name: '#12507',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1300,
                              name: 'Body',
                              children: [
                                {
                                  id: 1301,
                                  name: '#12516',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 4549.99081836161,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646a08',
                    },
                    {
                      id: 1302,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:821837',
                      children: [
                        {
                          id: 1303,
                          name: 'Body',
                          children: [
                            {
                              id: 1304,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1305,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1306,
                                      name: '#12611',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1307,
                              name: 'Body',
                              children: [
                                {
                                  id: 1308,
                                  name: '#12620',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5650.00000734159,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646950',
                    },
                    {
                      id: 1309,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:821895',
                      children: [
                        {
                          id: 1310,
                          name: 'Body',
                          children: [
                            {
                              id: 1311,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1312,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1313,
                                      name: '#12715',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1314,
                              name: 'Body',
                              children: [
                                {
                                  id: 1315,
                                  name: '#12724',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5650.00000734119,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf564699a',
                    },
                    {
                      id: 1316,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:821980',
                      children: [
                        {
                          id: 1317,
                          name: 'Body',
                          children: [
                            {
                              id: 1318,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1319,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1320,
                                      name: '#12819',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1321,
                              name: 'Body',
                              children: [
                                {
                                  id: 1322,
                                  name: '#12828',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4599.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 2674.95377009102,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2674.95377009102,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56469c1',
                    },
                    {
                      id: 1323,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:821994',
                      children: [
                        {
                          id: 1324,
                          name: 'Body',
                          children: [
                            {
                              id: 1325,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1326,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1327,
                                      name: '#12882',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1328,
                              name: 'Body',
                              children: [
                                {
                                  id: 1329,
                                  name: '#12891',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4599.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 1649.89665936241,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1649.89665936241,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56469f7',
                    },
                    {
                      id: 1330,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:822012',
                      children: [
                        {
                          id: 1331,
                          name: 'Body',
                          children: [
                            {
                              id: 1332,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1333,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1334,
                                      name: '#12945',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1335,
                              name: 'Body',
                              children: [
                                {
                                  id: 1336,
                                  name: '#12954',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4599.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 2775.00382697513,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2775.00382697513,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf56469e1',
                    },
                    {
                      id: 1337,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:822338',
                      children: [
                        {
                          id: 1338,
                          name: 'Body',
                          children: [
                            {
                              id: 1339,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1340,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1341,
                                      name: '#13008',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1342,
                              name: 'Body',
                              children: [
                                {
                                  id: 1343,
                                  name: '#13017',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5600.0000026196,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646f5f',
                    },
                    {
                      id: 1344,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:822441',
                      children: [
                        {
                          id: 1345,
                          name: 'Body',
                          children: [
                            {
                              id: 1346,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1347,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1348,
                                      name: '#13112',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1349,
                              name: 'Body',
                              children: [
                                {
                                  id: 1350,
                                  name: '#13121',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5600.00000727663,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646fb4',
                    },
                    {
                      id: 1351,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:822959',
                      children: [
                        {
                          id: 1352,
                          name: 'Body',
                          children: [
                            {
                              id: 1353,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1354,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1355,
                                      name: '#13216',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1356,
                              name: 'Body',
                              children: [
                                {
                                  id: 1357,
                                  name: '#13225',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5050.00000656179,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646db2',
                    },
                    {
                      id: 1358,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:823014',
                      children: [
                        {
                          id: 1359,
                          name: 'Body',
                          children: [
                            {
                              id: 1360,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1361,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1362,
                                      name: '#13320',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1363,
                              name: 'Body',
                              children: [
                                {
                                  id: 1364,
                                  name: '#13329',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4599.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 5000.00000649692,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5000.00000649692,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646dfb',
                    },
                    {
                      id: 1365,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:823072',
                      children: [
                        {
                          id: 1366,
                          name: 'Body',
                          children: [
                            {
                              id: 1367,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1368,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1369,
                                      name: '#13383',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1370,
                              name: 'Body',
                              children: [
                                {
                                  id: 1371,
                                  name: '#13392',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4599.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 7324.95261970504,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 7324.95261970504,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5646c3d',
                    },
                    {
                      id: 1372,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:823302',
                      children: [
                        {
                          id: 1373,
                          name: 'Body',
                          children: [
                            {
                              id: 1374,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1375,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1376,
                                      name: '#13446',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1377,
                              name: 'Body',
                              children: [
                                {
                                  id: 1378,
                                  name: '#13455',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 3324.34641919939,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf564731b',
                    },
                    {
                      id: 1379,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:823359',
                      children: [
                        {
                          id: 1380,
                          name: 'Body',
                          children: [
                            {
                              id: 1381,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1382,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1383,
                                      name: '#13550',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1384,
                              name: 'Body',
                              children: [
                                {
                                  id: 1385,
                                  name: '#13559',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4599.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 164.999999999862,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 164.999999999862,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5647322',
                    },
                    {
                      id: 1386,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:823667',
                      children: [
                        {
                          id: 1387,
                          name: 'Body',
                          children: [
                            {
                              id: 1388,
                              name: '#13613',
                              children: [],
                              properties: [],
                            },
                            {
                              id: 1389,
                              name: 'Composite Part',
                              children: [],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf564726e',
                    },
                    {
                      id: 1390,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:823668',
                      children: [
                        {
                          id: 1391,
                          name: 'Body',
                          children: [
                            {
                              id: 1392,
                              name: '#13675',
                              children: [],
                              properties: [],
                            },
                            {
                              id: 1393,
                              name: 'Composite Part',
                              children: [],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5647269',
                    },
                    {
                      id: 1394,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:823669',
                      children: [
                        {
                          id: 1395,
                          name: 'Body',
                          children: [
                            {
                              id: 1396,
                              name: '#13737',
                              children: [],
                              properties: [],
                            },
                            {
                              id: 1397,
                              name: 'Composite Part',
                              children: [],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5647268',
                    },
                    {
                      id: 1398,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:823670',
                      children: [
                        {
                          id: 1399,
                          name: 'Body',
                          children: [
                            {
                              id: 1400,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1401,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1402,
                                      name: '#13799',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1403,
                              name: 'Body',
                              children: [
                                {
                                  id: 1404,
                                  name: '#13808',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5649.97148253699,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf564726b',
                    },
                    {
                      id: 1405,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:823671',
                      children: [
                        {
                          id: 1406,
                          name: 'Body',
                          children: [
                            {
                              id: 1407,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1408,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1409,
                                      name: '#13903',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1410,
                              name: 'Body',
                              children: [
                                {
                                  id: 1411,
                                  name: '#13912',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5575.00383723985,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf564726a',
                    },
                    {
                      id: 1412,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:823672',
                      children: [
                        {
                          id: 1413,
                          name: 'Body',
                          children: [
                            {
                              id: 1414,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1415,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1416,
                                      name: '#14007',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1417,
                              name: 'Body',
                              children: [
                                {
                                  id: 1418,
                                  name: '#14016',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2524.96304392593,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5647265',
                    },
                    {
                      id: 1419,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:826838',
                      children: [
                        {
                          id: 1420,
                          name: 'Body',
                          children: [
                            {
                              id: 1421,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1422,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1423,
                                      name: '#14111',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1424,
                              name: 'Body',
                              children: [
                                {
                                  id: 1425,
                                  name: '#14120',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4599.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 2025.10684578964,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2025.10684578964,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5647ecb',
                    },
                    {
                      id: 1426,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:826839',
                      children: [
                        {
                          id: 1427,
                          name: 'Body',
                          children: [
                            {
                              id: 1428,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1429,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1430,
                                      name: '#14174',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1431,
                              name: 'Body',
                              children: [
                                {
                                  id: 1432,
                                  name: '#14183',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4599.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 5050.00000656197,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5050.00000656197,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5647eca',
                    },
                    {
                      id: 1433,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:826840',
                      children: [
                        {
                          id: 1434,
                          name: 'Body',
                          children: [
                            {
                              id: 1435,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1436,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1437,
                                      name: '#14237',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1438,
                              name: 'Body',
                              children: [
                                {
                                  id: 1439,
                                  name: '#14246',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4599.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 6450.00382344356,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 6450.00382344356,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5647ec5',
                    },
                    {
                      id: 1440,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:826841',
                      children: [
                        {
                          id: 1441,
                          name: 'Body',
                          children: [
                            {
                              id: 1442,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1443,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1444,
                                      name: '#14300',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1445,
                              name: 'Body',
                              children: [
                                {
                                  id: 1446,
                                  name: '#14309',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4599.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 5774.99993859732,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5774.99993859732,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5647ec4',
                    },
                    {
                      id: 1447,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:827079',
                      children: [
                        {
                          id: 1448,
                          name: 'Body',
                          children: [
                            {
                              id: 1449,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1450,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1451,
                                      name: '#14363',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1452,
                              name: 'Body',
                              children: [
                                {
                                  id: 1453,
                                  name: '#14372',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 4600.00000000002,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5647dda',
                    },
                    {
                      id: 1454,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:827080',
                      children: [
                        {
                          id: 1455,
                          name: 'Body',
                          children: [
                            {
                              id: 1456,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1457,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1458,
                                      name: '#14467',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1459,
                              name: 'Body',
                              children: [
                                {
                                  id: 1460,
                                  name: '#14476',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4599.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 3225.84898543338,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 3225.84898543338,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5647dd5',
                    },
                    {
                      id: 1461,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:827081',
                      children: [
                        {
                          id: 1462,
                          name: 'Body',
                          children: [
                            {
                              id: 1463,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1464,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1465,
                                      name: '#14530',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1466,
                              name: 'Body',
                              children: [
                                {
                                  id: 1467,
                                  name: '#14539',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 4474.99997336871,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5647dd4',
                    },
                    {
                      id: 1468,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:827082',
                      children: [
                        {
                          id: 1469,
                          name: 'Body',
                          children: [
                            {
                              id: 1470,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1471,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1472,
                                      name: '#14673',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1473,
                              name: 'Body',
                              children: [
                                {
                                  id: 1474,
                                  name: '#14682',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 4600.01122249156,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5647dd7',
                    },
                    {
                      id: 1475,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:827083',
                      children: [
                        {
                          id: 1476,
                          name: 'Body',
                          children: [
                            {
                              id: 1477,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1478,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1479,
                                      name: '#14777',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1480,
                              name: 'Body',
                              children: [
                                {
                                  id: 1481,
                                  name: '#14786',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4599.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 5250.00000682181,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5250.00000682181,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5647dd6',
                    },
                    {
                      id: 1482,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:827084',
                      children: [
                        {
                          id: 1483,
                          name: 'Body',
                          children: [
                            {
                              id: 1484,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1485,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1486,
                                      name: '#14840',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1487,
                              name: 'Body',
                              children: [
                                {
                                  id: 1488,
                                  name: '#14849',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4599.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 4625.00000600958,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 4625.00000600958,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5647dd1',
                    },
                    {
                      id: 1489,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:827085',
                      children: [
                        {
                          id: 1490,
                          name: 'Body',
                          children: [
                            {
                              id: 1491,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1492,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1493,
                                      name: '#14903',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1494,
                              name: 'Body',
                              children: [
                                {
                                  id: 1495,
                                  name: '#14912',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4599.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 4474.96227466597,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 4474.96227466597,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5647dd0',
                    },
                    {
                      id: 1496,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:827086',
                      children: [
                        {
                          id: 1497,
                          name: 'Body',
                          children: [
                            {
                              id: 1498,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1499,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1500,
                                      name: '#14966',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1501,
                              name: 'Body',
                              children: [
                                {
                                  id: 1502,
                                  name: '#14975',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4599.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 3224.99286460437,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 3224.99286460437,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5647dd3',
                    },
                    {
                      id: 1503,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:827087',
                      children: [
                        {
                          id: 1504,
                          name: 'Body',
                          children: [
                            {
                              id: 1505,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1506,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1507,
                                      name: '#15029',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1508,
                              name: 'Body',
                              children: [
                                {
                                  id: 1509,
                                  name: '#15038',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4599.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 4625.05608208469,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 4625.05608208469,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5647dd2',
                    },
                    {
                      id: 1510,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:827088',
                      children: [
                        {
                          id: 1511,
                          name: 'Body',
                          children: [
                            {
                              id: 1512,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1513,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1514,
                                      name: '#15092',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1515,
                              name: 'Body',
                              children: [
                                {
                                  id: 1516,
                                  name: '#15101',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 4599.99999999999,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 5250.20391982183,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 4599.99999999999,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5250.20391982183,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '72bbfbd5-bccb-4202-ba27-8fecf5647dcd',
                    },
                    {
                      id: 1517,
                      name: 'RHS:03.02.01.01.01.220_RHS300X200X8.0 G10/117:834514',
                      children: [
                        {
                          id: 1518,
                          name: 'Axis',
                          children: [
                            {
                              id: 1519,
                              name: 'Body',
                              children: [
                                {
                                  id: 1520,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1521,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1522,
                                          name: '#15370',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1523,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1524,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1525,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1526,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1527,
                                              name: '#15390',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 6285.00262082158,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 6285.00262082158,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '82d379e8-1ea7-4bc3-a964-01229b149861',
                    },
                    {
                      id: 1528,
                      name: 'RHS:03.02.01.01.01.220_RHS300X200X8.0 G10/117:834879',
                      children: [
                        {
                          id: 1529,
                          name: 'Axis',
                          children: [
                            {
                              id: 1530,
                              name: 'Body',
                              children: [
                                {
                                  id: 1531,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1532,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1533,
                                          name: '#15724',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1534,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1535,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1536,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1537,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1538,
                                              name: '#15738',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5205.04101589537,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5205.04101589537,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '82d379e8-1ea7-4bc3-a964-01229b149e8c',
                    },
                    {
                      id: 1539,
                      name: 'RHS:03.02.01.01.01.220_RHS300X200X8.0 G10/117:835402',
                      children: [
                        {
                          id: 1540,
                          name: 'Axis',
                          children: [
                            {
                              id: 1541,
                              name: 'Body',
                              children: [
                                {
                                  id: 1542,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1543,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1544,
                                          name: '#16041',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1545,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1546,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1547,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1548,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1549,
                                              name: '#16055',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 6285.0026208216,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 6285.0026208216,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '82d379e8-1ea7-4bc3-a964-01229b149cf9',
                    },
                    {
                      id: 1550,
                      name: 'RHS:03.02.01.01.01.220_RHS300X200X8.0 G10/117:835404',
                      children: [
                        {
                          id: 1551,
                          name: 'Axis',
                          children: [
                            {
                              id: 1552,
                              name: 'Body',
                              children: [
                                {
                                  id: 1553,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1554,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1555,
                                          name: '#16358',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1556,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1557,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1558,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1559,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1560,
                                              name: '#16372',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5205.04101589537,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5205.04101589537,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '82d379e8-1ea7-4bc3-a964-01229b149cff',
                    },
                    {
                      id: 1561,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:837587',
                      children: [
                        {
                          id: 1562,
                          name: 'Body',
                          children: [
                            {
                              id: 1563,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1564,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1565,
                                      name: '#18938',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1566,
                              name: 'Body',
                              children: [
                                {
                                  id: 1567,
                                  name: '#18947',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 1074.99999999866,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 5625.00000730892,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 1074.99999999866,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5625.00000730892,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3d71ac31-e43c-434a-a5d1-9031720d29e3',
                    },
                    {
                      id: 1568,
                      name: 'Basic Wall:03.02.01.01.04.110_150 Pre-Cast  Wall E60:837819',
                      children: [
                        {
                          id: 1569,
                          name: 'Body',
                          children: [
                            {
                              id: 1570,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1571,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1572,
                                      name: '#19001',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1573,
                              name: 'Body',
                              children: [
                                {
                                  id: 1574,
                                  name: '#19010',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 1074.99999999866,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 5625.00004284488,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 150,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 1074.99999999866,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5625.00004284488,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 150,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3d71ac31-e43c-434a-a5d1-9031720d268b',
                    },
                  ],
                  properties: [],
                  guid: 'f29c2cb3-6068-4200-8801-a9e6b063b2b3',
                },
                {
                  id: 1575,
                  name: 'ENTRANCE-2-SECOND',
                  children: [
                    {
                      id: 1576,
                      name: 'Floor:03.02.01.02.01.020_275 Reinf Conc Slab E05:835649',
                      children: [
                        {
                          id: 1577,
                          name: 'FootPrint',
                          children: [
                            {
                              id: 1578,
                              name: 'Body',
                              children: [
                                {
                                  id: 1579,
                                  name: '#16487',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1580,
                              name: 'FootPrint',
                              children: [
                                {
                                  id: 1581,
                                  name: 'FootPrint',
                                  children: [
                                    {
                                      id: 1582,
                                      name: '#16506',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: '3d71ac31-e43c-434a-a5d1-9031720d2e71',
                    },
                    {
                      id: 1583,
                      name: 'Floor:03.02.01.02.01.000_170 Reinf Conc Eaves Slab E05:835916',
                      children: [
                        {
                          id: 1584,
                          name: 'FootPrint',
                          children: [
                            {
                              id: 1585,
                              name: 'Body',
                              children: [
                                {
                                  id: 1586,
                                  name: '#17239',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1587,
                              name: 'FootPrint',
                              children: [
                                {
                                  id: 1588,
                                  name: 'FootPrint',
                                  children: [
                                    {
                                      id: 1589,
                                      name: '#17258',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [],
                        },
                      ],
                      properties: [],
                      guid: '3d71ac31-e43c-434a-a5d1-9031720d2f7c',
                    },
                    {
                      id: 1590,
                      name: 'Concrete-Rectangular Beam:03.02.01.01.04.080_400x400 Reinf Conc Beam E05:836333',
                      children: [
                        {
                          id: 1591,
                          name: 'Axis',
                          children: [],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5550.00000721155,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5550.00000721155,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3d71ac31-e43c-434a-a5d1-9031720d2cdd',
                    },
                    {
                      id: 1592,
                      name: 'Concrete-Rectangular Beam:03.02.01.01.04.080_400x400 Reinf Conc Beam E05:836626',
                      children: [
                        {
                          id: 1593,
                          name: 'Axis',
                          children: [],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5599.98879232168,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5599.98879232168,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3d71ac31-e43c-434a-a5d1-9031720d2a22',
                    },
                    {
                      id: 1594,
                      name: 'Concrete-Rectangular Beam:03.02.01.01.04.080_400x400 Reinf Conc Beam E05:836685',
                      children: [
                        {
                          id: 1595,
                          name: 'Axis',
                          children: [
                            {
                              id: 1596,
                              name: 'Body',
                              children: [
                                {
                                  id: 1597,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1598,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1599,
                                          name: '#18380',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1600,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1601,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1602,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1603,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1604,
                                              name: '#18394',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5600.01529321499,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5600.01529321499,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3d71ac31-e43c-434a-a5d1-9031720d2a7d',
                    },
                    {
                      id: 1605,
                      name: 'Concrete-Rectangular Beam:03.02.01.01.04.080_400x400 Reinf Conc Beam E05:836720',
                      children: [
                        {
                          id: 1606,
                          name: 'Axis',
                          children: [
                            {
                              id: 1607,
                              name: 'Body',
                              children: [
                                {
                                  id: 1608,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1609,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1610,
                                          name: '#18488',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1611,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1612,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1613,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1614,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1615,
                                              name: '#18502',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4250.05251177117,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4250.05251177117,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3d71ac31-e43c-434a-a5d1-9031720d2a40',
                    },
                    {
                      id: 1616,
                      name: 'Concrete-Rectangular Beam:03.02.01.01.04.080_400x400 Reinf Conc Beam E05:836797',
                      children: [
                        {
                          id: 1617,
                          name: 'Axis',
                          children: [
                            {
                              id: 1618,
                              name: 'Body',
                              children: [
                                {
                                  id: 1619,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1620,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1621,
                                          name: '#18596',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1622,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1623,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1624,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1625,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1626,
                                              name: '#18610',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4250.05251177117,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4250.05251177117,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3d71ac31-e43c-434a-a5d1-9031720d2a8d',
                    },
                    {
                      id: 1627,
                      name: 'Concrete-Rectangular Beam:03.02.01.01.04.080_400x400 Reinf Conc Beam E05:836828',
                      children: [
                        {
                          id: 1628,
                          name: 'Axis',
                          children: [
                            {
                              id: 1629,
                              name: 'Body',
                              children: [
                                {
                                  id: 1630,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1631,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1632,
                                          name: '#18704',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1633,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1634,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1635,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1636,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1637,
                                              name: '#18718',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4250.05251177117,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4250.05251177117,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3d71ac31-e43c-434a-a5d1-9031720d2aec',
                    },
                    {
                      id: 1638,
                      name: 'Floor:03.02.01.02.01.020_275 Reinf Conc Slab E05:836840',
                      children: [
                        {
                          id: 1639,
                          name: 'FootPrint',
                          children: [
                            {
                              id: 1640,
                              name: 'Body',
                              children: [
                                {
                                  id: 1641,
                                  name: '#18841',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1642,
                              name: 'FootPrint',
                              children: [
                                {
                                  id: 1643,
                                  name: 'FootPrint',
                                  children: [
                                    {
                                      id: 1644,
                                      name: '#18870',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Width',
                              displayValue: 275.000000000007,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Width',
                          displayValue: 275.000000000007,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3d71ac31-e43c-434a-a5d1-9031720d2ad8',
                    },
                    {
                      id: 1645,
                      name: 'Concrete-Rectangular Beam:03.02.01.01.04.090_Upstand 500 mm x 1000 mm E10/135:838048',
                      children: [
                        {
                          id: 1646,
                          name: 'Axis',
                          children: [
                            {
                              id: 1647,
                              name: 'Body',
                              children: [
                                {
                                  id: 1648,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1649,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1650,
                                          name: '#19065',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1651,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1652,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1653,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1654,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1655,
                                              name: '#19079',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 18434.0369843115,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 18434.0369843115,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3d71ac31-e43c-434a-a5d1-9031720d2790',
                    },
                    {
                      id: 1656,
                      name: 'Concrete-Rectangular Beam:03.02.01.01.04.090_Upstand 500 mm x 1000 mm E10/135:838343',
                      children: [
                        {
                          id: 1657,
                          name: 'Axis',
                          children: [
                            {
                              id: 1658,
                              name: 'Body',
                              children: [
                                {
                                  id: 1659,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1660,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1661,
                                          name: '#19175',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1662,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1663,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1664,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1665,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1666,
                                              name: '#19189',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 12387.2910076734,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 12387.2910076734,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3d71ac31-e43c-434a-a5d1-9031720d24f7',
                    },
                    {
                      id: 1667,
                      name: 'Basic Wall:03.02.01.01.04.100_200 Reinf Conc  Wall E05:840309',
                      children: [
                        {
                          id: 1668,
                          name: 'Body',
                          children: [
                            {
                              id: 1669,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1670,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1671,
                                      name: '#19278',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1672,
                              name: 'Body',
                              children: [
                                {
                                  id: 1673,
                                  name: '#19287',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 799.999999999991,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 2574.71141944988,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 200,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 799.999999999991,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2574.71141944988,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 200,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f9760896825',
                    },
                    {
                      id: 1674,
                      name: 'Basic Wall:03.02.01.01.04.100_200 Reinf Conc  Wall E05:840310',
                      children: [
                        {
                          id: 1675,
                          name: 'Body',
                          children: [
                            {
                              id: 1676,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1677,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1678,
                                      name: '#19341',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1679,
                              name: 'Body',
                              children: [
                                {
                                  id: 1680,
                                  name: '#19350',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 999.999999999981,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 1249.85864554191,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 200,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 999.999999999981,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1249.85864554191,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 200,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f9760896826',
                    },
                    {
                      id: 1681,
                      name: 'Basic Wall:03.02.01.01.04.100_200 Reinf Conc  Wall E05:840311',
                      children: [
                        {
                          id: 1682,
                          name: 'Body',
                          children: [
                            {
                              id: 1683,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1684,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1685,
                                      name: '#19404',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1686,
                              name: 'Body',
                              children: [
                                {
                                  id: 1687,
                                  name: '#19413',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 999.999999999981,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 1249.92938812365,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 200,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 999.999999999981,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1249.92938812365,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 200,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f9760896827',
                    },
                    {
                      id: 1688,
                      name: 'Basic Wall:03.02.01.01.04.100_200 Reinf Conc  Wall E05:840312',
                      children: [
                        {
                          id: 1689,
                          name: 'Body',
                          children: [
                            {
                              id: 1690,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1691,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1692,
                                      name: '#19467',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1693,
                              name: 'Body',
                              children: [
                                {
                                  id: 1694,
                                  name: '#19476',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 799.999999999991,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 2574.71141944991,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 200,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 799.999999999991,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2574.71141944991,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 200,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f9760896828',
                    },
                    {
                      id: 1695,
                      name: 'Basic Wall:03.02.01.01.04.100_200 Reinf Conc  Wall E05:840666',
                      children: [
                        {
                          id: 1696,
                          name: 'Body',
                          children: [
                            {
                              id: 1697,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1698,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1699,
                                      name: '#19530',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1700,
                              name: 'Body',
                              children: [
                                {
                                  id: 1701,
                                  name: '#19539',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Height',
                              displayValue: 999.999999999981,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Length',
                              displayValue: 2174.71737840879,
                              displayCategory: 'Quantities',
                            },
                            {
                              displayName: 'Width',
                              displayValue: 200,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Height',
                          displayValue: 999.999999999981,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2174.71737840879,
                          displayCategory: 'Quantities',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 200,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f976089698a',
                    },
                    {
                      id: 1702,
                      name: 'Floor:03.02.01.02.01.040_200 Reinf Conc Slab  E10/135:841145',
                      children: [
                        {
                          id: 1703,
                          name: 'FootPrint',
                          children: [
                            {
                              id: 1704,
                              name: 'Body',
                              children: [
                                {
                                  id: 1705,
                                  name: '#19598',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1706,
                              name: 'FootPrint',
                              children: [
                                {
                                  id: 1707,
                                  name: 'FootPrint',
                                  children: [
                                    {
                                      id: 1708,
                                      name: '#19607',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Width',
                              displayValue: 200.000000000007,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Width',
                          displayValue: 200.000000000007,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f9760896fe9',
                    },
                    {
                      id: 1709,
                      name: 'SHS:03.02.01.01.01.210_Structural framing B14_SHS150x5.0_G10/117:842293',
                      children: [
                        {
                          id: 1710,
                          name: 'Axis',
                          children: [
                            {
                              id: 1711,
                              name: 'Body',
                              children: [
                                {
                                  id: 1712,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1713,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1714,
                                          name: '#20169',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1715,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1716,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1717,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1718,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1719,
                                              name: '#20183',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1174.75136574537,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1174.75136574537,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f9760896065',
                    },
                    {
                      id: 1720,
                      name: 'SHS:03.02.01.01.01.210_Structural framing B14_SHS150x5.0_G10/117:842541',
                      children: [
                        {
                          id: 1721,
                          name: 'Axis',
                          children: [
                            {
                              id: 1722,
                              name: 'Body',
                              children: [
                                {
                                  id: 1723,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1724,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1725,
                                          name: '#20492',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1726,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1727,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1728,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1729,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1730,
                                              name: '#20506',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1249.75264450229,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1249.75264450229,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f976089617d',
                    },
                    {
                      id: 1731,
                      name: 'SHS:03.02.01.01.01.210_Structural framing B14_SHS150x5.0_G10/117:842615',
                      children: [
                        {
                          id: 1732,
                          name: 'Axis',
                          children: [
                            {
                              id: 1733,
                              name: 'Body',
                              children: [
                                {
                                  id: 1734,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1735,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1736,
                                          name: '#20809',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1737,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1738,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1739,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1740,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1741,
                                              name: '#20823',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 999.919144073374,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 999.919144073374,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f9760896127',
                    },
                    {
                      id: 1742,
                      name: 'SHS:03.02.01.01.01.210_Structural framing B14_SHS150x5.0_G10/117:842797',
                      children: [
                        {
                          id: 1743,
                          name: 'Axis',
                          children: [
                            {
                              id: 1744,
                              name: 'Body',
                              children: [
                                {
                                  id: 1745,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1746,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1747,
                                          name: '#21126',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1748,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1749,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1750,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1751,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1752,
                                              name: '#21140',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 999.919144073374,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 999.919144073374,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f976089667d',
                    },
                    {
                      id: 1753,
                      name: 'SHS:03.02.01.01.01.210_Structural framing B14_SHS150x5.0_G10/117:842835',
                      children: [
                        {
                          id: 1754,
                          name: 'Axis',
                          children: [
                            {
                              id: 1755,
                              name: 'Body',
                              children: [
                                {
                                  id: 1756,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1757,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1758,
                                          name: '#21443',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1759,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1760,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1761,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1762,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1763,
                                              name: '#21457',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 999.919144073374,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 999.919144073374,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f9760896603',
                    },
                    {
                      id: 1764,
                      name: 'SHS:03.02.01.01.01.210_Structural framing B14_SHS150x5.0_G10/117:842904',
                      children: [
                        {
                          id: 1765,
                          name: 'Axis',
                          children: [
                            {
                              id: 1766,
                              name: 'Body',
                              children: [
                                {
                                  id: 1767,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1768,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1769,
                                          name: '#21760',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1770,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1771,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1772,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1773,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1774,
                                              name: '#21774',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 999.919144073374,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 999.919144073374,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f97608966c8',
                    },
                    {
                      id: 1775,
                      name: 'SHS:03.02.01.01.01.210_Structural framing B14_SHS150x5.0_G10/117:843006',
                      children: [
                        {
                          id: 1776,
                          name: 'Axis',
                          children: [
                            {
                              id: 1777,
                              name: 'Body',
                              children: [
                                {
                                  id: 1778,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1779,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1780,
                                          name: '#22077',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1781,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1782,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1783,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1784,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1785,
                                              name: '#22091',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4625.11239902732,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4625.11239902732,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f97608966ae',
                    },
                    {
                      id: 1786,
                      name: 'SHS:03.02.01.01.01.210_Structural framing B14_SHS150x5.0_G10/117:843155',
                      children: [
                        {
                          id: 1787,
                          name: 'Axis',
                          children: [
                            {
                              id: 1788,
                              name: 'Body',
                              children: [
                                {
                                  id: 1789,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1790,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1791,
                                          name: '#22394',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1792,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1793,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1794,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1795,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1796,
                                              name: '#22408',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1374.94728123632,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1374.94728123632,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f97608967c3',
                    },
                    {
                      id: 1797,
                      name: 'SHS:03.02.01.01.01.210_Structural framing B14_SHS150x5.0_G10/117:843283',
                      children: [
                        {
                          id: 1798,
                          name: 'Axis',
                          children: [
                            {
                              id: 1799,
                              name: 'Body',
                              children: [
                                {
                                  id: 1800,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1801,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1802,
                                          name: '#22711',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1803,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1804,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1805,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1806,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1807,
                                              name: '#22725',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1125.03348559081,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1125.03348559081,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f9760896443',
                    },
                    {
                      id: 1808,
                      name: 'SHS:03.02.01.01.01.210_Structural framing B14_SHS150x5.0_G10/117:843448',
                      children: [
                        {
                          id: 1809,
                          name: 'Axis',
                          children: [
                            {
                              id: 1810,
                              name: 'Body',
                              children: [
                                {
                                  id: 1811,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1812,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1813,
                                          name: '#23028',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1814,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1815,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1816,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1817,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1818,
                                              name: '#23042',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5950.04715849075,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5950.04715849075,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f97608964e8',
                    },
                    {
                      id: 1819,
                      name: 'SHS:03.02.01.01.01.210_Structural framing B14_SHS150x5.0_G10/117:843541',
                      children: [
                        {
                          id: 1820,
                          name: 'Axis',
                          children: [
                            {
                              id: 1821,
                              name: 'Body',
                              children: [
                                {
                                  id: 1822,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1823,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1824,
                                          name: '#23345',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1825,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1826,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1827,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1828,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1829,
                                              name: '#23359',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 6000.00000779646,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 6000.00000779646,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f9760896545',
                    },
                    {
                      id: 1830,
                      name: 'SHS:03.02.01.01.01.210_Structural framing B14_SHS150x5.0_G10/117:843693',
                      children: [
                        {
                          id: 1831,
                          name: 'Axis',
                          children: [
                            {
                              id: 1832,
                              name: 'Body',
                              children: [
                                {
                                  id: 1833,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1834,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1835,
                                          name: '#23662',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1836,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1837,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1838,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1839,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1840,
                                              name: '#23676',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5725.00000743883,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5725.00000743883,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'fd4cfec3-8a88-4818-b152-7f97608965fd',
                    },
                    {
                      id: 1841,
                      name:
                        'PFC-Column:03.02.01.01.01.230_Structural framing PFC 200x75x23 (as columns) G10/117A:847359',
                      children: [
                        {
                          id: 1842,
                          name: 'Body',
                          children: [
                            {
                              id: 1843,
                              name: 'Body',
                              children: [
                                {
                                  id: 1844,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1845,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 244.999999999996,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 244.999999999996,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a78f3e',
                    },
                    {
                      id: 1846,
                      name: 'PFC:03.02.01.01.01.170_Structural framing B15 Cranked_PFC200x75x23_G10/117:847581',
                      children: [
                        {
                          id: 1847,
                          name: 'Axis',
                          children: [
                            {
                              id: 1848,
                              name: 'Body',
                              children: [
                                {
                                  id: 1849,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1850,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1851,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1852,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1853,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1854,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 2579.98676311551,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 2579.98676311551,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a78c1c',
                    },
                    {
                      id: 1855,
                      name:
                        'PFC-Column:03.02.01.01.01.230_Structural framing PFC 200x75x23 (as columns) G10/117A:848014',
                      children: [
                        {
                          id: 1856,
                          name: 'Body',
                          children: [
                            {
                              id: 1857,
                              name: 'Body',
                              children: [
                                {
                                  id: 1858,
                                  name: '#25301',
                                  children: [
                                    {
                                      id: 1859,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 244.999999999996,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 244.999999999996,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a7924f',
                    },
                    {
                      id: 1860,
                      name: 'PFC:03.02.01.01.01.170_Structural framing B15 Cranked_PFC200x75x23_G10/117:848016',
                      children: [
                        {
                          id: 1861,
                          name: 'Axis',
                          children: [
                            {
                              id: 1862,
                              name: 'Body',
                              children: [
                                {
                                  id: 1863,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1864,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1865,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1866,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1867,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1868,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 2579.98676311471,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 2579.98676311471,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79251',
                    },
                    {
                      id: 1869,
                      name:
                        'PFC-Column:03.02.01.01.01.230_Structural framing PFC 200x75x23 (as columns) G10/117A:848550',
                      children: [
                        {
                          id: 1870,
                          name: 'Body',
                          children: [
                            {
                              id: 1871,
                              name: 'Body',
                              children: [
                                {
                                  id: 1872,
                                  name: '#25518',
                                  children: [
                                    {
                                      id: 1873,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 244.999999999996,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 244.999999999996,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79067',
                    },
                    {
                      id: 1874,
                      name: 'PFC:03.02.01.01.01.170_Structural framing B15 Cranked_PFC200x75x23_G10/117:848552',
                      children: [
                        {
                          id: 1875,
                          name: 'Axis',
                          children: [
                            {
                              id: 1876,
                              name: 'Body',
                              children: [
                                {
                                  id: 1877,
                                  name: '#25562',
                                  children: [
                                    {
                                      id: 1878,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1879,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1880,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1881,
                                      name: '#25565',
                                      children: [
                                        {
                                          id: 1882,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 2579.98676311551,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 2579.98676311551,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79069',
                    },
                    {
                      id: 1883,
                      name:
                        'PFC-Column:03.02.01.01.01.230_Structural framing PFC 200x75x23 (as columns) G10/117A:848593',
                      children: [
                        {
                          id: 1884,
                          name: 'Body',
                          children: [
                            {
                              id: 1885,
                              name: 'Body',
                              children: [
                                {
                                  id: 1886,
                                  name: '#25615',
                                  children: [
                                    {
                                      id: 1887,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 244.999999999996,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 244.999999999996,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79010',
                    },
                    {
                      id: 1888,
                      name: 'PFC:03.02.01.01.01.170_Structural framing B15 Cranked_PFC200x75x23_G10/117:848595',
                      children: [
                        {
                          id: 1889,
                          name: 'Axis',
                          children: [
                            {
                              id: 1890,
                              name: 'Body',
                              children: [
                                {
                                  id: 1891,
                                  name: '#25659',
                                  children: [
                                    {
                                      id: 1892,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1893,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1894,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1895,
                                      name: '#25662',
                                      children: [
                                        {
                                          id: 1896,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 2579.98676311471,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 2579.98676311471,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79012',
                    },
                    {
                      id: 1897,
                      name:
                        'PFC-Column:03.02.01.01.01.230_Structural framing PFC 200x75x23 (as columns) G10/117A:848620',
                      children: [
                        {
                          id: 1898,
                          name: 'Body',
                          children: [
                            {
                              id: 1899,
                              name: 'Body',
                              children: [
                                {
                                  id: 1900,
                                  name: '#25712',
                                  children: [
                                    {
                                      id: 1901,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 244.999999999996,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 244.999999999996,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a7902d',
                    },
                    {
                      id: 1902,
                      name: 'PFC:03.02.01.01.01.170_Structural framing B15 Cranked_PFC200x75x23_G10/117:848622',
                      children: [
                        {
                          id: 1903,
                          name: 'Axis',
                          children: [
                            {
                              id: 1904,
                              name: 'Body',
                              children: [
                                {
                                  id: 1905,
                                  name: '#25756',
                                  children: [
                                    {
                                      id: 1906,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1907,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1908,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1909,
                                      name: '#25759',
                                      children: [
                                        {
                                          id: 1910,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 2579.98676311551,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 2579.98676311551,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a7902f',
                    },
                    {
                      id: 1911,
                      name:
                        'PFC-Column:03.02.01.01.01.230_Structural framing PFC 200x75x23 (as columns) G10/117A:848624',
                      children: [
                        {
                          id: 1912,
                          name: 'Body',
                          children: [
                            {
                              id: 1913,
                              name: 'Body',
                              children: [
                                {
                                  id: 1914,
                                  name: '#25809',
                                  children: [
                                    {
                                      id: 1915,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 244.999999999996,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 244.999999999996,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79031',
                    },
                    {
                      id: 1916,
                      name: 'PFC:03.02.01.01.01.170_Structural framing B15 Cranked_PFC200x75x23_G10/117:848626',
                      children: [
                        {
                          id: 1917,
                          name: 'Axis',
                          children: [
                            {
                              id: 1918,
                              name: 'Body',
                              children: [
                                {
                                  id: 1919,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1920,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 1921,
                                          name: '#25927',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1922,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1923,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1924,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1925,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 1926,
                                              name: '#25941',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 2579.98676311471,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 2579.98676311471,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79033',
                    },
                    {
                      id: 1927,
                      name:
                        'UB Flange-Column:03.02.01.01.01.240_Structural steel column S2 UB 203x133x25 G10/117A:848685',
                      children: [
                        {
                          id: 1928,
                          name: 'Body',
                          children: [
                            {
                              id: 1929,
                              name: 'Body',
                              children: [
                                {
                                  id: 1930,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1931,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 244.999999999996,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 244.999999999996,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a791ec',
                    },
                    {
                      id: 1932,
                      name: 'UB Flange:03.02.01.01.01.090_Structural framing B1 Cranked_UB203x133x25_G10/117:848890',
                      children: [
                        {
                          id: 1933,
                          name: 'Axis',
                          children: [
                            {
                              id: 1934,
                              name: 'Body',
                              children: [
                                {
                                  id: 1935,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1936,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1937,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1938,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1939,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1940,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 2579.98676630529,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 2579.98676630529,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a7913b',
                    },
                    {
                      id: 1941,
                      name:
                        'UB Flange-Column:03.02.01.01.01.240_Structural steel column S2 UB 203x133x25 G10/117A:849141',
                      children: [
                        {
                          id: 1942,
                          name: 'Body',
                          children: [
                            {
                              id: 1943,
                              name: 'Body',
                              children: [
                                {
                                  id: 1944,
                                  name: '#26247',
                                  children: [
                                    {
                                      id: 1945,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 244.999999999996,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 244.999999999996,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79634',
                    },
                    {
                      id: 1946,
                      name: 'UB Flange:03.02.01.01.01.090_Structural framing B1 Cranked_UB203x133x25_G10/117:849143',
                      children: [
                        {
                          id: 1947,
                          name: 'Axis',
                          children: [
                            {
                              id: 1948,
                              name: 'Body',
                              children: [
                                {
                                  id: 1949,
                                  name: '#26291',
                                  children: [
                                    {
                                      id: 1950,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1951,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1952,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1953,
                                      name: '#26294',
                                      children: [
                                        {
                                          id: 1954,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 2579.98676630529,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 2579.98676630529,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79636',
                    },
                    {
                      id: 1955,
                      name:
                        'UB Flange-Column:03.02.01.01.01.240_Structural steel column S2 UB 203x133x25 G10/117A:849173',
                      children: [
                        {
                          id: 1956,
                          name: 'Body',
                          children: [
                            {
                              id: 1957,
                              name: 'Body',
                              children: [
                                {
                                  id: 1958,
                                  name: '#26344',
                                  children: [
                                    {
                                      id: 1959,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 244.999999999996,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 244.999999999996,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a797d4',
                    },
                    {
                      id: 1960,
                      name: 'UB Flange:03.02.01.01.01.090_Structural framing B1 Cranked_UB203x133x25_G10/117:849175',
                      children: [
                        {
                          id: 1961,
                          name: 'Axis',
                          children: [
                            {
                              id: 1962,
                              name: 'Body',
                              children: [
                                {
                                  id: 1963,
                                  name: '#26388',
                                  children: [
                                    {
                                      id: 1964,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1965,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1966,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1967,
                                      name: '#26391',
                                      children: [
                                        {
                                          id: 1968,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 2579.98676630529,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 2579.98676630529,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a797d6',
                    },
                    {
                      id: 1969,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:849394',
                      children: [
                        {
                          id: 1970,
                          name: 'Body',
                          children: [
                            {
                              id: 1971,
                              name: 'Body',
                              children: [
                                {
                                  id: 1972,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1973,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1600,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1600,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79733',
                    },
                    {
                      id: 1974,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:849549',
                      children: [
                        {
                          id: 1975,
                          name: 'Body',
                          children: [
                            {
                              id: 1976,
                              name: 'Body',
                              children: [
                                {
                                  id: 1977,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1978,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a7944c',
                    },
                    {
                      id: 1979,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:849654',
                      children: [
                        {
                          id: 1980,
                          name: 'Axis',
                          children: [
                            {
                              id: 1981,
                              name: 'Body',
                              children: [
                                {
                                  id: 1982,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1983,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1984,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1985,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1986,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1987,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5415.90849352956,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5415.90849352956,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79437',
                    },
                    {
                      id: 1988,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:850026',
                      children: [
                        {
                          id: 1989,
                          name: 'Axis',
                          children: [
                            {
                              id: 1990,
                              name: 'Body',
                              children: [
                                {
                                  id: 1991,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 1992,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 1993,
                              name: 'Axis',
                              children: [
                                {
                                  id: 1994,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 1995,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 1996,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 795.000000000011,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 795.000000000011,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79aab',
                    },
                    {
                      id: 1997,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:850217',
                      children: [
                        {
                          id: 1998,
                          name: 'Body',
                          children: [
                            {
                              id: 1999,
                              name: 'Body',
                              children: [
                                {
                                  id: 2000,
                                  name: '#26867',
                                  children: [
                                    {
                                      id: 2001,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1600,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1600,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79be8',
                    },
                    {
                      id: 2002,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:850219',
                      children: [
                        {
                          id: 2003,
                          name: 'Body',
                          children: [
                            {
                              id: 2004,
                              name: 'Body',
                              children: [
                                {
                                  id: 2005,
                                  name: '#26911',
                                  children: [
                                    {
                                      id: 2006,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79bea',
                    },
                    {
                      id: 2007,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:850221',
                      children: [
                        {
                          id: 2008,
                          name: 'Axis',
                          children: [
                            {
                              id: 2009,
                              name: 'Body',
                              children: [
                                {
                                  id: 2010,
                                  name: '#26955',
                                  children: [
                                    {
                                      id: 2011,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2012,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2013,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2014,
                                      name: '#26958',
                                      children: [
                                        {
                                          id: 2015,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5415.90849352956,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5415.90849352956,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79bec',
                    },
                    {
                      id: 2016,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:850223',
                      children: [
                        {
                          id: 2017,
                          name: 'Axis',
                          children: [
                            {
                              id: 2018,
                              name: 'Body',
                              children: [
                                {
                                  id: 2019,
                                  name: '#27008',
                                  children: [
                                    {
                                      id: 2020,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2021,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2022,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2023,
                                      name: '#27011',
                                      children: [
                                        {
                                          id: 2024,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 795.000000000011,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 795.000000000011,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79bee',
                    },
                    {
                      id: 2025,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:850251',
                      children: [
                        {
                          id: 2026,
                          name: 'Body',
                          children: [
                            {
                              id: 2027,
                              name: 'Body',
                              children: [
                                {
                                  id: 2028,
                                  name: '#27057',
                                  children: [
                                    {
                                      id: 2029,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1600,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1600,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79b8a',
                    },
                    {
                      id: 2030,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:850253',
                      children: [
                        {
                          id: 2031,
                          name: 'Body',
                          children: [
                            {
                              id: 2032,
                              name: 'Body',
                              children: [
                                {
                                  id: 2033,
                                  name: '#27101',
                                  children: [
                                    {
                                      id: 2034,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79b8c',
                    },
                    {
                      id: 2035,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:850255',
                      children: [
                        {
                          id: 2036,
                          name: 'Axis',
                          children: [
                            {
                              id: 2037,
                              name: 'Body',
                              children: [
                                {
                                  id: 2038,
                                  name: '#27145',
                                  children: [
                                    {
                                      id: 2039,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2040,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2041,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2042,
                                      name: '#27148',
                                      children: [
                                        {
                                          id: 2043,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5415.90849352956,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5415.90849352956,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79b8e',
                    },
                    {
                      id: 2044,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:850257',
                      children: [
                        {
                          id: 2045,
                          name: 'Axis',
                          children: [
                            {
                              id: 2046,
                              name: 'Body',
                              children: [
                                {
                                  id: 2047,
                                  name: '#27198',
                                  children: [
                                    {
                                      id: 2048,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2049,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2050,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2051,
                                      name: '#27201',
                                      children: [
                                        {
                                          id: 2052,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 795.000000000011,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 795.000000000011,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79b90',
                    },
                    {
                      id: 2053,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:850316',
                      children: [
                        {
                          id: 2054,
                          name: 'Body',
                          children: [
                            {
                              id: 2055,
                              name: 'Body',
                              children: [
                                {
                                  id: 2056,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2057,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1850,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1850,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79b4d',
                    },
                    {
                      id: 2058,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:850318',
                      children: [
                        {
                          id: 2059,
                          name: 'Body',
                          children: [
                            {
                              id: 2060,
                              name: 'Body',
                              children: [
                                {
                                  id: 2061,
                                  name: '#27348',
                                  children: [
                                    {
                                      id: 2062,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79b4f',
                    },
                    {
                      id: 2063,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:850320',
                      children: [
                        {
                          id: 2064,
                          name: 'Axis',
                          children: [
                            {
                              id: 2065,
                              name: 'Body',
                              children: [
                                {
                                  id: 2066,
                                  name: '#27392',
                                  children: [
                                    {
                                      id: 2067,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2068,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2069,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2070,
                                      name: '#27395',
                                      children: [
                                        {
                                          id: 2071,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5415.90849352956,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5415.90849352956,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79b51',
                    },
                    {
                      id: 2072,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:850322',
                      children: [
                        {
                          id: 2073,
                          name: 'Axis',
                          children: [
                            {
                              id: 2074,
                              name: 'Body',
                              children: [
                                {
                                  id: 2075,
                                  name: '#27445',
                                  children: [
                                    {
                                      id: 2076,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2077,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2078,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2079,
                                      name: '#27448',
                                      children: [
                                        {
                                          id: 2080,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 795.000000000011,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 795.000000000011,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79b53',
                    },
                    {
                      id: 2081,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:850396',
                      children: [
                        {
                          id: 2082,
                          name: 'Body',
                          children: [
                            {
                              id: 2083,
                              name: 'Body',
                              children: [
                                {
                                  id: 2084,
                                  name: '#27494',
                                  children: [
                                    {
                                      id: 2085,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1850,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1850,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79b1d',
                    },
                    {
                      id: 2086,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:850398',
                      children: [
                        {
                          id: 2087,
                          name: 'Body',
                          children: [
                            {
                              id: 2088,
                              name: 'Body',
                              children: [
                                {
                                  id: 2089,
                                  name: '#27538',
                                  children: [
                                    {
                                      id: 2090,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79b1f',
                    },
                    {
                      id: 2091,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:850400',
                      children: [
                        {
                          id: 2092,
                          name: 'Axis',
                          children: [
                            {
                              id: 2093,
                              name: 'Body',
                              children: [
                                {
                                  id: 2094,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2095,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2096,
                                          name: '#27591',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2097,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2098,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2099,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2100,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2101,
                                              name: '#27605',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5415.90849352956,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5415.90849352956,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79b21',
                    },
                    {
                      id: 2102,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:850402',
                      children: [
                        {
                          id: 2103,
                          name: 'Axis',
                          children: [
                            {
                              id: 2104,
                              name: 'Body',
                              children: [
                                {
                                  id: 2105,
                                  name: '#27690',
                                  children: [
                                    {
                                      id: 2106,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2107,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2108,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2109,
                                      name: '#27693',
                                      children: [
                                        {
                                          id: 2110,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 795.000000000011,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 795.000000000011,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '54504955-8c3a-41f7-a2a2-64aac3a79b23',
                    },
                    {
                      id: 2111,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:851089',
                      children: [
                        {
                          id: 2112,
                          name: 'Body',
                          children: [
                            {
                              id: 2113,
                              name: 'Body',
                              children: [
                                {
                                  id: 2114,
                                  name: '#27739',
                                  children: [
                                    {
                                      id: 2115,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa512563ab',
                    },
                    {
                      id: 2116,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:851091',
                      children: [
                        {
                          id: 2117,
                          name: 'Axis',
                          children: [
                            {
                              id: 2118,
                              name: 'Body',
                              children: [
                                {
                                  id: 2119,
                                  name: '#27783',
                                  children: [
                                    {
                                      id: 2120,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2121,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2122,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2123,
                                      name: '#27786',
                                      children: [
                                        {
                                          id: 2124,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5415.90849352956,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5415.90849352956,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa512563a9',
                    },
                    {
                      id: 2125,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:851093',
                      children: [
                        {
                          id: 2126,
                          name: 'Axis',
                          children: [
                            {
                              id: 2127,
                              name: 'Body',
                              children: [
                                {
                                  id: 2128,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2129,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2130,
                                          name: '#27845',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2131,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2132,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2133,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2134,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2135,
                                              name: '#27859',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 795.000000000011,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 795.000000000011,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa512563af',
                    },
                    {
                      id: 2136,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:851187',
                      children: [
                        {
                          id: 2137,
                          name: 'Body',
                          children: [
                            {
                              id: 2138,
                              name: 'Body',
                              children: [
                                {
                                  id: 2139,
                                  name: '#27940',
                                  children: [
                                    {
                                      id: 2140,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1850,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1850,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa512563c9',
                    },
                    {
                      id: 2141,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:851353',
                      children: [
                        {
                          id: 2142,
                          name: 'Body',
                          children: [
                            {
                              id: 2143,
                              name: 'Body',
                              children: [
                                {
                                  id: 2144,
                                  name: '#27984',
                                  children: [
                                    {
                                      id: 2145,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1850,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1850,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa512562a3',
                    },
                    {
                      id: 2146,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:851355',
                      children: [
                        {
                          id: 2147,
                          name: 'Body',
                          children: [
                            {
                              id: 2148,
                              name: 'Body',
                              children: [
                                {
                                  id: 2149,
                                  name: '#28028',
                                  children: [
                                    {
                                      id: 2150,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa512562a1',
                    },
                    {
                      id: 2151,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:851357',
                      children: [
                        {
                          id: 2152,
                          name: 'Axis',
                          children: [
                            {
                              id: 2153,
                              name: 'Body',
                              children: [
                                {
                                  id: 2154,
                                  name: '#28072',
                                  children: [
                                    {
                                      id: 2155,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2156,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2157,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2158,
                                      name: '#28075',
                                      children: [
                                        {
                                          id: 2159,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5415.90849352956,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5415.90849352956,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa512562a7',
                    },
                    {
                      id: 2160,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:851359',
                      children: [
                        {
                          id: 2161,
                          name: 'Axis',
                          children: [
                            {
                              id: 2162,
                              name: 'Body',
                              children: [
                                {
                                  id: 2163,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2164,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2165,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2166,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2167,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2168,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 795.000000000011,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 795.000000000011,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa512562a5',
                    },
                    {
                      id: 2169,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:851442',
                      children: [
                        {
                          id: 2170,
                          name: 'Body',
                          children: [
                            {
                              id: 2171,
                              name: 'Body',
                              children: [
                                {
                                  id: 2172,
                                  name: '#28229',
                                  children: [
                                    {
                                      id: 2173,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1850,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1850,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa512562c8',
                    },
                    {
                      id: 2174,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:851444',
                      children: [
                        {
                          id: 2175,
                          name: 'Body',
                          children: [
                            {
                              id: 2176,
                              name: 'Body',
                              children: [
                                {
                                  id: 2177,
                                  name: '#28273',
                                  children: [
                                    {
                                      id: 2178,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa512562ce',
                    },
                    {
                      id: 2179,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:851446',
                      children: [
                        {
                          id: 2180,
                          name: 'Axis',
                          children: [
                            {
                              id: 2181,
                              name: 'Body',
                              children: [
                                {
                                  id: 2182,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2183,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2184,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2185,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2186,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2187,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5415.90849352956,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5415.90849352956,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa512562cc',
                    },
                    {
                      id: 2188,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:851448',
                      children: [
                        {
                          id: 2189,
                          name: 'Axis',
                          children: [
                            {
                              id: 2190,
                              name: 'Body',
                              children: [
                                {
                                  id: 2191,
                                  name: '#28425',
                                  children: [
                                    {
                                      id: 2192,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2193,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2194,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2195,
                                      name: '#28428',
                                      children: [
                                        {
                                          id: 2196,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 795.000000000011,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 795.000000000011,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa512562c2',
                    },
                    {
                      id: 2197,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:851450',
                      children: [
                        {
                          id: 2198,
                          name: 'Body',
                          children: [
                            {
                              id: 2199,
                              name: 'Body',
                              children: [
                                {
                                  id: 2200,
                                  name: '#28474',
                                  children: [
                                    {
                                      id: 2201,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa512562c0',
                    },
                    {
                      id: 2202,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:851452',
                      children: [
                        {
                          id: 2203,
                          name: 'Axis',
                          children: [
                            {
                              id: 2204,
                              name: 'Body',
                              children: [
                                {
                                  id: 2205,
                                  name: '#28518',
                                  children: [
                                    {
                                      id: 2206,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2207,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2208,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2209,
                                      name: '#28521',
                                      children: [
                                        {
                                          id: 2210,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5415.90849352956,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5415.90849352956,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa512562c6',
                    },
                    {
                      id: 2211,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:851454',
                      children: [
                        {
                          id: 2212,
                          name: 'Axis',
                          children: [
                            {
                              id: 2213,
                              name: 'Body',
                              children: [
                                {
                                  id: 2214,
                                  name: '#28571',
                                  children: [
                                    {
                                      id: 2215,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2216,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2217,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2218,
                                      name: '#28574',
                                      children: [
                                        {
                                          id: 2219,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 795.000000000011,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 795.000000000011,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa512562c4',
                    },
                    {
                      id: 2220,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:851533',
                      children: [
                        {
                          id: 2221,
                          name: 'Body',
                          children: [
                            {
                              id: 2222,
                              name: 'Body',
                              children: [
                                {
                                  id: 2223,
                                  name: '#28620',
                                  children: [
                                    {
                                      id: 2224,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1850,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1850,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51256177',
                    },
                    {
                      id: 2225,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:851535',
                      children: [
                        {
                          id: 2226,
                          name: 'Body',
                          children: [
                            {
                              id: 2227,
                              name: 'Body',
                              children: [
                                {
                                  id: 2228,
                                  name: '#28664',
                                  children: [
                                    {
                                      id: 2229,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51256175',
                    },
                    {
                      id: 2230,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:851537',
                      children: [
                        {
                          id: 2231,
                          name: 'Axis',
                          children: [
                            {
                              id: 2232,
                              name: 'Body',
                              children: [
                                {
                                  id: 2233,
                                  name: '#28708',
                                  children: [
                                    {
                                      id: 2234,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2235,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2236,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2237,
                                      name: '#28711',
                                      children: [
                                        {
                                          id: 2238,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5415.90849352956,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5415.90849352956,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa5125616b',
                    },
                    {
                      id: 2239,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:851539',
                      children: [
                        {
                          id: 2240,
                          name: 'Axis',
                          children: [
                            {
                              id: 2241,
                              name: 'Body',
                              children: [
                                {
                                  id: 2242,
                                  name: '#28761',
                                  children: [
                                    {
                                      id: 2243,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2244,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2245,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2246,
                                      name: '#28764',
                                      children: [
                                        {
                                          id: 2247,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 795.000000000011,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 795.000000000011,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51256169',
                    },
                    {
                      id: 2248,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:852139',
                      children: [
                        {
                          id: 2249,
                          name: 'Body',
                          children: [
                            {
                              id: 2250,
                              name: 'Body',
                              children: [
                                {
                                  id: 2251,
                                  name: '#28810',
                                  children: [
                                    {
                                      id: 2252,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249f91',
                    },
                    {
                      id: 2253,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:852141',
                      children: [
                        {
                          id: 2254,
                          name: 'Axis',
                          children: [
                            {
                              id: 2255,
                              name: 'Body',
                              children: [
                                {
                                  id: 2256,
                                  name: '#28854',
                                  children: [
                                    {
                                      id: 2257,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2258,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2259,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2260,
                                      name: '#28857',
                                      children: [
                                        {
                                          id: 2261,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5415.90849352956,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5415.90849352956,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249f97',
                    },
                    {
                      id: 2262,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:852143',
                      children: [
                        {
                          id: 2263,
                          name: 'Axis',
                          children: [
                            {
                              id: 2264,
                              name: 'Body',
                              children: [
                                {
                                  id: 2265,
                                  name: '#28907',
                                  children: [
                                    {
                                      id: 2266,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2267,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2268,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2269,
                                      name: '#28910',
                                      children: [
                                        {
                                          id: 2270,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 795.000000000011,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 795.000000000011,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249f95',
                    },
                    {
                      id: 2271,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:852207',
                      children: [
                        {
                          id: 2272,
                          name: 'Body',
                          children: [
                            {
                              id: 2273,
                              name: 'Body',
                              children: [
                                {
                                  id: 2274,
                                  name: '#28956',
                                  children: [
                                    {
                                      id: 2275,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1850,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1850,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249fd5',
                    },
                    {
                      id: 2276,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:852209',
                      children: [
                        {
                          id: 2277,
                          name: 'Body',
                          children: [
                            {
                              id: 2278,
                              name: 'Body',
                              children: [
                                {
                                  id: 2279,
                                  name: '#29000',
                                  children: [
                                    {
                                      id: 2280,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249fcb',
                    },
                    {
                      id: 2281,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:852211',
                      children: [
                        {
                          id: 2282,
                          name: 'Axis',
                          children: [
                            {
                              id: 2283,
                              name: 'Body',
                              children: [
                                {
                                  id: 2284,
                                  name: '#29044',
                                  children: [
                                    {
                                      id: 2285,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2286,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2287,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2288,
                                      name: '#29047',
                                      children: [
                                        {
                                          id: 2289,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5415.90849352956,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5415.90849352956,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249fc9',
                    },
                    {
                      id: 2290,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:852213',
                      children: [
                        {
                          id: 2291,
                          name: 'Axis',
                          children: [
                            {
                              id: 2292,
                              name: 'Body',
                              children: [
                                {
                                  id: 2293,
                                  name: '#29097',
                                  children: [
                                    {
                                      id: 2294,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2295,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2296,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2297,
                                      name: '#29100',
                                      children: [
                                        {
                                          id: 2298,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 795.000000000011,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 795.000000000011,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249fcf',
                    },
                    {
                      id: 2299,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:852281',
                      children: [
                        {
                          id: 2300,
                          name: 'Body',
                          children: [
                            {
                              id: 2301,
                              name: 'Body',
                              children: [
                                {
                                  id: 2302,
                                  name: '#29146',
                                  children: [
                                    {
                                      id: 2303,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1850,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1850,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249e03',
                    },
                    {
                      id: 2304,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:852283',
                      children: [
                        {
                          id: 2305,
                          name: 'Body',
                          children: [
                            {
                              id: 2306,
                              name: 'Body',
                              children: [
                                {
                                  id: 2307,
                                  name: '#29190',
                                  children: [
                                    {
                                      id: 2308,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249e01',
                    },
                    {
                      id: 2309,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:852285',
                      children: [
                        {
                          id: 2310,
                          name: 'Axis',
                          children: [
                            {
                              id: 2311,
                              name: 'Body',
                              children: [
                                {
                                  id: 2312,
                                  name: '#29234',
                                  children: [
                                    {
                                      id: 2313,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2314,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2315,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2316,
                                      name: '#29237',
                                      children: [
                                        {
                                          id: 2317,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5415.90849352956,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5415.90849352956,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249e07',
                    },
                    {
                      id: 2318,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:852287',
                      children: [
                        {
                          id: 2319,
                          name: 'Axis',
                          children: [
                            {
                              id: 2320,
                              name: 'Body',
                              children: [
                                {
                                  id: 2321,
                                  name: '#29287',
                                  children: [
                                    {
                                      id: 2322,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2323,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2324,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2325,
                                      name: '#29290',
                                      children: [
                                        {
                                          id: 2326,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 795.000000000011,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 795.000000000011,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249e05',
                    },
                    {
                      id: 2327,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:852376',
                      children: [
                        {
                          id: 2328,
                          name: 'Body',
                          children: [
                            {
                              id: 2329,
                              name: 'Body',
                              children: [
                                {
                                  id: 2330,
                                  name: '#29336',
                                  children: [
                                    {
                                      id: 2331,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1850,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1850,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249ea2',
                    },
                    {
                      id: 2332,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:852378',
                      children: [
                        {
                          id: 2333,
                          name: 'Body',
                          children: [
                            {
                              id: 2334,
                              name: 'Body',
                              children: [
                                {
                                  id: 2335,
                                  name: '#29380',
                                  children: [
                                    {
                                      id: 2336,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249ea0',
                    },
                    {
                      id: 2337,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:852380',
                      children: [
                        {
                          id: 2338,
                          name: 'Axis',
                          children: [
                            {
                              id: 2339,
                              name: 'Body',
                              children: [
                                {
                                  id: 2340,
                                  name: '#29424',
                                  children: [
                                    {
                                      id: 2341,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2342,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2343,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2344,
                                      name: '#29427',
                                      children: [
                                        {
                                          id: 2345,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5415.90849352956,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5415.90849352956,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249ea6',
                    },
                    {
                      id: 2346,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:852382',
                      children: [
                        {
                          id: 2347,
                          name: 'Axis',
                          children: [
                            {
                              id: 2348,
                              name: 'Body',
                              children: [
                                {
                                  id: 2349,
                                  name: '#29477',
                                  children: [
                                    {
                                      id: 2350,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2351,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2352,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2353,
                                      name: '#29480',
                                      children: [
                                        {
                                          id: 2354,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 795.000000000011,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 795.000000000011,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249ea4',
                    },
                    {
                      id: 2355,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:852415',
                      children: [
                        {
                          id: 2356,
                          name: 'Body',
                          children: [
                            {
                              id: 2357,
                              name: 'Body',
                              children: [
                                {
                                  id: 2358,
                                  name: '#29526',
                                  children: [
                                    {
                                      id: 2359,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1600,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1600,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249e85',
                    },
                    {
                      id: 2360,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:852417',
                      children: [
                        {
                          id: 2361,
                          name: 'Body',
                          children: [
                            {
                              id: 2362,
                              name: 'Body',
                              children: [
                                {
                                  id: 2363,
                                  name: '#29570',
                                  children: [
                                    {
                                      id: 2364,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249efb',
                    },
                    {
                      id: 2365,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:852419',
                      children: [
                        {
                          id: 2366,
                          name: 'Axis',
                          children: [
                            {
                              id: 2367,
                              name: 'Body',
                              children: [
                                {
                                  id: 2368,
                                  name: '#29614',
                                  children: [
                                    {
                                      id: 2369,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2370,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2371,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2372,
                                      name: '#29617',
                                      children: [
                                        {
                                          id: 2373,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5415.90849352956,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5415.90849352956,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249ef9',
                    },
                    {
                      id: 2374,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:852421',
                      children: [
                        {
                          id: 2375,
                          name: 'Axis',
                          children: [
                            {
                              id: 2376,
                              name: 'Body',
                              children: [
                                {
                                  id: 2377,
                                  name: '#29667',
                                  children: [
                                    {
                                      id: 2378,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2379,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2380,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2381,
                                      name: '#29670',
                                      children: [
                                        {
                                          id: 2382,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 795.000000000011,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 795.000000000011,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249eff',
                    },
                    {
                      id: 2383,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:852453',
                      children: [
                        {
                          id: 2384,
                          name: 'Body',
                          children: [
                            {
                              id: 2385,
                              name: 'Body',
                              children: [
                                {
                                  id: 2386,
                                  name: '#29716',
                                  children: [
                                    {
                                      id: 2387,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1600,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1600,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249edf',
                    },
                    {
                      id: 2388,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:852455',
                      children: [
                        {
                          id: 2389,
                          name: 'Body',
                          children: [
                            {
                              id: 2390,
                              name: 'Body',
                              children: [
                                {
                                  id: 2391,
                                  name: '#29760',
                                  children: [
                                    {
                                      id: 2392,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249edd',
                    },
                    {
                      id: 2393,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:852457',
                      children: [
                        {
                          id: 2394,
                          name: 'Axis',
                          children: [
                            {
                              id: 2395,
                              name: 'Body',
                              children: [
                                {
                                  id: 2396,
                                  name: '#29804',
                                  children: [
                                    {
                                      id: 2397,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2398,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2399,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2400,
                                      name: '#29807',
                                      children: [
                                        {
                                          id: 2401,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5415.90849352956,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5415.90849352956,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249ed3',
                    },
                    {
                      id: 2402,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:852459',
                      children: [
                        {
                          id: 2403,
                          name: 'Axis',
                          children: [
                            {
                              id: 2404,
                              name: 'Body',
                              children: [
                                {
                                  id: 2405,
                                  name: '#29857',
                                  children: [
                                    {
                                      id: 2406,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2407,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2408,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2409,
                                      name: '#29860',
                                      children: [
                                        {
                                          id: 2410,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 795.000000000011,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 795.000000000011,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249ed1',
                    },
                    {
                      id: 2411,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:852618',
                      children: [
                        {
                          id: 2412,
                          name: 'Body',
                          children: [
                            {
                              id: 2413,
                              name: 'Body',
                              children: [
                                {
                                  id: 2414,
                                  name: '#29906',
                                  children: [
                                    {
                                      id: 2415,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1600,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1600,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249db0',
                    },
                    {
                      id: 2416,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:852620',
                      children: [
                        {
                          id: 2417,
                          name: 'Body',
                          children: [
                            {
                              id: 2418,
                              name: 'Body',
                              children: [
                                {
                                  id: 2419,
                                  name: '#29950',
                                  children: [
                                    {
                                      id: 2420,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249db6',
                    },
                    {
                      id: 2421,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:852622',
                      children: [
                        {
                          id: 2422,
                          name: 'Axis',
                          children: [
                            {
                              id: 2423,
                              name: 'Body',
                              children: [
                                {
                                  id: 2424,
                                  name: '#29994',
                                  children: [
                                    {
                                      id: 2425,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2426,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2427,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2428,
                                      name: '#29997',
                                      children: [
                                        {
                                          id: 2429,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5415.90849352956,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5415.90849352956,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249db4',
                    },
                    {
                      id: 2430,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:852624',
                      children: [
                        {
                          id: 2431,
                          name: 'Axis',
                          children: [
                            {
                              id: 2432,
                              name: 'Body',
                              children: [
                                {
                                  id: 2433,
                                  name: '#30047',
                                  children: [
                                    {
                                      id: 2434,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2435,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2436,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2437,
                                      name: '#30050',
                                      children: [
                                        {
                                          id: 2438,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 795.000000000011,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 795.000000000011,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249daa',
                    },
                    {
                      id: 2439,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:852626',
                      children: [
                        {
                          id: 2440,
                          name: 'Body',
                          children: [
                            {
                              id: 2441,
                              name: 'Body',
                              children: [
                                {
                                  id: 2442,
                                  name: '#30096',
                                  children: [
                                    {
                                      id: 2443,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249da8',
                    },
                    {
                      id: 2444,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:852628',
                      children: [
                        {
                          id: 2445,
                          name: 'Axis',
                          children: [
                            {
                              id: 2446,
                              name: 'Body',
                              children: [
                                {
                                  id: 2447,
                                  name: '#30140',
                                  children: [
                                    {
                                      id: 2448,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2449,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2450,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2451,
                                      name: '#30143',
                                      children: [
                                        {
                                          id: 2452,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5415.90849352956,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5415.90849352956,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249dae',
                    },
                    {
                      id: 2453,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:852630',
                      children: [
                        {
                          id: 2454,
                          name: 'Axis',
                          children: [
                            {
                              id: 2455,
                              name: 'Body',
                              children: [
                                {
                                  id: 2456,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2457,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2458,
                                          name: '#30202',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2459,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2460,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2461,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2462,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2463,
                                              name: '#30216',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 795.000000000011,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 795.000000000011,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249dac',
                    },
                    {
                      id: 2464,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:852715',
                      children: [
                        {
                          id: 2465,
                          name: 'Body',
                          children: [
                            {
                              id: 2466,
                              name: 'Body',
                              children: [
                                {
                                  id: 2467,
                                  name: '#30297',
                                  children: [
                                    {
                                      id: 2468,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249dd1',
                    },
                    {
                      id: 2469,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:852717',
                      children: [
                        {
                          id: 2470,
                          name: 'Axis',
                          children: [
                            {
                              id: 2471,
                              name: 'Body',
                              children: [
                                {
                                  id: 2472,
                                  name: '#30341',
                                  children: [
                                    {
                                      id: 2473,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2474,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2475,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2476,
                                      name: '#30344',
                                      children: [
                                        {
                                          id: 2477,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5415.90849352956,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5415.90849352956,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249dd7',
                    },
                    {
                      id: 2478,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:852719',
                      children: [
                        {
                          id: 2479,
                          name: 'Axis',
                          children: [
                            {
                              id: 2480,
                              name: 'Body',
                              children: [
                                {
                                  id: 2481,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2482,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2483,
                                          name: '#30403',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2484,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2485,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2486,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2487,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2488,
                                              name: '#30417',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 799.430854437414,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 799.430854437414,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249dd5',
                    },
                    {
                      id: 2489,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:852901',
                      children: [
                        {
                          id: 2490,
                          name: 'Body',
                          children: [],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1396.99999999999,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1396.99999999999,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249c9f',
                    },
                    {
                      id: 2491,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:853237',
                      children: [
                        {
                          id: 2492,
                          name: 'Body',
                          children: [
                            {
                              id: 2493,
                              name: 'Body',
                              children: [
                                {
                                  id: 2494,
                                  name: '#30599',
                                  children: [
                                    {
                                      id: 2495,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249bcf',
                    },
                    {
                      id: 2496,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:853239',
                      children: [
                        {
                          id: 2497,
                          name: 'Axis',
                          children: [
                            {
                              id: 2498,
                              name: 'Body',
                              children: [
                                {
                                  id: 2499,
                                  name: '#30643',
                                  children: [
                                    {
                                      id: 2500,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2501,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2502,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2503,
                                      name: '#30646',
                                      children: [
                                        {
                                          id: 2504,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5415.90849352956,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5415.90849352956,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249bcd',
                    },
                    {
                      id: 2505,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:853241',
                      children: [
                        {
                          id: 2506,
                          name: 'Axis',
                          children: [
                            {
                              id: 2507,
                              name: 'Body',
                              children: [
                                {
                                  id: 2508,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2509,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2510,
                                          name: '#30705',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2511,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2512,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2513,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2514,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2515,
                                              name: '#30719',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 795.000000000011,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 795.000000000011,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249bc3',
                    },
                    {
                      id: 2516,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:853367',
                      children: [
                        {
                          id: 2517,
                          name: 'Body',
                          children: [
                            {
                              id: 2518,
                              name: 'Body',
                              children: [
                                {
                                  id: 2519,
                                  name: '#30800',
                                  children: [
                                    {
                                      id: 2520,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249a4d',
                    },
                    {
                      id: 2521,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:853369',
                      children: [
                        {
                          id: 2522,
                          name: 'Axis',
                          children: [
                            {
                              id: 2523,
                              name: 'Body',
                              children: [
                                {
                                  id: 2524,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2525,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2526,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2527,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2528,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2529,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 7514.07278425699,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 7514.07278425699,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249a43',
                    },
                    {
                      id: 2530,
                      name: 'PFC:03.02.01.01.01.150_Structural framing B4_PFC200x90x30_G10/117:853456',
                      children: [
                        {
                          id: 2531,
                          name: 'Axis',
                          children: [
                            {
                              id: 2532,
                              name: 'Body',
                              children: [
                                {
                                  id: 2533,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2534,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2535,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2536,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2537,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2538,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 745.000000000005,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 745.000000000005,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51249aea',
                    },
                    {
                      id: 2539,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:854499',
                      children: [
                        {
                          id: 2540,
                          name: 'Axis',
                          children: [
                            {
                              id: 2541,
                              name: 'Body',
                              children: [
                                {
                                  id: 2542,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2543,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2544,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2545,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2546,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2547,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 541.288607662366,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 541.288607662366,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa512496d9',
                    },
                    {
                      id: 2548,
                      name: 'UA:03.02.01.01.01.180_Structural framing L100x100x10 G10/117:856072',
                      children: [
                        {
                          id: 2549,
                          name: 'Axis',
                          children: [
                            {
                              id: 2550,
                              name: 'Body',
                              children: [
                                {
                                  id: 2551,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2552,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2553,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2554,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2555,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2556,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 429.999999999987,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 429.999999999987,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51248f32',
                    },
                    {
                      id: 2557,
                      name: 'UA:03.02.01.01.01.180_Structural framing L100x100x10 G10/117:856334',
                      children: [
                        {
                          id: 2558,
                          name: 'Axis',
                          children: [
                            {
                              id: 2559,
                              name: 'Body',
                              children: [
                                {
                                  id: 2560,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2561,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2562,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2563,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2564,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2565,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 415.000000000001,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 415.000000000001,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51248e34',
                    },
                    {
                      id: 2566,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:856697',
                      children: [
                        {
                          id: 2567,
                          name: 'Body',
                          children: [
                            {
                              id: 2568,
                              name: 'Body',
                              children: [
                                {
                                  id: 2569,
                                  name: '#31561',
                                  children: [
                                    {
                                      id: 2570,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51248d43',
                    },
                    {
                      id: 2571,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:856699',
                      children: [
                        {
                          id: 2572,
                          name: 'Axis',
                          children: [
                            {
                              id: 2573,
                              name: 'Body',
                              children: [
                                {
                                  id: 2574,
                                  name: '#31605',
                                  children: [
                                    {
                                      id: 2575,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2576,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2577,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2578,
                                      name: '#31608',
                                      children: [
                                        {
                                          id: 2579,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 7514.07278425699,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 7514.07278425699,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51248d41',
                    },
                    {
                      id: 2580,
                      name: 'PFC:03.02.01.01.01.150_Structural framing B4_PFC200x90x30_G10/117:856701',
                      children: [
                        {
                          id: 2581,
                          name: 'Axis',
                          children: [
                            {
                              id: 2582,
                              name: 'Body',
                              children: [
                                {
                                  id: 2583,
                                  name: '#31658',
                                  children: [
                                    {
                                      id: 2584,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2585,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2586,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2587,
                                      name: '#31661',
                                      children: [
                                        {
                                          id: 2588,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 745.000000000005,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 745.000000000005,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51248d47',
                    },
                    {
                      id: 2589,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:856705',
                      children: [
                        {
                          id: 2590,
                          name: 'Axis',
                          children: [
                            {
                              id: 2591,
                              name: 'Body',
                              children: [
                                {
                                  id: 2592,
                                  name: '#31707',
                                  children: [
                                    {
                                      id: 2593,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2594,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2595,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2596,
                                      name: '#31710',
                                      children: [
                                        {
                                          id: 2597,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 541.288607662366,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 541.288607662366,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51248dbb',
                    },
                    {
                      id: 2598,
                      name: 'UA:03.02.01.01.01.180_Structural framing L100x100x10 G10/117:856707',
                      children: [
                        {
                          id: 2599,
                          name: 'Axis',
                          children: [
                            {
                              id: 2600,
                              name: 'Body',
                              children: [
                                {
                                  id: 2601,
                                  name: '#31756',
                                  children: [
                                    {
                                      id: 2602,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2603,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2604,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2605,
                                      name: '#31759',
                                      children: [
                                        {
                                          id: 2606,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 429.999999999987,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 429.999999999987,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51248db9',
                    },
                    {
                      id: 2607,
                      name: 'UA:03.02.01.01.01.180_Structural framing L100x100x10 G10/117:856709',
                      children: [
                        {
                          id: 2608,
                          name: 'Axis',
                          children: [
                            {
                              id: 2609,
                              name: 'Body',
                              children: [
                                {
                                  id: 2610,
                                  name: '#31805',
                                  children: [
                                    {
                                      id: 2611,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2612,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2613,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2614,
                                      name: '#31808',
                                      children: [
                                        {
                                          id: 2615,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 415.000000000001,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 415.000000000001,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51248dbf',
                    },
                    {
                      id: 2616,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:856902',
                      children: [
                        {
                          id: 2617,
                          name: 'Body',
                          children: [
                            {
                              id: 2618,
                              name: 'Body',
                              children: [
                                {
                                  id: 2619,
                                  name: '#31854',
                                  children: [
                                    {
                                      id: 2620,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51248c7c',
                    },
                    {
                      id: 2621,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:856904',
                      children: [
                        {
                          id: 2622,
                          name: 'Axis',
                          children: [
                            {
                              id: 2623,
                              name: 'Body',
                              children: [
                                {
                                  id: 2624,
                                  name: '#31898',
                                  children: [
                                    {
                                      id: 2625,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2626,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2627,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2628,
                                      name: '#31901',
                                      children: [
                                        {
                                          id: 2629,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 7514.07278425699,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 7514.07278425699,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51248c72',
                    },
                    {
                      id: 2630,
                      name: 'PFC:03.02.01.01.01.150_Structural framing B4_PFC200x90x30_G10/117:856906',
                      children: [
                        {
                          id: 2631,
                          name: 'Axis',
                          children: [
                            {
                              id: 2632,
                              name: 'Body',
                              children: [
                                {
                                  id: 2633,
                                  name: '#31951',
                                  children: [
                                    {
                                      id: 2634,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2635,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2636,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2637,
                                      name: '#31954',
                                      children: [
                                        {
                                          id: 2638,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 745.000000000005,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 745.000000000005,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51248c70',
                    },
                    {
                      id: 2639,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:856910',
                      children: [
                        {
                          id: 2640,
                          name: 'Axis',
                          children: [
                            {
                              id: 2641,
                              name: 'Body',
                              children: [
                                {
                                  id: 2642,
                                  name: '#32000',
                                  children: [
                                    {
                                      id: 2643,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2644,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2645,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2646,
                                      name: '#32003',
                                      children: [
                                        {
                                          id: 2647,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 541.288607662366,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 541.288607662366,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51248c74',
                    },
                    {
                      id: 2648,
                      name: 'UA:03.02.01.01.01.180_Structural framing L100x100x10 G10/117:856912',
                      children: [
                        {
                          id: 2649,
                          name: 'Axis',
                          children: [
                            {
                              id: 2650,
                              name: 'Body',
                              children: [
                                {
                                  id: 2651,
                                  name: '#32049',
                                  children: [
                                    {
                                      id: 2652,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2653,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2654,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2655,
                                      name: '#32052',
                                      children: [
                                        {
                                          id: 2656,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 429.999999999987,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 429.999999999987,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51248c6a',
                    },
                    {
                      id: 2657,
                      name: 'UA:03.02.01.01.01.180_Structural framing L100x100x10 G10/117:856914',
                      children: [
                        {
                          id: 2658,
                          name: 'Axis',
                          children: [
                            {
                              id: 2659,
                              name: 'Body',
                              children: [
                                {
                                  id: 2660,
                                  name: '#32098',
                                  children: [
                                    {
                                      id: 2661,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2662,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2663,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2664,
                                      name: '#32101',
                                      children: [
                                        {
                                          id: 2665,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 415.000000000001,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 415.000000000001,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51248c68',
                    },
                    {
                      id: 2666,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:857018',
                      children: [
                        {
                          id: 2667,
                          name: 'Body',
                          children: [
                            {
                              id: 2668,
                              name: 'Body',
                              children: [
                                {
                                  id: 2669,
                                  name: '#32147',
                                  children: [
                                    {
                                      id: 2670,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 218.000000000002,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 218.000000000002,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51248c80',
                    },
                    {
                      id: 2671,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:857020',
                      children: [
                        {
                          id: 2672,
                          name: 'Axis',
                          children: [
                            {
                              id: 2673,
                              name: 'Body',
                              children: [
                                {
                                  id: 2674,
                                  name: '#32191',
                                  children: [
                                    {
                                      id: 2675,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2676,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2677,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2678,
                                      name: '#32194',
                                      children: [
                                        {
                                          id: 2679,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 7514.07278425699,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 7514.07278425699,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51248c86',
                    },
                    {
                      id: 2680,
                      name: 'PFC:03.02.01.01.01.150_Structural framing B4_PFC200x90x30_G10/117:857022',
                      children: [
                        {
                          id: 2681,
                          name: 'Axis',
                          children: [
                            {
                              id: 2682,
                              name: 'Body',
                              children: [
                                {
                                  id: 2683,
                                  name: '#32244',
                                  children: [
                                    {
                                      id: 2684,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2685,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2686,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2687,
                                      name: '#32247',
                                      children: [
                                        {
                                          id: 2688,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 745.000000000005,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 745.000000000005,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51248c84',
                    },
                    {
                      id: 2689,
                      name: 'UC Flange:03.02.01.01.01.100_Structural framing B2 Cranked_UC203x203x46_G10/117:857026',
                      children: [
                        {
                          id: 2690,
                          name: 'Axis',
                          children: [
                            {
                              id: 2691,
                              name: 'Body',
                              children: [
                                {
                                  id: 2692,
                                  name: '#32293',
                                  children: [
                                    {
                                      id: 2693,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2694,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2695,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2696,
                                      name: '#32296',
                                      children: [
                                        {
                                          id: 2697,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 541.288607662366,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 541.288607662366,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51248cf8',
                    },
                    {
                      id: 2698,
                      name: 'UA:03.02.01.01.01.180_Structural framing L100x100x10 G10/117:857028',
                      children: [
                        {
                          id: 2699,
                          name: 'Axis',
                          children: [
                            {
                              id: 2700,
                              name: 'Body',
                              children: [
                                {
                                  id: 2701,
                                  name: '#32342',
                                  children: [
                                    {
                                      id: 2702,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2703,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2704,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2705,
                                      name: '#32345',
                                      children: [
                                        {
                                          id: 2706,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 429.999999999987,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 429.999999999987,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51248cfe',
                    },
                    {
                      id: 2707,
                      name: 'UA:03.02.01.01.01.180_Structural framing L100x100x10 G10/117:857030',
                      children: [
                        {
                          id: 2708,
                          name: 'Axis',
                          children: [
                            {
                              id: 2709,
                              name: 'Body',
                              children: [
                                {
                                  id: 2710,
                                  name: '#32391',
                                  children: [
                                    {
                                      id: 2711,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2712,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2713,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2714,
                                      name: '#32394',
                                      children: [
                                        {
                                          id: 2715,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 415.000000000001,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 415.000000000001,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '097cc91a-5072-403f-ade6-a8fa51248cfc',
                    },
                    {
                      id: 2716,
                      name: 'UB Flange:03.02.01.01.01.020_Structural framing B10_UB406x140x46_G10/117:857806',
                      children: [
                        {
                          id: 2717,
                          name: 'Axis',
                          children: [
                            {
                              id: 2718,
                              name: 'Body',
                              children: [
                                {
                                  id: 2719,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2720,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2721,
                                          name: '#32449',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2722,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2723,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2724,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2725,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2726,
                                              name: '#32463',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4970.21912721433,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4970.21912721433,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'de0e808a-55ae-447d-9ab7-adbf0ae3378b',
                    },
                    {
                      id: 2727,
                      name: 'UB Flange:03.02.01.01.01.020_Structural framing B10_UB406x140x46_G10/117:857952',
                      children: [
                        {
                          id: 2728,
                          name: 'Axis',
                          children: [
                            {
                              id: 2729,
                              name: 'Body',
                              children: [
                                {
                                  id: 2730,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2731,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2732,
                                          name: '#32559',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2733,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2734,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2735,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2736,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2737,
                                              name: '#32573',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 6000.00000779621,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 6000.00000779621,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'de0e808a-55ae-447d-9ab7-adbf0ae33625',
                    },
                    {
                      id: 2738,
                      name: 'UB Flange:03.02.01.01.01.020_Structural framing B10_UB406x140x46_G10/117:858073',
                      children: [
                        {
                          id: 2739,
                          name: 'Axis',
                          children: [
                            {
                              id: 2740,
                              name: 'Body',
                              children: [
                                {
                                  id: 2741,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2742,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2743,
                                          name: '#32663',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2744,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2745,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2746,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2747,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2748,
                                              name: '#32677',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 6129.79061090122,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 6129.79061090122,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'de0e808a-55ae-447d-9ab7-adbf0ae3369c',
                    },
                    {
                      id: 2749,
                      name: 'UB Flange:03.02.01.01.01.020_Structural framing B10_UB406x140x46_G10/117:858236',
                      children: [
                        {
                          id: 2750,
                          name: 'Axis',
                          children: [
                            {
                              id: 2751,
                              name: 'Body',
                              children: [
                                {
                                  id: 2752,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2753,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2754,
                                          name: '#32767',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2755,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2756,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2757,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2758,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2759,
                                              name: '#32781',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4970.00000779644,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4970.00000779644,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'de0e808a-55ae-447d-9ab7-adbf0ae33939',
                    },
                    {
                      id: 2760,
                      name: 'UB Flange:03.02.01.01.01.020_Structural framing B10_UB406x140x46_G10/117:858238',
                      children: [
                        {
                          id: 2761,
                          name: 'Axis',
                          children: [
                            {
                              id: 2762,
                              name: 'Body',
                              children: [
                                {
                                  id: 2763,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2764,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2765,
                                          name: '#32871',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2766,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2767,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2768,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2769,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2770,
                                              name: '#32885',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 6000.00000779617,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 6000.00000779617,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'de0e808a-55ae-447d-9ab7-adbf0ae3393b',
                    },
                    {
                      id: 2771,
                      name: 'UB Flange:03.02.01.01.01.020_Structural framing B10_UB406x140x46_G10/117:858240',
                      children: [
                        {
                          id: 2772,
                          name: 'Axis',
                          children: [
                            {
                              id: 2773,
                              name: 'Body',
                              children: [
                                {
                                  id: 2774,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2775,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2776,
                                          name: '#32975',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2777,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2778,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2779,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2780,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2781,
                                              name: '#32989',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 6130.00000779657,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 6130.00000779657,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'de0e808a-55ae-447d-9ab7-adbf0ae339c5',
                    },
                    {
                      id: 2782,
                      name: 'UB Flange:03.02.01.01.000_Structural framing B9_UB406x178x74_G10/117:858523',
                      children: [
                        {
                          id: 2783,
                          name: 'Axis',
                          children: [
                            {
                              id: 2784,
                              name: 'Body',
                              children: [
                                {
                                  id: 2785,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2786,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2787,
                                          name: '#33079',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2788,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2789,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2790,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2791,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2792,
                                              name: '#33093',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 10940,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 10940,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'de0e808a-55ae-447d-9ab7-adbf0ae338de',
                    },
                    {
                      id: 2793,
                      name: 'UB Flange:03.02.01.01.01.050_Structural framing B1_UB203x133x25_G10/117:858835',
                      children: [
                        {
                          id: 2794,
                          name: 'Axis',
                          children: [
                            {
                              id: 2795,
                              name: 'Body',
                              children: [
                                {
                                  id: 2796,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2797,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2798,
                                          name: '#33189',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2799,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2800,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2801,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2802,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2803,
                                              name: '#33203',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5600.00000779658,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5600.00000779658,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'de0e808a-55ae-447d-9ab7-adbf0ae33b96',
                    },
                    {
                      id: 2804,
                      name: 'UB Flange:03.02.01.01.01.050_Structural framing B1_UB203x133x25_G10/117:859029',
                      children: [
                        {
                          id: 2805,
                          name: 'Axis',
                          children: [
                            {
                              id: 2806,
                              name: 'Body',
                              children: [
                                {
                                  id: 2807,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2808,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2809,
                                          name: '#33293',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2810,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2811,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2812,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2813,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2814,
                                              name: '#33307',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5470.45898901964,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5470.45898901964,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'de0e808a-55ae-447d-9ab7-adbf0ae33ad0',
                    },
                    {
                      id: 2815,
                      name: 'UB Flange:03.02.01.01.01.050_Structural framing B1_UB203x133x25_G10/117:859082',
                      children: [
                        {
                          id: 2816,
                          name: 'Axis',
                          children: [
                            {
                              id: 2817,
                              name: 'Body',
                              children: [
                                {
                                  id: 2818,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2819,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2820,
                                          name: '#33397',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2821,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2822,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2823,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2824,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2825,
                                              name: '#33411',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5770.20943875719,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5770.20943875719,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'de0e808a-55ae-447d-9ab7-adbf0ae33a8f',
                    },
                    {
                      id: 2826,
                      name: 'UB Flange:03.02.01.01.01.050_Structural framing B1_UB203x133x25_G10/117:859265',
                      children: [
                        {
                          id: 2827,
                          name: 'Axis',
                          children: [
                            {
                              id: 2828,
                              name: 'Body',
                              children: [
                                {
                                  id: 2829,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2830,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2831,
                                          name: '#33501',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2832,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2833,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2834,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2835,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2836,
                                              name: '#33515',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5469.37046149057,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5469.37046149057,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'de0e808a-55ae-447d-9ab7-adbf0ae33dc4',
                    },
                    {
                      id: 2837,
                      name: 'UB Flange:03.02.01.01.01.050_Structural framing B1_UB203x133x25_G10/117:859385',
                      children: [
                        {
                          id: 2838,
                          name: 'Axis',
                          children: [
                            {
                              id: 2839,
                              name: 'Body',
                              children: [
                                {
                                  id: 2840,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2841,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2842,
                                          name: '#33605',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2843,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2844,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2845,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2846,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2847,
                                              name: '#33619',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5600.00000779658,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5600.00000779658,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'de0e808a-55ae-447d-9ab7-adbf0ae33dbc',
                    },
                    {
                      id: 2848,
                      name: 'UB Flange:03.02.01.01.01.050_Structural framing B1_UB203x133x25_G10/117:859387',
                      children: [
                        {
                          id: 2849,
                          name: 'Axis',
                          children: [
                            {
                              id: 2850,
                              name: 'Body',
                              children: [
                                {
                                  id: 2851,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2852,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2853,
                                          name: '#33709',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2854,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2855,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2856,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2857,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2858,
                                              name: '#33723',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5770.20943875719,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5770.20943875719,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'de0e808a-55ae-447d-9ab7-adbf0ae33dbe',
                    },
                    {
                      id: 2859,
                      name: 'UB Flange:03.02.01.01.01.050_Structural framing B1_UB203x133x25_G10/117:859389',
                      children: [
                        {
                          id: 2860,
                          name: 'Axis',
                          children: [
                            {
                              id: 2861,
                              name: 'Body',
                              children: [
                                {
                                  id: 2862,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2863,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2864,
                                          name: '#33813',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2865,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2866,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2867,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2868,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2869,
                                              name: '#33827',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5469.37046149057,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5469.37046149057,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'de0e808a-55ae-447d-9ab7-adbf0ae33db8',
                    },
                    {
                      id: 2870,
                      name: 'UB Flange:03.02.01.01.01.050_Structural framing B1_UB203x133x25_G10/117:859448',
                      children: [
                        {
                          id: 2871,
                          name: 'Axis',
                          children: [
                            {
                              id: 2872,
                              name: 'Body',
                              children: [
                                {
                                  id: 2873,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2874,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2875,
                                          name: '#33917',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2876,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2877,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2878,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2879,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2880,
                                              name: '#33931',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5470.4448440156,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5470.4448440156,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'de0e808a-55ae-447d-9ab7-adbf0ae33c7d',
                    },
                    {
                      id: 2881,
                      name: 'UB Flange:03.02.01.01.000_Structural framing B9_UB406x178x74_G10/117:859947',
                      children: [
                        {
                          id: 2882,
                          name: 'Axis',
                          children: [
                            {
                              id: 2883,
                              name: 'Body',
                              children: [
                                {
                                  id: 2884,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2885,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2886,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2887,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2888,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2889,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 10941.526497479,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 10941.526497479,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'e861d2e7-b2bf-4378-bbd3-c2060f33c91e',
                    },
                    {
                      id: 2890,
                      name: 'UB Flange:03.02.01.01.000_Structural framing B9_UB406x178x74_G10/117:860167',
                      children: [
                        {
                          id: 2891,
                          name: 'Axis',
                          children: [
                            {
                              id: 2892,
                              name: 'Body',
                              children: [
                                {
                                  id: 2893,
                                  name: '#34116',
                                  children: [
                                    {
                                      id: 2894,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2895,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2896,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2897,
                                      name: '#34119',
                                      children: [
                                        {
                                          id: 2898,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 10941.526497479,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 10941.526497479,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'e861d2e7-b2bf-4378-bbd3-c2060f33f632',
                    },
                    {
                      id: 2899,
                      name: 'UB Flange:03.02.01.01.000_Structural framing B9_UB406x178x74_G10/117:860321',
                      children: [
                        {
                          id: 2900,
                          name: 'Axis',
                          children: [
                            {
                              id: 2901,
                              name: 'Body',
                              children: [
                                {
                                  id: 2902,
                                  name: '#34165',
                                  children: [
                                    {
                                      id: 2903,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2904,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2905,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2906,
                                      name: '#34168',
                                      children: [
                                        {
                                          id: 2907,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 10941.526497479,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 10941.526497479,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'e861d2e7-b2bf-4378-bbd3-c2060f33f694',
                    },
                    {
                      id: 2908,
                      name: 'UB Flange:03.02.01.01.000_Structural framing B9_UB406x178x74_G10/117:860439',
                      children: [
                        {
                          id: 2909,
                          name: 'Axis',
                          children: [
                            {
                              id: 2910,
                              name: 'Body',
                              children: [
                                {
                                  id: 2911,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2912,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2913,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2914,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2915,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2916,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 10941.526497479,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 10941.526497479,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'e861d2e7-b2bf-4378-bbd3-c2060f33f722',
                    },
                    {
                      id: 2917,
                      name: 'UB Flange:03.02.01.01.000_Structural framing B9_UB406x178x74_G10/117:860653',
                      children: [
                        {
                          id: 2918,
                          name: 'Axis',
                          children: [
                            {
                              id: 2919,
                              name: 'Body',
                              children: [
                                {
                                  id: 2920,
                                  name: '#34318',
                                  children: [
                                    {
                                      id: 2921,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2922,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2923,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2924,
                                      name: '#34321',
                                      children: [
                                        {
                                          id: 2925,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 10941.526497479,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 10941.526497479,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: 'e861d2e7-b2bf-4378-bbd3-c2060f33f7d8',
                    },
                    {
                      id: 2926,
                      name:
                        'UC Flange-Column:03.02.01.01.01.250_Structural steel column S1 UC 203x203x46 G10/117A:860714',
                      children: [
                        {
                          id: 2927,
                          name: 'Body',
                          children: [
                            {
                              id: 2928,
                              name: 'Body',
                              children: [
                                {
                                  id: 2929,
                                  name: '#34367',
                                  children: [
                                    {
                                      id: 2930,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1850,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1850,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9cafac3',
                    },
                    {
                      id: 2931,
                      name: 'UB Flange:03.02.01.01.01.030_Structural framing B3_UB254x146x37_G10/117:860761',
                      children: [
                        {
                          id: 2932,
                          name: 'Axis',
                          children: [
                            {
                              id: 2933,
                              name: 'Body',
                              children: [
                                {
                                  id: 2934,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2935,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2936,
                                          name: '#34420',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2937,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2938,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2939,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2940,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2941,
                                              name: '#34434',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5471.48577877089,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5471.48577877089,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9cafab0',
                    },
                    {
                      id: 2942,
                      name: 'UB Flange:03.02.01.01.01.030_Structural framing B3_UB254x146x37_G10/117:860912',
                      children: [
                        {
                          id: 2943,
                          name: 'Axis',
                          children: [
                            {
                              id: 2944,
                              name: 'Body',
                              children: [
                                {
                                  id: 2945,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2946,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 2947,
                                          name: '#34530',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2948,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2949,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2950,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2951,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 2952,
                                              name: '#34544',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5470.82115714095,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5470.82115714095,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9cafa19',
                    },
                    {
                      id: 2953,
                      name: 'UB Flange:03.02.01.01.01.080_Structural framing B11_UB152x89x16_G10/117:861053',
                      children: [
                        {
                          id: 2954,
                          name: 'Axis',
                          children: [
                            {
                              id: 2955,
                              name: 'Body',
                              children: [
                                {
                                  id: 2956,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2957,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2958,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2959,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2960,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2961,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4576.12236042624,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4576.12236042624,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9cafb94',
                    },
                    {
                      id: 2962,
                      name: 'UB Flange:03.02.01.01.01.080_Structural framing B11_UB152x89x16_G10/117:861512',
                      children: [
                        {
                          id: 2963,
                          name: 'Axis',
                          children: [
                            {
                              id: 2964,
                              name: 'Body',
                              children: [
                                {
                                  id: 2965,
                                  name: '#34735',
                                  children: [
                                    {
                                      id: 2966,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2967,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2968,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2969,
                                      name: '#34738',
                                      children: [
                                        {
                                          id: 2970,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4576.12236042624,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4576.12236042624,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9cafda1',
                    },
                    {
                      id: 2971,
                      name: 'UB Flange:03.02.01.01.01.080_Structural framing B11_UB152x89x16_G10/117:861653',
                      children: [
                        {
                          id: 2972,
                          name: 'Axis',
                          children: [
                            {
                              id: 2973,
                              name: 'Body',
                              children: [
                                {
                                  id: 2974,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 2975,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2976,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2977,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2978,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 2979,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4440.99017863583,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4440.99017863583,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9cafd3c',
                    },
                    {
                      id: 2980,
                      name: 'UB Flange:03.02.01.01.01.080_Structural framing B11_UB152x89x16_G10/117:861655',
                      children: [
                        {
                          id: 2981,
                          name: 'Axis',
                          children: [
                            {
                              id: 2982,
                              name: 'Body',
                              children: [
                                {
                                  id: 2983,
                                  name: '#34888',
                                  children: [
                                    {
                                      id: 2984,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2985,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2986,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2987,
                                      name: '#34891',
                                      children: [
                                        {
                                          id: 2988,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4440.99017863581,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4440.99017863581,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9cafd3e',
                    },
                    {
                      id: 2989,
                      name: 'UB Flange:03.02.01.01.01.080_Structural framing B11_UB152x89x16_G10/117:861854',
                      children: [
                        {
                          id: 2990,
                          name: 'Axis',
                          children: [
                            {
                              id: 2991,
                              name: 'Body',
                              children: [
                                {
                                  id: 2992,
                                  name: '#34937',
                                  children: [
                                    {
                                      id: 2993,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 2994,
                              name: 'Axis',
                              children: [
                                {
                                  id: 2995,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 2996,
                                      name: '#34940',
                                      children: [
                                        {
                                          id: 2997,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4440.99017863583,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4440.99017863583,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9cafe77',
                    },
                    {
                      id: 2998,
                      name: 'UB Flange:03.02.01.01.01.080_Structural framing B11_UB152x89x16_G10/117:861856',
                      children: [
                        {
                          id: 2999,
                          name: 'Axis',
                          children: [
                            {
                              id: 3000,
                              name: 'Body',
                              children: [
                                {
                                  id: 3001,
                                  name: '#34986',
                                  children: [
                                    {
                                      id: 3002,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3003,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3004,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3005,
                                      name: '#34989',
                                      children: [
                                        {
                                          id: 3006,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4440.99017863581,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4440.99017863581,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9cafe49',
                    },
                    {
                      id: 3007,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:862855',
                      children: [
                        {
                          id: 3008,
                          name: 'Axis',
                          children: [
                            {
                              id: 3009,
                              name: 'Body',
                              children: [
                                {
                                  id: 3010,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3011,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3012,
                                          name: '#35044',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3013,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3014,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3015,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3016,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3017,
                                              name: '#35058',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3984.88912922993,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3984.88912922993,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9caf26e',
                    },
                    {
                      id: 3018,
                      name: 'UB Flange:03.02.01.01.01.040_Structural framing B8_UB254x146x31_G10/117:865183',
                      children: [
                        {
                          id: 3019,
                          name: 'Axis',
                          children: [
                            {
                              id: 3020,
                              name: 'Body',
                              children: [
                                {
                                  id: 3021,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3022,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3023,
                                          name: '#35158',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3024,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3025,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3026,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3027,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3028,
                                              name: '#35172',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 7330.38932770009,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 7330.38932770009,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9caeb76',
                    },
                    {
                      id: 3029,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:865626',
                      children: [
                        {
                          id: 3030,
                          name: 'Axis',
                          children: [
                            {
                              id: 3031,
                              name: 'Body',
                              children: [
                                {
                                  id: 3032,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3033,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3034,
                                          name: '#35268',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3035,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3036,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3037,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3038,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3039,
                                              name: '#35282',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1372.45006032773,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1372.45006032773,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9caedb3',
                    },
                    {
                      id: 3040,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:866337',
                      children: [
                        {
                          id: 3041,
                          name: 'Axis',
                          children: [
                            {
                              id: 3042,
                              name: 'Body',
                              children: [
                                {
                                  id: 3043,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3044,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3045,
                                          name: '#35376',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3046,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3047,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3048,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3049,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3050,
                                              name: '#35390',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3627.03763467773,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3627.03763467773,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9cae0c8',
                    },
                    {
                      id: 3051,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:866634',
                      children: [
                        {
                          id: 3052,
                          name: 'Axis',
                          children: [
                            {
                              id: 3053,
                              name: 'Body',
                              children: [
                                {
                                  id: 3054,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3055,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3056,
                                          name: '#35486',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3057,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3058,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3059,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3060,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3061,
                                              name: '#35500',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3970.74153286198,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3970.74153286198,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9cae1a3',
                    },
                    {
                      id: 3062,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:866792',
                      children: [
                        {
                          id: 3063,
                          name: 'Axis',
                          children: [
                            {
                              id: 3064,
                              name: 'Body',
                              children: [
                                {
                                  id: 3065,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3066,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3067,
                                          name: '#35594',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3068,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3069,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3070,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3071,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3072,
                                              name: '#35608',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1346.83753967032,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1346.83753967032,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9cae101',
                    },
                    {
                      id: 3073,
                      name: 'UC Flange:03.02.01.01.01.260_Structural framing B2 UC 203x203x46 G10/117A:868110',
                      children: [
                        {
                          id: 3074,
                          name: 'Axis',
                          children: [
                            {
                              id: 3075,
                              name: 'Body',
                              children: [
                                {
                                  id: 3076,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3077,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3078,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3079,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3080,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3081,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 2160,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 2160,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9cae7e7',
                    },
                    {
                      id: 3082,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:868140',
                      children: [
                        {
                          id: 3083,
                          name: 'Axis',
                          children: [
                            {
                              id: 3084,
                              name: 'Body',
                              children: [
                                {
                                  id: 3085,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3086,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3087,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3088,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3089,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3090,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3980.29232022915,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3980.29232022915,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9cae7c5',
                    },
                    {
                      id: 3091,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:868142',
                      children: [
                        {
                          id: 3092,
                          name: 'Axis',
                          children: [
                            {
                              id: 3093,
                              name: 'Body',
                              children: [
                                {
                                  id: 3094,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3095,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3096,
                                          name: '#35924',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3097,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3098,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3099,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3100,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3101,
                                              name: '#35938',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1372.52497715492,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1372.52497715492,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9cae7c7',
                    },
                    {
                      id: 3102,
                      name: 'UC Flange:03.02.01.01.01.260_Structural framing B2 UC 203x203x46 G10/117A:868144',
                      children: [
                        {
                          id: 3103,
                          name: 'Axis',
                          children: [
                            {
                              id: 3104,
                              name: 'Body',
                              children: [
                                {
                                  id: 3105,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3106,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3107,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3108,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3109,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3110,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 2160,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 2160,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9cae7d9',
                    },
                    {
                      id: 3111,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:868230',
                      children: [
                        {
                          id: 3112,
                          name: 'Axis',
                          children: [
                            {
                              id: 3113,
                              name: 'Body',
                              children: [
                                {
                                  id: 3114,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3115,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3116,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3117,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3118,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3119,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3627.03654655539,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3627.03654655539,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9cae76f',
                    },
                    {
                      id: 3120,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:868505',
                      children: [
                        {
                          id: 3121,
                          name: 'Axis',
                          children: [
                            {
                              id: 3122,
                              name: 'Body',
                              children: [
                                {
                                  id: 3123,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3124,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3125,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3126,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3127,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3128,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3984.86288639416,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3984.86288639416,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca9870',
                    },
                    {
                      id: 3129,
                      name: 'UB Flange:03.02.01.01.01.040_Structural framing B8_UB254x146x31_G10/117:868507',
                      children: [
                        {
                          id: 3130,
                          name: 'Axis',
                          children: [
                            {
                              id: 3131,
                              name: 'Body',
                              children: [
                                {
                                  id: 3132,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3133,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3134,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3135,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3136,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3137,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 7330.38932768214,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 7330.38932768214,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca9872',
                    },
                    {
                      id: 3138,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:868509',
                      children: [
                        {
                          id: 3139,
                          name: 'Axis',
                          children: [
                            {
                              id: 3140,
                              name: 'Body',
                              children: [
                                {
                                  id: 3141,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3142,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3143,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3144,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3145,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3146,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1372.52497715504,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1372.52497715504,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca9874',
                    },
                    {
                      id: 3147,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:868511',
                      children: [
                        {
                          id: 3148,
                          name: 'Axis',
                          children: [
                            {
                              id: 3149,
                              name: 'Body',
                              children: [
                                {
                                  id: 3150,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3151,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3152,
                                          name: '#36574',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3153,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3154,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3155,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3156,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3157,
                                              name: '#36588',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3627.0372651175,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3627.0372651175,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca9876',
                    },
                    {
                      id: 3158,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:868513',
                      children: [
                        {
                          id: 3159,
                          name: 'Axis',
                          children: [
                            {
                              id: 3160,
                              name: 'Body',
                              children: [
                                {
                                  id: 3161,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3162,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3163,
                                          name: '#36684',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3164,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3165,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3166,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3167,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3168,
                                              name: '#36698',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3970.74153464901,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3970.74153464901,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca9848',
                    },
                    {
                      id: 3169,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:868515',
                      children: [
                        {
                          id: 3170,
                          name: 'Axis',
                          children: [
                            {
                              id: 3171,
                              name: 'Body',
                              children: [
                                {
                                  id: 3172,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3173,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3174,
                                          name: '#36792',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3175,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3176,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3177,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3178,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3179,
                                              name: '#36806',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1347.13686941553,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1347.13686941553,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca984a',
                    },
                    {
                      id: 3180,
                      name: 'UC Flange:03.02.01.01.01.260_Structural framing B2 UC 203x203x46 G10/117A:868517',
                      children: [
                        {
                          id: 3181,
                          name: 'Axis',
                          children: [
                            {
                              id: 3182,
                              name: 'Body',
                              children: [
                                {
                                  id: 3183,
                                  name: '#36891',
                                  children: [
                                    {
                                      id: 3184,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3185,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3186,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3187,
                                      name: '#36894',
                                      children: [
                                        {
                                          id: 3188,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 2160,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 2160,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca984c',
                    },
                    {
                      id: 3189,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:868519',
                      children: [
                        {
                          id: 3190,
                          name: 'Axis',
                          children: [
                            {
                              id: 3191,
                              name: 'Body',
                              children: [
                                {
                                  id: 3192,
                                  name: '#36944',
                                  children: [
                                    {
                                      id: 3193,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3194,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3195,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3196,
                                      name: '#36947',
                                      children: [
                                        {
                                          id: 3197,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3980.29232022921,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3980.29232022921,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca984e',
                    },
                    {
                      id: 3198,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:868521',
                      children: [
                        {
                          id: 3199,
                          name: 'Axis',
                          children: [
                            {
                              id: 3200,
                              name: 'Body',
                              children: [
                                {
                                  id: 3201,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3202,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3203,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3204,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3205,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3206,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1372.52497715493,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1372.52497715493,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca9840',
                    },
                    {
                      id: 3207,
                      name: 'UC Flange:03.02.01.01.01.260_Structural framing B2 UC 203x203x46 G10/117A:868523',
                      children: [
                        {
                          id: 3208,
                          name: 'Axis',
                          children: [
                            {
                              id: 3209,
                              name: 'Body',
                              children: [
                                {
                                  id: 3210,
                                  name: '#37105',
                                  children: [
                                    {
                                      id: 3211,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3212,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3213,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3214,
                                      name: '#37108',
                                      children: [
                                        {
                                          id: 3215,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 2160,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 2160,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca9842',
                    },
                    {
                      id: 3216,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:868525',
                      children: [
                        {
                          id: 3217,
                          name: 'Axis',
                          children: [
                            {
                              id: 3218,
                              name: 'Body',
                              children: [
                                {
                                  id: 3219,
                                  name: '#37158',
                                  children: [
                                    {
                                      id: 3220,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3221,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3222,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3223,
                                      name: '#37161',
                                      children: [
                                        {
                                          id: 3224,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3627.03654655538,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3627.03654655538,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca9844',
                    },
                    {
                      id: 3225,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:868811',
                      children: [
                        {
                          id: 3226,
                          name: 'Axis',
                          children: [
                            {
                              id: 3227,
                              name: 'Body',
                              children: [
                                {
                                  id: 3228,
                                  name: '#37213',
                                  children: [
                                    {
                                      id: 3229,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3230,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3231,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3232,
                                      name: '#37216',
                                      children: [
                                        {
                                          id: 3233,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3984.86288639418,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3984.86288639418,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca9922',
                    },
                    {
                      id: 3234,
                      name: 'UB Flange:03.02.01.01.01.040_Structural framing B8_UB254x146x31_G10/117:868813',
                      children: [
                        {
                          id: 3235,
                          name: 'Axis',
                          children: [
                            {
                              id: 3236,
                              name: 'Body',
                              children: [
                                {
                                  id: 3237,
                                  name: '#37266',
                                  children: [
                                    {
                                      id: 3238,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3239,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3240,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3241,
                                      name: '#37269',
                                      children: [
                                        {
                                          id: 3242,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 7330.38932768214,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 7330.38932768214,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca9924',
                    },
                    {
                      id: 3243,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:868815',
                      children: [
                        {
                          id: 3244,
                          name: 'Axis',
                          children: [
                            {
                              id: 3245,
                              name: 'Body',
                              children: [
                                {
                                  id: 3246,
                                  name: '#37319',
                                  children: [
                                    {
                                      id: 3247,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3248,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3249,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3250,
                                      name: '#37322',
                                      children: [
                                        {
                                          id: 3251,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1372.52497715507,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1372.52497715507,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca9926',
                    },
                    {
                      id: 3252,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:868817',
                      children: [
                        {
                          id: 3253,
                          name: 'Axis',
                          children: [
                            {
                              id: 3254,
                              name: 'Body',
                              children: [
                                {
                                  id: 3255,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3256,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3257,
                                          name: '#37381',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3258,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3259,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3260,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3261,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3262,
                                              name: '#37395',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3627.0372722611,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3627.0372722611,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca9938',
                    },
                    {
                      id: 3263,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:868819',
                      children: [
                        {
                          id: 3264,
                          name: 'Axis',
                          children: [
                            {
                              id: 3265,
                              name: 'Body',
                              children: [
                                {
                                  id: 3266,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3267,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3268,
                                          name: '#37491',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3269,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3270,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3271,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3272,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3273,
                                              name: '#37505',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3970.74033133048,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3970.74033133048,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca993a',
                    },
                    {
                      id: 3274,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:868821',
                      children: [
                        {
                          id: 3275,
                          name: 'Axis',
                          children: [
                            {
                              id: 3276,
                              name: 'Body',
                              children: [
                                {
                                  id: 3277,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3278,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3279,
                                          name: '#37599',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3280,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3281,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3282,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3283,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3284,
                                              name: '#37613',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1347.13854729223,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1347.13854729223,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca993c',
                    },
                    {
                      id: 3285,
                      name: 'UC Flange:03.02.01.01.01.260_Structural framing B2 UC 203x203x46 G10/117A:868823',
                      children: [
                        {
                          id: 3286,
                          name: 'Axis',
                          children: [
                            {
                              id: 3287,
                              name: 'Body',
                              children: [
                                {
                                  id: 3288,
                                  name: '#37698',
                                  children: [
                                    {
                                      id: 3289,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3290,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3291,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3292,
                                      name: '#37701',
                                      children: [
                                        {
                                          id: 3293,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 2160,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 2160,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca993e',
                    },
                    {
                      id: 3294,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:868825',
                      children: [
                        {
                          id: 3295,
                          name: 'Axis',
                          children: [
                            {
                              id: 3296,
                              name: 'Body',
                              children: [
                                {
                                  id: 3297,
                                  name: '#37751',
                                  children: [
                                    {
                                      id: 3298,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3299,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3300,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3301,
                                      name: '#37754',
                                      children: [
                                        {
                                          id: 3302,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3980.29232022921,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3980.29232022921,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca9930',
                    },
                    {
                      id: 3303,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:868827',
                      children: [
                        {
                          id: 3304,
                          name: 'Axis',
                          children: [
                            {
                              id: 3305,
                              name: 'Body',
                              children: [
                                {
                                  id: 3306,
                                  name: '#37804',
                                  children: [
                                    {
                                      id: 3307,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3308,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3309,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3310,
                                      name: '#37807',
                                      children: [
                                        {
                                          id: 3311,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1372.52497715497,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1372.52497715497,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca9932',
                    },
                    {
                      id: 3312,
                      name: 'UC Flange:03.02.01.01.01.260_Structural framing B2 UC 203x203x46 G10/117A:868829',
                      children: [
                        {
                          id: 3313,
                          name: 'Axis',
                          children: [
                            {
                              id: 3314,
                              name: 'Body',
                              children: [
                                {
                                  id: 3315,
                                  name: '#37857',
                                  children: [
                                    {
                                      id: 3316,
                                      name: 'Body',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3317,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3318,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3319,
                                      name: '#37860',
                                      children: [
                                        {
                                          id: 3320,
                                          name: 'Axis',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 2160,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 2160,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca9934',
                    },
                    {
                      id: 3321,
                      name: 'UC Flange:03.02.01.01.01.120_Structural framing B13_UC152x152x23_G10/117:868831',
                      children: [
                        {
                          id: 3322,
                          name: 'Axis',
                          children: [
                            {
                              id: 3323,
                              name: 'Body',
                              children: [
                                {
                                  id: 3324,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3325,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3326,
                                          name: '#37919',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3327,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3328,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3329,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3330,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3331,
                                              name: '#37933',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3627.03655380546,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3627.03655380546,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '6e2ef694-74f4-4cdf-b30b-5034c9ca9936',
                    },
                    {
                      id: 3332,
                      name: 'CHS:03.02.01.01.01.190_Structural framing B12 Ridge_CHS139.7x5_G10/117:868984',
                      children: [
                        {
                          id: 3333,
                          name: 'Axis',
                          children: [
                            {
                              id: 3334,
                              name: 'Body',
                              children: [
                                {
                                  id: 3335,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3336,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3337,
                                          name: '#38037',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3338,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3339,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3340,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3341,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3342,
                                              name: '#38057',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5837.82187930532,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5837.82187930532,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3c7ceed4-da23-4d83-ad79-5925efcc1393',
                    },
                    {
                      id: 3343,
                      name: 'PFC:03.02.01.01.01.140_Structural framing B4_PFC200x90x30_Rafter G10/117:869568',
                      children: [
                        {
                          id: 3344,
                          name: 'Axis',
                          children: [
                            {
                              id: 3345,
                              name: 'Body',
                              children: [
                                {
                                  id: 3346,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3347,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3348,
                                          name: '#38231',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3349,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3350,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3351,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3352,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3353,
                                              name: '#38245',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5662.97786356483,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5662.97786356483,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3c7ceed4-da23-4d83-ad79-5925efcc152b',
                    },
                    {
                      id: 3354,
                      name: 'PFC:03.02.01.01.01.140_Structural framing B4_PFC200x90x30_Rafter G10/117:869686',
                      children: [
                        {
                          id: 3355,
                          name: 'Axis',
                          children: [
                            {
                              id: 3356,
                              name: 'Body',
                              children: [
                                {
                                  id: 3357,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3358,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3359,
                                          name: '#38410',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3360,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3361,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3362,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3363,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3364,
                                              name: '#38424',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5663.92395090349,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5663.92395090349,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3c7ceed4-da23-4d83-ad79-5925efcc14dd',
                    },
                    {
                      id: 3365,
                      name: 'PFC:03.02.01.01.01.140_Structural framing B4_PFC200x90x30_Rafter G10/117:869881',
                      children: [
                        {
                          id: 3366,
                          name: 'Axis',
                          children: [
                            {
                              id: 3367,
                              name: 'Body',
                              children: [
                                {
                                  id: 3368,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3369,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3370,
                                          name: '#38583',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3371,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3372,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3373,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3374,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3375,
                                              name: '#38597',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5662.97786356486,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5662.97786356486,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3c7ceed4-da23-4d83-ad79-5925efcc1412',
                    },
                    {
                      id: 3376,
                      name: 'PFC:03.02.01.01.01.140_Structural framing B4_PFC200x90x30_Rafter G10/117:869883',
                      children: [
                        {
                          id: 3377,
                          name: 'Axis',
                          children: [
                            {
                              id: 3378,
                              name: 'Body',
                              children: [
                                {
                                  id: 3379,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3380,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3381,
                                          name: '#38756',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3382,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3383,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3384,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3385,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3386,
                                              name: '#38770',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5663.92395090346,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5663.92395090346,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3c7ceed4-da23-4d83-ad79-5925efcc1410',
                    },
                    {
                      id: 3387,
                      name: 'UB Flange:03.02.01.01.01.040_Structural framing B8_UB254x146x31_G10/117:870262',
                      children: [
                        {
                          id: 3388,
                          name: 'Axis',
                          children: [
                            {
                              id: 3389,
                              name: 'Body',
                              children: [
                                {
                                  id: 3390,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3391,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3392,
                                          name: '#38864',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3393,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3394,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3395,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3396,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3397,
                                              name: '#38878',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 7883.01110788705,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 7883.01110788705,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3c7ceed4-da23-4d83-ad79-5925efcc169d',
                    },
                    {
                      id: 3398,
                      name: 'UB Flange:03.02.01.01.01.040_Structural framing B8_UB254x146x31_G10/117:870750',
                      children: [
                        {
                          id: 3399,
                          name: 'Axis',
                          children: [
                            {
                              id: 3400,
                              name: 'Body',
                              children: [
                                {
                                  id: 3401,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3402,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3403,
                                          name: '#38972',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3404,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3405,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3406,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3407,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3408,
                                              name: '#38986',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 7883.01110788705,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 7883.01110788705,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3c7ceed4-da23-4d83-ad79-5925efcc18b5',
                    },
                    {
                      id: 3409,
                      name: 'PFC:03.02.01.01.01.150_Structural framing B4_PFC200x90x30_G10/117:871293',
                      children: [
                        {
                          id: 3410,
                          name: 'Axis',
                          children: [
                            {
                              id: 3411,
                              name: 'Body',
                              children: [
                                {
                                  id: 3412,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3413,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3414,
                                          name: '#39145',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3415,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3416,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3417,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3418,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3419,
                                              name: '#39159',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1986.47019543526,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1986.47019543526,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3c7ceed4-da23-4d83-ad79-5925efcc1a96',
                    },
                    {
                      id: 3420,
                      name: 'UB Flange:03.02.01.01.01.060_Structural framing B1_UB203x133x25_Rafter G10/117:871893',
                      children: [
                        {
                          id: 3421,
                          name: 'Axis',
                          children: [
                            {
                              id: 3422,
                              name: 'Body',
                              children: [
                                {
                                  id: 3423,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3424,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3425,
                                          name: '#39253',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3426,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3427,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3428,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3429,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3430,
                                              name: '#39267',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3c7ceed4-da23-4d83-ad79-5925efcc1c3e',
                    },
                    {
                      id: 3431,
                      name: 'UB Flange:03.02.01.01.01.040_Structural framing B8_UB254x146x31_G10/117:872387',
                      children: [
                        {
                          id: 3432,
                          name: 'Axis',
                          children: [
                            {
                              id: 3433,
                              name: 'Body',
                              children: [
                                {
                                  id: 3434,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3435,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3436,
                                          name: '#39367',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3437,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3438,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3439,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3440,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3441,
                                              name: '#39381',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 7894.37184389479,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 7894.37184389479,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3c7ceed4-da23-4d83-ad79-5925efcc1e28',
                    },
                    {
                      id: 3442,
                      name: 'UB Flange:03.02.01.01.01.040_Structural framing B8_UB254x146x31_G10/117:872389',
                      children: [
                        {
                          id: 3443,
                          name: 'Axis',
                          children: [
                            {
                              id: 3444,
                              name: 'Body',
                              children: [
                                {
                                  id: 3445,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3446,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3447,
                                          name: '#39475',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3448,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3449,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3450,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3451,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3452,
                                              name: '#39489',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 7894.37184389478,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 7894.37184389478,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3c7ceed4-da23-4d83-ad79-5925efcc1e2e',
                    },
                    {
                      id: 3453,
                      name: 'PFC:03.02.01.01.01.150_Structural framing B4_PFC200x90x30_G10/117:872391',
                      children: [
                        {
                          id: 3454,
                          name: 'Axis',
                          children: [
                            {
                              id: 3455,
                              name: 'Body',
                              children: [
                                {
                                  id: 3456,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3457,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3458,
                                          name: '#39648',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3459,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3460,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3461,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3462,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3463,
                                              name: '#39662',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1986.4648471216601,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1986.4648471216601,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3c7ceed4-da23-4d83-ad79-5925efcc1e2c',
                    },
                    {
                      id: 3464,
                      name: 'UB Flange:03.02.01.01.01.060_Structural framing B1_UB203x133x25_Rafter G10/117:872393',
                      children: [
                        {
                          id: 3465,
                          name: 'Axis',
                          children: [
                            {
                              id: 3466,
                              name: 'Body',
                              children: [
                                {
                                  id: 3467,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3468,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3469,
                                          name: '#39756',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3470,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3471,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3472,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3473,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3474,
                                              name: '#39770',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 4600,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 4600,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '3c7ceed4-da23-4d83-ad79-5925efcc1e22',
                    },
                    {
                      id: 3475,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:872711',
                      children: [
                        {
                          id: 3476,
                          name: 'Axis',
                          children: [
                            {
                              id: 3477,
                              name: 'Body',
                              children: [
                                {
                                  id: 3478,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3479,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3480,
                                          name: '#40027',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3481,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3482,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3483,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3484,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3485,
                                              name: '#40038',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 45995.6522650929,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 45995.6522650929,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60f6c',
                    },
                    {
                      id: 3486,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:872884',
                      children: [
                        {
                          id: 3487,
                          name: 'Axis',
                          children: [
                            {
                              id: 3488,
                              name: 'Body',
                              children: [
                                {
                                  id: 3489,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3490,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3491,
                                          name: '#40300',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3492,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3493,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3494,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3495,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3496,
                                              name: '#40311',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 43693.719548199,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 43693.719548199,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60fdf',
                    },
                    {
                      id: 3497,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:872913',
                      children: [
                        {
                          id: 3498,
                          name: 'Axis',
                          children: [
                            {
                              id: 3499,
                              name: 'Body',
                              children: [
                                {
                                  id: 3500,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3501,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3502,
                                          name: '#40566',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3503,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3504,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3505,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3506,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3507,
                                              name: '#40577',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 41373.2895971858,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 41373.2895971858,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60fba',
                    },
                    {
                      id: 3508,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:872941',
                      children: [
                        {
                          id: 3509,
                          name: 'Axis',
                          children: [
                            {
                              id: 3510,
                              name: 'Body',
                              children: [
                                {
                                  id: 3511,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3512,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3513,
                                          name: '#40832',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3514,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3515,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3516,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3517,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3518,
                                              name: '#40843',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 39052.859643544,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 39052.859643544,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60f86',
                    },
                    {
                      id: 3519,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:872970',
                      children: [
                        {
                          id: 3520,
                          name: 'Axis',
                          children: [
                            {
                              id: 3521,
                              name: 'Body',
                              children: [
                                {
                                  id: 3522,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3523,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3524,
                                          name: '#41098',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3525,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3526,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3527,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3528,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3529,
                                              name: '#41109',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 36732.4296899035,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 36732.4296899035,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60c61',
                    },
                    {
                      id: 3530,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:873070',
                      children: [
                        {
                          id: 3531,
                          name: 'Axis',
                          children: [
                            {
                              id: 3532,
                              name: 'Body',
                              children: [
                                {
                                  id: 3533,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3534,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3535,
                                          name: '#41364',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3536,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3537,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3538,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3539,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3540,
                                              name: '#41375',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 35668.8992856421,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 35668.8992856421,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60c05',
                    },
                    {
                      id: 3541,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:873144',
                      children: [
                        {
                          id: 3542,
                          name: 'Axis',
                          children: [
                            {
                              id: 3543,
                              name: 'Body',
                              children: [
                                {
                                  id: 3544,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3545,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3546,
                                          name: '#41630',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3547,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3548,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3549,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3550,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3551,
                                              name: '#41641',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 35465.340645596,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 35465.340645596,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60cd3',
                    },
                    {
                      id: 3552,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:873203',
                      children: [
                        {
                          id: 3553,
                          name: 'Axis',
                          children: [
                            {
                              id: 3554,
                              name: 'Body',
                              children: [
                                {
                                  id: 3555,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3556,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3557,
                                          name: '#41892',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3558,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3559,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3560,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3561,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3562,
                                              name: '#41903',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 34265.4018164088,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 34265.4018164088,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60c98',
                    },
                    {
                      id: 3563,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:873561',
                      children: [
                        {
                          id: 3564,
                          name: 'Axis',
                          children: [
                            {
                              id: 3565,
                              name: 'Body',
                              children: [
                                {
                                  id: 3566,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3567,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3568,
                                          name: '#42154',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3569,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3570,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3571,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3572,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3573,
                                              name: '#42165',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 22999.9231474268,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 22999.9231474268,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60a32',
                    },
                    {
                      id: 3574,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:873596',
                      children: [
                        {
                          id: 3575,
                          name: 'Axis',
                          children: [
                            {
                              id: 3576,
                              name: 'Body',
                              children: [
                                {
                                  id: 3577,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3578,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3579,
                                          name: '#42420',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3580,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3581,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3582,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3583,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3584,
                                              name: '#42431',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 20697.7083139219,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 20697.7083139219,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60a17',
                    },
                    {
                      id: 3585,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:873624',
                      children: [
                        {
                          id: 3586,
                          name: 'Axis',
                          children: [
                            {
                              id: 3587,
                              name: 'Body',
                              children: [
                                {
                                  id: 3588,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3589,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3590,
                                          name: '#42686',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3591,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3592,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3593,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3594,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3595,
                                              name: '#42697',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 18377.0000179655,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 18377.0000179655,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60af3',
                    },
                    {
                      id: 3596,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:873655',
                      children: [
                        {
                          id: 3597,
                          name: 'Axis',
                          children: [
                            {
                              id: 3598,
                              name: 'Body',
                              children: [
                                {
                                  id: 3599,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3600,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3601,
                                          name: '#42952',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3602,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3603,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3604,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3605,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3606,
                                              name: '#42963',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 16056.333468742,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 16056.333468742,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60adc',
                    },
                    {
                      id: 3607,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:873685',
                      children: [
                        {
                          id: 3608,
                          name: 'Axis',
                          children: [
                            {
                              id: 3609,
                              name: 'Body',
                              children: [
                                {
                                  id: 3610,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3611,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3612,
                                          name: '#43218',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3613,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3614,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3615,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3616,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3617,
                                              name: '#43229',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 13735.666920715,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 13735.666920715,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60abe',
                    },
                    {
                      id: 3618,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:873791',
                      children: [
                        {
                          id: 3619,
                          name: 'Axis',
                          children: [
                            {
                              id: 3620,
                              name: 'Body',
                              children: [
                                {
                                  id: 3621,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3622,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3623,
                                          name: '#43484',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3624,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3625,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3626,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3627,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3628,
                                              name: '#43495',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 12468.5385782809,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 12468.5385782809,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60b54',
                    },
                    {
                      id: 3629,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:873816',
                      children: [
                        {
                          id: 3630,
                          name: 'Axis',
                          children: [
                            {
                              id: 3631,
                              name: 'Body',
                              children: [
                                {
                                  id: 3632,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3633,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3634,
                                          name: '#43746',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3635,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3636,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3637,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3638,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3639,
                                              name: '#43757',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 11268.4774012637,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 11268.4774012637,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60b33',
                    },
                    {
                      id: 3640,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:873900',
                      children: [
                        {
                          id: 3641,
                          name: 'Axis',
                          children: [
                            {
                              id: 3642,
                              name: 'Body',
                              children: [
                                {
                                  id: 3643,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3644,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3645,
                                          name: '#44008',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3646,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3647,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3648,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3649,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3650,
                                              name: '#44019',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 12672.1868627526,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 12672.1868627526,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60bc7',
                    },
                    {
                      id: 3651,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:873947',
                      children: [
                        {
                          id: 3652,
                          name: 'Axis',
                          children: [
                            {
                              id: 3653,
                              name: 'Body',
                              children: [
                                {
                                  id: 3654,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3655,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3656,
                                          name: '#44276',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3657,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3658,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3659,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3660,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3661,
                                              name: '#44287',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 45998.000788929,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 45998.000788929,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60bb0',
                    },
                    {
                      id: 3662,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:873949',
                      children: [
                        {
                          id: 3663,
                          name: 'Axis',
                          children: [
                            {
                              id: 3664,
                              name: 'Body',
                              children: [
                                {
                                  id: 3665,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3666,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3667,
                                          name: '#44544',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3668,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3669,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3670,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3671,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3672,
                                              name: '#44555',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 43695.8295869843,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 43695.8295869843,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60bb6',
                    },
                    {
                      id: 3673,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:873951',
                      children: [
                        {
                          id: 3674,
                          name: 'Axis',
                          children: [
                            {
                              id: 3675,
                              name: 'Body',
                              children: [
                                {
                                  id: 3676,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3677,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3678,
                                          name: '#44812',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3679,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3680,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3681,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3682,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3683,
                                              name: '#44823',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 41375.163036047,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 41375.163036047,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60bb4',
                    },
                    {
                      id: 3684,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:873953',
                      children: [
                        {
                          id: 3685,
                          name: 'Axis',
                          children: [
                            {
                              id: 3686,
                              name: 'Body',
                              children: [
                                {
                                  id: 3687,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3688,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3689,
                                          name: '#45080',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3690,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3691,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3692,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3693,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3694,
                                              name: '#45091',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 39054.4964874051,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 39054.4964874051,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60b8a',
                    },
                    {
                      id: 3695,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:873955',
                      children: [
                        {
                          id: 3696,
                          name: 'Axis',
                          children: [
                            {
                              id: 3697,
                              name: 'Body',
                              children: [
                                {
                                  id: 3698,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3699,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3700,
                                          name: '#45348',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3701,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3702,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3703,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3704,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3705,
                                              name: '#45359',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 36733.8299387634,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 36733.8299387634,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60b88',
                    },
                    {
                      id: 3706,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:873957',
                      children: [
                        {
                          id: 3707,
                          name: 'Axis',
                          children: [
                            {
                              id: 3708,
                              name: 'Body',
                              children: [
                                {
                                  id: 3709,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3710,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3711,
                                          name: '#45616',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3712,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3713,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3714,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3715,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3716,
                                              name: '#45627',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 35670.1911120634,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 35670.1911120634,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60b8e',
                    },
                    {
                      id: 3717,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:873959',
                      children: [
                        {
                          id: 3718,
                          name: 'Axis',
                          children: [
                            {
                              id: 3719,
                              name: 'Body',
                              children: [
                                {
                                  id: 3720,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3721,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3722,
                                          name: '#45884',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3723,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3724,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3725,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3726,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3727,
                                              name: '#45895',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 35466.6117098093,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 35466.6117098093,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60b8c',
                    },
                    {
                      id: 3728,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874004',
                      children: [
                        {
                          id: 3729,
                          name: 'Axis',
                          children: [
                            {
                              id: 3730,
                              name: 'Body',
                              children: [
                                {
                                  id: 3731,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3732,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3733,
                                          name: '#46148',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3734,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3735,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3736,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3737,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3738,
                                              name: '#46159',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 22995.2297005533,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 22995.2297005533,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba6087f',
                    },
                    {
                      id: 3739,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874006',
                      children: [
                        {
                          id: 3740,
                          name: 'Axis',
                          children: [
                            {
                              id: 3741,
                              name: 'Body',
                              children: [
                                {
                                  id: 3742,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3743,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3744,
                                          name: '#46416',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3745,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3746,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3747,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3748,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3749,
                                              name: '#46427',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 20693.2533473842,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 20693.2533473842,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba6087d',
                    },
                    {
                      id: 3750,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874008',
                      children: [
                        {
                          id: 3751,
                          name: 'Axis',
                          children: [
                            {
                              id: 3752,
                              name: 'Body',
                              children: [
                                {
                                  id: 3753,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3754,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3755,
                                          name: '#46684',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3756,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3757,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3758,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3759,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3760,
                                              name: '#46695',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 18372.7816450745,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 18372.7816450745,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60873',
                    },
                    {
                      id: 3761,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874010',
                      children: [
                        {
                          id: 3762,
                          name: 'Axis',
                          children: [
                            {
                              id: 3763,
                              name: 'Body',
                              children: [
                                {
                                  id: 3764,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3765,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3766,
                                          name: '#46952',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3767,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3768,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3769,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3770,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3771,
                                              name: '#46963',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 16052.3516914555,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 16052.3516914555,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60871',
                    },
                    {
                      id: 3772,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874012',
                      children: [
                        {
                          id: 3773,
                          name: 'Axis',
                          children: [
                            {
                              id: 3774,
                              name: 'Body',
                              children: [
                                {
                                  id: 3775,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3776,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3777,
                                          name: '#47220',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3778,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3779,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3780,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3781,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3782,
                                              name: '#47231',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 13731.9217387603,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 13731.9217387603,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60877',
                    },
                    {
                      id: 3783,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874014',
                      children: [
                        {
                          id: 3784,
                          name: 'Axis',
                          children: [
                            {
                              id: 3785,
                              name: 'Body',
                              children: [
                                {
                                  id: 3786,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3787,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3788,
                                          name: '#47488',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3789,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3790,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3791,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3792,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3793,
                                              name: '#47499',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 12464.9225914897,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 12464.9225914897,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60875',
                    },
                    {
                      id: 3794,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874016',
                      children: [
                        {
                          id: 3795,
                          name: 'Axis',
                          children: [
                            {
                              id: 3796,
                              name: 'Body',
                              children: [
                                {
                                  id: 3797,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3798,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3799,
                                          name: '#47752',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3800,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3801,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3802,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3803,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3804,
                                              name: '#47763',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 5357.4021874374,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 5357.4021874374,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba6084b',
                    },
                    {
                      id: 3805,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874018',
                      children: [
                        {
                          id: 3806,
                          name: 'Axis',
                          children: [
                            {
                              id: 3807,
                              name: 'Body',
                              children: [
                                {
                                  id: 3808,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3809,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3810,
                                          name: '#48016',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3811,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3812,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3813,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3814,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3815,
                                              name: '#48027',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 12668.5501178668,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 12668.5501178668,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba60849',
                    },
                    {
                      id: 3816,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874120',
                      children: [
                        {
                          id: 3817,
                          name: 'Axis',
                          children: [
                            {
                              id: 3818,
                              name: 'Body',
                              children: [
                                {
                                  id: 3819,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3820,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3821,
                                          name: '#48284',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3822,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3823,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3824,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3825,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3826,
                                              name: '#48295',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 34266.5505327893,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 34266.5505327893,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba608e3',
                    },
                    {
                      id: 3827,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874154',
                      children: [
                        {
                          id: 3828,
                          name: 'Axis',
                          children: [
                            {
                              id: 3829,
                              name: 'Body',
                              children: [
                                {
                                  id: 3830,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3831,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3832,
                                          name: '#48548',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3833,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3834,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3835,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3836,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3837,
                                              name: '#48559',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 11264.983762273,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 11264.983762273,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '2690c2f9-f340-4cf1-a5d3-78728ba608c1',
                    },
                    {
                      id: 3838,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874320',
                      children: [
                        {
                          id: 3839,
                          name: 'Axis',
                          children: [
                            {
                              id: 3840,
                              name: 'Body',
                              children: [
                                {
                                  id: 3841,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3842,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3843,
                                          name: '#48810',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3844,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3845,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3846,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3847,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3848,
                                              name: '#48821',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 10738.5933161753,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 10738.5933161753,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad14de',
                    },
                    {
                      id: 3849,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874600',
                      children: [
                        {
                          id: 3850,
                          name: 'Axis',
                          children: [
                            {
                              id: 3851,
                              name: 'Body',
                              children: [
                                {
                                  id: 3852,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3853,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3854,
                                          name: '#49076',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3855,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3856,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3857,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3858,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3859,
                                              name: '#49087',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 8420.26295223137,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 8420.26295223137,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad1be6',
                    },
                    {
                      id: 3860,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874624',
                      children: [
                        {
                          id: 3861,
                          name: 'Axis',
                          children: [
                            {
                              id: 3862,
                              name: 'Body',
                              children: [
                                {
                                  id: 3863,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3864,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3865,
                                          name: '#49342',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3866,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3867,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3868,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3869,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3870,
                                              name: '#49353',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 6101.93259057928,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 6101.93259057928,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad1b0e',
                    },
                    {
                      id: 3871,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874654',
                      children: [
                        {
                          id: 3872,
                          name: 'Axis',
                          children: [
                            {
                              id: 3873,
                              name: 'Body',
                              children: [
                                {
                                  id: 3874,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3875,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3876,
                                          name: '#49608',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3877,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3878,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3879,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3880,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3881,
                                              name: '#49619',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3783.60222892727,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3783.60222892727,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad1b10',
                    },
                    {
                      id: 3882,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874680',
                      children: [
                        {
                          id: 3883,
                          name: 'Axis',
                          children: [
                            {
                              id: 3884,
                              name: 'Body',
                              children: [
                                {
                                  id: 3885,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3886,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3887,
                                          name: '#49874',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3888,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3889,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3890,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3891,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3892,
                                              name: '#49885',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1465.27186727517,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1465.27186727517,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad1b36',
                    },
                    {
                      id: 3893,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874706',
                      children: [
                        {
                          id: 3894,
                          name: 'Axis',
                          children: [
                            {
                              id: 3895,
                              name: 'Body',
                              children: [
                                {
                                  id: 3896,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3897,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3898,
                                          name: '#50140',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3899,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3900,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3901,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3902,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3903,
                                              name: '#50151',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 306.106686449153,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 306.106686449153,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad1b5c',
                    },
                    {
                      id: 3904,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874806',
                      children: [
                        {
                          id: 3905,
                          name: 'Axis',
                          children: [
                            {
                              id: 3906,
                              name: 'Body',
                              children: [
                                {
                                  id: 3907,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3908,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3909,
                                          name: '#50408',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3910,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3911,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3912,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3913,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3914,
                                              name: '#50419',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 10738.5933130292,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 10738.5933130292,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad1ab8',
                    },
                    {
                      id: 3915,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874808',
                      children: [
                        {
                          id: 3916,
                          name: 'Axis',
                          children: [
                            {
                              id: 3917,
                              name: 'Body',
                              children: [
                                {
                                  id: 3918,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3919,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3920,
                                          name: '#50676',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3921,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3922,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3923,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3924,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3925,
                                              name: '#50687',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 8420.262951569,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 8420.262951569,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad1ab6',
                    },
                    {
                      id: 3926,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874810',
                      children: [
                        {
                          id: 3927,
                          name: 'Axis',
                          children: [
                            {
                              id: 3928,
                              name: 'Body',
                              children: [
                                {
                                  id: 3929,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3930,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3931,
                                          name: '#50944',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3932,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3933,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3934,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3935,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3936,
                                              name: '#50955',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 6101.93259010382,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 6101.93259010382,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad1ab4',
                    },
                    {
                      id: 3937,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874812',
                      children: [
                        {
                          id: 3938,
                          name: 'Axis',
                          children: [
                            {
                              id: 3939,
                              name: 'Body',
                              children: [
                                {
                                  id: 3940,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3941,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3942,
                                          name: '#51212',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3943,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3944,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3945,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3946,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3947,
                                              name: '#51223',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 3783.60222863531,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 3783.60222863531,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad1ab2',
                    },
                    {
                      id: 3948,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874814',
                      children: [
                        {
                          id: 3949,
                          name: 'Axis',
                          children: [
                            {
                              id: 3950,
                              name: 'Body',
                              children: [
                                {
                                  id: 3951,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3952,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3953,
                                          name: '#51480',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3954,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3955,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3956,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3957,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3958,
                                              name: '#51491',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 1465.27186716456,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 1465.27186716456,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad1ab0',
                    },
                    {
                      id: 3959,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874816',
                      children: [
                        {
                          id: 3960,
                          name: 'Axis',
                          children: [
                            {
                              id: 3961,
                              name: 'Body',
                              children: [
                                {
                                  id: 3962,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3963,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3964,
                                          name: '#51748',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3965,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3966,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3967,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3968,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3969,
                                              name: '#51759',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 306.106686428808,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 306.106686428808,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad1ace',
                    },
                    {
                      id: 3970,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874870',
                      children: [
                        {
                          id: 3971,
                          name: 'Axis',
                          children: [
                            {
                              id: 3972,
                              name: 'Body',
                              children: [
                                {
                                  id: 3973,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3974,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3975,
                                          name: '#52014',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3976,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3977,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3978,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3979,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3980,
                                              name: '#52025',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 16638.9182311965,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 16638.9182311965,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad1af8',
                    },
                    {
                      id: 3981,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874964',
                      children: [
                        {
                          id: 3982,
                          name: 'Axis',
                          children: [
                            {
                              id: 3983,
                              name: 'Body',
                              children: [
                                {
                                  id: 3984,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3985,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3986,
                                          name: '#52280',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3987,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3988,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 3989,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 3990,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 3991,
                                              name: '#52291',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 14320.58786962,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 14320.58786962,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad1a5a',
                    },
                    {
                      id: 3992,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:874987',
                      children: [
                        {
                          id: 3993,
                          name: 'Axis',
                          children: [
                            {
                              id: 3994,
                              name: 'Body',
                              children: [
                                {
                                  id: 3995,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 3996,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 3997,
                                          name: '#52546',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 3998,
                              name: 'Axis',
                              children: [
                                {
                                  id: 3999,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 4000,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 4001,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 4002,
                                              name: '#52557',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 12002.2575080485,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 12002.2575080485,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad1a65',
                    },
                    {
                      id: 4003,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:875010',
                      children: [
                        {
                          id: 4004,
                          name: 'Axis',
                          children: [
                            {
                              id: 4005,
                              name: 'Body',
                              children: [
                                {
                                  id: 4006,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 4007,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 4008,
                                          name: '#52812',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 4009,
                              name: 'Axis',
                              children: [
                                {
                                  id: 4010,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 4011,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 4012,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 4013,
                                              name: '#52823',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 9683.92714648268,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 9683.92714648268,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad198c',
                    },
                    {
                      id: 4014,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:875039',
                      children: [
                        {
                          id: 4015,
                          name: 'Axis',
                          children: [
                            {
                              id: 4016,
                              name: 'Body',
                              children: [
                                {
                                  id: 4017,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 4018,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 4019,
                                          name: '#53078',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 4020,
                              name: 'Axis',
                              children: [
                                {
                                  id: 4021,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 4022,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 4023,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 4024,
                                              name: '#53089',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 7365.59678492285,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 7365.59678492285,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad1991',
                    },
                    {
                      id: 4025,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:875075',
                      children: [
                        {
                          id: 4026,
                          name: 'Axis',
                          children: [
                            {
                              id: 4027,
                              name: 'Body',
                              children: [
                                {
                                  id: 4028,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 4029,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 4030,
                                          name: '#53344',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 4031,
                              name: 'Axis',
                              children: [
                                {
                                  id: 4032,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 4033,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 4034,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 4035,
                                              name: '#53355',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 6206.43160414538,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 6206.43160414538,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad19cd',
                    },
                    {
                      id: 4036,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:875100',
                      children: [
                        {
                          id: 4037,
                          name: 'Axis',
                          children: [
                            {
                              id: 4038,
                              name: 'Body',
                              children: [
                                {
                                  id: 4039,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 4040,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 4041,
                                          name: '#53612',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 4042,
                              name: 'Axis',
                              children: [
                                {
                                  id: 4043,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 4044,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 4045,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 4046,
                                              name: '#53623',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 16638.9182288135,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 16638.9182288135,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad19d2',
                    },
                    {
                      id: 4047,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:875102',
                      children: [
                        {
                          id: 4048,
                          name: 'Axis',
                          children: [
                            {
                              id: 4049,
                              name: 'Body',
                              children: [
                                {
                                  id: 4050,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 4051,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 4052,
                                          name: '#53880',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 4053,
                              name: 'Axis',
                              children: [
                                {
                                  id: 4054,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 4055,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 4056,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 4057,
                                              name: '#53891',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 14320.5878696237,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 14320.5878696237,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad19d0',
                    },
                    {
                      id: 4058,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:875104',
                      children: [
                        {
                          id: 4059,
                          name: 'Axis',
                          children: [
                            {
                              id: 4060,
                              name: 'Body',
                              children: [
                                {
                                  id: 4061,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 4062,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 4063,
                                          name: '#54148',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 4064,
                              name: 'Axis',
                              children: [
                                {
                                  id: 4065,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 4066,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 4067,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 4068,
                                              name: '#54159',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 12002.2575080512,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 12002.2575080512,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad19ee',
                    },
                    {
                      id: 4069,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:875106',
                      children: [
                        {
                          id: 4070,
                          name: 'Axis',
                          children: [
                            {
                              id: 4071,
                              name: 'Body',
                              children: [
                                {
                                  id: 4072,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 4073,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 4074,
                                          name: '#54416',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 4075,
                              name: 'Axis',
                              children: [
                                {
                                  id: 4076,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 4077,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 4078,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 4079,
                                              name: '#54427',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 9683.92714648434,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 9683.92714648434,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad19ec',
                    },
                    {
                      id: 4080,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:875108',
                      children: [
                        {
                          id: 4081,
                          name: 'Axis',
                          children: [
                            {
                              id: 4082,
                              name: 'Body',
                              children: [
                                {
                                  id: 4083,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 4084,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 4085,
                                          name: '#54684',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 4086,
                              name: 'Axis',
                              children: [
                                {
                                  id: 4087,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 4088,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 4089,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 4090,
                                              name: '#54695',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 7365.5967849235,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 7365.5967849235,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad19ea',
                    },
                    {
                      id: 4091,
                      name: 'KINGSPAN M145065170 PURLIN:03.02.01.01.01.010_KINGSPAN M145065170 PURLIN G10/117:875110',
                      children: [
                        {
                          id: 4092,
                          name: 'Axis',
                          children: [
                            {
                              id: 4093,
                              name: 'Body',
                              children: [
                                {
                                  id: 4094,
                                  name: 'Body',
                                  children: [
                                    {
                                      id: 4095,
                                      name: 'Body',
                                      children: [
                                        {
                                          id: 4096,
                                          name: '#54952',
                                          children: [],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 4097,
                              name: 'Axis',
                              children: [
                                {
                                  id: 4098,
                                  name: 'Axis',
                                  children: [
                                    {
                                      id: 4099,
                                      name: 'Axis',
                                      children: [
                                        {
                                          id: 4100,
                                          name: 'Axis',
                                          children: [
                                            {
                                              id: 4101,
                                              name: '#54963',
                                              children: [],
                                              properties: [],
                                            },
                                          ],
                                          properties: [],
                                        },
                                      ],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Length',
                              displayValue: 6206.43160414557,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Length',
                          displayValue: 6206.43160414557,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad19e8',
                    },
                    {
                      id: 4102,
                      name: 'Floor:03.02.01.02.01.000_170 Reinf Conc Eaves Slab E05:875780',
                      children: [
                        {
                          id: 4103,
                          name: 'FootPrint',
                          children: [
                            {
                              id: 4104,
                              name: 'Body',
                              children: [
                                {
                                  id: 4105,
                                  name: '#55336',
                                  children: [],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                            {
                              id: 4106,
                              name: 'FootPrint',
                              children: [
                                {
                                  id: 4107,
                                  name: 'FootPrint',
                                  children: [
                                    {
                                      id: 4108,
                                      name: '#55349',
                                      children: [],
                                      properties: [],
                                    },
                                  ],
                                  properties: [],
                                },
                              ],
                              properties: [],
                            },
                          ],
                          properties: [
                            {
                              displayName: 'Width',
                              displayValue: 169.999999999996,
                              displayCategory: 'Quantities',
                            },
                          ],
                        },
                      ],
                      properties: [
                        {
                          displayName: 'Width',
                          displayValue: 169.999999999996,
                          displayCategory: 'Quantities',
                        },
                      ],
                      guid: '07d356cb-d15e-4262-b7c8-d77d52ad1e8a',
                    },
                  ],
                  properties: [],
                  guid: 'f29c2cb3-6068-4200-8801-a9e6b06940eb',
                },
              ],
              properties: [],
              guid: 'f29c2cb3-6068-4200-8801-a9e64f9c6b98',
            },
          ],
          properties: [],
          guid: 'f29c2cb3-6068-4200-8801-a9e64f9c6b9b',
        },
      ],
      properties: [],
      guid: 'f29c2cb3-6068-4200-8801-a9e64f9c6b99',
    },
  ],
  properties: [],
  guid: '41112cde-7354-5799-9891-3d972950a6b3',
}

export const SuperstructureTakeOffData: TakeOffItem[] = parseTakeOffData(ModelSuperstructureData)

export const SuperstructureTakeOffTableData: TakeOffTableItem[] = SuperstructureTakeOffData.map((t) =>
  convertToTableItem(t, 'BHR-R5D-401-00-M3-X-0004-D1-08_Entrance Building - Superstructure.ifc')
)
