export interface AdministrationEntry {
  key: number
  id: number
  project: string
  cost_plan: string
  documentation: string
  take_off: string
  schedule: string | number | undefined
  valuations: string
  libraries: string
}

export interface PortfolioBreakdownItems {
  id: number
  key: number
  groupCode: string
  title: string
}

export function emptyPortfolioBreakdownItem() {
  return {
    id: -1,
    key: -1,
    groupCode: '',
    title: '',
  }
}

export const administrationData: AdministrationEntry[] = [
  {
    key: 2,
    id: 2,
    project: 'TrafalgarSq',
    cost_plan: '',
    take_off: '',
    documentation: '',
    schedule: '',
    valuations: '',
    libraries: '',
  },
  {
    key: 3,
    id: 3,
    project: 'Oak Street',
    cost_plan: '',
    take_off: '',
    documentation: '',
    schedule: '',
    valuations: '',
    libraries: '',
  },
  {
    key: 4,
    id: 4,
    project: 'Pnie Street',
    cost_plan: '',
    take_off: '',
    documentation: '',
    schedule: '',
    valuations: '',
    libraries: '',
  },
]

export const portfolioBreakdownData: PortfolioBreakdownItems[] = [
  {
    id: 1,
    key: 1,
    groupCode: '10',
    title: 'CompanyXYZ',
  },
  {
    id: 2,
    key: 2,
    groupCode: '10.B',
    title: 'Building',
  },
  {
    id: 3,
    key: 3,
    groupCode: '10.B.10',
    title: 'Residential',
  },
  {
    id: 4,
    key: 4,
    groupCode: '10.B.20',
    title: 'Hospitals',
  },
  {
    id: 5,
    key: 5,
    groupCode: '10.B.30',
    title: 'Offices',
  },
  {
    id: 6,
    key: 6,
    groupCode: '10.B.40',
    title: 'Retail',
  },
  {
    id: 7,
    key: 7,
    groupCode: '10.B.50',
    title: 'Airport',
  },
  {
    id: 8,
    key: 8,
    groupCode: '10.B.60',
    title: 'Stations',
  },
  {
    id: 9,
    key: 9,
    groupCode: '10.I',
    title: 'Infrastructure',
  },
  {
    id: 10,
    key: 10,
    groupCode: '10.I.10',
    title: 'Roads',
  },
  {
    id: 11,
    key: 11,
    groupCode: '10.I.20',
    title: 'Rails',
  },
  {
    id: 12,
    key: 12,
    groupCode: '10.I.30',
    title: 'Water',
  },
  {
    id: 13,
    key: 13,
    groupCode: '10.I.40',
    title: 'Pipelines',
  },
  {
    id: 14,
    key: 14,
    groupCode: '10.I.50',
    title: 'Tunnels',
  },
  {
    id: 15,
    key: 15,
    groupCode: '10.I.60',
    title: 'Marine',
  },
]
