import React, { useState } from 'react'
import {
  Row,
  Col,
  Divider,
  Radio,
  Tabs,
  Collapse,
  Form,
  Button,
  Select,
  Comment,
  Tooltip,
  Avatar,
  Mentions,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { RootState } from '../../../../context/reducer'
import { useSelector, useDispatch } from 'react-redux'
import {
  DocumentationDetails,
  designPackage,
  discipline,
  ribaStage,
  status,
} from '../../../../data/DocumentationDetails.data'
import { AddComment } from '../../../../context/documentation/Documentation.action'
import moment from 'moment'
import Users from '../../../../data/Users.data'

const TabPane = Tabs.TabPane
const Panel = Collapse.Panel

export default function DocumentationReview() {
  const dispatch = useDispatch()

  const [documentSelected, setDocumentSelected] = useState(2)
  const [commentContent, setCommentContent] = useState('')

  const selectComments = (state: RootState) => state.documentationSection.visibleComments.get(documentSelected)
  const docComments = useSelector(selectComments) || []

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  }

  const onRadioChoose = (event: any) => setDocumentSelected(event.target.value)

  const RenderDocumentsReview = ({ name }: { name: string }) => {
    return (
      <>
        <div style={{ fontSize: '12px' }}>{name}</div>
        <div style={{ display: 'block' }}>
          {DocumentationDetails.filter((dm) => dm.folder === name).map((d, idx) => (
            <Radio value={d.id} key={d.id} style={radioStyle} onChange={(e) => onRadioChoose(e)}>
              {d.file_name}
            </Radio>
          ))}
          <Divider style={{ margin: '14px 0px' }} />
        </div>
      </>
    )
  }

  const ReviewsTableElement = ({ name, nameValue }: { name: string; nameValue: React.ReactNode }) => {
    return (
      <tr>
        <td style={{ width: '50%', textAlign: 'right', paddingRight: '10px', paddingBottom: '5px' }}>{name}</td>
        <td style={{ textAlign: 'left', paddingBottom: '5px' }}>{nameValue}</td>
      </tr>
    )
  }

  const DropdownMaker = ({ placeholder, values }: { placeholder: string; values: string[] }) => {
    return (
      <Select placeholder={placeholder} style={{ width: '100%' }}>
        {values.map((s) => (
          <Select.Option key={s} value={s}>
            {s}
          </Select.Option>
        ))}
      </Select>
    )
  }

  const RenderDocumentsReviewGeneral = ({ header, id }: { header: string; id: number }) => {
    return (
      <>
        {DocumentationDetails.filter((dm) => dm.id === id).map((d) => (
          <table style={{ width: '100%' }} key={header}>
            <tbody>
              <ReviewsTableElement name="Internal Doc Reference Code:" nameValue={d.internal_doc_reference_code} />
              <ReviewsTableElement name="Document Title:" nameValue={d.document_title} />
              <ReviewsTableElement name="File Type:" nameValue={d.file_type} />
              <ReviewsTableElement name="Revision:" nameValue={d.revision} />
              <ReviewsTableElement name="File Name:" nameValue={d.file_name} />
              <ReviewsTableElement
                name="Note:"
                nameValue={<TextArea key="general" name="note" id="note" cols={28} rows={6} defaultValue={d.note} />}
              />
            </tbody>
          </table>
        ))}
      </>
    )
  }

  const RenderDocumentsReviewDetails = ({ header, id }: { header: string; id: number }) => {
    return (
      <>
        {DocumentationDetails.filter((dm) => dm.id === id).map((d) => (
          <table key={header} className={header} style={{ width: '100%' }}>
            <tbody>
              <ReviewsTableElement
                name={'Status: '}
                nameValue={<DropdownMaker placeholder="Select status" values={status} />}
              />
              <ReviewsTableElement name="Location:" nameValue={d.location} />
              <ReviewsTableElement
                name={'Ribastage: '}
                nameValue={<DropdownMaker placeholder="Select RibaStage" values={ribaStage} />}
              />

              <ReviewsTableElement
                name={'Discipline: '}
                nameValue={<DropdownMaker placeholder="Select discipline" values={discipline} />}
              />
              <ReviewsTableElement
                name={'Design Package: '}
                nameValue={<DropdownMaker placeholder="Select package" values={designPackage} />}
              />
              <ReviewsTableElement name="Author:" nameValue={d.author} />
              <ReviewsTableElement name="Organisation:" nameValue={d.organisation} />
            </tbody>
          </table>
        ))}
      </>
    )
  }

  const RenderDocumentsReviewBS1192 = ({ header, id }: { header: string; id: number }) => {
    return (
      <>
        {DocumentationDetails.filter((dm) => dm.id === id).map((d) => (
          <table style={{ width: '100%' }} key={header} className={header}>
            <tbody>
              <ReviewsTableElement name="Project:" nameValue={d.project} />
              <ReviewsTableElement name="Originator:" nameValue={d.originator} />
              <ReviewsTableElement name="Volume/System:" nameValue={d.volume___system} />
              <ReviewsTableElement name="Level/Location:" nameValue={d.level___location} />
              <ReviewsTableElement name="Document Type:" nameValue={d.document_type} />
              <ReviewsTableElement name="Role:" nameValue={d.role} />
              <ReviewsTableElement name="Number:" nameValue={d.number} />
            </tbody>
          </table>
        ))}
      </>
    )
  }

  const RenderDocumentsReviewAdmin = ({ header, id }: { header: string; id: number }) => {
    return (
      <>
        {DocumentationDetails.filter((dm) => dm.id === id).map((d) => (
          <table style={{ width: '100%' }} key={header} className={header}>
            <tbody>
              <ReviewsTableElement name="Published by:" nameValue={d.published_by} />
              <ReviewsTableElement name="Publish date:" nameValue={d.publish_date} />
              <ReviewsTableElement name="Updated by:" nameValue={d.updated_by} />
              <ReviewsTableElement name="Update date:" nameValue={d.update_date} />
            </tbody>
          </table>
        ))}
      </>
    )
  }

  const addComment = (event: any) => {
    event.preventDefault()
    const newComment = {
      id: documentSelected,
      author: 'Steve Brunning',
      avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
      commentContent,
      datetime: moment().fromNow(),
      comments: [],
    }
    dispatch(AddComment(documentSelected, newComment))
    setCommentContent('')
  }

  const CommentsList = ({ comments }: { comments: any[] }) => {
    const actions = [<span key="comment-basic-reply-to">Reply to</span>]
    return (
      <>
        {comments.map((comment) => (
          <Comment
            key={comment.datetime}
            actions={actions}
            author={comment.author}
            avatar={<Avatar src={comment.avatar} alt={comment.author} />}
            content={<p>{comment.commentContent}</p>}
            datetime={
              <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
                <span>{comment.datetime}</span>
              </Tooltip>
            }
          />
        ))}
      </>
    )
  }

  const { Option, getMentions } = Mentions

  return (
    <div>
      <Row>
        <Col span={13}>
          <div
            className="review-documents"
            style={{ margin: '0px', background: '#fff', paddingLeft: '20px', paddingRight: '20px', height: 600 }}
          >
            <div className="title" style={{ paddingTop: '15px', fontSize: '14px', fontWeight: 'bold' }}>
              Documents
            </div>
            <Divider style={{ margin: '12px 0px' }} />
            <Radio.Group defaultValue={documentSelected}>
              <RenderDocumentsReview name="3D models" />
              <RenderDocumentsReview name="2D Drawings" />
              <RenderDocumentsReview name="Documentation" />
              <RenderDocumentsReview name="GIS Data" />
            </Radio.Group>
          </div>
        </Col>
        <Col span={10}>
          <div className="details-wrapper" style={{ marginLeft: '5px', background: '#fff' }}>
            <Tabs style={{ paddingLeft: '20px', paddingRight: '20px' }} defaultActiveKey="details">
              <TabPane
                tab={'Details'}
                key={'details'}
                style={{ fontSize: '14px', lineHeight: '14px', height: 555, overflow: 'scroll', overflowX: 'hidden' }}
              >
                <Collapse className="site-collapse-custom-panel">
                  <Panel forceRender={true} header={'General'} key="general" className="site-collapse-custom-panel">
                    <RenderDocumentsReviewGeneral header="General" id={documentSelected} />
                  </Panel>
                  <Panel header={'Details'} key="details" className="site-collapse-custom-panel">
                    <RenderDocumentsReviewDetails header="General" id={documentSelected} />
                  </Panel>
                  <Panel header={'BS1192'} key="bs1192" className="site-collapse-custom-panel">
                    <RenderDocumentsReviewBS1192 header={'BS1192'} id={documentSelected} />
                  </Panel>
                  <Panel header={'Admin'} key="admin" className="site-collapse-custom-panel">
                    <RenderDocumentsReviewAdmin header={'Admin'} id={documentSelected} />
                  </Panel>
                </Collapse>
              </TabPane>
              <TabPane
                tab={'Comments'}
                key={'comments'}
                style={{ fontSize: '14px', lineHeight: '14px', height: 555, overflow: 'scroll', overflowX: 'hidden' }}
              >
                <div className="comments-sectiond">
                  <CommentsList comments={docComments} />
                  <Form.Item>
                    <Mentions rows={4} onChange={(e) => setCommentContent(e)} value={commentContent}>
                      {Users.map((u) => (
                        <Option value={`${u.name} ${u.surname}`}>{`${u.name} ${u.surname}`} </Option>
                      ))}
                    </Mentions>
                  </Form.Item>
                  <Form.Item>
                    <Button htmlType="submit" type="primary" onClick={addComment}>
                      Add Comment
                    </Button>
                  </Form.Item>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </Col>
      </Row>
    </div>
  )
}
