import React from 'react'
import { FormInstance } from 'antd/lib/form'
import { Col, Form, Row, Select } from 'antd'

export const ChooseTemplateForm = ({ form }: { form?: FormInstance }) => {
  return (
    <Form form={form}>
      <Row gutter={32} style={{ paddingLeft: 32, paddingRight: 32, paddingTop: 16, paddingBottom: 0 }}>
        <Col span={24}>
          <Form.Item name="template" label={'Template'} rules={[{ required: true, message: 'Select template!' }]}>
            <Select placeholder={'Select...'}>
              {Templates.map((t) => (
                <Select.Option value={t} key={t}>
                  {t}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

const Templates = ['In-situ Concrete Wall']
