import React, { useState } from 'react'
import { FormInstance } from 'antd/lib/form'
import { Col, Form, Input, Row, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { TakeOffCostItemsTable } from '../cost-items-table/TakeOffCostItemsTable'
import { calculateTakeOffCostItems } from '../cost-items-table/helpers/TakeOffCostItemsCalculator'
import { TakeOffDetailsItem } from '../../helpers/TakeOffDataParser'

export interface InSituConcreteWallFields {
  provision: string
  placing: string
  reinfRatio: string
  formwork: string
  concreteFinishing: string
  groupByValues: string
  location: string
  description: string
}

export const convertToInSituConcreteWallsFields = (formData: any): InSituConcreteWallFields => {
  return {
    provision: formData ? formData.provision : undefined,
    placing: formData ? formData.placing : undefined,
    reinfRatio: formData ? formData.reinfRatio : undefined,
    formwork: formData ? formData.formwork : undefined,
    concreteFinishing: formData ? formData.concreteFinishing : undefined,
    groupByValues: formData ? formData.groupByValues : undefined,
    location: formData ? formData.location : undefined,
    description: formData ? formData.description : undefined,
  }
}

export const InSituConcreteWallForm = ({
  form,
  takeOffDetailsItems,
}: {
  form?: FormInstance
  takeOffDetailsItems: TakeOffDetailsItem[]
}) => {
  const [values, setValues] = useState()
  return (
    <Form form={form} layout={'vertical'} onValuesChange={(changedValues, allValues) => setValues(allValues)}>
      <Row gutter={32} style={{ paddingLeft: 32, paddingRight: 32, paddingTop: 16, paddingBottom: 0 }}>
        <Col span={12}>
          <Form.Item
            name="provision"
            label={'Provision of concrete'}
            rules={[{ required: true, message: 'Choose provision of concrete' }]}
          >
            <Select placeholder={'Select'}>
              {ProvisionOfConcrete.map((e) => (
                <Select.Option value={e} key={e}>
                  {e}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="placing"
            label={'Placing of concrete'}
            rules={[{ required: true, message: 'Choose placing of concrete!' }]}
          >
            <Select placeholder={'Select'}>
              {PlacingOfConcrete.map((e) => (
                <Select.Option value={e} key={e}>
                  {e}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32} style={{ paddingLeft: 32, paddingRight: 32, paddingTop: 8, paddingBottom: 0 }}>
        <Col span={12}>
          <Form.Item
            name="reinfRatio"
            label={'Reinf ratio'}
            rules={[{ required: true, message: 'Choose reinf ratio!' }]}
          >
            <Select placeholder={'Select'}>
              {ReinfRatio.map((e) => (
                <Select.Option value={e} key={e}>
                  {e}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="formwork" label={'Formwork'} rules={[{ required: true, message: 'Choose formwork!' }]}>
            <Select placeholder={'Select'}>
              {Formwork.map((e) => (
                <Select.Option value={e} key={e}>
                  {e}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32} style={{ paddingLeft: 32, paddingRight: 32, paddingTop: 8, paddingBottom: 0 }}>
        <Col span={12}>
          <Form.Item
            name="concreteFinishing"
            label={'Concrete finishing'}
            rules={[{ required: true, message: 'Choose concrete finishing!' }]}
          >
            <Select placeholder={'Select'}>
              {ConcreteFinishing.map((e) => (
                <Select.Option value={e} key={e}>
                  {e}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="groupBy" label={'Group by'} rules={[{ required: true, message: 'Choose group by!' }]}>
            <Select placeholder={'Select'}>
              {GroupByValues.map((e) => (
                <Select.Option value={e} key={e}>
                  {e}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32} style={{ paddingLeft: 32, paddingRight: 32, paddingTop: 8, paddingBottom: 0 }}>
        <Col span={12}>
          <Form.Item name="location" label={'Location'} rules={[{ required: true, message: 'Insert location!' }]}>
            <Input placeholder={'Choose location'} />
          </Form.Item>
        </Col>
        <Col span={12} />
      </Row>
      <Row gutter={32} style={{ paddingLeft: 32, paddingRight: 32, paddingTop: 8, paddingBottom: 8 }}>
        <Col span={24}>
          <Form.Item name="description" label={'Description'}>
            <TextArea placeholder={'Choose project description'} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <h4>Cost items</h4>
        {form && (
          <TakeOffCostItemsTable
            dataSource={calculateTakeOffCostItems(convertToInSituConcreteWallsFields(values), takeOffDetailsItems)}
          />
        )}
      </Row>
    </Form>
  )
}

const ProvisionOfConcrete = ['C20/25', 'C25/30', 'C28/35', 'C30/37', 'C32/40', 'C35/45', 'C40/50']
const PlacingOfConcrete = ["T'ness: n.e. 150mm", "T'ness: 150-300mm", "T'ness: 300-500mm", "T'ness: > 500mm"]
const ReinfRatio = ['100', '125', '150', '175', '200', '225', '250', '275', '300', '350']
const Formwork = ['Width: 0.2-0.4m', 'Width: 0.4-1.22m', 'Width: > 1.22m']
const ConcreteFinishing = [
  'Agg. exposure using retarder',
  'Bush hammering',
  'Other stated surface treatment carried out after striking formwork',
]
const GroupByValues = ['Wall thickness']
