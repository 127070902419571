export const takeoffKeys = [
  'Excavation',
  'Piling',
  'In-situ Concrete',
  'Steel works',
  'Cladding',
  'Precast',
  'Insulation',
  'Roof',
  'Barriers',
  'Windows',
  'Doors',
  'Partitions',
  'Walls',
  'Flooring',
  'Ceilings',
  'FFE',
  'Sanitary',
  'Lift',
]

export interface QuoteItem {
  boqId: number
  quoteId: number
  quantity: number
  prop_rate: number
  prop_total: number
  total_cost_difference: number
}

export function EmptyQuoteItemFor(boqId: number, quoteId: number): any {
  const x = {
    boqId,
    quoteId,
    quantity: 0,
  }
  // @ts-ignore
  x[`proposed_rate_${quoteId}`] = 0
  // @ts-ignore
  x[`proposed_total_${quoteId}`] = 0
  // @ts-ignore
  x[`total_cost_difference_${quoteId}`] = 0
  return x
}

export interface Quote {
  name: string
  id: number
  isAwarded: boolean
  items: QuoteItem[]
}

export const QuoteItems: any[] = [
  {
    boqId: 305,
    quoteId: 1,
    quantity: 685.22,
    proposed_rate_1: 90,
    proposed_total_1: 61669.6,
    total_cost_difference_1: 1713.04,
    negotiated_rate_1: 89,
    negotiated_rate_percent_1: 1.11,
    negotiated_total_1: 60984.38,
    proposed_to_negotiated_difference_1: -685.22,
    adjustment_percent_1: 2,
    adjustment_proposed_value_1: 1233.39,
    adjustment_negotiated_value_1: 1219.69,
    proposed_total_with_adjustment_1: 62902.99,
    negotiated_total_with_adjustment_1: 62204.07,
  },
  {
    boqId: 306,
    quoteId: 1,
    quantity: 513.91,
    proposed_rate_1: 0,
    proposed_total_1: 0,
    total_cost_difference_1: 0,
    negotiated_rate_1: 0,
    negotiated_rate_percent_1: 0,
    negotiated_total_1: 0,
    proposed_to_negotiated_difference_1: 0,
    adjustment_percent_1: 2,
    adjustment_proposed_value_1: 0,
    adjustment_negotiated_value_1: 0,
    proposed_total_with_adjustment_1: 0,
    negotiated_total_with_adjustment_1: 0,
  },
  {
    boqId: 307,
    quoteId: 1,
    quantity: 719.83,
    proposed_rate_1: 14.5,
    proposed_total_1: 10437.55,
    total_cost_difference_1: -359.92,
    negotiated_rate_1: 12.5,
    negotiated_rate_percent_1: 13.79,
    negotiated_total_1: 8997.88,
    proposed_to_negotiated_difference_1: -1439.66,
    adjustment_percent_1: 2,
    adjustment_proposed_value_1: 208.75,
    adjustment_negotiated_value_1: 179.96,
    proposed_total_with_adjustment_1: 10646.3,
    negotiated_total_with_adjustment_1: 9177.84,
  },
  {
    boqId: 309,
    quoteId: 1,
    quantity: 161.72,
    proposed_rate_1: 89,
    proposed_total_1: 14393.49,
    total_cost_difference_1: 242.59,
    negotiated_rate_1: 89,
    negotiated_rate_percent_1: 0,
    negotiated_total_1: 14393.49,
    proposed_to_negotiated_difference_1: 0,
    adjustment_percent_1: 2,
    adjustment_proposed_value_1: 287.87,
    adjustment_negotiated_value_1: 287.87,
    proposed_total_with_adjustment_1: 14681.36,
    negotiated_total_with_adjustment_1: 14681.36,
  },
  {
    boqId: 310,
    quoteId: 1,
    quantity: 161.72,
    proposed_rate_1: 12.5,
    proposed_total_1: 2021.56,
    total_cost_difference_1: 0,
    negotiated_rate_1: 12.5,
    negotiated_rate_percent_1: 0,
    negotiated_total_1: 2021.56,
    proposed_to_negotiated_difference_1: 0,
    adjustment_percent_1: 2,
    adjustment_proposed_value_1: 40.43,
    adjustment_negotiated_value_1: 40.43,
    proposed_total_with_adjustment_1: 2061.99,
    negotiated_total_with_adjustment_1: 2061.99,
  },
  {
    boqId: 311,
    quoteId: 1,
    quantity: 242.59,
    proposed_rate_1: 0,
    proposed_total_1: 0,
    total_cost_difference_1: 0,
    negotiated_rate_1: 0,
    negotiated_rate_percent_1: 0,
    negotiated_total_1: 0,
    proposed_to_negotiated_difference_1: 0,
    adjustment_percent_1: 2,
    adjustment_proposed_value_1: 0,
    adjustment_negotiated_value_1: 0,
    proposed_total_with_adjustment_1: 0,
    negotiated_total_with_adjustment_1: 0,
  },
  {
    boqId: 312,
    quoteId: 1,
    quantity: 49.54,
    proposed_rate_1: 21,
    proposed_total_1: 1040.27,
    total_cost_difference_1: 0,
    negotiated_rate_1: 21,
    negotiated_rate_percent_1: 0,
    negotiated_total_1: 1040.27,
    proposed_to_negotiated_difference_1: 0,
    adjustment_percent_1: -5,
    adjustment_proposed_value_1: -52.01,
    adjustment_negotiated_value_1: -52.01,
    proposed_total_with_adjustment_1: 988.26,
    negotiated_total_with_adjustment_1: 988.26,
  },
  {
    boqId: 313,
    quoteId: 1,
    quantity: 167.66,
    proposed_rate_1: 14.5,
    proposed_total_1: 2431.03,
    total_cost_difference_1: -83.83,
    negotiated_rate_1: 12.5,
    negotiated_rate_percent_1: 13.79,
    negotiated_total_1: 2095.71,
    proposed_to_negotiated_difference_1: -335.31,
    adjustment_percent_1: 2,
    adjustment_proposed_value_1: 48.62,
    adjustment_negotiated_value_1: 41.91,
    proposed_total_with_adjustment_1: 2479.65,
    negotiated_total_with_adjustment_1: 2137.63,
  },
  {
    boqId: 315,
    quoteId: 1,
    quantity: 613.84,
    proposed_rate_1: 101,
    proposed_total_1: 61997.81,
    total_cost_difference_1: 8286.84,
    negotiated_rate_1: 99,
    negotiated_rate_percent_1: 1.98,
    negotiated_total_1: 60770.13,
    proposed_to_negotiated_difference_1: -1227.68,
    adjustment_percent_1: 2,
    adjustment_proposed_value_1: 1239.96,
    adjustment_negotiated_value_1: 1215.4,
    proposed_total_with_adjustment_1: 63237.77,
    negotiated_total_with_adjustment_1: 61985.54,
  },
  {
    boqId: 316,
    quoteId: 1,
    quantity: 613.84,
    proposed_rate_1: 12.5,
    proposed_total_1: 7673,
    total_cost_difference_1: 0,
    negotiated_rate_1: 12.5,
    negotiated_rate_percent_1: 0,
    negotiated_total_1: 7673,
    proposed_to_negotiated_difference_1: 0,
    adjustment_percent_1: 2,
    adjustment_proposed_value_1: 153.46,
    adjustment_negotiated_value_1: 153.46,
    proposed_total_with_adjustment_1: 7826.46,
    negotiated_total_with_adjustment_1: 7826.46,
  },
  {
    boqId: 317,
    quoteId: 1,
    quantity: 920.76,
    proposed_rate_1: 0,
    proposed_total_1: 0,
    total_cost_difference_1: 0,
    negotiated_rate_1: 0,
    negotiated_rate_percent_1: 0,
    negotiated_total_1: 0,
    proposed_to_negotiated_difference_1: 0,
    adjustment_percent_1: 2,
    adjustment_proposed_value_1: 0,
    adjustment_negotiated_value_1: 0,
    proposed_total_with_adjustment_1: 0,
    negotiated_total_with_adjustment_1: 0,
  },
  {
    boqId: 318,
    quoteId: 1,
    quantity: 158.81,
    proposed_rate_1: 21,
    proposed_total_1: 3334.97,
    total_cost_difference_1: 0,
    negotiated_rate_1: 21,
    negotiated_rate_percent_1: 0,
    negotiated_total_1: 3334.97,
    proposed_to_negotiated_difference_1: 0,
    adjustment_percent_1: -5,
    adjustment_proposed_value_1: -166.75,
    adjustment_negotiated_value_1: -166.75,
    proposed_total_with_adjustment_1: 3168.23,
    negotiated_total_with_adjustment_1: 3168.23,
  },
  {
    boqId: 319,
    quoteId: 1,
    quantity: 632.88,
    proposed_rate_1: 14.5,
    proposed_total_1: 9176.74,
    total_cost_difference_1: -316.44,
    negotiated_rate_1: 12.5,
    negotiated_rate_percent_1: 13.79,
    negotiated_total_1: 7910.98,
    proposed_to_negotiated_difference_1: -1265.76,
    adjustment_percent_1: 2,
    adjustment_proposed_value_1: 183.53,
    adjustment_negotiated_value_1: 158.22,
    proposed_total_with_adjustment_1: 9360.27,
    negotiated_total_with_adjustment_1: 8069.2,
  },
  {
    boqId: 321,
    quoteId: 1,
    quantity: 164.7,
    proposed_rate_1: 94.5,
    proposed_total_1: 15564.25,
    total_cost_difference_1: 0,
    negotiated_rate_1: 94.5,
    negotiated_rate_percent_1: 0,
    negotiated_total_1: 15564.25,
    proposed_to_negotiated_difference_1: 0,
    adjustment_percent_1: 2,
    adjustment_proposed_value_1: 311.28,
    adjustment_negotiated_value_1: 311.28,
    proposed_total_with_adjustment_1: 15875.53,
    negotiated_total_with_adjustment_1: 15875.53,
  },
  {
    boqId: 323,
    quoteId: 1,
    quantity: 159.03,
    proposed_rate_1: 112,
    proposed_total_1: 17810.89,
    total_cost_difference_1: -1908.31,
    negotiated_rate_1: 112,
    negotiated_rate_percent_1: 0,
    negotiated_total_1: 17810.89,
    proposed_to_negotiated_difference_1: 0,
    adjustment_percent_1: 2,
    adjustment_proposed_value_1: 356.22,
    adjustment_negotiated_value_1: 356.22,
    proposed_total_with_adjustment_1: 18167.11,
    negotiated_total_with_adjustment_1: 18167.11,
  },
  {
    boqId: 326,
    quoteId: 1,
    quantity: 15,
    proposed_rate_1: 305,
    proposed_total_1: 4575,
    total_cost_difference_1: -450,
    negotiated_rate_1: 305,
    negotiated_rate_percent_1: 0,
    negotiated_total_1: 4575,
    proposed_to_negotiated_difference_1: 0,
    adjustment_percent_1: 2,
    adjustment_proposed_value_1: 91.5,
    adjustment_negotiated_value_1: 91.5,
    proposed_total_with_adjustment_1: 4666.5,
    negotiated_total_with_adjustment_1: 4666.5,
  },
  {
    boqId: 328,
    quoteId: 1,
    quantity: 119.06,
    proposed_rate_1: 101,
    proposed_total_1: 12024.69,
    total_cost_difference_1: 238.11,
    negotiated_rate_1: 99,
    negotiated_rate_percent_1: 1.98,
    negotiated_total_1: 11786.57,
    proposed_to_negotiated_difference_1: -238.11,
    adjustment_percent_1: 2,
    adjustment_proposed_value_1: 240.49,
    adjustment_negotiated_value_1: 235.73,
    proposed_total_with_adjustment_1: 12265.18,
    negotiated_total_with_adjustment_1: 12022.31,
  },
  {
    boqId: 330,
    quoteId: 1,
    quantity: 18.06,
    proposed_rate_1: 91,
    proposed_total_1: 1643.3,
    total_cost_difference_1: -90.29,
    negotiated_rate_1: 89,
    negotiated_rate_percent_1: 2.2,
    negotiated_total_1: 1607.19,
    proposed_to_negotiated_difference_1: -36.12,
    adjustment_percent_1: 2,
    adjustment_proposed_value_1: 32.87,
    adjustment_negotiated_value_1: 32.14,
    proposed_total_with_adjustment_1: 1676.17,
    negotiated_total_with_adjustment_1: 1639.33,
  },
  {
    boqId: 305,
    quoteId: 2,
    quantity: 685.22,
    proposed_rate_2: 89,
    proposed_total_2: 60984.38,
    total_cost_difference_2: 1027.83,
    negotiated_rate_2: 89,
    negotiated_rate_percent_2: 0,
    negotiated_total_2: 60984.38,
    proposed_to_negotieated_difference_2: 0,
    adjustment_percent_2: 1,
    adjustment_proposed_value_2: 609.84,
    adjustment_negotiated_value_2: 609.84,
    proposed_total_with_adjustment_2: 61594.22,
    negotiated_total_with_adjustment_2: 61594.22,
  },
  {
    boqId: 306,
    quoteId: 2,
    quantity: 513.91,
    proposed_rate_2: 0,
    proposed_total_2: 0,
    total_cost_difference_2: 0,
    negotiated_rate_2: 0,
    negotiated_rate_percent_2: 0,
    negotiated_total_2: 0,
    proposed_to_negotieated_difference_2: 0,
    adjustment_percent_2: 1,
    adjustment_proposed_value_2: 0,
    adjustment_negotiated_value_2: 0,
    proposed_total_with_adjustment_2: 0,
    negotiated_total_with_adjustment_2: 0,
  },
  {
    boqId: 307,
    quoteId: 2,
    quantity: 719.83,
    proposed_rate_2: 12,
    proposed_total_2: 8637.97,
    total_cost_difference_2: -2159.49,
    negotiated_rate_2: 11.5,
    negotiated_rate_percent_2: 4.17,
    negotiated_total_2: 8278.05,
    proposed_to_negotieated_difference_2: -359.92,
    adjustment_percent_2: 1,
    adjustment_proposed_value_2: 86.38,
    adjustment_negotiated_value_2: 82.78,
    proposed_total_with_adjustment_2: 8724.35,
    negotiated_total_with_adjustment_2: 8360.83,
  },
  {
    boqId: 309,
    quoteId: 2,
    quantity: 161.72,
    proposed_rate_2: 89,
    proposed_total_2: 14393.49,
    total_cost_difference_2: 242.59,
    negotiated_rate_2: 89,
    negotiated_rate_percent_2: 0,
    negotiated_total_2: 14393.49,
    proposed_to_negotieated_difference_2: 0,
    adjustment_percent_2: 1,
    adjustment_proposed_value_2: 143.93,
    adjustment_negotiated_value_2: 143.93,
    proposed_total_with_adjustment_2: 14537.42,
    negotiated_total_with_adjustment_2: 14537.42,
  },
  {
    boqId: 310,
    quoteId: 2,
    quantity: 161.72,
    proposed_rate_2: 12,
    proposed_total_2: 1940.69,
    total_cost_difference_2: -80.86,
    negotiated_rate_2: 11.5,
    negotiated_rate_percent_2: 4.17,
    negotiated_total_2: 1859.83,
    proposed_to_negotieated_difference_2: -80.86,
    adjustment_percent_2: 1,
    adjustment_proposed_value_2: 19.41,
    adjustment_negotiated_value_2: 18.6,
    proposed_total_with_adjustment_2: 1960.1,
    negotiated_total_with_adjustment_2: 1878.43,
  },
  {
    boqId: 311,
    quoteId: 2,
    quantity: 242.59,
    proposed_rate_2: 0,
    proposed_total_2: 0,
    total_cost_difference_2: 0,
    negotiated_rate_2: 0,
    negotiated_rate_percent_2: 0,
    negotiated_total_2: 0,
    proposed_to_negotieated_difference_2: 0,
    adjustment_percent_2: 1,
    adjustment_proposed_value_2: 0,
    adjustment_negotiated_value_2: 0,
    proposed_total_with_adjustment_2: 0,
    negotiated_total_with_adjustment_2: 0,
  },
  {
    boqId: 312,
    quoteId: 2,
    quantity: 49.54,
    proposed_rate_2: 21,
    proposed_total_2: 1040.27,
    total_cost_difference_2: 0,
    negotiated_rate_2: 21,
    negotiated_rate_percent_2: 0,
    negotiated_total_2: 1040.27,
    proposed_to_negotieated_difference_2: 0,
    adjustment_percent_2: -5,
    adjustment_proposed_value_2: -52.01,
    adjustment_negotiated_value_2: -52.01,
    proposed_total_with_adjustment_2: 988.26,
    negotiated_total_with_adjustment_2: 988.26,
  },
  {
    boqId: 313,
    quoteId: 2,
    quantity: 167.66,
    proposed_rate_2: 12,
    proposed_total_2: 2011.89,
    total_cost_difference_2: -502.97,
    negotiated_rate_2: 11.5,
    negotiated_rate_percent_2: 4.17,
    negotiated_total_2: 1928.06,
    proposed_to_negotieated_difference_2: -83.83,
    adjustment_percent_2: 1,
    adjustment_proposed_value_2: 20.12,
    adjustment_negotiated_value_2: 19.28,
    proposed_total_with_adjustment_2: 2032,
    negotiated_total_with_adjustment_2: 1947.34,
  },
  {
    boqId: 315,
    quoteId: 2,
    quantity: 613.84,
    proposed_rate_2: 89,
    proposed_total_2: 54631.74,
    total_cost_difference_2: 920.76,
    negotiated_rate_2: 89,
    negotiated_rate_percent_2: 0,
    negotiated_total_2: 54631.74,
    proposed_to_negotieated_difference_2: 0,
    adjustment_percent_2: 1,
    adjustment_proposed_value_2: 546.32,
    adjustment_negotiated_value_2: 546.32,
    proposed_total_with_adjustment_2: 55178.05,
    negotiated_total_with_adjustment_2: 55178.05,
  },
  {
    boqId: 316,
    quoteId: 2,
    quantity: 613.84,
    proposed_rate_2: 12,
    proposed_total_2: 7366.08,
    total_cost_difference_2: -306.92,
    negotiated_rate_2: 11.5,
    negotiated_rate_percent_2: 4.17,
    negotiated_total_2: 7059.16,
    proposed_to_negotieated_difference_2: -306.92,
    adjustment_percent_2: 1,
    adjustment_proposed_value_2: 73.66,
    adjustment_negotiated_value_2: 70.59,
    proposed_total_with_adjustment_2: 7439.74,
    negotiated_total_with_adjustment_2: 7129.75,
  },
  {
    boqId: 317,
    quoteId: 2,
    quantity: 920.76,
    proposed_rate_2: 0,
    proposed_total_2: 0,
    total_cost_difference_2: 0,
    negotiated_rate_2: 0,
    negotiated_rate_percent_2: 0,
    negotiated_total_2: 0,
    proposed_to_negotieated_difference_2: 0,
    adjustment_percent_2: 1,
    adjustment_proposed_value_2: 0,
    adjustment_negotiated_value_2: 0,
    proposed_total_with_adjustment_2: 0,
    negotiated_total_with_adjustment_2: 0,
  },
  {
    boqId: 318,
    quoteId: 2,
    quantity: 158.81,
    proposed_rate_2: 21,
    proposed_total_2: 3334.97,
    total_cost_difference_2: 0,
    negotiated_rate_2: 21,
    negotiated_rate_percent_2: 0,
    negotiated_total_2: 3334.97,
    proposed_to_negotieated_difference_2: 0,
    adjustment_percent_2: -5,
    adjustment_proposed_value_2: -166.75,
    adjustment_negotiated_value_2: -166.75,
    proposed_total_with_adjustment_2: 3168.23,
    negotiated_total_with_adjustment_2: 3168.23,
  },
  {
    boqId: 319,
    quoteId: 2,
    quantity: 632.88,
    proposed_rate_2: 12,
    proposed_total_2: 7594.54,
    total_cost_difference_2: -1898.64,
    negotiated_rate_2: 11.5,
    negotiated_rate_percent_2: 4.17,
    negotiated_total_2: 7278.1,
    proposed_to_negotieated_difference_2: -316.44,
    adjustment_percent_2: 1,
    adjustment_proposed_value_2: 75.95,
    adjustment_negotiated_value_2: 72.78,
    proposed_total_with_adjustment_2: 7670.49,
    negotiated_total_with_adjustment_2: 7350.88,
  },
  {
    boqId: 321,
    quoteId: 2,
    quantity: 164.7,
    proposed_rate_2: 89,
    proposed_total_2: 14658.39,
    total_cost_difference_2: -905.86,
    negotiated_rate_2: 89,
    negotiated_rate_percent_2: 0,
    negotiated_total_2: 14658.39,
    proposed_to_negotieated_difference_2: 0,
    adjustment_percent_2: 1,
    adjustment_proposed_value_2: 146.58,
    adjustment_negotiated_value_2: 146.58,
    proposed_total_with_adjustment_2: 14804.98,
    negotiated_total_with_adjustment_2: 14804.98,
  },
  {
    boqId: 323,
    quoteId: 2,
    quantity: 159.03,
    proposed_rate_2: 135,
    proposed_total_2: 21468.48,
    total_cost_difference_2: 1749.28,
    negotiated_rate_2: 125,
    negotiated_rate_percent_2: 7.41,
    negotiated_total_2: 19878.23,
    proposed_to_negotieated_difference_2: -1590.26,
    adjustment_percent_2: -5,
    adjustment_proposed_value_2: -1073.42,
    adjustment_negotiated_value_2: -993.91,
    proposed_total_with_adjustment_2: 20395.06,
    negotiated_total_with_adjustment_2: 18884.32,
  },
  {
    boqId: 326,
    quoteId: 2,
    quantity: 15,
    proposed_rate_2: 350,
    proposed_total_2: 5250,
    total_cost_difference_2: 225,
    negotiated_rate_2: 305,
    negotiated_rate_percent_2: 12.86,
    negotiated_total_2: 4575,
    proposed_to_negotieated_difference_2: -675,
    adjustment_percent_2: -5,
    adjustment_proposed_value_2: -262.5,
    adjustment_negotiated_value_2: -228.75,
    proposed_total_with_adjustment_2: 4987.5,
    negotiated_total_with_adjustment_2: 4346.25,
  },
  {
    boqId: 328,
    quoteId: 2,
    quantity: 119.06,
    proposed_rate_2: 89,
    proposed_total_2: 10596.01,
    total_cost_difference_2: -1190.56,
    negotiated_rate_2: 89,
    negotiated_rate_percent_2: 0,
    negotiated_total_2: 10596.01,
    proposed_to_negotieated_difference_2: 0,
    adjustment_percent_2: 1,
    adjustment_proposed_value_2: 105.96,
    adjustment_negotiated_value_2: 105.96,
    proposed_total_with_adjustment_2: 10701.97,
    negotiated_total_with_adjustment_2: 10701.97,
  },
  {
    boqId: 330,
    quoteId: 2,
    quantity: 18.06,
    proposed_rate_2: 89,
    proposed_total_2: 1607.19,
    total_cost_difference_2: -126.41,
    negotiated_rate_2: 89,
    negotiated_rate_percent_2: 0,
    negotiated_total_2: 1607.19,
    proposed_to_negotieated_difference_2: 0,
    adjustment_percent_2: 1,
    adjustment_proposed_value_2: 16.07,
    adjustment_negotiated_value_2: 16.07,
    proposed_total_with_adjustment_2: 1623.26,
    negotiated_total_with_adjustment_2: 1623.26,
  },
]

export const Quotes: Quote[] = [
  {
    name: 'Pelcoo Partitions',
    id: 1,
    isAwarded: true,
    items: QuoteItems.filter((q) => q.quoteId === 1),
  },
  {
    name: 'ACS Partitions Lnd',
    id: 2,
    isAwarded: false,
    items: QuoteItems.filter((q) => q.quoteId === 2),
  },
]
