import React, { FunctionComponent, useState } from 'react'
import { Input, Modal } from 'antd'

interface WorkPackagesDetailsModalProps {
  isModalVisible: boolean
  addNewQuote: (name: string) => void
  onOk: () => void
  onCancel: () => void
}

const WorkPackagesDetailsModal: FunctionComponent<WorkPackagesDetailsModalProps> = ({
  isModalVisible,
  addNewQuote,
  onOk,
  onCancel,
}) => {
  const [newQuoteName, setNewQuoteName] = useState('')

  return (
    <Modal
      title="New Quote"
      visible={isModalVisible}
      onOk={() => {
        addNewQuote(newQuoteName)
        onOk()
      }}
      onCancel={() => {
        onCancel()
      }}
    >
      <Input
        value={newQuoteName}
        placeholder={'Enter new Quote Name'}
        onChange={(event) => setNewQuoteName(event.target.value)}
      />
    </Modal>
  )
}

export default WorkPackagesDetailsModal
