import React from 'react'
import { Col, Form, Input, Row, Select } from 'antd'
import { Clients, ProjectRegions } from '../../../../data/Projects.data'
import AddProjectFormPart from './AddProjectFormPart'

const Client: AddProjectFormPart = ({ form }) => {
  return (
    <Form form={form} layout={'vertical'}>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item name={'client'} label={'Client'} rules={[{ required: true, message: 'Select client!' }]}>
            <Select showSearch={true} placeholder={'Select client'}>
              {Clients.map((c) => (
                <Select.Option key={c} value={c}>
                  {c}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'region'} label={'Region'} rules={[{ required: true, message: 'Select region!' }]}>
            <Select showSearch={true} placeholder={'Select region'}>
              {ProjectRegions.map((r) => (
                <Select.Option key={r} value={r}>
                  {r}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item name={'division'} label={'Division'} rules={[{ required: true, message: 'Enter division!' }]}>
            <Input placeholder={'Enter division'} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={'responsible'}
            label={'Responsible'}
            rules={[{ required: true, message: 'Enter responsible person name!' }]}
          >
            <Input placeholder={'Enter responsible person'} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default Client
