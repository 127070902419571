import React, { FunctionComponent, useEffect, useState } from 'react'
import { Row, Col, Steps, Space, Button, Divider } from 'antd'
import FormPart from '../../basic/form/FormPart'
import GeneralInformation from './parts/GeneralInformation'
import Client from './parts/Client'
import Properties from './parts/Properties'
import Location from './parts/Location'
import { ClientFields, GeneralInformationFields, LocationFields, PropertiesFields } from './parts/AddProjectFormPart'
import { Project } from '../../../model/Projects'
import cities from 'cities.json'
import countries from 'typed-countries'
import { useDispatch } from 'react-redux'
import { addProject } from '../../../context/projects/Projects.actions'
import { Moment } from 'moment'

interface AddProjectFormProps {
  closeModal: () => void
}

const AddProjectForm: FunctionComponent<AddProjectFormProps> = ({ closeModal }) => {
  const [current, changeCurrent] = useState(0)
  const [nextClicked, setNextClicked] = useState(false)
  const [prevClicked, setPrevClicked] = useState(false)

  const [generalInformation, setGeneralInformation] = useState<GeneralInformationFields | null>(null)
  const [clientInformation, setClientInformation] = useState<ClientFields | null>(null)
  const [locationInformation, setLocationInformation] = useState<LocationFields | null>(null)
  const [propertiesInformation, setPropertiesInformation] = useState<PropertiesFields | null>(null)

  const [formReady, setFormReady] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (formReady) {
      dispatch(addProject(projectFromFormParts()))
      closeModal()
    }
    setFormReady(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formReady])

  const prev = () => setPrevClicked(!prevClicked)
  const next = () => setNextClicked(!nextClicked)

  const proceedToNext = (formData: any) => {
    switch (current) {
      case 0:
        setGeneralInformation({
          category: formData.category,
          projectType: formData.type,
          name: formData.name,
          id: formData.code,
          start: (formData.projectDates[0] as Moment).format('YYYY-MM-DD'),
          end: (formData.projectDates[1] as Moment).format('YYYY-MM-DD'),
        })
        break
      case 1:
        setClientInformation({
          client: formData.client,
          region: formData.region,
        })
        break
      case 2:
        setLocationInformation({
          country: formData.country,
          city: formData.city,
        })
        break
      default:
        break
    }
    changeCurrent(current + 1)
  }
  const proceedToPrev = () => changeCurrent(current - 1)

  const projectFromFormParts: () => Project = () => {
    const country = countries.find((c) => c.name === locationInformation?.country)
    const location = (cities as any[]).find((c) => c.name === locationInformation?.city && c.country === country?.iso)
    return {
      key: generalInformation?.id,
      id: generalInformation?.id,
      name: generalInformation?.name,
      type: generalInformation?.projectType,
      division: generalInformation?.category,
      client: clientInformation?.client,
      region: clientInformation?.region,
      status: propertiesInformation?.stage,
      lat: location.lat,
      lng: location.lng,
      manager: propertiesInformation?.manager,
      value: '£ 0.00',
      start: generalInformation?.start,
      end: generalInformation?.end,
    } as Project
  }

  const saveFormAndCloseModal = (formData: any) => {
    setPropertiesInformation({
      manager: formData.manager,
      stage: formData.stage,
    })
    setFormReady(true)
  }

  return (
    <>
      <Row style={{ padding: 32 }}>
        <Col span={6}>
          <Steps progressDot={true} direction={'vertical'} size={'small'} current={current}>
            <Steps.Step title={'General Information'} />
            <Steps.Step title={'Client'} />
            <Steps.Step title={'Location'} />
            <Steps.Step title={'Properties'} />
          </Steps>
        </Col>
        <Col span={18}>
          <FormPart
            prevClicked={prevClicked}
            proceedToPrev={proceedToPrev}
            nextClicked={nextClicked}
            proceedToNext={current < 3 ? proceedToNext : saveFormAndCloseModal}
          >
            {
              [
                <GeneralInformation key={'general-information'} />,
                <Client key={'client'} />,
                <Location key={'location'} />,
                <Properties key={'properties'} />,
              ][current]
            }
          </FormPart>
        </Col>
      </Row>
      <Divider />
      <Row justify={'end'}>
        <Space style={{ marginRight: 32 }}>
          <Button disabled={current === 0} onClick={prev}>
            Previous
          </Button>
          <Button type={'primary'} onClick={next}>
            {current < 3 ? 'Next' : 'Submit'}
          </Button>
        </Space>
      </Row>
    </>
  )
}

export default AddProjectForm
