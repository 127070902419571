export interface CESMM4Item {
  id: number
  itemCode: string
  title: string
  notes?: string
  description?: string
}

export function emptyCESMM4Item() {
  return { id: -1, itemCode: '', title: '', notes: '', description: '' }
}

export const cesmm4Data: CESMM4Item[] = [
  {
    id: 1,
    itemCode: 'A',
    title: 'CLASS A: GENERAL ITEMS',
    notes: '',
    description: '',
  },
  {
    id: 2,
    itemCode: 'A.1',
    title: 'Contractual Requirements',
    notes: '',
    description: '',
  },
  {
    id: 3,
    itemCode: 'A.2',
    title: 'Specified Requirements',
    notes: '',
    description: '',
  },
  {
    id: 4,
    itemCode: 'A.3',
    title: 'Method-Related Charges',
    notes: '',
    description: '',
  },
  {
    id: 5,
    itemCode: 'A.4',
    title: 'Provisional Sums',
    notes: '',
    description: '',
  },
  {
    id: 6,
    itemCode: 'A.5',
    title: 'Nominated Sub-contracts which include Work on the Site',
    notes: '',
    description: '',
  },
  {
    id: 7,
    itemCode: 'A.6',
    title: 'Nominated Sub-contracts which do not include Work on the Site',
    notes: '',
    description: '',
  },
  {
    id: 8,
    itemCode: 'B',
    title: 'CLASS B: GROUND INVESTIGATION',
    notes: '',
    description: '',
  },
  {
    id: 9,
    itemCode: 'B.1',
    title: 'Trial Pits and Trenches',
    notes: '',
    description: '',
  },
  {
    id: 10,
    itemCode: 'B.2',
    title: 'Light Cable Percussion Boreholes',
    notes: '',
    description: '',
  },
  {
    id: 11,
    itemCode: 'B.3',
    title: 'Rotary Drilled Boreholes',
    notes: '',
    description: '',
  },
  {
    id: 12,
    itemCode: 'B.4',
    title: 'Samples',
    notes: '',
    description: '',
  },
  {
    id: 13,
    itemCode: 'B.5',
    title: 'Site Tests and Observations',
    notes: '',
    description: '',
  },
  {
    id: 14,
    itemCode: 'B.6',
    title: 'Instrumental Observations',
    notes: '',
    description: '',
  },
  {
    id: 15,
    itemCode: 'B.7',
    title: 'Laboratory Tests',
    notes: '',
    description: '',
  },
  {
    id: 16,
    itemCode: 'B.8',
    title: 'Professional Services',
    notes: '',
    description: '',
  },
  {
    id: 17,
    itemCode: 'C',
    title: 'CLASS C: GEOTECHNICAL AND OTHER SPECIALIST PROCESSES',
    notes: '',
    description: '',
  },
  {
    id: 18,
    itemCode: 'C.1',
    title: 'Drilling for Grout Holes through Material other than Rock or Artificial Hard Material',
    notes: '',
    description: '',
  },
  {
    id: 19,
    itemCode: 'C.2',
    title: 'Drilling for Grout Holes through Rock or Artificial Hard Material',
    notes: '',
    description: '',
  },
  {
    id: 20,
    itemCode: 'C.3',
    title: 'Driving Injection Pipes for Grout Holes',
    notes: '',
    description: '',
  },
  {
    id: 21,
    itemCode: 'C.4',
    title: 'Grout Holes Materials and Injection',
    notes: '',
    description: '',
  },
  {
    id: 22,
    itemCode: 'C.5',
    title: 'Diaphragm Walls',
    notes: '',
    description: '',
  },
  {
    id: 23,
    itemCode: 'C.6',
    title: 'Ground Reinforcement',
    notes: '',
    description: '',
  },
  {
    id: 24,
    itemCode: 'C.7',
    title: 'Sand, Band and Wick Drains',
    notes: '',
    description: '',
  },
  {
    id: 25,
    itemCode: 'C.8',
    title: 'Vibro Flotation',
    notes: '',
    description: '',
  },
  {
    id: 26,
    itemCode: 'D',
    title: 'CLASS D: DEMOLITION AND SITE CLEARANCE',
    notes: '',
    description: '',
  },
  {
    id: 27,
    itemCode: 'D.1',
    title: 'Site Clearance',
    notes: '',
    description: '',
  },
  {
    id: 28,
    itemCode: 'D.2',
    title: 'Trees',
    notes: '',
    description: '',
  },
  {
    id: 29,
    itemCode: 'D.3',
    title: 'Stumps',
    notes: '',
    description: '',
  },
  {
    id: 30,
    itemCode: 'D.4',
    title: 'Buildings',
    notes: '',
    description: '',
  },
  {
    id: 31,
    itemCode: 'D.5',
    title: 'Other Structures',
    notes: '',
    description: '',
  },
  {
    id: 32,
    itemCode: 'D.6',
    title: 'Pipelines',
    notes: '',
    description: '',
  },
  {
    id: 33,
    itemCode: 'E',
    title: 'CLASS E: EARTHWORKS',
    notes: '',
    description: '',
  },
  {
    id: 34,
    itemCode: 'E.1',
    title: 'Excavation by Dredging',
    notes: '',
    description: '',
  },
  {
    id: 35,
    itemCode: 'E.2',
    title: 'Excavation for Cuttings',
    notes: '',
    description: '',
  },
  {
    id: 36,
    itemCode: 'E.3',
    title: 'Excavation for Foundations',
    notes: '',
    description: '',
  },
  {
    id: 37,
    itemCode: 'E.4',
    title: 'General Excavation',
    notes: '',
    description: '',
  },
  {
    id: 38,
    itemCode: 'E.4.1',
    title: 'Topsoil',
    notes: '',
    description: '',
  },
  {
    id: 39,
    itemCode: 'E.4.2',
    title: 'Material other than topsoil, rock or artificial hard material',
    notes: '',
    description: '',
  },
  {
    id: 40,
    itemCode: 'E.4.2.3',
    title: "Gen excv ;Mat othr than t'soil, rock or artif'l hrd mat ; - Max. Dpth: 0.5-1m",
    notes: '',
    description: '',
  },
  {
    id: 41,
    itemCode: 'E.4.3',
    title: 'Rock',
    notes: '',
    description: '',
  },
  {
    id: 42,
    itemCode: 'E.5',
    title: 'Excavation Ancilliaries',
    notes: '',
    description: '',
  },
  {
    id: 43,
    itemCode: 'E.5.1',
    title: 'Trimming of excavated surfaces',
    notes: '',
    description: '',
  },
  {
    id: 44,
    itemCode: 'E.5.2',
    title: 'Preparation of excavated surfaces',
    notes: '',
    description: '',
  },
  {
    id: 45,
    itemCode: 'E.5.3',
    title: 'Disposal of excavated material',
    notes: '',
    description: '',
  },
  {
    id: 46,
    itemCode: 'E.5.3.2',
    title: "Excv ancil's ;Disp'l of excv mat ; - Material other than topsoil, rock or artificial hard material",
    notes: '',
    description: '',
  },
  {
    id: 47,
    itemCode: 'E.6',
    title: 'Filling',
    notes: '',
    description: '',
  },
  {
    id: 48,
    itemCode: 'E.6.1',
    title: 'To structures',
    notes: '',
    description: '',
  },
  {
    id: 49,
    itemCode: 'F',
    title: 'CLASS F: INSITU CONCRETE',
    notes: '',
    description: '',
  },
  {
    id: 50,
    itemCode: 'F.1',
    title: 'Provision of Concrete - Designed Concrete',
    notes: '',
    description: '',
  },
  {
    id: 51,
    itemCode: 'F.1.1',
    title: 'Strength C8/10',
    notes: '',
    description: '',
  },
  {
    id: 52,
    itemCode: 'F.1.2',
    title: 'Strength C12/15',
    notes: '',
    description: '',
  },
  {
    id: 53,
    itemCode: 'F.1.3',
    title: 'Strength C16/20',
    notes: '',
    description: '',
  },
  {
    id: 54,
    itemCode: 'F.1.4',
    title: 'Strength C20/25',
    notes: '',
    description: '',
  },
  {
    id: 55,
    itemCode: 'F.1.4.2',
    title: "Prov'n of conc - Designed conc ;Strength C20/25 ; - Maximum agg. size 20mm",
    notes: '',
    description: '',
  },
  {
    id: 56,
    itemCode: 'F.1.5',
    title: 'Strength C25/30',
    notes: '',
    description: '',
  },
  {
    id: 57,
    itemCode: 'F.1.6',
    title: 'Strength C28/35',
    notes: '',
    description: '',
  },
  {
    id: 58,
    itemCode: 'F.1.7',
    title: 'Strength C30/37',
    notes: '',
    description: '',
  },
  {
    id: 59,
    itemCode: 'F.1.8',
    title: 'Strength C32/40',
    notes: '',
    description: '',
  },
  {
    id: 60,
    itemCode: 'F.2',
    title: 'Provision of Concrete - Designated Concrete',
    notes: '',
    description: '',
  },
  {
    id: 61,
    itemCode: 'F.2.1',
    title: 'Strength RC20/25',
    notes: '',
    description: '',
  },
  {
    id: 62,
    itemCode: 'G',
    title: 'CLASS G: CONCRETE ANCILLARIES',
    notes: '',
    description: '',
  },
  {
    id: 63,
    itemCode: 'G.2',
    title: 'Formwork: Fair Finish',
    notes: '',
    description: '',
  },
  {
    id: 64,
    itemCode: 'G.2.1',
    title: 'Plane horizontal',
    notes: '',
    description: '',
  },
  {
    id: 65,
    itemCode: 'G.2.1.4',
    title: 'Fmwk: fair fin ;Plane horiz ; - Width: 0.4-1.22m',
    notes: '',
    description: '',
  },
  {
    id: 66,
    itemCode: 'G.2.2',
    title: 'Plane sloping',
    notes: '',
    description: '',
  },
  {
    id: 67,
    itemCode: 'G.2.3',
    title: 'Plane battered',
    notes: '',
    description: '',
  },
  {
    id: 68,
    itemCode: 'G.2.4',
    title: 'Plane vertical',
    notes: '',
    description: '',
  },
  {
    id: 69,
    itemCode: 'G.2.5',
    title: 'Curved to one radius in one plane',
    notes: '',
    description: '',
  },
  {
    id: 70,
    itemCode: 'G.2.6',
    title: 'Other curved',
    notes: '',
    description: '',
  },
  {
    id: 71,
    itemCode: 'G.2.7',
    title: 'For voids',
    notes: '',
    description: '',
  },
  {
    id: 72,
    itemCode: 'G.2.8',
    title: 'For concrete components of constant cross-section',
    notes: '',
    description: '',
  },
  {
    id: 73,
    itemCode: 'G.5',
    title: 'Reinforcement',
    notes: '',
    description: '',
  },
  {
    id: 74,
    itemCode: 'G.5.1',
    title: 'Plain round steel bars',
    notes: '',
    description: '',
  },
  {
    id: 75,
    itemCode: 'G.5.2',
    title: 'Deformed high yield steel bars',
    notes: '',
    description: '',
  },
  {
    id: 76,
    itemCode: 'G.5.2.5',
    title: 'Reinforcement ;Dfrmd h. y. stl bars  ; - Nom. size: 16mm',
    notes: '',
    description: '',
  },
  {
    id: 77,
    itemCode: 'G.5.3',
    title: 'Stainless steel bars of stated quality',
    notes: '',
    description: '',
  },
  {
    id: 78,
    itemCode: 'G.5.4',
    title: 'Reinforcing bars of other stated material',
    notes: '',
    description: '',
  },
  {
    id: 79,
    itemCode: 'G.5.5',
    title: 'Special joints',
    notes: '',
    description: '',
  },
  {
    id: 80,
    itemCode: 'G.5.6',
    title: 'Steel fabric',
    notes: '',
    description: '',
  },
  {
    id: 81,
    itemCode: 'G.5.7',
    title: 'Fabric of othre stated material',
    notes: '',
    description: '',
  },
  {
    id: 82,
    itemCode: 'G.8',
    title: 'Concrete Accessories',
    notes: '',
    description: '',
  },
  {
    id: 83,
    itemCode: 'G.8.1',
    title: 'Finishing of top surfaces',
    notes: '',
    description: '',
  },
  {
    id: 84,
    itemCode: 'G.8.1.1',
    title: "Conc access's ;Finish'g of top surfaces ; - Wood float",
    notes: '',
    description: '',
  },
  {
    id: 85,
    itemCode: 'G.8.2',
    title: 'Finishing of formed surfaces',
    notes: '',
    description: '',
  },
  {
    id: 86,
    itemCode: 'G.8.3',
    title: 'Inserts',
    notes: '',
    description: '',
  },
  {
    id: 87,
    itemCode: 'G.8.4',
    title: 'Grouting under plates',
    notes: '',
    description: '',
  },
  {
    id: 88,
    itemCode: 'H',
    title: 'CLASS H: PRECAST CONCRETE',
    notes: '',
    description: '',
  },
  {
    id: 89,
    itemCode: 'H.1',
    title: 'Beams',
    notes: '',
    description: '',
  },
  {
    id: 90,
    itemCode: 'H.2',
    title: 'Prestressed Pre-tensioned Beams',
    notes: '',
    description: '',
  },
  {
    id: 91,
    itemCode: 'H.3',
    title: 'Prestressed Post-tensioned Beams',
    notes: '',
    description: '',
  },
  {
    id: 92,
    itemCode: 'H.4',
    title: 'Columns',
    notes: '',
    description: '',
  },
  {
    id: 93,
    itemCode: 'H.5',
    title: 'Slabs',
    notes: '',
    description: '',
  },
  {
    id: 94,
    itemCode: 'H.6',
    title: 'Segmental Units',
    notes: '',
    description: '',
  },
  {
    id: 95,
    itemCode: 'H.7',
    title: 'Units for Subways, Culverts and Ducts',
    notes: '',
    description: '',
  },
  {
    id: 96,
    itemCode: 'H.8',
    title: 'Copings, Sills and Weir Blocks',
    notes: '',
    description: '',
  },
  {
    id: 97,
    itemCode: 'I',
    title: 'CLASS I: PIPEWORK - PIPES',
    notes: '',
    description: '',
  },
  {
    id: 98,
    itemCode: 'I.1',
    title: 'Clay Pipes',
    notes: '',
    description: '',
  },
  {
    id: 99,
    itemCode: 'I.2',
    title: 'Concrete Pipes',
    notes: '',
    description: '',
  },
  {
    id: 100,
    itemCode: 'I.3',
    title: 'Iron Pipes',
    notes: '',
    description: '',
  },
  {
    id: 101,
    itemCode: 'I.4',
    title: 'Steel Pipes',
    notes: '',
    description: '',
  },
  {
    id: 102,
    itemCode: 'I.5',
    title: 'Polyvinyl Chloride Pipes',
    notes: '',
    description: '',
  },
  {
    id: 103,
    itemCode: 'I.6',
    title: 'Glass Reinforced Plastic Pipes',
    notes: '',
    description: '',
  },
  {
    id: 104,
    itemCode: 'I.7',
    title: 'High Density Polyethylene Pipes',
    notes: '',
    description: '',
  },
  {
    id: 105,
    itemCode: 'I.8',
    title: 'Medium Density Polyethylene Pipes',
    notes: '',
    description: '',
  },
  {
    id: 106,
    itemCode: 'J',
    title: 'CLASS J: PIPEWORK - FITTINGS AND VALVES',
    notes: '',
    description: '',
  },
  {
    id: 107,
    itemCode: 'J.1',
    title: 'Clay Pipe Fittings',
    notes: '',
    description: '',
  },
  {
    id: 108,
    itemCode: 'J.2',
    title: 'Concrete Pipe Fittings',
    notes: '',
    description: '',
  },
  {
    id: 109,
    itemCode: 'J.3',
    title: 'Iron or Steel Pipe Fittings',
    notes: '',
    description: '',
  },
  {
    id: 110,
    itemCode: 'J.4',
    title: 'Polyvinyl Chloride Pipe Fittings',
    notes: '',
    description: '',
  },
  {
    id: 111,
    itemCode: 'J.5',
    title: 'Glass Reinforced Plastic Pipe Fittings',
    notes: '',
    description: '',
  },
  {
    id: 112,
    itemCode: 'J.6',
    title: 'High Density Polyethylene Pipe Fittings',
    notes: '',
    description: '',
  },
  {
    id: 113,
    itemCode: 'J.7',
    title: 'Medium Density Polyethylene Pipe Fittings',
    notes: '',
    description: '',
  },
  {
    id: 114,
    itemCode: 'J.8',
    title: 'Valves and Penstocks',
    notes: '',
    description: '',
  },
  {
    id: 115,
    itemCode: 'K',
    title: 'CLASS K: PIPEWORK - MANHOLES AND PIPEWORK ANCILLARIES',
    notes: '',
    description: '',
  },
  {
    id: 116,
    itemCode: 'K.1',
    title: 'Manholes',
    notes: '',
    description: '',
  },
  {
    id: 117,
    itemCode: 'K.2',
    title: 'Other Stated Chambers',
    notes: '',
    description: '',
  },
  {
    id: 118,
    itemCode: 'K.3',
    title: 'Gullies',
    notes: '',
    description: '',
  },
  {
    id: 119,
    itemCode: 'K.4',
    title: 'French Drains, Rubble Drains, Ditches and Trenches',
    notes: '',
    description: '',
  },
  {
    id: 120,
    itemCode: 'K.5',
    title: 'Ducts and Metal Culverts',
    notes: '',
    description: '',
  },
  {
    id: 121,
    itemCode: 'K.6',
    title: 'Crossings',
    notes: '',
    description: '',
  },
  {
    id: 122,
    itemCode: 'K.7',
    title: 'Reinstatement',
    notes: '',
    description: '',
  },
  {
    id: 123,
    itemCode: 'K.8',
    title: 'Other Pipework Ancilliaries',
    notes: '',
    description: '',
  },
  {
    id: 124,
    itemCode: 'L',
    title: 'CLASS L: PIPEWORK - SUPPORTS AND PROTECTION, ANCILLARIES TO LAYING AND EXCAVATION',
    notes: '',
    description: '',
  },
  {
    id: 125,
    itemCode: 'L.1',
    title: 'Extras to Excavation and Backfilling',
    notes: '',
    description: '',
  },
  {
    id: 126,
    itemCode: 'L.2',
    title: 'Special Pipe Laying Methods',
    notes: '',
    description: '',
  },
  {
    id: 127,
    itemCode: 'L.3',
    title: 'Beds',
    notes: '',
    description: '',
  },
  {
    id: 128,
    itemCode: 'L.4',
    title: 'Haunches',
    notes: '',
    description: '',
  },
  {
    id: 129,
    itemCode: 'L.5',
    title: 'Surrounds',
    notes: '',
    description: '',
  },
  {
    id: 130,
    itemCode: 'L.6',
    title: 'Wrapping and Lagging',
    notes: '',
    description: '',
  },
  {
    id: 131,
    itemCode: 'L.7',
    title: 'Concrete Stools and Thrust Blocks',
    notes: '',
    description: '',
  },
  {
    id: 132,
    itemCode: 'L.8',
    title: 'Other Isolated Pipe Supports',
    notes: '',
    description: '',
  },
  {
    id: 133,
    itemCode: 'M',
    title: 'CLASS M: STRUCTURAL METALWORK',
    notes: '',
    description: '',
  },
  {
    id: 134,
    itemCode: 'M.1',
    title: 'Fabrication of Main Members for Bridges',
    notes: '',
    description: '',
  },
  {
    id: 135,
    itemCode: 'M.2',
    title: 'Fabrication of Subsidiary Members for Bridges',
    notes: '',
    description: '',
  },
  {
    id: 136,
    itemCode: 'M.3',
    title: 'Fabrication of Members for Frames',
    notes: '',
    description: '',
  },
  {
    id: 137,
    itemCode: 'M.4',
    title: 'Fabrication of Other Members',
    notes: '',
    description: '',
  },
  {
    id: 138,
    itemCode: 'M.5',
    title: 'Erection of Members for Bridges',
    notes: '',
    description: '',
  },
  {
    id: 139,
    itemCode: 'M.6',
    title: 'Erection of Members for Frames',
    notes: '',
    description: '',
  },
  {
    id: 140,
    itemCode: 'M.7',
    title: 'Erection of Other Members',
    notes: '',
    description: '',
  },
  {
    id: 141,
    itemCode: 'M.8',
    title: 'Off Site Surface Treatment',
    notes: '',
    description: '',
  },
  {
    id: 142,
    itemCode: 'N',
    title: 'CLASS N: MISCELLANEOUS METALWORK',
    notes: '',
    description: '',
  },
  {
    id: 143,
    itemCode: 'N.1',
    title: 'Fabrication and Erection',
    notes: '',
    description: '',
  },
  {
    id: 144,
    itemCode: 'N.1.1',
    title: 'Stairways and landings',
    notes: '',
    description: '',
  },
  {
    id: 145,
    itemCode: 'N.1.2',
    title: 'Walkways and platforms',
    notes: '',
    description: '',
  },
  {
    id: 146,
    itemCode: 'N.1.3',
    title: 'Ladders',
    notes: '',
    description: '',
  },
  {
    id: 147,
    itemCode: 'N.1.4',
    title: 'Handrails',
    notes: '',
    description: '',
  },
  {
    id: 148,
    itemCode: 'N.1.4.1',
    title: "Fabric'n and erect'n ; ; - Handrails",
    notes: '',
    description: '',
  },
  {
    id: 149,
    itemCode: 'N.1.5',
    title: 'Bridge parapets',
    notes: '',
    description: '',
  },
  {
    id: 150,
    itemCode: 'N.1.6',
    title: 'Miscellaneous framing',
    notes: '',
    description: '',
  },
  {
    id: 151,
    itemCode: 'N.1.7',
    title: 'Plate flooring',
    notes: '',
    description: '',
  },
  {
    id: 152,
    itemCode: 'N.1.8',
    title: 'Open grid flooring',
    notes: '',
    description: '',
  },
  {
    id: 153,
    itemCode: 'N.2',
    title: 'Fabrication and Erection',
    notes: '',
    description: '',
  },
  {
    id: 154,
    itemCode: 'O',
    title: 'CLASS O: TIMBER',
    notes: '',
    description: '',
  },
  {
    id: 155,
    itemCode: 'O.1',
    title: 'Hardwood Components',
    notes: '',
    description: '',
  },
  {
    id: 156,
    itemCode: 'O.2',
    title: 'Softwood Components',
    notes: '',
    description: '',
  },
  {
    id: 157,
    itemCode: 'O.3',
    title: 'Hardwood Decking',
    notes: '',
    description: '',
  },
  {
    id: 158,
    itemCode: 'O.4',
    title: 'Softwood Decking',
    notes: '',
    description: '',
  },
  {
    id: 159,
    itemCode: 'O.5',
    title: 'Fittings and Fastenings',
    notes: '',
    description: '',
  },
  {
    id: 160,
    itemCode: 'P',
    title: 'CLASS P: PILES',
    notes: '',
    description: '',
  },
  {
    id: 161,
    itemCode: 'P.1',
    title: 'Bored Cast In Place Concrete Piles',
    notes: '',
    description: '',
  },
  {
    id: 162,
    itemCode: 'P.2',
    title: 'Driven Cast In Place Concrete Piles',
    notes: '',
    description: '',
  },
  {
    id: 163,
    itemCode: 'P.3',
    title: 'Preformed Concrete Piles',
    notes: '',
    description: '',
  },
  {
    id: 164,
    itemCode: 'P.4',
    title: 'Preformed Concrete Sheet Piles',
    notes: '',
    description: '',
  },
  {
    id: 165,
    itemCode: 'P.5',
    title: 'Timber Piles',
    notes: '',
    description: '',
  },
  {
    id: 166,
    itemCode: 'P.6',
    title: 'Isolated Steel Piles',
    notes: '',
    description: '',
  },
  {
    id: 167,
    itemCode: 'P.7',
    title: 'Interlocking Steel Piles',
    notes: '',
    description: '',
  },
  {
    id: 168,
    itemCode: 'P.8',
    title: 'Stone Columns',
    notes: '',
    description: '',
  },
  {
    id: 169,
    itemCode: 'Q',
    title: 'CLASS Q: PILING ANCILLIARIES',
    notes: '',
    description: '',
  },
  {
    id: 170,
    itemCode: 'Q.1',
    title: 'Cast In Place Concrete Piles',
    notes: '',
    description: '',
  },
  {
    id: 171,
    itemCode: 'Q.1.1',
    title: 'Pre-boring',
    notes: '',
    description: '',
  },
  {
    id: 172,
    itemCode: 'Q.1.2',
    title: 'Backfilling empty bore with stated material',
    notes: '',
    description: '',
  },
  {
    id: 173,
    itemCode: 'Q.1.3',
    title: 'Permanent casings each length: not exceeding 13m',
    notes: '',
    description: '',
  },
  {
    id: 174,
    itemCode: 'Q.1.4',
    title: 'Permanent casings each length: exceeding 13m',
    notes: '',
    description: '',
  },
  {
    id: 175,
    itemCode: 'Q.1.5',
    title: 'Enlarged bases',
    notes: '',
    description: '',
  },
  {
    id: 176,
    itemCode: 'Q.1.6',
    title: 'Cutting off surplus lengths',
    notes: '',
    description: '',
  },
  {
    id: 177,
    itemCode: 'Q.1.6.4',
    title: "Cast In Place Conc Piles ;Cut'g off surplus lgths ; - Dia.: 500mm or 550mm",
    notes: '',
    description: '',
  },
  {
    id: 178,
    itemCode: 'Q.1.7',
    title: 'Breaking obstructions',
    notes: '',
    description: '',
  },
  {
    id: 179,
    itemCode: 'Q.1.7.4',
    title: 'Cast In Place Conc Piles ;Breaking obstructions ; - Dia.: 600mm or 750mm',
    notes: '',
    description: '',
  },
  {
    id: 180,
    itemCode: 'Q.4',
    title: 'Timber Piles',
    notes: '',
    description: '',
  },
  {
    id: 181,
    itemCode: 'Q.1',
    title: 'Isolated Steel Piles',
    notes: '',
    description: '',
  },
  {
    id: 182,
    itemCode: 'Q.2',
    title: 'Interlocking Steel Piles',
    notes: '',
    description: '',
  },
  {
    id: 183,
    itemCode: 'Q.3',
    title: 'Obstructions',
    notes: '',
    description: '',
  },
  {
    id: 184,
    itemCode: 'Q.4',
    title: 'Pile Tests',
    notes: '',
    description: '',
  },
  {
    id: 185,
    itemCode: 'R',
    title: 'CLASS R: ROADS AND PAVINGS',
    notes: '',
    description: '',
  },
  {
    id: 186,
    itemCode: 'R.1',
    title: 'Unbound Sub-base',
    notes: '',
    description: '',
  },
  {
    id: 187,
    itemCode: 'R.2',
    title: 'Cement and Other Hydraulically Bound Pavements',
    notes: '',
    description: '',
  },
  {
    id: 188,
    itemCode: 'R.3',
    title: 'Bituminous Bound Pavements',
    notes: '',
    description: '',
  },
  {
    id: 189,
    itemCode: 'R.4',
    title: 'Bituminous Bound Pavements',
    notes: '',
    description: '',
  },
  {
    id: 190,
    itemCode: 'R.5',
    title: 'Concrete Pavements',
    notes: '',
    description: '',
  },
  {
    id: 191,
    itemCode: 'R.6',
    title: 'Joints in Concrete Pavements',
    notes: '',
    description: '',
  },
  {
    id: 192,
    itemCode: 'R.7',
    title: 'Kerbs, Channels, Edgings, Footways and Paved Areas',
    notes: '',
    description: '',
  },
  {
    id: 193,
    itemCode: 'R.8',
    title: 'Ancilliaries',
    notes: '',
    description: '',
  },
  {
    id: 194,
    itemCode: 'S',
    title: 'CLASS S: RAIL TRACK',
    notes: '',
    description: '',
  },
  {
    id: 195,
    itemCode: 'S.1',
    title: 'Track Foundations',
    notes: '',
    description: '',
  },
  {
    id: 196,
    itemCode: 'S.2',
    title: 'Taking Up',
    notes: '',
    description: '',
  },
  {
    id: 197,
    itemCode: 'S.3',
    title: 'Lifting, Packing and Slewing and Works to Existing Track',
    notes: '',
    description: '',
  },
  {
    id: 198,
    itemCode: 'S.4',
    title: 'Supplying',
    notes: '',
    description: '',
  },
  {
    id: 199,
    itemCode: 'S.5',
    title: 'Supplying',
    notes: '',
    description: '',
  },
  {
    id: 200,
    itemCode: 'S.6',
    title: 'Laying with Bearers on Ballast',
    notes: '',
    description: '',
  },
  {
    id: 201,
    itemCode: 'S.7',
    title: 'Laying',
    notes: '',
    description: '',
  },
  {
    id: 202,
    itemCode: 'T',
    title: 'CLASS T: TUNNELS',
    notes: '',
    description: '',
  },
  {
    id: 203,
    itemCode: 'T.1',
    title: 'Excavation',
    notes: '',
    description: '',
  },
  {
    id: 204,
    itemCode: 'T.2',
    title: 'In Situ Lining to Tunnels',
    notes: '',
    description: '',
  },
  {
    id: 205,
    itemCode: 'T.3',
    title: 'In Situ Lining to Shafts',
    notes: '',
    description: '',
  },
  {
    id: 206,
    itemCode: 'T.4',
    title: 'In Situ Lining to Other Cavities',
    notes: '',
    description: '',
  },
  {
    id: 207,
    itemCode: 'T.5',
    title: 'Preformed Segmental Lining to Tunnels',
    notes: '',
    description: '',
  },
  {
    id: 208,
    itemCode: 'T.6',
    title: 'Preformed Segmental Lining to Shafts',
    notes: '',
    description: '',
  },
  {
    id: 209,
    itemCode: 'T.7',
    title: 'Preformed Segmental Lining to Other Cavities',
    notes: '',
    description: '',
  },
  {
    id: 210,
    itemCode: 'T.8',
    title: 'Support and Stabilization',
    notes: '',
    description: '',
  },
  {
    id: 211,
    itemCode: 'U',
    title: 'CLASS U: BRICKWORK, BLOCKWORK & MASONRY',
    notes: '',
    description: '',
  },
  {
    id: 212,
    itemCode: 'U.1',
    title: 'Common Brickwork',
    notes: '',
    description: '',
  },
  {
    id: 213,
    itemCode: 'U.2',
    title: 'Facing Brickwork',
    notes: '',
    description: '',
  },
  {
    id: 214,
    itemCode: 'U.3',
    title: 'Engineering Brickwork',
    notes: '',
    description: '',
  },
  {
    id: 215,
    itemCode: 'U.4',
    title: 'Lightweight Blockwork',
    notes: '',
    description: '',
  },
  {
    id: 216,
    itemCode: 'U.5',
    title: 'Dense concrete Blockwork',
    notes: '',
    description: '',
  },
  {
    id: 217,
    itemCode: 'U.6',
    title: 'Artificial stone Blockwork',
    notes: '',
    description: '',
  },
  {
    id: 218,
    itemCode: 'U.7',
    title: 'Ashlar Masonry',
    notes: '',
    description: '',
  },
  {
    id: 219,
    itemCode: 'U.8',
    title: 'Rubble Masonry',
    notes: '',
    description: '',
  },
  {
    id: 220,
    itemCode: 'V',
    title: 'CLASS V: PAINTING',
    notes: '',
    description: '',
  },
  {
    id: 221,
    itemCode: 'V.1',
    title: 'Iron or Zinc Based Primer Paint',
    notes: '',
    description: '',
  },
  {
    id: 222,
    itemCode: 'V.2',
    title: 'Etch Primer Paint',
    notes: '',
    description: '',
  },
  {
    id: 223,
    itemCode: 'V.3',
    title: 'Oil Paint',
    notes: '',
    description: '',
  },
  {
    id: 224,
    itemCode: 'V.4',
    title: 'Alkyd Gloss Paint',
    notes: '',
    description: '',
  },
  {
    id: 225,
    itemCode: 'V.5',
    title: 'Emulsion Paint',
    notes: '',
    description: '',
  },
  {
    id: 226,
    itemCode: 'V.6',
    title: 'Cement Paint',
    notes: '',
    description: '',
  },
  {
    id: 227,
    itemCode: 'V.7',
    title: 'Epoxy or Polyurethane Paint',
    notes: '',
    description: '',
  },
  {
    id: 228,
    itemCode: 'V.8',
    title: 'Bituminous or Coal Tar Paint',
    notes: '',
    description: '',
  },
  {
    id: 229,
    itemCode: 'W',
    title: 'CLASS W: WATERPROOFING',
    notes: '',
    description: '',
  },
  {
    id: 230,
    itemCode: 'W.1',
    title: 'Damp Proofing',
    notes: '',
    description: '',
  },
  {
    id: 231,
    itemCode: 'W.2',
    title: 'Tanking',
    notes: '',
    description: '',
  },
  {
    id: 232,
    itemCode: 'W.3',
    title: 'Roofing',
    notes: '',
    description: '',
  },
  {
    id: 233,
    itemCode: 'W.4',
    title: 'Protective Layers',
    notes: '',
    description: '',
  },
  {
    id: 234,
    itemCode: 'W.5',
    title: 'Sprayed or Brushed Waterproofing',
    notes: '',
    description: '',
  },
  {
    id: 235,
    itemCode: 'W.6',
    title: 'Sheet Linings Membrane',
    notes: '',
    description: '',
  },
  {
    id: 236,
    itemCode: 'X',
    title: 'CLASS X: MISCELLANEOUS WORK',
    notes: '',
    description: '',
  },
  {
    id: 237,
    itemCode: 'X.1',
    title: 'Fences',
    notes: '',
    description: '',
  },
  {
    id: 238,
    itemCode: 'X.2',
    title: 'Gates and Stiles',
    notes: '',
    description: '',
  },
  {
    id: 239,
    itemCode: 'X.3',
    title: 'Drainage to Structures Above Ground',
    notes: '',
    description: '',
  },
  {
    id: 240,
    itemCode: 'X.4',
    title: 'Rock Filled Gabions',
    notes: '',
    description: '',
  },
  {
    id: 241,
    itemCode: 'X.5',
    title: 'Open Cell Block Systems',
    notes: '',
    description: '',
  },
  {
    id: 242,
    itemCode: 'Y',
    title: 'CLASS Y: SEWER & WATER MAIN RENOVATION AND ANCILLIARY WORKS',
    notes: '',
    description: '',
  },
  {
    id: 243,
    itemCode: 'Y.1',
    title: 'Preparation of Existing Sewers',
    notes: '',
    description: '',
  },
  {
    id: 244,
    itemCode: 'Y.2',
    title: 'Stabilization of Existing Sewers',
    notes: '',
    description: '',
  },
  {
    id: 245,
    itemCode: 'Y.3',
    title: 'Renovation of Existing Sewers',
    notes: '',
    description: '',
  },
  {
    id: 246,
    itemCode: 'Y.4',
    title: 'Laterals to Renovated Sewers',
    notes: '',
    description: '',
  },
  {
    id: 247,
    itemCode: 'Y.5',
    title: 'Water Main Renovation and Ancilliary Works',
    notes: '',
    description: '',
  },
  {
    id: 248,
    itemCode: 'Y.6',
    title: 'New Manholes',
    notes: '',
    description: '',
  },
  {
    id: 249,
    itemCode: 'Y.7',
    title: 'Existing Manholes',
    notes: '',
    description: '',
  },
  {
    id: 250,
    itemCode: 'Y.8',
    title: 'Interruptions',
    notes: '',
    description: '',
  },
  {
    id: 251,
    itemCode: 'Z',
    title: 'CLASS Z: SIMPLE BUILDING WORKS INCIDENTAL TO CIVIL ENGINEERING WORKS',
    notes: '',
    description: '',
  },
  {
    id: 252,
    itemCode: 'Z.1',
    title: 'Carpentry & Joinery',
    notes: '',
    description: '',
  },
  {
    id: 253,
    itemCode: 'Z.2',
    title: 'Insulation',
    notes: '',
    description: '',
  },
  {
    id: 254,
    itemCode: 'Z.3',
    title: 'Windows, Doors & Glazing',
    notes: '',
    description: '',
  },
  {
    id: 255,
    itemCode: 'Z.4',
    title: 'Surface Finishes, Linings and Partitions',
    notes: '',
    description: '',
  },
  {
    id: 256,
    itemCode: 'Z.5',
    title: 'Piped Building Services',
    notes: '',
    description: '',
  },
  {
    id: 257,
    itemCode: 'Z.6',
    title: 'Ducted Building Services',
    notes: '',
    description: '',
  },
  {
    id: 258,
    itemCode: 'Z.7',
    title: 'Cabled Building Services',
    notes: '',
    description: '',
  },
]
