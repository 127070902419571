import { ViewSettingsState } from './ViewSettings.state'
import { SET_LEFT_MENU_SIDER_COLLAPSED, SetLeftMenuSiderCollapsedAction } from './ViewSettings.actions'
import { Action } from 'redux'

const initialState: ViewSettingsState = {
  leftMenuSiderCollapsed: false,
}

export function viewSettingsReducer(
  state: ViewSettingsState = initialState,
  action: Action<string>
): ViewSettingsState {
  switch (action.type) {
    case SET_LEFT_MENU_SIDER_COLLAPSED: {
      return Object.assign({}, state, { leftMenuSiderCollapsed: (action as SetLeftMenuSiderCollapsedAction).collapsed })
    }
    default:
      return state
  }
}
