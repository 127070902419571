import { Collapse } from 'antd'
import React from 'react'
import { settingsProjectPropertiesData } from '../Settings.columns'

const Panel = Collapse.Panel

export default function SettingsProjectProperties() {
  return (
    <div>
      <Collapse className="site-collapse-custom-admin-panel">
        {settingsProjectPropertiesData.map(({ title, key, render }) => (
          <Panel forceRender={true} header={title} key={key} className="site-collapse-custom-admin-panel">
            {render}
          </Panel>
        ))}
      </Collapse>
    </div>
  )
}
