import React from 'react'
import { Tag, Badge, Button } from 'antd'
import { ColumnType } from 'antd/lib/table/interface'
import { ProjectStatus, statusToTagColor } from '../../../../model/Projects'

export const typeToBadgeColor = (text: string) => {
  switch (text) {
    case 'Office':
      return 'purple'
    case 'Residential':
      return 'orange'
    case 'Road':
      return 'pink'
    case 'Rail':
      return 'blue'
    case 'Bridge':
      return 'gold'
    default:
      return 'lime'
  }
}

export const portfolioColumns = (openProject: () => void): ColumnType<any>[] => [
  {
    title: 'ID',
    dataIndex: 'id',
    fixed: 'left',
    key: 'id',
    width: 130,
  },
  {
    title: 'Project Name',
    dataIndex: 'name',
    fixed: 'left',
    key: 'name',
    width: 200,
    render: (text) => (
      <Button type="link" onClick={openProject}>
        <strong>{text}</strong>
      </Button>
    ),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (text) => <Badge color={typeToBadgeColor(text)} text={text} />,
  },
  {
    title: 'Division',
    dataIndex: 'division',
    key: 'division',
  },
  {
    title: 'Region',
    dataIndex: 'region',
    key: 'region',
  },
  {
    title: 'Stage',
    dataIndex: 'status',
    key: 'status',
    render: (text: string) => {
      return <Tag color={statusToTagColor(text as ProjectStatus)}> {text} </Tag>
    },
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    align: 'right',
  },
  {
    title: 'Client',
    dataIndex: 'client',
    key: 'client',
  },
  {
    title: 'Manager',
    dataIndex: 'manager',
    key: 'manager',
  },
  {
    title: 'Start',
    dataIndex: 'start',
    key: 'start',
  },
  {
    title: 'End',
    dataIndex: 'end',
    key: 'end',
  },
]
