import { ExtendedColumnType } from '../../../../basic/table/sterling-table/SterlingTable'
import { stringSorter } from '../../../../../utils/Sorters'

export const breakdownStructureColumns: ExtendedColumnType[] = [
  {
    title: 'Group Code',
    dataIndex: 'itemCode',
    fixed: 'left',
    key: 'Group Code',
    width: 240,
    sorter: (a, b) => stringSorter(a.itemCode, b.itemCode),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'ascend',
    editable: true,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    fixed: 'left',
    key: 'Title',
    width: 240,
    sorter: (a, b) => stringSorter(a.title, b.title),
    sortDirections: ['ascend', 'descend'],
    editable: true,
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'Notes',
    width: 130,
    sorter: (a, b) => stringSorter(a.notes, b.notes),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'Description',
    width: 130,
    sorter: (a, b) => stringSorter(a.description, b.description),
    sortDirections: ['ascend', 'descend'],
  },
]
