import { SyncOutlined, PlusSquareOutlined, MenuUnfoldOutlined, FilterOutlined } from '@ant-design/icons'
import React from 'react'
import { Space, Button, Divider, Tooltip } from 'antd'
import { TextWrappingCustom } from '../../../../assets/icons/icons'

export interface SettingsHeaderProps {
  children?: any
}

export const SettingsHeader = ({ children }: { children: any }) => {
  return (
    <Space style={{ flex: 10 }}>
      <Tooltip title="Refresh">
        <Button type="text" shape="circle" icon={<SyncOutlined />} />
      </Tooltip>
      <Divider type="vertical" />
      <Tooltip title="Wrap text">
        <Button type="text" shape="circle" icon={<TextWrappingCustom />} />
      </Tooltip>
      <Tooltip title="Add row">
        <Button type="text" shape="circle" icon={<PlusSquareOutlined />} />
      </Tooltip>
      {children}
    </Space>
  )
}
