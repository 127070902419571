import React, { useEffect, useState } from 'react'
import { Button, Divider, Row, Space, notification } from 'antd'
import FormPart from '../../../basic/form/FormPart'
import { ChooseTemplateForm } from './parts/ChooseTemplateForm'
import { InSituConcreteWallForm } from './parts/InSituConcreteWallForm'
import { TakeOffDetailsItem } from '../helpers/TakeOffDataParser'
import { useHistory } from 'react-router-dom'

interface GenerateCostItemsFormProps {
  closeModal: () => void
  takeOffDetailsItems: TakeOffDetailsItem[]
}

export const GenerateCostItemsForm = ({ closeModal, takeOffDetailsItems }: GenerateCostItemsFormProps) => {
  const [current, changeCurrent] = useState(0)
  const [nextClicked, setNextClicked] = useState(false)
  const [prevClicked, setPrevClicked] = useState(false)

  const prev = () => setPrevClicked(!prevClicked)
  const next = () => setNextClicked(!nextClicked)
  const proceedToNext = () => changeCurrent(current + 1)
  const proceedToPrev = () => changeCurrent(current - 1)

  const [formReady, setFormReady] = useState(false)
  const [moveToCostPlan, setMoveToCostPlan] = useState(false)
  const history = useHistory()
  const saveFormAndCloseModal = (formData: any) => {
    setFormReady(true)
  }

  useEffect(() => {
    if (formReady) {
      closeModal()
      if (moveToCostPlan) {
        history.push('/cost-plan')
      }
      notification.success({
        message: 'Successful operation',
        description: 'Take-off items were successfully connected with cost items',
      })
    }
    setFormReady(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formReady])

  return (
    <>
      <Row>
        <h4>Generate cost items</h4>
      </Row>
      <Divider style={{ margin: 0 }} />
      <FormPart
        prevClicked={prevClicked}
        proceedToPrev={proceedToPrev}
        nextClicked={nextClicked}
        proceedToNext={current < 1 ? proceedToNext : saveFormAndCloseModal}
      >
        {
          [
            <ChooseTemplateForm key={'choose-template-form'} />,
            <InSituConcreteWallForm key={'in-situ-concrete-wall-form'} takeOffDetailsItems={takeOffDetailsItems} />,
          ][current]
        }
      </FormPart>

      <Row justify={'end'} style={{ paddingTop: 8 }}>
        <Space style={{ marginRight: 32 }}>
          {current === 1 && <Button onClick={prev}>Previous</Button>}
          <Button onClick={closeModal}>Cancel</Button>
          {current === 1 && (
            <>
              <Button
                type={'primary'}
                onClick={() => {
                  setMoveToCostPlan(true)
                  next()
                }}
              >
                Generate and move to cost plan
              </Button>
              <Button type={'primary'} onClick={next}>
                Generate and continue
              </Button>
            </>
          )}
          {current === 0 && (
            <Button type={'primary'} onClick={next}>
              Next
            </Button>
          )}
        </Space>
      </Row>
    </>
  )
}
