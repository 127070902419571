import React from 'react'
import AdministrationTemplate from './template/AdministrationTemplate'
import { Collapse } from 'antd'
import { globalPropertiesData } from './Administration.columns'

import './Administration.scss'
import BreadcrumbPanel from '../../basic/breadcrumb/BreadcrumbPanel'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenWindowState } from '../../../context/open-windows/OpenWindows.actions'
import NewWindow from '../../basic/new-window/NewWindow'
import ReplacementPane from '../../basic/new-window/ReplacementPane'
import { RootState } from '../../../context/reducer'
import { newWindowHeight, newWindowWidth } from '../../../constants/sizes'

const Panel = Collapse.Panel

function GlobalProperties(): JSX.Element {
  return (
    <AdministrationTemplate selectedKey={'global-properties'}>
      <GlobalPropertiesPart />
    </AdministrationTemplate>
  )
}

const RenderCollapse = () => {
  return (
    <div>
      <Collapse className="site-collapse-custom-admin-panel">
        {globalPropertiesData.map(({ title, key, render }) => (
          <Panel forceRender={true} header={title} key={key} className="site-collapse-custom-admin-panel">
            {render}
          </Panel>
        ))}
      </Collapse>
    </div>
  )
}

function GlobalPropertiesPart(): JSX.Element {
  const dispatch = useDispatch()

  const getOpenWindow = (id: string) => (state: RootState) => state.openWindows.windows.get(id) || false
  const globalPropertiesWindowId = 'globalProperties'
  const isglobalPropertiesWindowOpen = useSelector(getOpenWindow(globalPropertiesWindowId))

  return (
    <div>
      <BreadcrumbPanel
        moveTo={'/administration/global-properties'}
        categoryName={'Administration'}
        displayName={'Global Properties'}
        openWindow={() => dispatch(setOpenWindowState(globalPropertiesWindowId, true))}
      />
      <NewWindow
        height={newWindowHeight}
        width={newWindowWidth}
        isOpen={isglobalPropertiesWindowOpen}
        onPortalClose={() => dispatch(setOpenWindowState(globalPropertiesWindowId, false))}
        replacementPane={
          <ReplacementPane
            onHide={() => dispatch(setOpenWindowState(globalPropertiesWindowId, false))}
            onWindowShow={() => {}}
            style={{ width: '100%', height: 450, backgroundColor: '#fff' }}
          />
        }
      >
        <div className="title" style={{ fontSize: '14px', fontWeight: 'bolder', marginBottom: '35px' }}>
          Global Properties
        </div>
        <RenderCollapse />
      </NewWindow>
    </div>
  )
}

export default GlobalProperties
