import { Key } from 'react'
import { Entry } from '../SterlingTable'

export const keysToHaveExpanded = (entries: Entry[], level: number): Key[] => {
  return entries.flatMap((e) => findKeysToHaveExpanded(e, 1, level))
}

export const findKeysToHaveExpanded = (entry: Entry, currentLevel: number, targetLevel: number): Key[] => {
  if (currentLevel > targetLevel) {
    return []
  }
  if (currentLevel === targetLevel) {
    return [entry.key as Key]
  }
  if (entry.children !== undefined) {
    return [entry.key as Key].concat(
      entry.children.flatMap((e) => findKeysToHaveExpanded(e, currentLevel + 1, targetLevel))
    )
  }
  return []
}

export const findMaxExpandLevel = (entries: Entry[]): number => {
  return Math.max(...entries.map((e) => findExpandLevel(e, 0)))
}

const findExpandLevel = (entry: Entry, expandLevel: number): number => {
  if (entry.children !== undefined && entry.children.length > 0) {
    return Math.max(...entry.children.map((e) => findExpandLevel(e, expandLevel + 1)))
  }
  return expandLevel
}
