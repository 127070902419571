import React from 'react'
import { Layout } from 'antd'
import { MenuSider, MenuSiderItems } from '../../../basic/menu/MenuSider'
import { AuditOutlined, ClusterOutlined, DatabaseOutlined, ReadOutlined } from '@ant-design/icons/lib'

const { Content } = Layout

const LibraryMenuItems: MenuSiderItems = {
  head: {
    key: 'libraries',
    icon: <ReadOutlined />,
    title: 'Libraries',
  },
  items: [
    {
      key: 'resources',
      icon: <DatabaseOutlined />,
      title: 'Resource Library',
      address: '/libraries/resources',
    },
    {
      key: 'breakdown-structures',
      icon: <ClusterOutlined />,
      title: 'Breakdown Structures',
      address: '/libraries/breakdown-structures',
    },
    {
      key: 'contract',
      icon: <AuditOutlined />,
      title: 'Contact Library',
      address: '/libraries/contact',
    },
  ],
}

function LibrariesTemplate({ children, selectedKey }: { children: React.ReactNode; selectedKey: string }): JSX.Element {
  return (
    <>
      <Layout>
        <MenuSider disableHead={true} head={LibraryMenuItems.head} items={LibraryMenuItems.items} selectedKey={selectedKey} />
        <Content style={{ paddingLeft: '50px', paddingRight: '50px' }}>
          <div>{children}</div>
        </Content>
      </Layout>
    </>
  )
}

export default LibrariesTemplate
