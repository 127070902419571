import React, { FunctionComponent } from 'react'
import { Breadcrumb, Button } from 'antd'
import { Link } from 'react-router-dom'
import { ArrowsAltOutlined } from '@ant-design/icons'

interface BreadcrumbProps {
  displayName: string
  categoryName: string
  moveTo: string
  openWindow?: () => void
}

const BreadcrumbPanel: FunctionComponent<BreadcrumbProps> = ({ moveTo, displayName, categoryName, openWindow }) => {
  return (
    <div
      className="breadcrumb-wrapper"
      style={{ display: 'flex', paddingTop: '6px', width: '100%', justifyContent: 'space-between' }}
    >
      <Breadcrumb style={{ paddingTop: '6px' }}>
        <Breadcrumb.Item>
          <Link style={{ color: 'black' }} to={'/'}>
            Portfolio
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{categoryName}</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/${moveTo}`}>{displayName}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      {openWindow && (
        <Button
          type={'text'}
          onClick={openWindow}
          style={{ marginTop: '12px', marginRight: '8px' }}
          icon={<ArrowsAltOutlined />}
        />
      )}
    </div>
  )
}

export default BreadcrumbPanel
