import { Entry } from '../components/basic/table/sterling-table/SterlingTable'

export const identity = (x: any) => {
  return x
}

export function replaceRecursively<T extends Entry>(array: any[], itemToReplace: T) {
  if (!array || array.length === 0 || !itemToReplace) {
    return
  }
  const key = itemToReplace.key
  const foundIndex = array.findIndex((d) => d && d.key === key)
  if (foundIndex > 0) {
    array[foundIndex] = itemToReplace
    return
  } else {
    array.forEach((item) => replaceRecursively(item?.children, itemToReplace))
  }
}

export const filterRecursively = <T extends Entry>(entries: any[], predicate: (item: T) => boolean): Entry[] => {
  if (!entries || entries.length === 0) {
    return entries
  }
  return [
    ...entries
      .filter(predicate)
      .map((e) => Object.assign({}, e, e.children ? { children: filterRecursively(e.children, predicate) } : {})),
  ]
}

export const generateCSVHeader = (data: any[]) => {
  return data.map((c) => ({
    label: c.title,
    key: c.key,
  }))
}
