import { Action } from 'redux'
import { CommentLayout } from '../../model/Documentation'

export const ADD_COMMENT = 'ADD_COMMENT'

export interface AddCommentAction extends Action<string> {
  id: number
  comment: CommentLayout
}

export const AddComment = (id: number, comment: CommentLayout) => {
  return {
    type: ADD_COMMENT,
    id,
    comment,
  }
}
