import React from 'react'
import { Tabs } from 'antd'
import { TakeOff } from './TakeOff'
import ProjectTemplate from '../project/ProjectTemplate'
import { TakeOffMapping } from './TakeOffMapping'
import BreadcrumbPanel from '../../basic/breadcrumb/BreadcrumbPanel'
import NewWindow from '../../basic/new-window/NewWindow'
import ReplacementPane from '../../basic/new-window/ReplacementPane'
import { newWindowHeight, newWindowWidth } from '../../../constants/sizes'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../context/reducer'
import { setOpenWindowState } from '../../../context/open-windows/OpenWindows.actions'

const TabPane = Tabs.TabPane

export const TakeOffTabs = () => {
  const dispatch = useDispatch()

  const getOpenWindow = (id: string) => (state: RootState) => state.openWindows.windows.get(id) || false
  const takeOffWindowId = 'takeOff'
  const istakeOffWindowOpen = useSelector(getOpenWindow(takeOffWindowId))

  return (
    <ProjectTemplate selectedKey={'take-off'}>
      <BreadcrumbPanel
        moveTo={'take-off'}
        displayName={'Take-off'}
        categoryName={'Sample Project'}
        openWindow={() => dispatch(setOpenWindowState(takeOffWindowId, true))}
      />
      <NewWindow
        height={newWindowHeight}
        width={newWindowWidth}
        isOpen={istakeOffWindowOpen}
        onPortalClose={() => dispatch(setOpenWindowState(takeOffWindowId, false))}
        replacementPane={
          <ReplacementPane
            onHide={() => dispatch(setOpenWindowState(takeOffWindowId, false))}
            onWindowShow={() => {}}
            style={{ width: '100%', height: 450, backgroundColor: '#fff' }}
          />
        }
      >
        <Tabs>
          <TabPane tab={'Take-Off'} key={'take-off'}>
            <TakeOff />
          </TabPane>
          <TabPane tab={'Mappings'} key={'take-off-mappings'}>
            <TakeOffMapping />
          </TabPane>
        </Tabs>
      </NewWindow>
    </ProjectTemplate>
  )
}
