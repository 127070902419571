import { Action } from 'redux'
import { Entry } from '../../components/basic/table/sterling-table/SterlingTable'
import { Key } from 'react'
import { BoQBreakdownCode } from '../../model/BillOfQuantities'

export interface SterlingTableAction extends Action<string> {
  tableId: string
  codeExtractor?: (el: any) => string
}

export const UPDATE_ENTRY = 'UPDATE_ENTRY'

export interface UpdateEntryAction extends SterlingTableAction {
  id: number
  updates: { [key: string]: any }
}

export const updateEntry = (
  tableId: string,
  id: number,
  updates: { [key: string]: any },
  codeExtractor?: (el: any) => string
) => {
  return {
    type: UPDATE_ENTRY,
    tableId,
    id,
    codeExtractor,
    updates,
  }
}

export const DELETE_ENTRIES = 'DELETE_ENTRIES'

export interface DeleteEntriesAction extends SterlingTableAction {
  entriesIds: number[]
}

export const deleteEntries = (tableId: string, entriesIds: number[], codeExtractor?: (el: any) => string) => {
  return {
    type: DELETE_ENTRIES,
    tableId,
    codeExtractor,
    entriesIds,
  }
}

export const ADD_ENTRY = 'ADD_ENTRY'

export interface AddEntryAction extends SterlingTableAction {
  entry: any
}

export const addEntry = (tableId: string, entry: any, codeExtractor?: (el: any) => string) => {
  return {
    type: ADD_ENTRY,
    entry,
    tableId,
    codeExtractor,
  }
}

export const DUPLICATE_ENTRIES = 'DUPLICATE_ENTRIES'

export interface DuplicateEntries extends SterlingTableAction {
  entriesIds: number[]
}

export const duplicateEntries = (tableId: string, entriesIds: number[], codeExtractor?: (el: any) => string) => {
  return {
    type: DUPLICATE_ENTRIES,
    entriesIds,
    tableId,
    codeExtractor,
  }
}

export const GROUP_BY_EXTERNAL_DEPENDENCY = 'GROUP_BY_EXTERNAL_DEPENDENCY'

export interface GroupByExternalDependency extends SterlingTableAction {
  parentsList: any[]
  codeExtractor: (el: any) => string
  column: BoQBreakdownCode
  grouper: <T extends Entry>(parentsList: any[], elements: T[], codeExtractor: (el: any) => string) => Entry[]
  columnGroupingRules: { [key: string]: (children: Entry[]) => string }
}

export const groupByExternalEntries = (
  tableId: string,
  parentsList: any[],
  codeExtractor: (el: any) => string,
  grouper: <T extends Entry>(parentsList: any[], elements: T[], codeExtractor: (el: any) => string) => Entry[],
  columnGroupingRules: { [key: string]: (children: Entry[]) => string },
  column?: BoQBreakdownCode
) => {
  return {
    type: GROUP_BY_EXTERNAL_DEPENDENCY,
    parentsList,
    codeExtractor,
    tableId,
    grouper,
    columnGroupingRules,
    column,
  }
}

export const GROUP_BY_COLUMN = 'GROUP_BY_COLUMN'

export interface GroupByColumnAction extends SterlingTableAction {
  columnKey: Key
  emptyElementGenerator: (_: number) => Entry
  columnGroupingRules: { [key: string]: (children: Entry[]) => string }
}

export const groupByColumn = (
  tableId: string,
  emptyElementGenerator: (_: number) => Entry,
  columnGroupingRules: { [key: string]: (children: Entry[]) => string },
  columnKey?: Key,
  codeExtractor?: (el: any) => string // To apply default groupping when nothing is grouped.
) => {
  return {
    type: GROUP_BY_COLUMN,
    tableId,
    columnKey,
    emptyElementGenerator,
    columnGroupingRules,
    codeExtractor,
  }
}
