import React from 'react'
import { Tabs } from 'antd'

import LibrariesTemplate from './template/LibrariesTemplate'
import LibraryTable from './table/LibraryTable'
import { contactsExternalColumns } from './table/columns/ContactsExternal.columns'
import { emptyContactsExternalItem } from '../../../data/library/ContactsExternal.data'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../context/reducer'
import { contactsInternalColumns } from './table/columns/ContactsInternal.columns'
import { emptyContactsInternalItem } from '../../../data/library/ContactsInternal.data'
import { setOpenWindowState } from '../../../context/open-windows/OpenWindows.actions'
import NewWindow from '../../basic/new-window/NewWindow'
import ReplacementPane from '../../basic/new-window/ReplacementPane'
import BreadcrumbPanel from '../../basic/breadcrumb/BreadcrumbPanel'
import { newWindowHeight, newWindowWidth } from '../../../constants/sizes'
import { calculateHeightForWholePageTable } from '../../../constants/dimensions'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

const TabPane = Tabs.TabPane

function ContactLibrary(): JSX.Element {
  return (
    <LibrariesTemplate selectedKey={'contact'}>
      <ContactLibraryPart />
    </LibrariesTemplate>
  )
}

function ContactLibraryPart(): JSX.Element {
  const dispatch = useDispatch()

  const contractsExternal = useSelector((state: RootState) => state.sterlingTable.parsedData.contractsExternal)
  const contractsInternal = useSelector((state: RootState) => state.sterlingTable.parsedData.contractsInternal)

  const getOpenWindow = (id: string) => (state: RootState) => state.openWindows.windows.get(id) || false
  const contactLibraryWindowId = 'contactLibrary'
  const isBreakdownStructuresWindowOpen = useSelector(getOpenWindow(contactLibraryWindowId))

  const { height } = useWindowDimensions()
  const tableHeight = calculateHeightForWholePageTable(height)

  return (
    <>
      <BreadcrumbPanel
        moveTo={'libraries/contact'}
        displayName={'Contact'}
        categoryName={'Libraries'}
        openWindow={() => dispatch(setOpenWindowState(contactLibraryWindowId, true))}
      />
      <NewWindow
        height={newWindowHeight}
        width={newWindowWidth}
        isOpen={isBreakdownStructuresWindowOpen}
        onPortalClose={() => dispatch(setOpenWindowState(contactLibraryWindowId, false))}
        replacementPane={
          <ReplacementPane
            onHide={() => dispatch(setOpenWindowState(contactLibraryWindowId, false))}
            onWindowShow={() => {}}
            style={{ width: '100%', height: 450, backgroundColor: '#fff' }}
          />
        }
      >
        <Tabs>
          <TabPane tab={'Internal'} key={'internal'}>
            <LibraryTable
              columns={contactsInternalColumns}
              dataSource={contractsInternal}
              tableId={'contactsInternal'}
              emptyItemProvider={emptyContactsInternalItem}
              tableHeight={tableHeight}
              pagination={false}
            />
          </TabPane>
          <TabPane tab={'External'} key={'external'}>
            <LibraryTable
              columns={contactsExternalColumns}
              dataSource={contractsExternal}
              tableId={'contactsExternal'}
              emptyItemProvider={emptyContactsExternalItem}
              tableHeight={tableHeight}
              pagination={false}
            />
          </TabPane>
        </Tabs>
      </NewWindow>
    </>
  )
}

export default ContactLibrary
