export interface ComplexResourcesDetailsItem {
  crs_id: number
  id: number
  itemCode: string
  title: string
  description: string
  unit: string
  manhours_unit: number
  plant_hours_unit: number
  material_consumption_unit: number
  labour_rate: string
  material_rate: string
  plant_rate: string
  subcontractor_rate: string
  total_rate: string
  currency: string
  co2_labour_rate: number
  co2_material_rate: number
  co2_plant_rate: number
  co2_subcontractor_rate: number
  co2_unit_rate: number
  creation_date: string
  last_edited: string
  creator: string
  last_editor: string
}

export function emptyComplexResourcesDetailsItem(crsId: number) {
  return () => {
    return {
      crs_id: crsId,
      id: -1,
      itemCode: '',
      title: '',
      description: '',
      unit: '',
      manhours_unit: 1,
      plant_hours_unit: 1,
      material_consumption_unit: 1,
      labour_rate: '£0.00',
      material_rate: '£130.00',
      plant_rate: '£0.00',
      subcontractor_rate: '£0.00',
      total_rate: '£0.00',
      currency: '',
      co2_labour_rate: 0,
      co2_material_rate: 0,
      co2_plant_rate: 0,
      co2_subcontractor_rate: 0,
      co2_unit_rate: 0,
      creation_date: '',
      last_edited: '',
      creator: '',
      last_editor: '',
    }
  }
}

export const complexResourcesDetailsData = [
  {
    crs_id: 3,
    id: 1,
    itemCode: 'MAT.CON.CXX.010',
    title: 'Concrete',
    description: '',
    unit: 'M3',
    manhours_unit: 1,
    plant_hours_unit: 1,
    material_consumption_unit: 1,
    labour_rate: 0,
    material_rate: 102,
    plant_rate: 0,
    subcontractor_rate: 0,
    total_rate: 102,
    currency: 'GBP',
    co2_labour_rate: 0,
    co2_material_rate: '250,00',
    co2_plant_rate: 0,
    co2_subcontractor_rate: 0,
    co2_unit_rate: '250,00',
    creation_date: '12-Jun-2020',
    last_edited: '12-Jun-2020',
    creator: 'admin',
    last_editor: 'admin',
  },
  {
    crs_id: 3,
    id: 2,
    itemCode: 'MAT.CON.MSC.010',
    title: 'Spacers',
    description: '',
    unit: 'NO',
    manhours_unit: 1,
    plant_hours_unit: 1,
    material_consumption_unit: 5,
    labour_rate: 0,
    material_rate: 0.5,
    plant_rate: 0,
    subcontractor_rate: 0,
    total_rate: '2,5',
    currency: 'GBP',
    co2_labour_rate: 0,
    co2_material_rate: 0.1,
    co2_plant_rate: 0,
    co2_subcontractor_rate: 0,
    co2_unit_rate: 0.5,
    creation_date: '12-Jun-2020',
    last_edited: '12-Jun-2020',
    creator: 'admin',
    last_editor: 'admin',
  },
  {
    crs_id: 4,
    id: 3,
    itemCode: 'MAT.CON.C25.010',
    title: 'Concrete C25',
    description: '',
    unit: 'M3',
    manhours_unit: 1,
    plant_hours_unit: 1,
    material_consumption_unit: 1,
    labour_rate: 0,
    material_rate: 94,
    plant_rate: 0,
    subcontractor_rate: 0,
    total_rate: 94,
    currency: 'GBP',
    co2_labour_rate: 0,
    co2_material_rate: '225,00',
    co2_plant_rate: 0,
    co2_subcontractor_rate: 0,
    co2_unit_rate: '225,00',
    creation_date: '12-Jun-2020',
    last_edited: '12-Jun-2020',
    creator: 'admin',
    last_editor: 'admin',
  },
  {
    crs_id: 4,
    id: 4,
    itemCode: 'MAT.CON.MSC.010',
    title: 'Spacers',
    description: '',
    unit: 'NO',
    manhours_unit: 1,
    plant_hours_unit: 1,
    material_consumption_unit: 5,
    labour_rate: 0,
    material_rate: 0.5,
    plant_rate: 0,
    subcontractor_rate: 0,
    total_rate: '2,5',
    currency: 'GBP',
    co2_labour_rate: 0,
    co2_material_rate: 0.1,
    co2_plant_rate: 0,
    co2_subcontractor_rate: 0,
    co2_unit_rate: 0.5,
    creation_date: '12-Jun-2020',
    last_edited: '12-Jun-2020',
    creator: 'admin',
    last_editor: 'admin',
  },
  {
    crs_id: 9,
    id: 5,
    itemCode: 'MAT.CON.C28.010',
    title: 'Concrete C28',
    description: '',
    unit: 'M3',
    manhours_unit: 1,
    plant_hours_unit: 1,
    material_consumption_unit: 1,
    labour_rate: 0,
    material_rate: 99,
    plant_rate: 0,
    subcontractor_rate: 0,
    total_rate: 99,
    currency: 'GBP',
    co2_labour_rate: 0,
    co2_material_rate: 240,
    co2_plant_rate: 0,
    co2_subcontractor_rate: 0,
    co2_unit_rate: 240,
    creation_date: '12-Jun-2020',
    last_edited: '12-Jun-2020',
    creator: 'admin',
    last_editor: 'admin',
  },
  {
    crs_id: 9,
    id: 6,
    itemCode: 'MAT.CON.MSC.010',
    title: 'Spacers',
    description: '',
    unit: 'NO',
    manhours_unit: 1,
    plant_hours_unit: 1,
    material_consumption_unit: 5,
    labour_rate: 0,
    material_rate: 0.5,
    plant_rate: 0,
    subcontractor_rate: 0,
    total_rate: 2.5,
    currency: 'GBP',
    co2_labour_rate: 0,
    co2_material_rate: 0.1,
    co2_plant_rate: 0,
    co2_subcontractor_rate: 0,
    co2_unit_rate: 0.5,
    creation_date: '12-Jun-2020',
    last_edited: '12-Jun-2020',
    creator: 'admin',
    last_editor: 'admin',
  },
]
