export const ProjectRegions = [
  'South West',
  'South East London',
  'East of England',
  'Wales',
  'West England',
  'East England',
  'North West',
  'Yorkshire and the Humber',
  'North East',
  'Scotland',
  'Northern Ireland',
  'Isle of Man',
  'Guernsey',
  'Jersey',
]
export const Clients = ['MoD', 'NHS', 'NR', 'HE', 'NG', 'HS2', 'HMRC']
export const Layouts = ['Take-off', 'Estimate', 'Package', 'Valuations', 'Overview']
export const Breakdowns = ['ICMS', 'NRM1', 'CESMM4', 'RMM1', 'HMM', 'Uniclass Ss', 'Uniclass Pr', 'HS2 WBS', 'EA WBS']

interface CategorizationField {
  label: string
  message: string
}

interface ProjectCategorization {
  category: string
  type: string
  subtypes?: string[]
  fields?: CategorizationField[]
}

export const ProjectCategorizations: ProjectCategorization[] = [
  {
    category: 'Infrastructure',
    type: 'Bridge',
    subtypes: ['Underpass', 'Underbridge', 'Overbridge', 'Viaduct', 'Aqueduct', 'Foodbridge', 'Green Bridge'],
    fields: [
      {
        label: 'Length [km]',
        message: 'Enter length',
      },
    ],
  },
  {
    category: 'Infrastructure',
    type: 'Energy',
    subtypes: ['Power Plant', 'Photovoltaic Panels', 'Transmission Line', 'Water Plant', 'Wind Farm'],
    fields: [
      {
        label: 'Length [km]',
        message: 'Enter length',
      },
    ],
  },
  {
    category: 'Infrastructure',
    type: 'Parks',
    subtypes: ['Gardens', 'Parks', 'Squares', 'Promenade', 'Woodlands'],
    fields: [
      {
        label: 'Length [km]',
        message: 'Enter length',
      },
    ],
  },
  {
    category: 'Infrastructure',
    type: 'Pipe',
    subtypes: ['Gas', 'Oil', 'Water'],
    fields: [
      {
        label: 'Length [km]',
        message: 'Enter length',
      },
    ],
  },
  {
    category: 'Infrastructure',
    type: 'Port',
    subtypes: ['Cargo Dock', 'Dry Dock', 'Marine', 'Passengers Station', 'Shipyard'],
    fields: [
      {
        label: 'Length [km]',
        message: 'Enter length',
      },
    ],
  },
  {
    category: 'Infrastructure',
    type: 'Railway',
    subtypes: ['One Track', 'Two Track', 'High Speed'],
    fields: [
      {
        label: 'Length [km]',
        message: 'Enter length',
      },
    ],
  },
  {
    category: 'Infrastructure',
    type: 'Road',
    subtypes: ['Motorway', 'Primary Road', 'Non-primary Road', 'Private Road'],
    fields: [
      {
        label: 'Length [km]',
        message: 'Enter length',
      },
    ],
  },
  {
    category: 'Infrastructure',
    type: 'Water',
    subtypes: [
      'Channel',
      'Control Gate',
      'Dam',
      'Ditch',
      'Flood Prevention Structure',
      'Pumping Station',
      'Water Retaining Structure',
      'Well',
    ],
    fields: [
      {
        label: 'Length [km]',
        message: 'Enter length',
      },
    ],
  },
  {
    category: 'Infrastructure',
    type: 'Telecom',
    subtypes: ['Landline Network', 'Underground Network', 'Underwater Network', 'Wireless network'],
    fields: [
      {
        label: 'Length [km]',
        message: 'Enter length',
      },
    ],
  },
  {
    category: 'Infrastructure',
    type: 'Tunnel',
    subtypes: ['Drilled Tunnel', 'Jacked Tunnel', 'Mined Tunnel', 'Shaft', 'Portal', 'Underpass'],
    fields: [
      {
        label: 'Length [km]',
        message: 'Enter length',
      },
    ],
  },
  {
    category: 'Infrastructure',
    type: 'Other',
    fields: [],
  },
  {
    category: 'Building',
    type: 'Airport',
    fields: [
      {
        label: 'GIFA [sqm]',
        message: 'Enter GIFA',
      },
      {
        label: 'Number of passengers',
        message: 'Enter Number of passengers',
      },
    ],
  },
  {
    category: 'Building',
    type: 'Hospital',
    fields: [
      {
        label: 'GIFA [sqm]',
        message: 'Enter GIFA',
      },
      {
        label: 'Number of beds',
        message: 'Enter Number of beds',
      },
    ],
  },
  {
    category: 'Building',
    type: 'Industrial',
    fields: [
      {
        label: 'GIFA [sqm]',
        message: 'Enter GIFA',
      },
      {
        label: 'Capacity',
        message: 'Enter Capacity',
      },
    ],
  },
  {
    category: 'Building',
    type: 'Office',
    fields: [
      {
        label: 'GIFA [sqm]',
        message: 'Enter GIFA',
      },
      {
        label: 'Number of employees',
        message: 'Enter Number of Employees',
      },
    ],
  },
  {
    category: 'Building',
    type: 'Parking',
    fields: [
      {
        label: 'GIFA [sqm]',
        message: 'Enter GIFA',
      },
      {
        label: 'Number of car spaces',
        message: 'Enter number of car spaces',
      },
    ],
  },
  {
    category: 'Building',
    type: 'Renovation',
    fields: [
      {
        label: 'GIFA [sqm]',
        message: 'Enter GIFA',
      },
    ],
  },
  {
    category: 'Building',
    type: 'Residential',
    fields: [
      {
        label: 'GIFA [sqm]',
        message: 'Enter GIFA',
      },
      {
        label: 'Number of flats',
        message: 'Enter Number of flats',
      },
    ],
  },
  {
    category: 'Building',
    type: 'Retail',
    fields: [
      {
        label: 'GIFA [sqm]',
        message: 'Enter GIFA',
      },
      {
        label: 'Number of clients',
        message: 'Enter number of clients',
      },
    ],
  },
  {
    category: 'Building',
    type: 'School',
    fields: [
      {
        label: 'GIFA [sqm]',
        message: 'Enter GIFA',
      },
      {
        label: 'Number of students',
        message: 'Enter number of students',
      },
    ],
  },
  {
    category: 'Building',
    type: 'Sports Venue',
    fields: [
      {
        label: 'GIFA [sqm]',
        message: 'Enter GIFA',
      },
      {
        label: 'Number of fans',
        message: 'Enter number of fans',
      },
    ],
  },
  {
    category: 'Building',
    type: 'Station',
    fields: [
      {
        label: 'GIFA [sqm]',
        message: 'Enter GIFA',
      },
      {
        label: 'Number of passengers',
        message: 'Enter number of passengers',
      },
    ],
  },
  {
    category: 'Building',
    type: 'Other',
    fields: [
      {
        label: 'GIFA [sqm]',
        message: 'Enter GIFA',
      },
      {
        label: 'Capacity',
        message: 'Enter capacity',
      },
    ],
  },
]
