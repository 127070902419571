import React from 'react'
import { FunctionComponent, useState } from 'react'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { calculateHeightForWholePageTable } from '../../../constants/dimensions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../context/reducer'
import { Quotes, takeoffKeys } from '../../../data/WorkPackagesDetailsData'
import { EmptyValuationFor, ValuationsData } from '../../../data/Valuations.data'
import SterlingTable, { ExtendedColumnType } from '../../basic/table/sterling-table/SterlingTable'
import Select from 'antd/lib/select'
import { sortColumnsAccordingToGrouping } from '../../basic/table/sterling-table/helpers/ColumnsHelpers'
import { BoQData } from '../../../data/BillOfQuantities.data'
import { Row } from 'antd/lib/grid'
import WorkPackagesDetailsHeader from '../work-packages/parts/WorkPackagesDetailsHeader'
import { updateEntry } from '../../../context/resource-library/SterlingTable.actions'
import { ValuationsColumns } from './Valuations.columns'
import ProjectTemplate from '../project/ProjectTemplate'
import { WorkPackagesBoQColumns } from '../work-packages/headers/MiniTablesColumns'
import ValuationsHeader from './ValuationsHeader'
import { setOpenWindowState } from '../../../context/open-windows/OpenWindows.actions'
import BreadcrumbPanel from '../../basic/breadcrumb/BreadcrumbPanel'

const ValuationsPart: FunctionComponent = () => {
  const { height } = useWindowDimensions()
  const tableHeight = calculateHeightForWholePageTable(height) - 100
  const tableId = 'valuations'
  const dispatch = useDispatch()

  const [selectedTakeOffItem, setSelectedItem] = useState('Excavation')
  const [selectedPeriod, setSelectedPeriod] = useState(ValuationsData.map(({ name }) => name)[0])
  const data = useSelector((state: RootState) => state.sterlingTable.parsedData[tableId])
  const [valuations, setValuations] = useState(ValuationsData)
  const [valuationPeriods, setValuationPeriods] = useState(ValuationsData.map(({ name }) => name))

  const columns: ExtendedColumnType[] = [
    {
      title: (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p style={{ flex: 1 }}>Select work package</p>
          <Select
            showSearch={true}
            style={{ width: '50%' }}
            defaultValue={selectedTakeOffItem}
            onSelect={(d) => setSelectedItem(d)}
          >
            {takeoffKeys.map((k) => (
              <Select.Option key={k} value={k}>
                {k}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
      dataIndex: 'boq_gatherer',
      key: 'boq_gatherer',
      editable: true,
      children: WorkPackagesBoQColumns.map((c: any) => ({
        ...c,
        fixed: false,
        width: c.key === 'title' ? 250 : 100,
        editable: true,
      })),
    },
    {
      title: (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p style={{ flex: 1 }}>Select valuation period</p>
          <Select
            showSearch={true}
            style={{ width: '40%' }}
            defaultValue={selectedPeriod}
            onSelect={(d) => setSelectedPeriod(d)}
          >
            {valuationPeriods.map((k) => (
              <Select.Option key={k} value={k}>
                {k}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
      dataIndex: 'valuationsGatherer',
      key: 'valuationsGatherer',
      editable: true,
      children: ValuationsColumns.map((c: any) => ({
        ...c,
        width: c.key === 'title' ? 250 : 100,
        editable: true,
      })),
    },
  ]

  const activeGroupingColumn = useSelector((state: RootState) => state.sterlingTable.groupedBy[tableId])
  const sortedColumns = sortColumnsAccordingToGrouping(columns, activeGroupingColumn)

  const prepareData = () => {
    const valuationPeriodId = valuations.find((v) => v.name === selectedPeriod)?.id
    return (
      data
        // @ts-ignore
        .filter((d) => d.wp_code === selectedTakeOffItem)
        // @ts-ignore
        .filter((d) => d.valuationId === valuationPeriodId || ![...Object.keys(d)].includes('valuationId'))
    )
  }

  const addNewValuation = () => {
    const newValuationsId = valuations.reduce((acc, q) => Math.max(q.id, acc), 0) + 1
    setValuations([
      {
        id: newValuationsId,
        items: BoQData.map(({ id }) => EmptyValuationFor(id, newValuationsId)),
        name: 'New Valuation',
      },
      ...valuations,
    ])
  }

  return (
    <Row>
      <BreadcrumbPanel moveTo={'valuations'} displayName={'Valuations'} categoryName={'Sample Project'} />
      <SterlingTable
        columns={sortedColumns}
        dataSource={prepareData()}
        defaultPagination={false}
        scrollWidth={1000}
        bordered={true}
        onUpdate={(id: number, updates: { [key: string]: any }) => dispatch(updateEntry(tableId, id, updates))}
        renderHeader={(props) => (
          <ValuationsHeader
            sterlingHeaderProps={props}
            tableId={'workPackagesDetails'}
            onValuationAdd={addNewValuation}
          />
        )}
        tableId={'workPackagesDetails'}
        tableHeight={tableHeight}
      />
    </Row>
  )
}

const Valuations = () => (
  <ProjectTemplate selectedKey={'valuations'}>
    <ValuationsPart />
  </ProjectTemplate>
)

export default Valuations
