import { DocumentationState } from './Documentation.state'
import { CommentLayout } from '../../model/Documentation'
import { ADD_COMMENT, AddCommentAction } from './Documentation.action'

const initialState: DocumentationState = {
  visibleComments: new Map<number, CommentLayout[]>(),
}

export function documentationReducer(
  state: DocumentationState = initialState,
  action: AddCommentAction
): DocumentationState {
  switch (action.type) {
    case ADD_COMMENT:
      const { id, comment } = action as AddCommentAction
      const mapToUpdateComment = new Map(state.visibleComments)
      const existingComments = state.visibleComments.get(id)
      mapToUpdateComment.set(id, (existingComments || []).concat(comment))
      return Object.assign({}, state, {
        visibleComments: mapToUpdateComment,
      })
    default:
      return state
  }
}
