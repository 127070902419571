import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './router/routes'
import './App.scss'
import { Provider } from 'react-redux'
import { rootReducer } from './context/reducer'
import { createStore } from 'redux'
import { AppLayout } from './components/domain/app-layout/AppLayout'

function App() {
  return (
    <Provider store={createStore(rootReducer)}>
      <Router>
        <AppLayout>
          <Routes />
        </AppLayout>
      </Router>
    </Provider>
  )
}
export default App
