import React, { useRef, useState } from 'react'
import { Col, Row, Table } from 'antd'
import SterlingTable from '../../../basic/table/sterling-table/SterlingTable'
import { ActivitiesHeader } from '../header/ActivitiesHeader'
import { activitiesColumns } from '../../library/table/columns/Activities.columns'
import { useSelector, useStore } from 'react-redux'
import { RootState } from '../../../../context/reducer'
import { createDndContext, DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { replaceRecursively } from '../../../../utils/FunctionalUtils'
import WorkPackagesBoQ from '../../work-packages/headers/WorkPackagesBoQ'
import { DraggableBodyRow, DroppableBodyRow } from '../../work-packages/drag-drop/DragDropRows'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { WorkPackagesBoQColumns } from '../../work-packages/headers/MiniTablesColumns'

const RNDContext = createDndContext(HTML5Backend)

export default function ActivitiesMapping() {
  const data = useSelector((state: RootState) => state.sterlingTable.parsedData.activities)

  // @ts-ignore
  const state: RootState = useStore().getState() // Yes, I know this can cause cancer.

  const [draggableData, setDraggableData] = useState([...state.sterlingTable.parsedData.billOfQuantities])
  const [droppableData, setDroppableData] = useState(data)

  const dragRow = (dragItemKey: number, [dragItem]: any[]) => {
    if (!dragItem || !dragItemKey) return
    const newItem = Object.assign(dragItem, { disabled: true })
    const draggableDataCopy = draggableData.slice()
    setDraggableData(draggableDataCopy.map((item) => (item.key === dragItemKey ? newItem : item)))
  }

  const dropRow = (dropItemKey: number, [dragItem, dropItem]: any[]) => {
    if (!dropItem || !dropItemKey) return
    const newItem = Object.assign(dropItem, {
      externalData: dropItem.externalData ? [...dropItem.externalData, dragItem] : [dragItem],
    })
    const droppableDataCopy = droppableData.slice()
    replaceRecursively(droppableDataCopy, newItem)
    setDroppableData(droppableDataCopy)
  }

  const manager = useRef(RNDContext)
  const rowHeight = useWindowDimensions().height / 8

  return (
    <DndProvider manager={manager.current.dragDropManager!}>
      <Row>
        <Col span={12} style={{ paddingRight: 12 }}>
          <SterlingTable
            renderHeader={(props) => <ActivitiesHeader sterlingTableHeaderProps={props} />}
            tableId={'ActivitiesMapping'}
            dataSource={droppableData}
            columns={activitiesColumns}
            tableHeight={rowHeight * 6 - 21} // pixel perfect
            disableSelection={true}
            row={(props: any) => <DroppableBodyRow {...props} dndType={'ActivitiesBoQMapping'} />}
            onRow={(record, index) => {
              return {
                index,
                record,
                moveRow: dropRow,
              }
            }}
            rowExpandable={(record: any) => record.externalData}
            expandedRowRender={(record: any) =>
              record.externalData && (
                <Table
                  style={{ width: 800 }}
                  dataSource={record.externalData}
                  columns={WorkPackagesBoQColumns.map((x) => ({ ...x, fixed: false }))}
                  pagination={false}
                />
              )
            }
            defaultPagination={false}
            size={'small'}
          />
        </Col>
        <Col span={12} style={{ paddingLeft: 12 }}>
          <WorkPackagesBoQ
            data={draggableData}
            rowHeight={rowHeight}
            row={(props: any) => <DraggableBodyRow {...props} dndType={'ActivitiesBoQMapping'} />}
            onRow={(record, index) => {
              return {
                index,
                record,
                moveRow: dragRow,
              }
            }}
            tableId={'ActivitiesMappingBoQ'}
          />
        </Col>
      </Row>
    </DndProvider>
  )
}
