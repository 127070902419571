import { Entry } from '../components/basic/table/sterling-table/SterlingTable'
import { WorkPackagesEntry } from './WorkPackages.data'

export const emptyBoQEntry: (key: number) => BoQEntry = (key) => {
  return {
    key: 1000000 + key,
    id: 1000000 + key,
    wbs_code: '',
    title: '',
    note: '',
    description: '',
    location: '',
    status: '',
    currency: '',
    unit: '',
    quantity: undefined,
    estimated_quantity: undefined,
    take_off_quantity: undefined,
    quantity_difference: undefined,
    total_manhours: undefined,
    total_plant_hours: undefined,
    labour_rate: undefined,
    material_rate: undefined,
    plant_rate: undefined,
    subcontractor_rate: undefined,
    total_rate: undefined,
    total_labour_cost: undefined,
    total_material_cost: undefined,
    total_plant_cost: undefined,
    total_subcontractor_cost: undefined,
    base_total: undefined,
    base_mark_up_type: '',
    base_mark_up_value: undefined,
    base_mark_up_percent: undefined,
    net_total: undefined,
    net_mark_up_type: '',
    net_mark_up_value: undefined,
    net_mark_up_percent: undefined,
    gross_total: undefined,
    inspection_allowance: undefined,
    replacement_allowance: undefined,
    servicing_allowance: undefined,
    labour_co2_rate: undefined,
    material_co2_rate: undefined,
    plant_co2_rate: undefined,
    subcontract_co2_rate: undefined,
    total_co2_rate: undefined,
    total_labour_co2: undefined,
    total_material_co2: undefined,
    total_plant_co2: undefined,
    total_subcontract_co2: undefined,
    total_co2: undefined,
    cost___least: undefined,
    cost___likely: undefined,
    cost___most: undefined,
    co2___least: undefined,
    co2___likely: undefined,
    co2___most: undefined,
    awarded_labour_rate: undefined,
    awarded_material_rate: undefined,
    awarded_plant_rate: undefined,
    awarded_subcontract_rate: undefined,
    awarded_total_rate: undefined,
    rate_difference: undefined,
    awarded_labour_cost: undefined,
    awarded_material_cost: undefined,
    awarded_plant_cost: undefined,
    awarded_subcontract_cost: undefined,
    awarded_total_cost: undefined,
    total_cost_difference: undefined,
    estimator: '',
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
    ea_wbs_code: '',
    icms_code: '',
    nrm1_code: '',
    rmm_code: '',
    hmm_code: '',
    cesmm4_code: '',
    uniclass_ss_code: '',
    uniclass_pr_code: '',
    is_assigned_to_wp: '',
    is_assigned_to_activity: '',
    children: undefined,
    parent: undefined,
  }
}

export interface BoQEntry extends Entry {
  key: number
  id: number
  wbs_code: string
  title: string
  note?: string
  code?: string
  description?: string
  location: string
  status: string
  currency: string
  unit: string
  quantity?: number
  estimated_quantity?: number
  take_off_quantity?: number
  quantity_difference?: number
  total_manhours?: number
  total_plant_hours?: number
  labour_rate?: number
  material_rate?: number
  plant_rate?: number
  subcontractor_rate?: number
  total_rate?: number
  total_labour_cost?: number
  total_material_cost?: number
  total_plant_cost?: number
  total_subcontractor_cost?: number
  base_total?: number
  base_mark_up_type: string
  base_mark_up_value?: number
  base_mark_up_percent?: number
  net_total?: number
  net_mark_up_type: string
  net_mark_up_value?: number
  net_mark_up_percent?: number
  gross_total?: number
  inspection_allowance?: number
  replacement_allowance?: number
  servicing_allowance?: number
  labour_co2_rate?: number
  material_co2_rate?: number
  plant_co2_rate?: number
  subcontract_co2_rate?: number
  total_co2_rate?: number
  total_labour_co2?: number
  total_material_co2?: number
  total_plant_co2?: number
  total_subcontract_co2?: number
  total_co2?: number
  cost___least?: number
  cost___likely?: number
  cost___most?: number
  co2___least?: number
  co2___likely?: number
  co2___most?: number
  awarded_labour_rate?: number
  awarded_material_rate?: number
  awarded_plant_rate?: number
  awarded_subcontract_rate?: number
  awarded_total_rate?: number
  rate_difference?: number
  awarded_labour_cost?: number
  awarded_material_cost?: number
  awarded_plant_cost?: number
  awarded_subcontract_cost?: number
  awarded_total_cost?: number
  total_cost_difference?: number
  estimator: string
  creator: string
  last_editor: string
  creation_date: string
  last_edited: string
  ea_wbs_code: string
  icms_code: string
  nrm1_code: string
  nrm2_code?: string
  wp_code?: string
  rmm_code: string
  hmm_code: string
  cesmm4_code: string
  uniclass_ss_code: string
  uniclass_pr_code: string
  is_assigned_to_wp: string
  is_assigned_to_activity: string
}

export const BoQData: any[] = [
  {
    parent: undefined,
    children: undefined,
    key: 7,
    id: 7,
    code: '03.01.01.00.00.0010.0010',
    title: 'Excavation, commencing level stated on the drawings if not original ground level to Slabs',
    quantity: 279.62,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.01.01.00.00.0010',
    nrm1_code: '01.01.01.04',
    nrm2_code: '05.06.02',
    cesmm4_code: 'E.4.2.3',
    wp_code: 'Excavation',
    activity_code: 'Excavation',
    total_manhours: 25.17,
    total_plant_hours: 13.98,
    labour_rate: 1.64,
    material_rate: 0.46,
    plant_rate: 1.82,
    subcontractor_rate: 35.0,
    total_rate: 38.92,
    total_labour_cost: 458.58,
    total_material_cost: 128.63,
    total_plant_cost: 508.91,
    total_subcontractor_cost: 9786.73,
    base_total: 10882.84,
    labour_co2_rate: 0.3,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.3,
    total_labour_co2: 83.89,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 83.89,
  },
  {
    parent: undefined,
    children: undefined,
    key: 8,
    id: 8,
    code: '03.01.01.00.00.0010.0020',
    title:
      'Support to faces of excavation is at the discretion of the contractor, depth, location and method of forming support to be provided for approval.',
    quantity: 233.58,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.01.00.00.0010',
    nrm1_code: '01.01.01.04',
    nrm2_code: '05.06.02',
    cesmm4_code: '-',
    wp_code: 'Excavation',
    activity_code: 'Excavation',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 0.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 0.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 10,
    id: 10,
    code: '03.01.01.00.00.0020.0010',
    title: 'Excavation, commencing level stated on the drawings if not original ground level to Pile Caps',
    quantity: 144.83,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.01.00.00.0020',
    nrm1_code: '01.01.01.04',
    nrm2_code: '05.06.02',
    cesmm4_code: 'E.4.2.3',
    wp_code: 'Excavation',
    activity_code: 'Excavation',
    total_manhours: 13.04,
    total_plant_hours: 7.24,
    labour_rate: 1.64,
    material_rate: 0.46,
    plant_rate: 1.82,
    subcontractor_rate: 35.0,
    total_rate: 38.92,
    total_labour_cost: 237.53,
    total_material_cost: 66.62,
    total_plant_cost: 263.6,
    total_subcontractor_cost: 5069.18,
    base_total: 5636.93,
    labour_co2_rate: 0.3,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.3,
    total_labour_co2: 43.45,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 43.45,
  },
  {
    parent: undefined,
    children: undefined,
    key: 11,
    id: 11,
    code: '03.01.01.00.00.0020.0020',
    title:
      'Support to faces of excavation is at the discretion of the contractor, depth, location and method of forming support to be provided for approval.',
    quantity: 375.33,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.01.01.00.00.0020',
    nrm1_code: '01.01.01.04',
    nrm2_code: '05.06.02',
    cesmm4_code: '-',
    wp_code: 'Excavation',
    activity_code: 'Excavation',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 0.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 0.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 12,
    id: 12,
    code: '03.01.01.00.00.0020.0030',
    title: 'Return Fill & Ram with approved material',
    quantity: 279.62,
    unit: 'Item',
    currency: 'GBP',
    wbs_code: '03.01.01.00.00.0020',
    nrm1_code: '01.01.01.04',
    nrm2_code: '05.06.02',
    cesmm4_code: '-',
    wp_code: 'Excavation',
    activity_code: 'Excavation',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 2800.0,
    total_rate: 2800.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 2800.0,
    base_total: 2800.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 14,
    id: 14,
    code: '03.01.01.00.00.0030.0010',
    title: 'Excavation, commencing level stated on the drawings if not original ground level to Tie beams',
    quantity: 70.29,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.01.00.00.0030',
    nrm1_code: '01.01.01.04',
    nrm2_code: '05.06.02',
    cesmm4_code: 'E.4.2.3',
    wp_code: 'Excavation',
    activity_code: 'Excavation',
    total_manhours: 6.33,
    total_plant_hours: 3.51,
    labour_rate: 1.64,
    material_rate: 0.46,
    plant_rate: 1.82,
    subcontractor_rate: 35.0,
    total_rate: 38.92,
    total_labour_cost: 115.28,
    total_material_cost: 32.33,
    total_plant_cost: 127.93,
    total_subcontractor_cost: 2460.23,
    base_total: 2735.77,
    labour_co2_rate: 0.3,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.3,
    total_labour_co2: 21.09,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 21.09,
  },
  {
    parent: undefined,
    children: undefined,
    key: 15,
    id: 15,
    code: '03.01.01.00.00.0030.0020',
    title:
      'Support to faces of excavation is at the discretion of the contractor, depth, location and method of forming support to be provided for approval.',
    quantity: 187.45,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.01.01.00.00.0030',
    nrm1_code: '01.01.01.04',
    nrm2_code: '05.06.02',
    cesmm4_code: '-',
    wp_code: 'Excavation',
    activity_code: 'Excavation',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 0.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 0.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 16,
    id: 16,
    code: '03.01.01.00.00.0030.0030',
    title: 'Return Fill & Ram with approved material',
    quantity: 279.62,
    unit: 'Item',
    currency: 'GBP',
    wbs_code: '03.01.01.00.00.0030',
    nrm1_code: '01.01.01.04',
    nrm2_code: '05.06.02',
    cesmm4_code: '-',
    wp_code: 'Excavation',
    activity_code: 'Excavation',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 1350.0,
    total_rate: 1350.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1350.0,
    base_total: 1350.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 18,
    id: 18,
    code: '03.01.01.00.00.0040.0010',
    title: 'Excavation, commencing level stated on the drawings if not original ground level to Ground beams',
    quantity: 63.93,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.01.00.00.0040',
    nrm1_code: '01.01.01.04',
    nrm2_code: '05.06.02',
    cesmm4_code: 'E.4.2.3',
    wp_code: 'Excavation',
    activity_code: 'Excavation',
    total_manhours: 5.75,
    total_plant_hours: 3.2,
    labour_rate: 1.64,
    material_rate: 0.46,
    plant_rate: 1.82,
    subcontractor_rate: 35.0,
    total_rate: 38.92,
    total_labour_cost: 104.84,
    total_material_cost: 29.41,
    total_plant_cost: 116.34,
    total_subcontractor_cost: 2237.38,
    base_total: 2487.97,
    labour_co2_rate: 0.3,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.3,
    total_labour_co2: 19.18,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 19.18,
  },
  {
    parent: undefined,
    children: undefined,
    key: 19,
    id: 19,
    code: '03.01.01.00.00.0040.0020',
    title:
      'Support to faces of excavation is at the discretion of the contractor, depth, location and method of forming support to be provided for approval.',
    quantity: 225.14,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.01.01.00.00.0040',
    nrm1_code: '01.01.01.04',
    nrm2_code: '05.06.02',
    cesmm4_code: '-',
    wp_code: 'Excavation',
    activity_code: 'Excavation',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 0.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 0.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 20,
    id: 20,
    code: '03.01.01.00.00.0040.0030',
    title: 'Return Fill & Ram with approved material',
    quantity: 279.62,
    unit: 'Item',
    currency: 'GBP',
    wbs_code: '03.01.01.00.00.0040',
    nrm1_code: '01.01.01.04',
    nrm2_code: '05.06.02',
    cesmm4_code: '-',
    wp_code: 'Excavation',
    activity_code: 'Excavation',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 1200.0,
    total_rate: 1200.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1200.0,
    base_total: 1200.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 22,
    id: 22,
    code: '03.01.01.00.00.0050.0010',
    title: 'Excavating in Hazardous Materials details as per soil reports',
    quantity: 279.62,
    unit: 'Item',
    currency: 'GBP',
    wbs_code: '03.01.01.00.00.0050',
    nrm1_code: '01.01.01.04',
    nrm2_code: '05.06.02',
    cesmm4_code: '-',
    wp_code: 'Excavation',
    activity_code: 'Excavation',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 6900.0,
    total_rate: 6900.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 6900.0,
    base_total: 6900.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 23,
    id: 23,
    code: '03.01.01.00.00.0050.0060',
    title: 'Breaking Up reinforced Concrete',
    quantity: 279.62,
    unit: 'Item',
    currency: 'GBP',
    wbs_code: '03.01.01.00.00.0050',
    nrm1_code: '01.01.01.04',
    nrm2_code: '05.06.02',
    cesmm4_code: '-',
    wp_code: 'Excavation',
    activity_code: 'Excavation',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 1200.0,
    total_rate: 1200.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1200.0,
    base_total: 1200.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 28,
    id: 28,
    code: '03.01.02.01.01.0010.0010',
    title:
      'Piling mats and platforms (installing, moving, modifying and removal on completion) . as specified, NBS D30',
    quantity: 1225.15,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.01.02.01.01.0010',
    nrm1_code: '01.01.01.02',
    nrm2_code: '07.02.01',
    cesmm4_code: '-',
    wp_code: 'Piling',
    activity_code: 'Piling',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 19.53,
    total_rate: 19.53,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 23927.86,
    base_total: 23927.86,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 29,
    id: 29,
    code: '03.01.02.01.01.0010.0020',
    title: 'Piling plant as specified, NBS D30',
    quantity: 279.62,
    unit: 'Item',
    currency: 'GBP',
    wbs_code: '03.01.02.01.01.0010',
    nrm1_code: '01.01.01.02',
    nrm2_code: '07.02.01',
    cesmm4_code: '-',
    wp_code: 'Piling',
    activity_code: 'Piling',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 8000.0,
    total_rate: 8000.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 8000.0,
    base_total: 8000.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 32,
    id: 32,
    code: '03.01.02.02.04.0010',
    title: 'Cast In Place Pile 450 as specified, NBS D30',
    quantity: 153.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.01.02.02.04',
    nrm1_code: '01.01.01.02',
    nrm2_code: '07.02.01',
    cesmm4_code: '-',
    wp_code: 'Piling',
    activity_code: 'Piling',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 6500.0,
    total_rate: 6500.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 994500.0,
    base_total: 994500.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 34,
    id: 34,
    code: '03.01.02.02.07.0010',
    title: 'Disposal of excavated material arising from piling',
    quantity: 1225.15,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.01.02.02.07',
    nrm1_code: '01.01.01.02',
    nrm2_code: '07.02.01',
    cesmm4_code: 'E.5.3.2',
    wp_code: 'Piling',
    activity_code: 'Piling',
    total_manhours: 36.75,
    total_plant_hours: 36.75,
    labour_rate: 0.68,
    material_rate: 0.43,
    plant_rate: 1.46,
    subcontractor_rate: 35.0,
    total_rate: 37.57,
    total_labour_cost: 833.1,
    total_material_cost: 526.81,
    total_plant_cost: 1788.71,
    total_subcontractor_cost: 42880.1,
    base_total: 46028.73,
    labour_co2_rate: 0.09,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 26.33,
    total_co2_rate: 26.42,
    total_labour_co2: 110.26,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 32258.09,
    total_co2: 32368.35,
  },
  {
    parent: undefined,
    children: undefined,
    key: 35,
    id: 35,
    code: '03.01.02.02.07.0020',
    title: 'Extra for breaking out obstructions as specified, NBS C20',
    quantity: 279.62,
    unit: 'Item',
    currency: 'GBP',
    wbs_code: '03.01.02.02.07',
    nrm1_code: '01.01.01.02',
    nrm2_code: '07.02.01',
    cesmm4_code: 'Q.1.7.4',
    wp_code: 'Piling',
    activity_code: 'Piling',
    total_manhours: 1.0,
    total_plant_hours: 0.67,
    labour_rate: 23.11,
    material_rate: 1.46,
    plant_rate: 9.19,
    subcontractor_rate: 0.0,
    total_rate: 33.76,
    total_labour_cost: 23.11,
    total_material_cost: 1.46,
    total_plant_cost: 9.19,
    total_subcontractor_cost: 0.0,
    base_total: 33.76,
    labour_co2_rate: 3.57,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 3.57,
    total_labour_co2: 3.57,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 3.57,
  },
  {
    parent: undefined,
    children: undefined,
    key: 37,
    id: 37,
    code: '03.01.02.02.09.0010',
    title: 'Cutting off tops of concrete piles',
    quantity: 153.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.01.02.02.09',
    nrm1_code: '01.01.01.02',
    nrm2_code: '07.02.01',
    cesmm4_code: 'Q.1.6.4',
    wp_code: 'Piling',
    activity_code: 'Piling',
    total_manhours: 229.5,
    total_plant_hours: 153.0,
    labour_rate: 34.67,
    material_rate: 2.19,
    plant_rate: 13.79,
    subcontractor_rate: 30.95,
    total_rate: 81.6,
    total_labour_cost: 5304.51,
    total_material_cost: 335.07,
    total_plant_cost: 2109.87,
    total_subcontractor_cost: 4735.35,
    base_total: 12484.8,
    labour_co2_rate: 5.37,
    material_co2_rate: 17.31,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 32.59,
    total_co2_rate: 55.27,
    total_labour_co2: 821.61,
    total_material_co2: 2648.43,
    total_plant_co2: 0.0,
    total_subcontract_co2: 4986.27,
    total_co2: 8456.31,
  },
  {
    parent: undefined,
    children: undefined,
    key: 39,
    id: 39,
    code: '03.01.02.02.10.0010',
    title: 'Pile tests as specified, NBS D30',
    quantity: 279.62,
    unit: 'Item',
    currency: 'GBP',
    wbs_code: '03.01.02.02.10',
    nrm1_code: '01.01.01.02',
    nrm2_code: '07.02.01',
    cesmm4_code: '-',
    wp_code: 'Piling',
    activity_code: 'Piling',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 0.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 0.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 42,
    id: 42,
    code: '03.01.02.02.12.0010.0010',
    title: 'Formwork Rough finish all sides, Plain formwork left to the discursion of the contractor NBS Spec E20',
    quantity: 320.12,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.02.02.12.0010',
    nrm1_code: '01.01.01.02',
    nrm2_code: '11.13.01',
    cesmm4_code: 'G.2.1.4',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 880.33,
    total_plant_hours: 0.0,
    labour_rate: 65.13,
    material_rate: 11.66,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 76.79,
    total_labour_cost: 20849.46,
    total_material_cost: 3732.61,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 24582.07,
    labour_co2_rate: 9.86,
    material_co2_rate: 5.38,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 15.24,
    total_labour_co2: 3156.39,
    total_material_co2: 1722.25,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 4878.64,
  },
  {
    parent: undefined,
    children: undefined,
    key: 43,
    id: 43,
    code: '03.01.02.02.12.0010.0020',
    title: 'Reinforcement as specified, NBS E30',
    quantity: 19.9,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.01.02.02.12.0010',
    nrm1_code: '01.01.01.02',
    nrm2_code: '11.34.01',
    cesmm4_code: 'G.5.2.5',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 298.74,
    total_plant_hours: 0.0,
    labour_rate: 327.18,
    material_rate: 580.85,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 908.03,
    total_labour_cost: 6511.77,
    total_material_cost: 11560.49,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 18072.25,
    labour_co2_rate: 53.79,
    material_co2_rate: 978.17,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 1031.96,
    total_labour_co2: 1070.57,
    total_material_co2: 19468.23,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 20538.79,
  },
  {
    parent: undefined,
    children: undefined,
    key: 44,
    id: 44,
    code: '03.01.02.02.12.0010.0030',
    title: 'In-situ concrete (PC1) as specified, NBS E05',
    quantity: 110.57,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.01.02.02.12.0010',
    nrm1_code: '01.01.01.02',
    nrm2_code: '11.02.01',
    cesmm4_code: 'F.1.4.2',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 132.68,
    total_plant_hours: 667.85,
    labour_rate: 21.28,
    material_rate: 94.0,
    plant_rate: 11.08,
    subcontractor_rate: 0.0,
    total_rate: 126.36,
    total_labour_cost: 2352.94,
    total_material_cost: 10393.63,
    total_plant_cost: 1225.12,
    total_subcontractor_cost: 0.0,
    base_total: 13971.7,
    labour_co2_rate: 4.3,
    material_co2_rate: 207.0,
    plant_co2_rate: 48.68,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 259.98,
    total_labour_co2: 475.45,
    total_material_co2: 22888.11,
    total_plant_co2: 5382.58,
    total_subcontract_co2: 0.0,
    total_co2: 28746.14,
  },
  {
    parent: undefined,
    children: undefined,
    key: 45,
    id: 45,
    code: '03.01.02.02.12.0010.0040',
    title: 'Strip formwork to cast In-situ concrete as specified, NBS E20',
    quantity: 492.82,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.02.02.12.0010',
    nrm1_code: '01.01.01.02',
    nrm2_code: '11.13.01',
    cesmm4_code: '-',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 25.69,
    total_rate: 25.69,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 12660.01,
    base_total: 12660.01,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 46,
    id: 46,
    code: '03.01.02.02.12.0010.0050',
    title: 'Surface finish to In-situ concrete as specified, NBS E41',
    quantity: 103.18,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.02.02.12.0010',
    nrm1_code: '01.01.01.02',
    nrm2_code: '11.09.01',
    cesmm4_code: 'G.8.1.1',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 20.64,
    total_plant_hours: 0.0,
    labour_rate: 3.42,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 3.42,
    total_labour_cost: 352.87,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 352.87,
    labour_co2_rate: 0.72,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.72,
    total_labour_co2: 74.29,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 74.29,
  },
  {
    parent: undefined,
    children: undefined,
    key: 49,
    id: 49,
    code: '03.01.02.02.13.0010.0010',
    title: 'Formwork Rough finish all sides, Plain formwork left to the discursion of the contractor NBS Spec E20',
    quantity: 362.25,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.02.02.13.0010',
    nrm1_code: '01.01.01.02',
    nrm2_code: '11.13.01',
    cesmm4_code: 'G.2.1.4',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 996.18,
    total_plant_hours: 0.0,
    labour_rate: 65.13,
    material_rate: 11.66,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 76.79,
    total_labour_cost: 23593.1,
    total_material_cost: 4223.79,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 27816.89,
    labour_co2_rate: 9.86,
    material_co2_rate: 5.38,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 15.24,
    total_labour_co2: 3571.75,
    total_material_co2: 1948.88,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 5520.63,
  },
  {
    parent: undefined,
    children: undefined,
    key: 50,
    id: 50,
    code: '03.01.02.02.13.0010.0020',
    title: 'Reinforcement as specified, NBS E30',
    quantity: 16.37,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.01.02.02.13.0010',
    nrm1_code: '01.01.01.02',
    nrm2_code: '11.34.01',
    cesmm4_code: 'G.5.2.5',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 245.73,
    total_plant_hours: 0.0,
    labour_rate: 327.18,
    material_rate: 580.85,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 908.03,
    total_labour_cost: 5356.38,
    total_material_cost: 9509.3,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 14865.68,
    labour_co2_rate: 53.79,
    material_co2_rate: 978.17,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 1031.96,
    total_labour_co2: 880.61,
    total_material_co2: 16013.96,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 16894.58,
  },
  {
    parent: undefined,
    children: undefined,
    key: 51,
    id: 51,
    code: '03.01.02.02.13.0010.0030',
    title: 'In-situ concrete (TB1)as specified, NBS E05',
    quantity: 90.95,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.01.02.02.13.0010',
    nrm1_code: '01.01.01.02',
    nrm2_code: '11.02.01',
    cesmm4_code: 'F.1.4.2',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 109.14,
    total_plant_hours: 549.35,
    labour_rate: 21.28,
    material_rate: 94.0,
    plant_rate: 11.08,
    subcontractor_rate: 0.0,
    total_rate: 126.36,
    total_labour_cost: 1935.46,
    total_material_cost: 8549.48,
    total_plant_cost: 1007.75,
    total_subcontractor_cost: 0.0,
    base_total: 11492.69,
    labour_co2_rate: 4.3,
    material_co2_rate: 207.0,
    plant_co2_rate: 48.68,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 259.98,
    total_labour_co2: 391.09,
    total_material_co2: 18827.05,
    total_plant_co2: 4427.54,
    total_subcontract_co2: 0.0,
    total_co2: 23645.68,
  },
  {
    parent: undefined,
    children: undefined,
    key: 52,
    id: 52,
    code: '03.01.02.02.13.0010.0040',
    title: 'Strip formwork to cast In-situ concrete as specified, NBS E20',
    quantity: 413.52,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.02.02.13.0010',
    nrm1_code: '01.01.01.02',
    nrm2_code: '11.13.01',
    cesmm4_code: '-',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 31758.7,
    base_total: 31758.7,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 53,
    id: 53,
    code: '03.01.02.02.13.0010.0050',
    title: 'Surface finish to In-situ concrete as specified, NBS E41',
    quantity: 133.95,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.02.02.13.0010',
    nrm1_code: '01.01.01.02',
    nrm2_code: '11.09.01',
    cesmm4_code: 'G.8.1.1',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 26.79,
    total_plant_hours: 0.0,
    labour_rate: 3.42,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 3.42,
    total_labour_cost: 458.1,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 458.1,
    labour_co2_rate: 0.72,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.72,
    total_labour_co2: 96.44,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 96.44,
  },
  {
    parent: undefined,
    children: undefined,
    key: 58,
    id: 58,
    code: '03.01.03.01.01.0010.0010',
    title: 'Formwork Rough finish all sides, Plain formwork left to the discursion of the contractor NBS Spec E20',
    quantity: 20.83,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.03.01.01.0010',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.13.01',
    cesmm4_code: 'G.2.1.4',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 57.27,
    total_plant_hours: 0.0,
    labour_rate: 65.13,
    material_rate: 11.66,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 76.79,
    total_labour_cost: 1356.34,
    total_material_cost: 242.82,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 1599.17,
    labour_co2_rate: 9.86,
    material_co2_rate: 5.38,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 15.24,
    total_labour_co2: 205.34,
    total_material_co2: 112.04,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 317.38,
  },
  {
    parent: undefined,
    children: undefined,
    key: 59,
    id: 59,
    code: '03.01.03.01.01.0010.0020',
    title: 'Reinforcement as specified, NBS E30',
    quantity: 6.19,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.01.03.01.01.0010',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.34.01',
    cesmm4_code: 'G.5.2.5',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 92.98,
    total_plant_hours: 0.0,
    labour_rate: 327.18,
    material_rate: 580.85,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 908.03,
    total_labour_cost: 2026.81,
    total_material_cost: 3598.24,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 5625.05,
    labour_co2_rate: 53.79,
    material_co2_rate: 978.17,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 1031.96,
    total_labour_co2: 333.22,
    total_material_co2: 6059.55,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 6392.77,
  },
  {
    parent: undefined,
    children: undefined,
    key: 60,
    id: 60,
    code: '03.01.03.01.01.0010.0030',
    title: 'In-situ concrete (250mm) as specified, NBS E05',
    quantity: 34.42,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.01.03.01.01.0010',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.02.01',
    cesmm4_code: 'F.1.4.2',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 41.3,
    total_plant_hours: 207.87,
    labour_rate: 21.28,
    material_rate: 94.0,
    plant_rate: 11.08,
    subcontractor_rate: 0.0,
    total_rate: 126.36,
    total_labour_cost: 732.36,
    total_material_cost: 3235.05,
    total_plant_cost: 381.32,
    total_subcontractor_cost: 0.0,
    base_total: 4348.74,
    labour_co2_rate: 4.3,
    material_co2_rate: 207.0,
    plant_co2_rate: 48.68,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 259.98,
    total_labour_co2: 147.99,
    total_material_co2: 7124.0,
    total_plant_co2: 1675.35,
    total_subcontract_co2: 0.0,
    total_co2: 8947.34,
  },
  {
    parent: undefined,
    children: undefined,
    key: 61,
    id: 61,
    code: '03.01.03.01.01.0010.0040',
    title: 'Strip formwork to cast In-situ concrete as specified, NBS E20',
    quantity: 20.83,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.03.01.01.0010',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.13.01',
    cesmm4_code: '-',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1599.37,
    base_total: 1599.37,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 62,
    id: 62,
    code: '03.01.03.01.01.0010.0050',
    title: 'Surface finish to In-situ concrete as specified, NBS E41',
    quantity: 137.66,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.03.01.01.0010',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.09.01',
    cesmm4_code: 'G.8.1.1',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 27.53,
    total_plant_hours: 0.0,
    labour_rate: 3.42,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 3.42,
    total_labour_cost: 470.8,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 470.8,
    labour_co2_rate: 0.72,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.72,
    total_labour_co2: 99.12,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 99.12,
  },
  {
    parent: undefined,
    children: undefined,
    key: 63,
    id: 63,
    code: '03.01.03.01.01.0010.0060',
    title: 'Vapour barrier to cast In-situ concrete as specified, NBS J40A',
    quantity: 158.49,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.03.01.01.0010',
    nrm1_code: '01.01.01.03',
    nrm2_code: '19.01.01',
    cesmm4_code: '-',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 12171.81,
    base_total: 12171.81,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 65,
    id: 65,
    code: '03.01.03.01.01.0020.0010',
    title: 'Formwork Rough finish all sides, Plain formwork left to the discursion of the contractor NBS Spec E20',
    quantity: 61.82,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.03.01.01.0020',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.13.01',
    cesmm4_code: 'G.2.1.4',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 170.01,
    total_plant_hours: 0.0,
    labour_rate: 65.13,
    material_rate: 11.66,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 76.79,
    total_labour_cost: 4026.55,
    total_material_cost: 720.86,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 4747.41,
    labour_co2_rate: 9.86,
    material_co2_rate: 5.38,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 15.24,
    total_labour_co2: 609.58,
    total_material_co2: 332.61,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 942.19,
  },
  {
    parent: undefined,
    children: undefined,
    key: 66,
    id: 66,
    code: '03.01.03.01.01.0020.0020',
    title: 'Reinforcement as specified, NBS E30',
    quantity: 50.73,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.01.03.01.01.0020',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.34.01',
    cesmm4_code: 'G.5.2.5',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 761.45,
    total_plant_hours: 0.0,
    labour_rate: 327.18,
    material_rate: 580.85,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 908.03,
    total_labour_cost: 16597.76,
    total_material_cost: 29466.38,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 46064.15,
    labour_co2_rate: 53.79,
    material_co2_rate: 978.17,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 1031.96,
    total_labour_co2: 2728.75,
    total_material_co2: 49622.33,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 52351.09,
  },
  {
    parent: undefined,
    children: undefined,
    key: 67,
    id: 67,
    code: '03.01.03.01.01.0020.0030',
    title: 'In-situ concrete (250mm) as specified, NBS E05',
    quantity: 281.83,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.01.03.01.01.0020',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.02.01',
    cesmm4_code: 'F.1.4.2',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 338.2,
    total_plant_hours: 1702.27,
    labour_rate: 21.28,
    material_rate: 94.0,
    plant_rate: 11.08,
    subcontractor_rate: 0.0,
    total_rate: 126.36,
    total_labour_cost: 5997.39,
    total_material_cost: 26492.21,
    total_plant_cost: 3122.7,
    total_subcontractor_cost: 0.0,
    base_total: 35612.29,
    labour_co2_rate: 4.3,
    material_co2_rate: 207.0,
    plant_co2_rate: 48.68,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 259.98,
    total_labour_co2: 1211.88,
    total_material_co2: 58339.23,
    total_plant_co2: 13719.58,
    total_subcontract_co2: 0.0,
    total_co2: 73270.69,
  },
  {
    parent: undefined,
    children: undefined,
    key: 68,
    id: 68,
    code: '03.01.03.01.01.0020.0040',
    title: 'Strip formwork to cast In-situ concrete as specified, NBS E20',
    quantity: 61.82,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.03.01.01.0020',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.13.01',
    cesmm4_code: '-',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 4748.03,
    base_total: 4748.03,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 69,
    id: 69,
    code: '03.01.03.01.01.0020.0050',
    title: 'Surface finish to In-situ concrete as specified, NBS E41',
    quantity: 1127.33,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.03.01.01.0020',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.09.01',
    cesmm4_code: 'G.8.1.1',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 225.47,
    total_plant_hours: 0.0,
    labour_rate: 3.42,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 3.42,
    total_labour_cost: 3855.46,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 3855.46,
    labour_co2_rate: 0.72,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.72,
    total_labour_co2: 811.68,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 811.68,
  },
  {
    parent: undefined,
    children: undefined,
    key: 70,
    id: 70,
    code: '03.01.03.01.01.0020.0060',
    title: 'Vapour barrier to cast In-situ concrete as specified, NBS J40A',
    quantity: 1189.15,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.03.01.01.0020',
    nrm1_code: '01.01.01.03',
    nrm2_code: '19.01.01',
    cesmm4_code: '-',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 91326.82,
    base_total: 91326.82,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 72,
    id: 72,
    code: '03.01.03.01.01.0030.0010',
    title: 'Vapour barrier to cast In-situ concrete as specified, NBS J40A',
    quantity: 8.57,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.03.01.01.0030',
    nrm1_code: '01.01.01.03',
    nrm2_code: '19.01.01',
    cesmm4_code: '-',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 658.07,
    base_total: 658.07,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 73,
    id: 73,
    code: '03.01.03.01.01.0030.0020',
    title: 'Formwork Rough finish all sides, Plain formwork left to the discursion of the contractor NBS Spec E20',
    quantity: 5.71,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.03.01.01.0030',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.13.01',
    cesmm4_code: 'G.2.1.4',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 15.71,
    total_plant_hours: 0.0,
    labour_rate: 65.13,
    material_rate: 11.66,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 76.79,
    total_labour_cost: 372.05,
    total_material_cost: 66.61,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 438.66,
    labour_co2_rate: 9.86,
    material_co2_rate: 5.38,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 15.24,
    total_labour_co2: 56.32,
    total_material_co2: 30.73,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 87.06,
  },
  {
    parent: undefined,
    children: undefined,
    key: 74,
    id: 74,
    code: '03.01.03.01.01.0030.0030',
    title: 'Reinforcement as specified, NBS E30',
    quantity: 0.13,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.01.03.01.01.0030',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.34.01',
    cesmm4_code: 'G.5.2.5',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 1.93,
    total_plant_hours: 0.0,
    labour_rate: 327.18,
    material_rate: 580.85,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 908.03,
    total_labour_cost: 42.05,
    total_material_cost: 74.66,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 116.71,
    labour_co2_rate: 53.79,
    material_co2_rate: 978.17,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 1031.96,
    total_labour_co2: 6.91,
    total_material_co2: 125.72,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 132.64,
  },
  {
    parent: undefined,
    children: undefined,
    key: 75,
    id: 75,
    code: '03.01.03.01.01.0030.0040',
    title: 'In-situ concrete (250mm) as specified, NBS E05',
    quantity: 0.71,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.01.03.01.01.0030',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.02.01',
    cesmm4_code: 'F.1.4.2',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 0.86,
    total_plant_hours: 4.31,
    labour_rate: 21.28,
    material_rate: 94.0,
    plant_rate: 11.08,
    subcontractor_rate: 0.0,
    total_rate: 126.36,
    total_labour_cost: 15.2,
    total_material_cost: 67.12,
    total_plant_cost: 7.91,
    total_subcontractor_cost: 0.0,
    base_total: 90.23,
    labour_co2_rate: 4.3,
    material_co2_rate: 207.0,
    plant_co2_rate: 48.68,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 259.98,
    total_labour_co2: 3.07,
    total_material_co2: 147.81,
    total_plant_co2: 34.76,
    total_subcontract_co2: 0.0,
    total_co2: 185.64,
  },
  {
    parent: undefined,
    children: undefined,
    key: 76,
    id: 76,
    code: '03.01.03.01.01.0030.0050',
    title: 'Strip formwork to cast In-situ concrete as specified, NBS E20',
    quantity: 11.42,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.03.01.01.0030',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.13.01',
    cesmm4_code: '-',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 877.43,
    base_total: 877.43,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 77,
    id: 77,
    code: '03.01.03.01.01.0030.0060',
    title: 'Surface finish to In-situ concrete as specified, NBS E41',
    quantity: 2.86,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.03.01.01.0030',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.09.01',
    cesmm4_code: 'G.8.1.1',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 0.57,
    total_plant_hours: 0.0,
    labour_rate: 3.42,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 3.42,
    total_labour_cost: 9.77,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 9.77,
    labour_co2_rate: 0.72,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.72,
    total_labour_co2: 2.06,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 2.06,
  },
  {
    parent: undefined,
    children: undefined,
    key: 80,
    id: 80,
    code: '03.01.03.01.03.0010.0010',
    title: 'Formwork Rough finish all sides, Plain formwork left to the discretion of the contractor, NBS E20',
    quantity: 94.87,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.03.01.03.0010',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.22.01',
    cesmm4_code: 'G.2.1.4',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 260.89,
    total_plant_hours: 0.0,
    labour_rate: 65.13,
    material_rate: 11.66,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 76.79,
    total_labour_cost: 6178.72,
    total_material_cost: 1106.15,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 7284.87,
    labour_co2_rate: 9.86,
    material_co2_rate: 5.38,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 15.24,
    total_labour_co2: 935.39,
    total_material_co2: 510.39,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 1445.78,
  },
  {
    parent: undefined,
    children: undefined,
    key: 81,
    id: 81,
    code: '03.01.03.01.03.0010.0020',
    title: 'Reinforcement as specified, NBS E30',
    quantity: 1.27,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.01.03.01.03.0010',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.34.01',
    cesmm4_code: 'G.5.2.5',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 19.0,
    total_plant_hours: 0.0,
    labour_rate: 327.18,
    material_rate: 580.85,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 908.03,
    total_labour_cost: 414.16,
    total_material_cost: 735.27,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 1149.44,
    labour_co2_rate: 53.79,
    material_co2_rate: 978.17,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 1031.96,
    total_labour_co2: 68.09,
    total_material_co2: 1238.22,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 1306.31,
  },
  {
    parent: undefined,
    children: undefined,
    key: 82,
    id: 82,
    code: '03.01.03.01.03.0010.0030',
    title: 'In-situ concrete as specified, NBS E05',
    quantity: 10.55,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.01.03.01.03.0010',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.05.01',
    cesmm4_code: 'F.1.4.2',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 12.66,
    total_plant_hours: 63.71,
    labour_rate: 21.28,
    material_rate: 94.0,
    plant_rate: 11.08,
    subcontractor_rate: 0.0,
    total_rate: 126.36,
    total_labour_cost: 224.48,
    total_material_cost: 991.59,
    total_plant_cost: 116.88,
    total_subcontractor_cost: 0.0,
    base_total: 1332.95,
    labour_co2_rate: 4.3,
    material_co2_rate: 207.0,
    plant_co2_rate: 48.68,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 259.98,
    total_labour_co2: 45.36,
    total_material_co2: 2183.6,
    total_plant_co2: 513.52,
    total_subcontract_co2: 0.0,
    total_co2: 2742.48,
  },
  {
    parent: undefined,
    children: undefined,
    key: 83,
    id: 83,
    code: '03.01.03.01.03.0010.0040',
    title: 'Strip formwork to cast In-situ concrete as specified, NBS E20',
    quantity: 63.24,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.03.01.03.0010',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.22.01',
    cesmm4_code: '-',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 4857.21,
    base_total: 4857.21,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 84,
    id: 84,
    code: '03.01.03.01.03.0010.0050',
    title: 'Surface finish to In-situ concrete as specified, NBS E41',
    quantity: 29.88,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.03.01.03.0010',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.12.01',
    cesmm4_code: 'G.8.1.1',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 5.98,
    total_plant_hours: 0.0,
    labour_rate: 3.42,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 3.42,
    total_labour_cost: 102.2,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 102.2,
    labour_co2_rate: 0.72,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.72,
    total_labour_co2: 21.52,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 21.52,
  },
  {
    parent: undefined,
    children: undefined,
    key: 86,
    id: 86,
    code: '03.01.03.01.03.0020.0010',
    title: 'Formwork Rough finish all sides, Plain formwork left to the discursion of the contractor NBS Spec E20',
    quantity: 12.94,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.03.01.03.0020',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.13.01',
    cesmm4_code: 'G.2.1.4',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 35.58,
    total_plant_hours: 0.0,
    labour_rate: 65.13,
    material_rate: 11.66,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 76.79,
    total_labour_cost: 842.62,
    total_material_cost: 150.85,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 993.47,
    labour_co2_rate: 9.86,
    material_co2_rate: 5.38,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 15.24,
    total_labour_co2: 127.56,
    total_material_co2: 69.6,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 197.17,
  },
  {
    parent: undefined,
    children: undefined,
    key: 87,
    id: 87,
    code: '03.01.03.01.03.0020.0020',
    title: 'Reinforcement as specified, NBS E30',
    quantity: 0.98,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.01.03.01.03.0020',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.34.01',
    cesmm4_code: 'G.5.2.5',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 14.72,
    total_plant_hours: 0.0,
    labour_rate: 327.18,
    material_rate: 580.85,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 908.03,
    total_labour_cost: 320.78,
    total_material_cost: 569.49,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 890.27,
    labour_co2_rate: 53.79,
    material_co2_rate: 978.17,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 1031.96,
    total_labour_co2: 52.74,
    total_material_co2: 959.03,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 1011.77,
  },
  {
    parent: undefined,
    children: undefined,
    key: 88,
    id: 88,
    code: '03.01.03.01.03.0020.0030',
    title: 'In-situ concrete (750mm) as specified, NBS E05',
    quantity: 8.17,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.01.03.01.03.0020',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.02.01',
    cesmm4_code: 'F.1.4.2',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 9.8,
    total_plant_hours: 49.35,
    labour_rate: 21.28,
    material_rate: 94.0,
    plant_rate: 11.08,
    subcontractor_rate: 0.0,
    total_rate: 126.36,
    total_labour_cost: 173.86,
    total_material_cost: 768.01,
    total_plant_cost: 90.53,
    total_subcontractor_cost: 0.0,
    base_total: 1032.4,
    labour_co2_rate: 4.3,
    material_co2_rate: 207.0,
    plant_co2_rate: 48.68,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 259.98,
    total_labour_co2: 35.13,
    total_material_co2: 1691.25,
    total_plant_co2: 397.73,
    total_subcontract_co2: 0.0,
    total_co2: 2124.12,
  },
  {
    parent: undefined,
    children: undefined,
    key: 89,
    id: 89,
    code: '03.01.03.01.03.0020.0040',
    title: 'Strip formwork to cast In-situ concrete as specified, NBS E20',
    quantity: 12.94,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.03.01.03.0020',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.13.01',
    cesmm4_code: '-',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 993.6,
    base_total: 993.6,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 90,
    id: 90,
    code: '03.01.03.01.03.0020.0050',
    title: 'Surface finish to In-situ concrete as specified, NBS E41',
    quantity: 10.89,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.01.03.01.03.0020',
    nrm1_code: '01.01.01.03',
    nrm2_code: '11.09.01',
    cesmm4_code: 'G.8.1.1',
    wp_code: 'In-situ Concrete',
    activity_code: 'Foundations',
    total_manhours: 2.18,
    total_plant_hours: 0.0,
    labour_rate: 3.42,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 3.42,
    total_labour_cost: 37.26,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 37.26,
    labour_co2_rate: 0.72,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.72,
    total_labour_co2: 7.84,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 7.84,
  },
  {
    parent: undefined,
    children: undefined,
    key: 96,
    id: 96,
    code: '03.02.01.01.01.0010.0010',
    title: 'Supply Universal Beam as specified, NBS G10',
    quantity: 22.98,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.02.01.01.01.0010',
    nrm1_code: '01.02.01.01',
    nrm2_code: '15.01.02',
    cesmm4_code: '-',
    wp_code: 'Steel works',
    activity_code: 'Steel roof',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1764.76,
    base_total: 1764.76,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 97,
    id: 97,
    code: '03.02.01.01.01.0010.0020',
    title: 'Erect Universal beams as specified, NBS G10',
    quantity: 142.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.01.01.01.0010',
    nrm1_code: '01.02.01.01',
    nrm2_code: '15.02.02',
    cesmm4_code: '-',
    wp_code: 'Steel works',
    activity_code: 'Steel roof',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 10905.6,
    base_total: 10905.6,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 98,
    id: 98,
    code: '03.02.01.01.01.0010.0030',
    title: 'Cleaning and preparation Universal beams as specified, NBS Z12',
    quantity: 686.73,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.01.01.0010',
    nrm1_code: '01.02.01.01',
    nrm2_code: '15.10.01',
    cesmm4_code: '-',
    wp_code: 'Steel works',
    activity_code: 'Steel roof',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 52740.9,
    base_total: 52740.9,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 99,
    id: 99,
    code: '03.02.01.01.01.0010.0040',
    title: 'Sprayed fire protection as specified, NBS Z12',
    quantity: 686.73,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.01.01.0010',
    nrm1_code: '01.02.01.01',
    nrm2_code: '31.05.01',
    cesmm4_code: '-',
    wp_code: 'Steel works',
    activity_code: 'Steel roof',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 52740.9,
    base_total: 52740.9,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 101,
    id: 101,
    code: '03.02.01.01.01.0020.0010',
    title: 'Supply Universal columns as specified, NBS G10',
    quantity: 11.02,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.02.01.01.01.0020',
    nrm1_code: '01.02.01.01',
    nrm2_code: '15.01.02',
    cesmm4_code: '-',
    wp_code: 'Steel works',
    activity_code: 'Steel roof',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 846.11,
    base_total: 846.11,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 102,
    id: 102,
    code: '03.02.01.01.01.0020.0020',
    title: 'Erect Universal columns as specified, NBS G10',
    quantity: 119.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.01.01.01.0020',
    nrm1_code: '01.02.01.01',
    nrm2_code: '15.02.02',
    cesmm4_code: '-',
    wp_code: 'Steel works',
    activity_code: 'Steel roof',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 9139.2,
    base_total: 9139.2,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 103,
    id: 103,
    code: '03.02.01.01.01.0020.0030',
    title: 'Cleaning and preparation to Universal columns as specified, NBS Z12',
    quantity: 349.85,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.01.01.0020',
    nrm1_code: '01.02.01.01',
    nrm2_code: '15.10.01',
    cesmm4_code: '-',
    wp_code: 'Steel works',
    activity_code: 'Steel roof',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 26868.36,
    base_total: 26868.36,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 104,
    id: 104,
    code: '03.02.01.01.01.0020.0040',
    title: 'Sprayed fire protection as specified, NBS Z12',
    quantity: 349.85,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.01.01.0020',
    nrm1_code: '01.02.01.01',
    nrm2_code: '31.05.01',
    cesmm4_code: '-',
    wp_code: 'Steel works',
    activity_code: 'Steel roof',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 26868.36,
    base_total: 26868.36,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 106,
    id: 106,
    code: '03.02.01.01.01.0030.0010',
    title: 'Supply Hollow Sections as specified, NBS G10',
    quantity: 3.79,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.02.01.01.01.0030',
    nrm1_code: '01.02.01.01',
    nrm2_code: '15.01.02',
    cesmm4_code: '-',
    wp_code: 'Steel works',
    activity_code: 'Steel roof',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 291.32,
    base_total: 291.32,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 107,
    id: 107,
    code: '03.02.01.01.01.0030.0020',
    title: 'Erect Hollow Section thick as specified, NBS G10',
    quantity: 28.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.01.01.01.0030',
    nrm1_code: '01.02.01.01',
    nrm2_code: '15.02.02',
    cesmm4_code: '-',
    wp_code: 'Steel works',
    activity_code: 'Steel roof',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 2150.4,
    base_total: 2150.4,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 108,
    id: 108,
    code: '03.02.01.01.01.0030.0030',
    title: 'Cleaning and preparation to Hollow Section thick as specified, NBS Z12',
    quantity: 71.17,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.01.01.0030',
    nrm1_code: '01.02.01.01',
    nrm2_code: '15.10.01',
    cesmm4_code: '-',
    wp_code: 'Steel works',
    activity_code: 'Steel roof',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 5465.86,
    base_total: 5465.86,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 109,
    id: 109,
    code: '03.02.01.01.01.0030.0040',
    title: 'Sprayed fire protection as specified, NBS Z12',
    quantity: 71.17,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.01.01.0030',
    nrm1_code: '01.02.01.01',
    nrm2_code: '31.05.01',
    cesmm4_code: '-',
    wp_code: 'Steel works',
    activity_code: 'Steel roof',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 5465.86,
    base_total: 5465.86,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 111,
    id: 111,
    code: '03.02.01.01.01.0040.0010',
    title: 'Supply Parallel Flange Channels as specified, NBS G10',
    quantity: 1.33,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.02.01.01.01.0040',
    nrm1_code: '01.02.01.01',
    nrm2_code: '15.01.02',
    cesmm4_code: '-',
    wp_code: 'Steel works',
    activity_code: 'Steel roof',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 101.99,
    base_total: 101.99,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 112,
    id: 112,
    code: '03.02.01.01.01.0040.0020',
    title: 'Erect Parallel Flange Channels as specified, NBS G10',
    quantity: 30.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.01.01.01.0040',
    nrm1_code: '01.02.01.01',
    nrm2_code: '15.02.02',
    cesmm4_code: '-',
    wp_code: 'Steel works',
    activity_code: 'Steel roof',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 2304.0,
    base_total: 2304.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 113,
    id: 113,
    code: '03.02.01.01.01.0040.0030',
    title: 'Cleaning and preparation to Parallel Flange Channels as specified, NBS Z12',
    quantity: 31.05,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.01.01.0040',
    nrm1_code: '01.02.01.01',
    nrm2_code: '15.10.01',
    cesmm4_code: '-',
    wp_code: 'Steel works',
    activity_code: 'Steel roof',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 2384.86,
    base_total: 2384.86,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 114,
    id: 114,
    code: '03.02.01.01.01.0040.0040',
    title: 'Sprayed fire protection as specified, NBS Z12',
    quantity: 31.05,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.01.01.0040',
    nrm1_code: '01.02.01.01',
    nrm2_code: '31.05.01',
    cesmm4_code: '-',
    wp_code: 'Steel works',
    activity_code: 'Steel roof',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 2384.86,
    base_total: 2384.86,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 116,
    id: 116,
    code: '03.02.01.01.01.0050.0010',
    title: 'Supply Kingspan M145065170 Purlin as specified, NBS G10',
    quantity: 5.31,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.02.01.01.01.0050',
    nrm1_code: '01.02.01.01',
    nrm2_code: '15.01.02',
    cesmm4_code: '-',
    wp_code: 'Steel works',
    activity_code: 'Steel roof',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 407.82,
    base_total: 407.82,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 117,
    id: 117,
    code: '03.02.01.01.01.0050.0020',
    title: 'Erect Kingspan M145065170 Purlin as specified, NBS G10',
    quantity: 62.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.01.01.01.0050',
    nrm1_code: '01.02.01.01',
    nrm2_code: '15.02.02',
    cesmm4_code: '-',
    wp_code: 'Steel works',
    activity_code: 'Steel roof',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 4761.6,
    base_total: 4761.6,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 121,
    id: 121,
    code: '03.02.01.03.03.0010.0010',
    title: 'Stone Cladding as specified, NBS F22A',
    quantity: 108.79,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.03.03.0010',
    nrm1_code: '01.02.01.03',
    nrm2_code: '14.04.01',
    cesmm4_code: '-',
    wp_code: 'Cladding',
    activity_code: 'Steel roof',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 8354.85,
    base_total: 8354.85,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 123,
    id: 123,
    code: '03.02.01.03.03.0020.0010',
    title: 'Stone Cladding as specified, NBS F22A',
    quantity: 13.92,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.03.03.0020',
    nrm1_code: '01.02.01.03',
    nrm2_code: '14.04.01',
    cesmm4_code: '-',
    wp_code: 'Cladding',
    activity_code: 'Steel roof',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1068.75,
    base_total: 1068.75,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 127,
    id: 127,
    code: '03.02.01.04.01.0010.0010',
    title: 'Formwork Rough finish all sides, Plain formwork left to the discursion of the contractor NBS Spec E20',
    quantity: 586.44,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0010',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.20.01',
    cesmm4_code: 'G.2.1.4',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 1612.7,
    total_plant_hours: 0.0,
    labour_rate: 65.13,
    material_rate: 11.66,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 76.79,
    total_labour_cost: 38194.66,
    total_material_cost: 6837.86,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 45032.52,
    labour_co2_rate: 9.86,
    material_co2_rate: 5.38,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 15.24,
    total_labour_co2: 5782.27,
    total_material_co2: 3155.03,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 8937.3,
  },
  {
    parent: undefined,
    children: undefined,
    key: 128,
    id: 128,
    code: '03.02.01.04.01.0010.0020',
    title: 'Reinforcement as specified, NBS E30',
    quantity: 11.15,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0010',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.34.01',
    cesmm4_code: 'G.5.2.5',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 167.3,
    total_plant_hours: 0.0,
    labour_rate: 327.18,
    material_rate: 580.85,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 908.03,
    total_labour_cost: 3646.66,
    total_material_cost: 6474.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 10120.66,
    labour_co2_rate: 53.79,
    material_co2_rate: 978.17,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 1031.96,
    total_labour_co2: 599.53,
    total_material_co2: 10902.42,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 11501.95,
  },
  {
    parent: undefined,
    children: undefined,
    key: 129,
    id: 129,
    code: '03.02.01.04.01.0010.0030',
    title: 'In-situ reinforced concrete as specified, NBS E05',
    quantity: 49.54,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0010',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.05.02',
    cesmm4_code: 'F.1.4.2',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 59.44,
    total_plant_hours: 299.2,
    labour_rate: 21.28,
    material_rate: 94.0,
    plant_rate: 11.08,
    subcontractor_rate: 0.0,
    total_rate: 126.36,
    total_labour_cost: 1054.14,
    total_material_cost: 4656.44,
    total_plant_cost: 548.87,
    total_subcontractor_cost: 0.0,
    base_total: 6259.44,
    labour_co2_rate: 4.3,
    material_co2_rate: 207.0,
    plant_co2_rate: 48.68,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 259.98,
    total_labour_co2: 213.01,
    total_material_co2: 10254.08,
    total_plant_co2: 2411.44,
    total_subcontract_co2: 0.0,
    total_co2: 12878.52,
  },
  {
    parent: undefined,
    children: undefined,
    key: 130,
    id: 130,
    code: '03.02.01.04.01.0010.0040',
    title: 'Strip formwork to cast In-situ concrete as specified, NBS E20',
    quantity: 586.44,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0010',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.20.01',
    cesmm4_code: '-',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 45038.39,
    base_total: 45038.39,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 131,
    id: 131,
    code: '03.02.01.04.01.0010.0050',
    title: 'Surface finish to In-situ concrete as specified, NBS E41',
    quantity: 586.44,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0010',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.12.01',
    cesmm4_code: 'G.8.1.1',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 117.29,
    total_plant_hours: 0.0,
    labour_rate: 3.42,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 3.42,
    total_labour_cost: 2005.62,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 2005.62,
    labour_co2_rate: 0.72,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.72,
    total_labour_co2: 422.23,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 422.23,
  },
  {
    parent: undefined,
    children: undefined,
    key: 133,
    id: 133,
    code: '03.02.01.04.01.0020.0010',
    title: 'Formwork Rough finish all sides, Plain formwork left to the discursion of the contractor NBS Spec E20',
    quantity: 2.49,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0020',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.13.01',
    cesmm4_code: 'G.2.1.4',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 6.84,
    total_plant_hours: 0.0,
    labour_rate: 65.13,
    material_rate: 11.66,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 76.79,
    total_labour_cost: 161.89,
    total_material_cost: 28.98,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 190.87,
    labour_co2_rate: 9.86,
    material_co2_rate: 5.38,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 15.24,
    total_labour_co2: 24.51,
    total_material_co2: 13.37,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 37.88,
  },
  {
    parent: undefined,
    children: undefined,
    key: 134,
    id: 134,
    code: '03.02.01.04.01.0020.0020',
    title: 'Reinforcement as specified, NBS E30',
    quantity: 0.11,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0020',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.34.01',
    cesmm4_code: 'G.5.2.5',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 1.65,
    total_plant_hours: 0.0,
    labour_rate: 327.18,
    material_rate: 580.85,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 908.03,
    total_labour_cost: 35.92,
    total_material_cost: 63.77,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 99.7,
    labour_co2_rate: 53.79,
    material_co2_rate: 978.17,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 1031.96,
    total_labour_co2: 5.91,
    total_material_co2: 107.4,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 113.3,
  },
  {
    parent: undefined,
    children: undefined,
    key: 134,
    id: 134,
    code: '03.02.01.04.01.0020.0020',
    title: 'In-situ reinforced concrete as specified, NBS E05',
    quantity: 0.78,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0020',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.02.01',
    cesmm4_code: 'G.5.2.5',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 11.77,
    total_plant_hours: 0.0,
    labour_rate: 327.18,
    material_rate: 580.85,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 908.03,
    total_labour_cost: 256.59,
    total_material_cost: 455.53,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 712.12,
    labour_co2_rate: 53.79,
    material_co2_rate: 978.17,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 1031.96,
    total_labour_co2: 42.18,
    total_material_co2: 767.13,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 809.31,
  },
  {
    parent: undefined,
    children: undefined,
    key: 136,
    id: 136,
    code: '03.02.01.04.01.0020.0040',
    title: 'Strip formwork to cast In-situ concrete as specified, NBS E20',
    quantity: 2.49,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0020',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.13.01',
    cesmm4_code: '-',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 190.89,
    base_total: 190.89,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 137,
    id: 137,
    code: '03.02.01.04.01.0020.0050',
    title: 'Surface finish to In-situ concrete as specified, NBS E41',
    quantity: 2.24,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0020',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.09.01',
    cesmm4_code: 'G.8.1.1',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 0.45,
    total_plant_hours: 0.0,
    labour_rate: 3.42,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 3.42,
    total_labour_cost: 7.65,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 7.65,
    labour_co2_rate: 0.72,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.72,
    total_labour_co2: 1.61,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 1.61,
  },
  {
    parent: undefined,
    children: undefined,
    key: 139,
    id: 139,
    code: '03.02.01.04.01.0030.0010',
    title: 'Formwork Rough finish all sides, Plain formwork left to the discursion of the contractor NBS Spec E20',
    quantity: 35.72,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0030',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.19.01',
    cesmm4_code: 'G.2.1.4',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 98.23,
    total_plant_hours: 0.0,
    labour_rate: 65.13,
    material_rate: 11.66,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 76.79,
    total_labour_cost: 2326.45,
    total_material_cost: 416.5,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 2742.95,
    labour_co2_rate: 9.86,
    material_co2_rate: 5.38,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 15.24,
    total_labour_co2: 352.2,
    total_material_co2: 192.17,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 544.37,
  },
  {
    parent: undefined,
    children: undefined,
    key: 140,
    id: 140,
    code: '03.02.01.04.01.0030.0020',
    title: 'Reinforcement as specified, NBS E30',
    quantity: 1.42,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0030',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.34.01',
    cesmm4_code: 'G.5.2.5',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 21.25,
    total_plant_hours: 0.0,
    labour_rate: 327.18,
    material_rate: 580.85,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 908.03,
    total_labour_cost: 463.3,
    total_material_cost: 822.5,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 1285.8,
    labour_co2_rate: 53.79,
    material_co2_rate: 978.17,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 1031.96,
    total_labour_co2: 76.17,
    total_material_co2: 1385.12,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 1461.28,
  },
  {
    parent: undefined,
    children: undefined,
    key: 141,
    id: 141,
    code: '03.02.01.04.01.0030.0030',
    title: 'In-situ reinforced concrete (400x400mm) as specified, NBS E05',
    quantity: 4.72,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0030',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.05.02',
    cesmm4_code: 'F.1.4.2',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 5.66,
    total_plant_hours: 28.51,
    labour_rate: 21.28,
    material_rate: 94.0,
    plant_rate: 11.08,
    subcontractor_rate: 0.0,
    total_rate: 126.36,
    total_labour_cost: 100.44,
    total_material_cost: 443.69,
    total_plant_cost: 52.3,
    total_subcontractor_cost: 0.0,
    base_total: 596.43,
    labour_co2_rate: 4.3,
    material_co2_rate: 207.0,
    plant_co2_rate: 48.68,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 259.98,
    total_labour_co2: 20.3,
    total_material_co2: 977.06,
    total_plant_co2: 229.77,
    total_subcontract_co2: 0.0,
    total_co2: 1227.13,
  },
  {
    parent: undefined,
    children: undefined,
    key: 142,
    id: 142,
    code: '03.02.01.04.01.0030.0040',
    title: 'Strip formwork to cast In-situ concrete as specified, NBS E20',
    quantity: 35.72,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0030',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.19.01',
    cesmm4_code: '-',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 2743.31,
    base_total: 2743.31,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 143,
    id: 143,
    code: '03.02.01.04.01.0030.0050',
    title: 'Surface finish to In-situ concrete as specified, NBS E41',
    quantity: 11.8,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0030',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.12.01',
    cesmm4_code: 'G.8.1.1',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 2.36,
    total_plant_hours: 0.0,
    labour_rate: 3.42,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 3.42,
    total_labour_cost: 40.36,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 40.36,
    labour_co2_rate: 0.72,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.72,
    total_labour_co2: 8.5,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 8.5,
  },
  {
    parent: undefined,
    children: undefined,
    key: 145,
    id: 145,
    code: '03.02.01.04.01.0040.0010',
    title: 'Formwork Rough finish all sides, Plain formwork left to the discursion of the contractor NBS Spec E20',
    quantity: 61.64,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0040',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.19.01',
    cesmm4_code: 'G.2.1.4',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 169.52,
    total_plant_hours: 0.0,
    labour_rate: 65.13,
    material_rate: 11.66,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 76.79,
    total_labour_cost: 4014.79,
    total_material_cost: 718.75,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 4733.54,
    labour_co2_rate: 9.86,
    material_co2_rate: 5.38,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 15.24,
    total_labour_co2: 607.8,
    total_material_co2: 331.64,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 939.43,
  },
  {
    parent: undefined,
    children: undefined,
    key: 146,
    id: 146,
    code: '03.02.01.04.01.0040.0020',
    title: 'Reinforcement as specified, NBS E30',
    quantity: 2.62,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0040',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.34.01',
    cesmm4_code: 'G.5.2.5',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 39.32,
    total_plant_hours: 0.0,
    labour_rate: 327.18,
    material_rate: 580.85,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 908.03,
    total_labour_cost: 857.15,
    total_material_cost: 1521.72,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 2378.87,
    labour_co2_rate: 53.79,
    material_co2_rate: 978.17,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 1031.96,
    total_labour_co2: 140.92,
    total_material_co2: 2562.62,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 2703.54,
  },
  {
    parent: undefined,
    children: undefined,
    key: 147,
    id: 147,
    code: '03.02.01.04.01.0040.0030',
    title: 'In-situ reinforced concrete (500mmx1000mm) as specified, NBS E05',
    quantity: 15.41,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0040',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.05.02',
    cesmm4_code: 'F.1.4.2',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 18.49,
    total_plant_hours: 93.08,
    labour_rate: 21.28,
    material_rate: 94.0,
    plant_rate: 11.08,
    subcontractor_rate: 0.0,
    total_rate: 126.36,
    total_labour_cost: 327.94,
    total_material_cost: 1448.6,
    total_plant_cost: 170.75,
    total_subcontractor_cost: 0.0,
    base_total: 1947.29,
    labour_co2_rate: 4.3,
    material_co2_rate: 207.0,
    plant_co2_rate: 48.68,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 259.98,
    total_labour_co2: 66.27,
    total_material_co2: 3190.01,
    total_plant_co2: 750.19,
    total_subcontract_co2: 0.0,
    total_co2: 4006.46,
  },
  {
    parent: undefined,
    children: undefined,
    key: 148,
    id: 148,
    code: '03.02.01.04.01.0040.0040',
    title: 'Strip formwork to cast In-situ concrete as specified, NBS E20',
    quantity: 61.64,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0040',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.19.01',
    cesmm4_code: '-',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 4734.16,
    base_total: 4734.16,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 149,
    id: 149,
    code: '03.02.01.04.01.0040.0050',
    title: 'Surface finish to In-situ concrete as specified, NBS E41',
    quantity: 15.41,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0040',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.12.01',
    cesmm4_code: 'G.8.1.1',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 3.08,
    total_plant_hours: 0.0,
    labour_rate: 3.42,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 3.42,
    total_labour_cost: 52.7,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 52.7,
    labour_co2_rate: 0.72,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.72,
    total_labour_co2: 11.1,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 11.1,
  },
  {
    parent: undefined,
    children: undefined,
    key: 151,
    id: 151,
    code: '03.02.01.04.01.0050.0010',
    title: 'Formwork Rough finish all sides, Plain formwork left to the discursion of the contractor NBS Spec E20',
    quantity: 1192.0,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0050',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.22.01',
    cesmm4_code: 'G.2.1.4',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 3278.0,
    total_plant_hours: 0.0,
    labour_rate: 65.13,
    material_rate: 11.66,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 76.79,
    total_labour_cost: 77634.92,
    total_material_cost: 13898.71,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 91533.64,
    labour_co2_rate: 9.86,
    material_co2_rate: 5.38,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 15.24,
    total_labour_co2: 11753.11,
    total_material_co2: 6412.96,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 18166.07,
  },
  {
    parent: undefined,
    children: undefined,
    key: 152,
    id: 152,
    code: '03.02.01.04.01.0050.0020',
    title: 'Reinforcement as specified, NBS E30',
    quantity: 16.69,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0050',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.34.01',
    cesmm4_code: 'G.5.2.5',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 250.49,
    total_plant_hours: 0.0,
    labour_rate: 327.18,
    material_rate: 580.85,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 908.03,
    total_labour_cost: 5459.98,
    total_material_cost: 9693.22,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 15153.2,
    labour_co2_rate: 53.79,
    material_co2_rate: 978.17,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 1031.96,
    total_labour_co2: 897.65,
    total_material_co2: 16323.69,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 17221.34,
  },
  {
    parent: undefined,
    children: undefined,
    key: 153,
    id: 153,
    code: '03.02.01.04.01.0050.0030',
    title: 'In-situ reinforced concrete (200mm) as specified, NBS E05',
    quantity: 119.2,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0050',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.05.02',
    cesmm4_code: 'F.1.4.2',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 143.04,
    total_plant_hours: 719.97,
    labour_rate: 21.28,
    material_rate: 94.0,
    plant_rate: 11.08,
    subcontractor_rate: 0.0,
    total_rate: 126.36,
    total_labour_cost: 2536.57,
    total_material_cost: 11204.79,
    total_plant_cost: 1320.74,
    total_subcontractor_cost: 0.0,
    base_total: 15062.11,
    labour_co2_rate: 4.3,
    material_co2_rate: 207.0,
    plant_co2_rate: 48.68,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 259.98,
    total_labour_co2: 512.56,
    total_material_co2: 24674.39,
    total_plant_co2: 5802.65,
    total_subcontract_co2: 0.0,
    total_co2: 30989.6,
  },
  {
    parent: undefined,
    children: undefined,
    key: 154,
    id: 154,
    code: '03.02.01.04.01.0050.0040',
    title: 'Strip formwork to cast In-situ concrete as specified, NBS E20',
    quantity: 1192.0,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0050',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.22.01',
    cesmm4_code: '-',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 91545.56,
    base_total: 91545.56,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 155,
    id: 155,
    code: '03.02.01.04.01.0050.0050',
    title: 'Surface finish to In-situ concrete as specified, NBS E41',
    quantity: 1192.0,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0050',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.12.01',
    cesmm4_code: 'G.8.1.1',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 238.4,
    total_plant_hours: 0.0,
    labour_rate: 3.42,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 3.42,
    total_labour_cost: 4076.64,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 4076.64,
    labour_co2_rate: 0.72,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.72,
    total_labour_co2: 858.24,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 858.24,
  },
  {
    parent: undefined,
    children: undefined,
    key: 157,
    id: 157,
    code: '03.02.01.04.01.0060.0010',
    title: 'Formwork Rough finish all sides, Plain formwork left to the discursion of the contractor NBS Spec E20',
    quantity: 101.42,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0060',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.22.01',
    cesmm4_code: 'G.2.1.4',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 278.91,
    total_plant_hours: 0.0,
    labour_rate: 65.13,
    material_rate: 11.66,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 76.79,
    total_labour_cost: 6605.58,
    total_material_cost: 1182.58,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 7788.16,
    labour_co2_rate: 9.86,
    material_co2_rate: 5.38,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 15.24,
    total_labour_co2: 1000.02,
    total_material_co2: 545.65,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 1545.66,
  },
  {
    parent: undefined,
    children: undefined,
    key: 158,
    id: 158,
    code: '03.02.01.04.01.0060.0020',
    title: 'Reinforcement as specified, NBS E30',
    quantity: 1.06,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0060',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.34.01',
    cesmm4_code: 'G.5.2.5',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 15.98,
    total_plant_hours: 0.0,
    labour_rate: 327.18,
    material_rate: 580.85,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 908.03,
    total_labour_cost: 348.42,
    total_material_cost: 618.56,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 966.98,
    labour_co2_rate: 53.79,
    material_co2_rate: 978.17,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 1031.96,
    total_labour_co2: 57.28,
    total_material_co2: 1041.68,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 1098.96,
  },
  {
    parent: undefined,
    children: undefined,
    key: 159,
    id: 159,
    code: '03.02.01.04.01.0060.0030',
    title: 'In-situ reinforced concrete (150mm) as specified, NBS E05',
    quantity: 7.61,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0060',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.05.02',
    cesmm4_code: 'F.1.4.2',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 9.13,
    total_plant_hours: 45.94,
    labour_rate: 21.28,
    material_rate: 94.0,
    plant_rate: 11.08,
    subcontractor_rate: 0.0,
    total_rate: 126.36,
    total_labour_cost: 161.87,
    total_material_cost: 715.02,
    total_plant_cost: 84.28,
    total_subcontractor_cost: 0.0,
    base_total: 961.17,
    labour_co2_rate: 4.3,
    material_co2_rate: 207.0,
    plant_co2_rate: 48.68,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 259.98,
    total_labour_co2: 32.71,
    total_material_co2: 1574.57,
    total_plant_co2: 370.29,
    total_subcontract_co2: 0.0,
    total_co2: 1977.57,
  },
  {
    parent: undefined,
    children: undefined,
    key: 160,
    id: 160,
    code: '03.02.01.04.01.0060.0040',
    title: 'Strip formwork to cast In-situ concrete as specified, NBS E20',
    quantity: 101.42,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0060',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.22.01',
    cesmm4_code: '-',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 7789.17,
    base_total: 7789.17,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 161,
    id: 161,
    code: '03.02.01.04.01.0060.0050',
    title: 'Surface finish to In-situ concrete as specified, NBS E41',
    quantity: 101.42,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0060',
    nrm1_code: '01.02.01.04',
    nrm2_code: '11.12.01',
    cesmm4_code: 'G.8.1.1',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 20.28,
    total_plant_hours: 0.0,
    labour_rate: 3.42,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 3.42,
    total_labour_cost: 346.86,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 346.86,
    labour_co2_rate: 0.72,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.72,
    total_labour_co2: 73.02,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 73.02,
  },
  {
    parent: undefined,
    children: undefined,
    key: 163,
    id: 163,
    code: '03.02.01.04.01.0070.0010',
    title: 'Precast Retaining Wall System as specified, NBS E60/183 as specified, NBS E05',
    quantity: 1366.4,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.01.04.01.0070',
    nrm1_code: '01.02.01.04',
    nrm2_code: '13.01.01',
    cesmm4_code: '-',
    wp_code: 'Precast',
    activity_code: 'Concrete frame',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 104939.15,
    base_total: 104939.15,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 168,
    id: 168,
    code: '03.02.02.02.01.0010.0010',
    title: 'Formwork Rough finish all sides, Plain formwork left to the discursion of the contractor NBS Spec E20',
    quantity: 33.21,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0010',
    nrm1_code: '01.02.02.01',
    nrm2_code: '11.14.01',
    cesmm4_code: 'G.2.1.4',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 91.32,
    total_plant_hours: 0.0,
    labour_rate: 65.13,
    material_rate: 11.66,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 76.79,
    total_labour_cost: 2162.81,
    total_material_cost: 387.2,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 2550.01,
    labour_co2_rate: 9.86,
    material_co2_rate: 5.38,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 15.24,
    total_labour_co2: 327.43,
    total_material_co2: 178.66,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 506.08,
  },
  {
    parent: undefined,
    children: undefined,
    key: 169,
    id: 169,
    code: '03.02.02.02.01.0010.0020',
    title: 'Reinforcement as specified, NBS E30',
    quantity: 4.6,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0010',
    nrm1_code: '01.02.02.01',
    nrm2_code: '11.34.01',
    cesmm4_code: 'G.5.2.5',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 69.12,
    total_plant_hours: 0.0,
    labour_rate: 327.18,
    material_rate: 580.85,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 908.03,
    total_labour_cost: 1506.57,
    total_material_cost: 2674.64,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 4181.21,
    labour_co2_rate: 53.79,
    material_co2_rate: 978.17,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 1031.96,
    total_labour_co2: 247.69,
    total_material_co2: 4504.18,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 4751.87,
  },
  {
    parent: undefined,
    children: undefined,
    key: 170,
    id: 170,
    code: '03.02.02.02.01.0010.0030',
    title: 'In-situ reinforced concrete (170mm) as specified, NBS E05',
    quantity: 32.89,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0010',
    nrm1_code: '01.02.02.01',
    nrm2_code: '11.02.01',
    cesmm4_code: 'F.1.4.2',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 39.47,
    total_plant_hours: 198.66,
    labour_rate: 21.28,
    material_rate: 94.0,
    plant_rate: 11.08,
    subcontractor_rate: 0.0,
    total_rate: 126.36,
    total_labour_cost: 699.91,
    total_material_cost: 3091.73,
    total_plant_cost: 364.43,
    total_subcontractor_cost: 0.0,
    base_total: 4156.07,
    labour_co2_rate: 4.3,
    material_co2_rate: 207.0,
    plant_co2_rate: 48.68,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 259.98,
    total_labour_co2: 141.43,
    total_material_co2: 6808.38,
    total_plant_co2: 1601.12,
    total_subcontract_co2: 0.0,
    total_co2: 8550.93,
  },
  {
    parent: undefined,
    children: undefined,
    key: 171,
    id: 171,
    code: '03.02.02.02.01.0010.0040',
    title: 'Strip formwork to cast In-situ concrete as specified, NBS E20',
    quantity: 33.21,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0010',
    nrm1_code: '01.02.02.01',
    nrm2_code: '11.14.01',
    cesmm4_code: '-',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 2550.34,
    base_total: 2550.34,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 172,
    id: 172,
    code: '03.02.02.02.01.0010.0050',
    title: 'Surface finish to In-situ concrete as specified, NBS E41',
    quantity: 193.47,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0010',
    nrm1_code: '01.02.02.01',
    nrm2_code: '11.09.01',
    cesmm4_code: 'G.8.1.1',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 38.69,
    total_plant_hours: 0.0,
    labour_rate: 3.42,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 3.42,
    total_labour_cost: 661.68,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 661.68,
    labour_co2_rate: 0.72,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.72,
    total_labour_co2: 139.3,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 139.3,
  },
  {
    parent: undefined,
    children: undefined,
    key: 174,
    id: 174,
    code: '03.02.02.02.01.0020.0010',
    title: 'Formwork Rough finish all sides, Plain formwork left to the discursion of the contractor NBS Spec E20',
    quantity: 157.61,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0020',
    nrm1_code: '01.02.02.01',
    nrm2_code: '11.14.01',
    cesmm4_code: 'G.2.1.4',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 433.44,
    total_plant_hours: 0.0,
    labour_rate: 65.13,
    material_rate: 11.66,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 76.79,
    total_labour_cost: 10265.44,
    total_material_cost: 1837.79,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 12103.22,
    labour_co2_rate: 9.86,
    material_co2_rate: 5.38,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 15.24,
    total_labour_co2: 1554.08,
    total_material_co2: 847.97,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 2402.05,
  },
  {
    parent: undefined,
    children: undefined,
    key: 175,
    id: 175,
    code: '03.02.02.02.01.0020.0020',
    title: 'Reinforcement as specified, NBS E30',
    quantity: 74.7,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0020',
    nrm1_code: '01.02.02.01',
    nrm2_code: '11.34.01',
    cesmm4_code: 'G.5.2.5',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 1121.2,
    total_plant_hours: 0.0,
    labour_rate: 327.18,
    material_rate: 580.85,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 908.03,
    total_labour_cost: 24439.23,
    total_material_cost: 43387.52,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 67826.75,
    labour_co2_rate: 53.79,
    material_co2_rate: 978.17,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 1031.96,
    total_labour_co2: 4017.93,
    total_material_co2: 73065.97,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 77083.9,
  },
  {
    parent: undefined,
    children: undefined,
    key: 176,
    id: 176,
    code: '03.02.02.02.01.0020.0030',
    title: 'In-situ reinforced concrete (275mm) as specified, NBS E05',
    quantity: 533.55,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0020',
    nrm1_code: '01.02.02.01',
    nrm2_code: '11.02.01',
    cesmm4_code: 'F.1.4.2',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 640.26,
    total_plant_hours: 3222.62,
    labour_rate: 21.28,
    material_rate: 94.0,
    plant_rate: 11.08,
    subcontractor_rate: 0.0,
    total_rate: 126.36,
    total_labour_cost: 11353.88,
    total_material_cost: 50153.43,
    total_plant_cost: 5911.7,
    total_subcontractor_cost: 0.0,
    base_total: 67419.02,
    labour_co2_rate: 4.3,
    material_co2_rate: 207.0,
    plant_co2_rate: 48.68,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 259.98,
    total_labour_co2: 2294.25,
    total_material_co2: 110444.26,
    total_plant_co2: 25973.08,
    total_subcontract_co2: 0.0,
    total_co2: 138711.59,
  },
  {
    parent: undefined,
    children: undefined,
    key: 177,
    id: 177,
    code: '03.02.02.02.01.0020.0040',
    title: 'Strip formwork to cast In-situ concrete as specified, NBS E20',
    quantity: 101.69,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0020',
    nrm1_code: '01.02.02.01',
    nrm2_code: '11.14.01',
    cesmm4_code: '-',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 7809.55,
    base_total: 7809.55,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 178,
    id: 178,
    code: '03.02.02.02.01.0020.0050',
    title: 'Surface finish to In-situ concrete as specified, NBS E41',
    quantity: 1940.17,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0020',
    nrm1_code: '01.02.02.01',
    nrm2_code: '11.09.01',
    cesmm4_code: 'G.8.1.1',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 388.03,
    total_plant_hours: 0.0,
    labour_rate: 3.42,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 3.42,
    total_labour_cost: 6635.39,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 6635.39,
    labour_co2_rate: 0.72,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.72,
    total_labour_co2: 1396.92,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 1396.92,
  },
  {
    parent: undefined,
    children: undefined,
    key: 180,
    id: 180,
    code: '03.02.02.02.01.0030.0010',
    title: 'Formwork Rough finish all sides, Plain formwork left to the discursion of the contractor NBS Spec E20',
    quantity: 1.38,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0030',
    nrm1_code: '01.02.02.01',
    nrm2_code: '11.14.01',
    cesmm4_code: 'G.2.1.4',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 3.81,
    total_plant_hours: 0.0,
    labour_rate: 65.13,
    material_rate: 11.66,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 76.79,
    total_labour_cost: 90.19,
    total_material_cost: 16.15,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 106.34,
    labour_co2_rate: 9.86,
    material_co2_rate: 5.38,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 15.24,
    total_labour_co2: 13.65,
    total_material_co2: 7.45,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 21.1,
  },
  {
    parent: undefined,
    children: undefined,
    key: 181,
    id: 181,
    code: '03.02.02.02.01.0030.0020',
    title: 'Reinforcement as specified, NBS E30',
    quantity: 0.12,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0030',
    nrm1_code: '01.02.02.01',
    nrm2_code: '11.34.01',
    cesmm4_code: 'G.5.2.5',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 1.82,
    total_plant_hours: 0.0,
    labour_rate: 327.18,
    material_rate: 580.85,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 908.03,
    total_labour_cost: 39.62,
    total_material_cost: 70.33,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 109.95,
    labour_co2_rate: 53.79,
    material_co2_rate: 978.17,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 1031.96,
    total_labour_co2: 6.51,
    total_material_co2: 118.44,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 124.96,
  },
  {
    parent: undefined,
    children: undefined,
    key: 182,
    id: 182,
    code: '03.02.02.02.01.0030.0030',
    title: 'In-situ reinforced concrete (150mm) as specified, NBS E05',
    quantity: 0.86,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0030',
    nrm1_code: '01.02.02.01',
    nrm2_code: '11.02.01',
    cesmm4_code: 'F.1.4.2',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 1.04,
    total_plant_hours: 5.22,
    labour_rate: 21.28,
    material_rate: 94.0,
    plant_rate: 11.08,
    subcontractor_rate: 0.0,
    total_rate: 126.36,
    total_labour_cost: 18.41,
    total_material_cost: 81.3,
    total_plant_cost: 9.58,
    total_subcontractor_cost: 0.0,
    base_total: 109.29,
    labour_co2_rate: 4.3,
    material_co2_rate: 207.0,
    plant_co2_rate: 48.68,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 259.98,
    total_labour_co2: 3.72,
    total_material_co2: 179.04,
    total_plant_co2: 42.1,
    total_subcontract_co2: 0.0,
    total_co2: 224.86,
  },
  {
    parent: undefined,
    children: undefined,
    key: 183,
    id: 183,
    code: '03.02.02.02.01.0030.0040',
    title: 'Strip formwork to cast In-situ concrete as specified, NBS E20',
    quantity: 1.38,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0030',
    nrm1_code: '01.02.02.01',
    nrm2_code: '11.14.01',
    cesmm4_code: '-',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.8,
    total_rate: 76.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 106.35,
    base_total: 106.35,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 184,
    id: 184,
    code: '03.02.02.02.01.0030.0050',
    title: 'Surface finish to In-situ concrete as specified, NBS E41',
    quantity: 5.77,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0030',
    nrm1_code: '01.02.02.01',
    nrm2_code: '11.09.01',
    cesmm4_code: 'G.8.1.1',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 1.15,
    total_plant_hours: 0.0,
    labour_rate: 3.42,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 3.42,
    total_labour_cost: 19.72,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 19.72,
    labour_co2_rate: 0.72,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.72,
    total_labour_co2: 4.15,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 4.15,
  },
  {
    parent: undefined,
    children: undefined,
    key: 186,
    id: 186,
    code: '03.02.02.02.01.0040.0010',
    title: 'Formwork Rough finish all sides, Plain formwork left to the discursion of the contractor NBS Spec E20',
    quantity: 1.95,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0040',
    nrm1_code: '01.02.02.01',
    nrm2_code: '11.14.01',
    cesmm4_code: 'G.2.1.4',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 5.36,
    total_plant_hours: 0.0,
    labour_rate: 65.13,
    material_rate: 11.66,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 76.79,
    total_labour_cost: 126.98,
    total_material_cost: 22.73,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 149.72,
    labour_co2_rate: 9.86,
    material_co2_rate: 5.38,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 15.24,
    total_labour_co2: 19.22,
    total_material_co2: 10.49,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 29.71,
  },
  {
    parent: undefined,
    children: undefined,
    key: 187,
    id: 187,
    code: '03.02.02.02.01.0040.0020',
    title: 'Reinforcement as specified, NBS E30',
    quantity: 0.17,
    unit: 't',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0040',
    nrm1_code: '01.02.02.01',
    nrm2_code: '11.34.01',
    cesmm4_code: 'G.5.2.5',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 2.49,
    total_plant_hours: 0.0,
    labour_rate: 327.18,
    material_rate: 580.85,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 908.03,
    total_labour_cost: 54.24,
    total_material_cost: 96.29,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 150.53,
    labour_co2_rate: 53.79,
    material_co2_rate: 978.17,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 1031.96,
    total_labour_co2: 8.92,
    total_material_co2: 162.16,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 171.08,
  },
  {
    parent: undefined,
    children: undefined,
    key: 188,
    id: 188,
    code: '03.02.02.02.01.0040.0030',
    title: 'In-situ reinforced concrete (200mm) as specified, NBS E05',
    quantity: 1.18,
    unit: 'M3',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0040',
    nrm1_code: '01.02.02.01',
    nrm2_code: '11.02.01',
    cesmm4_code: 'F.1.4.2',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 1.42,
    total_plant_hours: 7.15,
    labour_rate: 21.28,
    material_rate: 94.0,
    plant_rate: 11.08,
    subcontractor_rate: 0.0,
    total_rate: 126.36,
    total_labour_cost: 25.2,
    total_material_cost: 111.31,
    total_plant_cost: 13.12,
    total_subcontractor_cost: 0.0,
    base_total: 149.63,
    labour_co2_rate: 4.3,
    material_co2_rate: 207.0,
    plant_co2_rate: 48.68,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 259.98,
    total_labour_co2: 5.09,
    total_material_co2: 245.12,
    total_plant_co2: 57.64,
    total_subcontract_co2: 0.0,
    total_co2: 307.85,
  },
  {
    parent: undefined,
    children: undefined,
    key: 189,
    id: 189,
    code: '03.02.02.02.01.0040.0040',
    title: 'Strip formwork to cast In-situ concrete as specified, NBS E20',
    quantity: 1.95,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0040',
    nrm1_code: '01.02.02.01',
    nrm2_code: '11.14.01',
    cesmm4_code: '-',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 25.69,
    total_rate: 25.69,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 50.09,
    base_total: 50.09,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 190,
    id: 190,
    code: '03.02.02.02.01.0040.0050',
    title: 'Surface finish to In-situ concrete as specified, NBS E41',
    quantity: 5.92,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0040',
    nrm1_code: '01.02.02.01',
    nrm2_code: '11.09.01',
    cesmm4_code: 'G.8.1.1',
    wp_code: 'In-situ Concrete',
    activity_code: 'Concrete frame',
    total_manhours: 1.18,
    total_plant_hours: 0.0,
    labour_rate: 3.42,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 3.42,
    total_labour_cost: 20.25,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 20.25,
    labour_co2_rate: 0.72,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.72,
    total_labour_co2: 4.26,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 4.26,
  },
  {
    parent: undefined,
    children: undefined,
    key: 192,
    id: 192,
    code: '03.02.02.02.01.0050.0010',
    title: 'Install 105mm Insulation as specified, NBS P10A',
    quantity: 892.52,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0050',
    nrm1_code: '01.02.02.01',
    nrm2_code: '31.02.01',
    cesmm4_code: '-',
    wp_code: 'Insulation',
    activity_code: 'Roofing',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 87.5,
    total_rate: 87.5,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 78095.87,
    base_total: 78095.87,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 193,
    id: 193,
    code: '03.02.02.02.01.0050.0020',
    title: 'Install 125mm Insulation as specified, NBS P10A',
    quantity: 88.84,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0050',
    nrm1_code: '01.02.02.01',
    nrm2_code: '31.02.01',
    cesmm4_code: '-',
    wp_code: 'Insulation',
    activity_code: 'Roofing',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 92.5,
    total_rate: 92.5,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 8217.83,
    base_total: 8217.83,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 194,
    id: 194,
    code: '03.02.02.02.01.0050.0030',
    title: 'Install 100mm heavy duty floor screed as specified, NBS P10A',
    quantity: 981.37,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0050',
    nrm1_code: '01.02.02.01',
    nrm2_code: '28.01.01',
    cesmm4_code: '-',
    wp_code: 'Insulation',
    activity_code: 'Roofing',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 32.6,
    total_rate: 32.6,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 31992.66,
    base_total: 31992.66,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 196,
    id: 196,
    code: '03.02.02.02.01.0060.0010',
    title: 'Install raised access floor as specified, NBS K41A',
    quantity: 606.24,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.02.02.01.0060',
    nrm1_code: '01.02.02.01',
    nrm2_code: '13.01.01',
    cesmm4_code: '-',
    wp_code: 'Insulation',
    activity_code: 'Roofing',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 138.92,
    total_rate: 138.92,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 84218.51,
    base_total: 84218.51,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 201,
    id: 201,
    code: '03.02.03.02.01.0010.0010',
    title: 'Sheet metal covered pitched timber roof as specified, NBS H92A',
    quantity: 1278.15,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.03.02.01.0010',
    nrm1_code: '01.02.03.01',
    nrm2_code: '17.01.01',
    cesmm4_code: '-',
    wp_code: 'Roof',
    activity_code: 'Roofing',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 76.5,
    total_rate: 76.5,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 97778.85,
    base_total: 97778.85,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 203,
    id: 203,
    code: '03.02.03.02.01.0020.0010',
    title: 'Install metal standing seam roof as specified, NBS H31A',
    quantity: 15.77,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.03.02.01.0020',
    nrm1_code: '01.02.03.01',
    nrm2_code: '17.01.01',
    cesmm4_code: '-',
    wp_code: 'Roof',
    activity_code: 'Roofing',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 56.0,
    total_rate: 56.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 883.28,
    base_total: 883.28,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 206,
    id: 206,
    code: '03.02.03.02.05.0010.0010',
    title: 'Install soffit planks as specified, NBS H92',
    quantity: 154.39,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.03.02.05.0010',
    nrm1_code: '01.02.03.05',
    nrm2_code: '17.01.01',
    cesmm4_code: '-',
    wp_code: 'Roof',
    activity_code: 'Roofing',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 132.0,
    total_rate: 132.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 20379.42,
    base_total: 20379.42,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 208,
    id: 208,
    code: '03.02.03.02.05.0020.0010',
    title: 'Install Aluminium fascia as specified, NBS H92',
    quantity: 414.33,
    unit: 'M',
    currency: 'GBP',
    wbs_code: '03.02.03.02.05.0020',
    nrm1_code: '01.02.03.05',
    nrm2_code: '17.09.01',
    cesmm4_code: '-',
    wp_code: 'Roof',
    activity_code: 'Roofing',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 98.0,
    total_rate: 98.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 40604.34,
    base_total: 40604.34,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 213,
    id: 213,
    code: '03.02.04.04.01.0010.0010',
    title: 'Supply and install Precast Concrete Stair Landing as specified, NBS E05',
    quantity: 3.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.04.04.01.0010',
    nrm1_code: '01.02.04.01',
    nrm2_code: '13.01.01',
    cesmm4_code: '-',
    wp_code: 'Precast',
    activity_code: 'Concrete frame',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 1480.0,
    total_rate: 1480.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 4440.0,
    base_total: 4440.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 215,
    id: 215,
    code: '03.02.04.04.01.0020.0010',
    title: 'Supply and install Precast Concrete Stair as specified, NBS E60',
    quantity: 9.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.04.04.01.0020',
    nrm1_code: '01.02.04.01',
    nrm2_code: '13.01.01',
    cesmm4_code: '-',
    wp_code: 'Precast',
    activity_code: 'Concrete frame',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 2970.0,
    total_rate: 2970.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 26730.0,
    base_total: 26730.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 217,
    id: 217,
    code: '03.02.04.04.01.0030.0010',
    title: 'Supply and Install Handrail with infill panel as specified, NBS L30A',
    quantity: 35.07,
    unit: 'M',
    currency: 'GBP',
    wbs_code: '03.02.04.04.01.0030',
    nrm1_code: '01.02.04.01',
    nrm2_code: '25.08.01',
    cesmm4_code: 'N.1.4.1',
    wp_code: 'Barriers',
    activity_code: 'External cladding',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 100.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 100.0,
    total_labour_cost: 0.0,
    total_material_cost: 3506.58,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 3506.58,
    labour_co2_rate: 0.0,
    material_co2_rate: 250.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 250.0,
    total_labour_co2: 0.0,
    total_material_co2: 8766.45,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 8766.45,
  },
  {
    parent: undefined,
    children: undefined,
    key: 219,
    id: 219,
    code: '03.02.04.04.01.0040.0010',
    title: 'Supply and install Stair handrail with steel infill panel as specified, NBS L30A',
    quantity: 44.24,
    unit: 'M',
    currency: 'GBP',
    wbs_code: '03.02.04.04.01.0040',
    nrm1_code: '01.02.04.01',
    nrm2_code: '25.08.01',
    cesmm4_code: '-',
    wp_code: 'Barriers',
    activity_code: 'External cladding',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 249.0,
    total_rate: 249.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 11016.36,
    base_total: 11016.36,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 224,
    id: 224,
    code: '03.02.05.01.01.0010.0010',
    title: 'External walls as specified, NBS F10B',
    quantity: 56.29,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.05.01.01.0010',
    nrm1_code: '01.02.05.01',
    nrm2_code: '21.01.02',
    cesmm4_code: '-',
    wp_code: 'Cladding',
    activity_code: 'External cladding',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 105.0,
    total_rate: 105.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 5910.94,
    base_total: 5910.94,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 225,
    id: 225,
    code: '03.02.05.01.01.0010.0020',
    title: '150mm Air Barrier/Cavity as specified, NBS F30/214 as specified, NBS F10B',
    quantity: 56.29,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.05.01.01.0010',
    nrm1_code: '01.02.05.01',
    nrm2_code: '14.15.01',
    cesmm4_code: '-',
    wp_code: 'Cladding',
    activity_code: 'External cladding',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 0.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 0.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 227,
    id: 227,
    code: '03.02.05.01.01.0020.0010',
    title: 'Brick Wall as specified, NBS F10B',
    quantity: 373.31,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.05.01.01.0020',
    nrm1_code: '01.02.05.01',
    nrm2_code: '14.01.01',
    cesmm4_code: '-',
    wp_code: 'Cladding',
    activity_code: 'External cladding',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 105.0,
    total_rate: 105.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 39197.52,
    base_total: 39197.52,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 228,
    id: 228,
    code: '03.02.05.01.01.0020.0020',
    title: '150mm Air Barrier/Cavity as specified, NBS F30/214 as specified, NBS F10B',
    quantity: 373.31,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.05.01.01.0020',
    nrm1_code: '01.02.05.01',
    nrm2_code: '14.15.01',
    cesmm4_code: '-',
    wp_code: 'Cladding',
    activity_code: 'External cladding',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 0.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 0.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 229,
    id: 229,
    code: '03.02.05.01.01.0020.0030',
    title: 'Extra over external walls as specified, NBS F10B',
    quantity: 373.31,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.05.01.01.0020',
    nrm1_code: '01.02.05.01',
    nrm2_code: '21.01.02',
    cesmm4_code: '-',
    wp_code: 'Cladding',
    activity_code: 'External cladding',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 0.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 0.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 230,
    id: 230,
    code: '03.02.05.01.01.0020.0040',
    title: 'Finishes to walls and columns as specified, NBS F10B',
    quantity: 373.31,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.05.01.01.0020',
    nrm1_code: '01.02.05.01',
    nrm2_code: '18.02.01',
    cesmm4_code: '-',
    wp_code: 'Cladding',
    activity_code: 'External cladding',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 8.4,
    total_rate: 8.4,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 3135.8,
    base_total: 3135.8,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 232,
    id: 232,
    code: '03.02.05.01.01.0050.0010',
    title: 'External walls as specified, NBS F10B',
    quantity: 89.57,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.05.01.01.0050',
    nrm1_code: '01.02.05.01',
    nrm2_code: '18.02.01',
    cesmm4_code: '-',
    wp_code: 'Cladding',
    activity_code: 'External cladding',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 105.0,
    total_rate: 105.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 9405.1,
    base_total: 9405.1,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 233,
    id: 233,
    code: '03.02.05.01.01.0050.0020',
    title: '150mm Air Barrier/Cavity as specified, NBS F30/214 as specified, NBS F10B',
    quantity: 89.57,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.05.01.01.0050',
    nrm1_code: '01.02.05.01',
    nrm2_code: '14.15.01',
    cesmm4_code: '-',
    wp_code: 'Cladding',
    activity_code: 'External cladding',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 0.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 0.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 235,
    id: 235,
    code: '03.02.05.01.01.0070.0010',
    title: 'Blockwork as specified, NBS F10B',
    quantity: 536.17,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.05.01.01.0070',
    nrm1_code: '01.02.05.01',
    nrm2_code: '14.01.02',
    cesmm4_code: '-',
    wp_code: 'Cladding',
    activity_code: 'External cladding',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 105.0,
    total_rate: 105.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 56297.8,
    base_total: 56297.8,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 236,
    id: 236,
    code: '03.02.05.01.01.0070.0020',
    title: 'Painting as specified, NBS M60A',
    quantity: 536.17,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.05.01.01.0070',
    nrm1_code: '01.02.05.01',
    nrm2_code: '18.02.01',
    cesmm4_code: '-',
    wp_code: 'Cladding',
    activity_code: 'External cladding',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 0.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 0.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 238,
    id: 238,
    code: '03.02.05.01.01.0080.0010',
    title: 'Rainscreen Cladding to Match Vehicle Gate as Kalzip FC Façade System 30/500 as specified, NBS H92',
    quantity: 81.69,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.05.01.01.0080',
    nrm1_code: '01.02.05.01',
    nrm2_code: '21.01.02',
    cesmm4_code: '-',
    wp_code: 'Cladding',
    activity_code: 'External cladding',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 150.0,
    total_rate: 150.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 12253.35,
    base_total: 12253.35,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 240,
    id: 240,
    code: '03.02.05.01.01.0090.0010',
    title: 'Cladding Covering system as Kalzip aluminium standing seam system as specified, NBS H31a',
    quantity: 162.1,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.05.01.01.0090',
    nrm1_code: '01.02.05.01',
    nrm2_code: '21.01.02',
    cesmm4_code: '-',
    wp_code: 'Cladding',
    activity_code: 'External cladding',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 165.0,
    total_rate: 165.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 26745.85,
    base_total: 26745.85,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 242,
    id: 242,
    code: '03.02.05.01.01.0100.0010',
    title: 'External Render as specified, NBS M20A',
    quantity: 86.39,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.05.01.01.0100',
    nrm1_code: '01.02.05.01',
    nrm2_code: '21.01.02',
    cesmm4_code: '-',
    wp_code: 'Cladding',
    activity_code: 'External cladding',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 32.5,
    total_rate: 32.5,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 2807.69,
    base_total: 2807.69,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 244,
    id: 244,
    code: '03.02.05.01.01.0110.0010',
    title: 'External Render as specified, NBS M20A',
    quantity: 132.93,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.05.01.01.0110',
    nrm1_code: '01.02.05.01',
    nrm2_code: '21.01.02',
    cesmm4_code: '-',
    wp_code: 'Cladding',
    activity_code: 'External cladding',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 32.5,
    total_rate: 32.5,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 4320.38,
    base_total: 4320.38,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 249,
    id: 249,
    code: '03.02.06.01.01.0010.0010',
    title: 'Fixed Ballistic Steel Windows to Entrance Building as specified, NBS L10A',
    quantity: 10.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.06.01.01.0010',
    nrm1_code: '01.02.06.01',
    nrm2_code: '23.01.01',
    cesmm4_code: '-',
    wp_code: 'Windows',
    activity_code: 'Windows',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 2002.5,
    total_rate: 2002.5,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 20025.0,
    base_total: 20025.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 251,
    id: 251,
    code: '03.02.06.01.01.0020.0010',
    title: 'External Window inclusive of ironmongery as specified, NBS L10A',
    quantity: 8.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.06.01.01.0020',
    nrm1_code: '01.02.06.01',
    nrm2_code: '23.01.01',
    cesmm4_code: '-',
    wp_code: 'Windows',
    activity_code: 'Windows',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 2002.5,
    total_rate: 2002.5,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 16020.0,
    base_total: 16020.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 253,
    id: 253,
    code: '03.02.06.01.01.0030.0010',
    title: 'External Window inclusive of ironmongery as specified, NBS L10A',
    quantity: 8.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.06.01.01.0030',
    nrm1_code: '01.02.06.01',
    nrm2_code: '23.01.01',
    cesmm4_code: '-',
    wp_code: 'Windows',
    activity_code: 'Windows',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 2002.5,
    total_rate: 2002.5,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 16020.0,
    base_total: 16020.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 255,
    id: 255,
    code: '03.02.06.01.01.0040.0010',
    title: 'Curtain Wall (Glazing) inclusive of ironmongery as specified, NBS H11A',
    quantity: 5.46,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.06.01.01.0040',
    nrm1_code: '01.02.06.01',
    nrm2_code: '23.01.01',
    cesmm4_code: '-',
    wp_code: 'Windows',
    activity_code: 'Windows',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 800.0,
    total_rate: 800.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 4371.59,
    base_total: 4371.59,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 257,
    id: 257,
    code: '03.02.06.01.01.0050.0010',
    title: 'Curtain Wall (Ballistic Steel) inclusive of ironmongery as specified, NBS H11A',
    quantity: 147.97,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.06.01.01.0050',
    nrm1_code: '01.02.06.01',
    nrm2_code: '23.01.01',
    cesmm4_code: '-',
    wp_code: 'Windows',
    activity_code: 'Windows',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 800.0,
    total_rate: 800.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 118378.77,
    base_total: 118378.77,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 259,
    id: 259,
    code: '03.02.06.01.01.0060.0010',
    title: 'External Window inclusive of ironmongery as specified, NBS L10A',
    quantity: 4.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.06.01.01.0060',
    nrm1_code: '01.02.06.01',
    nrm2_code: '23.01.01',
    cesmm4_code: '-',
    wp_code: 'Windows',
    activity_code: 'Windows',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 2002.5,
    total_rate: 2002.5,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 8010.0,
    base_total: 8010.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 261,
    id: 261,
    code: '03.02.06.01.01.0070.0010',
    title: 'External Window inclusive of ironmongery as specified, NBS L10A',
    quantity: 32.0,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.06.01.01.0070',
    nrm1_code: '01.02.06.01',
    nrm2_code: '23.01.01',
    cesmm4_code: '-',
    wp_code: 'Windows',
    activity_code: 'Windows',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 800.0,
    total_rate: 800.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 25600.0,
    base_total: 25600.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 263,
    id: 263,
    code: '03.02.06.01.01.0080.0010',
    title: 'Install Airlock as specified, NBS L20A',
    quantity: 2.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.06.01.01.0080',
    nrm1_code: '01.02.06.01',
    nrm2_code: '23.01.01',
    cesmm4_code: '-',
    wp_code: 'Windows',
    activity_code: 'Windows',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 2002.5,
    total_rate: 2002.5,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 4005.0,
    base_total: 4005.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 265,
    id: 265,
    code: '03.02.06.01.01.0090.0010',
    title: 'External Window inclusive of ironmongery as specified, NBS L10A',
    quantity: 12.09,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.06.01.01.0090',
    nrm1_code: '01.02.06.01',
    nrm2_code: '23.01.01',
    cesmm4_code: '-',
    wp_code: 'Windows',
    activity_code: 'Windows',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 800.0,
    total_rate: 800.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 9668.55,
    base_total: 9668.55,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 268,
    id: 268,
    code: '03.02.06.01.02.0010.0010',
    title: 'Louver as specified, NBS L10A',
    quantity: 80.97,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.06.01.02.0010',
    nrm1_code: '01.02.06.01',
    nrm2_code: '23.04.01',
    cesmm4_code: '-',
    wp_code: 'Windows',
    activity_code: 'Windows',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 650.0,
    total_rate: 650.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 52631.81,
    base_total: 52631.81,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 270,
    id: 270,
    code: '03.02.06.01.02.0020.0010',
    title: 'Solar Shade as specified, NBS L10A',
    quantity: 48.92,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.06.01.02.0020',
    nrm1_code: '01.02.06.01',
    nrm2_code: '23.04.01',
    cesmm4_code: '-',
    wp_code: 'Windows',
    activity_code: 'Windows',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 650.0,
    total_rate: 650.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 31798.65,
    base_total: 31798.65,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 272,
    id: 272,
    code: '03.02.06.01.02.0030.0010',
    title: 'Solar Shade as specified, NBS L10A',
    quantity: 34.5,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.06.01.02.0030',
    nrm1_code: '01.02.06.01',
    nrm2_code: '23.04.01',
    cesmm4_code: '-',
    wp_code: 'Windows',
    activity_code: 'Windows',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 650.0,
    total_rate: 650.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 22427.99,
    base_total: 22427.99,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 274,
    id: 274,
    code: '03.02.06.01.02.0040.0010',
    title: 'Window lintels in accordance to client schedule',
    quantity: 279.62,
    unit: 'Item',
    currency: 'GBP',
    wbs_code: '03.02.06.01.02.0040',
    nrm1_code: '01.02.06.01',
    nrm2_code: '23.04.01',
    cesmm4_code: '-',
    wp_code: 'Windows',
    activity_code: 'Windows',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 0.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 0.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 278,
    id: 278,
    code: '03.02.06.02.01.0010.0010',
    title: 'Timber door sets with 18SWG steel panels each side. FD60 as specified, NBS L20A',
    quantity: 7.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.06.02.01.0010',
    nrm1_code: '01.02.06.02',
    nrm2_code: '24.02.01',
    cesmm4_code: '-',
    wp_code: 'Doors',
    activity_code: 'External doors',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 569.0,
    total_rate: 569.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 3983.0,
    base_total: 3983.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 280,
    id: 280,
    code: '03.02.06.02.01.0020.0010',
    title: 'Timber door sets with 18SWG steel panels each side. FD60 as specified, NBS L20A',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.06.02.01.0020',
    nrm1_code: '01.02.06.02',
    nrm2_code: '24.02.01',
    cesmm4_code: '-',
    wp_code: 'Doors',
    activity_code: 'External doors',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 569.0,
    total_rate: 569.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 569.0,
    base_total: 569.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 282,
    id: 282,
    code: '03.02.06.02.01.0030.0010',
    title: 'External Door inclusive of ironmongery as specified, NBS L20A',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.06.02.01.0030',
    nrm1_code: '01.02.06.02',
    nrm2_code: '24.02.01',
    cesmm4_code: '-',
    wp_code: 'Doors',
    activity_code: 'External doors',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 420.0,
    total_rate: 420.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 420.0,
    base_total: 420.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 284,
    id: 284,
    code: '03.02.06.02.01.0040.0010',
    title: 'Install Vehicle Gate With Cladding',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.06.02.01.0040',
    nrm1_code: '01.02.06.02',
    nrm2_code: '24.02.01',
    cesmm4_code: '-',
    wp_code: 'Doors',
    activity_code: 'External doors',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 2500.0,
    total_rate: 2500.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 2500.0,
    base_total: 2500.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 286,
    id: 286,
    code: '03.02.06.02.01.0050.0010',
    title: 'Timber door sets with 18SWG steel panels each side. FD60 as specified, NBS L20A',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.06.02.01.0050',
    nrm1_code: '01.02.06.02',
    nrm2_code: '24.02.01',
    cesmm4_code: '-',
    wp_code: 'Doors',
    activity_code: 'External doors',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 569.0,
    total_rate: 569.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 569.0,
    base_total: 569.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 288,
    id: 288,
    code: '03.02.06.02.01.0060.0010',
    title: 'Timber door sets with 18SWG steel panels each side. FD60 as specified, NBS L20A',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.06.02.01.0060',
    nrm1_code: '01.02.06.02',
    nrm2_code: '24.02.01',
    cesmm4_code: '-',
    wp_code: 'Doors',
    activity_code: 'External doors',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 569.0,
    total_rate: 569.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 569.0,
    base_total: 569.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 290,
    id: 290,
    code: '03.02.06.02.01.0070.0010',
    title: 'Timber door sets with 18SWG steel panels each side. FD60 as specified, NBS L20A',
    quantity: 4.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.06.02.01.0070',
    nrm1_code: '01.02.06.02',
    nrm2_code: '24.02.01',
    cesmm4_code: '-',
    wp_code: 'Doors',
    activity_code: 'External doors',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 569.0,
    total_rate: 569.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 2276.0,
    base_total: 2276.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 292,
    id: 292,
    code: '03.02.06.02.01.0080.0010',
    title: 'Timber door sets with 18SWG steel panels each side. FD60 as specified, NBS L20A',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.06.02.01.0080',
    nrm1_code: '01.02.06.02',
    nrm2_code: '24.02.01',
    cesmm4_code: '-',
    wp_code: 'Doors',
    activity_code: 'External doors',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 569.0,
    total_rate: 569.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 569.0,
    base_total: 569.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 294,
    id: 294,
    code: '03.02.06.02.01.0090.0010',
    title: 'Timber door sets with 18SWG steel panels each side. FD60 as specified, NBS L20A',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.06.02.01.0090',
    nrm1_code: '01.02.06.02',
    nrm2_code: '24.02.01',
    cesmm4_code: '-',
    wp_code: 'Doors',
    activity_code: 'External doors',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 569.0,
    total_rate: 569.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 569.0,
    base_total: 569.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 296,
    id: 296,
    code: '03.02.06.02.01.0100.0010',
    title: 'Door lintels in accordance to client schedule',
    quantity: 279.62,
    unit: 'Item',
    currency: 'GBP',
    wbs_code: '03.02.06.02.01.0100',
    nrm1_code: '01.02.06.02',
    nrm2_code: '24.02.01',
    cesmm4_code: '-',
    wp_code: 'Doors',
    activity_code: 'External doors',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 0.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 0.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 298,
    id: 298,
    code: '03.02.06.02.01.0110.0010',
    title: 'Weldmesh cladding to gate as specified, NBS Q40',
    quantity: 2.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.06.02.01.0110',
    nrm1_code: '01.02.06.02',
    nrm2_code: '24.02.01',
    cesmm4_code: '-',
    wp_code: 'Doors',
    activity_code: 'External doors',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 3200.0,
    total_rate: 3200.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 6400.0,
    base_total: 6400.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 301,
    id: 301,
    code: '03.02.06.02.02.0010.0010',
    title: 'Install Revolving Door as specified, NBS L20A',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.06.02.02.0010',
    nrm1_code: '01.02.06.02',
    nrm2_code: '24.02.01',
    cesmm4_code: '-',
    wp_code: 'Doors',
    activity_code: 'External doors',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 1890.0,
    total_rate: 1890.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1890.0,
    base_total: 1890.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 305,
    id: 305,
    code: '03.02.07.01.01.0020',
    title: 'Internal Walls 200 mm Metal Stud Framing, 20GA, 16 OC as specified, NBS K10A',
    quantity: 685.22,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.07.01.01',
    nrm1_code: '01.02.07.01',
    nrm2_code: '20.01.01',
    cesmm4_code: '-',
    wp_code: 'Partitions',
    activity_code: 'Partitions',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 87.5,
    total_rate: 87.5,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 59956.55,
    base_total: 59956.55,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 306,
    id: 306,
    code: '03.02.07.01.01.0030',
    title: 'Hang 5/200 mm TypexGyp Bd, Bottom Course side 1 as specified, NBS K10A',
    quantity: 513.91,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.07.01.01',
    nrm1_code: '01.02.07.01',
    nrm2_code: '20.01.01',
    cesmm4_code: '-',
    wp_code: 'Partitions',
    activity_code: 'Partitions',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 0.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 0.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 307,
    id: 307,
    code: '03.02.07.01.01.0060',
    title: 'Decoration to Walls as specified, NBS M60A',
    quantity: 719.83,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.07.01.01',
    nrm1_code: '01.02.07.01',
    nrm2_code: '20.01.01',
    cesmm4_code: '-',
    wp_code: 'Partitions',
    activity_code: 'Partitions',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 15.0,
    total_rate: 15.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 10797.46,
    base_total: 10797.46,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 309,
    id: 309,
    code: '03.02.07.01.02.0020',
    title: 'Internal FR Walls 200 mm Metal Stud Framing, 20GA, 16 OC as specified, NBS K10A',
    quantity: 161.72,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.07.01.02',
    nrm1_code: '01.02.07.01',
    nrm2_code: '20.01.01',
    cesmm4_code: '-',
    wp_code: 'Partitions',
    activity_code: 'Partitions',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 87.5,
    total_rate: 87.5,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 14150.9,
    base_total: 14150.9,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 310,
    id: 310,
    code: '03.02.07.01.02.0030',
    title: 'Install Acoustical Insulation as specified, NBS P10A',
    quantity: 161.72,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.07.01.02',
    nrm1_code: '01.02.07.01',
    nrm2_code: '20.01.01',
    cesmm4_code: '-',
    wp_code: 'Partitions',
    activity_code: 'Partitions',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 12.5,
    total_rate: 12.5,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 2021.56,
    base_total: 2021.56,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 311,
    id: 311,
    code: '03.02.07.01.02.0040',
    title: 'Hang 5/200 mm TypexGyp Bd, Bottom Course side 1 as specified, NBS K10A',
    quantity: 242.59,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.07.01.02',
    nrm1_code: '01.02.07.01',
    nrm2_code: '20.01.01',
    cesmm4_code: '-',
    wp_code: 'Partitions',
    activity_code: 'Partitions',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 0.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 0.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 312,
    id: 312,
    code: '03.02.07.01.02.0050',
    title: 'Fire Caulking at Head of Wall as specified, NBS K10A',
    quantity: 49.54,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.07.01.02',
    nrm1_code: '01.02.07.01',
    nrm2_code: '20.01.01',
    cesmm4_code: '-',
    wp_code: 'Partitions',
    activity_code: 'Partitions',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 21.0,
    total_rate: 21.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1040.27,
    base_total: 1040.27,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 313,
    id: 313,
    code: '03.02.07.01.02.0080',
    title: 'Decoration to Walls as specified, NBS M60A',
    quantity: 167.66,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.07.01.02',
    nrm1_code: '01.02.07.01',
    nrm2_code: '20.01.01',
    cesmm4_code: '-',
    wp_code: 'Partitions',
    activity_code: 'Partitions',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 15.0,
    total_rate: 15.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 2514.86,
    base_total: 2514.86,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 315,
    id: 315,
    code: '03.02.07.01.03.0020',
    title: 'Internal FR Walls 200 mm Metal Stud Framing, 20GA, 16 OC as specified, NBS K10A',
    quantity: 613.84,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.07.01.03',
    nrm1_code: '01.02.07.01',
    nrm2_code: '20.01.01',
    cesmm4_code: '-',
    wp_code: 'Partitions',
    activity_code: 'Partitions',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 87.5,
    total_rate: 87.5,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 53710.98,
    base_total: 53710.98,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 316,
    id: 316,
    code: '03.02.07.01.03.0030',
    title: 'Install Acoustical Insulation as specified, NBS P10A',
    quantity: 613.84,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.07.01.03',
    nrm1_code: '01.02.07.01',
    nrm2_code: '20.01.01',
    cesmm4_code: '-',
    wp_code: 'Partitions',
    activity_code: 'Partitions',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 12.5,
    total_rate: 12.5,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 7673.0,
    base_total: 7673.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 317,
    id: 317,
    code: '03.02.07.01.03.0040',
    title: 'Hang 5/200 mm TypexGyp Bd, Bottom Course side 1 as specified, NBS K10A',
    quantity: 920.76,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.07.01.03',
    nrm1_code: '01.02.07.01',
    nrm2_code: '20.01.01',
    cesmm4_code: '-',
    wp_code: 'Partitions',
    activity_code: 'Partitions',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 0.0,
    total_rate: 0.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 0.0,
    base_total: 0.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 318,
    id: 318,
    code: '03.02.07.01.03.0050',
    title: 'Fire Caulking at Head of Wall as specified, NBS K10A',
    quantity: 158.81,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.07.01.03',
    nrm1_code: '01.02.07.01',
    nrm2_code: '20.01.01',
    cesmm4_code: '-',
    wp_code: 'Partitions',
    activity_code: 'Partitions',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 21.0,
    total_rate: 21.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 3334.97,
    base_total: 3334.97,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 319,
    id: 319,
    code: '03.02.07.01.03.0080',
    title: 'Decoration to Walls as specified, NBS M60A',
    quantity: 632.88,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.07.01.03',
    nrm1_code: '01.02.07.01',
    nrm2_code: '20.01.01',
    cesmm4_code: '-',
    wp_code: 'Partitions',
    activity_code: 'Partitions',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 15.0,
    total_rate: 15.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 9493.18,
    base_total: 9493.18,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 321,
    id: 321,
    code: '03.02.07.01.04.0010',
    title: 'Install Concrete Blockwork as specified, NBS F10B',
    quantity: 164.7,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.07.01.04',
    nrm1_code: '01.02.07.01',
    nrm2_code: '14.01.02',
    cesmm4_code: '-',
    wp_code: 'Partitions',
    activity_code: 'Partitions',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 94.5,
    total_rate: 94.5,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 15564.25,
    base_total: 15564.25,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 323,
    id: 323,
    code: '03.02.07.01.05.0010',
    title: 'Install Precast Wall as specified, NBS E05',
    quantity: 159.03,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.07.01.05',
    nrm1_code: '01.02.07.01',
    nrm2_code: '13.01.01',
    cesmm4_code: '-',
    wp_code: 'Partitions',
    activity_code: 'Partitions',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 124.0,
    total_rate: 124.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 19719.2,
    base_total: 19719.2,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 326,
    id: 326,
    code: '03.02.07.04.01.0010',
    title: 'Fix Toilet Partitions as specified, NBS K32A',
    quantity: 15.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.07.04.01',
    nrm1_code: '01.02.07.04',
    nrm2_code: '20.01.01',
    cesmm4_code: '-',
    wp_code: 'Partitions',
    activity_code: 'Partitions',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 335.0,
    total_rate: 335.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 5025.0,
    base_total: 5025.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 328,
    id: 328,
    code: '03.02.07.04.02.0010',
    title: 'Install Opaque screen as specified, NBS L10A',
    quantity: 119.06,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.07.04.02',
    nrm1_code: '01.02.07.04',
    nrm2_code: '20.01.01',
    cesmm4_code: '-',
    wp_code: 'Partitions',
    activity_code: 'Partitions',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 99.0,
    total_rate: 99.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 11786.57,
    base_total: 11786.57,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 330,
    id: 330,
    code: '03.02.07.04.03.0010',
    title: 'Install Semi Opaque screen as specified, NBS L10A',
    quantity: 18.06,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.02.07.04.03',
    nrm1_code: '01.02.07.04',
    nrm2_code: '20.01.01',
    cesmm4_code: '-',
    wp_code: 'Partitions',
    activity_code: 'Partitions',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 96.0,
    total_rate: 96.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1733.59,
    base_total: 1733.59,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 334,
    id: 334,
    code: '03.02.08.01.01.0010',
    title: 'Fit Internal Single Dorset including ironmongery as specified, NBS L20A',
    quantity: 78.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.08.01.01',
    nrm1_code: '01.02.08.01',
    nrm2_code: '24.02.01',
    cesmm4_code: '-',
    wp_code: 'Doors',
    activity_code: 'Internal doors',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 289.0,
    total_rate: 289.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 22542.0,
    base_total: 22542.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 336,
    id: 336,
    code: '03.02.08.01.02.0010',
    title: 'Fit Internal Double Dorset including ironmongery as specified, NBS L20A',
    quantity: 8.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.08.01.02',
    nrm1_code: '01.02.08.01',
    nrm2_code: '24.02.01',
    cesmm4_code: '-',
    wp_code: 'Doors',
    activity_code: 'Internal doors',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 289.0,
    total_rate: 289.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 2312.0,
    base_total: 2312.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 338,
    id: 338,
    code: '03.02.08.01.03.0010',
    title: 'Fit Internal Double Door including ironmongery as specified, NBS L20A',
    quantity: 3.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.08.01.03',
    nrm1_code: '01.02.08.01',
    nrm2_code: '24.02.01',
    cesmm4_code: '-',
    wp_code: 'Doors',
    activity_code: 'Internal doors',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 289.0,
    total_rate: 289.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 867.0,
    base_total: 867.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 339,
    id: 339,
    code: '03.02.08.01.03.0020',
    title: 'Fit Internal Double Door including ironmongery as specified, NBS L20A',
    quantity: 3.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.08.01.03',
    nrm1_code: '01.02.08.01',
    nrm2_code: '24.02.01',
    cesmm4_code: '-',
    wp_code: 'Doors',
    activity_code: 'Internal doors',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 289.0,
    total_rate: 289.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 867.0,
    base_total: 867.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 340,
    id: 340,
    code: '03.02.08.01.03.0030',
    title: 'Fit Internal Double Door including ironmongery as specified, NBS L20A',
    quantity: 3.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.02.08.01.03',
    nrm1_code: '01.02.08.01',
    nrm2_code: '24.02.01',
    cesmm4_code: '-',
    wp_code: 'Doors',
    activity_code: 'Internal doors',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 289.0,
    total_rate: 289.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 867.0,
    base_total: 867.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 345,
    id: 345,
    code: '03.03.01.01.01.0010',
    title: 'Eggshell paint as specified, NBS M60',
    quantity: 1039.79,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.03.01.01.01',
    nrm1_code: '01.03.01.01',
    nrm2_code: '28.07.01',
    cesmm4_code: '-',
    wp_code: 'Walls',
    activity_code: 'Wall finishings',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 21.9,
    total_rate: 21.9,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 22771.4,
    base_total: 22771.4,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 346,
    id: 346,
    code: '03.03.01.01.01.0020',
    title: 'Emulsion paint as specified, NBS M60',
    quantity: 4216.29,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.03.01.01.01',
    nrm1_code: '01.03.01.01',
    nrm2_code: '28.07.01',
    cesmm4_code: '-',
    wp_code: 'Walls',
    activity_code: 'Wall finishings',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 16.9,
    total_rate: 16.9,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 71255.3,
    base_total: 71255.3,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 347,
    id: 347,
    code: '03.03.01.01.01.0030',
    title: 'Impervious sheet as specified, NBS M50',
    quantity: 152.51,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.03.01.01.01',
    nrm1_code: '01.03.01.01',
    nrm2_code: '28.07.01',
    cesmm4_code: '-',
    wp_code: 'Walls',
    activity_code: 'Wall finishings',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 43.2,
    total_rate: 43.2,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 6588.43,
    base_total: 6588.43,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 348,
    id: 348,
    code: '03.03.01.01.01.0040',
    title: 'Antibacterial paint as specified, NBS M60',
    quantity: 92.37,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.03.01.01.01',
    nrm1_code: '01.03.01.01',
    nrm2_code: '28.07.01',
    cesmm4_code: '-',
    wp_code: 'Walls',
    activity_code: 'Wall finishings',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 24.5,
    total_rate: 24.5,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 2263.15,
    base_total: 2263.15,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 349,
    id: 349,
    code: '03.03.01.01.01.0050',
    title: 'Wall Finish Protection as specified, NBS K13',
    quantity: 139.13,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.03.01.01.01',
    nrm1_code: '01.03.01.01',
    nrm2_code: '28.07.01',
    cesmm4_code: '-',
    wp_code: 'Walls',
    activity_code: 'Wall finishings',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 35.8,
    total_rate: 35.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 4980.85,
    base_total: 4980.85,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 351,
    id: 351,
    code: '03.03.01.01.02.0010',
    title: 'Integral covered skirting',
    quantity: 205.17,
    unit: 'M',
    currency: 'GBP',
    wbs_code: '03.03.01.01.02',
    nrm1_code: '01.03.01.01',
    nrm2_code: '28.07.01',
    cesmm4_code: '-',
    wp_code: 'Walls',
    activity_code: 'Wall finishings',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 19.6,
    total_rate: 19.6,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 4021.33,
    base_total: 4021.33,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 352,
    id: 352,
    code: '03.03.01.01.02.0020',
    title: 'Soft Wood as specified, NBS P20/110',
    quantity: 563.1,
    unit: 'M',
    currency: 'GBP',
    wbs_code: '03.03.01.01.02',
    nrm1_code: '01.03.01.01',
    nrm2_code: '28.07.01',
    cesmm4_code: '-',
    wp_code: 'Walls',
    activity_code: 'Wall finishings',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 29.6,
    total_rate: 29.6,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 16667.76,
    base_total: 16667.76,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 353,
    id: 353,
    code: '03.03.01.01.02.0030',
    title: 'Vinyl siton / applied',
    quantity: 219.14,
    unit: 'M',
    currency: 'GBP',
    wbs_code: '03.03.01.01.02',
    nrm1_code: '01.03.01.01',
    nrm2_code: '28.07.01',
    cesmm4_code: '-',
    wp_code: 'Walls',
    activity_code: 'Wall finishings',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 12.5,
    total_rate: 12.5,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 2739.25,
    base_total: 2739.25,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 354,
    id: 354,
    code: '03.03.01.01.02.0040',
    title: 'Tile as specified, NBS M40',
    quantity: 156.18,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.03.01.01.02',
    nrm1_code: '01.03.01.01',
    nrm2_code: '28.07.01',
    cesmm4_code: '-',
    wp_code: 'Walls',
    activity_code: 'Wall finishings',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 44.9,
    total_rate: 44.9,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 7012.48,
    base_total: 7012.48,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 358,
    id: 358,
    code: '03.03.02.01.01.0010',
    title: 'Slip resistant sheet vinyl / linoleum as specified, NBS M50/155',
    quantity: 211.1,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.03.02.01.01',
    nrm1_code: '01.03.02.01',
    nrm2_code: '28.02.02',
    cesmm4_code: '-',
    wp_code: 'Flooring',
    activity_code: 'Flooring',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 29.9,
    total_rate: 29.9,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 6311.89,
    base_total: 6311.89,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 359,
    id: 359,
    code: '03.03.02.01.01.0020',
    title: 'Carpet tile as specified, NBS M50',
    quantity: 501.53,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.03.02.01.01',
    nrm1_code: '01.03.02.01',
    nrm2_code: '28.02.02',
    cesmm4_code: '-',
    wp_code: 'Flooring',
    activity_code: 'Flooring',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 25.5,
    total_rate: 25.5,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 12789.02,
    base_total: 12789.02,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 360,
    id: 360,
    code: '03.03.02.01.01.0030',
    title: 'Acoustic Vinyl/Linoleum as specified, NBS M50',
    quantity: 181.88,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.03.02.01.01',
    nrm1_code: '01.03.02.01',
    nrm2_code: '28.02.02',
    cesmm4_code: '-',
    wp_code: 'Flooring',
    activity_code: 'Flooring',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 34.9,
    total_rate: 34.9,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 6347.61,
    base_total: 6347.61,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 361,
    id: 361,
    code: '03.03.02.01.01.0040',
    title: 'Terrazzo as specified, NBS M12',
    quantity: 251.48,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.03.02.01.01',
    nrm1_code: '01.03.02.01',
    nrm2_code: '28.02.02',
    cesmm4_code: '-',
    wp_code: 'Flooring',
    activity_code: 'Flooring',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 89.4,
    total_rate: 89.4,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 22482.31,
    base_total: 22482.31,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 362,
    id: 362,
    code: '03.03.02.01.01.0050',
    title: 'Entrance Matting as specified, NBS M50',
    quantity: 88.81,
    unit: 'M',
    currency: 'GBP',
    wbs_code: '03.03.02.01.01',
    nrm1_code: '01.03.02.01',
    nrm2_code: '28.02.02',
    cesmm4_code: '-',
    wp_code: 'Flooring',
    activity_code: 'Flooring',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 69.9,
    total_rate: 69.9,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 6207.82,
    base_total: 6207.82,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 363,
    id: 363,
    code: '03.03.02.01.01.0060',
    title: 'Sheet vinyl / linoleum as specified, NBS M50',
    quantity: 90.26,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.03.02.01.01',
    nrm1_code: '01.03.02.01',
    nrm2_code: '28.02.02',
    cesmm4_code: '-',
    wp_code: 'Flooring',
    activity_code: 'Flooring',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 22.9,
    total_rate: 22.9,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 2066.95,
    base_total: 2066.95,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 364,
    id: 364,
    code: '03.03.02.01.01.0070',
    title: 'Paint / seal as specified, NBS M60',
    quantity: 155.06,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.03.02.01.01',
    nrm1_code: '01.03.02.01',
    nrm2_code: '28.02.02',
    cesmm4_code: '-',
    wp_code: 'Flooring',
    activity_code: 'Flooring',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 8.5,
    total_rate: 8.5,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1318.01,
    base_total: 1318.01,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 368,
    id: 368,
    code: '03.03.03.01.01.0010',
    title: 'Suspended nonsecure, as specified, NBS K40',
    quantity: 949.63,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.03.03.01.01',
    nrm1_code: '01.03.03.01',
    nrm2_code: '30.01.02',
    cesmm4_code: '-',
    wp_code: 'Ceilings',
    activity_code: 'Ceilings',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 26.6,
    total_rate: 26.6,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 25260.16,
    base_total: 25260.16,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 369,
    id: 369,
    code: '03.03.03.01.01.0020',
    title: 'None exposed services',
    quantity: 452.96,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.03.03.01.01',
    nrm1_code: '01.03.03.01',
    nrm2_code: '30.01.02',
    cesmm4_code: '-',
    wp_code: 'Ceilings',
    activity_code: 'Ceilings',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 24.5,
    total_rate: 24.5,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 11097.52,
    base_total: 11097.52,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 370,
    id: 370,
    code: '03.03.03.01.01.0030',
    title: 'Suspended secure, as specified, NBS K40',
    quantity: 252.23,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.03.03.01.01',
    nrm1_code: '01.03.03.01',
    nrm2_code: '30.01.02',
    cesmm4_code: '-',
    wp_code: 'Ceilings',
    activity_code: 'Ceilings',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 34.6,
    total_rate: 34.6,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 8727.16,
    base_total: 8727.16,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 371,
    id: 371,
    code: '03.03.03.01.01.0040',
    title: 'Ecophon Solo suspended panels as specified, NBS K40',
    quantity: 185.65,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.03.03.01.01',
    nrm1_code: '01.03.03.01',
    nrm2_code: '30.01.02',
    cesmm4_code: '-',
    wp_code: 'Ceilings',
    activity_code: 'Ceilings',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 29.8,
    total_rate: 29.8,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 5532.23,
    base_total: 5532.23,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 372,
    id: 372,
    code: '03.03.03.01.01.0050',
    title: 'Secure plank suspended ceiling as specified, NBS K40/106',
    quantity: 57.92,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.03.03.01.01',
    nrm1_code: '01.03.03.01',
    nrm2_code: '30.01.02',
    cesmm4_code: '-',
    wp_code: 'Ceilings',
    activity_code: 'Ceilings',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 32.9,
    total_rate: 32.9,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1905.54,
    base_total: 1905.54,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 373,
    id: 373,
    code: '03.03.03.01.01.0060',
    title: 'Plaster Board Bulkheads as specified, NBS K10',
    quantity: 1.3,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.03.03.01.01',
    nrm1_code: '01.03.03.01',
    nrm2_code: '30.01.02',
    cesmm4_code: '-',
    wp_code: 'Ceilings',
    activity_code: 'Ceilings',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 59.9,
    total_rate: 59.9,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 78.03,
    base_total: 78.03,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 374,
    id: 374,
    code: '03.03.03.01.01.0070',
    title: 'Acoustic panel lining, as specified, NBS K13',
    quantity: 90.73,
    unit: 'M2',
    currency: 'GBP',
    wbs_code: '03.03.03.01.01',
    nrm1_code: '01.03.03.01',
    nrm2_code: '30.01.02',
    cesmm4_code: '-',
    wp_code: 'Ceilings',
    activity_code: 'Ceilings',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 44.6,
    total_rate: 44.6,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 4046.7,
    base_total: 4046.7,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 379,
    id: 379,
    code: '03.04.01.01.01.0010',
    title: 'Bench seat as specified, NBS N10',
    quantity: 2.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 45.0,
    total_rate: 45.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 90.0,
    base_total: 90.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 380,
    id: 380,
    code: '03.04.01.01.01.0020',
    title: 'Cabinet filing, 4 drawer, lockable',
    quantity: 25.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 38.0,
    total_rate: 38.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 950.0,
    base_total: 950.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 381,
    id: 381,
    code: '03.04.01.01.01.0030',
    title: 'Chair dining, staff use',
    quantity: 10.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 150.0,
    total_rate: 150.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1500.0,
    base_total: 1500.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 382,
    id: 382,
    code: '03.04.01.01.01.0040',
    title: 'Chair Easy Armchair',
    quantity: 9.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 179.0,
    total_rate: 179.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1611.0,
    base_total: 1611.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 383,
    id: 383,
    code: '03.04.01.01.01.0050',
    title: 'Chair meeting',
    quantity: 34.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 150.0,
    total_rate: 150.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 5100.0,
    base_total: 5100.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 384,
    id: 384,
    code: '03.04.01.01.01.0060',
    title: 'Chair moulded as specified, NBS N10',
    quantity: 8.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 150.0,
    total_rate: 150.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1200.0,
    base_total: 1200.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 385,
    id: 385,
    code: '03.04.01.01.01.0070',
    title: 'Chair operator with arms',
    quantity: 18.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 150.0,
    total_rate: 150.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 2700.0,
    base_total: 2700.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 386,
    id: 386,
    code: '03.04.01.01.01.0080',
    title: 'Chair plastic With Arms',
    quantity: 6.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 89.0,
    total_rate: 89.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 534.0,
    base_total: 534.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 387,
    id: 387,
    code: '03.04.01.01.01.0090',
    title: 'Chair stacking, Staff/Visitors',
    quantity: 4.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 89.0,
    total_rate: 89.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 356.0,
    base_total: 356.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 388,
    id: 388,
    code: '03.04.01.01.01.0100',
    title: 'Chair upholstered linear seating 20 No',
    quantity: 2.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 1690.0,
    total_rate: 1690.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 3380.0,
    base_total: 3380.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 389,
    id: 389,
    code: '03.04.01.01.01.0110',
    title: 'Chair visitors',
    quantity: 12.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 89.0,
    total_rate: 89.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1068.0,
    base_total: 1068.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 390,
    id: 390,
    code: '03.04.01.01.01.0120',
    title: 'Chair with writing tablet',
    quantity: 30.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 189.0,
    total_rate: 189.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 5670.0,
    base_total: 5670.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 391,
    id: 391,
    code: '03.04.01.01.01.0130',
    title: 'Cupboard built in secure N10/113',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 129.0,
    total_rate: 129.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 129.0,
    base_total: 129.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 392,
    id: 392,
    code: '03.04.01.01.01.0140',
    title: 'Cupboard kitchen, wall , floor and drawers, to suit layout N11/310',
    quantity: 3.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 129.0,
    total_rate: 129.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 387.0,
    base_total: 387.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 393,
    id: 393,
    code: '03.04.01.01.01.0150',
    title: 'Desk',
    quantity: 3.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 310.0,
    total_rate: 310.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 930.0,
    base_total: 930.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 394,
    id: 394,
    code: '03.04.01.01.01.0160',
    title: 'Desk pedestal side of desk',
    quantity: 9.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 135.0,
    total_rate: 135.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1215.0,
    base_total: 1215.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 395,
    id: 395,
    code: '03.04.01.01.01.0170',
    title: 'Display Cabinet Patient Produced Goods, Lockable N10',
    quantity: 3.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 120.0,
    total_rate: 120.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 360.0,
    base_total: 360.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 396,
    id: 396,
    code: '03.04.01.01.01.0180',
    title: 'Drinks vending machine hot and cold beverages and chilled water as specified, NBS N10',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 1470.0,
    total_rate: 1470.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1470.0,
    base_total: 1470.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 397,
    id: 397,
    code: '03.04.01.01.01.0190',
    title: 'Electronic searching equipment (motion detector)',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 67.0,
    total_rate: 67.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 67.0,
    base_total: 67.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 398,
    id: 398,
    code: '03.04.01.01.01.0200',
    title: 'Examination couch including side rail',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 479.0,
    total_rate: 479.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 479.0,
    base_total: 479.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 399,
    id: 399,
    code: '03.04.01.01.01.0210',
    title: 'Work bench',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 55.0,
    total_rate: 55.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 55.0,
    base_total: 55.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 400,
    id: 400,
    code: '03.04.01.01.01.0220',
    title: 'Locker full height 1 door as specified, NBS N13',
    quantity: 10.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 107.0,
    total_rate: 107.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1070.0,
    base_total: 1070.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 401,
    id: 401,
    code: '03.04.01.01.01.0230',
    title: 'Locker half height 2 door as specified, NBS N13',
    quantity: 15.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 107.0,
    total_rate: 107.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1605.0,
    base_total: 1605.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 402,
    id: 402,
    code: '03.04.01.01.01.0240',
    title: 'Mini Lockers N10/224 40 No',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 45.0,
    total_rate: 45.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 45.0,
    base_total: 45.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 403,
    id: 403,
    code: '03.04.01.01.01.0250',
    title: 'Office furniture (open/modular) to include desk, side pedestal, stackable desk screen',
    quantity: 12.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 232.0,
    total_rate: 232.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 2784.0,
    base_total: 2784.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 404,
    id: 404,
    code: '03.04.01.01.01.0260',
    title: 'Racking as specified, NBS N10',
    quantity: 4.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 89.0,
    total_rate: 89.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 356.0,
    base_total: 356.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 405,
    id: 405,
    code: '03.04.01.01.01.0270',
    title: 'Racking for 12 PPE kit bags N10',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 89.0,
    total_rate: 89.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 89.0,
    base_total: 89.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 406,
    id: 406,
    code: '03.04.01.01.01.0280',
    title: 'Racking for 20 anti stab vests N10',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 89.0,
    total_rate: 89.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 89.0,
    base_total: 89.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 407,
    id: 407,
    code: '03.04.01.01.01.0290',
    title: 'Racking for 20 boxed helmets and boots N10',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 89.0,
    total_rate: 89.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 89.0,
    base_total: 89.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 408,
    id: 408,
    code: '03.04.01.01.01.0300',
    title: 'Racking for 40 surplus overalls N10',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 89.0,
    total_rate: 89.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 89.0,
    base_total: 89.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 409,
    id: 409,
    code: '03.04.01.01.01.0310',
    title: 'Racking for 40 towels N10',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 89.0,
    total_rate: 89.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 89.0,
    base_total: 89.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 410,
    id: 410,
    code: '03.04.01.01.01.0320',
    title: 'Racking for 70 kit bags to suit layout of room N10',
    quantity: 18.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 89.0,
    total_rate: 89.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1602.0,
    base_total: 1602.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 411,
    id: 411,
    code: '03.04.01.01.01.0330',
    title: 'Racking for drying boots N10',
    quantity: 2.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 89.0,
    total_rate: 89.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 178.0,
    base_total: 178.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 412,
    id: 412,
    code: '03.04.01.01.01.0340',
    title: 'Racking for drying helmets N10',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 89.0,
    total_rate: 89.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 89.0,
    base_total: 89.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 413,
    id: 413,
    code: '03.04.01.01.01.0350',
    title: 'Racking for storage of shield operational bags N10',
    quantity: 2.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 89.0,
    total_rate: 89.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 178.0,
    base_total: 178.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 414,
    id: 414,
    code: '03.04.01.01.01.0360',
    title: 'Racking to store decontamination equipment N10',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 89.0,
    total_rate: 89.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 89.0,
    base_total: 89.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 415,
    id: 415,
    code: '03.04.01.01.01.0370',
    title: 'Racking to store paper suitsx40 N10',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 89.0,
    total_rate: 89.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 89.0,
    base_total: 89.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 416,
    id: 416,
    code: '03.04.01.01.01.0380',
    title: 'Safe large 2000x1000x1000',
    quantity: 3.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.02.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 450.0,
    total_rate: 450.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1350.0,
    base_total: 1350.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 417,
    id: 417,
    code: '03.04.01.01.01.0390',
    title: 'Safe medium 790x640x660',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.02.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 310.0,
    total_rate: 310.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 310.0,
    base_total: 310.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 418,
    id: 418,
    code: '03.04.01.01.01.0400',
    title: 'Safe small 630x540x540',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.02.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 159.0,
    total_rate: 159.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 159.0,
    base_total: 159.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 419,
    id: 419,
    code: '03.04.01.01.01.0410',
    title: 'Security Portal N10/360',
    quantity: 3.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 350.0,
    total_rate: 350.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1050.0,
    base_total: 1050.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 420,
    id: 420,
    code: '03.04.01.01.01.0420',
    title: 'Shelves 2 over desk, as specified, NBS N10',
    quantity: 13.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 289.0,
    total_rate: 289.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 3757.0,
    base_total: 3757.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 421,
    id: 421,
    code: '03.04.01.01.01.0430',
    title: 'Shelves as specified, NBS N10',
    quantity: 8.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 289.0,
    total_rate: 289.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 2312.0,
    base_total: 2312.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 422,
    id: 422,
    code: '03.04.01.01.01.0440',
    title: 'Staff Locker, 213 No (Various Size) N10',
    quantity: 213.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 107.0,
    total_rate: 107.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 22791.0,
    base_total: 22791.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 423,
    id: 423,
    code: '03.04.01.01.01.0450',
    title: 'Storage lockable for MoE equipment N10/227',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 230.0,
    total_rate: 230.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 230.0,
    base_total: 230.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 424,
    id: 424,
    code: '03.04.01.01.01.0460',
    title: 'Table Coffee, Staff/Visitors',
    quantity: 13.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 90.0,
    total_rate: 90.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1170.0,
    base_total: 1170.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 425,
    id: 425,
    code: '03.04.01.01.01.0470',
    title: 'Table dining, staff, 800x800',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 77.0,
    total_rate: 77.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 77.0,
    base_total: 77.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 426,
    id: 426,
    code: '03.04.01.01.01.0480',
    title: 'Table meeting, 1600x800',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 89.0,
    total_rate: 89.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 89.0,
    base_total: 89.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 427,
    id: 427,
    code: '03.04.01.01.01.0490',
    title: 'Table meeting, 2000x1000',
    quantity: 6.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 125.0,
    total_rate: 125.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 750.0,
    base_total: 750.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 428,
    id: 428,
    code: '03.04.01.01.01.0500',
    title: 'Table search, 1800x800',
    quantity: 5.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 115.0,
    total_rate: 115.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 575.0,
    base_total: 575.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 429,
    id: 429,
    code: '03.04.01.01.01.0510',
    title: 'Tambour unit with hanging rails',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 887.0,
    total_rate: 887.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 887.0,
    base_total: 887.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 430,
    id: 430,
    code: '03.04.01.01.01.0520',
    title: 'Tray roller N10',
    quantity: 2.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 269.0,
    total_rate: 269.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 538.0,
    base_total: 538.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 431,
    id: 431,
    code: '03.04.01.01.01.0530',
    title: 'Turnstile L20/700',
    quantity: 4.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 1200.0,
    total_rate: 1200.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 4800.0,
    base_total: 4800.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 432,
    id: 432,
    code: '03.04.01.01.01.0540',
    title: 'Visitor Lockers, 80 No, Visitor Size N10/220',
    quantity: 80.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 120.0,
    total_rate: 120.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 9600.0,
    base_total: 9600.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 433,
    id: 433,
    code: '03.04.01.01.01.0550',
    title: 'Water cooler staff/visitor, plumbed N12/466',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.02.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 60.0,
    total_rate: 60.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 60.0,
    base_total: 60.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 434,
    id: 434,
    code: '03.04.01.01.01.0560',
    title: 'Worktop as specified, NBS N10',
    quantity: 9.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.01.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 240.0,
    total_rate: 240.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 2160.0,
    base_total: 2160.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 435,
    id: 435,
    code: '03.04.01.01.01.0570',
    title: 'Xray search machine N10/362',
    quantity: 4.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.02.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 5999.0,
    total_rate: 5999.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 23996.0,
    base_total: 23996.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 436,
    id: 436,
    code: '03.04.01.01.01.0580',
    title: 'Xray "on / off" rollers machine N10',
    quantity: 8.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.04.01.01.01',
    nrm1_code: '01.04.01.01',
    nrm2_code: '32.02.01',
    cesmm4_code: '-',
    wp_code: 'FFE',
    activity_code: 'Furniture and equiplent',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 999.0,
    total_rate: 999.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 7992.0,
    base_total: 7992.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 441,
    id: 441,
    code: '03.05.01.01.01.0010',
    title: 'Sink cleaners as specified, NBS N13',
    quantity: 6.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.05.01.01.01',
    nrm1_code: '01.05.01.01',
    nrm2_code: '32.02.01',
    cesmm4_code: '-',
    wp_code: 'Sanitary',
    activity_code: 'Sanitary',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 89.0,
    total_rate: 89.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 534.0,
    base_total: 534.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 442,
    id: 442,
    code: '03.05.01.01.01.0020',
    title: 'WHB clinical as specified, NBS N13',
    quantity: 18.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.05.01.01.01',
    nrm1_code: '01.05.01.01',
    nrm2_code: '32.02.01',
    cesmm4_code: '-',
    wp_code: 'Sanitary',
    activity_code: 'Sanitary',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 189.0,
    total_rate: 189.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 3402.0,
    base_total: 3402.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 443,
    id: 443,
    code: '03.05.01.01.01.0030',
    title: 'WC staff/visitor as specified, NBS N13',
    quantity: 13.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.05.01.01.01',
    nrm1_code: '01.05.01.01',
    nrm2_code: '32.02.01',
    cesmm4_code: '-',
    wp_code: 'Sanitary',
    activity_code: 'Sanitary',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 145.0,
    total_rate: 145.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1885.0,
    base_total: 1885.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 444,
    id: 444,
    code: '03.05.01.01.01.0040',
    title: 'Urinal as specified, NBS N13',
    quantity: 4.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.05.01.01.01',
    nrm1_code: '01.05.01.01',
    nrm2_code: '32.02.01',
    cesmm4_code: '-',
    wp_code: 'Sanitary',
    activity_code: 'Sanitary',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 121.0,
    total_rate: 121.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 484.0,
    base_total: 484.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 445,
    id: 445,
    code: '03.05.01.01.01.0050',
    title: 'Shower Non patient with tray as specified, NBS N13',
    quantity: 5.0,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.05.01.01.01',
    nrm1_code: '01.05.01.01',
    nrm2_code: '32.02.01',
    cesmm4_code: '-',
    wp_code: 'Sanitary',
    activity_code: 'Sanitary',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 350.0,
    total_rate: 350.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 1750.0,
    base_total: 1750.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 449,
    id: 449,
    code: '03.05.02.01.01.0010',
    title: 'Installation of Passenger Lift',
    quantity: 279.62,
    unit: 'NR',
    currency: 'GBP',
    wbs_code: '03.05.02.01.01',
    nrm1_code: '01.05.10.01',
    nrm2_code: '32.02.01',
    cesmm4_code: '-',
    wp_code: 'Lift',
    activity_code: 'Lift',
    total_manhours: 0.0,
    total_plant_hours: 0.0,
    labour_rate: 0.0,
    material_rate: 0.0,
    plant_rate: 0.0,
    subcontractor_rate: 45000.0,
    total_rate: 45000.0,
    total_labour_cost: 0.0,
    total_material_cost: 0.0,
    total_plant_cost: 0.0,
    total_subcontractor_cost: 45000.0,
    base_total: 45000.0,
    labour_co2_rate: 0.0,
    material_co2_rate: 0.0,
    plant_co2_rate: 0.0,
    subcontract_co2_rate: 0.0,
    total_co2_rate: 0.0,
    total_labour_co2: 0.0,
    total_material_co2: 0.0,
    total_plant_co2: 0.0,
    total_subcontract_co2: 0.0,
    total_co2: 0.0,
  },
]

export interface BoQResourcesEntry extends Entry {
  key: number
  id: number
  boqid: number
  cesmm4_code: string
  title: string
  type: string
  product_code: string
  title__1?: string
  boq_qty?: number
  boq_unit?: string
  productivity?: number
  quantity_unit?: number
  resource_qty_1?: number
  unit_1?: string
  resource_qty_2?: number
  unit_2?: string
  total_manhours?: number
  total_plant_hours?: number
  resource_rate?: number
  gang_size?: number
  waste_factor?: number
  location_factor?: number
  note?: string
  factor_1?: number
  factor_2?: number
  build_up_rate?: number
  total_build_up_cost?: number
  manufacture?: string
  company?: string
  currency?: string
  quantity_unit_formula?: string
  exchange_rate?: number
  co2_rate?: number

  co2_build_up_rate?: number
  boq_rate?: number
  co2_boq_rate?: number
  total_co2_build_up_cost?: number
  inspection_allowance?: number
  replacement_allowance?: number
  servicing_allowance?: number
  creator?: string
  last_editor?: string
  creation_date?: string
  last_edited?: string
}

export const BoQResourcesData: BoQResourcesEntry[] = [
  {
    parent: undefined,
    children: undefined,
    key: 1,
    id: 1,
    boqid: 7,
    cesmm4_code: 'E.4.2.3',
    title: 'General Excavation; Material other than topsoil, rock or artificial hard material; Maximum depth 0.5-1m',
    type: 'L',
    product_code: 'LSL0220',
    title__1: 'General Operative (Day Shift)',
    boq_qty: 279.62,
    boq_unit: 'M3',
    productivity: 17.5,
    quantity_unit: 0.0571,
    resource_qty_1: 15.98,
    unit_1: 'Hr',
    resource_qty_2: 2.0,
    unit_2: 'Day',
    total_manhours: 15.98,
    total_plant_hours: 0,
    resource_rate: 16.67,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 15.0,
    total_build_up_cost: 239.68,
    currency: 'GBP',
    co2_build_up_rate: 3.58,
    total_co2_build_up_cost: 57.27,
  },
  {
    parent: undefined,
    children: undefined,
    key: 2,
    id: 2,
    boqid: 7,
    cesmm4_code: 'E.4.2.3',
    title: 'General Excavation; Material other than topsoil, rock or artificial hard material; Maximum depth 0.5-1m',
    type: 'P',
    product_code: 'EDZ0010',
    title__1: 'Cat D4 Dozer OPT',
    boq_qty: 279.62,
    boq_unit: 'M3',
    productivity: 35.0,
    quantity_unit: 0.0286,
    resource_qty_1: 7.99,
    unit_1: 'Hr',
    resource_qty_2: 1.0,
    unit_2: 'Day',
    total_manhours: 0,
    total_plant_hours: 7.99,
    resource_rate: 43.58,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 39.22,
    total_build_up_cost: 313.34,
    currency: 'GBP',
    co2_build_up_rate: 0,
    total_co2_build_up_cost: 0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 3,
    id: 3,
    boqid: 7,
    cesmm4_code: 'E.4.2.3',
    title: 'General Excavation; Material other than topsoil, rock or artificial hard material; Maximum depth 0.5-1m',
    type: 'P',
    product_code: 'EEX0060',
    title__1: '14T Wheeled Excavator (OPT)',
    boq_qty: 279.62,
    boq_unit: 'M3',
    productivity: 48.0,
    quantity_unit: 0.0208,
    resource_qty_1: 5.83,
    unit_1: 'Hr',
    resource_qty_2: 1.0,
    unit_2: 'Day',
    total_manhours: 0,
    total_plant_hours: 5.83,
    resource_rate: 37.47,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 33.72,
    total_build_up_cost: 196.43,
    currency: 'GBP',
    co2_build_up_rate: 0,
    total_co2_build_up_cost: 0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 4,
    id: 4,
    boqid: 7,
    cesmm4_code: 'E.4.2.3',
    title: 'General Excavation; Material other than topsoil, rock or artificial hard material; Maximum depth 0.5-1m',
    type: 'S',
    product_code: 'SSC0330V',
    title__1: 'Disposal (Road)',
    boq_qty: 279.62,
    boq_unit: 'M3',
    productivity: 1.0,
    quantity_unit: 1.0,
    resource_qty_1: 279.62,
    unit_1: '£',
    resource_qty_2: 279.62,
    unit_2: '£',
    total_manhours: 0,
    total_plant_hours: 0,
    resource_rate: 31.82,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 1.1,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 35.0,
    total_build_up_cost: 9786.73,
    currency: 'GBP',
    co2_build_up_rate: 0,
    total_co2_build_up_cost: 0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 5,
    id: 5,
    boqid: 7,
    cesmm4_code: 'E.4.2.3',
    title: 'General Excavation; Material other than topsoil, rock or artificial hard material; Maximum depth 0.5-1m',
    type: 'M',
    product_code: 'MFU0010',
    title__1: 'Fuel-Diesel -Off road',
    boq_qty: 279.62,
    boq_unit: 'M3',
    productivity: 1.307,
    quantity_unit: 0.7651,
    resource_qty_1: 213.94,
    unit_1: 'LIT',
    resource_qty_2: 213.94,
    unit_2: 'LIT',
    total_manhours: 0,
    total_plant_hours: 0,
    resource_rate: 0.67,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 0.6,
    total_build_up_cost: 128.36,
    currency: 'GBP',
    co2_build_up_rate: 0,
    total_co2_build_up_cost: 0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 6,
    id: 6,
    boqid: 34,
    cesmm4_code: 'E.5.3.2',
    title: 'General Excavation; Disposal of excavated material (Inert Assumed 75%)',
    type: 'L',
    product_code: 'LSL0010',
    title__1: 'Banksman (Day Shift)',
    boq_qty: 1225.15,
    boq_unit: 'M3',
    productivity: 40.0,
    quantity_unit: 0.025,
    resource_qty_1: 30.63,
    unit_1: 'Hr',
    resource_qty_2: 4.0,
    unit_2: 'Day',
    total_manhours: 30.63,
    total_plant_hours: 0,
    resource_rate: 30.27,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 27.24,
    total_build_up_cost: 834.32,
    currency: 'GBP',
    co2_build_up_rate: 3.58,
    total_co2_build_up_cost: 109.77,
  },
  {
    parent: undefined,
    children: undefined,
    key: 7,
    id: 7,
    boqid: 34,
    cesmm4_code: 'E.5.3.2',
    title: 'General Excavation; Disposal of excavated material (Inert Assumed 75%)',
    type: 'P',
    product_code: 'EWL0020',
    title__1: 'CAT 966 Wheel Loader 3.2m3/175kw OPT',
    boq_qty: 1225.15,
    boq_unit: 'M3',
    productivity: 40.0,
    quantity_unit: 0.025,
    resource_qty_1: 30.63,
    unit_1: 'Hr',
    resource_qty_2: 4.0,
    unit_2: 'Day',
    total_manhours: 0,
    total_plant_hours: 30.63,
    resource_rate: 64.67,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 58.2,
    total_build_up_cost: 1782.59,
    currency: 'GBP',
    co2_build_up_rate: 0,
    total_co2_build_up_cost: 0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 8,
    id: 8,
    boqid: 34,
    cesmm4_code: 'E.5.3.2',
    title: 'General Excavation; Disposal of excavated material (Inert Assumed 75%)',
    type: 'S',
    product_code: 'SSC0330V',
    title__1: 'Disposal (Road)',
    boq_qty: 1225.15,
    boq_unit: 'M3',
    productivity: 1.0,
    quantity_unit: 1.0,
    resource_qty_1: 1225.15,
    unit_1: '£',
    resource_qty_2: 1225.15,
    unit_2: '£',
    total_manhours: 0,
    total_plant_hours: 0,
    resource_rate: 31.82,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 1.1,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 35.0,
    total_build_up_cost: 42880.1,
    currency: 'GBP',
    co2_build_up_rate: 26.33,
    total_co2_build_up_cost: 32251.96,
  },
  {
    parent: undefined,
    children: undefined,
    key: 9,
    id: 9,
    boqid: 34,
    cesmm4_code: 'E.5.3.2',
    title: 'General Excavation; Disposal of excavated material (Inert Assumed 75%)',
    type: 'M',
    product_code: 'MFU0010',
    title__1: 'Fuel-Diesel -Off road',
    boq_qty: 1225.15,
    boq_unit: 'M3',
    productivity: 1.4,
    quantity_unit: 0.7143,
    resource_qty_1: 875.1,
    unit_1: 'LIT',
    resource_qty_2: 875.1,
    unit_2: 'LIT',
    total_manhours: 0,
    total_plant_hours: 0,
    resource_rate: 0.67,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 0.6,
    total_build_up_cost: 525.06,
    currency: 'GBP',
    co2_build_up_rate: 0,
    total_co2_build_up_cost: 0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 10,
    id: 10,
    boqid: 37,
    cesmm4_code: 'Q.1.6.4',
    title: 'Continuous Piles; Cutting off surplus lengths; 750 mm diameter',
    type: 'L',
    product_code: 'LSL0010',
    title__1: 'Banksman (Day Shift)',
    boq_qty: 153.0,
    boq_unit: 'M',
    productivity: 2.0,
    quantity_unit: 0.5,
    resource_qty_1: 76.5,
    unit_1: 'Hr',
    resource_qty_2: 8.0,
    unit_2: 'Day',
    total_manhours: 76.5,
    total_plant_hours: 0,
    resource_rate: 30.27,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 27.24,
    total_build_up_cost: 2083.86,
    currency: 'GBP',
    co2_build_up_rate: 3.58,
    total_co2_build_up_cost: 274.18,
  },
  {
    parent: undefined,
    children: undefined,
    key: 11,
    id: 11,
    boqid: 37,
    cesmm4_code: 'Q.1.6.4',
    title: 'Continuous Piles; Cutting off surplus lengths; 750 mm diameter',
    type: 'L',
    product_code: 'LSL0220',
    title__1: 'General Operative (Day Shift)',
    boq_qty: 153.0,
    boq_unit: 'M',
    productivity: 2.0,
    quantity_unit: 0.5,
    resource_qty_1: 76.5,
    unit_1: 'Hr',
    resource_qty_2: 8.0,
    unit_2: 'Day',
    total_manhours: 76.5,
    total_plant_hours: 0,
    resource_rate: 16.67,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 15.0,
    total_build_up_cost: 1147.5,
    currency: 'GBP',
    co2_build_up_rate: 3.58,
    total_co2_build_up_cost: 274.18,
  },
  {
    parent: undefined,
    children: undefined,
    key: 12,
    id: 12,
    boqid: 37,
    cesmm4_code: 'Q.1.6.4',
    title: 'Continuous Piles; Cutting off surplus lengths; 750 mm diameter',
    type: 'L',
    product_code: 'LSL0310',
    title__1: 'Plant Operator- Excavator <20t (Day Shi',
    boq_qty: 153.0,
    boq_unit: 'M',
    productivity: 2.0,
    quantity_unit: 0.5,
    resource_qty_1: 76.5,
    unit_1: 'Hr',
    resource_qty_2: 8.0,
    unit_2: 'Day',
    total_manhours: 76.5,
    total_plant_hours: 0,
    resource_rate: 30.1,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 27.09,
    total_build_up_cost: 2072.39,
    currency: 'GBP',
    co2_build_up_rate: 3.58,
    total_co2_build_up_cost: 274.18,
  },
  {
    parent: undefined,
    children: undefined,
    key: 13,
    id: 13,
    boqid: 37,
    cesmm4_code: 'Q.1.6.4',
    title: 'Continuous Piles; Cutting off surplus lengths; 750 mm diameter',
    type: 'P',
    product_code: 'EBK0070',
    title__1: 'Hydraulic Breaker up to 3t (OPT)',
    boq_qty: 153.0,
    boq_unit: 'M',
    productivity: 2.0,
    quantity_unit: 0.5,
    resource_qty_1: 76.5,
    unit_1: 'Hr',
    resource_qty_2: 8.0,
    unit_2: 'Day',
    total_manhours: 0,
    total_plant_hours: 76.5,
    resource_rate: 25.92,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 23.33,
    total_build_up_cost: 1784.75,
    currency: 'GBP',
    co2_build_up_rate: 0,
    total_co2_build_up_cost: 0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 14,
    id: 14,
    boqid: 37,
    cesmm4_code: 'Q.1.6.4',
    title: 'Continuous Piles; Cutting off surplus lengths; 750 mm diameter',
    type: 'P',
    product_code: 'EEX0210',
    title__1: '1.5T Mini excavator  (  no operator )',
    boq_qty: 153.0,
    boq_unit: 'M',
    productivity: 2.0,
    quantity_unit: 0.5,
    resource_qty_1: 76.5,
    unit_1: 'Hr',
    resource_qty_2: 8.0,
    unit_2: 'Day',
    total_manhours: 0,
    total_plant_hours: 76.5,
    resource_rate: 4.7,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 4.23,
    total_build_up_cost: 323.6,
    currency: 'GBP',
    co2_build_up_rate: 0,
    total_co2_build_up_cost: 0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 15,
    id: 15,
    boqid: 37,
    cesmm4_code: 'Q.1.6.4',
    title: 'Continuous Piles; Cutting off surplus lengths; 750 mm diameter',
    type: 'S',
    product_code: 'SSC0330V',
    title__1: 'Disposal (Road)',
    boq_qty: 153.0,
    boq_unit: 'M',
    productivity: 1.131,
    quantity_unit: 0.8842,
    resource_qty_1: 135.28,
    unit_1: '£',
    resource_qty_2: 135.28,
    unit_2: '£',
    total_manhours: 0,
    total_plant_hours: 0,
    resource_rate: 31.82,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 1.1,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 35.0,
    total_build_up_cost: 4734.75,
    currency: 'GBP',
    co2_build_up_rate: 36.86,
    total_co2_build_up_cost: 4986.73,
  },
  {
    parent: undefined,
    children: undefined,
    key: 16,
    id: 16,
    boqid: 37,
    cesmm4_code: 'Q.1.6.4',
    title: 'Continuous Piles; Cutting off surplus lengths; 750 mm diameter',
    type: 'M',
    product_code: 'MFU0010',
    title__1: 'Fuel-Diesel -Off road',
    boq_qty: 153.0,
    boq_unit: 'M',
    productivity: 0.274,
    quantity_unit: 3.6496,
    resource_qty_1: 558.39,
    unit_1: 'LIT',
    resource_qty_2: 558.39,
    unit_2: 'LIT',
    total_manhours: 0,
    total_plant_hours: 0,
    resource_rate: 0.67,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 0.6,
    total_build_up_cost: 335.04,
    currency: 'GBP',
    co2_build_up_rate: 4.74,
    total_co2_build_up_cost: 2648.47,
  },
  {
    parent: undefined,
    children: undefined,
    key: 17,
    id: 17,
    boqid: 35,
    cesmm4_code: 'Q.1.7.4',
    title: 'Continuous Piles; Preparing heads; 750 mm diameter',
    type: 'L',
    product_code: 'LSL0010',
    title__1: 'Banksman (Day Shift)',
    boq_qty: 1.0,
    boq_unit: 'EACH',
    productivity: 3.0,
    quantity_unit: 0.3333,
    resource_qty_1: 0.33,
    unit_1: 'Hr',
    resource_qty_2: 1.0,
    unit_2: 'Day',
    total_manhours: 0.33,
    total_plant_hours: 0,
    resource_rate: 30.27,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 27.24,
    total_build_up_cost: 9.08,
    currency: 'GBP',
    co2_build_up_rate: 3.58,
    total_co2_build_up_cost: 1.19,
  },
  {
    parent: undefined,
    children: undefined,
    key: 18,
    id: 18,
    boqid: 35,
    cesmm4_code: 'Q.1.7.4',
    title: 'Continuous Piles; Preparing heads; 750 mm diameter',
    type: 'L',
    product_code: 'LSL0220',
    title__1: 'General Operative (Day Shift)',
    boq_qty: 1.0,
    boq_unit: 'EACH',
    productivity: 3.0,
    quantity_unit: 0.3333,
    resource_qty_1: 0.33,
    unit_1: 'Hr',
    resource_qty_2: 1.0,
    unit_2: 'Day',
    total_manhours: 0.33,
    total_plant_hours: 0,
    resource_rate: 16.67,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 15.0,
    total_build_up_cost: 5.0,
    currency: 'GBP',
    co2_build_up_rate: 3.58,
    total_co2_build_up_cost: 1.19,
  },
  {
    parent: undefined,
    children: undefined,
    key: 19,
    id: 19,
    boqid: 35,
    cesmm4_code: 'Q.1.7.4',
    title: 'Continuous Piles; Preparing heads; 750 mm diameter',
    type: 'L',
    product_code: 'LSL0310',
    title__1: 'Plant Operator- Excavator <20t (Day Shi',
    boq_qty: 1.0,
    boq_unit: 'EACH',
    productivity: 3.0,
    quantity_unit: 0.3333,
    resource_qty_1: 0.33,
    unit_1: 'Hr',
    resource_qty_2: 1.0,
    unit_2: 'Day',
    total_manhours: 0.33,
    total_plant_hours: 0,
    resource_rate: 30.1,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 27.09,
    total_build_up_cost: 9.03,
    currency: 'GBP',
    co2_build_up_rate: 3.58,
    total_co2_build_up_cost: 1.19,
  },
  {
    parent: undefined,
    children: undefined,
    key: 20,
    id: 20,
    boqid: 35,
    cesmm4_code: 'Q.1.7.4',
    title: 'Continuous Piles; Preparing heads; 750 mm diameter',
    type: 'P',
    product_code: 'EBK0070',
    title__1: 'Hydraulic Breaker up to 3t (OPT)',
    boq_qty: 1.0,
    boq_unit: 'EACH',
    productivity: 3.0,
    quantity_unit: 0.3333,
    resource_qty_1: 0.33,
    unit_1: 'Hr',
    resource_qty_2: 1.0,
    unit_2: 'Day',
    total_manhours: 0,
    total_plant_hours: 0.33,
    resource_rate: 25.92,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 23.33,
    total_build_up_cost: 7.78,
    currency: 'GBP',
    co2_build_up_rate: 0,
    total_co2_build_up_cost: 0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 21,
    id: 21,
    boqid: 35,
    cesmm4_code: 'Q.1.7.4',
    title: 'Continuous Piles; Preparing heads; 750 mm diameter',
    type: 'P',
    product_code: 'EEX0210',
    title__1: '1.5T Mini excavator  (  no operator )',
    boq_qty: 1.0,
    boq_unit: 'EACH',
    productivity: 3.0,
    quantity_unit: 0.3333,
    resource_qty_1: 0.33,
    unit_1: 'Hr',
    resource_qty_2: 1.0,
    unit_2: 'Day',
    total_manhours: 0,
    total_plant_hours: 0.33,
    resource_rate: 4.7,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 4.23,
    total_build_up_cost: 1.41,
    currency: 'GBP',
    co2_build_up_rate: 0,
    total_co2_build_up_cost: 0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 22,
    id: 22,
    boqid: 35,
    cesmm4_code: 'Q.1.7.4',
    title: 'Continuous Piles; Preparing heads; 750 mm diameter',
    type: 'M',
    product_code: 'MFU0010',
    title__1: 'Fuel-Diesel -Off road',
    boq_qty: 1.0,
    boq_unit: 'EACH',
    productivity: 0.411,
    quantity_unit: 2.4331,
    resource_qty_1: 2.43,
    unit_1: 'LIT',
    resource_qty_2: 2.43,
    unit_2: 'LIT',
    total_manhours: 0,
    total_plant_hours: 0,
    resource_rate: 0.67,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 0.6,
    total_build_up_cost: 1.46,
    currency: 'GBP',
    co2_build_up_rate: 0,
    total_co2_build_up_cost: 0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 23,
    id: 23,
    boqid: 44,
    cesmm4_code: 'F.1.4.2',
    title: 'In Situ Concrete; Prov and placing of concrete',
    type: 'M',
    product_code: 'MCO0140',
    title__1: 'Concrete C20/25   (BS/EN 206-1) - All ex',
    boq_qty: 110.57,
    boq_unit: 'M3',
    productivity: 0.971,
    quantity_unit: 1.0299,
    resource_qty_1: 113.87,
    unit_1: 'M3',
    resource_qty_2: 113.87,
    unit_2: 'M3',
    total_manhours: 0,
    total_plant_hours: 0,
    resource_rate: 96.58,
    gang_size: 1.0,
    waste_factor: 1.05,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 91.27,
    total_build_up_cost: 10393.18,
    currency: 'GBP',
    co2_build_up_rate: 201.0,
    total_co2_build_up_cost: 22888.45,
  },
  {
    parent: undefined,
    children: undefined,
    key: 24,
    id: 24,
    boqid: 44,
    cesmm4_code: 'F.1.4.2',
    title: 'In Situ Concrete; Prov and placing of concrete',
    type: 'L',
    product_code: 'LSL0640',
    title__1: 'Skilled Operative - Concreter Ganger (Da',
    boq_qty: 110.57,
    boq_unit: 'M3',
    productivity: 5.0,
    quantity_unit: 0.2,
    resource_qty_1: 22.11,
    unit_1: 'Hr',
    resource_qty_2: 3.0,
    unit_2: 'Day',
    total_manhours: 22.11,
    total_plant_hours: 0,
    resource_rate: 23.2,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 20.88,
    total_build_up_cost: 461.74,
    currency: 'GBP',
    co2_build_up_rate: 3.58,
    total_co2_build_up_cost: 79.26,
  },
  {
    parent: undefined,
    children: undefined,
    key: 25,
    id: 25,
    boqid: 44,
    cesmm4_code: 'F.1.4.2',
    title: 'In Situ Concrete; Prov and placing of concrete',
    type: 'L',
    product_code: 'LSL0670',
    title__1: 'Skilled Operative - Concreter Operative',
    boq_qty: 110.57,
    boq_unit: 'M3',
    productivity: 1.0,
    quantity_unit: 1.0,
    resource_qty_1: 110.57,
    unit_1: 'Hr',
    resource_qty_2: 12.0,
    unit_2: 'Day',
    total_manhours: 110.57,
    total_plant_hours: 0,
    resource_rate: 19.0,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 17.1,
    total_build_up_cost: 1890.76,
    currency: 'GBP',
    co2_build_up_rate: 3.58,
    total_co2_build_up_cost: 396.28,
  },
  {
    parent: undefined,
    children: undefined,
    key: 26,
    id: 26,
    boqid: 44,
    cesmm4_code: 'F.1.4.2',
    title: 'In Situ Concrete; Prov and placing of concrete',
    type: 'P',
    product_code: 'EAT0020',
    title__1: 'Poker Vibrator (Air)',
    boq_qty: 110.57,
    boq_unit: 'M3',
    productivity: 0.833,
    quantity_unit: 1.2005,
    resource_qty_1: 132.74,
    unit_1: 'Hr',
    resource_qty_2: 14.0,
    unit_2: 'Day',
    total_manhours: 0,
    total_plant_hours: 132.74,
    resource_rate: 0.52,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 0.47,
    total_build_up_cost: 62.39,
    currency: 'GBP',
    co2_build_up_rate: 0,
    total_co2_build_up_cost: 0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 27,
    id: 27,
    boqid: 44,
    cesmm4_code: 'F.1.4.2',
    title: 'In Situ Concrete; Prov and placing of concrete',
    type: 'P',
    product_code: 'ECP0010',
    title__1: 'Compressor hoses/tools',
    boq_qty: 110.57,
    boq_unit: 'M3',
    productivity: 0.417,
    quantity_unit: 2.3981,
    resource_qty_1: 265.16,
    unit_1: 'Hr',
    resource_qty_2: 27.0,
    unit_2: 'Day',
    total_manhours: 0,
    total_plant_hours: 265.16,
    resource_rate: 0.28,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 0.25,
    total_build_up_cost: 66.29,
    currency: 'GBP',
    co2_build_up_rate: 0,
    total_co2_build_up_cost: 0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 28,
    id: 28,
    boqid: 44,
    cesmm4_code: 'F.1.4.2',
    title: 'In Situ Concrete; Prov and placing of concrete',
    type: 'P',
    product_code: 'ECP0030',
    title__1: 'Electric Compressor 150 cfm',
    boq_qty: 110.57,
    boq_unit: 'M3',
    productivity: 0.833,
    quantity_unit: 1.2005,
    resource_qty_1: 132.74,
    unit_1: 'Hr',
    resource_qty_2: 14.0,
    unit_2: 'Day',
    total_manhours: 0,
    total_plant_hours: 132.74,
    resource_rate: 1.22,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 1.1,
    total_build_up_cost: 146.01,
    currency: 'GBP',
    co2_build_up_rate: 39.25,
    total_co2_build_up_cost: 5209.29,
  },
  {
    parent: undefined,
    children: undefined,
    key: 29,
    id: 29,
    boqid: 44,
    cesmm4_code: 'F.1.4.2',
    title: 'In Situ Concrete; Prov and placing of concrete',
    type: 'P',
    product_code: 'EPC0060',
    title__1: 'Mobile Conc pump 47m boom 9hrs work (OPT',
    boq_qty: 110.57,
    boq_unit: 'M3',
    productivity: 25.0,
    quantity_unit: 0.04,
    resource_qty_1: 4.42,
    unit_1: 'Hr',
    resource_qty_2: 1.0,
    unit_2: 'Day',
    total_manhours: 0,
    total_plant_hours: 4.42,
    resource_rate: 121.84,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 109.66,
    total_build_up_cost: 485.01,
    currency: 'GBP',
    co2_build_up_rate: 39.25,
    total_co2_build_up_cost: 173.57,
  },
  {
    parent: undefined,
    children: undefined,
    key: 30,
    id: 30,
    boqid: 44,
    cesmm4_code: 'F.1.4.2',
    title: 'In Situ Concrete; Prov and placing of concrete',
    type: 'P',
    product_code: 'ESP0030',
    title__1: 'Concrete Vibrator         (PLANT)',
    boq_qty: 110.57,
    boq_unit: 'M3',
    productivity: 0.833,
    quantity_unit: 1.2005,
    resource_qty_1: 132.74,
    unit_1: 'Hr',
    resource_qty_2: 14.0,
    unit_2: 'Day',
    total_manhours: 0,
    total_plant_hours: 132.74,
    resource_rate: 3.9,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 3.51,
    total_build_up_cost: 465.91,
    currency: 'GBP',
    co2_build_up_rate: 0,
    total_co2_build_up_cost: 0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 31,
    id: 31,
    boqid: 43,
    cesmm4_code: 'G.5.2.5',
    title: 'In Situ Concrete; Reinforcement; HY rebar',
    type: 'L',
    product_code: 'LSL0220',
    title__1: 'General Operative (Day Shift)',
    boq_qty: 19.9,
    boq_unit: 'T',
    productivity: 0.267,
    quantity_unit: 3.7453,
    resource_qty_1: 74.54,
    unit_1: 'Hr',
    resource_qty_2: 8.0,
    unit_2: 'Day',
    total_manhours: 74.54,
    total_plant_hours: 0,
    resource_rate: 16.67,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 15.0,
    total_build_up_cost: 1118.13,
    currency: 'GBP',
    co2_build_up_rate: 3.58,
    total_co2_build_up_cost: 267.16,
  },
  {
    parent: undefined,
    children: undefined,
    key: 32,
    id: 32,
    boqid: 43,
    cesmm4_code: 'G.5.2.5',
    title: 'In Situ Concrete; Reinforcement; HY rebar',
    type: 'L',
    product_code: 'LSL0850',
    title__1: 'Skilled Operative - Steel Fixer (Day Shi',
    boq_qty: 19.9,
    boq_unit: 'T',
    productivity: 0.133,
    quantity_unit: 7.5188,
    resource_qty_1: 149.64,
    unit_1: 'Hr',
    resource_qty_2: 15.0,
    unit_2: 'Day',
    total_manhours: 149.64,
    total_plant_hours: 0,
    resource_rate: 25.87,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 23.28,
    total_build_up_cost: 3483.72,
    currency: 'GBP',
    co2_build_up_rate: 3.58,
    total_co2_build_up_cost: 536.33,
  },
  {
    parent: undefined,
    children: undefined,
    key: 33,
    id: 33,
    boqid: 43,
    cesmm4_code: 'G.5.2.5',
    title: 'In Situ Concrete; Reinforcement; HY rebar',
    type: 'L',
    product_code: 'LSL0880',
    title__1: 'Skilled Operative - Chargehand Steel Fix',
    boq_qty: 19.9,
    boq_unit: 'T',
    productivity: 0.267,
    quantity_unit: 3.7453,
    resource_qty_1: 74.54,
    unit_1: 'Hr',
    resource_qty_2: 8.0,
    unit_2: 'Day',
    total_manhours: 74.54,
    total_plant_hours: 0,
    resource_rate: 28.47,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 25.62,
    total_build_up_cost: 1909.77,
    currency: 'GBP',
    co2_build_up_rate: 3.58,
    total_co2_build_up_cost: 267.16,
  },
  {
    parent: undefined,
    children: undefined,
    key: 34,
    id: 34,
    boqid: 43,
    cesmm4_code: 'G.5.2.5',
    title: 'In Situ Concrete; Reinforcement; HY rebar',
    type: 'M',
    product_code: 'MRE0020',
    title__1: 'HY Rebar > 20mm all in',
    boq_qty: 19.9,
    boq_unit: 'T',
    productivity: 0.971,
    quantity_unit: 1.0299,
    resource_qty_1: 20.5,
    unit_1: 'T',
    resource_qty_2: 20.5,
    unit_2: 'T',
    total_manhours: 0,
    total_plant_hours: 0,
    resource_rate: 606.23,
    gang_size: 1.0,
    waste_factor: 1.01,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 551.06,
    total_build_up_cost: 11295.14,
    currency: 'GBP',
    co2_build_up_rate: 893.0,
    total_co2_build_up_cost: 18303.93,
  },
  {
    parent: undefined,
    children: undefined,
    key: 35,
    id: 35,
    boqid: 43,
    cesmm4_code: 'G.5.2.5',
    title: 'In Situ Concrete; Reinforcement; HY rebar',
    type: 'M',
    product_code: 'MRE0070',
    title__1: 'Reinforcement spacer Type DI 35 mm',
    boq_qty: 19.9,
    boq_unit: 'T',
    productivity: 0.006,
    quantity_unit: 166.6667,
    resource_qty_1: 3317.12,
    unit_1: 'Item',
    resource_qty_2: 3317.12,
    unit_2: 'Item',
    total_manhours: 0,
    total_plant_hours: 0,
    resource_rate: 0.05,
    gang_size: 1.0,
    waste_factor: 1.1,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 0.05,
    total_build_up_cost: 165.86,
    currency: 'GBP',
    co2_build_up_rate: 0,
    total_co2_build_up_cost: 0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 36,
    id: 36,
    boqid: 43,
    cesmm4_code: 'G.5.2.5',
    title: 'In Situ Concrete; Reinforcement; HY rebar',
    type: 'M',
    product_code: 'MRE0330V',
    title__1: 'Tying wire (per tonne of reinforcement)',
    boq_qty: 19.9,
    boq_unit: 'T',
    productivity: 1.0,
    quantity_unit: 1.0,
    resource_qty_1: 19.9,
    unit_1: 'T',
    resource_qty_2: 19.9,
    unit_2: 'T',
    total_manhours: 0,
    total_plant_hours: 0,
    resource_rate: 5.05,
    gang_size: 1.0,
    waste_factor: 1.1,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 5.0,
    total_build_up_cost: 99.51,
    currency: 'GBP',
    co2_build_up_rate: 58.5,
    total_co2_build_up_cost: 1164.31,
  },
  {
    parent: undefined,
    children: undefined,
    key: 37,
    id: 37,
    boqid: 42,
    cesmm4_code: 'G.2.1.4',
    title: 'In Situ Concrete; Formwork',
    type: 'L',
    product_code: 'LSL0580',
    title__1: 'Skilled Operative - Carpenter (Day Shift',
    boq_qty: 320.12,
    boq_unit: 'M2',
    productivity: 0.436,
    quantity_unit: 2.2936,
    resource_qty_1: 734.22,
    unit_1: 'Hr',
    resource_qty_2: 74.0,
    unit_2: 'Day',
    total_manhours: 734.22,
    total_plant_hours: 0,
    resource_rate: 25.87,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 23.28,
    total_build_up_cost: 17092.68,
    currency: 'GBP',
    co2_build_up_rate: 3.58,
    total_co2_build_up_cost: 2631.45,
  },
  {
    parent: undefined,
    children: undefined,
    key: 38,
    id: 38,
    boqid: 42,
    cesmm4_code: 'G.2.1.4',
    title: 'In Situ Concrete; Formwork',
    type: 'L',
    product_code: 'LSL0610',
    title__1: 'Skilled Operative - Chargehand Carpenter',
    boq_qty: 320.12,
    boq_unit: 'M2',
    productivity: 2.182,
    quantity_unit: 0.4583,
    resource_qty_1: 146.71,
    unit_1: 'Hr',
    resource_qty_2: 15.0,
    unit_2: 'Day',
    total_manhours: 146.71,
    total_plant_hours: 0,
    resource_rate: 28.47,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 25.62,
    total_build_up_cost: 3758.7,
    currency: 'GBP',
    co2_build_up_rate: 3.58,
    total_co2_build_up_cost: 525.81,
  },
  {
    parent: undefined,
    children: undefined,
    key: 39,
    id: 39,
    boqid: 42,
    cesmm4_code: 'G.2.1.4',
    title: 'In Situ Concrete; Formwork',
    type: 'M',
    product_code: 'MFW0630',
    title__1: 'Curing Agent',
    boq_qty: 320.12,
    boq_unit: 'M2',
    productivity: 0.2,
    quantity_unit: 5.0,
    resource_qty_1: 1600.6,
    unit_1: 'LIT',
    resource_qty_2: 1600.6,
    unit_2: 'LIT',
    total_manhours: 0,
    total_plant_hours: 0,
    resource_rate: 0.51,
    gang_size: 1.0,
    waste_factor: 1.2,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 0.55,
    total_build_up_cost: 880.33,
    currency: 'GBP',
    co2_build_up_rate: 0,
    total_co2_build_up_cost: 0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 40,
    id: 40,
    boqid: 42,
    cesmm4_code: 'G.2.1.4',
    title: 'In Situ Concrete; Formwork',
    type: 'M',
    product_code: 'MFW0640',
    title__1: '20mm marine ply',
    boq_qty: 320.12,
    boq_unit: 'M2',
    productivity: 4.0,
    quantity_unit: 0.25,
    resource_qty_1: 80.03,
    unit_1: 'M2',
    resource_qty_2: 80.03,
    unit_2: 'M2',
    total_manhours: 0,
    total_plant_hours: 0,
    resource_rate: 10.19,
    gang_size: 1.0,
    waste_factor: 1.2,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 11.0,
    total_build_up_cost: 880.33,
    currency: 'GBP',
    co2_build_up_rate: 0,
    total_co2_build_up_cost: 0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 41,
    id: 41,
    boqid: 42,
    cesmm4_code: 'G.2.1.4',
    title: 'In Situ Concrete; Formwork',
    type: 'M',
    product_code: 'MFW0680',
    title__1: 'Shutter Oil',
    boq_qty: 320.12,
    boq_unit: 'M2',
    productivity: 20.0,
    quantity_unit: 0.05,
    resource_qty_1: 16.01,
    unit_1: 'LIT',
    resource_qty_2: 16.01,
    unit_2: 'LIT',
    total_manhours: 0,
    total_plant_hours: 0,
    resource_rate: 1.16,
    gang_size: 1.0,
    waste_factor: 1.2,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 1.25,
    total_build_up_cost: 20.01,
    currency: 'GBP',
    co2_build_up_rate: 0,
    total_co2_build_up_cost: 0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 42,
    id: 42,
    boqid: 42,
    cesmm4_code: 'G.2.1.4',
    title: 'In Situ Concrete; Formwork',
    type: 'M',
    product_code: 'MFW0690',
    title__1: 'Nail Allowance',
    boq_qty: 320.12,
    boq_unit: 'M2',
    productivity: 1.0,
    quantity_unit: 1.0,
    resource_qty_1: 320.12,
    unit_1: 'M2',
    resource_qty_2: 320.12,
    unit_2: 'M2',
    total_manhours: 0,
    total_plant_hours: 0,
    resource_rate: 0.93,
    gang_size: 1.0,
    waste_factor: 1.2,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 1.0,
    total_build_up_cost: 320.12,
    currency: 'GBP',
    co2_build_up_rate: 0.48,
    total_co2_build_up_cost: 152.81,
  },
  {
    parent: undefined,
    children: undefined,
    key: 43,
    id: 43,
    boqid: 42,
    cesmm4_code: 'G.2.1.4',
    title: 'In Situ Concrete; Formwork',
    type: 'M',
    product_code: 'MFW0700',
    title__1: 'Propping Allowance',
    boq_qty: 320.12,
    boq_unit: 'M2',
    productivity: 1.0,
    quantity_unit: 1.0,
    resource_qty_1: 320.12,
    unit_1: 'M2',
    resource_qty_2: 320.12,
    unit_2: 'M2',
    total_manhours: 0,
    total_plant_hours: 0,
    resource_rate: 1.39,
    gang_size: 1.0,
    waste_factor: 1.2,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 1.5,
    total_build_up_cost: 480.18,
    currency: 'GBP',
    co2_build_up_rate: 4.9,
    total_co2_build_up_cost: 1568.39,
  },
  {
    parent: undefined,
    children: undefined,
    key: 44,
    id: 44,
    boqid: 42,
    cesmm4_code: 'G.2.1.4',
    title: 'In Situ Concrete; Formwork',
    type: 'M',
    product_code: 'MTI0020',
    title__1: 'Timber',
    boq_qty: 320.12,
    boq_unit: 'M2',
    productivity: 66.7,
    quantity_unit: 0.015,
    resource_qty_1: 4.8,
    unit_1: 'M3',
    resource_qty_2: 4.8,
    unit_2: 'M3',
    total_manhours: 0,
    total_plant_hours: 0,
    resource_rate: 222.22,
    gang_size: 1.0,
    waste_factor: 1.2,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 240.0,
    total_build_up_cost: 1151.86,
    currency: 'GBP',
    co2_build_up_rate: 0,
    total_co2_build_up_cost: 0,
  },
  {
    parent: undefined,
    children: undefined,
    key: 45,
    id: 45,
    boqid: 46,
    cesmm4_code: 'G.8.1.1',
    title: 'In Situ Concrete; Surface finishing',
    type: 'L',
    product_code: 'LSL0670',
    title__1: 'Skilled Operative - Concreter Operative',
    boq_qty: 320.12,
    boq_unit: 'M2',
    productivity: 5.0,
    quantity_unit: 0.2,
    resource_qty_1: 64.02,
    unit_1: 'Hr',
    resource_qty_2: 7.0,
    unit_2: 'Day',
    total_manhours: 64.02,
    total_plant_hours: 0,
    resource_rate: 19.0,
    gang_size: 1.0,
    waste_factor: 1.0,
    location_factor: 0.9,
    factor_1: 1.0,
    factor_2: 1.0,
    build_up_rate: 17.1,
    total_build_up_cost: 1094.81,
    currency: 'GBP',
    co2_build_up_rate: 3.58,
    total_co2_build_up_cost: 229.46,
  },
]

export interface BoQTakeoffEntry extends Entry {
  key: number
  id: number
  boqids: number[]
  viewerIds: number[]
  full_name: string
  code: string
  title: string
  qty_1_value: number
  qty_2_value: number
}

export const BoQTakeOffData = []
// export const BoQTakeOffData: BoQTakeoffEntry[] = [
//   {
//     key: 1,
//     boqid: 30,
//     id: 2,
//     title: 'Contiguous Pile 600 mm',
//     note: 'Note',
//     take_off_type: 'BIM',
//     source_document_name: 'Bridge model',
//     object_guid: '1Vf2bObon9yu8pfzz2dq12',
//     qty_1_type: 'Gross Volume',
//     qty_1_value: 16.96,
//     unit_1: 'M3',
//     qty_2_type: 'Side Area',
//     qty_2_value: 56.52,
//     unit_2: 'M2',
//     qty_3_type: 'Length',
//     qty_3_value: 15.0,
//     unit_3: 'M',
//     selected_qty: 'Qty 1',
//     qty_type: 'Gross Volume',
//     quantity: 16.96,
//     unit: 'M3',
//     factor_1: 1.05,
//     factor_2: 1.0,
//     factor_3: 1.0,
//     final_qty: 17.8,
//     creator: 'admin',
//     last_editor: 'scsadm',
//     creation_date: '6/11/2018',
//     last_edited: '6/11/2018',
//     children: undefined,
//     parent: undefined,
//   },
//   {
//     key: 2,
//     boqid: 30,
//     id: 3,
//     title: 'Contiguous Pile 600 mm',
//     note: 'Note',
//     take_off_type: 'BIM',
//     source_document_name: 'Bridge model',
//     object_guid: '1Vf2bObon9yu8pfzz2dq13',
//     qty_1_type: 'Gross Volume',
//     qty_1_value: 16.96,
//     unit_1: 'M3',
//     qty_2_type: 'Side Area',
//     qty_2_value: 56.52,
//     unit_2: 'M2',
//     qty_3_type: 'Length',
//     qty_3_value: 15.0,
//     unit_3: 'M',
//     selected_qty: 'Qty 1',
//     qty_type: 'Gross Volume',
//     quantity: 16.96,
//     unit: 'M3',
//     factor_1: 1.05,
//     factor_2: 1.0,
//     factor_3: 1.0,
//     final_qty: 17.8,
//     creator: 'admin',
//     last_editor: 'scsadm',
//     creation_date: '6/11/2018',
//     last_edited: '6/11/2018',
//     children: undefined,
//     parent: undefined,
//   },
//   {
//     key: 3,
//     boqid: 30,
//     id: 4,
//     title: 'Contiguous Pile 600 mm',
//     note: 'Note',
//     take_off_type: 'BIM',
//     source_document_name: 'Bridge model',
//     object_guid: '1Vf2bObon9yu8pfzz2dq14',
//     qty_1_type: 'Gross Volume',
//     qty_1_value: 16.96,
//     unit_1: 'M3',
//     qty_2_type: 'Side Area',
//     qty_2_value: 56.52,
//     unit_2: 'M2',
//     qty_3_type: 'Length',
//     qty_3_value: 15.0,
//     unit_3: 'M',
//     selected_qty: 'Qty 1',
//     qty_type: 'Gross Volume',
//     quantity: 16.96,
//     unit: 'M3',
//     factor_1: 1.05,
//     factor_2: 1.0,
//     factor_3: 1.0,
//     final_qty: 17.8,
//     creator: 'admin',
//     last_editor: 'scsadm',
//     creation_date: '6/11/2018',
//     last_edited: '6/11/2018',
//     children: undefined,
//     parent: undefined,
//   },
//   {
//     key: 4,
//     boqid: 30,
//     id: 5,
//     title: 'Contiguous Pile 600 mm',
//     note: 'Note',
//     take_off_type: 'BIM',
//     source_document_name: 'Bridge model',
//     object_guid: '1Vf2bObon9yu8pfzz2dq15',
//     qty_1_type: 'Gross Volume',
//     qty_1_value: 16.96,
//     unit_1: 'M3',
//     qty_2_type: 'Side Area',
//     qty_2_value: 56.52,
//     unit_2: 'M2',
//     qty_3_type: 'Length',
//     qty_3_value: 15.0,
//     unit_3: 'M',
//     selected_qty: 'Qty 1',
//     qty_type: 'Gross Volume',
//     quantity: 16.96,
//     unit: 'M3',
//     factor_1: 1.05,
//     factor_2: 1.0,
//     factor_3: 1.0,
//     final_qty: 17.8,
//     creator: 'admin',
//     last_editor: 'scsadm',
//     creation_date: '6/11/2018',
//     last_edited: '6/11/2018',
//     children: undefined,
//     parent: undefined,
//   },
//   {
//     key: 5,
//     boqid: 30,
//     id: 6,
//     title: 'Contiguous Pile 600 mm',
//     note: 'Note',
//     take_off_type: 'BIM',
//     source_document_name: 'Bridge model',
//     object_guid: '1Vf2bObon9yu8pfzz2dq16',
//     qty_1_type: 'Gross Volume',
//     qty_1_value: 16.96,
//     unit_1: 'M3',
//     qty_2_type: 'Side Area',
//     qty_2_value: 56.52,
//     unit_2: 'M2',
//     qty_3_type: 'Length',
//     qty_3_value: 15.0,
//     unit_3: 'M',
//     selected_qty: 'Qty 1',
//     qty_type: 'Gross Volume',
//     quantity: 16.96,
//     unit: 'M3',
//     factor_1: 1.05,
//     factor_2: 1.0,
//     factor_3: 1.0,
//     final_qty: 17.8,
//     creator: 'admin',
//     last_editor: 'scsadm',
//     creation_date: '6/11/2018',
//     last_edited: '6/11/2018',
//     children: undefined,
//     parent: undefined,
//   },
//   {
//     key: 6,
//     boqid: 30,
//     id: 2,
//     title: 'Contiguous Pile 600 mm',
//     note: 'Note',
//     take_off_type: 'BIM',
//     source_document_name: 'Bridge model',
//     object_guid: '1Vf2bObon9yu8pfzz2dq12',
//     qty_1_type: 'Gross Volume',
//     qty_1_value: 16.96,
//     unit_1: 'M3',
//     qty_2_type: 'Side Area',
//     qty_2_value: 56.52,
//     unit_2: 'M2',
//     qty_3_type: 'Length',
//     qty_3_value: 15.0,
//     unit_3: 'M',
//     selected_qty: 'Qty 1',
//     qty_type: 'Gross Volume',
//     quantity: 16.96,
//     unit: 'M3',
//     factor_1: 1.05,
//     factor_2: 1.0,
//     factor_3: 1.0,
//     final_qty: 17.8,
//     creator: 'admin',
//     last_editor: 'scsadm',
//     creation_date: '6/11/2018',
//     last_edited: '6/11/2018',
//     children: undefined,
//     parent: undefined,
//   },
//   {
//     key: 7,
//     boqid: 30,
//     id: 3,
//     title: 'Contiguous Pile 600 mm',
//     note: 'Note',
//     take_off_type: 'BIM',
//     source_document_name: 'Bridge model',
//     object_guid: '1Vf2bObon9yu8pfzz2dq13',
//     qty_1_type: 'Gross Volume',
//     qty_1_value: 16.96,
//     unit_1: 'M3',
//     qty_2_type: 'Side Area',
//     qty_2_value: 56.52,
//     unit_2: 'M2',
//     qty_3_type: 'Length',
//     qty_3_value: 15.0,
//     unit_3: 'M',
//     selected_qty: 'Qty 1',
//     qty_type: 'Gross Volume',
//     quantity: 16.96,
//     unit: 'M3',
//     factor_1: 1.05,
//     factor_2: 1.0,
//     factor_3: 1.0,
//     final_qty: 17.8,
//     creator: 'admin',
//     last_editor: 'scsadm',
//     creation_date: '6/11/2018',
//     last_edited: '6/11/2018',
//     children: undefined,
//     parent: undefined,
//   },
//   {
//     key: 8,
//     boqid: 30,
//     id: 4,
//     title: 'Contiguous Pile 600 mm',
//     note: 'Note',
//     take_off_type: 'BIM',
//     source_document_name: 'Bridge model',
//     object_guid: '1Vf2bObon9yu8pfzz2dq14',
//     qty_1_type: 'Gross Volume',
//     qty_1_value: 16.96,
//     unit_1: 'M3',
//     qty_2_type: 'Side Area',
//     qty_2_value: 56.52,
//     unit_2: 'M2',
//     qty_3_type: 'Length',
//     qty_3_value: 15.0,
//     unit_3: 'M',
//     selected_qty: 'Qty 1',
//     qty_type: 'Gross Volume',
//     quantity: 16.96,
//     unit: 'M3',
//     factor_1: 1.05,
//     factor_2: 1.0,
//     factor_3: 1.0,
//     final_qty: 17.8,
//     creator: 'admin',
//     last_editor: 'scsadm',
//     creation_date: '6/11/2018',
//     last_edited: '6/11/2018',
//     children: undefined,
//     parent: undefined,
//   },
//   {
//     key: 9,
//     boqid: 30,
//     id: 5,
//     title: 'Contiguous Pile 600 mm',
//     note: 'Note',
//     take_off_type: 'BIM',
//     source_document_name: 'Bridge model',
//     object_guid: '1Vf2bObon9yu8pfzz2dq15',
//     qty_1_type: 'Gross Volume',
//     qty_1_value: 16.96,
//     unit_1: 'M3',
//     qty_2_type: 'Side Area',
//     qty_2_value: 56.52,
//     unit_2: 'M2',
//     qty_3_type: 'Length',
//     qty_3_value: 15.0,
//     unit_3: 'M',
//     selected_qty: 'Qty 1',
//     qty_type: 'Gross Volume',
//     quantity: 16.96,
//     unit: 'M3',
//     factor_1: 1.05,
//     factor_2: 1.0,
//     factor_3: 1.0,
//     final_qty: 17.8,
//     creator: 'admin',
//     last_editor: 'scsadm',
//     creation_date: '6/11/2018',
//     last_edited: '6/11/2018',
//     children: undefined,
//     parent: undefined,
//   },
//   {
//     key: 10,
//     boqid: 30,
//     id: 6,
//     title: 'Contiguous Pile 600 mm',
//     note: 'Note',
//     take_off_type: 'BIM',
//     source_document_name: 'Bridge model',
//     object_guid: '1Vf2bObon9yu8pfzz2dq16',
//     qty_1_type: 'Gross Volume',
//     qty_1_value: 16.96,
//     unit_1: 'M3',
//     qty_2_type: 'Side Area',
//     qty_2_value: 56.52,
//     unit_2: 'M2',
//     qty_3_type: 'Length',
//     qty_3_value: 15.0,
//     unit_3: 'M',
//     selected_qty: 'Qty 1',
//     qty_type: 'Gross Volume',
//     quantity: 16.96,
//     unit: 'M3',
//     factor_1: 1.05,
//     factor_2: 1.0,
//     factor_3: 1.0,
//     final_qty: 17.8,
//     creator: 'admin',
//     last_editor: 'scsadm',
//     creation_date: '6/11/2018',
//     last_edited: '6/11/2018',
//     children: undefined,
//     parent: undefined,
//   },
// ]

export interface BoQWorkPackagesEntry extends Entry {
  key: number
  boqid: number
  id: number
  title: string
  note: string
  boq_qty: number
  boq_unit: string
  resource_qty: number
  resource_unit: string
  boq_rate: number
  resource_rate: number
  total_cost: number
  id__1: number
  title__1: string
  note__1: string
  country: string
  currency: string
  no_of_bids: number
  awarded_bid: string
  awarded_boq_rate: number
  rate_difference: number
  awarded_total_cost: number
  total_cost_difference: number
  creator: string
  last_editor: string
  creation_date: string
  last_edited: string
}

export const BoQWorkPackagesData: BoQWorkPackagesEntry[] = [
  {
    boqid: 44,
    id: 1,
    key: 1,
    children: undefined,
    parent: undefined,
    title: 'Concrete C20/25   (BS/EN 206-1) - All ex',
    note: '',
    boq_qty: 110.57,
    boq_unit: 'M3',
    resource_qty: 113.87,
    resource_unit: 'M3',
    boq_rate: 94.0,
    resource_rate: 91.27,
    total_cost: 10393.18,
    id__1: 1,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 99.0,
    rate_difference: -5.0,
    awarded_total_cost: 10946.49,
    total_cost_difference: -553.31,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 44,
    id: 2,
    key: 2,
    children: undefined,
    parent: undefined,
    title: 'Skilled Operative - Concreter Ganger (Da',
    note: '',
    boq_qty: 110.57,
    boq_unit: 'M3',
    resource_qty: 22.11,
    resource_unit: 'Hr',
    boq_rate: 4.18,
    resource_rate: 20.88,
    total_cost: 461.74,
    id__1: 2,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 5.0,
    rate_difference: -0.82,
    awarded_total_cost: 552.85,
    total_cost_difference: -91.11,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 44,
    id: 3,
    key: 3,
    children: undefined,
    parent: undefined,
    title: 'Skilled Operative - Concreter Operative',
    note: '',
    boq_qty: 110.57,
    boq_unit: 'M3',
    resource_qty: 110.57,
    resource_unit: 'Hr',
    boq_rate: 17.1,
    resource_rate: 17.1,
    total_cost: 1890.76,
    id__1: 3,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 17.1,
    rate_difference: 0.0,
    awarded_total_cost: 1890.76,
    total_cost_difference: 0.0,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 44,
    id: 4,
    key: 4,
    children: undefined,
    parent: undefined,
    title: 'Poker Vibrator (Air)',
    note: '',
    boq_qty: 110.57,
    boq_unit: 'M3',
    resource_qty: 132.74,
    resource_unit: 'Hr',
    boq_rate: 0.56,
    resource_rate: 0.47,
    total_cost: 62.39,
    id__1: 4,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 0.0,
    rate_difference: 0.56,
    awarded_total_cost: 0.0,
    total_cost_difference: 62.39,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 44,
    id: 5,
    key: 5,
    children: undefined,
    parent: undefined,
    title: 'Compressor hoses/tools',
    note: '',
    boq_qty: 110.57,
    boq_unit: 'M3',
    resource_qty: 265.16,
    resource_unit: 'Hr',
    boq_rate: 0.6,
    resource_rate: 0.25,
    total_cost: 66.29,
    id__1: 5,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 0.0,
    rate_difference: 0.6,
    awarded_total_cost: 0.0,
    total_cost_difference: 66.29,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 44,
    id: 6,
    key: 6,
    children: undefined,
    parent: undefined,
    title: 'Electric Compressor 150 cfm',
    note: '',
    boq_qty: 110.57,
    boq_unit: 'M3',
    resource_qty: 132.74,
    resource_unit: 'Hr',
    boq_rate: 1.32,
    resource_rate: 1.1,
    total_cost: 146.01,
    id__1: 6,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 0.0,
    rate_difference: 1.32,
    awarded_total_cost: 0.0,
    total_cost_difference: 146.01,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 44,
    id: 7,
    key: 7,
    children: undefined,
    parent: undefined,
    title: 'Mobile Conc pump 47m boom 9hrs work (OPT',
    note: '',
    boq_qty: 110.57,
    boq_unit: 'M3',
    resource_qty: 4.42,
    resource_unit: 'Hr',
    boq_rate: 4.39,
    resource_rate: 109.66,
    total_cost: 485.01,
    id__1: 7,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 5.0,
    rate_difference: -0.61,
    awarded_total_cost: 552.85,
    total_cost_difference: -67.85,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 44,
    id: 8,
    key: 8,
    children: undefined,
    parent: undefined,
    title: 'Concrete Vibrator         (PLANT)',
    note: '',
    boq_qty: 110.57,
    boq_unit: 'M3',
    resource_qty: 132.74,
    resource_unit: 'Hr',
    boq_rate: 4.21,
    resource_rate: 3.51,
    total_cost: 465.91,
    id__1: 8,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 5.0,
    rate_difference: -0.79,
    awarded_total_cost: 552.85,
    total_cost_difference: -86.94,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 43,
    id: 9,
    key: 9,
    children: undefined,
    parent: undefined,
    title: 'General Operative (Day Shift)',
    note: '',
    boq_qty: 19.9,
    boq_unit: 'T',
    resource_qty: 74.54,
    resource_unit: 'Hr',
    boq_rate: 56.18,
    resource_rate: 15.0,
    total_cost: 1118.13,
    id__1: 9,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 55.0,
    rate_difference: 1.18,
    awarded_total_cost: 1094.65,
    total_cost_difference: 23.48,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 43,
    id: 10,
    key: 10,
    children: undefined,
    parent: undefined,
    title: 'Skilled Operative - Steel Fixer (Day Shi',
    note: '',
    boq_qty: 19.9,
    boq_unit: 'T',
    resource_qty: 149.64,
    resource_unit: 'Hr',
    boq_rate: 175.04,
    resource_rate: 23.28,
    total_cost: 3483.72,
    id__1: 10,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 170.0,
    rate_difference: 5.04,
    awarded_total_cost: 3383.46,
    total_cost_difference: 100.26,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 43,
    id: 11,
    key: 11,
    children: undefined,
    parent: undefined,
    title: 'Skilled Operative - Chargehand Steel Fix',
    note: '',
    boq_qty: 19.9,
    boq_unit: 'T',
    resource_qty: 74.54,
    resource_unit: 'Hr',
    boq_rate: 95.96,
    resource_rate: 25.62,
    total_cost: 1909.77,
    id__1: 11,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 100.0,
    rate_difference: -4.04,
    awarded_total_cost: 1990.27,
    total_cost_difference: -80.51,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 43,
    id: 12,
    key: 12,
    children: undefined,
    parent: undefined,
    title: 'HY Rebar > 20mm all in',
    note: '',
    boq_qty: 19.9,
    boq_unit: 'T',
    resource_qty: 20.5,
    resource_unit: 'T',
    boq_rate: 567.52,
    resource_rate: 551.06,
    total_cost: 11295.14,
    id__1: 12,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 600.0,
    rate_difference: -32.48,
    awarded_total_cost: 11941.62,
    total_cost_difference: -646.48,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 43,
    id: 13,
    key: 13,
    children: undefined,
    parent: undefined,
    title: 'Reinforcement spacer Type DI 35 mm',
    note: '',
    boq_qty: 19.9,
    boq_unit: 'T',
    resource_qty: 3317.12,
    resource_unit: 'Item',
    boq_rate: 8.33,
    resource_rate: 0.05,
    total_cost: 165.86,
    id__1: 13,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 10.0,
    rate_difference: -1.67,
    awarded_total_cost: 199.03,
    total_cost_difference: -33.17,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 43,
    id: 14,
    key: 14,
    children: undefined,
    parent: undefined,
    title: 'Tying wire (per tonne of reinforcement)',
    note: '',
    boq_qty: 19.9,
    boq_unit: 'T',
    resource_qty: 19.9,
    resource_unit: 'T',
    boq_rate: 5.0,
    resource_rate: 5.0,
    total_cost: 99.51,
    id__1: 14,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 5.0,
    rate_difference: 0.0,
    awarded_total_cost: 99.51,
    total_cost_difference: 0.0,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 42,
    id: 15,
    key: 15,
    children: undefined,
    parent: undefined,
    title: 'Skilled Operative - Carpenter (Day Shift',
    note: '',
    boq_qty: 320.12,
    boq_unit: 'M2',
    resource_qty: 734.22,
    resource_unit: 'Hr',
    boq_rate: 53.39,
    resource_rate: 23.28,
    total_cost: 17092.68,
    id__1: 15,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 55.0,
    rate_difference: -1.61,
    awarded_total_cost: 17606.64,
    total_cost_difference: -513.96,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 42,
    id: 16,
    key: 16,
    children: undefined,
    parent: undefined,
    title: 'Skilled Operative - Chargehand Carpenter',
    note: '',
    boq_qty: 320.12,
    boq_unit: 'M2',
    resource_qty: 146.71,
    resource_unit: 'Hr',
    boq_rate: 11.74,
    resource_rate: 25.62,
    total_cost: 3758.7,
    id__1: 16,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 12.5,
    rate_difference: -0.76,
    awarded_total_cost: 4001.51,
    total_cost_difference: -242.8,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 42,
    id: 17,
    key: 17,
    children: undefined,
    parent: undefined,
    title: 'Curing Agent',
    note: '',
    boq_qty: 320.12,
    boq_unit: 'M2',
    resource_qty: 1600.6,
    resource_unit: 'LIT',
    boq_rate: 2.75,
    resource_rate: 0.55,
    total_cost: 880.33,
    id__1: 17,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 5.0,
    rate_difference: -2.25,
    awarded_total_cost: 1600.6,
    total_cost_difference: -720.27,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 42,
    id: 18,
    key: 18,
    children: undefined,
    parent: undefined,
    title: '20mm marine ply',
    note: '',
    boq_qty: 320.12,
    boq_unit: 'M2',
    resource_qty: 80.03,
    resource_unit: 'M2',
    boq_rate: 2.75,
    resource_rate: 11.0,
    total_cost: 880.33,
    id__1: 18,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 5.0,
    rate_difference: -2.25,
    awarded_total_cost: 1600.6,
    total_cost_difference: -720.27,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 42,
    id: 19,
    key: 19,
    children: undefined,
    parent: undefined,
    title: 'Shutter Oil',
    note: '',
    boq_qty: 320.12,
    boq_unit: 'M2',
    resource_qty: 16.01,
    resource_unit: 'LIT',
    boq_rate: 0.06,
    resource_rate: 1.25,
    total_cost: 20.01,
    id__1: 19,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 0.0,
    rate_difference: 0.06,
    awarded_total_cost: 0.0,
    total_cost_difference: 20.01,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 42,
    id: 20,
    key: 20,
    children: undefined,
    parent: undefined,
    title: 'Nail Allowance',
    note: '',
    boq_qty: 320.12,
    boq_unit: 'M2',
    resource_qty: 320.12,
    resource_unit: 'M2',
    boq_rate: 1.0,
    resource_rate: 1.0,
    total_cost: 320.12,
    id__1: 20,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 0.0,
    rate_difference: 1.0,
    awarded_total_cost: 0.0,
    total_cost_difference: 320.12,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 42,
    id: 21,
    key: 21,
    children: undefined,
    parent: undefined,
    title: 'Propping Allowance',
    note: '',
    boq_qty: 320.12,
    boq_unit: 'M2',
    resource_qty: 320.12,
    resource_unit: 'M2',
    boq_rate: 1.5,
    resource_rate: 1.5,
    total_cost: 480.18,
    id__1: 21,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 0.0,
    rate_difference: 1.5,
    awarded_total_cost: 0.0,
    total_cost_difference: 480.18,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 42,
    id: 22,
    key: 22,
    children: undefined,
    parent: undefined,
    title: 'Timber',
    note: '',
    boq_qty: 320.12,
    boq_unit: 'M2',
    resource_qty: 4.8,
    resource_unit: 'M3',
    boq_rate: 3.6,
    resource_rate: 240.0,
    total_cost: 1151.86,
    id__1: 22,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 5.0,
    rate_difference: -1.4,
    awarded_total_cost: 1600.6,
    total_cost_difference: -448.74,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
  {
    boqid: 46,
    id: 23,
    key: 23,
    children: undefined,
    parent: undefined,
    title: 'Skilled Operative - Concreter Operative',
    note: '',
    boq_qty: 320.12,
    boq_unit: 'M2',
    resource_qty: 64.02,
    resource_unit: 'Hr',
    boq_rate: 3.42,
    resource_rate: 17.1,
    total_cost: 1094.81,
    id__1: 23,
    title__1: 'In-situ Concrete',
    note__1: '',
    country: 'United Kingdom',
    currency: 'GBP',
    no_of_bids: 4.0,
    awarded_bid: 'Construct LND',
    awarded_boq_rate: 5.0,
    rate_difference: -1.58,
    awarded_total_cost: 1600.6,
    total_cost_difference: -505.79,
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
  },
]

export interface BoQActivitiesEntry extends Entry {
  key: number
  boqid: number
  id: number
  wbs_code: string
  title: string
  note: string
  description: string
  location: string
  status: string
  currency: string
  unit: string
  quantity: number
  total_rate: number
  gross_total: number
  id__1: number
  activity_code: string
  title__1: string
  note__1: string
  qty_split: number
  qty_value: number
  total_value: number
  start_date: string
  end_date: string
  creator: string
  last_editor: string
  creation_date: string
  last_edited: string
}

export const BoQActivitiesData: BoQActivitiesEntry[] = [
  {
    boqid: 42,
    id: 1,
    key: 1,
    children: undefined,
    parent: undefined,
    wbs_code: '03.01.02.02.12.0010.0010',
    title: 'Formwork Rough finish all sides, Plain formwork left to the discursion of the contractor NBS Spec E20',
    note: '',
    description: '',
    location: 'Foundations',
    status: 'Under review',
    currency: 'GBP',
    unit: 'M2',
    quantity: 320.12,
    total_rate: 76.79,
    gross_total: 24582.07,
    id__1: 3,
    activity_code: 'A1020',
    title__1: 'Foundations',
    note__1: '',
    qty_split: 100,
    qty_value: 320.12,
    total_value: 24582.07,
    start_date: '15.05.2018',
    end_date: '12.06.2018',
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '01.01.2020',
    last_edited: '01.01.2020',
  },
  {
    boqid: 43,
    id: 2,
    key: 2,
    children: undefined,
    parent: undefined,
    wbs_code: '03.01.02.02.12.0010.0020',
    title: 'Reinforcement as specified, NBS E30',
    note: '',
    description: '',
    location: 'Foundations',
    status: 'Under review',
    currency: 'GBP',
    unit: 't',
    quantity: 19.9,
    total_rate: 908.03,
    gross_total: 18072.25,
    id__1: 3,
    activity_code: 'A1020',
    title__1: 'Foundations',
    note__1: '',
    qty_split: 100,
    qty_value: 19.9,
    total_value: 18072.25,
    start_date: '15.05.2018',
    end_date: '12.06.2018',
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '01.01.2020',
    last_edited: '01.01.2020',
  },
  {
    boqid: 44,
    id: 3,
    key: 3,
    children: undefined,
    parent: undefined,
    wbs_code: '03.01.02.02.12.0010.0030',
    title: 'In-situ concrete (PC1) as specified, NBS E05',
    note: '',
    description: '',
    location: 'Foundations',
    status: 'Under review',
    currency: 'GBP',
    unit: 'M3',
    quantity: 110.57,
    total_rate: 126.36,
    gross_total: 13971.7,
    id__1: 3,
    activity_code: 'A1020',
    title__1: 'Foundations',
    note__1: '',
    qty_split: 100,
    qty_value: 110.57,
    total_value: 13971.7,
    start_date: '15.05.2018',
    end_date: '12.06.2018',
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '01.01.2020',
    last_edited: '01.01.2020',
  },
  {
    boqid: 45,
    id: 4,
    key: 4,
    children: undefined,
    parent: undefined,
    wbs_code: '03.01.02.02.12.0010.0040',
    title: 'Strip formwork to cast In-situ concrete as specified, NBS E20',
    note: '',
    description: '',
    location: 'Foundations',
    status: 'Under review',
    currency: 'GBP',
    unit: 'M2',
    quantity: 492.82,
    total_rate: 25.69,
    gross_total: 12660.01,
    id__1: 3,
    activity_code: 'A1020',
    title__1: 'Foundations',
    note__1: '',
    qty_split: 100,
    qty_value: 492.82,
    total_value: 12660.01,
    start_date: '15.05.2018',
    end_date: '12.06.2018',
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '01.01.2020',
    last_edited: '01.01.2020',
  },
  {
    boqid: 46,
    id: 5,
    key: 5,
    children: undefined,
    parent: undefined,
    wbs_code: '03.01.02.02.12.0010.0050',
    title: 'Surface finish to In-situ concrete as specified, NBS E41',
    note: '',
    description: '',
    location: 'Foundations',
    status: 'Under review',
    currency: 'GBP',
    unit: 'M2',
    quantity: 103.18,
    total_rate: 3.42,
    gross_total: 352.87,
    id__1: 3,
    activity_code: 'A1020',
    title__1: 'Foundations',
    note__1: '',
    qty_split: 100,
    qty_value: 103.18,
    total_value: 352.87,
    start_date: '15.05.2018',
    end_date: '12.06.2018',
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '01.01.2020',
    last_edited: '01.01.2020',
  },
]
