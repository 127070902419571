import React, { useState } from 'react'
import { emptyDocumentationEntry, documentationData } from '../../../../data/Documentation.data'
import { Row, Col, Divider } from 'antd'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { calculateHeightForWholePageTable, headerHeight } from '../../../../constants/dimensions'
import { ForgeViewer } from '../../forge-viewer/ForgeViewer'
import SterlingTable from '../../../basic/table/sterling-table/SterlingTable'
import { DocumentationHeader } from '../header/DocumentationHeader'
import DocumentationSelectionToolbar from '../documentation-selection-toolbar/DocumentationSelectionToolbar'
import TableSelectionToolbar from '../../../basic/table/table-selection-toolbar/TableSelectionToolbar'
import { Model } from '../../forge-viewer/ForgeViewer.model'
import { setsDifference, setsIntersection, setsUnion } from '../../../../utils/collections/SetUtils'
import { documentationColumns } from '../../library/table/columns/Documentation.columns'
import NewWindow from '../../../basic/new-window/NewWindow'
import { newWindowHeight, newWindowWidth } from '../../../../constants/sizes'
import ReplacementPane from '../../../basic/new-window/ReplacementPane'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../context/reducer'
import { setOpenWindowState } from '../../../../context/open-windows/OpenWindows.actions'

export default function DocumentationManage() {
  const { height } = useWindowDimensions()
  const tableHeight = calculateHeightForWholePageTable(height)
  const [forgeDocuments, setForgeDocuments] = useState<any[]>([])
  const [modelState, setModelState] = useState<Model[]>([])

  const dispatch = useDispatch()

  const getOpenWindow = (id: string) => (state: RootState) => state.openWindows.windows.get(id) || false
  const documentationLeftWindowWindowId = 'documentationLeftWindow'
  const isdocumentationLeftWindowOpen = useSelector(getOpenWindow(documentationLeftWindowWindowId))

  const data = [...new Set(documentationData.map((d) => d.folder))].map((folder, idx) => {
    return {
      ...emptyDocumentationEntry(folder, idx + 100000),
      children: documentationData.filter((doc) => doc.folder === folder),
    }
  })

  const excludedEntryIds = data.map((v) => v.id)

  const models3DIds = documentationData.filter((f) => f.folder === '3D models').map((e) => e.id)
  const [visibleFiles, setVisibleFiles] = useState(new Set(models3DIds))

  const document = (urn: string) => {
    return {
      urn,
    }
  }

  const onEnableCallDiff = (urn: string[]) => {
    setForgeDocuments(urn)
  }

  return (
    <div>
      <Row>
        <Col span={10}>
          <NewWindow
            height={newWindowHeight}
            width={newWindowWidth}
            isOpen={isdocumentationLeftWindowOpen}
            onPortalClose={() => dispatch(setOpenWindowState(documentationLeftWindowWindowId, false))}
            replacementPane={
              <ReplacementPane
                onHide={() => dispatch(setOpenWindowState(documentationLeftWindowWindowId, false))}
                onWindowShow={() => {}}
                style={{ width: '100%', height: 450, backgroundColor: '#fff' }}
              />
            }
          >
            <SterlingTable
              tableId="documentation"
              tableHeight={tableHeight}
              defaultPagination={false}
              renderHeader={(props) => (
                <DocumentationHeader
                  openWindow={() => dispatch(setOpenWindowState(documentationLeftWindowWindowId, true))}
                  documentationData={data}
                  sterlingTableHeaderProps={props}
                />
              )}
              renderSelectionToolbar={(props) => (
                <TableSelectionToolbar
                  {...props}
                  children={
                    <DocumentationSelectionToolbar
                      selectedItemsNumber={props.selectedItemsNumber}
                      selectedItem={
                        props.selectedItems !== undefined && props.selectedItems.length > 0 ? props.selectedItems : []
                      }
                      enableCallDiff={onEnableCallDiff}
                    />
                  }
                />
              )}
              dataSource={data}
              columns={documentationColumns(
                visibleFiles,
                (id) => {
                  if (!models3DIds.includes(id) || setsIntersection(visibleFiles, new Set(models3DIds)).size === 0) {
                    setVisibleFiles(new Set([id]))
                  } else {
                    setVisibleFiles(setsUnion(visibleFiles, new Set([id])))
                  }
                },
                (id) => setVisibleFiles(setsDifference(visibleFiles, new Set([id]))),
                excludedEntryIds
              )}
              size={'small'}
            />
          </NewWindow>
        </Col>
        <Divider type="vertical" />
        <Col span={13}>
          {/* <SubstructureDiff /> */}
          <ForgeViewer
            documents={documentationData.flatMap((f) => Object.values(f.revisions)).map((v) => document(v))}
            onModelLoaded={(model) => {
              setModelState(modelState.concat(model))
            }}
            // @ts-ignore
            diff={
              forgeDocuments.length > 0
                ? {
                    mainModel: modelState.find((v) => v.documentUrn === forgeDocuments[0]),
                    diffModel: modelState.find((v) => v.documentUrn === forgeDocuments[1]),
                  }
                : undefined
            }
            visibleModelsUrns={documentationData
              .filter((f) => visibleFiles.has(f.id))
              .flatMap((f) => Object.values(f.revisions))}
          />
        </Col>
      </Row>
    </div>
  )
}
