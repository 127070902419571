import { SyncOutlined, PlusSquareOutlined } from '@ant-design/icons'
import React from 'react'
import { Space, Button, Divider, Tooltip } from 'antd'
import {
  SterlingTableHeaderProps,
  SterlingTableHeader,
} from '../../../basic/table/sterling-table/header/SterlingTableHeader'

export interface AdministrationHeaderProps {
  sterlingTableHeaderProps: SterlingTableHeaderProps
  children?: any[]
}

export const AdministrationHeader = ({ children, sterlingTableHeaderProps }: AdministrationHeaderProps) => {
  return (
    <SterlingTableHeader {...sterlingTableHeaderProps} columnsLayoutManagerVisibility={false}>
      <Tooltip title="Refresh">
        <Button type="text" shape="circle" icon={<SyncOutlined />} />
      </Tooltip>
      <Divider type="vertical" />
      <Tooltip title="Add row">
        <Button type="text" shape="circle" icon={<PlusSquareOutlined />} />
      </Tooltip>
      {children}
    </SterlingTableHeader>
  )
}
