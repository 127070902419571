import { Table } from 'antd'
import React from 'react'
import { SettingsHeader } from '../header/SettingsHeader'
import { settingsRevisionsColumns } from '../Settings.columns'
import { settingsRevisionsData } from '../Settings.data'

export default function SettingsRevisions() {
  return (
    <Table
      title={() => <SettingsHeader children={() => {}} />}
      dataSource={settingsRevisionsData}
      columns={settingsRevisionsColumns}
      rowSelection={{
        type: 'radio',
        columnTitle: 'Active Version',
        columnWidth: 100,
      }}
    />
  )
}
