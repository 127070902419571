import React, { FunctionComponent } from 'react'
import SterlingTable from '../../../basic/table/sterling-table/SterlingTable'
import { WorkPackagesBoQColumns } from './MiniTablesColumns'
import WorkPackagesHeader from './WorkPackagesHeader'

interface WorkPackagesBoQProps {
  data: any[]
  tableId: string
  row?: any
  rowHeight: number
  onRow?: (index: number, record: any) => any
  pixelPerfectHeightBonus?: number
}

const WorkPackagesBoQ: FunctionComponent<WorkPackagesBoQProps> = ({
  data,
  tableId,
  onRow,
  row,
  rowHeight,
  pixelPerfectHeightBonus,
}) => {
  return (
    <SterlingTable
      columns={WorkPackagesBoQColumns}
      dataSource={data}
      tableId={tableId}
      onRow={onRow}
      row={row}
      tableHeight={rowHeight * 6 + (pixelPerfectHeightBonus !== undefined ? pixelPerfectHeightBonus : 0)}
      defaultPagination={false}
      renderHeader={(p) => (
        <WorkPackagesHeader
          data={data}
          forMapping={true}
          noImportExport={true}
          columns={WorkPackagesBoQColumns}
          tableId={'workPackagesBoQ'}
          sterlingHeaderProps={p}
        />
      )}
      disableSelection={true}
      size={'small'}
    />
  )
}

export default WorkPackagesBoQ
