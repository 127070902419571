import React, { FunctionComponent, ReactText, useState } from 'react'
import { Row, Col, Table, Tag, Button, Select, Space, Modal, Input } from 'antd'
import { BoQData } from '../../../../data/BillOfQuantities.data'
import { WorkPackagesDetailsColumnsFor } from '../tables/WorkPackagesDetails.columns'
import { WorkPackagesBoQColumns } from '../headers/MiniTablesColumns'
import { identity } from '../../../../utils/FunctionalUtils'
import { TrophyOutlined } from '@ant-design/icons/lib'
import SterlingTable, { ExtendedColumnType } from '../../../basic/table/sterling-table/SterlingTable'
import WorkPackagesDetailsHeader from './WorkPackagesDetailsHeader'
import { EmptyQuoteItemFor, Quotes, takeoffKeys } from '../../../../data/WorkPackagesDetailsData'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { calculateHeightForWholePageTable } from '../../../../constants/dimensions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../context/reducer'
import { updateEntry } from '../../../../context/resource-library/SterlingTable.actions'
import FormItem from 'antd/lib/form/FormItem'
import WorkPackagesDetailsModal from './WorkPackagesDetailsModal'

const WorkPackagesDetails: FunctionComponent = () => {
  const { height } = useWindowDimensions()
  const tableHeight = calculateHeightForWholePageTable(height) - 100
  const tableId = 'wpDetails'
  const dispatch = useDispatch()

  const [selectedItem, setSelectedItem] = useState('Partitions')
  const [isModalVisible, setModalVisible] = useState(false)
  const [newQuoteName, setNewQuoteName] = useState('')
  const [awardedId, setAwardedId] = useState(0)
  const data = useSelector((state: RootState) => state.sterlingTable.parsedData[tableId])
  const [quotes, setQuotes] = useState(Quotes)

  const quoteItems = quotes.map((q) => ({
    title: (
      <div>
        <span>{`${q.name} - `}</span>
        <span>{`£ ${
          // @ts-ignore
          q.items.reduce((acc, item) => acc + (item[`proposed_total_${q.id}`] || 0), 0).toFixed(2)
        } `}</span>
        {q.isAwarded && (
          <span style={{ color: '#ffcc72' }}>
            <TrophyOutlined /> Awarded
          </span>
        )}
      </div>
    ),
    supportTitle: q.name,
    width: 500,
    key: 'wp_gatherer',
    dataIndex: 'wp_gatherer',
    render: identity,
    children: WorkPackagesDetailsColumnsFor(q.id),
  }))

  const columns: ExtendedColumnType[] = [
    {
      title: (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p style={{ flex: 1 }}>Select work package</p>
          <Select
            showSearch={true}
            style={{ width: '50%' }}
            defaultValue={selectedItem}
            onSelect={(d) => setSelectedItem(d)}
          >
            {takeoffKeys.map((k) => (
              <Select.Option key={k} value={k}>
                {k}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
      dataIndex: 'boq_gatherer',
      key: 'boq_gatherer',
      fixed: 'left' as 'left',
      render: identity,
      editable: true,
      children: WorkPackagesBoQColumns.map((c) => ({
        ...c,
        fixed: 'left',
        width: c.key === 'title' ? 250 : 100,
        editable: true,
      })),
    },
    ...quoteItems,
  ]

  // const activeGroupingColumn = useSelector((state: RootState) => state.sterlingTable.groupedBy['workPackagesDetails'])
  // const sortedColumns = sortColumnsAccordingToGrouping(columns, activeGroupingColumn)

  const prepareData = () => {
    const quoteIds = quotes.map((q) => q.id)
    return (
      data
        .map((d) => {
          quoteIds.forEach((id) => {
            // @ts-ignore
            d[`proposed_total_${id}`] = d[`proposed_rate_${id}`] * d[`quantity`]
            // @ts-ignore
            d[`total_cost_difference_${id}`] = d[`proposed_total_${id}`] - d[`base_total`]
          })
          return d
        })
        // @ts-ignore
        .filter((d) => d.wp_code === selectedItem)
    )
  }

  const addNewQuote = (name: string) => {
    const newQuoteId = quotes.reduce((acc, q) => Math.max(q.id, acc), 0) + 1
    setQuotes([
      {
        id: newQuoteId,
        items: BoQData.map(({ id }) => EmptyQuoteItemFor(id, newQuoteId)),
        isAwarded: false,
        name,
      },
      ...quotes,
    ])
  }
  return (
    <>
      <Row>
        <SterlingTable
          columns={columns}
          dataSource={prepareData()}
          defaultPagination={false}
          scrollWidth={1000}
          bordered={true}
          onUpdate={(id: number, updates: { [key: string]: any }) => dispatch(updateEntry(tableId, id, updates))}
          renderHeader={(props) => (
            <WorkPackagesDetailsHeader
              awardedId={awardedId}
              sterlingHeaderProps={props}
              tableId={'workPackagesDetails'}
              columns={columns}
              quotes={Quotes}
              onQuoteAdd={() => setModalVisible(true)}
              onQuoteAward={(awarded_id: number) => setAwardedId(awarded_id)}
              data={prepareData()}
            />
          )}
          tableId={'workPackagesDetails'}
          tableHeight={tableHeight}
        />
      </Row>
      <WorkPackagesDetailsModal
        isModalVisible={isModalVisible}
        addNewQuote={addNewQuote}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
      />
    </>
  )
}

export default WorkPackagesDetails
