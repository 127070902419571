import { ModelNode } from '../../components/domain/forge-viewer/ForgeViewer.model'
import { parseTakeOffData, TakeOffItem } from '../../components/domain/take-off/helpers/TakeOffDataParser'
import { convertToTableItem } from '../../components/domain/take-off/helpers/TakeOffConverter'
import { TakeOffTableItem } from '../../components/domain/take-off/TakeOffTables.model'

export const ModelMEPData: ModelNode = {
  id: 1,
  name: 'Model',
  children: [
    {
      id: 3790,
      name: 'Plumbing Fixtures',
      children: [
        {
          id: 3791,
          name: 'Sink - Work',
          children: [
            {
              id: 3793,
              name: '03.05.40.006.01_G.032_WHB staff/visitor N13/337',
              children: [
                {
                  id: 3794,
                  name: 'Sink - Work [854349]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3795,
                  name: 'Sink - Work [854457]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.006.01_G.032_WHB staff/visitor N13/337',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Sink - Work',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3797,
              name: '03.05.40.004.01_G.030WHB staff/visitor N13/337',
              children: [
                {
                  id: 3798,
                  name: 'Sink - Work [856672]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.004.01_G.030WHB staff/visitor N13/337',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Sink - Work',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3802,
              name: '03.05.28.005.01_G.019_WHB  clinical N13/337A',
              children: [
                {
                  id: 3803,
                  name: 'Sink - Work [857696]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.28.005.01_G.019_WHB  clinical N13/337A',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Sink - Work',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3805,
              name: '03.05.40.003.01_G.020_WHB patient N13/335',
              children: [
                {
                  id: 3806,
                  name: 'Sink - Work [857815]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.003.01_G.020_WHB patient N13/335',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Sink - Work',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3813,
              name: '03.05.05.001.01_G.016_Sink cleaners N13/390',
              children: [
                {
                  id: 3814,
                  name: 'Sink - Work [858081]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.05.001.01_G.016_Sink cleaners N13/390',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Sink - Work',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3816,
              name: '03.05.22.001.01_G.010_Sink N13/331',
              children: [
                {
                  id: 3817,
                  name: 'Sink - Work [858215]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.22.001.01_G.010_Sink N13/331',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Sink - Work',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3827,
              name: '03.05.05.002.01_1.021_Sink cleaners N13/390',
              children: [
                {
                  id: 3828,
                  name: 'Sink - Work [859171]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.05.002.01_1.021_Sink cleaners N13/390',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Sink - Work',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3848,
              name: '03.05.40.009.01_1.025_WHB staff/visitor N13/337',
              children: [
                {
                  id: 3849,
                  name: 'Sink - Work [861181]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.009.01_1.025_WHB staff/visitor N13/337',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Sink - Work',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3855,
              name: '03.05.40.010.01_1.027_WHB staff/visitor N13/337',
              children: [
                {
                  id: 3856,
                  name: 'Sink - Work [861655]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.010.01_1.027_WHB staff/visitor N13/337',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Sink - Work',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3858,
              name: '03.05.32.002.01_1.008_Sink N13/331',
              children: [
                {
                  id: 3859,
                  name: 'Sink - Work [862025]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.32.002.01_1.008_Sink N13/331',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Sink - Work',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3865,
              name: '03.05.40.008.01_1.020_WHB staff/visitor N13/337',
              children: [
                {
                  id: 3866,
                  name: 'Sink - Work [862861]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3867,
                  name: 'Sink - Work [862862]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3868,
                  name: 'Sink - Work [862863]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.008.01_1.020_WHB staff/visitor N13/337',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Sink - Work',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3870,
              name: '03.05.40.007.01_1.018_WHB staff/visitor N13/337',
              children: [
                {
                  id: 3871,
                  name: 'Sink - Work [862864]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3872,
                  name: 'Sink - Work [862865]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3873,
                  name: 'Sink - Work [862866]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.007.01_1.018_WHB staff/visitor N13/337',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Sink - Work',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3885,
              name: '03.05.40.005.01_G.031_WHB staff/visitor N13/337',
              children: [
                {
                  id: 3886,
                  name: 'Sink - Work [862969]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3887,
                  name: 'Sink - Work [862970]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.005.01_G.031_WHB staff/visitor N13/337',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Sink - Work',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3905,
              name: '03.05.40.001.01_G.008_WHB staff/visitor N13/337',
              children: [
                {
                  id: 3906,
                  name: 'Sink - Work [863105]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.001.01_G.008_WHB staff/visitor N13/337',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Sink - Work',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3908,
              name: '03.05.40.002.01_G.009_WHB staff/visitor N13/337',
              children: [
                {
                  id: 3909,
                  name: 'Sink - Work [863179]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.002.01_G.009_WHB staff/visitor N13/337',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Sink - Work',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3911,
              name: '03.05.30.001.01_G.038_WHB staff/visitor N13/337',
              children: [
                {
                  id: 3912,
                  name: 'Sink - Work [863223]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.30.001.01_G.038_WHB staff/visitor N13/337',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Sink - Work',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3914,
              name: '03.05.32.005.01_1.026_WHB staff/visitor N13/337',
              children: [
                {
                  id: 3915,
                  name: 'Sink - Work [863660]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.32.005.01_1.026_WHB staff/visitor N13/337',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Sink - Work',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Sink - Work',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Plumbing Fixtures',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3807,
          name: 'M_Water Closet - Flush Valve - Wall Mounted',
          children: [
            {
              id: 3809,
              name: '03.05.40.003.01_G.020_WC patient N13/300',
              children: [
                {
                  id: 3810,
                  name: 'M_Water Closet - Flush Valve - Wall Mounted [857935]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.003.01_G.020_WC patient N13/300',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Water Closet - Flush Valve - Wall Mounted',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3819,
              name: '03.05.40.001.01_G.008_WC  staff/visitor N13/302',
              children: [
                {
                  id: 3820,
                  name: 'M_Water Closet - Flush Valve - Wall Mounted [858400]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.001.01_G.008_WC  staff/visitor N13/302',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Water Closet - Flush Valve - Wall Mounted',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3823,
              name: '03.05.40.002.01_G.009_WC  staff/visitor N13/302',
              children: [
                {
                  id: 3824,
                  name: 'M_Water Closet - Flush Valve - Wall Mounted [858409]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.002.01_G.009_WC  staff/visitor N13/302',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Water Closet - Flush Valve - Wall Mounted',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3840,
              name: '03.05.40.008.01_1.020_WC  staff/visitor N13/302',
              children: [
                {
                  id: 3841,
                  name: 'M_Water Closet - Flush Valve - Wall Mounted [860502]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.008.01_1.020_WC  staff/visitor N13/302',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Water Closet - Flush Valve - Wall Mounted',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3844,
              name: '03.05.40.007.01_1.018_WC  staff/visitor N13/302',
              children: [
                {
                  id: 3845,
                  name: 'M_Water Closet - Flush Valve - Wall Mounted [861054]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3846,
                  name: 'M_Water Closet - Flush Valve - Wall Mounted [861084]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.007.01_1.018_WC  staff/visitor N13/302',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Water Closet - Flush Valve - Wall Mounted',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3851,
              name: '03.05.40.010.01_1.027_WC  staff/visitor N13/302',
              children: [
                {
                  id: 3852,
                  name: 'M_Water Closet - Flush Valve - Wall Mounted [861453]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.010.01_1.027_WC  staff/visitor N13/302',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Water Closet - Flush Valve - Wall Mounted',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3861,
              name: '03.05.40.009.01_1.025_WC  disabled staff/visitor N13/311',
              children: [
                {
                  id: 3862,
                  name: 'M_Water Closet - Flush Valve - Wall Mounted [862798]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.009.01_1.025_WC  disabled staff/visitor N13/311',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Water Closet - Flush Valve - Wall Mounted',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3881,
              name: '03.05.40.005.01_G.031_WC  staff/visitor N13/302',
              children: [
                {
                  id: 3882,
                  name: 'M_Water Closet - Flush Valve - Wall Mounted [862937]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3888,
                  name: 'M_Water Closet - Flush Valve - Wall Mounted [862995]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.005.01_G.031_WC  staff/visitor N13/302',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Water Closet - Flush Valve - Wall Mounted',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3891,
              name: '03.05.40.006.01_G.032_WC  staff/visitor N13/302',
              children: [
                {
                  id: 3892,
                  name: 'M_Water Closet - Flush Valve - Wall Mounted [863022]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.006.01_G.032_WC  staff/visitor N13/302',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Water Closet - Flush Valve - Wall Mounted',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3902,
              name: '03.05.40.004.01_G.030_WC  disabled staff/visitor N13/311',
              children: [
                {
                  id: 3903,
                  name: 'M_Water Closet - Flush Valve - Wall Mounted [863078]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.004.01_G.030_WC  disabled staff/visitor N13/311',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Water Closet - Flush Valve - Wall Mounted',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'M_Water Closet - Flush Valve - Wall Mounted',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Plumbing Fixtures',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3829,
          name: 'Shower Stall - Rectangular',
          children: [
            {
              id: 3831,
              name: '03.05.40.008.01_1.020_Shower Nonpatient with tray N13/375',
              children: [
                {
                  id: 3832,
                  name: 'Shower Stall - Rectangular [859474]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3833,
                  name: 'Shower Stall - Rectangular [859674]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3834,
                  name: 'Shower Stall - Rectangular [859694]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.008.01_1.020_Shower Nonpatient with tray N13/375',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Shower Stall - Rectangular',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3836,
              name: '03.05.40.007.01_1.018_Shower Nonpatient with tray N13/375',
              children: [
                {
                  id: 3837,
                  name: 'Shower Stall - Rectangular [859709]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3838,
                  name: 'Shower Stall - Rectangular [859727]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.007.01_1.018_Shower Nonpatient with tray N13/375',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Shower Stall - Rectangular',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Shower Stall - Rectangular',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Plumbing Fixtures',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3875,
          name: 'Urinal - Wall Hung',
          children: [
            {
              id: 3877,
              name: '03.05.40.008.01_1.020_Urinal N13/315',
              children: [
                {
                  id: 3878,
                  name: 'Urinal - Wall Hung [862912]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3879,
                  name: 'Urinal - Wall Hung [862913]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.008.01_1.020_Urinal N13/315',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Urinal - Wall Hung',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3896,
              name: '03.05.40.006.01_G.032_Urinal N13/315',
              children: [
                {
                  id: 3897,
                  name: 'Urinal - Wall Hung [863051]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3899,
                  name: 'Urinal - Wall Hung [863053]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.40.006.01_G.032_Urinal N13/315',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Urinal - Wall Hung',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Urinal - Wall Hung',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Plumbing Fixtures',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 5011,
          name: 'Floor Drain - Rectangular',
          children: [
            {
              id: 5013,
              name: 'xx.xx.xx.xxx.xx_Floor drain',
              children: [
                {
                  id: 5014,
                  name: 'Floor Drain - Rectangular [931125]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5017,
                  name: 'Floor Drain - Rectangular [931433]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5018,
                  name: 'Floor Drain - Rectangular [931479]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5020,
                  name: 'Floor Drain - Rectangular [931758]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5021,
                  name: 'Floor Drain - Rectangular [931883]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5022,
                  name: 'Floor Drain - Rectangular [932013]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Plumbing Fixtures',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Floor drain',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor Drain - Rectangular',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Plumbing Fixtures',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Floor Drain - Rectangular',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Plumbing Fixtures',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Plumbing Fixtures',
          displayCategory: '__category__',
        },
      ],
    },
    {
      id: 3916,
      name: 'Generic Models',
      children: [
        {
          id: 3917,
          name: 'mechanical_equipment',
          children: [
            {
              id: 3919,
              name: 'xx.xx.xx.xxx.xx_Air Handling Unit AHU/401/1F',
              children: [
                {
                  id: 3920,
                  name: 'mechanical_equipment [865052]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Generic Models',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1700,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 6.9904,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3921,
                  name: 'mechanical_equipment [865122]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Generic Models',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1700,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 6.9904,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Air Handling Unit AHU/401/1F',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'mechanical_equipment',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Generic Models',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3923,
              name: 'xx.xx.xx.xxx.xx_Outdoor Heater Unit ODU/401/1F',
              children: [
                {
                  id: 3924,
                  name: 'mechanical_equipment [865534]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Generic Models',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1250,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.1250000000000004,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3925,
                  name: 'mechanical_equipment [865535]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Generic Models',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1060,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9010000000000002,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3926,
                  name: 'mechanical_equipment [865536]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Generic Models',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1060,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9010000000000002,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Outdoor Heater Unit ODU/401/1F',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'mechanical_equipment',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Generic Models',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'mechanical_equipment',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Generic Models',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 4603,
          name: 'Electrical_equipment',
          children: [
            {
              id: 4605,
              name: 'xx.xx.xx.xxx.xx_DB-401-GF-01 Ground Floor L&P',
              children: [
                {
                  id: 4606,
                  name: 'Electrical_equipment [895320]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Generic Models',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 440,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0858,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_DB-401-GF-01 Ground Floor L&P',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Electrical_equipment',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Generic Models',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4608,
              name: 'xx.xx.xx.xxx.xx_DB-401-GF-02 Distribution Board',
              children: [
                {
                  id: 4609,
                  name: 'Electrical_equipment [895627]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Generic Models',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 440,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0858,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_DB-401-GF-02 Distribution Board',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Electrical_equipment',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Generic Models',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4611,
              name: 'xx.xx.xx.xxx.xx_DB-401-1F-01 Distribution Board',
              children: [
                {
                  id: 4612,
                  name: 'Electrical_equipment [895701]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Generic Models',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 440,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0858,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_DB-401-1F-01 Distribution Board',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Electrical_equipment',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Generic Models',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4614,
              name: 'xx.xx.xx.xxx.xx_DB-401-1F-03 Distribution Board',
              children: [
                {
                  id: 4615,
                  name: 'Electrical_equipment [895754]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Generic Models',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 440,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0858,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_DB-401-1F-03 Distribution Board',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Electrical_equipment',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Generic Models',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4617,
              name: 'xx.xx.xx.xxx.xx_DB-401-1F-02 Distribution Board',
              children: [
                {
                  id: 4618,
                  name: 'Electrical_equipment [895764]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Generic Models',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5200000000000002,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_DB-401-1F-02 Distribution Board',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Electrical_equipment',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Generic Models',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4620,
              name: 'xx.xx.xx.xxx.xx_FAP-Enterance Building  Main Panel',
              children: [
                {
                  id: 4621,
                  name: 'Electrical_equipment [895876]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Generic Models',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1160,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1500,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.8351999999999998,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_FAP-Enterance Building  Main Panel',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Electrical_equipment',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Generic Models',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5005,
              name: 'xx.xx.xx.xxx.xx_Generator Set',
              children: [
                {
                  id: 5006,
                  name: 'Electrical_equipment [929860]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Generic Models',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 5.2,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Generator Set',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Electrical_equipment',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Generic Models',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Electrical_equipment',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Generic Models',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Generic Models',
          displayCategory: '__category__',
        },
      ],
    },
    {
      id: 3927,
      name: 'Ducts',
      children: [
        {
          id: 3928,
          name: 'Rectangular Duct',
          children: [
            {
              id: 3930,
              name: 'xx.xx.xx.xxx.xx_Supply Duct - Riser',
              children: [
                {
                  id: 3931,
                  name: 'Rectangular Duct [865744]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 12.838093376993193,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5349.205573747164,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Supply Duct - Riser',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Duct',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ducts',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3947,
              name: 'xx.xx.xx.xxx.xx_Supply Duct - Main',
              children: [
                {
                  id: 3948,
                  name: 'Rectangular Duct [866152]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.3568487752661693,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 982.0203230275705,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3949,
                  name: 'Rectangular Duct [866478]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 9.605808775266311,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4002.42032302763,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3950,
                  name: 'Rectangular Duct [866712]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.0715783056394934,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 863.1576273497888,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3963,
                  name: 'Rectangular Duct [866818]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.2568000000001573,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1128.4000000000788,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3964,
                  name: 'Rectangular Duct [867389]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 12.538400000000108,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6269.200000000053,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3979,
                  name: 'Rectangular Duct [867709]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 30.42920001107236,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 15214.60000553618,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3982,
                  name: 'Rectangular Duct [867874]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.330541162164434,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3331.5882263527715,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3985,
                  name: 'Rectangular Duct [867948]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.4004389842329323,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1714.5992744520947,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3988,
                  name: 'Rectangular Duct [867966]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.529014722113058,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3949.2962300807562,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3991,
                  name: 'Rectangular Duct [868048]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.1535843237291625,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1538.274516949402,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3994,
                  name: 'Rectangular Duct [868063]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.329144323240483,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1663.6745166003452,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3997,
                  name: 'Rectangular Duct [868111]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 9.249270580079452,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7707.725483399543,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4098,
                  name: 'Rectangular Duct [872504]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 8.084120000000079,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3674.6000000000354,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4103,
                  name: 'Rectangular Duct [872841]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 14.024120000000032,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6374.600000000015,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4106,
                  name: 'Rectangular Duct [872918]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 29.95199999999998,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 16639.99999999999,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4109,
                  name: 'Rectangular Duct [873060]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.0544590593022725,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1467.4707566444804,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4112,
                  name: 'Rectangular Duct [873135]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 11.913440000000042,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 8509.60000000003,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4115,
                  name: 'Rectangular Duct [873362]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.002979999999992,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7694.599999999993,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4118,
                  name: 'Rectangular Duct [873431]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.778980000000006,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5214.600000000005,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4171,
                  name: 'Rectangular Duct [876977]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.011170727908013,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2504.654469961672,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4172,
                  name: 'Rectangular Duct [876978]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.8260799999999477,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1594.1999999999782,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4173,
                  name: 'Rectangular Duct [876980]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.52207999998658,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1884.1999999944085,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4178,
                  name: 'Rectangular Duct [877126]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.25408000000024,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2189.2000000001,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4181,
                  name: 'Rectangular Duct [877137]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.41674858301818,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 590.3119095909083,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4308,
                  name: 'Rectangular Duct [883523]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7573049311713139,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 315.54372132138076,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4309,
                  name: 'Rectangular Duct [883524]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.562079999986591,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1484.1999999944128,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4311,
                  name: 'Rectangular Duct [883556]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.3220799999995605,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1384.1999999998166,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4312,
                  name: 'Rectangular Duct [883557]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.25408000000024,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2189.2000000001,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4314,
                  name: 'Rectangular Duct [883559]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.41674858301818,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 590.3119095909083,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4386,
                  name: 'Rectangular Duct [886241]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 11.67582152254931,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4864.925634395546,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4387,
                  name: 'Rectangular Duct [886332]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.668532507959935,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1945.2218783166397,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4388,
                  name: 'Rectangular Duct [886378]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.0191999999999375,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1257.9999999999739,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4392,
                  name: 'Rectangular Duct [886538]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 14.155457490061114,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5898.107287525464,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4393,
                  name: 'Rectangular Duct [886639]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.04172675298188,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 434.05281374245,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4402,
                  name: 'Rectangular Duct [886807]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.7260310592809285,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1969.1796080337201,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4405,
                  name: 'Rectangular Duct [886870]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.9180310597201835,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2049.179608216743,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4408,
                  name: 'Rectangular Duct [886884]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 19.030080000000158,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7929.200000000065,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4411,
                  name: 'Rectangular Duct [886907]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.91468135758025,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2047.7838989917705,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4414,
                  name: 'Rectangular Duct [886939]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.0564550527817342,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1273.522938659056,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4455,
                  name: 'Rectangular Duct [888035]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.057860274445712,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 550,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 350,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5587.70015246984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4474,
                  name: 'Rectangular Duct [889687]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.6772183732260805,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 677.2183732260804,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4476,
                  name: 'Rectangular Duct [889816]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.9820799999929696,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 818.3999999941414,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4478,
                  name: 'Rectangular Duct [889863]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.2172492800000034,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 304.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 304.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 998.4000000000027,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4512,
                  name: 'Rectangular Duct [892952]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.2254539525274447,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 225.4539525274447,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4513,
                  name: 'Rectangular Duct [892989]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.2795743180415878,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 254.00000000000003,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 254.00000000000003,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 275.1715728755785,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4542,
                  name: 'Rectangular Duct [893875]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.043000000000067,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 9130.00000000006,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4545,
                  name: 'Rectangular Duct [893896]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.079000000000047,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1890.0000000000425,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4600,
                  name: 'Rectangular Duct [894774]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.862000000000013,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4420.000000000012,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Supply Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Duct',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ducts',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4005,
              name: 'xx.xx.xx.xxx.xx_Extract Duct - Main',
              children: [
                {
                  id: 4006,
                  name: 'Rectangular Duct [868648]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.8588424995467894,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 929.4212497733947,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4011,
                  name: 'Rectangular Duct [869112]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.687839910385534,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 500,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5937.688839103074,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4012,
                  name: 'Rectangular Duct [869266]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 25.24425315883373,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 500,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 14024.58508824096,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4013,
                  name: 'Rectangular Duct [869428]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.669200000000003,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2334.6000000000013,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4018,
                  name: 'Rectangular Duct [869559]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.664283385505618,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 500,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5924.601880836454,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4027,
                  name: 'Rectangular Duct [869789]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.1671471822588706,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2639.2893185490584,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4030,
                  name: 'Rectangular Duct [869803]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.4030598119810187,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2403.059811981019,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4031,
                  name: 'Rectangular Duct [869856]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.9181645916341843,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1918.1645916341843,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4034,
                  name: 'Rectangular Duct [869971]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.6093587190474227,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 609.3587190474226,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4035,
                  name: 'Rectangular Duct [870107]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.27647922061343655,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 276.47922061343655,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4040,
                  name: 'Rectangular Duct [870185]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.584955642557484,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1584.955642557484,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4045,
                  name: 'Rectangular Duct [870248]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.6581786429570151,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 658.1786429570151,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4048,
                  name: 'Rectangular Duct [870305]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.4831786429655995,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2483.1786429655995,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4049,
                  name: 'Rectangular Duct [870331]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.19162641057693572,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 191.62641057693568,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4058,
                  name: 'Rectangular Duct [870494]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 7.521132189627061,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 550,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 350,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4178.406772015034,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4099,
                  name: 'Rectangular Duct [872505]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 9.849759425845049,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 350,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5184.083908339499,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4121,
                  name: 'Rectangular Duct [873789]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 7.354295576419879,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 350,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3870.6818823262524,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4124,
                  name: 'Rectangular Duct [873832]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.556000000000013,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7540.000000000008,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4127,
                  name: 'Rectangular Duct [873991]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 14.923999999999978,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 11479.999999999982,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4130,
                  name: 'Rectangular Duct [874230]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 11.133066058951187,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 8563.89696842399,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4133,
                  name: 'Rectangular Duct [874352]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 13.614404003414629,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 12376.730912195117,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4134,
                  name: 'Rectangular Duct [874489]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.1089136584741675,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2826.2851440674244,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4141,
                  name: 'Rectangular Duct [874547]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.5890600000000272,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1444.6000000000245,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4161,
                  name: 'Rectangular Duct [876059]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.209308939923553,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2604.654469961776,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4162,
                  name: 'Rectangular Duct [876154]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.2826708634234354,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1141.3354317117175,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4165,
                  name: 'Rectangular Duct [876473]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.8683999999888137,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1434.1999999944069,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4184,
                  name: 'Rectangular Duct [877424]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.078400000000165,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2039.2000000000821,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4187,
                  name: 'Rectangular Duct [877435]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.2463529557583794,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 123.17647787918969,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4306,
                  name: 'Rectangular Duct [883520]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.8310874426430096,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 415.5437213215048,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4307,
                  name: 'Rectangular Duct [883521]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.145535154224548,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1572.7675771122738,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4310,
                  name: 'Rectangular Duct [883555]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.862670863423244,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 931.3354317116219,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4316,
                  name: 'Rectangular Duct [883561]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.078400000000165,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2039.2000000000821,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4318,
                  name: 'Rectangular Duct [883563]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.2463529557583794,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 123.17647787918969,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4376,
                  name: 'Rectangular Duct [885828]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 21.45703323980401,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 10728.516619902002,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4379,
                  name: 'Rectangular Duct [885965]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.0384000000000784,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1019.2000000000392,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4382,
                  name: 'Rectangular Duct [886036]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.595999999999949,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 797.9999999999745,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4419,
                  name: 'Rectangular Duct [887085]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.138399993503027,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2069.199996751514,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4422,
                  name: 'Rectangular Duct [887188]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.458145109489098,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1229.0725547445488,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4425,
                  name: 'Rectangular Duct [887199]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 12.609754122682178,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6304.877061341089,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4428,
                  name: 'Rectangular Duct [887231]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.2127145750509534,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 606.3572875254766,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4431,
                  name: 'Rectangular Duct [887297]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.4760856279950961,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 238.04281399754802,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4434,
                  name: 'Rectangular Duct [887312]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.137353425723794,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2068.6767128618967,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4503,
                  name: 'Rectangular Duct [892558]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7780799995793938,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 648.3999996494947,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4505,
                  name: 'Rectangular Duct [892586]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.6439692795726102,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 304.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 304.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1348.3999996494506,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4557,
                  name: 'Rectangular Duct [894149]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.5282400000000544,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2298.400000000049,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4561,
                  name: 'Rectangular Duct [894254]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.8039999999999963,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1639.9999999999964,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Extract Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Duct',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ducts',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4063,
              name: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main',
              children: [
                {
                  id: 4064,
                  name: 'Rectangular Duct [870943]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.1275200000000172,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 939.6000000000143,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4067,
                  name: 'Rectangular Duct [871149]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.749185941912832,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3957.6549515940264,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4072,
                  name: 'Rectangular Duct [871334]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.1813594361200432,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 984.4661967667025,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4075,
                  name: 'Rectangular Duct [871380]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.211520292269906,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 350,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 325,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3119.6446609406707,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4146,
                  name: 'Rectangular Duct [874682]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.1932953763035155,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 350,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 350,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4423.7824116453685,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4152,
                  name: 'Rectangular Duct [875173]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.4709869044016064,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1900.7591572320048,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4158,
                  name: 'Rectangular Duct [875642]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.3858523367665967,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 320,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1332.5503238140352,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4160,
                  name: 'Rectangular Duct [875721]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.724600000000004,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2724.6000000000045,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4168,
                  name: 'Rectangular Duct [876562]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 20.836848775265857,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 8682.02032302744,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4190,
                  name: 'Rectangular Duct [877799]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 29.398079999998462,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 12249.199999999357,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4193,
                  name: 'Rectangular Duct [878117]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.022080000000122,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2509.2000000000507,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4194,
                  name: 'Rectangular Duct [878286]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.2990399999999729,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 124.5999999999887,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4241,
                  name: 'Rectangular Duct [881017]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.6700800000002689,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 279.200000000112,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4242,
                  name: 'Rectangular Duct [881076]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.026080000000281,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 844.200000000117,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4243,
                  name: 'Rectangular Duct [881091]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.07791624461242484,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 32.46510192184368,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4246,
                  name: 'Rectangular Duct [881246]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.36703521924771104,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 70.5836960091752,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4252,
                  name: 'Rectangular Duct [882019]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.884905748344505,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2035.3773951435442,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4255,
                  name: 'Rectangular Duct [882029]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 12.039783250074223,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5016.576354197593,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4260,
                  name: 'Rectangular Duct [882148]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.1632985175008232,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1318.0410489586761,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4261,
                  name: 'Rectangular Duct [882149]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.002079999999628,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 834.199999999845,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4262,
                  name: 'Rectangular Duct [882151]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.07791624461243653,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 32.46510192184855,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4264,
                  name: 'Rectangular Duct [882153]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.36703521924771104,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 70.5836960091752,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4294,
                  name: 'Rectangular Duct [883316]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.1660800000000454,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 69.20000000001892,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4297,
                  name: 'Rectangular Duct [883329]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.094079999999803,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1289.199999999918,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4300,
                  name: 'Rectangular Duct [883344]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.750080000000542,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1979.2000000002258,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4303,
                  name: 'Rectangular Duct [883355]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.6384564530894563,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 266.02352212060674,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Duct',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ducts',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4095,
              name: 'xx.xx.xx.xxx.xx_Vehicle Lock Extract Duct - Main',
              children: [
                {
                  id: 4096,
                  name: 'Rectangular Duct [872206]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.947730819306817,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 350,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3130.3846417404297,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4097,
                  name: 'Rectangular Duct [872421]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.8355669426881306,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 350,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 439.7720750990161,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4278,
                  name: 'Rectangular Duct [882290]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.4469799999999373,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 350,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1814.199999999967,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4283,
                  name: 'Rectangular Duct [882450]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 13.393479995945606,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 350,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7049.199997866109,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4286,
                  name: 'Rectangular Duct [882754]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.017739999999983,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 350,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2114.599999999991,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4289,
                  name: 'Rectangular Duct [882799]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.30927117160457923,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 96.647241126431,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Vehicle Lock Extract Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Duct',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ducts',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4198,
              name: 'xx.xx.xx.xxx.xx_Fresh Air Intake Duct - Main',
              children: [
                {
                  id: 4199,
                  name: 'Rectangular Duct [878450]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5020800012945634,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 209.20000053940146,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4202,
                  name: 'Rectangular Duct [878580]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.7752271939952897,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 739.6779974980374,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4207,
                  name: 'Rectangular Duct [878711]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.438079999999802,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1849.1999999999175,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4210,
                  name: 'Rectangular Duct [878748]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.04895030563875026,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 20.395960682812607,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4217,
                  name: 'Rectangular Duct [879557]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.790200175349111,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 151.96157218252134,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4218,
                  name: 'Rectangular Duct [880093]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.1942671939953144,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 914.2779974980476,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4221,
                  name: 'Rectangular Duct [880148]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 7.462079999999509,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3109.1999999997956,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4224,
                  name: 'Rectangular Duct [880245]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.289157414280763,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 953.8155892836511,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4227,
                  name: 'Rectangular Duct [880301]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.04888390932154712,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 20.368295550644635,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4228,
                  name: 'Rectangular Duct [880303]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7902001753491601,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 151.96157218253077,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4229,
                  name: 'Rectangular Duct [880326]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.756012193054123,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2398.338413772551,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4230,
                  name: 'Rectangular Duct [880371]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.9420799999997667,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 809.1999999999026,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Fresh Air Intake Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Duct',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ducts',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4344,
              name: 'xx.xx.xx.xxx.xx_Toilet Extract Duct - Main',
              children: [
                {
                  id: 4345,
                  name: 'Rectangular Duct [884126]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.41009629453174734,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 227.83127473985962,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Toilet Extract Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Duct',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ducts',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4438,
              name: 'xx.xx.xx.xxx.xx_Extract Duct - Riser',
              children: [
                {
                  id: 4439,
                  name: 'Rectangular Duct [887327]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 12.488400000000142,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6244.200000000071,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Extract Duct - Riser',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Duct',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ducts',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Rectangular Duct',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Ducts',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3940,
          name: 'Round Duct',
          children: [
            {
              id: 3942,
              name: 'xx.xx.xx.xxx.xx_Toilet Extract Duct - Riser',
              children: [
                {
                  id: 3943,
                  name: 'Round Duct [866024]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.7329400055461175,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6026.166377920383,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5388,
                  name: 'Round Duct [943525]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.7329400055461175,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6026.166377920383,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Toilet Extract Duct - Riser',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Round Duct',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ducts',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4086,
              name: 'xx.xx.xx.xxx.xx_Toilet Extract Duct - Main',
              children: [
                {
                  id: 4087,
                  name: 'Round Duct [871571]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.05452935437054176,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 69.428930333451,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4088,
                  name: 'Round Duct [871937]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.1245020319593038,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2704.999999960795,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4332,
                  name: 'Round Duct [883644]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.379756435094259,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3029.9999999999905,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4333,
                  name: 'Round Duct [883737]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.07068583470579758,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 90.00000000003476,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4336,
                  name: 'Round Duct [883748]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.1688606051305318,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 215.00000000010257,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4346,
                  name: 'Round Duct [884257]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.421532411075939,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5629.669914110099,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4349,
                  name: 'Round Duct [884438]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.009057862809628807,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 11.532829120005355,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4352,
                  name: 'Round Duct [884703]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5694136684590193,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 724.9999999947413,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4355,
                  name: 'Round Duct [885048]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.185951226730159,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1510.0000000000168,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4358,
                  name: 'Round Duct [885255]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.9085175370558352,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2430.0000000000477,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4361,
                  name: 'Round Duct [885314]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.427590572715939,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6910.62294981371,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4362,
                  name: 'Round Duct [885323]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.02153392100556062,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 27.417839777482985,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4369,
                  name: 'Round Duct [885550]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.07068583470578664,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 90.00000000002082,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4442,
                  name: 'Round Duct [887747]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.7996541950379286,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3818.9848132847,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4443,
                  name: 'Round Duct [887865]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.0235528111778982,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 49.980617635633976,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5389,
                  name: 'Round Duct [943526]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.05452935437054176,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 69.428930333451,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5390,
                  name: 'Round Duct [943527]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.1245020319593038,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2704.999999960795,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5391,
                  name: 'Round Duct [943528]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.379756435094259,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3029.9999999999905,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5392,
                  name: 'Round Duct [943529]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.07068583470579758,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 90.00000000003476,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5393,
                  name: 'Round Duct [943530]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.1688606051305318,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 215.00000000010257,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5394,
                  name: 'Round Duct [943531]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.421532411075939,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5629.669914110099,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5395,
                  name: 'Round Duct [943532]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.009057862809628807,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 11.532829120005355,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5396,
                  name: 'Round Duct [943533]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5694136684590193,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 724.9999999947413,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5397,
                  name: 'Round Duct [943534]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.185951226730159,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1510.0000000000168,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5398,
                  name: 'Round Duct [943535]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.9085175370558352,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2430.0000000000477,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5399,
                  name: 'Round Duct [943536]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.427590572715939,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6910.62294981371,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5400,
                  name: 'Round Duct [943537]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.02153392100556062,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 27.417839777482985,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5401,
                  name: 'Round Duct [943538]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.07068583470578664,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 90.00000000002082,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5402,
                  name: 'Round Duct [943539]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.7996541950379286,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3818.9848132847,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5403,
                  name: 'Round Duct [943540]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.0235528111778982,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 49.980617635633976,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Toilet Extract Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Round Duct',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ducts',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4451,
              name: 'xx.xx.xx.xxx.xx_Extract Duct - Main',
              children: [
                {
                  id: 4452,
                  name: 'Round Duct [887968]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.068217410041518,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6438.578643762714,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4463,
                  name: 'Round Duct [889272]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.45418112262198,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4398.000000000006,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4464,
                  name: 'Round Duct [889343]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.3758294442772785,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3024.999999999999,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4519,
                  name: 'Round Duct [893307]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.4971036935363572,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 527.4434003703332,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4528,
                  name: 'Round Duct [893434]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.4327471716681406,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2581.2249177587505,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4529,
                  name: 'Round Duct [893479]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.33929200658768377,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 359.9999999999856,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4551,
                  name: 'Round Duct [893953]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7641924129856945,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 972.999999999972,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4553,
                  name: 'Round Duct [893989]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.2982631640959772,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1652.9999999999952,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4555,
                  name: 'Round Duct [894066]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.4553427967754051,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1852.9999999999168,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4559,
                  name: 'Round Duct [894204]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.9936546979680825,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3173.0000000000023,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4564,
                  name: 'Round Duct [894298]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.0967431076975067,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 123.17715040103836,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4569,
                  name: 'Round Duct [894323]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.08639379797370335,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 109.99999999997976,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4570,
                  name: 'Round Duct [894360]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.24347343065322188,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 310.0000000000167,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4573,
                  name: 'Round Duct [894371]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 9.888162877173988,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 12590.000000000156,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4577,
                  name: 'Round Duct [894529]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.08639379797380208,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 110.00000000010547,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4578,
                  name: 'Round Duct [894538]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.22776546738526554,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 290.0000000000073,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4581,
                  name: 'Round Duct [894562]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.0468966737836436,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1332.9502443130436,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4584,
                  name: 'Round Duct [894569]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.8050331174823959,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1025.0000000000155,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5404,
                  name: 'Round Duct [943541]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.068217410041518,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6438.578643762714,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5406,
                  name: 'Round Duct [943543]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.45418112262198,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4398.000000000006,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5407,
                  name: 'Round Duct [943544]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.3758294442772785,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3024.999999999999,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5412,
                  name: 'Round Duct [943549]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.4971036935363572,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 527.4434003703332,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5413,
                  name: 'Round Duct [943550]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.4327471716681406,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2581.2249177587505,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5414,
                  name: 'Round Duct [943551]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.33929200658768377,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 359.9999999999856,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5417,
                  name: 'Round Duct [943554]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7641924129856945,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 972.999999999972,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5418,
                  name: 'Round Duct [943555]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.2982631640959772,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1652.9999999999952,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5419,
                  name: 'Round Duct [943556]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.4553427967754051,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1852.9999999999168,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5420,
                  name: 'Round Duct [943557]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.9936546979680825,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3173.0000000000023,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5421,
                  name: 'Round Duct [943558]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.0967431076975067,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 123.17715040103836,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5422,
                  name: 'Round Duct [943559]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.08639379797370335,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 109.99999999997976,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5423,
                  name: 'Round Duct [943560]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.24347343065322188,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 310.0000000000167,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5424,
                  name: 'Round Duct [943561]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 9.888162877173988,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 12590.000000000156,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5426,
                  name: 'Round Duct [943563]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.08639379797380208,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 110.00000000010547,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5427,
                  name: 'Round Duct [943564]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.22776546738526554,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 290.0000000000073,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5428,
                  name: 'Round Duct [943565]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.0468966737836436,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1332.9502443130436,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5429,
                  name: 'Round Duct [943566]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.8050331174823959,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1025.0000000000155,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Extract Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Round Duct',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ducts',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4459,
              name: 'xx.xx.xx.xxx.xx_Supply Duct - Main',
              children: [
                {
                  id: 4460,
                  name: 'Round Duct [889068]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.181953382555678,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5498.2233047033615,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4473,
                  name: 'Round Duct [889542]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.9211708376144816,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1172.8711379075721,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4480,
                  name: 'Round Duct [889901]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.203056319382152,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3490.4638564286383,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4483,
                  name: 'Round Duct [889950]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.1875220230569201,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1259.9999999999784,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4502,
                  name: 'Round Duct [892503]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.031736777659801,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 33.67376694910512,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5405,
                  name: 'Round Duct [943542]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.181953382555678,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5498.2233047033615,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5408,
                  name: 'Round Duct [943545]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.9211708376144816,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1172.8711379075721,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5409,
                  name: 'Round Duct [943546]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.203056319382152,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3490.4638564286383,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5410,
                  name: 'Round Duct [943547]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.1875220230569201,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1259.9999999999784,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5411,
                  name: 'Round Duct [943548]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.031736777659801,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 33.67376694910512,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Supply Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Round Duct',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ducts',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4535,
              name: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main',
              children: [
                {
                  id: 4536,
                  name: 'Round Duct [893662]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.9448339905671512,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1203.0000000000277,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4540,
                  name: 'Round Duct [893764]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.9251990364824305,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1178.000000000302,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4574,
                  name: 'Round Duct [894424]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.10773070779711727,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 114.30583112466546,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4587,
                  name: 'Round Duct [894615]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.2062572382865421,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 218.84572681190755,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4588,
                  name: 'Round Duct [894631]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ducts',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.9466478624876349,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2065.457531827863,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5415,
                  name: 'Round Duct [943552]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.9448339905671512,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1203.0000000000277,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5416,
                  name: 'Round Duct [943553]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.9251990364824305,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1178.000000000302,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5425,
                  name: 'Round Duct [943562]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.10773070779711727,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 114.30583112466546,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5430,
                  name: 'Round Duct [943567]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.2062572382865421,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 218.84572681190755,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5431,
                  name: 'Round Duct [943568]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.9466478624876349,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2065.457531827863,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Round Duct',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ducts',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Round Duct',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Ducts',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Ducts',
          displayCategory: '__category__',
        },
      ],
    },
    {
      id: 3951,
      name: 'Duct Fittings',
      children: [
        {
          id: 3952,
          name: 'Rectangular Elbow - Mitered',
          children: [
            {
              id: 3954,
              name: 'xx.xx.xx.xxx.xx_Supply Duct - Riser',
              children: [
                {
                  id: 3955,
                  name: 'Rectangular Elbow - Mitered [866732]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4417,
                  name: 'Rectangular Elbow - Mitered [886993]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Supply Duct - Riser',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Elbow - Mitered',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3958,
              name: 'xx.xx.xx.xxx.xx_Supply Duct - Main',
              children: [
                {
                  id: 3959,
                  name: 'Rectangular Elbow - Mitered [866768]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3961,
                  name: 'Rectangular Elbow - Mitered [866779]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3980,
                  name: 'Rectangular Elbow - Mitered [867869]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3989,
                  name: 'Rectangular Elbow - Mitered [867975]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3992,
                  name: 'Rectangular Elbow - Mitered [868057]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3995,
                  name: 'Rectangular Elbow - Mitered [868109]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 406.4,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 304.8,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4104,
                  name: 'Rectangular Elbow - Mitered [872896]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4113,
                  name: 'Rectangular Elbow - Mitered [873207]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4119,
                  name: 'Rectangular Elbow - Mitered [873496]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4174,
                  name: 'Rectangular Elbow - Mitered [877059]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4175,
                  name: 'Rectangular Elbow - Mitered [877061]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4179,
                  name: 'Rectangular Elbow - Mitered [877133]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4182,
                  name: 'Rectangular Elbow - Mitered [877398]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4313,
                  name: 'Rectangular Elbow - Mitered [883558]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4315,
                  name: 'Rectangular Elbow - Mitered [883560]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4324,
                  name: 'Rectangular Elbow - Mitered [883583]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4325,
                  name: 'Rectangular Elbow - Mitered [883584]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4390,
                  name: 'Rectangular Elbow - Mitered [886514]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4394,
                  name: 'Rectangular Elbow - Mitered [886686]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4395,
                  name: 'Rectangular Elbow - Mitered [886688]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4398,
                  name: 'Rectangular Elbow - Mitered [886710]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4399,
                  name: 'Rectangular Elbow - Mitered [886712]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4403,
                  name: 'Rectangular Elbow - Mitered [886866]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4406,
                  name: 'Rectangular Elbow - Mitered [886881]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4409,
                  name: 'Rectangular Elbow - Mitered [886905]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4412,
                  name: 'Rectangular Elbow - Mitered [886937]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4415,
                  name: 'Rectangular Elbow - Mitered [886962]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Supply Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Elbow - Mitered',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4008,
              name: 'xx.xx.xx.xxx.xx_Extract Duct - Riser',
              children: [
                {
                  id: 4009,
                  name: 'Rectangular Elbow - Mitered [868945]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4440,
                  name: 'Rectangular Elbow - Mitered [887495]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Extract Duct - Riser',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Elbow - Mitered',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4015,
              name: 'xx.xx.xx.xxx.xx_Extract Duct - Main',
              children: [
                {
                  id: 4016,
                  name: 'Rectangular Elbow - Mitered [869555]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4019,
                  name: 'Rectangular Elbow - Mitered [869731]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 500,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4021,
                  name: 'Rectangular Elbow - Mitered [869744]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 500,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4036,
                  name: 'Rectangular Elbow - Mitered [870155]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4038,
                  name: 'Rectangular Elbow - Mitered [870162]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4041,
                  name: 'Rectangular Elbow - Mitered [870231]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4042,
                  name: 'Rectangular Elbow - Mitered [870233]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4046,
                  name: 'Rectangular Elbow - Mitered [870286]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4050,
                  name: 'Rectangular Elbow - Mitered [870350]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4052,
                  name: 'Rectangular Elbow - Mitered [870401]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4122,
                  name: 'Rectangular Elbow - Mitered [873822]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 350,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4135,
                  name: 'Rectangular Elbow - Mitered [874516]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4136,
                  name: 'Rectangular Elbow - Mitered [874517]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4142,
                  name: 'Rectangular Elbow - Mitered [874576]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4163,
                  name: 'Rectangular Elbow - Mitered [876162]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4166,
                  name: 'Rectangular Elbow - Mitered [876480]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4185,
                  name: 'Rectangular Elbow - Mitered [877431]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4188,
                  name: 'Rectangular Elbow - Mitered [877742]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4317,
                  name: 'Rectangular Elbow - Mitered [883562]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4319,
                  name: 'Rectangular Elbow - Mitered [883564]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4328,
                  name: 'Rectangular Elbow - Mitered [883596]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4329,
                  name: 'Rectangular Elbow - Mitered [883597]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4377,
                  name: 'Rectangular Elbow - Mitered [885961]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4380,
                  name: 'Rectangular Elbow - Mitered [885974]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4384,
                  name: 'Rectangular Elbow - Mitered [886226]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4420,
                  name: 'Rectangular Elbow - Mitered [887092]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4423,
                  name: 'Rectangular Elbow - Mitered [887197]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4426,
                  name: 'Rectangular Elbow - Mitered [887229]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4429,
                  name: 'Rectangular Elbow - Mitered [887238]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4432,
                  name: 'Rectangular Elbow - Mitered [887308]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4435,
                  name: 'Rectangular Elbow - Mitered [887323]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Extract Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Elbow - Mitered',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4069,
              name: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main',
              children: [
                {
                  id: 4070,
                  name: 'Rectangular Elbow - Mitered [871251]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4073,
                  name: 'Rectangular Elbow - Mitered [871367]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4195,
                  name: 'Rectangular Elbow - Mitered [878337]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4239,
                  name: 'Rectangular Elbow - Mitered [881013]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4244,
                  name: 'Rectangular Elbow - Mitered [881144]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4253,
                  name: 'Rectangular Elbow - Mitered [882026]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4256,
                  name: 'Rectangular Elbow - Mitered [882038]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4258,
                  name: 'Rectangular Elbow - Mitered [882092]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4263,
                  name: 'Rectangular Elbow - Mitered [882152]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4268,
                  name: 'Rectangular Elbow - Mitered [882207]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4270,
                  name: 'Rectangular Elbow - Mitered [882210]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4271,
                  name: 'Rectangular Elbow - Mitered [882212]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4295,
                  name: 'Rectangular Elbow - Mitered [883325]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4298,
                  name: 'Rectangular Elbow - Mitered [883340]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4301,
                  name: 'Rectangular Elbow - Mitered [883351]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4304,
                  name: 'Rectangular Elbow - Mitered [883479]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5169,
                  name: 'Rectangular Elbow - Mitered [937938]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Elbow - Mitered',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4204,
              name: 'xx.xx.xx.xxx.xx_Fresh Air Intake Duct - Main',
              children: [
                {
                  id: 4205,
                  name: 'Rectangular Elbow - Mitered [878589]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4208,
                  name: 'Rectangular Elbow - Mitered [878726]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4211,
                  name: 'Rectangular Elbow - Mitered [878789]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4222,
                  name: 'Rectangular Elbow - Mitered [880212]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4225,
                  name: 'Rectangular Elbow - Mitered [880282]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4231,
                  name: 'Rectangular Elbow - Mitered [880535]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4232,
                  name: 'Rectangular Elbow - Mitered [880537]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4235,
                  name: 'Rectangular Elbow - Mitered [880765]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Fresh Air Intake Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Elbow - Mitered',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4275,
              name: 'xx.xx.xx.xxx.xx_Vehicle Lock Extract Duct - Main',
              children: [
                {
                  id: 4276,
                  name: 'Rectangular Elbow - Mitered [882250]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 350,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4279,
                  name: 'Rectangular Elbow - Mitered [882384]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 350,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4284,
                  name: 'Rectangular Elbow - Mitered [882460]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 350,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4287,
                  name: 'Rectangular Elbow - Mitered [882761]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 350,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Vehicle Lock Extract Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Elbow - Mitered',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Rectangular Elbow - Mitered',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Duct Fittings',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3965,
          name: 'Rectangular Tap - Beveled',
          children: [
            {
              id: 3967,
              name: 'xx.xx.xx.xxx.xx_Supply Duct - Main',
              children: [
                {
                  id: 3968,
                  name: 'Rectangular Tap - Beveled [867478]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4389,
                  name: 'Rectangular Tap - Beveled [886420]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4475,
                  name: 'Rectangular Tap - Beveled [889700]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4477,
                  name: 'Rectangular Tap - Beveled [889829]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4479,
                  name: 'Rectangular Tap - Beveled [889872]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Supply Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Tap - Beveled',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4060,
              name: 'xx.xx.xx.xxx.xx_Extract Duct - Main',
              children: [
                {
                  id: 4061,
                  name: 'Rectangular Tap - Beveled [870578]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4383,
                  name: 'Rectangular Tap - Beveled [886199]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4504,
                  name: 'Rectangular Tap - Beveled [892569]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4506,
                  name: 'Rectangular Tap - Beveled [892597]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4558,
                  name: 'Rectangular Tap - Beveled [894162]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Extract Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Tap - Beveled',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4154,
              name: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main',
              children: [
                {
                  id: 4155,
                  name: 'Rectangular Tap - Beveled [875440]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4159,
                  name: 'Rectangular Tap - Beveled [875689]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Tap - Beveled',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Rectangular Tap - Beveled',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Duct Fittings',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3969,
          name: 'Rectangular Transition - Angle1',
          children: [
            {
              id: 3971,
              name: 'xx.xx.xx.xxx.xx_Supply Duct - Main',
              children: [
                {
                  id: 3972,
                  name: 'Rectangular Transition - Angle1 [867480]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3983,
                  name: 'Rectangular Transition - Angle1 [867944]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3986,
                  name: 'Rectangular Transition - Angle1 [867963]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3998,
                  name: 'Rectangular Transition - Angle1 [868338]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4000,
                  name: 'Rectangular Transition - Angle1 [868575]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4002,
                  name: 'Rectangular Transition - Angle1 [868584]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4107,
                  name: 'Rectangular Transition - Angle1 [873049]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4110,
                  name: 'Rectangular Transition - Angle1 [873076]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4116,
                  name: 'Rectangular Transition - Angle1 [873428]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4514,
                  name: 'Rectangular Transition - Angle1 [892998]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4543,
                  name: 'Rectangular Transition - Angle1 [893894]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4601,
                  name: 'Rectangular Transition - Angle1 [894783]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Supply Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Transition - Angle1',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4024,
              name: 'xx.xx.xx.xxx.xx_Extract Duct - Main',
              children: [
                {
                  id: 4025,
                  name: 'Rectangular Transition - Angle1 [869750]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4028,
                  name: 'Rectangular Transition - Angle1 [869800]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4032,
                  name: 'Rectangular Transition - Angle1 [869910]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4125,
                  name: 'Rectangular Transition - Angle1 [873917]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4128,
                  name: 'Rectangular Transition - Angle1 [874101]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4131,
                  name: 'Rectangular Transition - Angle1 [874349]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4137,
                  name: 'Rectangular Transition - Angle1 [874518]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4562,
                  name: 'Rectangular Transition - Angle1 [894267]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Extract Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Transition - Angle1',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4082,
              name: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main',
              children: [
                {
                  id: 4083,
                  name: 'Rectangular Transition - Angle1 [871483]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4156,
                  name: 'Rectangular Transition - Angle1 [875615]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5170,
                  name: 'Rectangular Transition - Angle1 [937939]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Transition - Angle1',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4214,
              name: 'xx.xx.xx.xxx.xx_Fresh Air Intake Duct - Main',
              children: [
                {
                  id: 4215,
                  name: 'Rectangular Transition - Angle1 [879314]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4236,
                  name: 'Rectangular Transition - Angle1 [880768]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Fresh Air Intake Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Transition - Angle1',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4249,
              name: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main 60 degree',
              children: [
                {
                  id: 4250,
                  name: 'Rectangular Transition - Angle1 [881628]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4265,
                  name: 'Rectangular Transition - Angle1 [882154]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main 60 degree',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Transition - Angle1',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4291,
              name: 'xx.xx.xx.xxx.xx_Vehicle Lock Extract Duct - Main',
              children: [
                {
                  id: 4292,
                  name: 'Rectangular Transition - Angle1 [883007]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Vehicle Lock Extract Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Transition - Angle1',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4340,
              name: 'xx.xx.xx.xxx.xx_Toilet Extract Duct - Main',
              children: [
                {
                  id: 4341,
                  name: 'Rectangular Transition - Angle1 [883874]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Toilet Extract Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Transition - Angle1',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Rectangular Transition - Angle1',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Duct Fittings',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3974,
          name: 'Rectangular to Round Transition - Angle1',
          children: [
            {
              id: 3976,
              name: 'xx.xx.xx.xxx.xx_Supply Duct - Main',
              children: [
                {
                  id: 3977,
                  name: 'Rectangular to Round Transition - Angle1 [867521]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4461,
                  name: 'Rectangular to Round Transition - Angle1 [889083]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4481,
                  name: 'Rectangular to Round Transition - Angle1 [889912]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4516,
                  name: 'Rectangular to Round Transition - Angle1 [893093]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Supply Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular to Round Transition - Angle1',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4055,
              name: 'xx.xx.xx.xxx.xx_Extract Duct - Main',
              children: [
                {
                  id: 4056,
                  name: 'Rectangular to Round Transition - Angle1 [870472]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4144,
                  name: 'Rectangular to Round Transition - Angle1 [874646]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4453,
                  name: 'Rectangular to Round Transition - Angle1 [887983]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4565,
                  name: 'Rectangular to Round Transition - Angle1 [894312]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Extract Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular to Round Transition - Angle1',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5177,
              name: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main',
              children: [
                {
                  id: 5178,
                  name: 'Rectangular to Round Transition - Angle1 [938223]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular to Round Transition - Angle1',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Rectangular to Round Transition - Angle1',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Duct Fittings',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 4076,
          name: 'Rectangular Transition - Angle',
          children: [
            {
              id: 4078,
              name: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main',
              children: [
                {
                  id: 4079,
                  name: 'Rectangular Transition - Angle [871393]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Transition - Angle',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Rectangular Transition - Angle',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Duct Fittings',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 4089,
          name: 'Round Elbow',
          children: [
            {
              id: 4091,
              name: 'xx.xx.xx.xxx.xx_Toilet Extract Duct - Main',
              children: [
                {
                  id: 4092,
                  name: 'Round Elbow [872126]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4334,
                  name: 'Round Elbow [883745]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4337,
                  name: 'Round Elbow [883753]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4347,
                  name: 'Round Elbow [884264]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4350,
                  name: 'Round Elbow [884447]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4353,
                  name: 'Round Elbow [884710]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4356,
                  name: 'Round Elbow [885055]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4359,
                  name: 'Round Elbow [885262]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4363,
                  name: 'Round Elbow [885539]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4370,
                  name: 'Round Elbow [885551]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4371,
                  name: 'Round Elbow [885552]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4372,
                  name: 'Round Elbow [885554]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4448,
                  name: 'Round Elbow [887943]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5338,
                  name: 'Round Elbow [943480]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5341,
                  name: 'Round Elbow [943482]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5342,
                  name: 'Round Elbow [943483]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5343,
                  name: 'Round Elbow [943484]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5344,
                  name: 'Round Elbow [943485]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5345,
                  name: 'Round Elbow [943486]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5346,
                  name: 'Round Elbow [943487]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5347,
                  name: 'Round Elbow [943488]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5348,
                  name: 'Round Elbow [943489]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5350,
                  name: 'Round Elbow [943491]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5351,
                  name: 'Round Elbow [943492]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5352,
                  name: 'Round Elbow [943493]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5355,
                  name: 'Round Elbow [943495]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Toilet Extract Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Round Elbow',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4365,
              name: 'xx.xx.xx.xxx.xx_Toilet Extract Duct - Riser',
              children: [
                {
                  id: 4366,
                  name: 'Round Elbow [885540]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5349,
                  name: 'Round Elbow [943490]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Toilet Extract Duct - Riser',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Round Elbow',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4466,
              name: 'xx.xx.xx.xxx.xx_Extract Duct - Main',
              children: [
                {
                  id: 4467,
                  name: 'Round Elbow [889350]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4567,
                  name: 'Round Elbow [894321]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4571,
                  name: 'Round Elbow [894369]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4579,
                  name: 'Round Elbow [894560]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4582,
                  name: 'Round Elbow [894567]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4585,
                  name: 'Round Elbow [894584]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4595,
                  name: 'Round Elbow [894725]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4596,
                  name: 'Round Elbow [894726]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5356,
                  name: 'Round Elbow [943496]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5373,
                  name: 'Round Elbow [943511]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5374,
                  name: 'Round Elbow [943512]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5375,
                  name: 'Round Elbow [943513]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5376,
                  name: 'Round Elbow [943514]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5377,
                  name: 'Round Elbow [943515]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5380,
                  name: 'Round Elbow [943518]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5381,
                  name: 'Round Elbow [943519]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Extract Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Round Elbow',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4590,
              name: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main',
              children: [
                {
                  id: 4591,
                  name: 'Round Elbow [894699]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4592,
                  name: 'Round Elbow [894702]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5378,
                  name: 'Round Elbow [943516]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5379,
                  name: 'Round Elbow [943517]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Round Elbow',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Round Elbow',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Duct Fittings',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 4147,
          name: 'Round Transition - Angle',
          children: [
            {
              id: 4149,
              name: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main',
              children: [
                {
                  id: 4150,
                  name: 'Round Transition - Angle [875033]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5340,
                  name: 'Round Transition - Angle [943481]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Round Transition - Angle',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4525,
              name: 'xx.xx.xx.xxx.xx_Extract Duct - Main',
              children: [
                {
                  id: 4526,
                  name: 'Round Transition - Angle [893411]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4530,
                  name: 'Round Transition - Angle [893633]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4532,
                  name: 'Round Transition - Angle [893637]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5364,
                  name: 'Round Transition - Angle [943502]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5365,
                  name: 'Round Transition - Angle [943503]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5366,
                  name: 'Round Transition - Angle [943504]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Extract Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Round Transition - Angle',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Round Transition - Angle',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Duct Fittings',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 4444,
          name: 'Round Takeoff',
          children: [
            {
              id: 4446,
              name: 'Standard',
              children: [
                {
                  id: 4447,
                  name: 'Round Takeoff [887878]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5354,
                  name: 'Round Takeoff [943494]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'Standard',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Round Takeoff',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Round Takeoff',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Duct Fittings',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 4469,
          name: 'Round Takeoff - Shoe',
          children: [
            {
              id: 4471,
              name: 'xx.xx.xx.xxx.xx_Extract Duct - Main',
              children: [
                {
                  id: 4472,
                  name: 'Round Takeoff - Shoe [889408]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4518,
                  name: 'Round Takeoff - Shoe [893291]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4552,
                  name: 'Round Takeoff - Shoe [893977]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4554,
                  name: 'Round Takeoff - Shoe [894030]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4556,
                  name: 'Round Takeoff - Shoe [894105]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4560,
                  name: 'Round Takeoff - Shoe [894242]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5358,
                  name: 'Round Takeoff - Shoe [943497]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5362,
                  name: 'Round Takeoff - Shoe [943500]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5369,
                  name: 'Round Takeoff - Shoe [943507]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5370,
                  name: 'Round Takeoff - Shoe [943508]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5371,
                  name: 'Round Takeoff - Shoe [943509]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5372,
                  name: 'Round Takeoff - Shoe [943510]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Extract Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Round Takeoff - Shoe',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4485,
              name: 'xx.xx.xx.xxx.xx_Supply Duct - Main',
              children: [
                {
                  id: 4486,
                  name: 'Round Takeoff - Shoe [889959]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5359,
                  name: 'Round Takeoff - Shoe [943498]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Supply Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Round Takeoff - Shoe',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4538,
              name: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main',
              children: [
                {
                  id: 4539,
                  name: 'Round Takeoff - Shoe [893748]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4541,
                  name: 'Round Takeoff - Shoe [893814]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5367,
                  name: 'Round Takeoff - Shoe [943505]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5368,
                  name: 'Round Takeoff - Shoe [943506]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Exhaust Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Round Takeoff - Shoe',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Round Takeoff - Shoe',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Duct Fittings',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 4495,
          name: 'Round Pressed Twin Bend - Slip Joint',
          children: [
            {
              id: 4497,
              name: 'xx.xx.xx.xxx.xx_Supply Duct - Main',
              children: [
                {
                  id: 4498,
                  name: 'Round Pressed Twin Bend - Slip Joint [891836]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5361,
                  name: 'Round Pressed Twin Bend - Slip Joint [943499]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Supply Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Round Pressed Twin Bend - Slip Joint',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4521,
              name: 'xx.xx.xx.xxx.xx_Extract Duct - Main',
              children: [
                {
                  id: 4522,
                  name: 'Round Pressed Twin Bend - Slip Joint [893364]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 5363,
                  name: 'Round Pressed Twin Bend - Slip Joint [943501]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center line',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Extract Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Round Pressed Twin Bend - Slip Joint',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Round Pressed Twin Bend - Slip Joint',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Duct Fittings',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 4507,
          name: 'Rectangular Wye - Curved - Transition',
          children: [
            {
              id: 4509,
              name: 'xx.xx.xx.xxx.xx_Supply Duct - Main',
              children: [
                {
                  id: 4510,
                  name: 'Rectangular Wye - Curved - Transition [892644]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Supply Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Wye - Curved - Transition',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Rectangular Wye - Curved - Transition',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Duct Fittings',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 4546,
          name: 'Rectangular Elbow - Radius 1 W',
          children: [
            {
              id: 4548,
              name: 'xx.xx.xx.xxx.xx_Supply Duct - Main',
              children: [
                {
                  id: 4549,
                  name: 'Rectangular Elbow - Radius 1 W [893925]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Duct Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Supply Duct - Main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Rectangular Elbow - Radius 1 W',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Duct Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Rectangular Elbow - Radius 1 W',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Duct Fittings',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Duct Fittings',
          displayCategory: '__category__',
        },
      ],
    },
    {
      id: 4670,
      name: 'Cable Trays',
      children: [
        {
          id: 4671,
          name: 'Cable Tray with Fittings',
          children: [
            {
              id: 4673,
              name: 'xx.xx.xx.xxx.xx_Multi-Comparment Trunking (Lighting/Power/Fire)',
              children: [
                {
                  id: 4674,
                  name: 'Cable Tray with Fittings [906749]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2783.0380287665175,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4675,
                  name: 'Cable Tray with Fittings [906930]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 575.0000000053269,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4694,
                  name: 'Cable Tray with Fittings [907522]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 14111.607196657444,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4702,
                  name: 'Cable Tray with Fittings [907692]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1677.0447823080024,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4712,
                  name: 'Cable Tray with Fittings [907794]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2093.27793027028,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4717,
                  name: 'Cable Tray with Fittings [907863]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 26499.999999598545,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4721,
                  name: 'Cable Tray with Fittings [908137]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5946.280303652584,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4731,
                  name: 'Cable Tray with Fittings [908806]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 38,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 16105.284058506682,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4732,
                  name: 'Cable Tray with Fittings [909166]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2984.999999980974,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4733,
                  name: 'Cable Tray with Fittings [909323]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 149.32169521505347,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4741,
                  name: 'Cable Tray with Fittings [910128]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7759.290751089758,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4752,
                  name: 'Cable Tray with Fittings [910658]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 13.20753239681283,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4755,
                  name: 'Cable Tray with Fittings [910751]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7000.000000000003,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4756,
                  name: 'Cable Tray with Fittings [910924]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 854.7924042931191,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4757,
                  name: 'Cable Tray with Fittings [911049]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1332.5000000000002,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4761,
                  name: 'Cable Tray with Fittings [911187]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 11927.133362915149,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4762,
                  name: 'Cable Tray with Fittings [911359]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2686.8198900290554,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4763,
                  name: 'Cable Tray with Fittings [911392]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 11668.361526064537,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4764,
                  name: 'Cable Tray with Fittings [911424]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 687.3123092422161,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4782,
                  name: 'Cable Tray with Fittings [912614]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 640.1134013858554,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4800,
                  name: 'Cable Tray with Fittings [913568]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7918.3408020319475,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4804,
                  name: 'Cable Tray with Fittings [914178]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2597.8893003289622,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4808,
                  name: 'Cable Tray with Fittings [914557]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 10840.033599806402,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4826,
                  name: 'Cable Tray with Fittings [915547]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 267.7043686274511,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4839,
                  name: 'Cable Tray with Fittings [916373]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 442.4232967975304,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4840,
                  name: 'Cable Tray with Fittings [916435]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1704.9999999999632,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4844,
                  name: 'Cable Tray with Fittings [916658]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3809.9999999999873,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4846,
                  name: 'Cable Tray with Fittings [916833]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 8500.000000000002,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4861,
                  name: 'Cable Tray with Fittings [917745]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1622.2436650008708,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4868,
                  name: 'Cable Tray with Fittings [920455]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1602.6838405978913,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4889,
                  name: 'Cable Tray with Fittings [922312]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 19637.72581335669,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4892,
                  name: 'Cable Tray with Fittings [922718]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 21282.82482280778,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4903,
                  name: 'Cable Tray with Fittings [923545]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 11990.869777194557,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4906,
                  name: 'Cable Tray with Fittings [923660]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 18734.999999999985,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4911,
                  name: 'Cable Tray with Fittings [923802]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 19307.50000058184,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4916,
                  name: 'Cable Tray with Fittings [924038]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 12282.500020808006,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4924,
                  name: 'Cable Tray with Fittings [924268]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7149.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4925,
                  name: 'Cable Tray with Fittings [924896]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1422.509661060885,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4926,
                  name: 'Cable Tray with Fittings [925021]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 277.4999999999954,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4928,
                  name: 'Cable Tray with Fittings [925107]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2537.553276564067,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4929,
                  name: 'Cable Tray with Fittings [925207]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 13330.360096127266,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4931,
                  name: 'Cable Tray with Fittings [925274]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7101.430715570314,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4935,
                  name: 'Cable Tray with Fittings [925443]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7823.116172918723,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4952,
                  name: 'Cable Tray with Fittings [927201]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 424.99999999472294,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4960,
                  name: 'Cable Tray with Fittings [928075]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 656.7167691656799,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4966,
                  name: 'Cable Tray with Fittings [928666]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 890.2542028919123,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4979,
                  name: 'Cable Tray with Fittings [929071]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 786.7999999949005,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4985,
                  name: 'Cable Tray with Fittings [929464]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7216.730222183397,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4991,
                  name: 'Cable Tray with Fittings [929505]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 824.5242680749906,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Multi-Comparment Trunking (Lighting/Power/Fire)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cable Tray with Fittings',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Trays',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4677,
              name: 'xx.xx.xx.xxx.xx_Cable Tray (Security)',
              children: [
                {
                  id: 4678,
                  name: 'Cable Tray with Fittings [906997]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3425.000000000044,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4679,
                  name: 'Cable Tray with Fittings [907026]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 675.0000000053388,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4695,
                  name: 'Cable Tray with Fittings [907590]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 14973.982389373406,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4703,
                  name: 'Cable Tray with Fittings [907693]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1827.0447823078764,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4713,
                  name: 'Cable Tray with Fittings [907795]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2363.285690259957,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4718,
                  name: 'Cable Tray with Fittings [907864]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 21175.007759989858,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4730,
                  name: 'Cable Tray with Fittings [908642]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4853.291372233488,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4743,
                  name: 'Cable Tray with Fittings [910339]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 9168.70103475772,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4750,
                  name: 'Cable Tray with Fittings [910500]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5500.000000423376,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4770,
                  name: 'Cable Tray with Fittings [911694]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 12400.000000000013,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4772,
                  name: 'Cable Tray with Fittings [911811]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2859.9830149632944,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4799,
                  name: 'Cable Tray with Fittings [913530]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7889.094841354357,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4803,
                  name: 'Cable Tray with Fittings [914177]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2770.586701970813,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4807,
                  name: 'Cable Tray with Fittings [914556]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 12419.999999999975,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4828,
                  name: 'Cable Tray with Fittings [915683]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 220.23714292097813,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4853,
                  name: 'Cable Tray with Fittings [917363]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3400.5481893009846,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4854,
                  name: 'Cable Tray with Fittings [917369]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1300.0430459754257,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4865,
                  name: 'Cable Tray with Fittings [917987]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1266.738793294951,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4870,
                  name: 'Cable Tray with Fittings [920457]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1249.718968882215,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4890,
                  name: 'Cable Tray with Fittings [922627]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 21332.824822807557,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4902,
                  name: 'Cable Tray with Fittings [923500]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 11838.108488368092,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4905,
                  name: 'Cable Tray with Fittings [923659]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 18547.264967968877,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4910,
                  name: 'Cable Tray with Fittings [923801]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 18855.00000029587,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4915,
                  name: 'Cable Tray with Fittings [924037]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 12009.999999689324,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4923,
                  name: 'Cable Tray with Fittings [924267]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7565.0000000000155,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4939,
                  name: 'Cable Tray with Fittings [925687]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 9659.882696916016,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4941,
                  name: 'Cable Tray with Fittings [926297]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 623.7903005793128,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4942,
                  name: 'Cable Tray with Fittings [926359]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 467.03505152560535,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4955,
                  name: 'Cable Tray with Fittings [927776]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 527.4999999947083,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4962,
                  name: 'Cable Tray with Fittings [928131]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1265.457120375073,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4968,
                  name: 'Cable Tray with Fittings [928748]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1984.8022794116637,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4975,
                  name: 'Cable Tray with Fittings [929004]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 961.7999999947222,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4987,
                  name: 'Cable Tray with Fittings [929466]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6264.9849992684885,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4993,
                  name: 'Cable Tray with Fittings [929507]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 430.8239168559298,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Cable Tray (Security)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cable Tray with Fittings',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Trays',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4681,
              name: 'xx.xx.xx.xxx.xx_Cable Tray (EMG Lighting',
              children: [
                {
                  id: 4682,
                  name: 'Cable Tray with Fittings [907156]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2680.0000000001032,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4683,
                  name: 'Cable Tray with Fittings [907157]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 675.0000000053388,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4689,
                  name: 'Cable Tray with Fittings [907441]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 13760.311459270783,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4704,
                  name: 'Cable Tray with Fittings [907694]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1827.0447823079812,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4711,
                  name: 'Cable Tray with Fittings [907793]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2183.277930270422,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4719,
                  name: 'Cable Tray with Fittings [907865]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 20014.999999999993,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4722,
                  name: 'Cable Tray with Fittings [908494]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5109.61312011981,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4728,
                  name: 'Cable Tray with Fittings [908586]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5440.000000000042,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4742,
                  name: 'Cable Tray with Fittings [910282]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 9720.000000401864,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4769,
                  name: 'Cable Tray with Fittings [911556]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 11800.000000000022,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4781,
                  name: 'Cable Tray with Fittings [912508]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 372.3339381781827,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4801,
                  name: 'Cable Tray with Fittings [913657]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 8234.094841354236,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4805,
                  name: 'Cable Tray with Fittings [914179]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3001.159441087621,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4809,
                  name: 'Cable Tray with Fittings [914558]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 10499.19850858117,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4829,
                  name: 'Cable Tray with Fittings [915783]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 256.13224518104505,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4842,
                  name: 'Cable Tray with Fittings [916534]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3555.0000000000055,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4847,
                  name: 'Cable Tray with Fittings [916918]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5539.999999999994,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4848,
                  name: 'Cable Tray with Fittings [917048]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2947.500264536182,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4860,
                  name: 'Cable Tray with Fittings [917663]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2305.000000000014,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4867,
                  name: 'Cable Tray with Fittings [920454]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 8182.655837371896,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4879,
                  name: 'Cable Tray with Fittings [921196]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 170.00784473142866,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4880,
                  name: 'Cable Tray with Fittings [921494]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2190.0039553779707,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4882,
                  name: 'Cable Tray with Fittings [921759]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 11050.004098682175,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4893,
                  name: 'Cable Tray with Fittings [922803]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 21332.82482280765,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4904,
                  name: 'Cable Tray with Fittings [923572]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 12969.513823042445,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4907,
                  name: 'Cable Tray with Fittings [923661]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 19821.473145331678,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4912,
                  name: 'Cable Tray with Fittings [923803]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 27100.000008976287,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4917,
                  name: 'Cable Tray with Fittings [924039]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 13134.999999999985,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4933,
                  name: 'Cable Tray with Fittings [925360]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 10542.26819888123,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4951,
                  name: 'Cable Tray with Fittings [927155]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 592.8499999947237,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4959,
                  name: 'Cable Tray with Fittings [928045]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 427.6743330837088,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4965,
                  name: 'Cable Tray with Fittings [928630]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1238.8877352650609,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4981,
                  name: 'Cable Tray with Fittings [929096]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 961.7999999947222,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4984,
                  name: 'Cable Tray with Fittings [929463]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7916.889461185466,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4990,
                  name: 'Cable Tray with Fittings [929504]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1266.0667041565587,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Cable Tray (EMG Lighting',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cable Tray with Fittings',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Trays',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4774,
              name: 'xx.xx.xx.xxx.xx_Cabe Tray (Sub-Mains)',
              children: [
                {
                  id: 4775,
                  name: 'Cable Tray with Fittings [911906]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2110.000000000137,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4776,
                  name: 'Cable Tray with Fittings [911907]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1332.5000000000348,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4780,
                  name: 'Cable Tray with Fittings [912425]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 352.4830149634713,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4802,
                  name: 'Cable Tray with Fittings [913689]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 8314.0948413541,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4806,
                  name: 'Cable Tray with Fittings [914180]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2868.347881736898,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4810,
                  name: 'Cable Tray with Fittings [914559]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3939.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4822,
                  name: 'Cable Tray with Fittings [915253]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4709.999992214424,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4824,
                  name: 'Cable Tray with Fittings [915470]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1618.178279271427,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4827,
                  name: 'Cable Tray with Fittings [915570]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 537.3043686276534,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4838,
                  name: 'Cable Tray with Fittings [916235]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2100.0000000000023,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4849,
                  name: 'Cable Tray with Fittings [917267]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2626.388868391101,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4851,
                  name: 'Cable Tray with Fittings [917318]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1526.837368144916,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4863,
                  name: 'Cable Tray with Fittings [917855]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1842.2662892374058,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4869,
                  name: 'Cable Tray with Fittings [920456]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1517.5399999898436,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4873,
                  name: 'Cable Tray with Fittings [920609]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2415.000000000012,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4884,
                  name: 'Cable Tray with Fittings [921876]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5527.721714674784,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4885,
                  name: 'Cable Tray with Fittings [921963]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2288.319260550156,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4886,
                  name: 'Cable Tray with Fittings [922017]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 11400.00000000002,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4894,
                  name: 'Cable Tray with Fittings [923006]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 25300.000000000062,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4898,
                  name: 'Cable Tray with Fittings [923295]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 18359.999999999964,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4900,
                  name: 'Cable Tray with Fittings [923386]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 20359.99999959792,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4936,
                  name: 'Cable Tray with Fittings [925526]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 8083.942610814847,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4938,
                  name: 'Cable Tray with Fittings [925589]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1282.1499966577921,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4944,
                  name: 'Cable Tray with Fittings [926566]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 527.0834810686415,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4945,
                  name: 'Cable Tray with Fittings [926615]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 23.15858364520489,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4950,
                  name: 'Cable Tray with Fittings [926998]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3980.0000000000005,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4953,
                  name: 'Cable Tray with Fittings [927258]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 292.84999999470534,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4961,
                  name: 'Cable Tray with Fittings [928103]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 592.5177696848347,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4967,
                  name: 'Cable Tray with Fittings [928699]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1578.6440996057606,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4977,
                  name: 'Cable Tray with Fittings [929036]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 641.7999999947256,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4986,
                  name: 'Cable Tray with Fittings [929465]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6388.8518459002535,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4992,
                  name: 'Cable Tray with Fittings [929506]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 353.76326754486723,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Cabe Tray (Sub-Mains)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cable Tray with Fittings',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Trays',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4784,
              name: 'xx.xx.xx.xxx.xx_Multi-Comparment Trunking (Lighting/Power/Fire)-Riser',
              children: [
                {
                  id: 4785,
                  name: 'Cable Tray with Fittings [912773]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4725.000000005301,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4833,
                  name: 'Cable Tray with Fittings [916002]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3568.200000000055,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Multi-Comparment Trunking (Lighting/Power/Fire)-Riser',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cable Tray with Fittings',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Trays',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4787,
              name: 'xx.xx.xx.xxx.xx_Cable Tray (Security)-Riser',
              children: [
                {
                  id: 4788,
                  name: 'Cable Tray with Fittings [912893]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4612.500000005319,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4837,
                  name: 'Cable Tray with Fittings [916172]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3755.7000000000558,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Cable Tray (Security)-Riser',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cable Tray with Fittings',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Trays',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4831,
              name: 'xx.xx.xx.xxx.xx_Cable Tray (EMG Lighting-Riser',
              children: [
                {
                  id: 4832,
                  name: 'Cable Tray with Fittings [915895]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3813.2000000000685,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4934,
                  name: 'Cable Tray with Fittings [925418]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4597.150000005275,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Cable Tray (EMG Lighting-Riser',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cable Tray with Fittings',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Trays',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4835,
              name: 'xx.xx.xx.xxx.xx_Cabe Tray (Sub-Mains)-Riser',
              children: [
                {
                  id: 4836,
                  name: 'Cable Tray with Fittings [916109]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 50,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3455.3000000000548,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4937,
                  name: 'Cable Tray with Fittings [925556]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Trays',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 75,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4557.150000005422,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Cabe Tray (Sub-Mains)-Riser',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cable Tray with Fittings',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Trays',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Cable Tray with Fittings',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Cable Trays',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Cable Trays',
          displayCategory: '__category__',
        },
      ],
    },
    {
      id: 4684,
      name: 'Cable Tray Fittings',
      children: [
        {
          id: 4685,
          name: 'M_Channel Vertical Inside Bend',
          children: [
            {
              id: 4687,
              name: 'xx.xx.xx.xxx.xx_Cable Tray (EMG Lighting',
              children: [
                {
                  id: 4688,
                  name: 'M_Channel Vertical Inside Bend [907369]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4857,
                  name: 'M_Channel Vertical Inside Bend [917584]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4881,
                  name: 'M_Channel Vertical Inside Bend [921739]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4958,
                  name: 'M_Channel Vertical Inside Bend [927878]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Cable Tray (EMG Lighting',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Channel Vertical Inside Bend',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Tray Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4700,
              name: 'xx.xx.xx.xxx.xx_Cable Tray (Security)',
              children: [
                {
                  id: 4701,
                  name: 'M_Channel Vertical Inside Bend [907630]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4859,
                  name: 'M_Channel Vertical Inside Bend [917656]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4940,
                  name: 'M_Channel Vertical Inside Bend [926172]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4956,
                  name: 'M_Channel Vertical Inside Bend [927784]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4976,
                  name: 'M_Channel Vertical Inside Bend [929010]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Cable Tray (Security)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Channel Vertical Inside Bend',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Tray Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4759,
              name: 'xx.xx.xx.xxx.xx_Multi-Comparment Trunking (Lighting/Power/Fire)',
              children: [
                {
                  id: 4760,
                  name: 'M_Channel Vertical Inside Bend [911138]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4858,
                  name: 'M_Channel Vertical Inside Bend [917610]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4891,
                  name: 'M_Channel Vertical Inside Bend [922697]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4957,
                  name: 'M_Channel Vertical Inside Bend [927861]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Multi-Comparment Trunking (Lighting/Power/Fire)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Channel Vertical Inside Bend',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Tray Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4778,
              name: 'xx.xx.xx.xxx.xx_Cabe Tray (Sub-Mains)',
              children: [
                {
                  id: 4779,
                  name: 'M_Channel Vertical Inside Bend [912397]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4864,
                  name: 'M_Channel Vertical Inside Bend [917921]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4954,
                  name: 'M_Channel Vertical Inside Bend [927264]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4978,
                  name: 'M_Channel Vertical Inside Bend [929042]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Cabe Tray (Sub-Mains)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Channel Vertical Inside Bend',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Tray Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'M_Channel Vertical Inside Bend',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Cable Tray Fittings',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 4690,
          name: 'M_Channel Horizontal Bend',
          children: [
            {
              id: 4692,
              name: 'xx.xx.xx.xxx.xx_Cable Tray (EMG Lighting',
              children: [
                {
                  id: 4693,
                  name: 'M_Channel Horizontal Bend [907479]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4710,
                  name: 'M_Channel Horizontal Bend [907736]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4716,
                  name: 'M_Channel Horizontal Bend [907802]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4723,
                  name: 'M_Channel Horizontal Bend [908577]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4745,
                  name: 'M_Channel Horizontal Bend [910379]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4812,
                  name: 'M_Channel Horizontal Bend [914738]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4813,
                  name: 'M_Channel Horizontal Bend [914739]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4843,
                  name: 'M_Channel Horizontal Bend [916557]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4909,
                  name: 'M_Channel Horizontal Bend [923774]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4914,
                  name: 'M_Channel Horizontal Bend [923980]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4922,
                  name: 'M_Channel Horizontal Bend [924091]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4969,
                  name: 'M_Channel Horizontal Bend [928770]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5000,
                  name: 'M_Channel Horizontal Bend [929753]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5001,
                  name: 'M_Channel Horizontal Bend [929754]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Cable Tray (EMG Lighting',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Channel Horizontal Bend',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Tray Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4697,
              name: 'xx.xx.xx.xxx.xx_Cable Tray (Security)',
              children: [
                {
                  id: 4698,
                  name: 'M_Channel Horizontal Bend [907628]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4705,
                  name: 'M_Channel Horizontal Bend [907733]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4714,
                  name: 'M_Channel Horizontal Bend [907800]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4744,
                  name: 'M_Channel Horizontal Bend [910377]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4746,
                  name: 'M_Channel Horizontal Bend [910496]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4771,
                  name: 'M_Channel Horizontal Bend [911742]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4816,
                  name: 'M_Channel Horizontal Bend [914742]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4817,
                  name: 'M_Channel Horizontal Bend [914743]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4855,
                  name: 'M_Channel Horizontal Bend [917425]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4856,
                  name: 'M_Channel Horizontal Bend [917426]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4871,
                  name: 'M_Channel Horizontal Bend [920460]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4908,
                  name: 'M_Channel Horizontal Bend [923770]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4913,
                  name: 'M_Channel Horizontal Bend [923978]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4920,
                  name: 'M_Channel Horizontal Bend [924087]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4946,
                  name: 'M_Channel Horizontal Bend [926848]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4947,
                  name: 'M_Channel Horizontal Bend [926850]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4974,
                  name: 'M_Channel Horizontal Bend [928859]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4988,
                  name: 'M_Channel Horizontal Bend [929486]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4995,
                  name: 'M_Channel Horizontal Bend [929745]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Cable Tray (Security)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Channel Horizontal Bend',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Tray Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4707,
              name: 'xx.xx.xx.xxx.xx_Multi-Comparment Trunking (Lighting/Power/Fire)',
              children: [
                {
                  id: 4708,
                  name: 'M_Channel Horizontal Bend [907734]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4709,
                  name: 'M_Channel Horizontal Bend [907735]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4715,
                  name: 'M_Channel Horizontal Bend [907801]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4720,
                  name: 'M_Channel Horizontal Bend [907933]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4754,
                  name: 'M_Channel Horizontal Bend [910694]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4766,
                  name: 'M_Channel Horizontal Bend [911499]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4767,
                  name: 'M_Channel Horizontal Bend [911500]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4768,
                  name: 'M_Channel Horizontal Bend [911501]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4814,
                  name: 'M_Channel Horizontal Bend [914740]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4815,
                  name: 'M_Channel Horizontal Bend [914741]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4845,
                  name: 'M_Channel Horizontal Bend [916686]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4872,
                  name: 'M_Channel Horizontal Bend [920467]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4918,
                  name: 'M_Channel Horizontal Bend [924083]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4919,
                  name: 'M_Channel Horizontal Bend [924084]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4921,
                  name: 'M_Channel Horizontal Bend [924089]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4930,
                  name: 'M_Channel Horizontal Bend [925235]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4932,
                  name: 'M_Channel Horizontal Bend [925330]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4970,
                  name: 'M_Channel Horizontal Bend [928771]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4989,
                  name: 'M_Channel Horizontal Bend [929488]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4998,
                  name: 'M_Channel Horizontal Bend [929750]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Multi-Comparment Trunking (Lighting/Power/Fire)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Channel Horizontal Bend',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Tray Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4794,
              name: 'xx.xx.xx.xxx.xx_Cabe Tray (Sub-Mains)',
              children: [
                {
                  id: 4795,
                  name: 'M_Channel Horizontal Bend [913350]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4811,
                  name: 'M_Channel Horizontal Bend [914737]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4818,
                  name: 'M_Channel Horizontal Bend [915165]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4850,
                  name: 'M_Channel Horizontal Bend [917293]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4852,
                  name: 'M_Channel Horizontal Bend [917355]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4887,
                  name: 'M_Channel Horizontal Bend [922024]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4888,
                  name: 'M_Channel Horizontal Bend [922025]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4899,
                  name: 'M_Channel Horizontal Bend [923338]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4901,
                  name: 'M_Channel Horizontal Bend [923393]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4948,
                  name: 'M_Channel Horizontal Bend [926878]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4949,
                  name: 'M_Channel Horizontal Bend [926892]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4972,
                  name: 'M_Channel Horizontal Bend [928793]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4996,
                  name: 'M_Channel Horizontal Bend [929748]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4997,
                  name: 'M_Channel Horizontal Bend [929749]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Cabe Tray (Sub-Mains)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Channel Horizontal Bend',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Tray Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'M_Channel Horizontal Bend',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Cable Tray Fittings',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 4724,
          name: 'M_Channel Reducer',
          children: [
            {
              id: 4726,
              name: 'xx.xx.xx.xxx.xx_Cable Tray (EMG Lighting',
              children: [
                {
                  id: 4727,
                  name: 'M_Channel Reducer [908578]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4729,
                  name: 'M_Channel Reducer [908598]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Cable Tray (EMG Lighting',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Channel Reducer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Tray Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4739,
              name: 'xx.xx.xx.xxx.xx_Multi-Comparment Trunking (Lighting/Power/Fire)',
              children: [
                {
                  id: 4740,
                  name: 'M_Channel Reducer [909945]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4765,
                  name: 'M_Channel Reducer [911498]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4983,
                  name: 'M_Channel Reducer [929411]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Multi-Comparment Trunking (Lighting/Power/Fire)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Channel Reducer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Tray Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4748,
              name: 'xx.xx.xx.xxx.xx_Cable Tray (Security)',
              children: [
                {
                  id: 4749,
                  name: 'M_Channel Reducer [910498]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4751,
                  name: 'M_Channel Reducer [910508]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Cable Tray (Security)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Channel Reducer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Tray Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4820,
              name: 'xx.xx.xx.xxx.xx_Cabe Tray (Sub-Mains)',
              children: [
                {
                  id: 4821,
                  name: 'M_Channel Reducer [915166]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4823,
                  name: 'M_Channel Reducer [915467]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4825,
                  name: 'M_Channel Reducer [915482]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Cabe Tray (Sub-Mains)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Channel Reducer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Tray Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'M_Channel Reducer',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Cable Tray Fittings',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 4734,
          name: 'M_Channel Vertical Outside Bend',
          children: [
            {
              id: 4736,
              name: 'xx.xx.xx.xxx.xx_Multi-Comparment Trunking (Lighting/Power/Fire)',
              children: [
                {
                  id: 4737,
                  name: 'M_Channel Vertical Outside Bend [909943]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4753,
                  name: 'M_Channel Vertical Outside Bend [910666]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4789,
                  name: 'M_Channel Vertical Outside Bend [913254]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4841,
                  name: 'M_Channel Vertical Outside Bend [916503]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4862,
                  name: 'M_Channel Vertical Outside Bend [917827]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4895,
                  name: 'M_Channel Vertical Outside Bend [923071]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4927,
                  name: 'M_Channel Vertical Outside Bend [925066]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4971,
                  name: 'M_Channel Vertical Outside Bend [928772]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4980,
                  name: 'M_Channel Vertical Outside Bend [929077]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4999,
                  name: 'M_Channel Vertical Outside Bend [929751]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Multi-Comparment Trunking (Lighting/Power/Fire)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Channel Vertical Outside Bend',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Tray Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4791,
              name: 'xx.xx.xx.xxx.xx_Cable Tray (EMG Lighting',
              children: [
                {
                  id: 4792,
                  name: 'M_Channel Vertical Outside Bend [913271]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4878,
                  name: 'M_Channel Vertical Outside Bend [920835]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4883,
                  name: 'M_Channel Vertical Outside Bend [921806]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4896,
                  name: 'M_Channel Vertical Outside Bend [923078]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4964,
                  name: 'M_Channel Vertical Outside Bend [928610]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4982,
                  name: 'M_Channel Vertical Outside Bend [929102]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4994,
                  name: 'M_Channel Vertical Outside Bend [929741]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Cable Tray (EMG Lighting',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Channel Vertical Outside Bend',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Tray Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4797,
              name: 'xx.xx.xx.xxx.xx_Cabe Tray (Sub-Mains)',
              children: [
                {
                  id: 4798,
                  name: 'M_Channel Vertical Outside Bend [913358]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4874,
                  name: 'M_Channel Vertical Outside Bend [920734]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4943,
                  name: 'M_Channel Vertical Outside Bend [926547]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4973,
                  name: 'M_Channel Vertical Outside Bend [928795]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5002,
                  name: 'M_Channel Vertical Outside Bend [929770]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Cabe Tray (Sub-Mains)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Channel Vertical Outside Bend',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Tray Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4876,
              name: 'xx.xx.xx.xxx.xx_Cable Tray (Security)',
              children: [
                {
                  id: 4877,
                  name: 'M_Channel Vertical Outside Bend [920753]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4897,
                  name: 'M_Channel Vertical Outside Bend [923184]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4963,
                  name: 'M_Channel Vertical Outside Bend [928577]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5003,
                  name: 'M_Channel Vertical Outside Bend [929787]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Cable Tray Fittings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 2.53999998984,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_Cable Tray (Security)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'M_Channel Vertical Outside Bend',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Cable Tray Fittings',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'M_Channel Vertical Outside Bend',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Cable Tray Fittings',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Cable Tray Fittings',
          displayCategory: '__category__',
        },
      ],
    },
    {
      id: 5023,
      name: 'Pipes',
      children: [
        {
          id: 5024,
          name: 'Pipe Types',
          children: [
            {
              id: 5026,
              name: 'xx.xx.xx.xxx.xx_VP - VENT PIPE R11/310',
              children: [
                {
                  id: 5027,
                  name: 'Pipe Types [932042]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7225663103256508,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5039,
                  name: 'Pipe Types [933133]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.30447977723542513,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 969.1892323707419,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5045,
                  name: 'Pipe Types [933408]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7225663103240723,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.9999999949723,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5051,
                  name: 'Pipe Types [933935]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7225663103240723,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.9999999949723,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5057,
                  name: 'Pipe Types [934352]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7225663103256508,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5063,
                  name: 'Pipe Types [934693]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7225663103256508,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5069,
                  name: 'Pipe Types [934777]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7225663103256508,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5075,
                  name: 'Pipe Types [934796]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7225663103256508,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5081,
                  name: 'Pipe Types [934836]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.541924732744238,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5087,
                  name: 'Pipe Types [935152]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5377936976233928,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1711.8505068086224,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5095,
                  name: 'Pipe Types [935470]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7225663103256508,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5101,
                  name: 'Pipe Types [935489]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.541924732744238,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5110,
                  name: 'Pipe Types [935789]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.541924732744238,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5116,
                  name: 'Pipe Types [935818]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.479877818750456,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1018.3323591238901,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5124,
                  name: 'Pipe Types [936500]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7225663103256508,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5127,
                  name: 'Pipe Types [936691]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.28125318089618223,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 895.2566799989294,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5130,
                  name: 'Pipe Types [936876]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.28125318089618223,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 895.2566799989294,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5133,
                  name: 'Pipe Types [937157]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7225663103273109,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2300.0000000052814,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5139,
                  name: 'Pipe Types [937517]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5419247327454833,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2300.0000000052814,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5145,
                  name: 'Pipe Types [937593]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5419247327454833,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2300.0000000052814,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5151,
                  name: 'Pipe Types [937625]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.0838494654909665,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2300.0000000052814,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5157,
                  name: 'Pipe Types [937640]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5419247327454833,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2300.0000000052814,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5163,
                  name: 'Pipe Types [937682]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5419247327454833,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2300.0000000052814,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5434,
                  name: 'Pipe Types [943569]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7225663103256508,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5436,
                  name: 'Pipe Types [943571]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.30447977723542513,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 969.1892323707419,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5438,
                  name: 'Pipe Types [943573]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7225663103240723,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.9999999949723,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5440,
                  name: 'Pipe Types [943575]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7225663103240723,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.9999999949723,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5442,
                  name: 'Pipe Types [943577]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7225663103256508,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5444,
                  name: 'Pipe Types [943579]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7225663103256508,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5446,
                  name: 'Pipe Types [943581]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7225663103256508,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5448,
                  name: 'Pipe Types [943583]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7225663103256508,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5450,
                  name: 'Pipe Types [943585]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.541924732744238,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5452,
                  name: 'Pipe Types [943587]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5377936976233928,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1711.8505068086224,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5454,
                  name: 'Pipe Types [943589]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7225663103256508,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5456,
                  name: 'Pipe Types [943591]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.541924732744238,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5459,
                  name: 'Pipe Types [943594]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.541924732744238,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5461,
                  name: 'Pipe Types [943596]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.479877818750456,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1018.3323591238901,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5463,
                  name: 'Pipe Types [943598]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7225663103256508,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2299.999999999997,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5464,
                  name: 'Pipe Types [943599]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.28125318089618223,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 895.2566799989294,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5465,
                  name: 'Pipe Types [943600]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.28125318089618223,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 895.2566799989294,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5466,
                  name: 'Pipe Types [943601]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7225663103273109,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2300.0000000052814,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5468,
                  name: 'Pipe Types [943603]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5419247327454833,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2300.0000000052814,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5470,
                  name: 'Pipe Types [943605]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5419247327454833,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2300.0000000052814,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5472,
                  name: 'Pipe Types [943607]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.0838494654909665,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2300.0000000052814,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5474,
                  name: 'Pipe Types [943609]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5419247327454833,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2300.0000000052814,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5476,
                  name: 'Pipe Types [943611]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5419247327454833,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2300.0000000052814,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_VP - VENT PIPE R11/310',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Pipe Types',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Pipes',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5035,
              name: 'xx.xx.xx.xxx.xx_DP - DISCHARGE PIPE R11/310',
              children: [
                {
                  id: 5036,
                  name: 'Pipe Types [932394]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.0734511513692637,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6600.000000000006,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5042,
                  name: 'Pipe Types [933265]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.2644910430715517,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4025.0000000052873,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5048,
                  name: 'Pipe Types [933739]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.6283185307195353,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2000.0000000050202,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5054,
                  name: 'Pipe Types [934263]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.6283185307195353,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2000.0000000050202,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5060,
                  name: 'Pipe Types [934542]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.073451151369264,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6600.000000000008,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5064,
                  name: 'Pipe Types [934694]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.073451151369264,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6600.000000000008,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5070,
                  name: 'Pipe Types [934778]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.073451151369264,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6600.000000000008,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5076,
                  name: 'Pipe Types [934797]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.073451151369264,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6600.000000000008,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5082,
                  name: 'Pipe Types [934837]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.4712388980372224,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1999.9999999947113,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5096,
                  name: 'Pipe Types [935471]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.073451151369264,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6600.000000000008,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5102,
                  name: 'Pipe Types [935490]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.471238898037143,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1999.999999994374,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5107,
                  name: 'Pipe Types [935602]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.9483682823036638,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4025.0000000052873,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5111,
                  name: 'Pipe Types [935790]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.471238898037143,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1999.999999994374,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5136,
                  name: 'Pipe Types [937364]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5419247327442407,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1725.0000000000061,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5140,
                  name: 'Pipe Types [937518]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.4064435495581805,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1725.0000000000061,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5146,
                  name: 'Pipe Types [937594]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.4064435495581805,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1725.0000000000061,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5152,
                  name: 'Pipe Types [937626]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.812887099116361,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1725.0000000000061,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5158,
                  name: 'Pipe Types [937641]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.4064435495581805,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1725.0000000000061,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5164,
                  name: 'Pipe Types [937683]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.4064435495581805,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1725.0000000000061,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5173,
                  name: 'Pipe Types [938062]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.445132620653066,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4600.00000000561,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5435,
                  name: 'Pipe Types [943570]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.0734511513692637,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6600.000000000006,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5437,
                  name: 'Pipe Types [943572]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.2644910430715517,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4025.0000000052873,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5439,
                  name: 'Pipe Types [943574]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.6283185307195353,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2000.0000000050202,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5441,
                  name: 'Pipe Types [943576]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.6283185307195353,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2000.0000000050202,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5443,
                  name: 'Pipe Types [943578]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.073451151369264,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6600.000000000008,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5445,
                  name: 'Pipe Types [943580]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.073451151369264,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6600.000000000008,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5447,
                  name: 'Pipe Types [943582]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.073451151369264,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6600.000000000008,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5449,
                  name: 'Pipe Types [943584]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.073451151369264,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6600.000000000008,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5451,
                  name: 'Pipe Types [943586]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.4712388980372224,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1999.9999999947113,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5455,
                  name: 'Pipe Types [943590]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.073451151369264,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6600.000000000008,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5457,
                  name: 'Pipe Types [943592]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.471238898037143,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1999.999999994374,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5458,
                  name: 'Pipe Types [943593]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.9483682823036638,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4025.0000000052873,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5460,
                  name: 'Pipe Types [943595]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.471238898037143,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1999.999999994374,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5467,
                  name: 'Pipe Types [943602]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5419247327442407,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1725.0000000000061,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5469,
                  name: 'Pipe Types [943604]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.4064435495581805,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1725.0000000000061,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5471,
                  name: 'Pipe Types [943606]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.4064435495581805,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1725.0000000000061,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5473,
                  name: 'Pipe Types [943608]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.812887099116361,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1725.0000000000061,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5475,
                  name: 'Pipe Types [943610]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.4064435495581805,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1725.0000000000061,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5477,
                  name: 'Pipe Types [943612]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.4064435495581805,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1725.0000000000061,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5478,
                  name: 'Pipe Types [943613]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.445132620653066,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4600.00000000561,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_DP - DISCHARGE PIPE R11/310',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Pipe Types',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Pipes',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5091,
              name: 'xx.xx.xx.xxx.xx_SS - STUB STACK R11/310',
              children: [
                {
                  id: 5092,
                  name: 'Pipe Types [935241]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.38484510006233763,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1224.9999999923234,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5453,
                  name: 'Pipe Types [943588]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.38484510006233763,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1224.9999999923234,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_SS - STUB STACK R11/310',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Pipe Types',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Pipes',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5120,
              name: 'xx.xx.xx.xxx.xx_SVP - SOIL VENT PIPE R11/310',
              children: [
                {
                  id: 5121,
                  name: 'Pipe Types [936321]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Pipes',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.6283185307174715,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1999.9999999984511,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5462,
                  name: 'Pipe Types [943597]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Center Line',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.6283185307174715,
                      displayCategory: 'Mechanical',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1999.9999999984511,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'xx.xx.xx.xxx.xx_SVP - SOIL VENT PIPE R11/310',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Pipe Types',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Pipes',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Pipe Types',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Pipes',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Pipes',
          displayCategory: '__category__',
        },
      ],
    },
  ],
  properties: [
    {
      displayName: 'Category',
      displayValue: 'Revit Document',
      displayCategory: '__category__',
    },
  ],
}

export const MEPTakeOffData: TakeOffItem[] = parseTakeOffData(ModelMEPData)

export const MEPTakeOffTableData: TakeOffTableItem[] = MEPTakeOffData.map((t) =>
  convertToTableItem(t, 'BHR-R5D-401-00-M3-X-0006-D1-03_Entrance Building - MEP.rvt')
)
