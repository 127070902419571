import React from 'react'
import { Col, Row } from 'antd'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../context/reducer'
import { headerHeight } from '../../../../constants/dimensions'

interface ThreePartsLayoutProps {
  layoutId: string
  leftSideComponent: (height: number) => JSX.Element
  rightSideComponent: () => JSX.Element
  bottomPanelComponent: (rowHeight: number) => JSX.Element
}

export const ThreePartsLayout = ({
  layoutId,
  leftSideComponent,
  rightSideComponent,
  bottomPanelComponent,
}: ThreePartsLayoutProps) => {
  const { height } = useWindowDimensions()
  const { bottomComponentOpened, rightSideComponentOpened } = useSelector((state: RootState) =>
    state.layoutsReducer.layouts.get(layoutId)
  )

  const rowHeight = (height - headerHeight - 52) * 0.1
  const bottomPanelHeight = bottomComponentOpened ? 4 * rowHeight : 0
  const bottomPanelSpan = bottomComponentOpened ? 24 : 0
  const rightSideComponentSpan = rightSideComponentOpened ? 10 : 0
  const leftSideComponentHeight = rowHeight * (bottomComponentOpened ? 5 : 9)
  const leftSideComponentSpan = rightSideComponentOpened ? 14 : 24

  return (
    <>
      <Row gutter={16}>
        <Col span={leftSideComponentSpan}>{leftSideComponent(leftSideComponentHeight)}</Col>
        <Col span={rightSideComponentSpan}>{rightSideComponent()}</Col>
      </Row>
      <Row style={{ background: '#fff', height: bottomPanelHeight, marginTop: 16 }}>
        <Col span={bottomPanelSpan}>{bottomPanelComponent(rowHeight)}</Col>
      </Row>
    </>
  )
}
