import React, { FunctionComponent } from 'react'
import {
  SterlingTableHeader,
  SterlingTableHeaderProps,
} from '../../../basic/table/sterling-table/header/SterlingTableHeader'
import { Button, Divider, Dropdown, Menu, Tooltip } from 'antd'
import {
  ExportOutlined,
  ImportOutlined,
  PlusSquareOutlined,
  TrophyOutlined,
  CheckOutlined,
} from '@ant-design/icons/lib'
import ColumnGroupingManager from '../../../basic/table/managers/column-grouping/ColumnGroupingManager'
import { SpellCheckCustom, TextWrappingCustom } from '../../../../assets/icons/icons'
import { Entry, ExtendedColumnType } from '../../../basic/table/sterling-table/SterlingTable'
import { ColumnType } from 'antd/lib/table/interface'
import { emptyWorkPackagesEntry, WorkPackagesEntry } from '../../../../data/WorkPackages.data'
import MenuItem from 'antd/lib/menu/MenuItem'
import SubMenu from 'antd/lib/menu/SubMenu'
import { currency } from '../../../../utils/rendering/Rendering'
import { Quote, QuoteItems } from '../../../../data/WorkPackagesDetailsData'

interface WorkPackagesDetailsHeaderProps {
  data: Entry[]
  columns: ColumnType<any>[]
  tableId: string
  onQuoteAward: (x: number) => void
  onQuoteAdd: () => void
  awardedId: number
  quotes: Quote[]
  sterlingHeaderProps: SterlingTableHeaderProps
}

const WorkPackagesDetailsHeader: FunctionComponent<WorkPackagesDetailsHeaderProps> = ({
  columns,
  tableId,
  sterlingHeaderProps,
  awardedId,
  onQuoteAward,
  onQuoteAdd,
  quotes,
  data,
}) => {
  const quoteFor = (quoteId: number, property: string) => {
    const value = QuoteItems
      // @ts-ignore
      .filter((d) => d.quoteId === quoteId)
      // @ts-ignore
      .reduce((acc, y) => acc + (y[`${property}_${quoteId}`] || 0), 0)
    return currency(value)
  }

  const awardingMenu = (
    <Menu>
      {
        //@ts-ignore
        quotes.map((c) => (
          <SubMenu title={c.name}>
            <MenuItem>
              <span>Proposed total </span>
              <span style={{ color: 'grey' }}>({quoteFor(c.id, 'proposed_total')})</span>
            </MenuItem>
            <MenuItem>
              <span>Proposed adj Total </span>
              <span style={{ color: 'grey' }}>({quoteFor(c.id, 'proposed_total_with_adjustment')})</span>
            </MenuItem>
            <MenuItem>
              <span>Negotiated Total </span>
              <span style={{ color: 'grey' }}>({quoteFor(c.id, 'negotiated_total')})</span>
            </MenuItem>
            <MenuItem>
              <span>Negotiated Adj Total </span>
              <span style={{ color: 'grey' }}>({quoteFor(c.id, 'negotiated_total_with_adjustment')})</span>
            </MenuItem>
          </SubMenu>
        ))
      }
    </Menu>
  )
  return (
    <SterlingTableHeader {...sterlingHeaderProps}>
      <Divider type={'vertical'} />
      <Tooltip title="Import document">
        <Button type="text" shape="circle" icon={<ImportOutlined />} />
      </Tooltip>
      <Tooltip title="Import document">
        <Button type="text" shape="circle" icon={<ExportOutlined />} />
      </Tooltip>
      <Divider type={'vertical'} />
      <Tooltip title="Add document">
        <Button type="text" shape="circle" icon={<PlusSquareOutlined />} />
      </Tooltip>
      <Tooltip title="Wrap text">
        <Button type="text" shape="circle" icon={<TextWrappingCustom />} />
      </Tooltip>
      <Tooltip title="Check spelling">
        <Button type="text" shape="circle" icon={<SpellCheckCustom />} />
      </Tooltip>
      <Dropdown overlay={awardingMenu}>
        <Tooltip title="Awards">
          <Button type="text" shape={'circle'} icon={<TrophyOutlined />} />
        </Tooltip>
      </Dropdown>
    </SterlingTableHeader>
  )
}

export default WorkPackagesDetailsHeader
