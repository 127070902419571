import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Form, Checkbox, Input, Button, Modal, Alert } from 'antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import './LoginPage.scss'

export default function LoginPage() {
  const history = useHistory()
  const [visible, setVisible] = useState(false)

  const onFinish = (values: any) => {
    if (values.username === 'admin' && values.password === 'admin') {
      history.push('/portfolio')
    } else {
      setVisible(true)
    }
  }

  return (
    <div className="login-page">
      <Form
        name="normal_login"
        className="login-form"
        size={'large'}
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <div className="main-name-wrapper">STERLING</div>
        <div className="info-wrapper">Log into your account</div>
        <Form.Item
          name="username"
          className="item-wrapper"
          rules={[{ required: true, message: 'Please input your Username!' }]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="password"
          className="item-wrapper"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" />
        </Form.Item>
        <Form.Item className="down-panel-wrapper">
          <Form.Item name="remember" className="item-wrapper" valuePropName="checked" noStyle={true}>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <a className="login-form-forgot" href="">
            Forgot password
          </a>
        </Form.Item>
        <Form.Item>
          <Button type="primary" size="large" htmlType="submit" className="login-form-button">
            Log in
          </Button>
        </Form.Item>
        <Form.Item className="register-wrapper">
          New to Sterling? <a href="">register now!</a>
        </Form.Item>
      </Form>
      <Modal visible={visible} onOk={() => setVisible(false)} onCancel={() => setVisible(false)}>
        <Alert
          message="Error"
          description="Username or password is incorrect. Please try again!"
          type="error"
          showIcon
        />
      </Modal>
    </div>
  )
}
