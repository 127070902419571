import React from 'react'

import { Tabs } from 'antd'
import ProjectTemplate from '../project/ProjectTemplate'
import ActivitiesSchedule from './parts/ActivitiesSchedule'
import ActivitiesBreadcrumb from './breadcrumb/ActivitiesBreadcrumb'
import ActivitiesMapping from './parts/ActivitiesMapping'
import ActivitiesCashFlow from './parts/ActivitiesCashFlow'

const TabPane = Tabs.TabPane

function Activities(): JSX.Element {
  return (
    <ProjectTemplate selectedKey={'activities'}>
      <ActivitiesPart />
    </ProjectTemplate>
  )
}

function ActivitiesPart(): JSX.Element {
  return (
    <div>
      <ActivitiesBreadcrumb />
      <Tabs defaultActiveKey="manage">
        <TabPane tab={'Schedule'} key="schedule">
          <ActivitiesSchedule />
        </TabPane>
        <TabPane tab={'Mapping'} key="mapping">
          <ActivitiesMapping />
        </TabPane>
        <TabPane tab={'Cash-flow'} key="cash-flow">
          <ActivitiesCashFlow />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default Activities
