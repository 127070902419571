import { ExtendedColumnType } from '../../../../basic/table/sterling-table/SterlingTable'
import { stringSorter, numberSorter } from '../../../../../utils/Sorters'
import { Button } from 'antd'
import { EyeFilled, EyeTwoTone } from '@ant-design/icons'
import React from 'react'
import { renderDecimal } from '../../../../../utils/rendering/Rendering'

export const documentationColumns = (
  visibleItems: Set<number>,
  showItem: (id: number) => void,
  hideItem: (id: number) => void,
  excludedItems: number[]
): ExtendedColumnType[] => [
  {
    title: '',
    dataIndex: 'eye',
    key: 'eye',
    width: 30,
    fixed: 'left',
    align: 'center',
    editable: false,
    formattingRule: undefined,
    render: (value, record) =>
      !excludedItems.includes(record.id) &&
      (visibleItems.has(record.id) ? (
        <Button type="text" shape="circle" icon={<EyeTwoTone />} onClick={() => hideItem(record.id)} />
      ) : (
        <Button type="text" shape="circle" icon={<EyeFilled />} onClick={() => showItem(record.id)} />
      )),
  },
  {
    title: 'Document name',
    dataIndex: 'document_name',
    fixed: 'left',
    key: 'document_name',
    width: 130,
    sorter: (a, b) => stringSorter(a.document_name, b.document_name),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Document title',
    dataIndex: 'document_title',
    fixed: 'left',
    key: 'document_title',
    width: 130,
    sorter: (a, b) => stringSorter(a.document_title, b.document_title),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'File type',
    dataIndex: 'file_type',
    key: 'file_type',
    width: 130,
    sorter: (a, b) => stringSorter(a.file_type, b.file_type),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Internal Doc Reference Code',
    dataIndex: 'internal_doc_reference_code',
    key: 'internal_doc_reference_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.internal_doc_reference_code, b.internal_doc_reference_code),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'File size',
    dataIndex: 'file_size',
    key: 'file_size',
    width: 130,
    sorter: (a, b) => numberSorter(a.file_size, b.file_size),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'File name',
    dataIndex: 'file_name',
    key: 'file_name',
    width: 130,
    sorter: (a, b) => stringSorter(a.file_name, b.file_name),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Folder',
    dataIndex: 'folder',
    key: 'folder',
    width: 130,
    sorter: (a, b) => stringSorter(a.folder, b.folder),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    width: 130,
    sorter: (a, b) => stringSorter(a.note, b.note),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Revision',
    dataIndex: 'revision',
    key: 'revision',
    width: 130,
    sorter: (a, b) => numberSorter(a.revision, b.revision),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 130,
    sorter: (a, b) => stringSorter(a.status, b.status),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    width: 130,
    sorter: (a, b) => stringSorter(a.location, b.location),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'RIBA stage',
    dataIndex: 'riba_stage',
    key: 'riba_stage',
    width: 130,
    sorter: (a, b) => stringSorter(a.riba_stage, b.riba_stage),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Discipline',
    dataIndex: 'discipline',
    key: 'discipline',
    width: 130,
    sorter: (a, b) => stringSorter(a.discipline, b.discipline),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Design package',
    dataIndex: 'design_package',
    key: 'design_package',
    width: 130,
    sorter: (a, b) => stringSorter(a.design_package, b.design_package),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Author',
    dataIndex: 'author',
    key: 'author',
    width: 130,
    sorter: (a, b) => stringSorter(a.author, b.author),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Organisation',
    dataIndex: 'organisation',
    key: 'organisation',
    width: 130,
    sorter: (a, b) => stringSorter(a.organisation, b.organisation),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Document type',
    dataIndex: 'document_type',
    key: 'document_type',
    width: 130,
    sorter: (a, b) => stringSorter(a.document_type, b.document_type),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    width: 130,
    sorter: (a, b) => stringSorter(a.role, b.role),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Published by',
    dataIndex: 'published_by',
    key: 'published_by',
    width: 130,
    sorter: (a, b) => stringSorter(a.published_by, b.published_by),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Publish date',
    dataIndex: 'publish_date',
    key: 'publish_date',
    width: 130,
    sorter: (a, b) => stringSorter(a.publish_date, b.publish_date),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Updated by',
    dataIndex: 'updated_by',
    key: 'updated_by',
    width: 130,
    sorter: (a, b) => stringSorter(a.updated_by, b.updated_by),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Update date',
    dataIndex: '',
    key: '',
    width: '',
    // sorter: (a, b) =>stringSorter(a., b.),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: undefined,
    editable: false,
    formattingRule: undefined,
  },
]
