import React from 'react'
import Modal from 'antd/lib/modal/Modal'
import { Button, Row, Col, Input } from 'antd'

export interface SettingsModalProps {
  visibility: boolean
  hideModal: () => void
  handleClick?: () => void // () => addNewUserToList
}

export default function SettingsModal({ visibility, hideModal, handleClick }: SettingsModalProps) {
  return (
    <Modal
      width={'500px'}
      title="Add user"
      visible={visibility}
      destroyOnClose={false}
      onCancel={hideModal}
      footer={[
        <Button key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => {}}>
          Add
        </Button>,
      ]}
    >
      <div className="modal-selector-wrapper" style={{ width: '100%' }}>
        <Row style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <Col>
            <p style={{ paddingRight: '10px' }}>User: </p>
          </Col>
          <Col>
            <Input placeholder="Enter user name" />
          </Col>
        </Row>
      </div>
    </Modal>
  )
}
