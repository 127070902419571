import React, { FunctionComponent, Key, useState } from 'react'
import { Button, Popover, Radio, Divider, Space, Tooltip } from 'antd'
import { Entry } from '../../sterling-table/SterlingTable'
import { ColumnType } from 'antd/lib/table/interface'
import { useDispatch, useSelector } from 'react-redux'
import { groupByColumn } from '../../../../../context/resource-library/SterlingTable.actions'
import { GroupByCustom } from '../../../../../assets/icons/icons'
import { RootState } from '../../../../../context/reducer'

interface ColumnGroupingManagerContentProps {
  hide: () => void
  tableId: string
  columns: ColumnType<any>[]
  emptyEntryGenerator: (id: any) => Entry
  groupingRules: any
  defaultItemCodeExtractor?: (el: any) => string
}

const ColumnGroupingManagerContent: FunctionComponent<ColumnGroupingManagerContentProps> = ({
  tableId,
  hide,
  emptyEntryGenerator,
  columns,
  groupingRules,
  defaultItemCodeExtractor,
}) => {
  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  }

  const dispatch = useDispatch()

  const dividerStyle = { padding: 4, margin: 4 }
  const savedKey = useSelector((state: RootState) => state.sterlingTable.groupedBy[tableId])
  const [selectedKey, setSelectedKey] = useState<Key | undefined>(savedKey)

  return (
    <div>
      <Radio.Group
        style={{ height: 400, width: 320, overflowY: 'scroll', overflowX: 'hidden' }}
        value={selectedKey}
        onChange={({ target }) => setSelectedKey(target.value)}
      >
        <Radio key={'none'} value={undefined} style={radioStyle} checked={true}>
          No grouping
        </Radio>
        <Divider style={dividerStyle} />
        {columns.map(({ key, title }) => (
          <Radio key={key} style={radioStyle} value={key}>
            {title}
          </Radio>
        ))}
      </Radio.Group>
      <Divider style={dividerStyle} />
      <Space direction={'horizontal'} align={'end'}>
        <Button
          onClick={() => {
            setSelectedKey(savedKey)
            hide()
          }}
        >
          Cancel
        </Button>
        <Button
          type={'primary'}
          onClick={() => {
            dispatch(groupByColumn(tableId, emptyEntryGenerator, groupingRules, selectedKey, defaultItemCodeExtractor))
            hide()
          }}
        >
          Show
        </Button>
      </Space>
    </div>
  )
}

interface ColumnGroupingManagerProps {
  columns: ColumnType<any>[]
  tableId: string
  emptyEntryGenerator: (id: any) => Entry
  groupingRules: any
  defaultItemCodeExtractor?: (el: any) => string
}

const ColumnGroupingManager: FunctionComponent<ColumnGroupingManagerProps> = ({
  tableId,
  columns,
  emptyEntryGenerator,
  groupingRules,
  defaultItemCodeExtractor,
}) => {
  const [visible, setVisible] = useState(false)

  return (
    <Popover
      visible={visible}
      title={'Column grouping'}
      content={
        <ColumnGroupingManagerContent
          tableId={tableId}
          hide={() => setVisible(false)}
          columns={columns}
          emptyEntryGenerator={emptyEntryGenerator}
          groupingRules={groupingRules}
          defaultItemCodeExtractor={defaultItemCodeExtractor}
        />
      }
    >
      <Tooltip title="Group by">
        <Button type="text" shape="circle" icon={<GroupByCustom />} onClick={() => setVisible(true)} />
      </Tooltip>
    </Popover>
  )
}

export default ColumnGroupingManager
