import React, { Key } from 'react'
import TableSelectionToolbar, { TableSelectionToolbarProps } from './TableSelectionToolbar'
import { Button, Divider } from 'antd'

export interface ItemsManagementToolbarProps {
  baseProps: TableSelectionToolbarProps
  onDelete: (selectedItems: Key[]) => void
  onAdd: (selectedItems: Key[]) => void
  onDuplicate: (selectedItems: Key[]) => void
  children?: React.ReactNode | any[]
}

export const ItemsManagementToolbar = ({ baseProps, onDelete, onAdd, onDuplicate }: ItemsManagementToolbarProps) => {
  return (
    <TableSelectionToolbar {...baseProps}>
      <Button type="text" style={{ marginRight: '15px' }} onClick={() => onAdd(baseProps.selectedItems)}>
        Add new item
      </Button>
      <Button type="text" onClick={() => onDuplicate(baseProps.selectedItems)}>
        Duplicate items
      </Button>
      <Divider type={'vertical'} />
      <Button type="text" style={{ marginRight: '15px' }}>
        Isolate items
      </Button>
      <Button type="text">Hide items</Button>
      <Divider type={'vertical'} />
      <Button type="text">Export items</Button>
      <Divider type={'vertical'} />
      <Button type="text" onClick={() => onDelete(baseProps.selectedItems)}>
        Delete items
      </Button>
    </TableSelectionToolbar>
  )
}
