import React from 'react'
import { Layout } from 'antd'
import { ClusterOutlined, SettingOutlined, TeamOutlined, ToolOutlined } from '@ant-design/icons/lib'
import { MenuSider, MenuSiderItems } from '../../../basic/menu/MenuSider'

const { Content } = Layout

const MenuItems: MenuSiderItems = {
  head: {
    key: 'administration',
    icon: <SettingOutlined />,
    title: 'Administration',
  },
  items: [
    {
      key: 'permission-management',
      icon: <TeamOutlined />,
      title: 'Permission Management',
      address: 'permission-management',
    },
    {
      key: 'global-properties',
      icon: <ToolOutlined />,
      title: 'Global Properties',
      address: 'global-properties',
    },
    {
      key: 'portfolio-breakdown',
      icon: <ClusterOutlined />,
      title: 'Portfolio Breakdown',
      address: 'portfolio-breakdown',
    },
  ],
}

function AdministrationTemplate({
  children,
  selectedKey,
}: {
  children: React.ReactNode
  selectedKey: string
}): JSX.Element {
  return (
    <>
      <Layout>
        <MenuSider disableHead={true} head={MenuItems.head} items={MenuItems.items} selectedKey={selectedKey} />
        <Content style={{ paddingLeft: '50px', paddingRight: '50px' }}>
          <div>{children}</div>
        </Content>
      </Layout>
    </>
  )
}

export default AdministrationTemplate
