import { ProjectWBS } from '../BillOfQuantitiesBreakdownStructures.data'

export const wbsData = ProjectWBS.map((x) => ({
  ...x,
  itemCode: x.wbs_code,
  description: '',
  note: '',
}))

export function emptyProjectWBSItem() {
  return { id: -1, itemCode: '', title: '', notes: '', description: '' }
}