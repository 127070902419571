import { ModelNode } from '../../components/domain/forge-viewer/ForgeViewer.model'
import { parseTakeOffData, TakeOffItem } from '../../components/domain/take-off/helpers/TakeOffDataParser'
import { convertToTableItem } from '../../components/domain/take-off/helpers/TakeOffConverter'
import { TakeOffTableItem } from '../../components/domain/take-off/TakeOffTables.model'

export const ModelInternalData: ModelNode = {
  id: 1,
  name: 'Model',
  children: [
    {
      id: 3653,
      name: 'Walls',
      children: [
        {
          id: 3654,
          name: 'Basic Wall',
          children: [
            {
              id: 3656,
              name: '03.02.07.01.03.020_Metal Stud Partition Type 6 K10/103',
              children: [
                {
                  id: 3657,
                  name: 'Basic Wall [812176]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2427.5796605460664,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 9.702976608251259,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.1837631462066063,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3658,
                  name: 'Basic Wall [812734]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3602.6307626226726,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 15.020886126772176,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.8325481074661323,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3659,
                  name: 'Basic Wall [812806]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1862.500000000141,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 7.886350000010877,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9621347000012513,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3660,
                  name: 'Basic Wall [812839]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3500.000000000414,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 11.728800000018751,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.4309136000022082,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3661,
                  name: 'Basic Wall [813280]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4509.795310480652,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 13.747960772994064,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.6772512143055067,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3662,
                  name: 'Basic Wall [813477]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1062.4935540237118,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.1061235715020326,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5009470757231395,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3663,
                  name: 'Basic Wall [813727]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2612.529241238765,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.461269889092184,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.2762749264692559,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3664,
                  name: 'Basic Wall [813877]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3358.818814890786,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 14.041757141070438,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.7130943712105264,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3692,
                  name: 'Basic Wall [819688]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3159.1287185591445,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 11.005027746109107,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.3426133850254307,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3693,
                  name: 'Basic Wall [819833]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2289.746138336067,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.766759167189784,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.8255446183969783,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3694,
                  name: 'Basic Wall [820161]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1505.8680492463986,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.1166590019178,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.7462323982339437,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3699,
                  name: 'Basic Wall [821498]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2231.5322534650522,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 9.649482239216432,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.1772368331843799,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3700,
                  name: 'Basic Wall [821610]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4335.388539045507,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 18.128787822059536,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.1811999142911964,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3701,
                  name: 'Basic Wall [821803]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4623.593704608578,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 13.853734188919901,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.6901555710478984,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3702,
                  name: 'Basic Wall [821948]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2231.5322534649486,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 8.649082239217387,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.0551880331844155,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3703,
                  name: 'Basic Wall [822119]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2231.5322534652178,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 8.64908223921861,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.0551880331845633,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3704,
                  name: 'Basic Wall [822167]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1943.3270879026477,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 8.321335872360525,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9846907764279141,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3705,
                  name: 'Basic Wall [822469]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 407.5836538851143,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.4639033570319269,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.11757180955782766,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3716,
                  name: 'Basic Wall [824875]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 494.1263873882704,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.775830937629037,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2166498189321595,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3717,
                  name: 'Basic Wall [824921]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 533.1970682352658,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.9360207291023417,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.23619297349191692,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3808,
                  name: 'Basic Wall [839025]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1748.3091078790837,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 8.019221896234518,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9783450713406943,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3809,
                  name: 'Basic Wall [839112]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5177.642963568289,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 21.134957632388417,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.578464831150985,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3810,
                  name: 'Basic Wall [839241]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6766.401006652231,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 28.72384463056565,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.50430904492867,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3811,
                  name: 'Basic Wall [839289]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1753.309107879067,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.3830218962354905,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6567286713407433,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3812,
                  name: 'Basic Wall [839531]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2569.122040942438,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 9.416361388321873,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.1487960893751368,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3813,
                  name: 'Basic Wall [839644]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4038.586727639255,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 18.29689894711965,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.2322216715483822,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3814,
                  name: 'Basic Wall [839819]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4038.3058138478427,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 18.856806743678646,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.3005304227285728,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3815,
                  name: 'Basic Wall [839980]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 10282.698255349422,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 43.05841197455409,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 5.253126260895615,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3816,
                  name: 'Basic Wall [840116]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1013.8300101019161,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.3830180464640724,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5347282016685946,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3823,
                  name: 'Basic Wall [841280]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5239.995982338338,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 24.384581518728318,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.9749189452847338,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3824,
                  name: 'Basic Wall [841469]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 15219.649521986257,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 61.24578780105786,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 7.471986111728779,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3825,
                  name: 'Basic Wall [841583]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5240.242764404125,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 23.824516716231745,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.9065910393801575,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3826,
                  name: 'Basic Wall [841737]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5240.398280233116,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 23.825232089045098,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.90667831486339,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3827,
                  name: 'Basic Wall [841889]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1164.3632807266129,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.075471091336527,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6192074731430743,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3828,
                  name: 'Basic Wall [842067]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1164.3632807266129,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.075471091336481,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6192074731430267,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3829,
                  name: 'Basic Wall [842112]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5240.536132880326,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 23.82586621122226,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.906755677769004,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 6813,
                  name: 'Basic Wall [892726]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3312.270604764394,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.729192995722597,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.3089615454781065,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 6814,
                  name: 'Basic Wall [892850]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2312.729402108732,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 7.430707026958804,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9065462572889375,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 6815,
                  name: 'Basic Wall [893039]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1674.9999999999911,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.124900000000005,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.625237799999975,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 6816,
                  name: 'Basic Wall [893130]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1176.6260972813282,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.4802661210282526,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.424592466765446,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 6817,
                  name: 'Basic Wall [893249]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1263.1430397077302,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.881941926650753,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.44925121915390287,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 6818,
                  name: 'Basic Wall [893440]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1750.0732946677963,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.356141872404005,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6534493084332589,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 6819,
                  name: 'Basic Wall [893575]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2624.655964754626,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 8.846164683690137,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.079232091410159,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 6821,
                  name: 'Basic Wall [893794]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5080.000000000007,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 16.562699999999957,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.020649399999991,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 6822,
                  name: 'Basic Wall [893961]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4550.003544836414,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 15.015011697960144,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.8318314271512992,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 6823,
                  name: 'Basic Wall [894115]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1753.3091078789973,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.383320056000794,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6567650468320707,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 6824,
                  name: 'Basic Wall [894221]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1588.8792227018946,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.0420014349166165,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.615124175059802,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 6825,
                  name: 'Basic Wall [894337]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2810.2401668377624,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 8.871192550564713,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.0822854911688118,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 6826,
                  name: 'Basic Wall [894399]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 260.08199559435764,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.8582705854616326,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.10470901142631084,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 6827,
                  name: 'Basic Wall [894515]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3317.5270889037556,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.730039393382194,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.309064805992573,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 6838,
                  name: 'Basic Wall [894939]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 253.0252484386512,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.43238331984721484,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.05275076502136,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 6847,
                  name: 'Basic Wall [895093]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 260.0819955944537,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.45567058546180744,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.055591811426336186,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.07.01.03.020_Metal Stud Partition Type 6 K10/103',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 122,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 3666,
              name: '03.02.07.01.03.000_Metal Stud Partition Type 4 K10/128',
              children: [
                {
                  id: 3667,
                  name: 'Basic Wall [814075]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 11881.87435242298,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 41.85248484499669,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 5.524527999539278,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3668,
                  name: 'Basic Wall [814325]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3558.973024552087,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 14.321189400682114,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.8903970008898334,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3672,
                  name: 'Basic Wall [815329]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3559.121498705691,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 14.321798144711893,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.890477355101762,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3674,
                  name: 'Basic Wall [816089]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 334.34967745978787,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.1002474719494657,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.14523084539500308,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3675,
                  name: 'Basic Wall [816230]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6924.932515000105,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 28.39222331153706,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.7477734771224798,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3676,
                  name: 'Basic Wall [816369]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6925.1312570841155,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 21.73103815408165,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.8684970363385327,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3677,
                  name: 'Basic Wall [816421]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3898.5662293727078,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 15.442921540448001,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.038465643338911,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3678,
                  name: 'Basic Wall [816800]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6600.282158621865,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 27.061156850384556,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.5720727042503704,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3695,
                  name: 'Basic Wall [820576]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4563.823613076252,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 16.340576813636527,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.156956139399827,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3696,
                  name: 'Basic Wall [820908]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3178.8506138547637,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.33418751682081,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.3641127522201169,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3706,
                  name: 'Basic Wall [822720]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4362.721101695752,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 17.637056516975395,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.3280914602403757,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3707,
                  name: 'Basic Wall [822898]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4362.4200462592635,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 13.772663867206791,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.8156753492162447,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3708,
                  name: 'Basic Wall [823100]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5330.837103119021,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 19.48533212281639,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.572063840211573,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3709,
                  name: 'Basic Wall [823200]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1474.7517501151299,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.404782175479554,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.44943124716324545,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3710,
                  name: 'Basic Wall [823410]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6471.970438418178,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 26.917764987527196,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.5174257783531666,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3711,
                  name: 'Basic Wall [823537]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2148.7379427360356,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 9.463111755208045,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.2134115516874135,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3712,
                  name: 'Basic Wall [823750]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1849.5277591751967,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 7.35889143267186,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.8999352691126875,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3736,
                  name: 'Basic Wall [827386]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 367.59604946188836,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.9026298688580663,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.24154554667819936,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3737,
                  name: 'Basic Wall [827570]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 943.9531084493301,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.071035364690433,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.21135988541994422,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3738,
                  name: 'Basic Wall [827675]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 271.92975189400636,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.655589641683102,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.19617335388950638,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3806,
                  name: 'Basic Wall [838627]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4933.598753854279,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 20.854154267703912,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.752748363336375,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3807,
                  name: 'Basic Wall [838877]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3491.0241380366356,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 15.778111034950454,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.0827106566132305,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3817,
                  name: 'Basic Wall [840172]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2493.542470442737,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 9.372295364023529,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.2371429880509714,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3820,
                  name: 'Basic Wall [840801]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5234.390930937999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 21.653598282287664,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.858274973261434,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3821,
                  name: 'Basic Wall [840957]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 13321.08188321959,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 54.32977666274141,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 7.17153051948168,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3822,
                  name: 'Basic Wall [841083]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5234.583539716885,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 23.775484282670295,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1383639253121314,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3830,
                  name: 'Basic Wall [842313]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1008.8364781175666,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.337047799335431,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.572490309512338,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3831,
                  name: 'Basic Wall [842420]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 12150.17494780585,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 44.719162520364996,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 5.902929452686963,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3832,
                  name: 'Basic Wall [842580]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5238.569876318703,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 21.672821431038887,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.860812428896698,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3841,
                  name: 'Basic Wall [843976]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1750.073294667924,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.345137155464019,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.7055581045211711,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3853,
                  name: 'Basic Wall [845937]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 11999.906726476584,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 53.0785709417291,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 7.0063713643073955,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3884,
                  name: 'Basic Wall [848212]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 958.5360961206256,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.19212098258833826,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.02535996970165911,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3885,
                  name: 'Basic Wall [848385]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 16431.956260093088,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.8925297211218552,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5138139231880151,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3886,
                  name: 'Basic Wall [848439]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 9431.742593986757,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.2164595095868687,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.29257265526544846,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3887,
                  name: 'Basic Wall [848573]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7015.934125524412,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.655976857952636,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2177860746614909,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3888,
                  name: 'Basic Wall [848660]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 761.6226398506157,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.16840368779285741,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.021426416200421648,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3889,
                  name: 'Basic Wall [848727]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 760.663316739621,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.20141528480548218,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.025791975711972295,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3890,
                  name: 'Basic Wall [848799]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 921.9767479558323,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.22155818922148154,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.028450839094876994,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3891,
                  name: 'Basic Wall [848831]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 16431.95626009326,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.830489721121886,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.50562464318802,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3892,
                  name: 'Basic Wall [848911]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1021.2589756800487,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.20470367069794546,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.027020884532130942,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3893,
                  name: 'Basic Wall [848990]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1022.4869440643876,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.20494980771291352,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.02705337461810183,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3894,
                  name: 'Basic Wall [849099]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 958.5360961206045,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.19212098258833818,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.025359969701658856,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3895,
                  name: 'Basic Wall [849142]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 958.5360961205757,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.1921209825883314,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.025359969701660007,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3896,
                  name: 'Basic Wall [849356]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 958.5360961205564,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.1921209825883314,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.025359969701656992,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3897,
                  name: 'Basic Wall [849465]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 958.5360961204985,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.1921209825883224,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.02535996970165583,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3898,
                  name: 'Basic Wall [849559]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 958.5360961205081,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.19212098258832916,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.02535996970166048,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3899,
                  name: 'Basic Wall [849653]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 958.5360961204987,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.19212098258831792,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.02535996970166238,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3900,
                  name: 'Basic Wall [849747]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 958.5360961204603,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.19212098258831334,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.025359969701652517,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3901,
                  name: 'Basic Wall [849834]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 958.5360961203736,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.1921209825883156,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.025359969701660448,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3902,
                  name: 'Basic Wall [849923]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 958.53609612047,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.19212098258830884,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.025359969701657156,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3903,
                  name: 'Basic Wall [850025]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 958.5360961204217,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.19212098258830432,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.02535996970165598,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3904,
                  name: 'Basic Wall [850142]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 958.5360961204123,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.19212098258830204,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.025359969701657343,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3905,
                  name: 'Basic Wall [850684]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 777.6581174919844,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.17272111479991586,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.021877893153587343,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3906,
                  name: 'Basic Wall [850763]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 778.9864615747055,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.17307472364730805,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.02191526352144563,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3907,
                  name: 'Basic Wall [850845]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 777.5965403121685,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.172706644162635,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.021875983029466174,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3908,
                  name: 'Basic Wall [850919]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 777.596540312077,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.17270664416266682,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.02187598302947204,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3909,
                  name: 'Basic Wall [851005]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1017.7846242368418,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.20384476255342937,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.026907508657048496,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3910,
                  name: 'Basic Wall [851103]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1017.784624236815,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.20384476255343875,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.02690750865705048,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3933,
                  name: 'Basic Wall [854902]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6453.242472185569,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 21.942768782683665,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.8964454793140098,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3937,
                  name: 'Basic Wall [856113]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2524.865375281762,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 7.099380726282749,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.937118255869211,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3942,
                  name: 'Basic Wall [857119]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 175.7032445859026,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.130207277457169,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1342497329617227,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3943,
                  name: 'Basic Wall [857144]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 746.3442570106241,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.355918897732935,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2982100572873924,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3944,
                  name: 'Basic Wall [857207]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1719.36818013874,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.34941922079363,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6687192692202653,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3945,
                  name: 'Basic Wall [857254]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2326.0158839498067,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.91870041732973,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.8880951516823882,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3946,
                  name: 'Basic Wall [857307]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 671.4559973435606,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.571178227099714,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.26887171285966754,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3947,
                  name: 'Basic Wall [857351]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1948.7871576168375,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.84512612929791,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6020807670999456,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3970,
                  name: 'Basic Wall [859487]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1593.1549711332998,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.5496164758056943,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.46854781246525773,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3971,
                  name: 'Basic Wall [859647]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1076.7579097996463,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.4576190356684516,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.05456653178155461,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3972,
                  name: 'Basic Wall [859923]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1610.6130150852905,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.3292191831799779,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.035857233697287216,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3973,
                  name: 'Basic Wall [859986]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3490.483073099157,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.23523029110424595,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.030889999191851376,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3974,
                  name: 'Basic Wall [860034]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2790.656070250804,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.8486791766131357,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.10639362303826815,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3975,
                  name: 'Basic Wall [860103]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 817.9576441392006,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.09276997678581894,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0071799580592309155,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3976,
                  name: 'Basic Wall [860198]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 291.67468025270244,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.4725693364444223,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1914862011785397,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 6094,
                  name: 'Basic Wall [876826]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 24324.72065248234,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 42.41427216245006,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 5.598683925442761,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 132,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.07.01.03.000_Metal Stud Partition Type 4 K10/128',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 132,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 3670,
              name: '03.02.07.01.03.010_Metal Stud Partition Type 5 K10/129',
              children: [
                {
                  id: 3671,
                  name: 'Basic Wall [814772]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6180.765331686633,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 17.49315725698055,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.134165185351736,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3673,
                  name: 'Basic Wall [815750]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 454.38041210231444,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.6128596896217635,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.19676888213382307,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3697,
                  name: 'Basic Wall [821092]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2574.7114194499013,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 7.8263168197582615,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9548106520104345,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3698,
                  name: 'Basic Wall [821326]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1610.5841481527425,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.9821950074341252,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.48582779090691436,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3818,
                  name: 'Basic Wall [840413]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1008.914326953431,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.337405903980761,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5291635202856695,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3819,
                  name: 'Basic Wall [840637]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2464.2448567436886,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 11.03192634100834,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.3458950136029666,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3833,
                  name: 'Basic Wall [842756]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1586.0000000000232,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.548999999992991,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5549779999990814,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3840,
                  name: 'Basic Wall [843810]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1586.7023025443857,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.552230591697104,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5553721321869582,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3842,
                  name: 'Basic Wall [844135]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1590.6090602912213,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.547201677331833,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.554758604634462,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3843,
                  name: 'Basic Wall [844288]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1585.6090602913898,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.547201677332904,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5547586046346519,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3844,
                  name: 'Basic Wall [844457]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4767.320835772458,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 14.04707584452834,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.713743253032483,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3845,
                  name: 'Basic Wall [844554]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1659.36314871163,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.231470484064889,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6382393990557707,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3846,
                  name: 'Basic Wall [844792]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 610.7910439598422,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.529038802212307,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.3085427338698912,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3847,
                  name: 'Basic Wall [844886]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7113.952619705032,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 33.00478205060524,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 4.02658341017368,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3848,
                  name: 'Basic Wall [845141]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2409.1540649389804,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 8.680508698706731,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.059022061242203,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3849,
                  name: 'Basic Wall [845294]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 209.948321345896,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.6851622781904114,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.08358979793922515,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3850,
                  name: 'Basic Wall [845592]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2363.802228249366,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 8.471890249934834,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.0335706104920108,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3851,
                  name: 'Basic Wall [845715]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1199.9770519911724,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.3988944391530738,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.414665121576671,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3852,
                  name: 'Basic Wall [845797]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4361.233688405561,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 17.02807496664311,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.0774251459303765,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3984,
                  name: 'Basic Wall [861515]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2960.984494394122,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.0703205005405336,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.374579101066013,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7678,
                  name: 'Basic Wall [946511]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1237.7511029796947,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.0978682014558099,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.133939920577603,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 122,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.07.01.03.010_Metal Stud Partition Type 5 K10/129',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 122,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 3680,
              name: '03.02.07.01.03.030_Concrete blockwork 7 N/mm2 F10/357',
              children: [
                {
                  id: 3681,
                  name: 'Basic Wall [817197]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6549.694138815631,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 28.3274271503778,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 4.249114072556441,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3682,
                  name: 'Basic Wall [817556]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2400.000000000112,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.380000000000507,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.5569999999999877,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3683,
                  name: 'Basic Wall [817830]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5650.000007341429,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 18.434366228320002,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.7651549342477524,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3684,
                  name: 'Basic Wall [817943]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1677.9825646083705,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 7.257274591931209,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.0885911887898128,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3685,
                  name: 'Basic Wall [818197]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2263.7289614632464,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 9.202427758328646,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.3803641637493966,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3686,
                  name: 'Basic Wall [818237]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1653.2606693128898,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.50160239477822,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9752403592168719,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3687,
                  name: 'Basic Wall [818355]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 543.5275722452279,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.162499999998655,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.32437499999979963,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3688,
                  name: 'Basic Wall [818613]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1543.6578762480235,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.543710806894189,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9319935125243352,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3689,
                  name: 'Basic Wall [818948]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2038.7597110603037,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 8.548907992419721,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.1850071060304954,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3690,
                  name: 'Basic Wall [819076]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 650.5027866564607,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.4890495522891354,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.3733574328432869,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3691,
                  name: 'Basic Wall [819264]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 560.3313001113559,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.611689622943077,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.3439874591186692,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3713,
                  name: 'Basic Wall [823948]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5449.974777867803,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 23.571140914278278,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.535671137141411,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3714,
                  name: 'Basic Wall [824137]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2395.203542804144,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.68363032262791,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.6025445483943026,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3715,
                  name: 'Basic Wall [824156]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6107.56995282887,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 22.28986504598519,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.34347975689779,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3780,
                  name: 'Basic Wall [831619]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1009.9896627150707,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.02019979325429742,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.003029968988137562,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3782,
                  name: 'Basic Wall [831936]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1415.0000000000032,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.5635750000000155,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2345362500000889,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3783,
                  name: 'Basic Wall [832104]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1414.9809216303547,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.5654533036146965,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.23481799554219054,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3784,
                  name: 'Basic Wall [832153]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1414.9810571254197,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.5635540681236781,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.23453311021856568,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3794,
                  name: 'Basic Wall [835592]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1010.0000000000011,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.2518845262622222,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.337782678939296,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3796,
                  name: 'Basic Wall [835919]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1550.0000040280793,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.063787515205969,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.7595681272810195,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.07.01.03.030_Concrete blockwork 7 N/mm2 F10/357',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 150,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 3719,
              name: '03.02.07.04.02.000_Opaque Screen',
              children: [
                {
                  id: 3720,
                  name: 'Basic Wall [824992]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5050.003290426264,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 11.236257321198423,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5618128660598088,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3721,
                  name: 'Basic Wall [825115]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2740.2031430945126,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.950101993385268,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.297505099669127,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3722,
                  name: 'Basic Wall [825223]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4499.612846828132,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 9.956013584192762,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.49780067920953724,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3723,
                  name: 'Basic Wall [825311]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 719.5982891204925,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.6011061932930934,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0800553096646848,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3724,
                  name: 'Basic Wall [825416]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4569.845770772151,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.167906839968023,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5083953419982987,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3725,
                  name: 'Basic Wall [825541]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4768.783996579406,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.666169392389255,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5333084696193894,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3726,
                  name: 'Basic Wall [825684]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1118.4122124083808,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.432842172608591,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.12164210863042707,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3727,
                  name: 'Basic Wall [825773]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3277.618124061029,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 7.348325326035791,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.367416266301716,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3728,
                  name: 'Basic Wall [825892]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1349.4830870539638,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.0026027044325647,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.15012999343844644,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3729,
                  name: 'Basic Wall [826015]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 129.99999999997027,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.23362783559278244,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.011681249996381015,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3730,
                  name: 'Basic Wall [826077]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3415.2145792151164,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 7.598852438753666,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.37994262193767925,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3731,
                  name: 'Basic Wall [826203]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6176.638629488232,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 13.68739595061135,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6843697975305614,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3732,
                  name: 'Basic Wall [826412]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1653.0214952436452,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.190393908530155,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.10951260365962952,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3733,
                  name: 'Basic Wall [826614]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4188.144140049043,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.629261809818565,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.27980684049101884,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3734,
                  name: 'Basic Wall [826752]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 538.6740387306234,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7000222770645,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.033344863853212066,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3735,
                  name: 'Basic Wall [827160]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 983.8819213802402,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.355535748506961,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.06519245423000783,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3939,
                  name: 'Basic Wall [856367]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2187.1276251732825,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.179021163072184,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.258951058153453,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3940,
                  name: 'Basic Wall [856438]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2425.33169650326,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 7.92109459846092,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.396054729922989,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3956,
                  name: 'Basic Wall [858247]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 722.9602177619331,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.9910472885346135,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.04955236442672981,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3957,
                  name: 'Basic Wall [858273]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1798.5999963445472,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.3500199951565452,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.11750099975787144,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3958,
                  name: 'Basic Wall [858314]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 176.10251137982263,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.266460827578257,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.013323041378909984,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3959,
                  name: 'Basic Wall [858341]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1674.0177868879653,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.184948567626549,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.10924742838129066,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3960,
                  name: 'Basic Wall [858464]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3076.5156126813754,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.076383186802802,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.20381915934021685,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3961,
                  name: 'Basic Wall [858628]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 155.00000000005883,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.34487500000010923,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0172437500000059,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3962,
                  name: 'Basic Wall [858724]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 952.5908433761273,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.11951462651188,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.10597573132557281,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.07.04.02.000_Opaque Screen',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 50,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 3835,
              name: '03.02.07.01.03.040_150 Pre-Cast Wall E60',
              children: [
                {
                  id: 3836,
                  name: 'Basic Wall [842970]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1627.40310667424,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.365054290701434,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.8047581436052376,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 149.99999999999997,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3837,
                  name: 'Basic Wall [843254]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5950.000007926352,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 26.11001762350854,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.9165000054010486,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 149.99999999999997,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3838,
                  name: 'Basic Wall [843476]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 11069.708042956552,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 49.31565699759991,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 7.397348549638979,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 149.99999999999997,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3839,
                  name: 'Basic Wall [843727]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 18150.000023583998,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 77.56000010848643,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 11.634000016273896,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 149.99999999999997,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3932,
                  name: 'Basic Wall [854795]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 146.7575237706195,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.6750846093448489,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.10126269140173944,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 149.99999999999997,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.07.01.03.040_150 Pre-Cast Wall E60',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 149.99999999999997,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 3964,
              name: '03.02.07.04.02.010_ Semi Opaque Screen',
              children: [
                {
                  id: 3965,
                  name: 'Basic Wall [858989]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1089.6849399082232,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.70363720655967,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2511148834467095,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3966,
                  name: 'Basic Wall [859179]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1546.2909410036507,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.4106608726551753,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.32181725005624967,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3967,
                  name: 'Basic Wall [859266]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2151.428179666133,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.78692769975718,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.4786927699756201,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3968,
                  name: 'Basic Wall [859345]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2734.7671945476814,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.277427694149767,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.621327746653244,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3969,
                  name: 'Basic Wall [859401]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 827.9326882617628,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.7778800003363688,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.17137297727186088,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.07.04.02.010_ Semi Opaque Screen',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 100,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 4008,
              name: '03.02.07.04.01.000_Toilet Partition',
              children: [
                {
                  id: 4009,
                  name: 'Basic Wall [862180]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 934.3039781471026,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.1838817491919538,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.021838817491898498,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 9.999999999999993,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4010,
                  name: 'Basic Wall [862292]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2182.6628848924956,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.241241207375098,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.03241241207371939,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 9.999999999999993,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4011,
                  name: 'Basic Wall [862644]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2238.662884892484,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.2412412073752095,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.03241241207367145,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 9.999999999999993,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4014,
                  name: 'Basic Wall [863054]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2427.711918690402,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.822605210955462,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.03822605210951421,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 9.999999999999993,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4016,
                  name: 'Basic Wall [863117]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1860.4705247594877,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.969835154470854,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0396983515446701,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 9.999999999999993,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4017,
                  name: 'Basic Wall [863216]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4123.812787013462,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.2071881314295685,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.02207188131419896,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 9.999999999999993,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4018,
                  name: 'Basic Wall [863271]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1860.4705247594877,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.9478351544712136,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.03947835154467389,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 9.999999999999993,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4019,
                  name: 'Basic Wall [863301]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1860.4705247595166,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.9478351544716572,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.03947835154463964,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 9.999999999999993,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4020,
                  name: 'Basic Wall [863327]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1860.4705247595166,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.9478351544720387,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.03947835154468236,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 9.999999999999993,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4025,
                  name: 'Basic Wall [863754]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4724.979508074154,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.960754917763005,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.03960754917773403,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 9.999999999999993,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4026,
                  name: 'Basic Wall [863755]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1860.470524758959,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.947835154469924,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.03947835154462301,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 9.999999999999993,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4027,
                  name: 'Basic Wall [863756]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1860.470524759286,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.947835154471128,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.03947835154463504,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 9.999999999999993,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4028,
                  name: 'Basic Wall [863757]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1860.4705247595068,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.947835154471891,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.03947835154464259,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 9.999999999999993,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 6820,
                  name: 'Basic Wall [893628]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1263.1577409096758,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.644747030001369,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.026447470299962358,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 9.999999999999993,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 6828,
                  name: 'Basic Wall [894645]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1185.5335221946934,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.4739737488284708,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.024739737488276327,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 9.999999999999993,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.07.04.01.000_Toilet Partition',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 9.999999999999993,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Basic Wall',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Walls',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Walls',
          displayCategory: '__category__',
        },
      ],
    },
    {
      id: 3739,
      name: 'Doors',
      children: [
        {
          id: 3740,
          name: 'Single-Flush_instance param',
          children: [
            {
              id: 3742,
              name: '03.02.08.01.01.000_Internal Single Door L20/416',
              children: [
                {
                  id: 3743,
                  name: 'Single-Flush_instance param [827915]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3744,
                  name: 'Single-Flush_instance param [827931]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999998,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3745,
                  name: 'Single-Flush_instance param [828686]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999918,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3746,
                  name: 'Single-Flush_instance param [828729]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999998862,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3747,
                  name: 'Single-Flush_instance param [828841]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.999999999915,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3748,
                  name: 'Single-Flush_instance param [828890]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1010.000000000155,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3754,
                  name: 'Single-Flush_instance param [829303]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3755,
                  name: 'Single-Flush_instance param [829371]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3762,
                  name: 'Single-Flush_instance param [829627]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1010.0000000000687,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3763,
                  name: 'Single-Flush_instance param [829767]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999998669,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3765,
                  name: 'Single-Flush_instance param [830094]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999998669,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3766,
                  name: 'Single-Flush_instance param [830110]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.999999999867,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3767,
                  name: 'Single-Flush_instance param [830273]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999726,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3768,
                  name: 'Single-Flush_instance param [830543]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 900,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3769,
                  name: 'Single-Flush_instance param [830690]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3771,
                  name: 'Single-Flush_instance param [830887]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3772,
                  name: 'Single-Flush_instance param [830941]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 810.0000000000001,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3773,
                  name: 'Single-Flush_instance param [830973]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 810.0000000000001,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3774,
                  name: 'Single-Flush_instance param [831040]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 810.0000000000001,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3776,
                  name: 'Single-Flush_instance param [831100]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3777,
                  name: 'Single-Flush_instance param [831161]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3779,
                  name: 'Single-Flush_instance param [831519]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 750,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3781,
                  name: 'Single-Flush_instance param [831784]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9896627146298,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3795,
                  name: 'Single-Flush_instance param [835814]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3801,
                  name: 'Single-Flush_instance param [836670]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9531385677852,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3802,
                  name: 'Single-Flush_instance param [836691]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9858635031876,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3854,
                  name: 'Single-Flush_instance param [846182]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3855,
                  name: 'Single-Flush_instance param [846358]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3856,
                  name: 'Single-Flush_instance param [846452]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3857,
                  name: 'Single-Flush_instance param [846527]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3858,
                  name: 'Single-Flush_instance param [846599]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3859,
                  name: 'Single-Flush_instance param [846655]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3860,
                  name: 'Single-Flush_instance param [846746]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3861,
                  name: 'Single-Flush_instance param [846835]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3862,
                  name: 'Single-Flush_instance param [846934]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3863,
                  name: 'Single-Flush_instance param [846982]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3864,
                  name: 'Single-Flush_instance param [847082]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3865,
                  name: 'Single-Flush_instance param [847146]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3866,
                  name: 'Single-Flush_instance param [847202]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3867,
                  name: 'Single-Flush_instance param [847242]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3868,
                  name: 'Single-Flush_instance param [847308]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3869,
                  name: 'Single-Flush_instance param [847352]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3870,
                  name: 'Single-Flush_instance param [847430]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3871,
                  name: 'Single-Flush_instance param [847489]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3872,
                  name: 'Single-Flush_instance param [847542]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3873,
                  name: 'Single-Flush_instance param [847583]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3874,
                  name: 'Single-Flush_instance param [847632]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3875,
                  name: 'Single-Flush_instance param [847677]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3876,
                  name: 'Single-Flush_instance param [847721]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3877,
                  name: 'Single-Flush_instance param [847778]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3878,
                  name: 'Single-Flush_instance param [847832]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3879,
                  name: 'Single-Flush_instance param [847888]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3880,
                  name: 'Single-Flush_instance param [847945]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3881,
                  name: 'Single-Flush_instance param [847989]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3882,
                  name: 'Single-Flush_instance param [848045]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3883,
                  name: 'Single-Flush_instance param [848090]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3930,
                  name: 'Single-Flush_instance param [854624]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3931,
                  name: 'Single-Flush_instance param [854689]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3934,
                  name: 'Single-Flush_instance param [855072]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3941,
                  name: 'Single-Flush_instance param [856532]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.999999999867,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3948,
                  name: 'Single-Flush_instance param [857433]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2260,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 910.0000000000001,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3977,
                  name: 'Single-Flush_instance param [860420]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 750,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3981,
                  name: 'Single-Flush_instance param [861049]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4006,
                  name: 'Single-Flush_instance param [861814]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9531385677852,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4012,
                  name: 'Single-Flush_instance param [862671]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4013,
                  name: 'Single-Flush_instance param [862806]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4015,
                  name: 'Single-Flush_instance param [863055]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4021,
                  name: 'Single-Flush_instance param [863451]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4022,
                  name: 'Single-Flush_instance param [863594]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4023,
                  name: 'Single-Flush_instance param [863635]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4024,
                  name: 'Single-Flush_instance param [863674]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4029,
                  name: 'Single-Flush_instance param [863759]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 750,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4030,
                  name: 'Single-Flush_instance param [863760]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 750,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4031,
                  name: 'Single-Flush_instance param [863761]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 750,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4032,
                  name: 'Single-Flush_instance param [863762]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 750,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4033,
                  name: 'Single-Flush_instance param [864157]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 6137,
                  name: 'Single-Flush_instance param [878439]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 899.9999999999562,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 6807,
                  name: 'Single-Flush_instance param [890722]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.08.01.01.000_Internal Single Door L20/416',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Single-Flush_instance param',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Doors',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 50.8,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Single-Flush_instance param',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Doors',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3749,
          name: 'Double-Flush_instance param',
          children: [
            {
              id: 3751,
              name: '03.02.08.01.01.010_Internal Double Door L20/416',
              children: [
                {
                  id: 3752,
                  name: 'Double-Flush_instance param [829132]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1929.8784863952994,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3753,
                  name: 'Double-Flush_instance param [829200]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1926.3503721605427,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3770,
                  name: 'Double-Flush_instance param [830746]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3798,
                  name: 'Double-Flush_instance param [836191]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1810,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3925,
                  name: 'Double-Flush_instance param [854028]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3926,
                  name: 'Double-Flush_instance param [854181]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1810,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3927,
                  name: 'Double-Flush_instance param [854197]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1810,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3938,
                  name: 'Double-Flush_instance param [856210]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2150,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.08.01.01.010_Internal Double Door L20/416',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Double-Flush_instance param',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Doors',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 50.8,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Double-Flush_instance param',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Doors',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3788,
          name: 'Revolving',
          children: [
            {
              id: 3790,
              name: '03.02.08.01.01.020_Internal Security Airlocks L20/497',
              children: [
                {
                  id: 3791,
                  name: 'Revolving [835432]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2370,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 0,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3792,
                  name: 'Revolving [835489]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2370,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 0,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3793,
                  name: 'Revolving [835537]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2370,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 0,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.08.01.01.020_Internal Security Airlocks L20/497',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Revolving',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Doors',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 2370,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 0,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 0,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Revolving',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Doors',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Doors',
          displayCategory: '__category__',
        },
      ],
    },
    {
      id: 3756,
      name: 'Windows',
      children: [
        {
          id: 3757,
          name: 'Fixed Window_Instance param',
          children: [
            {
              id: 3759,
              name: '03.02.06.01.01.050_Steel Framed Window NBS L10/310',
              children: [
                {
                  id: 3760,
                  name: 'Fixed Window_Instance param [829461]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3761,
                  name: 'Fixed Window_Instance param [829559]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3764,
                  name: 'Fixed Window_Instance param [830027]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3778,
                  name: 'Fixed Window_Instance param [831259]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3797,
                  name: 'Fixed Window_Instance param [836019]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3803,
                  name: 'Fixed Window_Instance param [837208]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 850,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1415,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3804,
                  name: 'Fixed Window_Instance param [837357]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 850,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1415,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3805,
                  name: 'Fixed Window_Instance param [837453]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 850,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1415,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3911,
                  name: 'Fixed Window_Instance param [851285]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1850,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3912,
                  name: 'Fixed Window_Instance param [851513]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3913,
                  name: 'Fixed Window_Instance param [851756]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 550,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3914,
                  name: 'Fixed Window_Instance param [851971]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3915,
                  name: 'Fixed Window_Instance param [852167]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 550,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3916,
                  name: 'Fixed Window_Instance param [852372]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1850,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3917,
                  name: 'Fixed Window_Instance param [852785]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1575,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3918,
                  name: 'Fixed Window_Instance param [853080]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1575,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3920,
                  name: 'Fixed Window_Instance param [853261]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1575,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3922,
                  name: 'Fixed Window_Instance param [853365]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1575,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3924,
                  name: 'Fixed Window_Instance param [853581]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1575,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3935,
                  name: 'Fixed Window_Instance param [855166]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1000.0000000000152,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 3500.1465893360823,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3936,
                  name: 'Fixed Window_Instance param [855892]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1000.0000000000152,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 6626.042239478053,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3949,
                  name: 'Fixed Window_Instance param [857548]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3950,
                  name: 'Fixed Window_Instance param [857739]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 530,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3951,
                  name: 'Fixed Window_Instance param [857799]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1037.2655850095314,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3952,
                  name: 'Fixed Window_Instance param [857997]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1037.2655850095314,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3954,
                  name: 'Fixed Window_Instance param [858102]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1330.0990664917852,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3955,
                  name: 'Fixed Window_Instance param [858182]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 640,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3978,
                  name: 'Fixed Window_Instance param [860792]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1484.9999999999916,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 3346.5986230268963,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3979,
                  name: 'Fixed Window_Instance param [860873]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1484.9999999999916,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1389.5771316506812,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3980,
                  name: 'Fixed Window_Instance param [860902]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1484.9999999999916,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 890,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3982,
                  name: 'Fixed Window_Instance param [861197]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1484.9999999999916,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 580,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3983,
                  name: 'Fixed Window_Instance param [861430]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1484.9999999999916,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2219.1838536428163,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.01.01.050_Steel Framed Window NBS L10/310',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Fixed Window_Instance param',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Windows',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Fixed Window_Instance param',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Windows',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Windows',
          displayCategory: '__category__',
        },
      ],
    },
    {
      id: 4036,
      name: 'Ceilings',
      children: [
        {
          id: 4037,
          name: 'Compound Ceiling',
          children: [
            {
              id: 4039,
              name: '03.02.02.03.02.010_Suspended tile and grid ceiling with clips system K40/115',
              children: [
                {
                  id: 4040,
                  name: 'Compound Ceiling [864545]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.244147155562166,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5854530099403779,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4053,
                  name: 'Compound Ceiling [864792]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 15.238776653693304,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.8708960857585724,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4068,
                  name: 'Compound Ceiling [864900]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.9522841894139353,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.22587304142500642,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4077,
                  name: 'Compound Ceiling [864943]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.099344427236911,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5771775340165896,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4086,
                  name: 'Compound Ceiling [864982]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 9.829300309677693,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5617445126980801,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4097,
                  name: 'Compound Ceiling [865039]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 14.766050269087195,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.8438797728783333,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4174,
                  name: 'Compound Ceiling [865661]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 40.59077334785401,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.3197626434591045,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.02.03.02.010_Suspended tile and grid ceiling with clips system K40/115',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Compound Ceiling',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ceilings',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 57.15,
                  displayCategory: 'Construction',
                },
              ],
            },
            {
              id: 4105,
              name: '03.02.02.03.02.000_Suspended tile and grid ceiling K40/115',
              children: [
                {
                  id: 4106,
                  name: 'Compound Ceiling [865134]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 13.821703682319166,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.7899103654445403,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4119,
                  name: 'Compound Ceiling [865309]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 15.791511816618463,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9024849003197454,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4130,
                  name: 'Compound Ceiling [865416]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 9.757410891115452,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.557636032427248,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4141,
                  name: 'Compound Ceiling [865467]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.701922612931565,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2687148773290389,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4152,
                  name: 'Compound Ceiling [865528]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 59.2515831299224,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.3862281303150565,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4213,
                  name: 'Compound Ceiling [865893]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 7.896944856199955,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.45131039853182753,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4222,
                  name: 'Compound Ceiling [865907]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.395878182353508,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.30837443812150306,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4233,
                  name: 'Compound Ceiling [865959]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.969526173783648,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1697084208317355,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4240,
                  name: 'Compound Ceiling [865971]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.6683309869920766,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.15249511590659717,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4252,
                  name: 'Compound Ceiling [866005]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.3454127239328626,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1911903371727631,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4269,
                  name: 'Compound Ceiling [866098]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.7179134197490376,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.15532875193865756,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4276,
                  name: 'Compound Ceiling [866130]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 20.65847693024897,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.1806319565637284,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4287,
                  name: 'Compound Ceiling [866184]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 28.05685854485927,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.6034494658387073,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4303,
                  name: 'Compound Ceiling [866379]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.040075758692006,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.3451903296092482,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4310,
                  name: 'Compound Ceiling [866387]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.4496688051110924,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.13999857221209888,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4318,
                  name: 'Compound Ceiling [866396]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.224767767560906,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.12714547791610578,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4325,
                  name: 'Compound Ceiling [866404]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.631337869842624,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.20753095926150594,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4334,
                  name: 'Compound Ceiling [866474]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 17.89115012929338,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.0224792298891168,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4345,
                  name: 'Compound Ceiling [866526]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 62.28400014615628,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.5595268718123982,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4386,
                  name: 'Compound Ceiling [866761]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.514693920114555,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.029414757534546813,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4476,
                  name: 'Compound Ceiling [867735]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 25.167503877055086,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.438322846573698,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4488,
                  name: 'Compound Ceiling [868059]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.047430553295781,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2884606561208546,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4501,
                  name: 'Compound Ceiling [868115]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 22.462322425267367,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.28372172660403,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4516,
                  name: 'Compound Ceiling [868170]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 19.448803713519652,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.1114991322276482,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4531,
                  name: 'Compound Ceiling [868220]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 15.18576355569474,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.8678663872079542,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4542,
                  name: 'Compound Ceiling [868232]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.500169216664319,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2571846707323659,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4549,
                  name: 'Compound Ceiling [868240]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.548127484828116,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.20277548575792687,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4556,
                  name: 'Compound Ceiling [868252]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.8327363288001184,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.21904088119092666,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4564,
                  name: 'Compound Ceiling [868284]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 17.236072021904587,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.985041516051846,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4579,
                  name: 'Compound Ceiling [868378]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.8319717643577493,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2189971863330454,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4589,
                  name: 'Compound Ceiling [868414]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 8.450659146121675,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.4829551702008536,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4596,
                  name: 'Compound Ceiling [868426]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 19.503684902326505,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.1146355234199414,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4621,
                  name: 'Compound Ceiling [868556]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 41.48238868253238,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.3707185132067257,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4638,
                  name: 'Compound Ceiling [868755]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 21.005665058784366,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.2004737581095266,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4651,
                  name: 'Compound Ceiling [868804]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 123.21350509103107,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 7.041653535262555,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4679,
                  name: 'Compound Ceiling [869052]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 45.59324512521647,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.6056539589061214,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4689,
                  name: 'Compound Ceiling [869110]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 16.241555008393465,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9282048687296865,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4699,
                  name: 'Compound Ceiling [869145]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.1074278098464365,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2918894993327239,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4714,
                  name: 'Compound Ceiling [869205]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 14.41356296562039,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.8237351234852051,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4738,
                  name: 'Compound Ceiling [869339]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.6503811960868617,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.20861928535636415,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4754,
                  name: 'Compound Ceiling [869565]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.284258625501289,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.30199538044739865,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4771,
                  name: 'Compound Ceiling [869649]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 22.448976466984565,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.2829590050881674,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4785,
                  name: 'Compound Ceiling [869746]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 15.416083927677816,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.881029196466787,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4803,
                  name: 'Compound Ceiling [869806]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 15.35463579663751,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.877517435777835,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4825,
                  name: 'Compound Ceiling [869926]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 22.96276124106134,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.3123218049266554,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4840,
                  name: 'Compound Ceiling [870035]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 15.223697000127105,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.8700342835572639,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4848,
                  name: 'Compound Ceiling [870044]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 16.46103889727415,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9407483729792175,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4858,
                  name: 'Compound Ceiling [870059]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 41.13679286104529,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.3509677120087393,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4873,
                  name: 'Compound Ceiling [870176]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 18.80591555156803,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.0747580737721132,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4886,
                  name: 'Compound Ceiling [870233]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.471064503687219,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.36982133638572456,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 4896,
                  name: 'Compound Ceiling [870267]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.427739782246702,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.3673453285553989,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6800,
                  name: 'Compound Ceiling [888687]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.3199927978751167,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.07543758839856292,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.02.03.02.000_Suspended tile and grid ceiling K40/115',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Compound Ceiling',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ceilings',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 57.15,
                  displayCategory: 'Construction',
                },
              ],
            },
            {
              id: 4193,
              name: '03.02.02.03.02.020_Secure plank suspended ceiling K40/106',
              children: [
                {
                  id: 4194,
                  name: 'Compound Ceiling [865790]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 57.91927360784511,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.310086486688343,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.02.03.02.020_Secure plank suspended ceiling K40/106',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Compound Ceiling',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ceilings',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 57.15,
                  displayCategory: 'Construction',
                },
              ],
            },
            {
              id: 4372,
              name: '03.02.02.02.01.000_Bulkheads K10/223',
              children: [
                {
                  id: 4373,
                  name: 'Compound Ceiling [866680]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.3026211698370582,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.07444479985618786,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.02.02.01.000_Bulkheads K10/223',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Compound Ceiling',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ceilings',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 57.15,
                  displayCategory: 'Construction',
                },
              ],
            },
            {
              id: 4398,
              name: '03.02.02.03.02.030_Ecophon Solo suspended panels K40/118',
              children: [
                {
                  id: 4399,
                  name: 'Compound Ceiling [866810]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 185.64335798709828,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 10.609517178158917,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 57.15,
                      displayCategory: 'Construction',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.02.03.02.030_Ecophon Solo suspended panels K40/118',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Compound Ceiling',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ceilings',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 57.15,
                  displayCategory: 'Construction',
                },
              ],
            },
            {
              id: 6141,
              name: '03.02.02.02.01.010_Acoustic panel lining, K13/170',
              children: [
                {
                  id: 6142,
                  name: 'Compound Ceiling [880473]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 69.49190613673224,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.8845975530432515,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 55.9,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6150,
                  name: 'Compound Ceiling [880765]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 63.19117575506701,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.532386724708192,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 55.9,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6158,
                  name: 'Compound Ceiling [880824]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 96.49718993390177,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 5.394192917304823,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 55.9,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6166,
                  name: 'Compound Ceiling [880914]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 94.30881704644214,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 5.271862872896153,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 55.9,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6178,
                  name: 'Compound Ceiling [882150]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.0295666002066275,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.3370527729515261,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 55.9,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6185,
                  name: 'Compound Ceiling [882864]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 30.715507123614195,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.7169968482099924,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 55.9,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6193,
                  name: 'Compound Ceiling [882881]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 24.462408309354178,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.367448624492877,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 55.9,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6201,
                  name: 'Compound Ceiling [883355]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.0295666002066275,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.3370527729515261,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 55.9,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6208,
                  name: 'Compound Ceiling [883362]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 30.715507123614195,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.7169968482100204,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 55.9,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6216,
                  name: 'Compound Ceiling [883370]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 24.462408309353222,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.3674486244928248,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 55.9,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6224,
                  name: 'Compound Ceiling [883411]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 24.603090014545565,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.375312731813177,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 55.9,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6232,
                  name: 'Compound Ceiling [884105]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 26.99120636407675,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.5088084357519778,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 55.9,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6240,
                  name: 'Compound Ceiling [884130]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 26.454041434879592,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.4787809162098555,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 55.9,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6251,
                  name: 'Compound Ceiling [884591]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.107272082766565,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.15879820383009413,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 55.9,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6259,
                  name: 'Compound Ceiling [885009]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.107563333575095,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.15881422454465485,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 55.9,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6267,
                  name: 'Compound Ceiling [885030]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.512711910523307,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.18162063880627474,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 55.9,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6275,
                  name: 'Compound Ceiling [885038]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.5198490660549373,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.18201856280571072,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 55.9,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6283,
                  name: 'Compound Ceiling [885094]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.517309018944249,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.18260357532152346,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 55.9,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6291,
                  name: 'Compound Ceiling [885102]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.518295920789737,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.18265563317905906,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 55.9,
                      displayCategory: 'Construction',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.02.02.01.010_Acoustic panel lining, K13/170',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Compound Ceiling',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ceilings',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 55.9,
                  displayCategory: 'Construction',
                },
              ],
            },
            {
              id: 6459,
              name: '03.02.01.06.01.000_Raised access floor K41/130',
              children: [
                {
                  id: 6460,
                  name: 'Compound Ceiling [888136]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 25.167503877055086,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.258375193852904,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6469,
                  name: 'Compound Ceiling [888145]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.047430553295781,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.25237152766481974,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6478,
                  name: 'Compound Ceiling [888154]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 22.46232242526735,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.1231161212634997,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6489,
                  name: 'Compound Ceiling [888165]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 19.448803713519652,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9724401856760986,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6500,
                  name: 'Compound Ceiling [888176]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 15.18576355569443,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.7592881777848134,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6511,
                  name: 'Compound Ceiling [888187]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.500169216664319,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.22500846083324275,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6518,
                  name: 'Compound Ceiling [888194]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.548127484828116,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.17740637424142694,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6525,
                  name: 'Compound Ceiling [888201]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.8327363288001184,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.19163681644002864,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6532,
                  name: 'Compound Ceiling [888208]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 17.236072021904587,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.8618036010953312,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6543,
                  name: 'Compound Ceiling [888219]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.8319717643577738,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1915985882179115,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6550,
                  name: 'Compound Ceiling [888226]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 8.450659146121675,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.42253295730613394,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6557,
                  name: 'Compound Ceiling [888233]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 19.503684902326505,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9751841849694437,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6572,
                  name: 'Compound Ceiling [888253]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 41.48238868253238,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.074119434126866,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6585,
                  name: 'Compound Ceiling [888266]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 21.005665058784366,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.0502832529393433,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6594,
                  name: 'Compound Ceiling [888275]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 123.21350509103102,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 6.160676758760622,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6617,
                  name: 'Compound Ceiling [888299]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 45.59324512521647,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.2796622562610946,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6624,
                  name: 'Compound Ceiling [888306]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 16.241555008393465,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.81207775041977,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6631,
                  name: 'Compound Ceiling [888313]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.107427809846487,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.25537139049235474,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6642,
                  name: 'Compound Ceiling [888324]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 14.413562965620411,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.7206781482811065,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6659,
                  name: 'Compound Ceiling [888341]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.6503811960868617,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.18251905980436486,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6670,
                  name: 'Compound Ceiling [888352]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.284258625501289,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.26421293127509593,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6681,
                  name: 'Compound Ceiling [888363]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 22.448976466984565,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.1224488233493612,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6692,
                  name: 'Compound Ceiling [888374]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 15.416083927677816,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.7708041963839825,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6703,
                  name: 'Compound Ceiling [888385]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 15.35463579663751,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.7677317898319678,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6718,
                  name: 'Compound Ceiling [888400]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 22.96276124106134,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.1481380620532038,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6729,
                  name: 'Compound Ceiling [888411]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 15.223697000127105,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.7611848500064458,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6737,
                  name: 'Compound Ceiling [888419]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 16.46103889727415,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.8230519448638056,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6747,
                  name: 'Compound Ceiling [888429]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 41.13679286104529,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.05683964305251,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6758,
                  name: 'Compound Ceiling [888440]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 18.80591555156803,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9402957775785136,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6767,
                  name: 'Compound Ceiling [888449]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.471064503687219,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.32355322518439944,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6774,
                  name: 'Compound Ceiling [888456]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.427739782246706,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.32138698911237357,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6789,
                  name: 'Compound Ceiling [888557]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.3199927978751167,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0659996398937637,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50,
                      displayCategory: 'Construction',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.01.06.01.000_Raised access floor K41/130',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Compound Ceiling',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ceilings',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 50,
                  displayCategory: 'Construction',
                },
              ],
            },
            {
              id: 6859,
              name: '03.02.01.02.01.060_100 mm Heavy duty floor screed with 125 mm insulation M10/134',
              children: [
                {
                  id: 6860,
                  name: 'Compound Ceiling [896794]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 37.59013948739495,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 8.457781384663894,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 225,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 7145,
                  name: 'Compound Ceiling [899396]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 11.257275253069249,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.532886931940591,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 225,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 7208,
                  name: 'Compound Ceiling [900086]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 12.835611388270301,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.888012562360829,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 225,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 7215,
                  name: 'Compound Ceiling [900156]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 27.158362296624702,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 6.11063151674058,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 225,
                      displayCategory: 'Construction',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.01.02.01.060_100 mm Heavy duty floor screed with 125 mm insulation M10/134',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Compound Ceiling',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ceilings',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 225,
                  displayCategory: 'Construction',
                },
              ],
            },
            {
              id: 6889,
              name: '03.02.01.02.01.050_100 mm Heavy duty floor screed with 105 mm insulation M10/134',
              children: [
                {
                  id: 6890,
                  name: 'Compound Ceiling [897154]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 176.05676845892933,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 36.0916158936668,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 205.00000000000003,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6911,
                  name: 'Compound Ceiling [897429]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 483.31789805308074,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 99.0801672330822,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 205.00000000000003,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 6982,
                  name: 'Compound Ceiling [898414]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 127.6743316539684,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 26.173236473245996,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 205.00000000000003,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 7014,
                  name: 'Compound Ceiling [898574]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.668274659660288,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5469963052303732,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 205.00000000000003,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 7021,
                  name: 'Compound Ceiling [898625]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 57.802401819137856,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 11.849492372923569,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 205.00000000000003,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 7166,
                  name: 'Compound Ceiling [899553]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 22.610615637037593,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 4.6351762055928285,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 205.00000000000003,
                      displayCategory: 'Construction',
                    },
                  ],
                },
                {
                  id: 7173,
                  name: 'Compound Ceiling [899649]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Ceilings',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 22.38687499642411,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 4.589309374267063,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 205.00000000000003,
                      displayCategory: 'Construction',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.01.02.01.050_100 mm Heavy duty floor screed with 105 mm insulation M10/134',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Compound Ceiling',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Ceilings',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 205.00000000000003,
                  displayCategory: 'Construction',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Compound Ceiling',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Ceilings',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Ceilings',
          displayCategory: '__category__',
        },
      ],
    },
    {
      id: 4911,
      name: 'Floors',
      children: [
        {
          id: 4912,
          name: 'Floor',
          children: [
            {
              id: 4914,
              name: '03.03.40.005_G.031_WC _ Visitors _ female',
              children: [
                {
                  id: 4915,
                  name: 'Floor [871598]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 14.42597085611373,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 47.60570382517522,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.40.005_G.031_WC _ Visitors _ female',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4934,
              name: '03.03.40.006_G.032_WC _ Visitors _ male',
              children: [
                {
                  id: 4935,
                  name: 'Floor [871710]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 9.681540838031362,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 31.94908476550344,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.40.006_G.032_WC _ Visitors _ male',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4951,
              name: '03.03.40.004_G.030_WC _ Visitors _ m/f _ disabled',
              children: [
                {
                  id: 4952,
                  name: 'Floor [871850]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.4639195233419127,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 11.430934427028296,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.40.004_G.030_WC _ Visitors _ m/f _ disabled',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4964,
              name: '03.03.20.002_G.029_Interview Room (no 1)',
              children: [
                {
                  id: 4965,
                  name: 'Floor [871883]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.099344427236607,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 33.327836609880755,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.20.002_G.029_Interview Room (no 1)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4977,
              name: '03.03.20.001_G.028_Interview Room (no 2)',
              children: [
                {
                  id: 4978,
                  name: 'Floor [871915]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 9.829300309677683,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 32.4366910219363,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.20.001_G.028_Interview Room (no 2)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4992,
              name: '03.03.26.001_G.027_Meeting Room',
              children: [
                {
                  id: 4993,
                  name: 'Floor [871970]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 14.766050269087112,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 48.727965887987395,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.26.001_G.027_Meeting Room',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5003,
              name: '03.03.28.009_G.026_De_registration',
              children: [
                {
                  id: 5004,
                  name: 'Floor [872001]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.701922612931657,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 15.516344622674444,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.28.009_G.026_De_registration',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5018,
              name: '03.03.28.008_G.024_Key Supervisor Office',
              children: [
                {
                  id: 5019,
                  name: 'Floor [872041]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 9.757410891115565,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 32.19945594068131,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.28.008_G.024_Key Supervisor Office',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5033,
              name: '03.03.28.007_G.023_Key Exchange Office',
              children: [
                {
                  id: 5034,
                  name: 'Floor [872080]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 15.791511816618508,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 52.111988994840985,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.28.007_G.023_Key Exchange Office',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5048,
              name: '03.03.07.002_G.025_Corridor',
              children: [
                {
                  id: 5049,
                  name: 'Floor [872119]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 13.821703682318862,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 45.61162215165229,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.07.002_G.025_Corridor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5065,
              name: '03.03.28.006_G.022_Key Exchange',
              children: [
                {
                  id: 5066,
                  name: 'Floor [872167]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 59.251602221827696,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 195.53027584807685,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.28.006_G.022_Key Exchange',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5087,
              name: '03.03.43.002_G.021_Visitors Waiting Room',
              children: [
                {
                  id: 5088,
                  name: 'Floor [872268]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 35.17149640650929,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 116.06593503050702,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.43.002_G.021_Visitors Waiting Room',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5103,
              name: '03.03.28.005_G.019_Leave of Absence',
              children: [
                {
                  id: 5104,
                  name: 'Floor [872336]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 58.27927360784296,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 192.32160290588143,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.28.005_G.019_Leave of Absence',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5135,
              name: '03.03.40.003_G.020_WC _ Patient (LoA)',
              children: [
                {
                  id: 5136,
                  name: 'Floor [872460]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.668330986991973,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 8.805492257073498,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.40.003_G.020_WC _ Patient (LoA)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5151,
              name: '03.03.28.004_G.018_Leave of Absence Office',
              children: [
                {
                  id: 5152,
                  name: 'Floor [872490]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 7.896944856199894,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 26.059918025459616,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.28.004_G.018_Leave of Absence Office',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5167,
              name: '03.03.28.003_G.017_Leave of Absence Lobby',
              children: [
                {
                  id: 5168,
                  name: 'Floor [872524]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.39587818235314,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 17.806398001765334,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.28.003_G.017_Leave of Absence Lobby',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5182,
              name: '03.03.36.001_G.012_Air Lock Corridor',
              children: [
                {
                  id: 5183,
                  name: 'Floor [872557]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 20.65847693024915,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 68.17297386982207,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.36.001_G.012_Air Lock Corridor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5197,
              name: '03.03.05.001_G.016_Cleaners cupboard',
              children: [
                {
                  id: 5198,
                  name: 'Floor [872597]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.9695261737835574,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 9.799436373485722,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.05.001_G.016_Cleaners cupboard',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5209,
              name: '03.03.37.001_G.014_Stair lobby',
              children: [
                {
                  id: 5210,
                  name: 'Floor [872621]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.3454127239328137,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 11.039861988978272,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.37.001_G.014_Stair lobby',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5231,
              name: '03.03.40.002_G.009_WC _ Staff _ male',
              children: [
                {
                  id: 5232,
                  name: 'Floor [872670]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.957945079689189,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 6.4612187629743145,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.40.002_G.009_WC _ Staff _ male',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5242,
              name: '03.03.40.001_G.008_WC _ Staff _ female',
              children: [
                {
                  id: 5243,
                  name: 'Floor [872693]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.1839218124733932,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 7.206941981162187,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.40.001_G.008_WC _ Staff _ female',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5253,
              name: '03.03.30.001_G.013_Duct',
              children: [
                {
                  id: 5254,
                  name: 'Floor [872720]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.717913419748912,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 8.969114285171393,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.30.001_G.013_Duct',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5264,
              name: '03.03.28.002_G.006_Handover Office',
              children: [
                {
                  id: 5265,
                  name: 'Floor [872743]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 17.891150129293333,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 59.040795426667906,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.28.002_G.006_Handover Office',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5279,
              name: '03.03.33.002_G.011_Contractor Search',
              children: [
                {
                  id: 5280,
                  name: 'Floor [872788]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 28.056858544859114,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 92.58763319803492,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.33.002_G.011_Contractor Search',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5299,
              name: '03.03.22.001_G.010_Kitchenette',
              children: [
                {
                  id: 5300,
                  name: 'Floor [872847]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.6313378698425463,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 11.983414970480386,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.22.001_G.010_Kitchenette',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5312,
              name: '03.03.07.001_G.007_Corridor',
              children: [
                {
                  id: 5313,
                  name: 'Floor [872878]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.040075758692272,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 19.93225000368447,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.07.001_G.007_Corridor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5323,
              name: '03.03.30.001_G.005_Reception',
              children: [
                {
                  id: 5324,
                  name: 'Floor [872901]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 62.28395963260178,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 205.53703563066617,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.30.001_G.005_Reception',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5355,
              name: '03.03.00.001_G.003_Arrival Hall',
              children: [
                {
                  id: 5356,
                  name: 'Floor [873046]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 175.37775867005448,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 578.7464582514265,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.00.001_G.003_Arrival Hall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5402,
              name: '03.03.26.001_1.022_Multi Purpose Room (ESC)',
              children: [
                {
                  id: 5403,
                  name: 'Floor [874038]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 25.167503877055005,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 83.05276279428139,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.26.001_1.022_Multi Purpose Room (ESC)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5418,
              name: '03.03.32.005_1.026_Staff Rest _ Control Room suite',
              children: [
                {
                  id: 5419,
                  name: 'Floor [874315]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 17.236072021904405,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 56.879037672284454,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.32.005_1.026_Staff Rest _ Control Room suite',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5437,
              name: '03.03.40.009_1.025_WC _ Staff _ m/f _ disabled',
              children: [
                {
                  id: 5438,
                  name: 'Floor [874366]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.082128212801996,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 13.471023102246567,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.40.009_1.025_WC _ Staff _ m/f _ disabled',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5448,
              name: '03.03.37.003_1.024_Lobby',
              children: [
                {
                  id: 5449,
                  name: 'Floor [874394]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.548127484828041,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 11.708820699932515,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.37.003_1.024_Lobby',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5464,
              name: '03.03.37.001_1.029A_Store 1',
              children: [
                {
                  id: 5465,
                  name: 'Floor [874422]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.8327363288001455,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 12.64802988504046,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.37.001_1.029A_Store 1',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5476,
              name: '03.03.40.010_1.027_WC _ Staff _ m/f (control room suite)',
              children: [
                {
                  id: 5477,
                  name: 'Floor [874446]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.445360413578621,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 11.369689364809432,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.40.010_1.027_WC _ Staff _ m/f (control room suite)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5487,
              name: '03.03.05.002_1.021_Cleaners cupboard',
              children: [
                {
                  id: 5488,
                  name: 'Floor [874472]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.55327492345908,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 15.025807247414937,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.05.002_1.021_Cleaners cupboard',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5500,
              name: '03.03.40.008_1.020_WC _ Staff _ male _ multi',
              children: [
                {
                  id: 5501,
                  name: 'Floor [874507]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 19.780494375200302,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 65.2756314381609,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.40.008_1.020_WC _ Staff _ male _ multi',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5513,
              name: '03.03.40.007_1.018_WC _ Staff _ female _ multi',
              children: [
                {
                  id: 5514,
                  name: 'Floor [874550]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 17.458134303847377,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 57.611843202696264,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.40.007_1.018_WC _ Staff _ female _ multi',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5528,
              name: '03.03.07.006_1.019_Corridor',
              children: [
                {
                  id: 5529,
                  name: 'Floor [874593]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 15.185763555694322,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 50.11301973379119,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.07.006_1.019_Corridor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5551,
              name: '03.03.07.007_1.023_Corridor',
              children: [
                {
                  id: 5552,
                  name: 'Floor [874640]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 8.45065914612177,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 27.887175182201798,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.07.007_1.023_Corridor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5567,
              name: '03.03.07.005_1.013_Corridor',
              children: [
                {
                  id: 5568,
                  name: 'Floor [874674]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 16.461038897274044,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 54.321428361004266,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.07.005_1.013_Corridor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5588,
              name: '03.03.22.001_1.017_IM&T Hub',
              children: [
                {
                  id: 5589,
                  name: 'Floor [874716]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.427739782246761,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 21.211541281414274,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.22.001_1.017_IM&T Hub',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5607,
              name: '03.03.30.004_1.016_PMVA Office Store',
              children: [
                {
                  id: 5608,
                  name: 'Floor [874753]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.47106450368717,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 21.354512862167628,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.30.004_1.016_PMVA Office Store',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5621,
              name: '03.03.28.011_1.015_Office _ 2 person PMVA',
              children: [
                {
                  id: 5622,
                  name: 'Floor [874779]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 18.80591555156825,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 62.05952132017513,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.28.011_1.015_Office _ 2 person PMVA',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5638,
              name: '03.03.30.003_1.014_PMVA Briefing Room',
              children: [
                {
                  id: 5639,
                  name: 'Floor [874814]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 41.13679286104575,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 135.75141644145074,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.30.003_1.014_PMVA Briefing Room',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5657,
              name: '03.03.30.002_1.012_PMVA Kit Bag Store',
              children: [
                {
                  id: 5658,
                  name: 'Floor [874857]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 22.962761241061322,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 75.77711209550225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.30.002_1.012_PMVA Kit Bag Store',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5676,
              name: '03.03.32.004_1.010_Staff Lockers _ Male',
              children: [
                {
                  id: 5677,
                  name: 'Floor [874900]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 15.354635796637428,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 50.67029812890342,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.32.004_1.010_Staff Lockers _ Male',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5702,
              name: '03.03.32.003_1.009_Staff Lockers _ Female',
              children: [
                {
                  id: 5703,
                  name: 'Floor [874962]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 15.416083927677576,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 50.873076961335926,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.32.003_1.009_Staff Lockers _ Female',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5724,
              name: '03.03.32.002_1.008_Staff Rest _ main',
              children: [
                {
                  id: 5725,
                  name: 'Floor [875008]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 22.448976466984284,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 74.08162234104803,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.32.002_1.008_Staff Rest _ main',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5742,
              name: '03.03.07.004_1.011_Corridor',
              children: [
                {
                  id: 5743,
                  name: 'Floor [875050]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 15.223697000127382,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 50.23820010042029,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.07.004_1.011_Corridor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5759,
              name: '03.03.07.003_1.005_Corridor',
              children: [
                {
                  id: 5760,
                  name: 'Floor [875082]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 14.41356296562056,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 47.564757786547766,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.07.003_1.005_Corridor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5786,
              name: '03.03.37.002_1.007_Stair Lobby',
              children: [
                {
                  id: 5787,
                  name: 'Floor [875151]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.284258625501307,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 17.438053464154287,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.37.002_1.007_Stair Lobby',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5807,
              name: '03.03.33.003_1.006_Dedicated Search Team Storage',
              children: [
                {
                  id: 5808,
                  name: 'Floor [875196]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.650381196087094,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 12.04625794708739,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.33.003_1.006_Dedicated Search Team Storage',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5827,
              name: '03.03.30.009_1.003_Equipment Room',
              children: [
                {
                  id: 5828,
                  name: 'Floor [875240]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 45.59324512521643,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 150.45770891321396,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.30.009_1.003_Equipment Room',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5841,
              name: '03.03.00.002_1.004_Archive Store',
              children: [
                {
                  id: 5842,
                  name: 'Floor [875266]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 16.241555008393878,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 53.59713152769971,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.00.002_1.004_Archive Store',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5855,
              name: '03.03.36.003_1.002_Air Lock',
              children: [
                {
                  id: 5856,
                  name: 'Floor [875316]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.107427809846258,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 16.85451177249262,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.36.003_1.002_Air Lock',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5874,
              name: '03.03.06.001_1.029_Silver Command',
              children: [
                {
                  id: 5875,
                  name: 'Floor [875359]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 41.48238868253257,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 136.89188265235728,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.06.001_1.029_Silver Command',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5895,
              name: '03.03.28.012_1.030_Site Management Office',
              children: [
                {
                  id: 5896,
                  name: 'Floor [875410]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 21.005665058784277,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 69.31869469398801,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.28.012_1.030_Site Management Office',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5912,
              name: '03.03.36.004_1.028_Air Lock Corridor',
              children: [
                {
                  id: 5913,
                  name: 'Floor [875445]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 19.50367070508146,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 64.36213115436692,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.36.004_1.028_Air Lock Corridor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5941,
              name: '03.03.30.008_1.001_Control Room',
              children: [
                {
                  id: 5942,
                  name: 'Floor [875544]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 123.21353815988256,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 406.60466381455217,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.30.008_1.001_Control Room',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5973,
              name: '03.03.30.011_1.031_Plant Room',
              children: [
                {
                  id: 5974,
                  name: 'Floor [875651]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 53.05648502591631,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 244.05983111921475,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 4600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.30.011_1.031_Plant Room',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 5996,
              name: '03.03.30.012_1.033_Plant Room',
              children: [
                {
                  id: 5997,
                  name: 'Floor [875794]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 46.24761993406383,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 212.7390516966933,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 4600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.30.012_1.033_Plant Room',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 6033,
              name: '03.03.28.001_G.001_Entrance',
              children: [
                {
                  id: 6034,
                  name: 'Floor [876176]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 24.36700418169987,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 185.676571864553,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 7620,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.28.001_G.001_Entrance',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 6049,
              name: '03.03.33.001_G.004_Search Hall',
              children: [
                {
                  id: 6050,
                  name: 'Floor [876280]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 191.27462649353998,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 784.2262964404762,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 4100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.33.001_G.004_Search Hall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 7641,
              name: '03.03.43.001_G.002_Visitors Casual Waiting area',
              children: [
                {
                  id: 7642,
                  name: 'Floor [944665]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 24.380756521690838,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 185.78136469528417,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 7620,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.43.001_G.002_Visitors Casual Waiting area',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 7662,
              name: '03.03.32.001_G.033_Staff Lockers',
              children: [
                {
                  id: 7663,
                  name: 'Floor [945837]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 13.27269431690268,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 43.79989124577878,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.32.001_G.033_Staff Lockers',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 7685,
              name: '03.03.30.002_G.015_Wheelchair Store',
              children: [
                {
                  id: 7686,
                  name: 'Floor [947019]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.77661632644789,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 5.769606436930568,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 0,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.30.002_G.015_Wheelchair Store',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 7700,
              name: '03.03.36.002_G.021A_Air Lock _ Visitors Waiting',
              children: [
                {
                  id: 7701,
                  name: 'Floor [947477]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.188191214471183,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 17.121031007754876,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.36.002_G.021A_Air Lock _ Visitors Waiting',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 7713,
              name: '03.03.30.003_G.034_Vehicle Lock',
              children: [
                {
                  id: 7714,
                  name: 'Floor [948030]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 143.76107304607604,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1294.568462779912,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 9005,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.30.003_G.034_Vehicle Lock',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 7724,
              name: '03.03.30.004_G.035_Gate Equipment',
              children: [
                {
                  id: 7725,
                  name: 'Floor [948217]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 9.149459450652401,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 82.39088235312671,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 9005,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.30.004_G.035_Gate Equipment',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 7741,
              name: '03.03.28.010_G.036_Patients Gifts Cage',
              children: [
                {
                  id: 7742,
                  name: 'Floor [948347]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.0067857326737155,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 4.354348293813825,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 4325,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.28.010_G.036_Patients Gifts Cage',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 7752,
              name: '03.03.30.005_G.037_Gate Equipment',
              children: [
                {
                  id: 7753,
                  name: 'Floor [948369]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 9.147019386456508,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 82.36890957504079,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 9005,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.30.005_G.037_Gate Equipment',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 7769,
              name: '03.03.30.001_G.038_PMVA Store and Drying Room',
              children: [
                {
                  id: 7770,
                  name: 'Floor [948499]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 15.365480244695211,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 66.45570205830687,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 4325,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.30.001_G.038_PMVA Store and Drying Room',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 7785,
              name: '03.03.30.006_G.039_Boiler Room',
              children: [
                {
                  id: 7786,
                  name: 'Floor [948585]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 27.94198459515387,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 114.5621368401309,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 4100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.30.006_G.039_Boiler Room',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 7803,
              name: '03.03.30.007_G.040_Electrical Switch Room',
              children: [
                {
                  id: 7804,
                  name: 'Floor [948666]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 13.973129323007651,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 57.28983022433141,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 4100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.30.007_G.040_Electrical Switch Room',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 7821,
              name: '03.03.30.013_G.041_Generator Room',
              children: [
                {
                  id: 7822,
                  name: 'Floor [948746]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 37.83293967856351,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 155.11505268211056,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 4100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.30.013_G.041_Generator Room',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 7841,
              name: '03.03.37.005__Stairs 2 _ emergency',
              children: [
                {
                  id: 7842,
                  name: 'Floor [948865]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 22.386873858585112,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 201.3699303579731,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 8995,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 7884,
                  name: 'Floor [949696]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.3199927978749812,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 4.365216182572554,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 3307.000000000001,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.37.005__Stairs 2 _ emergency',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 7852,
              name: '03.03.37.006__Stairs 3 _ Plant Room',
              children: [
                {
                  id: 7853,
                  name: 'Floor [949018]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 12.835528333508181,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 115.19886679323598,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 8975,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.37.006__Stairs 3 _ Plant Room',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 7863,
              name: '03.03.37.004__Stairs 1',
              children: [
                {
                  id: 7864,
                  name: 'Floor [949143]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 22.61061563703772,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 203.38248765515428,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 8995,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.37.004__Stairs 1',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 7874,
              name: '03.03.30.010_1.004A_Duct',
              children: [
                {
                  id: 7875,
                  name: 'Floor [949421]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.7181652699671757,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 15.270652486675605,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 5618.000000000001,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.30.010_1.004A_Duct',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Floor',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Floors',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Floors',
          displayCategory: '__category__',
        },
      ],
    },
    {
      id: 6808,
      name: 'Specialty Equipment',
      children: [
        {
          id: 6809,
          name: 'Elevator-Electric',
          children: [
            {
              id: 6811,
              name: '03.05.10.01.01.000_Passenger lift',
              children: [
                {
                  id: 6812,
                  name: 'Elevator-Electric [891511]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Specialty Equipment',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 10230.799999999996,
                      displayCategory: 'Other',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1900,
                      displayCategory: 'Other',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.05.10.01.01.000_Passenger lift',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Elevator-Electric',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Specialty Equipment',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 1900,
                  displayCategory: 'Other',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Elevator-Electric',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Specialty Equipment',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Specialty Equipment',
          displayCategory: '__category__',
        },
      ],
    },
    {
      id: 7253,
      name: 'Structural Framing',
      children: [
        {
          id: 7254,
          name: 'Railing',
          children: [
            {
              id: 7256,
              name: '03.02.03.04.01.030_Stair Handrail',
              children: [
                {
                  id: 7257,
                  name: 'Railing [902201]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 202.1809516676037,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.00028359961165851626,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7265,
                  name: 'Railing [903800]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 919.4161892024714,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0014709106864470886,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7270,
                  name: 'Railing [904066]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1229.295354991599,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.002014550002231426,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7276,
                  name: 'Railing [904445]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1033.4628117534767,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0016119997800382554,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7283,
                  name: 'Railing [905645]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1296.978132680022,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0021167057310554445,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7288,
                  name: 'Railing [906772]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 868.6658238397719,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0014207589187280154,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7295,
                  name: 'Railing [907756]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1017.1178862947266,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0015937999886495363,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7319,
                  name: 'Railing [916255]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 202.1809516676037,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0002878463391399619,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7321,
                  name: 'Railing [916259]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 921.2969763465971,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0014705822132759642,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7323,
                  name: 'Railing [916261]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1232.1847596959044,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.002010631747921069,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7337,
                  name: 'Railing [916424]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1033.4628117534767,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0016120564686141932,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7339,
                  name: 'Railing [916428]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1297.2972024405105,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0021171595540961435,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7347,
                  name: 'Railing [916670]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 866.9957763238002,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0014154855466902289,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7349,
                  name: 'Railing [916678]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1010.0719012581063,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0015964012846621002,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7375,
                  name: 'Railing [917925]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 144.4802919402448,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.00024150767156865655,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7384,
                  name: 'Railing [918411]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1009.3645261853144,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0016046440374118244,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7388,
                  name: 'Railing [918598]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 761.7698819820367,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0011768023176773866,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7394,
                  name: 'Railing [918767]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1067.5248550822785,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0017500692616256928,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7398,
                  name: 'Railing [919526]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 700.4805843927627,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0011307803166570095,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7423,
                  name: 'Railing [927389]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3894.6536368518678,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.00620729832589103,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7427,
                  name: 'Railing [927803]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 542.6666666666829,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0008682666666662085,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7433,
                  name: 'Railing [928183]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3217.3677571419125,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.005147788411424548,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7437,
                  name: 'Railing [928297]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 156.16033409445672,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.00023984515292290202,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7441,
                  name: 'Railing [928956]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2105.0439417382736,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0033680703067808654,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7447,
                  name: 'Railing [929093]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2264.631861738508,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.003623410978782074,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7453,
                  name: 'Railing [929335]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 628.4827586207538,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0010055724137931247,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7459,
                  name: 'Railing [929739]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2237.4292131274797,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.003579886741002133,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7465,
                  name: 'Railing [929848]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2510.5699203406552,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.004016911872545917,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7470,
                  name: 'Railing [929953]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 65.0000000000043,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.00009082541077166957,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7476,
                  name: 'Railing [930251]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2105.0439417382736,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0033680703067812184,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7482,
                  name: 'Railing [930424]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2264.631861738508,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0036234109787828595,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7488,
                  name: 'Railing [930640]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 628.4827586207538,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0010055724137927333,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7494,
                  name: 'Railing [931022]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2237.4292131274797,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.003579886741002989,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7500,
                  name: 'Railing [931139]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2510.569920340647,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.004016911872546004,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7505,
                  name: 'Railing [931242]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 60.0000000000091,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.00008848015316127895,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.03.04.01.030_Stair Handrail',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Railing',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Framing',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 7300,
              name: '03.02.03.04.01.020_Stair Handrail with steel infill panel',
              children: [
                {
                  id: 7301,
                  name: 'Railing [908099]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 202.1809516676037,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.00028381747436919617,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7303,
                  name: 'Railing [908103]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 889.16255701309,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0014709106864465341,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7313,
                  name: 'Railing [908339]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 871.8302351120058,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0013950173559061395,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7357,
                  name: 'Railing [917124]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 202.1809516676037,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0002871927492616092,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7359,
                  name: 'Railing [917128]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 891.5444299223655,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.001471346408209735,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7369,
                  name: 'Railing [917366]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 884.7455030746445,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0014155928049271135,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7403,
                  name: 'Railing [925261]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3840.0014237307,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.006144002277941836,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7409,
                  name: 'Railing [925383]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 144.48980159245835,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.00024152237666219805,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7413,
                  name: 'Railing [925628]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2779.999162386036,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0044479986600791666,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7419,
                  name: 'Railing [925711]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 75.78854239532923,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.00012126166783254693,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7511,
                  name: 'Railing [931569]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2105.0439417382736,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0033680703067806984,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7517,
                  name: 'Railing [931698]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2139.9999999999955,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.003424000000000878,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7521,
                  name: 'Railing [932732]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2510.5699203406557,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.004016911872546004,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7526,
                  name: 'Railing [932828]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 60.651264014267916,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.00009704202242285811,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7533,
                  name: 'Railing [932854]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1605.000000000011,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0025680000000005885,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7537,
                  name: 'Railing [935346]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 85.00000000000422,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.000136000000000003,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7541,
                  name: 'Railing [936031]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1879.9999999999966,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.003008000000001876,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7545,
                  name: 'Railing [937316]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 29.997793535231413,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.00007157301999427395,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7549,
                  name: 'Railing [937745]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 25.00000000011966,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.00004738215145073064,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7555,
                  name: 'Railing [938667]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 39.99102794761726,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.00006398564471622398,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7559,
                  name: 'Railing [939030]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 590.3520478998472,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0009445632766401999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7563,
                  name: 'Railing [940394]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 82.61210868550168,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.00013217937389678334,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7565,
                  name: 'Railing [940396]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 29.99999999998993,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.00007145983186796657,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7573,
                  name: 'Railing [940613]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 29.30304937244014,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.000055463425914736226,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7579,
                  name: 'Railing [940656]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 37.306375256795754,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.000059690200411124754,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7583,
                  name: 'Railing [940862]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 591.2275091374632,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0009459640146205822,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7587,
                  name: 'Railing [941234]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2105.0439417382736,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0033680703067808875,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7593,
                  name: 'Railing [941426]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2139.9371735037034,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0034240000000004753,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7597,
                  name: 'Railing [941541]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1863.077880556626,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.002980924608888949,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7599,
                  name: 'Railing [941642]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2510.569920340647,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.004016911872543556,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7604,
                  name: 'Railing [941743]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 62.165994312042436,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0001314655908993052,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7611,
                  name: 'Railing [941805]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1609.976382680108,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.002523642212288454,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7615,
                  name: 'Railing [942819]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 54.99999999999183,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.00008799999999993736,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7619,
                  name: 'Railing [943001]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 55.006034864380155,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.00011017482058555897,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7623,
                  name: 'Railing [943568]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 73.30776426607332,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.00012730380787771373,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7627,
                  name: 'Railing [943656]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 40.000000000009315,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.00006400000000005769,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7631,
                  name: 'Railing [943889]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 580.9195493920774,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0009294712790272756,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 7633,
                  name: 'Railing [944238]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Framing',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1346.8361023700907,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.002154937763792481,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.03.04.01.020_Stair Handrail with steel infill panel',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Railing',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Framing',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Railing',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Structural Framing',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Structural Framing',
          displayCategory: '__category__',
        },
      ],
    },
  ],
  properties: [
    {
      displayName: 'Category',
      displayValue: 'Revit Document',
      displayCategory: '__category__',
    },
  ],
}

export const InternalTakeOffData: TakeOffItem[] = parseTakeOffData(ModelInternalData)

export const InternalTakeOffTableData: TakeOffTableItem[] = InternalTakeOffData.map((t) =>
  convertToTableItem(t, 'BHR-R5D-401-00-M3-X-0007-D1-03_Entrance Building - Internal.rvt')
)
