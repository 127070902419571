import React, { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { emptyWorkPackagesEntry, WorkPackagesEntry } from '../../../../data/WorkPackages.data'
import { RootState } from '../../../../context/reducer'
import SterlingTable, { ExtendedColumnType } from '../../../basic/table/sterling-table/SterlingTable'
import { WorkPackagesColumns } from '../WorkPackages.columns'
import WorkPackagesHeader from '../headers/WorkPackagesHeader'
import {
  addEntry,
  deleteEntries,
  duplicateEntries,
  updateEntry,
} from '../../../../context/resource-library/SterlingTable.actions'
import { Table } from 'antd'
import { WorkPackagesBoQColumns } from '../headers/MiniTablesColumns'
import { ItemsManagementToolbar } from '../../../basic/table/table-selection-toolbar/ItemsManagementToolbar'

interface WorkPackagesOverviewTableProps {
  rowHeight: number
  row?: any
  data?: any[]
  columns?: ExtendedColumnType[]
  forMapping?: boolean
  onRow?: (index: number, record: any) => any
}

const tableId = 'workPackages'

const WorkPackagesOverviewTable: FunctionComponent<WorkPackagesOverviewTableProps> = ({
  rowHeight,
  data,
  row,
  columns,
  forMapping,
  onRow,
}) => {
  const dispatch = useDispatch()
  const itemCodeExtractor = (item: WorkPackagesEntry) => item.wp_code
  const entries = useSelector((state: RootState) => state.sterlingTable.parsedData[tableId])

  return (
    <SterlingTable
      columns={columns || WorkPackagesColumns}
      dataSource={data || entries}
      row={row}
      onRow={onRow}
      defaultPagination={false}
      rowExpandable={(record: any) => record.externalData}
      expandedRowRender={(record: any) =>
        record.externalData && (
          <Table
            style={{ width: 800 }}
            dataSource={record.externalData}
            columns={WorkPackagesBoQColumns.map((x) => ({ ...x, fixed: false }))}
            pagination={false}
          />
        )
      }
      tableId={tableId}
      tableHeight={rowHeight * 6}
      renderHeader={(props) => (
        <WorkPackagesHeader
          forMapping={forMapping}
          data={data || entries}
          columns={WorkPackagesColumns}
          tableId={tableId}
          sterlingHeaderProps={props}
        />
      )}
      renderSelectionToolbar={(props) => (
        <ItemsManagementToolbar
          baseProps={props}
          onAdd={() => dispatch(addEntry(tableId, emptyWorkPackagesEntry(0), itemCodeExtractor))}
          onDuplicate={(selectedRows) =>
            dispatch(
              duplicateEntries(
                tableId,
                selectedRows.map((key) => key as number),
                itemCodeExtractor
              )
            )
          }
          onDelete={(selectedRows) =>
            dispatch(
              deleteEntries(
                tableId,
                selectedRows.map((key) => key as number),
                itemCodeExtractor
              )
            )
          }
        />
      )}
      size={'small'}
      onUpdate={(id, updates) => {
        dispatch(updateEntry(tableId, id, updates, itemCodeExtractor))
      }}
    />
  )
}

export default WorkPackagesOverviewTable
