export interface SubcontractorsItem {
  id: number
  itemCode: string
  title: string
  notes: string
  unit: string
  unitRate?: number
  carbonRate?: number
  currency: string
  company: string
  country: string
}

export function emptySubcontractorItem() {
  return {
    id: -1,
    itemCode: '',
    title: '',
    notes: '',
    unit: '',
    currency: '',
    company: '',
    country: '',
  }
}

export const subcontractorsData: SubcontractorsItem[] = [
  {
    id: 1,
    itemCode: 'SUB',
    title: 'Subcontractor',
    notes: '',
    unit: '',
    currency: '',
    company: '',
    country: '',
  },
  {
    id: 2,
    itemCode: 'SUB.CIV',
    title: 'Civil works',
    notes: '',
    unit: '',
    currency: '',
    company: '',
    country: '',
  },
  {
    id: 3,
    itemCode: 'SUB.CIV.EXC',
    title: 'Earth works',
    notes: '',
    unit: 'M3',
    currency: '',
    company: '',
    country: '',
  },
  {
    id: 4,
    itemCode: 'SUB.CIV.EXC.010',
    title: 'Removing Overburden',
    notes: '',
    unit: 'M2',
    unitRate: 5.47,
    carbonRate: 10.5,
    currency: 'GBP - Great Britain Pound',
    company: 'XYZ Excavation',
    country: 'UNITED KINGDOM',
  },
  {
    id: 5,
    itemCode: 'SUB.CIV.EXC.020',
    title: 'Removing Vegetation and Scaling',
    notes: '',
    unit: 'M2',
    unitRate: 42.67,
    carbonRate: 10.5,
    currency: 'GBP - Great Britain Pound',
    company: 'XYZ Excavation',
    country: 'UNITED KINGDOM',
  },
  {
    id: 6,
    itemCode: 'SUB.CIV.EXC.030',
    title: 'Rock Bolting',
    notes: '',
    unit: 'M',
    unitRate: 55.8,
    currency: 'GBP - Great Britain Pound',
    company: 'XYZ Excavation',
    country: 'UNITED KINGDOM',
  },
  {
    id: 7,
    itemCode: 'SUB.CIV.EXC.040',
    title: 'Ground Anchors',
    notes: '',
    unit: 'M',
    unitRate: 44.86,
    carbonRate: 10.5,
    currency: 'GBP - Great Britain Pound',
    company: 'XYZ Excavation',
    country: 'UNITED KINGDOM',
  },
  {
    id: 8,
    itemCode: 'SUB.CIV.EXC.050',
    title: 'Soil Nailing - 3m deep',
    notes: '',
    unit: 'M2',
    unitRate: 273.51,
    carbonRate: 10.5,
    currency: 'GBP - Great Britain Pound',
    company: 'XYZ Excavation',
    country: 'UNITED KINGDOM',
  },
  {
    id: 9,
    itemCode: 'SUB.CIV.EXC.060',
    title: 'Disposal Rate',
    notes: '',
    unit: 'M3',
    unitRate: 7.25,
    carbonRate: 10.5,
    currency: 'GBP - Great Britain Pound',
    company: 'XYZ Excavation',
    country: 'UNITED KINGDOM',
  },
  {
    id: 10,
    itemCode: 'SUB.CIV.XD',
    title: 'Test entry',
    notes: '',
    unit: 'M3',
    currency: '',
    company: '',
    country: '',
  },
  {
    id: 11,
    itemCode: 'SUB.CIV.XD.10',
    title: 'Test entry',
    notes: '',
    unit: 'M3',
    unitRate: 7.25,
    carbonRate: 10.5,
    currency: 'GBP - Great Britain Pound',
    company: 'XYZ Excavation',
    country: 'UNITED KINGDOM',
  },
  {
    id: 12,
    itemCode: 'SUB.CIV.XD.20',
    title: 'Test entry',
    notes: '',
    unit: 'M3',
    unitRate: 7.25,
    carbonRate: 10.5,
    currency: 'GBP - Great Britain Pound',
    company: 'XYZ Excavation',
    country: 'UNITED KINGDOM',
  },
]
