import { Avatar, Layout, Menu } from 'antd'
import React, { FunctionComponent } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
const { Header } = Layout

const calculateCurrentKey = (location: string): string => {
  if (location === '/portfolio') {
    return 'portfolio'
  }
  if (location.startsWith('/libraries')) {
    return 'libraries'
  }
  if (location.startsWith('/administration')) {
    return 'administration'
  }
  return 'portfolio'
}

export const AppLayout: FunctionComponent = (props) => {
  const history = useHistory()
  const location = useLocation()
  const currentKey = calculateCurrentKey(location.pathname)

  return (
    <Layout className={'site-layout'}>
      {location.pathname.match('/login') ? null : (
        <Header className={'site-layout-background portfolio-header'}>
          <Menu className={'portfolio-menu'} mode="horizontal" selectedKeys={[currentKey]}>
            <Menu.Item key="portfolio" onClick={() => history.push('/portfolio')}>
              Portfolio
            </Menu.Item>
            <Menu.Item key="libraries" onClick={() => history.push('/libraries')}>
              Libraries
            </Menu.Item>
            <Menu.Item key="administration" onClick={() => history.push('/administration')}>
              Administration
            </Menu.Item>
          </Menu>
          <div className={'portfolio-avatar'}>
            <Avatar> SB </Avatar>
            <strong> Steve Brunning </strong>
          </div>
        </Header>
      )}
      {props.children}
    </Layout>
  )
}
