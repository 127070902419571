import { TakeOffState } from './TakeOff.state'
import {
  ADD_TAKE_OFF,
  ADD_TAKE_OFF_DETAILS_ITEM,
  AddTakeOffAction,
  AddTakeOffDetailsItemAction,
  TakeOffAction,
  UPDATE_TAKE_OFF,
  UpdateTakeOffAction,
} from './TakeOff.actions'
import { Drawings2DFilesIDs, InitialTakeOff2DItems } from '../../data/take-off/TakeOff.data'
import { create2DTakeOffDetailsItem, TakeOffItem } from '../../components/domain/take-off/helpers/TakeOffDataParser'

const initialState: TakeOffState = {
  takeOff2DItems: Object.fromEntries(Drawings2DFilesIDs.map((value) => [value, InitialTakeOff2DItems[value] || []])),
}

export function takeOffReducer(state: TakeOffState = initialState, action: TakeOffAction): TakeOffState {
  switch (action.type) {
    case ADD_TAKE_OFF: {
      const actionObject = action as AddTakeOffAction
      const currentTakeOffs = state.takeOff2DItems[actionObject.parentId]
      const newId =
        currentTakeOffs.length > 0 ? Math.max(...currentTakeOffs.map((i) => i.id)) + 1 : actionObject.parentId * 100
      const updatedTakeOffs = currentTakeOffs.concat(createTakeOff(newId, '', '', ''))
      const mapToUpdate = Object.assign({}, state.takeOff2DItems)
      mapToUpdate[actionObject.parentId] = updatedTakeOffs
      return Object.assign({}, state, {
        takeOff2DItems: mapToUpdate,
      })
    }
    case UPDATE_TAKE_OFF: {
      const actionObject = action as UpdateTakeOffAction
      return updateTakeOffs(state, actionObject.id, (e) => Object.assign({}, e, actionObject.updates))
    }
    case ADD_TAKE_OFF_DETAILS_ITEM: {
      const actionObject = action as AddTakeOffDetailsItemAction
      const takeOffDetailsItem = create2DTakeOffDetailsItem(actionObject.takeOff, actionObject.measurement)
      return updateTakeOffs(state, actionObject.takeOff.id, (e) =>
        Object.assign({}, e, {
          children: (e.children || []).concat(takeOffDetailsItem),
        })
      )
    }
    default:
      return state
  }
}

const updateTakeOffs = (
  state: TakeOffState,
  itemId: number,
  updateFunc: (item: TakeOffItem) => TakeOffItem
): TakeOffState => {
  const takeOff2DItems = state.takeOff2DItems
  const mapToUpdate = Object.assign({}, takeOff2DItems)
  const parentId = Object.keys(takeOff2DItems)
    .map((k) => parseInt(k, 10))
    .find((key) => takeOff2DItems[key].find((v) => v.id === itemId) !== undefined)
  if (parentId) {
    mapToUpdate[parentId] = takeOff2DItems[parentId].map((e) => {
      if (e.id === itemId) {
        return updateFunc(e)
      } else {
        return e
      }
    })
    return Object.assign({}, state, {
      takeOff2DItems: mapToUpdate,
    })
  }
  return state
}

const createTakeOff = (id: number, name: string, code: string, type: string): TakeOffItem => {
  return {
    id,
    code,
    name,
    takeOffType: type,
    children: [],
  }
}
