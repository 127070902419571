import { Col, Divider, Row } from 'antd'
import { FrappeGantt, ViewMode } from 'frappe-gantt-react'
import React from 'react'
import { ActivitiesTasks } from '../../../../data/Activities.data'

import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { calculateHeightForWholePageTable } from '../../../../constants/dimensions'
import ActivitiesTable from './ActivitiesTable'

export default function ActivitiesSchedule() {
  const { height } = useWindowDimensions()
  const tableHeight = calculateHeightForWholePageTable(height) - 50

  return (
    <div>
      <Row>
        <Col span={10}>
          <ActivitiesTable tableHeight={tableHeight} />
        </Col>
        <Divider type="vertical" />
        <Col span={13}>
          <div style={{ backgroundColor: 'white', height: '75px' }}></div>
          <div style={{ maxHeight: tableHeight - 30, overflowY: 'scroll' }}>
            <FrappeGantt viewMode={ViewMode.Month} tasks={ActivitiesTasks} />
          </div>
        </Col>
      </Row>
    </div>
  )
}
