import { Action } from 'redux'
import { TakeOffItem } from '../../components/domain/take-off/helpers/TakeOffDataParser'

export const ADD_TAKE_OFF = 'ADD_TAKE_OFF'

export interface AddTakeOffAction extends Action<string> {
  parentId: number
}

export const AddTakeOff = (parentId: number) => {
  return {
    type: ADD_TAKE_OFF,
    parentId,
  }
}

export const UPDATE_TAKE_OFF = 'UPDATE_TAKE_OFF'

export interface UpdateTakeOffAction extends Action<string> {
  id: number
  updates: { [key: string]: any }
}

export const UpdateTakeOff = (id: number, updates: { [key: string]: any }) => {
  return {
    type: UPDATE_TAKE_OFF,
    id,
    updates,
  }
}

export const ADD_TAKE_OFF_DETAILS_ITEM = 'ADD_TAKE_OFF_DETAILS_ITEM'

export interface AddTakeOffDetailsItemAction extends Action<string> {
  takeOff: TakeOffItem
  measurement: any
}

export const AddTakeOffDetailsItem = (takeOff: TakeOffItem, measurement: any) => {
  return {
    type: ADD_TAKE_OFF_DETAILS_ITEM,
    takeOff,
    measurement,
  }
}
export type TakeOffAction = AddTakeOffAction | UpdateTakeOffAction | AddTakeOffDetailsItemAction
