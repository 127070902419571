import { Layout, Menu, Button, Divider } from 'antd'
import { LeftCircleTwoTone, RightCircleTwoTone } from '@ant-design/icons/lib'
import React, { Key } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../context/reducer'
import { setLeftMenuSiderCollapsed } from '../../../context/view-settings/ViewSettings.actions'
const { Sider } = Layout

export interface MenuSiderItem {
  key: Key
  icon: JSX.Element
  title: string
  address?: string
}

export interface MenuSiderItems {
  head: MenuSiderItem
  items: MenuSiderItem[]
}

export const MenuSider = ({
  selectedKey,
  disableHead,
  head,
  items,
}: { selectedKey: string; disableHead: boolean } & MenuSiderItems) => {
  const dispatch = useDispatch()
  const collapsed = useSelector((state: RootState) => state.viewSettings.leftMenuSiderCollapsed)

  const history = useHistory()

  return (
    <Sider collapsed={collapsed} style={{ backgroundColor: '#fff' }}>
      <div>
        <Menu defaultSelectedKeys={[selectedKey]} mode="inline" inlineCollapsed={collapsed} theme="light">
          <div style={{ position: 'absolute', zIndex: 1000, width: 64}}>
            <Button
              type={'ghost'}
              size={'large'}
              style={Object.assign(
                {},
                { marginTop: '4px', border: 'none' },
                collapsed ? { marginLeft: '64px' } : { marginLeft: '184px' }
              )}
              icon={collapsed ? <RightCircleTwoTone /> : <LeftCircleTwoTone />}
              onClick={() => dispatch(setLeftMenuSiderCollapsed(!collapsed))}
            />
          </div>

          <Menu.Item
            key={head.key}
            disabled={disableHead}
            icon={head.icon}
            onClick={() => history.push(head.address as string)}
          >
            {head.title}
          </Menu.Item>
          <Divider type={'horizontal'} style={{ margin: '5px 0' }} />
          {items.map((mi) => (
            <Menu.Item key={mi.key} icon={mi.icon} onClick={() => history.push(mi.address as string)}>
              {mi.title}
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </Sider>
  )
}
