import React, { FunctionComponent } from 'react'
import {
  SterlingTableHeader,
  SterlingTableHeaderProps,
} from '../../../basic/table/sterling-table/header/SterlingTableHeader'
import { Button, Divider, Tooltip } from 'antd'
import {
  ApartmentOutlined,
  ArrowsAltOutlined,
  ExportOutlined,
  ImportOutlined,
  MoreOutlined,
  PlusSquareOutlined,
  SyncOutlined,
} from '@ant-design/icons/lib'
import ColumnGroupingManager from '../../../basic/table/managers/column-grouping/ColumnGroupingManager'
import { SpellCheckCustom, TextWrappingCustom } from '../../../../assets/icons/icons'
import { Entry } from '../../../basic/table/sterling-table/SterlingTable'
import { ColumnType } from 'antd/lib/table/interface'
import { emptyWorkPackagesEntry, WorkPackagesEntry } from '../../../../data/WorkPackages.data'

interface WorkPackagesHeaderProps {
  data: Entry[]
  columns: ColumnType<any>[]
  tableId: string
  forMapping?: boolean
  noImportExport?: boolean
  sterlingHeaderProps: SterlingTableHeaderProps
  openWindow?: () => void
}
const WorkPackagesHeader: FunctionComponent<WorkPackagesHeaderProps> = ({
  columns,
  tableId,
  noImportExport,
  sterlingHeaderProps,
  forMapping,
  openWindow,
}) => {
  return (
    <SterlingTableHeader
      {...sterlingHeaderProps}
      columnsLayoutManagerVisibility={true}
      rightSideChildren={
        <>
          {openWindow !== undefined && (
            <Button type={'text'} shape="circle" onClick={openWindow} icon={<ArrowsAltOutlined />} />
          )}
        </>
      }
    >
      <Tooltip title="Breakdown structure">
        <Button type="text" shape="circle" icon={<ApartmentOutlined />} />
      </Tooltip>
      <Tooltip title="Refresh">
        <Button type="text" shape="circle" icon={<SyncOutlined />} />
      </Tooltip>
      {!noImportExport && (
        <>
          <Divider type={'vertical'} />
          <Tooltip title="Import">
            <Button type="text" shape="circle" icon={<ImportOutlined />} />
          </Tooltip>
          <Tooltip title="Export">
            <Button type="text" shape="circle" icon={<ExportOutlined />} />
          </Tooltip>
          <Divider type={'vertical'} />
        </>
      )}
      <Tooltip title="Wrap text">
        <Button type="text" shape="circle" icon={<TextWrappingCustom />} />
      </Tooltip>
      {!forMapping && (
        <>
          <Tooltip title="Check spelling">
            <Button type="text" shape="circle" icon={<SpellCheckCustom />} />
          </Tooltip>
          <Tooltip title="Add row">
            <Button type="text" shape="circle" icon={<PlusSquareOutlined />} />
          </Tooltip>
          <Tooltip title="More">
            <Button type="text" shape="circle" icon={<MoreOutlined />} />
          </Tooltip>
        </>
      )}
      <Button type="text" shape="circle" icon={<SpellCheckCustom />} />
    </SterlingTableHeader>
  )
}
export default WorkPackagesHeader
