export interface SterlingUser {
  name: string
  surname: string
  email: string
}

const Users: SterlingUser[] = [
  {
    name: 'Matt',
    surname: 'Kowalski',
    email: 'matt_kowalski@gmail.com',
  },

  {
    name: 'Stephen',
    surname: 'Brunning',
    email: 'stephen_brunning@gmail.com',
  },

  {
    name: 'James',
    surname: 'Hunter',
    email: 'james_hunter@gmail.com',
  },
]
export default Users
