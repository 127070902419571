import { ExtendedColumnType } from '../../../../basic/table/sterling-table/SterlingTable'
import { numberSorter, stringSorter } from '../../../../../utils/Sorters'
import { Currency } from '../../../../../utils/currency/Currencies'
import { currency, renderDecimal } from '../../../../../utils/rendering/Rendering'

export const TakeOffCostItemsColumns: ExtendedColumnType[] = [
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    width: 80,
    fixed: 'left',
    sorter: (a, b) => stringSorter(a.code, b.code),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    width: 300,
    fixed: 'left',
    sorter: (a, b) => stringSorter(a.title, b.title),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    width: 80,
    sorter: (a, b) => numberSorter(a.quantity, b.quantity),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: (value) => renderDecimal(value),
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    width: 80,
    sorter: (a, b) => stringSorter(a.unit, b.unit),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    width: 80,
    sorter: (a, b) => stringSorter(a.currency, b.currency),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Rate',
    dataIndex: 'rate',
    key: 'rate',
    width: 80,
    sorter: (a, b) => numberSorter(a.rate, b.rate),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: (value) => currency(value),
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    width: 80,
    sorter: (a, b) => numberSorter(a.total, b.total),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: (value) => currency(value),
  },
]
