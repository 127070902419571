import React, { useState } from 'react'
import { Button, Divider, Menu, Dropdown } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons/lib/icons'
import { documentationData } from '../../../../data/Documentation.data'
import DocumentationModal from '../helpers/DocumentationModal'

interface DocumentationToolbarProps {
  compareRevisions?: () => void
  onExport?: () => void
  onDelete?: () => void
  selectedItemsNumber: number
  selectedItem: any | undefined
  enableCallDiff: (urn: string[]) => void
}

export default function DocumentationSelectionToolbar({
  compareRevisions,
  onExport,
  onDelete,
  selectedItemsNumber,
  selectedItem,
  enableCallDiff,
}: DocumentationToolbarProps) {
  const [documentationModalVisible, setDocumentationModalVisibility] = useState(false)

  const showModal = () => setDocumentationModalVisibility(true)
  const hideModal = () => setDocumentationModalVisibility(false)

  let activeDocument: any
  let defaultRevision: string = ''
  const revisions: any[] = []
  const forgeDocuments: any[] = []

  if (selectedItem[0] < 1000) {
    Object.keys(selectedItem).forEach((val) => {
      activeDocument = documentationData.find((o) => o.key === selectedItem[val])
      revisions.push(...Object.keys(activeDocument?.revisions))
    })
    Object.values(activeDocument.revisions).forEach((val) => {
      forgeDocuments.push(val)
    })

    defaultRevision = Object.keys(activeDocument?.revisions)[0]
  }

  const handleClick = () => {
    enableCallDiff(forgeDocuments)
    hideModal()
  }

  const menuRevision = (
    <Menu className={'table-selection-toolbar-menu'} style={{ backgroundColor: '#10239E', color: 'white' }}>
      <Menu.Item style={{ color: 'white' }} key="0">
        Add new revision
      </Menu.Item>
      <Menu.Item style={{ color: 'white' }} key="1">
        View selected Revision
      </Menu.Item>
      <Menu.Item style={{ color: 'white' }} key="3" disabled={selectedItemsNumber > 1} onClick={showModal}>
        Compare Revisions
      </Menu.Item>
    </Menu>
  )
  const menuActivation = (
    <Menu className={'table-selection-toolbar-menu'} style={{ backgroundColor: '#10239E', color: 'white' }}>
      <Menu.Item style={{ color: 'white' }} key="0">
        Activate model
      </Menu.Item>
      <Menu.Item style={{ color: 'white' }} key="1">
        Change activation options
      </Menu.Item>
      <Menu.Item style={{ color: 'white' }} key="3">
        Deactivate model
      </Menu.Item>
    </Menu>
  )

  return (
    <div>
      {activeDocument.folder === '3D models' ? (
        <Dropdown overlay={menuActivation} trigger={['click']} overlayStyle={{ backgroundColor: '#10239E' }}>
          <a style={{ color: 'white' }} className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            Activation <CaretDownOutlined />
          </a>
        </Dropdown>
      ) : (
        ''
      )}
      <Divider type={'vertical'} />
      <Dropdown overlay={menuRevision} trigger={['click']} overlayStyle={{ backgroundColor: '#10239E' }}>
        <a style={{ color: 'white' }} className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Revision <CaretDownOutlined />
        </a>
      </Dropdown>
      <Divider type={'vertical'} />
      <Button type="text">Export</Button>
      <Divider type={'vertical'} />
      <Button type="text">Delete</Button>
      <DocumentationModal
        activeDocument={activeDocument?.document_name}
        handleClick={handleClick}
        hideModal={hideModal}
        revisions={revisions}
        visibility={documentationModalVisible}
      />
    </div>
  )
}
