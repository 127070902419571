import { numberSorter, stringSorter } from '../../../../../utils/Sorters'
import { ExtendedColumnType } from '../../../../basic/table/sterling-table/SterlingTable'
import { currency, percent, renderDecimal } from '../../../../../utils/rendering/Rendering'

export const activitiesColumns: ExtendedColumnType[] = [
  {
    title: 'Activity Code',
    dataIndex: 'activity_code',
    key: 'activity_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.activity_code, b.activity_code),
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'Title',
    width: 130,
    sorter: (a, b) => stringSorter(a.title, b.title),
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'Notes',
    width: 130,
    sorter: (a, b) => stringSorter(a.notes, b.notes),
  },
  {
    title: 'Start Date',
    dataIndex: 'start_date',
    key: 'start_date',
    width: 130,
    sorter: (a, b) => stringSorter(a.start_date, b.start_date),
  },
  {
    title: 'End Date',
    dataIndex: 'end_date',
    key: 'end_date',
    width: 130,
    sorter: (a, b) => stringSorter(a.end_date, b.end_date),
  },
  {
    title: 'Responsible',
    dataIndex: 'responsible',
    key: 'responsible',
    width: 130,
    sorter: (a, b) => stringSorter(a.responsible, b.responsible),
  },
  {
    title: 'Predecessor',
    dataIndex: 'predecessor',
    key: 'predecessor',
    width: 130,
    sorter: (a, b) => stringSorter(a.predecessor, b.predecessor),
  },
  {
    title: 'Successor',
    dataIndex: 'successor',
    key: 'successor',
    width: 130,
    sorter: (a, b) => stringSorter(a.successor, b.successor),
  },
  {
    title: 'Completion',
    dataIndex: 'completion',
    key: 'completion',
    width: 130,
    sorter: (a, b) => stringSorter(a.completion, b.completion),
    render: percent,
  },
  {
    title: 'Quantity Completed',
    dataIndex: 'quantity_completed',
    key: 'quantity_completed',
    width: 130,
    sorter: (a, b) => numberSorter(a.quantity_completed, b.quantity_completed),
    render: renderDecimal,
  },
  {
    title: 'Total Value Completed',
    dataIndex: 'total_value_completed',
    key: 'total_value_completed',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_value_completed, b.total_value_completed),
    render: renderDecimal,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    width: 130,
    sorter: (a, b) => numberSorter(a.quantity, b.quantity),
    render: renderDecimal,
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    width: 130,
    sorter: (a, b) => stringSorter(a.unit, b.unit),
  },
  {
    title: 'Total Value',
    dataIndex: 'total_value',
    key: 'total_value',
    width: 130,
    sorter: (a, b) => stringSorter(a.total_value, b.total_value),
    render: renderDecimal,
  },
  {
    title: 'Creator',
    dataIndex: 'creator',
    key: 'creator',
    width: 130,
    sorter: (a, b) => stringSorter(a.creator, b.creator),
  },
  {
    title: 'Last Editor',
    dataIndex: 'last_editor',
    key: 'last_editor',
    width: 130,
    sorter: (a, b) => stringSorter(a.last_editor, b.last_editor),
  },

  {
    title: 'Creation Date',
    dataIndex: 'creation_date',
    key: 'creation_date',
    width: 130,
    sorter: (a, b) => stringSorter(a.creation_date, b.creation_date),
  },
  {
    title: 'Last Edited',
    dataIndex: 'last_edited',
    key: 'last_edited',
    width: 130,
    sorter: (a, b) => stringSorter(a.last_edited, b.last_edited),
  },
]
