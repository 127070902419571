import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Portfolio from '../components/domain/portfolio/Portfolio'
import BillOfQuantities from '../components/domain/bill-of-quantities/BillOfQuantities'
import BreakdownStructures from '../components/domain/library/BreakdownStructures'
import ContactLibrary from '../components/domain/library/ContactLibrary'
import ResourceLibrary from '../components/domain/library/ResourceLibrary'
import Documentation from '../components/domain/documentation/Documentation'
import WorkPackages from '../components/domain/work-packages/WorkPackages'
import Settings from '../components/domain/settings-panel/Settings'
import Administration from '../components/domain/administration/PermissionManagement'
import GlobalProperties from '../components/domain/administration/GlobalProperties'
import PortfolioBreakdown from '../components/domain/administration/PortfolioBreakdown'
import Activities from '../components/domain/activities/Activities'
import { TakeOffTabs } from '../components/domain/take-off/TakeOffTabs'
import FrontPage from '../components/domain/frontpage/Frontpage'
import Valuations from '../components/domain/valuations/Valuations'
import LoginPage from '../components/domain/login-page/LoginPage'

export default function Routes() {
  return (
    <Switch>
      {/* Login */}
      <Route path={'/'} exact={true}>
        <Redirect to={'/login'} />
      </Route>
      <Route path={'/login'} exact={true} component={LoginPage} />
      {/* Portfolio */}
      <Route path={'/portfolio'} exact={true} component={Portfolio} />
      {/* Libraries */}
      <Route path={'/libraries'} exact={true}>
        <Redirect to={'/libraries/resources'} />
      </Route>
      <Route path={'/libraries/resources'} exact={true} component={ResourceLibrary} />
      <Route path={'/libraries/breakdown-structures'} exact={true} component={BreakdownStructures} />
      <Route path={'/libraries/contact'} exact={true} component={ContactLibrary} />
      <Route path={'/libraries/documentation'} exact={true} component={Documentation} />
      {/* Project */}
      <Route path={'/main'} exact={true} component={FrontPage} />
      <Route path={'/cost-plan'} exact={true} component={BillOfQuantities} />
      <Route path={'/documentation'} exact={true} component={Documentation} />
      <Route path={'/take-off'} exact={true} component={TakeOffTabs} />
      <Route path={'/work-packages'} exact={true} component={WorkPackages} />
      <Route path={'/activities'} exact={true} component={Activities} />
      <Route path={'/settings'} exact={true} component={Settings} />
      <Route path={'/valuations'} exact={true} component={Valuations} />
      {/* Administration */}
      <Route path={'/administration'} exact={true}>
        <Redirect to={'/administration/permission-management'} />
      </Route>
      <Route path={'/administration/permission-management'} exact={true} component={Administration} />
      <Route path={'/administration/global-properties'} exact={true} component={GlobalProperties} />
      <Route path={'/administration/portfolio-breakdown'} exact={true} component={PortfolioBreakdown} />
    </Switch>
  )
}
