import { ExtendedColumnType } from '../../basic/table/sterling-table/SterlingTable'
import { numberSorter } from '../../../utils/Sorters'
import { currency, percent } from '../../../utils/rendering/Rendering'

export const ValuationsColumns: ExtendedColumnType[] = [
  {
    title: 'Rep. Qty.',
    dataIndex: 'reported_qty',
    key: 'reported_qty',
    sorter: (a, b) => numberSorter(a.reported_qty, b.reported_qty),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
    editable: true,
  },
  {
    title: 'App. val.',
    dataIndex: 'application_value',
    key: 'application_value',
    sorter: (a, b) => numberSorter(a.application_value, b.application_value),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
    editable: true,
    render: currency,
  },
  {
    title: 'Cert. Qty.',
    dataIndex: 'certified_qty',
    key: 'certified_qty',
    sorter: (a, b) => numberSorter(a.certified_qty, b.certified_qty),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
    editable: true,
  },
  {
    title: 'Cert. Val.',
    dataIndex: 'certified_value',
    key: 'certified_value',
    sorter: (a, b) => numberSorter(a.certified_value, b.certified_value),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
    editable: true,
    render: currency,
  },
  {
    title: 'Variance',
    dataIndex: 'certified_value',
    key: 'certified_value',
    sorter: (a, b) => numberSorter(a.certified_value, b.certified_value),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
    editable: true,
    render: currency,
  },
  {
    title: 'Cert. Qty. To Date',
    dataIndex: 'certified_qty_to_date',
    key: 'certified_qty_to_date',
    sorter: (a, b) => numberSorter(a.certified_qty_to_date, b.certified_qty_to_date),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
    editable: true,
  },
  {
    title: 'Cert. Val. To Date',
    dataIndex: 'certified_value_to_date',
    key: 'certified_value_to_date',
    sorter: (a, b) => numberSorter(a.certified_value_to_date, b.certified_value_to_date),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
    editable: true,
    render: currency,
  },
  {
    title: 'Remaining Qty.',
    dataIndex: 'remaining_qty',
    key: 'remaining_qty',
    sorter: (a, b) => numberSorter(a.remaining_qty, b.remaining_qty),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
    editable: true,
  },
  {
    title: 'Contract Balance',
    dataIndex: 'contract_balance',
    key: 'contract_balance',
    sorter: (a, b) => numberSorter(a.contract_balance, b.contract_balance),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
    editable: true,
    render: currency,
  },
  {
    title: 'Retention',
    dataIndex: 'retention',
    key: 'retention',
    sorter: (a, b) => numberSorter(a.retention, b.retention),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
    editable: true,
    render: percent,
  },
  {
    title: 'Retention Val.',
    dataIndex: 'retention_value',
    key: 'retention_value',
    sorter: (a, b) => numberSorter(a.retention_value, b.retention_value),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
    editable: true,
    render: currency,
  },
  {
    title: 'Approved',
    dataIndex: 'approved',
    key: 'approved',
    sorter: (a, b) => numberSorter(a.approved, b.approved),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
    editable: true,
    render: currency,
  },
]
