interface Valuation {
  name: string
  id: number
  items: any[]
}

export const ValuationEntries: any[] = [
  {
    boqId: 7,
    id: 1,
    valuationId: 1,
    reported_qty: 279.62,
    application_value: 11100.5,
    certified_qty: 279.62,
    certified_value: 10656.48,
    variance: 444.02,
    variance_deduction: '',
    certified_qty_to_date: 279.62,
    certified_value_to_date: 10656.48,
    remaining_qty: 0,
    contract_balance: 226.36,
    retention: 0,
    retention_value: 0,
    approved: 10656.48,
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '06.11.2018',
    last_edited: '06.11.2018',
  },
  {
    boqId: 8,
    id: 2,
    valuationId: 1,
    reported_qty: 233.58,
    application_value: 0,
    certified_qty: 233.58,
    certified_value: 0,
    variance: 0,
    variance_deduction: '',
    certified_qty_to_date: 233.58,
    certified_value_to_date: 0,
    remaining_qty: 0,
    contract_balance: 0,
    retention: 0,
    retention_value: 0,
    approved: 0,
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '06.11.2018',
    last_edited: '06.11.2018',
  },
  {
    boqId: 10,
    id: 3,
    valuationId: 1,
    reported_qty: 144.83,
    application_value: 5749.67,
    certified_qty: 144.83,
    certified_value: 5519.68,
    variance: 229.99,
    variance_deduction: '',
    certified_qty_to_date: 144.83,
    certified_value_to_date: 5519.68,
    remaining_qty: 0,
    contract_balance: 117.25,
    retention: 0,
    retention_value: 0,
    approved: 5519.68,
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '06.11.2018',
    last_edited: '06.11.2018',
  },
  {
    boqId: 11,
    id: 4,
    valuationId: 1,
    reported_qty: 375.33,
    application_value: 0,
    certified_qty: 375.33,
    certified_value: 0,
    variance: 0,
    variance_deduction: '',
    certified_qty_to_date: 375.33,
    certified_value_to_date: 0,
    remaining_qty: 0,
    contract_balance: 0,
    retention: 0,
    retention_value: 0,
    approved: 0,
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '06.11.2018',
    last_edited: '06.11.2018',
  },
  {
    boqId: 12,
    id: 5,
    valuationId: 1,
    reported_qty: 1,
    application_value: 2856,
    certified_qty: 1,
    certified_value: 2741.76,
    variance: 114.24,
    variance_deduction: '',
    certified_qty_to_date: 1,
    certified_value_to_date: 2741.76,
    remaining_qty: 0,
    contract_balance: 58.24,
    retention: 0,
    retention_value: 0,
    approved: 2741.76,
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '06.11.2018',
    last_edited: '06.11.2018',
  },
  {
    boqId: 14,
    id: 6,
    valuationId: 1,
    reported_qty: 70.29,
    application_value: 2790.49,
    certified_qty: 70.29,
    certified_value: 2678.87,
    variance: 111.62,
    variance_deduction: '',
    certified_qty_to_date: 70.29,
    certified_value_to_date: 2678.87,
    remaining_qty: 0,
    contract_balance: 56.9,
    retention: 0,
    retention_value: 0,
    approved: 2678.87,
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '06.11.2018',
    last_edited: '06.11.2018',
  },
  {
    boqId: 15,
    id: 7,
    valuationId: 1,
    reported_qty: 187.45,
    application_value: 0,
    certified_qty: 187.45,
    certified_value: 0,
    variance: 0,
    variance_deduction: '',
    certified_qty_to_date: 187.45,
    certified_value_to_date: 0,
    remaining_qty: 0,
    contract_balance: 0,
    retention: 0,
    retention_value: 0,
    approved: 0,
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '06.11.2018',
    last_edited: '06.11.2018',
  },
  {
    boqId: 16,
    id: 8,
    valuationId: 1,
    reported_qty: 1,
    application_value: 1377,
    certified_qty: 1,
    certified_value: 1321.92,
    variance: 55.08,
    variance_deduction: '',
    certified_qty_to_date: 1,
    certified_value_to_date: 1321.92,
    remaining_qty: 0,
    contract_balance: 28.08,
    retention: 0,
    retention_value: 0,
    approved: 1321.92,
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '06.11.2018',
    last_edited: '06.11.2018',
  },
  {
    boqId: 18,
    id: 9,
    valuationId: 1,
    reported_qty: 63.93,
    application_value: 2537.73,
    certified_qty: 63.93,
    certified_value: 2436.22,
    variance: 101.51,
    variance_deduction: '',
    certified_qty_to_date: 63.93,
    certified_value_to_date: 2436.22,
    remaining_qty: 0,
    contract_balance: 51.75,
    retention: 0,
    retention_value: 0,
    approved: 2436.22,
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '06.11.2018',
    last_edited: '06.11.2018',
  },
  {
    boqId: 19,
    id: 10,
    valuationId: 1,
    reported_qty: 225.14,
    application_value: 0,
    certified_qty: 225.14,
    certified_value: 0,
    variance: 0,
    variance_deduction: '',
    certified_qty_to_date: 225.14,
    certified_value_to_date: 0,
    remaining_qty: 0,
    contract_balance: 0,
    retention: 0,
    retention_value: 0,
    approved: 0,
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '06.11.2018',
    last_edited: '06.11.2018',
  },
  {
    boqId: 20,
    id: 11,
    valuationId: 1,
    reported_qty: 1,
    application_value: 1224,
    certified_qty: 1,
    certified_value: 1175.04,
    variance: 48.96,
    variance_deduction: '',
    certified_qty_to_date: 1,
    certified_value_to_date: 1175.04,
    remaining_qty: 0,
    contract_balance: 24.96,
    retention: 0,
    retention_value: 0,
    approved: 1175.04,
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '06.11.2018',
    last_edited: '06.11.2018',
  },
  {
    boqId: 22,
    id: 12,
    valuationId: 1,
    reported_qty: 1,
    application_value: 7038,
    certified_qty: 1,
    certified_value: 6756.48,
    variance: 281.52,
    variance_deduction: '',
    certified_qty_to_date: 1,
    certified_value_to_date: 6756.48,
    remaining_qty: 0,
    contract_balance: 143.52,
    retention: 0,
    retention_value: 0,
    approved: 6756.48,
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '06.11.2018',
    last_edited: '06.11.2018',
  },
  {
    boqId: 23,
    id: 13,
    valuationId: 1,
    reported_qty: 1,
    application_value: 1224,
    certified_qty: 1,
    certified_value: 1175.04,
    variance: 48.96,
    variance_deduction: '',
    certified_qty_to_date: 1,
    certified_value_to_date: 1175.04,
    remaining_qty: 0,
    contract_balance: 24.96,
    retention: 0,
    retention_value: 0,
    approved: 1175.04,
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '06.11.2018',
    last_edited: '06.11.2018',
  },
  {
    boqId: 28,
    id: 14,
    valuationId: 1,
    reported_qty: 796.34,
    application_value: 16749.5,
    certified_qty: 716.71,
    certified_value: 16414.51,
    variance: 334.99,
    variance_deduction: '',
    certified_qty_to_date: 716.71,
    certified_value_to_date: 16414.51,
    remaining_qty: 508.44,
    contract_balance: 7513.35,
    retention: 10,
    retention_value: 1641.45,
    approved: 14773.06,
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '06.11.2018',
    last_edited: '06.11.2018',
  },
  {
    boqId: 29,
    id: 15,
    valuationId: 1,
    reported_qty: 1,
    application_value: 8000,
    certified_qty: 1,
    certified_value: 7840,
    variance: 160,
    variance_deduction: '',
    certified_qty_to_date: 1,
    certified_value_to_date: 7840,
    remaining_qty: 0,
    contract_balance: 160,
    retention: 10,
    retention_value: 784,
    approved: 7056,
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '06.11.2018',
    last_edited: '06.11.2018',
  },
  {
    boqId: 32,
    id: 16,
    valuationId: 1,
    reported_qty: 99.45,
    application_value: 696150,
    certified_qty: 89.51,
    certified_value: 682227,
    variance: 13923,
    variance_deduction: '',
    certified_qty_to_date: 89.51,
    certified_value_to_date: 682227,
    remaining_qty: 63.5,
    contract_balance: 312273,
    retention: 10,
    retention_value: 68222.7,
    approved: 614004.3,
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '06.11.2018',
    last_edited: '06.11.2018',
  },
  {
    boqId: 34,
    id: 17,
    valuationId: 1,
    reported_qty: 796.34,
    application_value: 32220.11,
    certified_qty: 716.71,
    certified_value: 31575.71,
    variance: 644.4,
    variance_deduction: '',
    certified_qty_to_date: 716.71,
    certified_value_to_date: 31575.71,
    remaining_qty: 508.44,
    contract_balance: 14453.02,
    retention: 10,
    retention_value: 3157.57,
    approved: 28418.14,
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '06.11.2018',
    last_edited: '06.11.2018',
  },
  {
    boqId: 35,
    id: 18,
    valuationId: 1,
    reported_qty: 1,
    application_value: 33.76,
    certified_qty: 1,
    certified_value: 33.08,
    variance: 0.68,
    variance_deduction: '',
    certified_qty_to_date: 1,
    certified_value_to_date: 33.08,
    remaining_qty: 0,
    contract_balance: 0.68,
    retention: 10,
    retention_value: 3.31,
    approved: 29.78,
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '06.11.2018',
    last_edited: '06.11.2018',
  },
  {
    boqId: 37,
    id: 19,
    valuationId: 1,
    reported_qty: 99.45,
    application_value: 8739.36,
    certified_qty: 89.51,
    certified_value: 8564.57,
    variance: 174.79,
    variance_deduction: '',
    certified_qty_to_date: 89.51,
    certified_value_to_date: 8564.57,
    remaining_qty: 63.5,
    contract_balance: 3920.23,
    retention: 10,
    retention_value: 856.46,
    approved: 7708.12,
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '06.11.2018',
    last_edited: '06.11.2018',
  },
  {
    boqId: 39,
    id: 20,
    valuationId: 1,
    reported_qty: 1,
    application_value: 0,
    certified_qty: 1,
    certified_value: 0,
    variance: 0,
    variance_deduction: '',
    certified_qty_to_date: 1,
    certified_value_to_date: 0,
    remaining_qty: 0,
    contract_balance: 0,
    retention: 10,
    retention_value: 0,
    approved: 0,
    creator: 'admin',
    last_editor: 'admin',
    creation_date: '06.11.2018',
    last_edited: '06.11.2018',
  },
]

export const ValuationsData: Valuation[] = [
  {
    name: 'Pelcoo Partitions - March 2020',
    id: 1,
    items: ValuationEntries.filter((q) => q.valuationId === 1),
  },
  {
    name: 'Pelcoo Partitions - April 2020',
    id: 2,
    items: ValuationEntries.filter((q) => q.valuationId === 2),
  },
]

export function EmptyValuationFor(boqId: number, valuationId: number): any {
  return {
    boqId,
    valuationId,
    reported_qty: 0,
    application_value: 0,
    certified_qty: 0,
    certified_value: 0,
    variance: 0,
    variance_deduction: 0,
    certified_qty_to_date: 0,
    certified_value_to_date: 0,
    remaining_qty: 0,
    contract_balance: 0,
    retention: 0,
    retention_value: 0,
    approved: 0,
  }
}
