import { ModelNode } from '../../forge-viewer/ForgeViewer.model'
import { Queue } from 'queue-typescript'

export const TakeOffTypes = [
  'Wall',
  'Slab',
  'Beam',
  'Column',
  'Pile',
  'Door',
  'Window',
  'Flooring',
  'Ceiling',
  'Ancillaries',
  'Roof',
  'Tray',
  'Duct',
  'Pipe',
  'Fitting',
  'Equipment',
  'Fixture',
]
interface Task {
  node: ModelNode
  typeContext?: string
  parent?: ModelNode
}

export interface TakeOffItem {
  id: number
  code: string
  name: string
  takeOffType: string
  children: TakeOffDetailsItem[]
}

export interface TakeOffDetailsItem {
  id: number
  name: string
  takeOffType: string
  takeOffExternalType: string
  count: number
  volume?: number
  topArea?: number
  bottomArea?: number
  sideAreas?: number
  endSideAreas?: number
  edgeArea?: number
  edgeLength?: number
  width?: number
  height?: number
  length?: number
  diameter?: number
}

export const parseTakeOffData = (root: ModelNode): TakeOffItem[] => {
  const takeOffItems: { [key: string]: TakeOffItem } = {}

  const addTakeOffChild = (child: ModelNode, parent: ModelNode, takeOffType: string) => {
    const [code, name] = parseNodeName(parent.name)
    const takeOffDetailsItem = createTakeOffDetailsItem(child, name, takeOffType)
    if (parent.name in takeOffItems) {
      takeOffItems[parent.name].children.push(takeOffDetailsItem)
    } else {
      takeOffItems[parent.name] = {
        id: parent.id,
        code,
        name,
        takeOffType,
        children: [takeOffDetailsItem],
      }
    }
  }

  const queue = new Queue<Task>(createTask(root))
  while (queue.length > 0) {
    const task = queue.dequeue()
    if ((task.node.children === undefined || task.node.children.length === 0) && task.parent && task.typeContext) {
      addTakeOffChild(task.node, task.parent, task.typeContext)
    } else {
      const typeCandidate = TakeOffTypes.find((t) => task.node.name.toLowerCase().includes(t.toLowerCase()))
      task.node.children?.forEach((child) =>
        queue.enqueue(createTask(child, typeCandidate || task.typeContext, task.node))
      )
    }
  }

  return Object.values(takeOffItems)
}

const createTask = (node: ModelNode, typeContext?: string, parent?: ModelNode) => {
  return {
    node,
    typeContext,
    parent,
  }
}

const parseNodeName = (name: string): [string, string] => {
  const splitted = name.split('_')
  return [splitted[0], splitted[splitted.length - 1]]
}

const getPropertyValue = (displayName: string, node: ModelNode): number | undefined => {
  return Number(node.properties.find((p) => p.displayName === displayName)?.displayValue)
}

export const createTakeOffDetailsItem = (node: ModelNode, name: string, takeOffType: string): TakeOffDetailsItem => {
  return {
    id: node.id,
    name,
    takeOffType,
    takeOffExternalType: 'BIM',
    count: 1,
    volume: getPropertyValue('Volume', node),
    topArea: getPropertyValue('Area', node),
    bottomArea: getPropertyValue('Area', node),
    sideAreas: getPropertyValue('Area', node),
    endSideAreas: getPropertyValue('End Side Areas', node),
    edgeArea: getPropertyValue('Edge Area', node),
    edgeLength: getPropertyValue('Edge Length', node),
    width: getPropertyValue('Width', node),
    height: getPropertyValue('Height', node),
    length: getPropertyValue('Length', node),
    diameter: getPropertyValue('Diameter', node),
  }
}

export const create2DTakeOffDetailsItem = (parent: TakeOffItem, measurement?: any): TakeOffDetailsItem => {
  return {
    id: parent.children && parent.children.length > 0 ? Math.max(...parent.children.map((i) => i.id)) : parent.id * 50,
    name: parent.name,
    takeOffType: parent.takeOffType,
    takeOffExternalType: '2D',
    count: 1,
    length: measurement ? parseFloat(measurement.distance) : undefined,
  }
}
