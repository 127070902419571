import { Action } from 'redux'

export const SET_LEFT_MENU_SIDER_COLLAPSED = 'SET_LEFT_MENU_SIDER_COLLAPSED'

export interface SetLeftMenuSiderCollapsedAction extends Action<string> {
  collapsed: boolean
}

export const setLeftMenuSiderCollapsed = (collapsed: boolean) => {
  return {
    type: SET_LEFT_MENU_SIDER_COLLAPSED,
    collapsed,
  }
}
