import { createLayout } from '../../basic/table/columns-layout-manager/helpers/LayoutHelpers'

const OverviewLayoutColumns = [
  'wp_code',
  'title',
  'note',
  'description',
  'location',
  'cost_items',
  'invited',
  'accepted',
  'declined',
  'status',
  'responsible',
  'due_date',
  'awarded',
  'currency',
  'base_total',
]

const PriceLayoutColumns = [
  'wp_code',
  'title',
  'note',
  'description',
  'location',
  'currency',
  'total_labour_cost',
  'total_material_cost',
  'total_plant_cost',
  'total_subcontractor_cost',
  'base_total',
  'net_total',
  'gross_total',
]

const BidLayoutColumns = [
  'wp_code',
  'title',
  'note',
  'description',
  'location',
  'currency',
  'base_total',
  'net_total',
  'gross_total',
  'awarded_total_cost',
  'total_cost_difference',
]

const AdminLayoutColumns = [
  'wp_code',
  'title',
  'note',
  'description',
  'location',
  'creator',
  'last_editor',
  'creation_date',
  'last_edited',
]

export const WorkPackagesDefaultLayouts = [
  createLayout('Overview', OverviewLayoutColumns),
  createLayout('Price', PriceLayoutColumns),
  createLayout('Bid', BidLayoutColumns),
  createLayout('Admin', AdminLayoutColumns),
]
