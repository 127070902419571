import React, { Key } from 'react'
import { Table } from 'antd'
import { portfolioColumns } from './PortfolioTable.columns'
import PortfolioTableHeader from './header/PortfolioTableHeader'
import './PortfolioTable.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../context/reducer'
import { setVisibleColumns, setLockers } from '../../../../context/columns-visibility/ColumnsVisibility.actions'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { headerHeight } from '../../../../constants/dimensions'
import { safeComparator } from '../../../../utils/Sorters'
import { useHistory } from 'react-router-dom'

interface PortfolioTableProps {
  data: any[]
  selectedRows?: number[]
  onSelect?: (key: number) => void
  isInNewWindow?: boolean
}

export const portfolioTableId = 'portfolio'

function PortfolioTable({ data, selectedRows, onSelect, isInNewWindow }: PortfolioTableProps): JSX.Element {
  const history = useHistory()

  const columns = portfolioColumns(() => history.push('/main'))
  const renderHeader = () => <PortfolioTableHeader columns={columns} />

  const { height } = useWindowDimensions()

  const rawVisibleColumnsKeys = useSelector(selectVisibleColumnsKeys)
  const rawLockersKeys = useSelector(selectLockersKeys)

  const dispatch = useDispatch()

  if (!rawVisibleColumnsKeys) {
    dispatch(
      setVisibleColumns(
        'portfolio',
        columns.map((c) => c.key as Key)
      )
    )
  }

  if (!rawLockersKeys) {
    dispatch(
      setLockers(
        'portfolio',
        columns.filter(({ fixed }) => fixed).map(({ key }) => key as Key)
      )
    )
  }
  const visibleColumnsKeys: Key[] = rawVisibleColumnsKeys || []
  const lockerKeys: Key[] = rawLockersKeys || []

  const visibleColumns = columns
    .filter((column) => column.key && visibleColumnsKeys.includes(column.key))
    .map((column) => {
      return {
        ...column,
        fixed: lockerKeys.includes(column.key as Key) ? ('left' as 'left') : undefined,
      }
    })
    .sort((a, b) => safeComparator(a?.fixed, b?.fixed, () => 0))

  return (
    <Table
      className={'portfolio-table'}
      pagination={false}
      size={'middle'}
      dataSource={data}
      scroll={
        data.length && !isInNewWindow
          ? { x: 180 * visibleColumns.length, y: (height - headerHeight - 2 * 8) / 2 }
          : { x: 180 * visibleColumns.length, y: height * 0.8 }
          ? undefined
          : undefined
      }
      columns={visibleColumns}
      title={renderHeader}
      rowSelection={{
        selectedRowKeys: selectedRows,
        onSelect: (record: any) => onSelect && onSelect(record.key),
        type: 'radio',
        columnWidth: 0,
      }}
      onRow={onRowSelect(onSelect)}
    />
  )
}

const selectVisibleColumnsKeys = (state: RootState) => state.columnsVisibility.visibleColumnsKeys.get(portfolioTableId)
const selectLockersKeys = (state: RootState) => state.columnsVisibility.lockerStateKeys.get(portfolioTableId)

const onRowSelect = (onSelect?: (key: number) => void) => (record: any) => {
  return {
    onClick: () => onSelect && onSelect(record.key),
  }
}

export default PortfolioTable
