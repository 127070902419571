import { ExtendedColumnType } from '../../../../basic/table/sterling-table/SterlingTable'
import { stringSorter } from '../../../../../utils/Sorters'

export const contactsExternalColumns: ExtendedColumnType[] = [
  {
    title: 'Item Code',
    dataIndex: 'itemCode',
    fixed: 'left',
    key: 'Item Code',
    width: 240,
    sorter: (a, b) => stringSorter(a.itemCode, b.itemCode),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'ascend',
    editable: true,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    fixed: 'left',
    key: 'Title',
    width: 240,
    sorter: (a, b) => stringSorter(a.title, b.title),
    sortDirections: ['ascend', 'descend'],
    editable: true,
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'Notes',
    width: 130,
    sorter: (a, b) => stringSorter(a.notes, b.notes),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'Description',
    width: 130,
    sorter: (a, b) => stringSorter(a.description, b.description),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Contact Person',
    dataIndex: 'contactPerson',
    key: 'Contact Person',
    width: 130,
    sorter: (a, b) => stringSorter(a.contactPerson, b.contactPerson),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'Role',
    width: 130,
    sorter: (a, b) => stringSorter(a.role, b.role),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'Type',
    width: 130,
    sorter: (a, b) => stringSorter(a.type, b.type),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Phone Number',
    dataIndex: 'phoneNumber',
    key: 'Phone Number',
    width: 130,
    sorter: (a, b) => stringSorter(a.phoneNumber, b.phoneNumber),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Mobile Number',
    dataIndex: 'mobileNumber',
    key: 'Mobile Number',
    width: 130,
    sorter: (a, b) => stringSorter(a.mobileNumber, b.mobileNumber),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'E-mail',
    width: 180,
    sorter: (a, b) => stringSorter(a.email, b.email),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'Address',
    width: 130,
    sorter: (a, b) => stringSorter(a.address, b.address),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'City/Residence',
    dataIndex: 'cityResidence',
    key: 'City Residence',
    width: 130,
    sorter: (a, b) => stringSorter(a.cityResidence, b.cityResidence),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'State/Province',
    dataIndex: 'stateProvince',
    key: 'State Province',
    width: 130,
    sorter: (a, b) => stringSorter(a.stateProvince, b.stateProvince),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Post Code',
    dataIndex: 'postCode',
    key: 'Post Code',
    width: 130,
    sorter: (a, b) => stringSorter(a.postCode, b.postCode),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'Country',
    width: 130,
    sorter: (a, b) => stringSorter(a.country, b.country),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'URL',
    width: 130,
    sorter: (a, b) => stringSorter(a.url, b.url),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Creation Date',
    dataIndex: 'creationDate',
    key: 'Creation Date',
    width: 130,
    sorter: (a, b) => stringSorter(a.creationDate, b.creationDate),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Last Edited',
    dataIndex: 'lastEdited',
    key: 'Last Edited',
    width: 130,
    sorter: (a, b) => stringSorter(a.lastEdited, b.lastEdited),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Creator',
    dataIndex: 'creator',
    key: 'Creator',
    width: 130,
    sorter: (a, b) => stringSorter(a.creator, b.creator),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Last Editor',
    dataIndex: 'lastEditor',
    key: 'Last Editor',
    width: 130,
    sorter: (a, b) => stringSorter(a.lastEditor, b.lastEditor),
    sortDirections: ['ascend', 'descend'],
  },
]
