import { Action } from 'redux'

export const SET_LAYOUTS = 'SET_LAYOUTS'

export interface SetLayoutsAction extends Action<string> {
  layoutKey: string
  layoutState: any
}

export const setLayouts = (layoutKey: string, layoutState: any) => {
  return {
    type: SET_LAYOUTS,
    layoutKey,
    layoutState,
  }
}
