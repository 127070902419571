import React from 'react'
import AdministrationTemplate from './template/AdministrationTemplate'
import { portfolioBreakdownColumns } from './Administration.columns'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../context/reducer'
import SterlingTable from '../../basic/table/sterling-table/SterlingTable'
import { AdministrationHeader } from './header/AdministrationHeader'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { calculateHeightForWholePageTable } from '../../../constants/dimensions'
import NewWindow from '../../basic/new-window/NewWindow'
import ReplacementPane from '../../basic/new-window/ReplacementPane'
import { setOpenWindowState } from '../../../context/open-windows/OpenWindows.actions'
import BreadcrumbPanel from '../../basic/breadcrumb/BreadcrumbPanel'
import { newWindowHeight, newWindowWidth } from '../../../constants/sizes'

function PortfolioBreakdown(): JSX.Element {
  return (
    <AdministrationTemplate selectedKey={'portfolio-breakdown'}>
      <PortfolioBreakdownPart />
    </AdministrationTemplate>
  )
}

function PortfolioBreakdownPart(): JSX.Element {
  const { height } = useWindowDimensions()
  const tableHeight = calculateHeightForWholePageTable(height) - 64

  const dispatch = useDispatch()
  const portfolioBreakdown = useSelector((state: RootState) => state.sterlingTable.parsedData.breakdown)

  const getOpenWindow = (id: string) => (state: RootState) => state.openWindows.windows.get(id) || false
  const portfolioBreakdownWindowId = 'portfolioBreakdown'
  const isportfolioBreakdownWindowOpen = useSelector(getOpenWindow(portfolioBreakdownWindowId))

  return (
    <div>
      <BreadcrumbPanel
        moveTo={'/administration/portfolio-breakdown'}
        displayName={'Portfolio Breakdown'}
        categoryName={'Administration'}
        openWindow={() => dispatch(setOpenWindowState(portfolioBreakdownWindowId, true))}
      />
      <div className="title" style={{ fontSize: '14px', fontWeight: 'bolder', marginBottom: '35px' }}>
        Portfolio Breakdown
      </div>

      <NewWindow
        height={newWindowHeight}
        width={newWindowWidth}
        isOpen={isportfolioBreakdownWindowOpen}
        onPortalClose={() => dispatch(setOpenWindowState(portfolioBreakdownWindowId, false))}
        replacementPane={
          <ReplacementPane
            onHide={() => dispatch(setOpenWindowState(portfolioBreakdownWindowId, false))}
            onWindowShow={() => {}}
            style={{ width: '100%', height: 450, backgroundColor: '#fff' }}
          />
        }
      >
        <SterlingTable
          renderHeader={(props) => <AdministrationHeader sterlingTableHeaderProps={props} />}
          tableId={'portfolioBreakdown'}
          dataSource={portfolioBreakdown}
          columns={portfolioBreakdownColumns}
          tableHeight={tableHeight}
          defaultPagination={false}
        />
      </NewWindow>
    </div>
  )
}

export default PortfolioBreakdown
