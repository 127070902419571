import React from 'react'

import { Tabs } from 'antd'
import './Documentation.scss'
import DocumentationManage from './parts/DocumentationManage'
import DocumentationReview from './parts/DocumentationReview'
import ProjectTemplate from '../project/ProjectTemplate'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../context/reducer'
import { setOpenWindowState } from '../../../context/open-windows/OpenWindows.actions'
import NewWindow from '../../basic/new-window/NewWindow'
import ReplacementPane from '../../basic/new-window/ReplacementPane'
import BreadcrumbPanel from '../../basic/breadcrumb/BreadcrumbPanel'
import { newWindowHeight, newWindowWidth } from '../../../constants/sizes'

const TabPane = Tabs.TabPane

function Documentation(): JSX.Element {
  return (
    <ProjectTemplate selectedKey={'documentation'}>
      <DocumentationPart />
    </ProjectTemplate>
  )
}

function DocumentationPart(): JSX.Element {
  const dispatch = useDispatch()

  const getOpenWindow = (id: string) => (state: RootState) => state.openWindows.windows.get(id) || false
  const documentationWindowId = 'documentationWindow'
  const isDocumentationWindowOpen = useSelector(getOpenWindow(documentationWindowId))

  return (
    <>
      <BreadcrumbPanel
        moveTo={'documentation'}
        displayName={'Documentation'}
        categoryName={'Sample Project'}
        openWindow={() => dispatch(setOpenWindowState(documentationWindowId, true))}
      />
      <NewWindow
        height={newWindowHeight}
        width={newWindowWidth}
        isOpen={isDocumentationWindowOpen}
        onPortalClose={() => dispatch(setOpenWindowState(documentationWindowId, false))}
        replacementPane={
          <ReplacementPane
            onHide={() => dispatch(setOpenWindowState(documentationWindowId, false))}
            onWindowShow={() => {}}
            style={{ width: '100%', height: 450, backgroundColor: '#fff' }}
          />
        }
      >
        <Tabs>
          <TabPane tab={'Manage'} key="manage">
            <DocumentationManage />
          </TabPane>
          <TabPane tab={'Review'} key="review">
            <DocumentationReview />
          </TabPane>
        </Tabs>
      </NewWindow>
    </>
  )
}

export default Documentation
