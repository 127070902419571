export const ProjectWBS = [
  {
    id: 10000,
    wbs_code: '03',
    title: '401 - ENTRANCE BUILDING',
  },
  {
    id: 10001,
    wbs_code: '03.01',
    title: '01 - Substructure',
  },
  {
    id: 10002,
    wbs_code: '03.01.01',
    title: 'Foundation Systems',
  },
  {
    id: 10003,
    wbs_code: '03.01.01.00',
    title: 'Foundation Excavation',
  },
  {
    id: 10004,
    wbs_code: '03.01.01.00.00',
    title: 'Excavation to commencing at level stated if not original ground level',
  },
  {
    id: 10005,
    wbs_code: '03.01.01.00.00.0010',
    title: 'Slabs / Basements and the like Foundations',
  },
  {
    id: 10006,
    wbs_code: '03.01.01.00.00.0020',
    title: 'Pile Caps Foundations',
  },
  {
    id: 10007,
    wbs_code: '03.01.01.00.00.0030',
    title: 'Tie beams',
  },
  {
    id: 10008,
    wbs_code: '03.01.01.00.00.0040',
    title: 'Ground beams',
  },
  {
    id: 10009,
    wbs_code: '03.01.01.00.00.0050',
    title: 'Extra Over all types of Excavation irrespective of depth',
  },
  {
    id: 10010,
    wbs_code: '03.01.02',
    title: 'Piled foundations',
  },
  {
    id: 10011,
    wbs_code: '03.01.02.01',
    title:
      'Piling mats and platforms (installing, moving, modifying and removal on completion) . as specified, NBS D30',
  },
  {
    id: 10012,
    wbs_code: '03.01.02.01.01',
    title: 'Piled foundations: Piling mats/platforms: details, including thickness of mat/platform (mm), to be stated.',
  },
  {
    id: 10013,
    wbs_code: '03.01.02.01.01.0010',
    title: 'Piling mats/ platforms',
  },
  {
    id: 10014,
    wbs_code: '03.01.02.02',
    title: 'Specialist foundations Piles, piles caps and underpinning',
  },
  {
    id: 10015,
    wbs_code: '03.01.02.02.04',
    title: 'Piles as specified, NBS D30',
  },
  {
    id: 10016,
    wbs_code: '03.01.02.02.07',
    title: 'Disposal of excavated material arising from piling',
  },
  {
    id: 10017,
    wbs_code: '03.01.02.02.09',
    title: 'Cutting off tops of concrete piles and preparing pile heads',
  },
  {
    id: 10018,
    wbs_code: '03.01.02.02.10',
    title: 'Pile tests',
  },
  {
    id: 10019,
    wbs_code: '03.01.02.02.12',
    title: 'Pile Caps',
  },
  {
    id: 10020,
    wbs_code: '03.01.02.02.12.0010',
    title: 'In-situ reinforced Concrete Pile Caps as specified, NBS E05',
  },
  {
    id: 10021,
    wbs_code: '03.01.02.02.13',
    title: 'Ground beams',
  },
  {
    id: 10022,
    wbs_code: '03.01.02.02.13.0010',
    title: 'In-situ reinforced Concrete Tie Beams as specified, NBS E05',
  },
  {
    id: 10023,
    wbs_code: '03.01.03',
    title: 'Lowest Floor construction below the underside of screed',
  },
  {
    id: 10024,
    wbs_code: '03.01.03.01',
    title: 'Lowest floor construction',
  },
  {
    id: 10025,
    wbs_code: '03.01.03.01.01',
    title: 'Lowest floor construction',
  },
  {
    id: 10026,
    wbs_code: '03.01.03.01.01.0010',
    title: 'In-situ reinforced Concrete Ground Bearing Slab 250mm as specified, NBS E05',
  },
  {
    id: 10027,
    wbs_code: '03.01.03.01.01.0020',
    title: 'Reinforced Concrete Slab 250mm as specified, NBS E05',
  },
  {
    id: 10028,
    wbs_code: '03.01.03.01.01.0030',
    title: 'In situ reinforced Concrete Slab Edge Thickening 125x250 as specified, NBS E05',
  },
  {
    id: 10029,
    wbs_code: '03.01.03.01.03',
    title: 'Extra Over Lowest Floor Construction for Forming Stairs, Lift Pits and the Like',
  },
  {
    id: 10030,
    wbs_code: '03.01.03.01.03.0010',
    title: 'In-situ reinforced Concrete Lift Pit Wall 250mm thick as specified, NBS E05',
  },
  {
    id: 10031,
    wbs_code: '03.01.03.01.03.0020',
    title: 'In-situ reinforced Concrete Lift Pit Base 750mm thick as specified, NBS E05',
  },
  {
    id: 10032,
    wbs_code: '03.02',
    title: '02 - Superstructure',
  },
  {
    id: 10033,
    wbs_code: '03.02.01',
    title: 'Frame',
  },
  {
    id: 10034,
    wbs_code: '03.02.01.01',
    title: 'Structural Steel Frames',
  },
  {
    id: 10035,
    wbs_code: '03.02.01.01.01',
    title: 'Structural Steel Frame',
  },
  {
    id: 10036,
    wbs_code: '03.02.01.01.01.0010',
    title: 'Universal beams as specified, NBS G10',
  },
  {
    id: 10037,
    wbs_code: '03.02.01.01.01.0020',
    title: 'Universal columns as specified, NBS G10',
  },
  {
    id: 10038,
    wbs_code: '03.02.01.01.01.0030',
    title: 'Hollow Sections as specified, NBS G10',
  },
  {
    id: 10039,
    wbs_code: '03.02.01.01.01.0040',
    title: 'Parallel Flange Channel Rafter as specified, NBS G10',
  },
  {
    id: 10040,
    wbs_code: '03.02.01.01.01.0050',
    title: 'Kingspan M 145065220 Purlins as specified, NBS G10',
  },
  {
    id: 10041,
    wbs_code: '03.02.01.03',
    title: 'Protective casings to columns an beams for structural or protective purposes',
  },
  {
    id: 10042,
    wbs_code: '03.02.01.03.03',
    title: 'Concrete casings to steel frames',
  },
  {
    id: 10043,
    wbs_code: '03.02.01.03.03.0010',
    title: 'Stone clad colonnade vertical as specified, NBS F22',
  },
  {
    id: 10044,
    wbs_code: '03.02.01.03.03.0020',
    title: 'Stone clad colonnade horizontal as specified, NBS F22',
  },
  {
    id: 10045,
    wbs_code: '03.02.01.04',
    title: 'Superstructure Columns and Beams',
  },
  {
    id: 10046,
    wbs_code: '03.02.01.04.01',
    title: 'Concrete frames Definition: Concrete columns and beams',
  },
  {
    id: 10047,
    wbs_code: '03.02.01.04.01.0010',
    title: 'In-situ reinforced Concrete Column as specified, NBS E10',
  },
  {
    id: 10048,
    wbs_code: '03.02.01.04.01.0020',
    title: 'Stair Plinth as specified, NBS E05',
  },
  {
    id: 10049,
    wbs_code: '03.02.01.04.01.0030',
    title: 'Reinforced Concrete Beams as specified, NBS E05',
  },
  {
    id: 10050,
    wbs_code: '03.02.01.04.01.0040',
    title: 'Reinforced Concrete Upstands as specified, NBS E10',
  },
  {
    id: 10051,
    wbs_code: '03.02.01.04.01.0050',
    title: 'Reinforced Concrete Walls 200mm as specified, NBS E05',
  },
  {
    id: 10052,
    wbs_code: '03.02.01.04.01.0060',
    title: 'Concrete Infill Panel as specified, NBS B14A',
  },
  {
    id: 10053,
    wbs_code: '03.02.01.04.01.0070',
    title: 'Precast Concrete Walls 150mm as specified, NBS E60 .183',
  },
  {
    id: 10054,
    wbs_code: '03.02.02',
    title: 'Upper Floors',
  },
  {
    id: 10055,
    wbs_code: '03.02.02.02',
    title:
      'Reinforced and post tensioned concrete floor decks consisting of proprietary precast units, a combination of In-situ concrete with filler units of other material',
  },
  {
    id: 10056,
    wbs_code: '03.02.02.02.01',
    title: 'Suspended Floor Slabs',
  },
  {
    id: 10057,
    wbs_code: '03.02.02.02.01.0010',
    title: 'Reinforced Concrete Slab 170mm as specified, NBS E05',
  },
  {
    id: 10058,
    wbs_code: '03.02.02.02.01.0020',
    title: 'Reinforced Concrete Slab 275mm as specified, NBS E05',
  },
  {
    id: 10059,
    wbs_code: '03.02.02.02.01.0030',
    title: 'Reinforced Concrete Slab 150mm as specified, NBS E05',
  },
  {
    id: 10060,
    wbs_code: '03.02.02.02.01.0040',
    title: 'Reinforced Concrete Slab 200mm as specified, NBS E10',
  },
  {
    id: 10061,
    wbs_code: '03.02.02.02.01.0050',
    title: 'Heavy duty floor 100mm screed with 105 mm insulation M10/134',
  },
  {
    id: 10062,
    wbs_code: '03.02.02.02.01.0060',
    title: 'Raised access floor, as specified, NBS K41',
  },
  {
    id: 10063,
    wbs_code: '03.02.03',
    title: 'Roof',
  },
  {
    id: 10064,
    wbs_code: '03.02.03.02',
    title: 'Roof coverings and coatings to roofs',
  },
  {
    id: 10065,
    wbs_code: '03.02.03.02.01',
    title: 'Roof coverings, non-structural screeds, thermal insulation, and surface treatments',
  },
  {
    id: 10066,
    wbs_code: '03.02.03.02.01.0010',
    title:
      'Metal standing seam roof to clerestory rooflights inclusive of ridges, flashing, coping and fair ends as required, all as specified, NBS H31A',
  },
  {
    id: 10067,
    wbs_code: '03.02.03.02.01.0020',
    title:
      'Metal standing seam roof to clerestory rooflights inclusive of ridges, flashing, coping and fair ends as required, all as specified, NBS H31A',
  },
  {
    id: 10068,
    wbs_code: '03.02.03.02.05',
    title: 'Canopy Soffit',
  },
  {
    id: 10069,
    wbs_code: '03.02.03.02.05.0010',
    title: 'Aluminium soffit planks as specified, NBS H72A',
  },
  {
    id: 10070,
    wbs_code: '03.02.03.02.05.0020',
    title: 'Aluminium Fascia vertical as specified, NBS H72A',
  },
  {
    id: 10071,
    wbs_code: '03.02.04',
    title: 'Stairs and Ramps',
  },
  {
    id: 10072,
    wbs_code: '03.02.04.04',
    title: 'Stair/ramp balustrades and handrails',
  },
  {
    id: 10073,
    wbs_code: '03.02.04.04.01',
    title: 'Stair Structures',
  },
  {
    id: 10074,
    wbs_code: '03.02.04.04.01.0010',
    title: 'Precast Concrete Stair Landing as specified, NBS E60',
  },
  {
    id: 10075,
    wbs_code: '03.02.04.04.01.0020',
    title: 'Precast Concrete Stair as specified, NBS E05',
  },
  {
    id: 10076,
    wbs_code: '03.02.04.04.01.0030',
    title: 'Stair Handrail with steel infill panel as specified, NBS L30A',
  },
  {
    id: 10077,
    wbs_code: '03.02.04.04.01.0040',
    title: 'Stair Handrail as specified, NBS L30A',
  },
  {
    id: 10078,
    wbs_code: '03.02.05',
    title: 'External walls',
  },
  {
    id: 10079,
    wbs_code: '03.02.05.01',
    title: 'External enclosing walls above ground level Definition: External enclosing walls above ground floor level',
  },
  {
    id: 10080,
    wbs_code: '03.02.05.01.01',
    title: 'External walls as specified, NBS F10B',
  },
  {
    id: 10081,
    wbs_code: '03.02.05.01.01.0010',
    title: 'Engineering brick plinth with 150mm air barrier as specified, NBS F10',
  },
  {
    id: 10082,
    wbs_code: '03.02.05.01.01.0020',
    title: 'Facing Brick with 150mm air barrier as specified, NBS F10',
  },
  {
    id: 10083,
    wbs_code: '03.02.05.01.01.0050',
    title: 'Engineering brick plinth with 182mm air barrier as specified, NBS F10',
  },
  {
    id: 10084,
    wbs_code: '03.02.05.01.01.0070',
    title: 'Painted fair faced block work as specified, NBS M60/110 as specified, NBS F10B',
  },
  {
    id: 10085,
    wbs_code: '03.02.05.01.01.0080',
    title: 'Metal cladding as specified, NBS H92A',
  },
  {
    id: 10086,
    wbs_code: '03.02.05.01.01.0090',
    title: 'Metal cladding as specified, NBS H92A',
  },
  {
    id: 10087,
    wbs_code: '03.02.05.01.01.0100',
    title: 'Through colour render with 150mm air barrier as specified, NBS M20',
  },
  {
    id: 10088,
    wbs_code: '03.02.05.01.01.0110',
    title: 'Through colour render with 180mm air barrier as specified, NBS M20',
  },
  {
    id: 10089,
    wbs_code: '03.02.06',
    title: 'Windows and external doors',
  },
  {
    id: 10090,
    wbs_code: '03.02.06.01',
    title: 'External windows',
  },
  {
    id: 10091,
    wbs_code: '03.02.06.01.01',
    title: 'Windows including ironmongery work and materials in forming opening',
  },
  {
    id: 10092,
    wbs_code: '03.02.06.01.01.0010',
    title: 'Steel Framed Window inclusive of ironmongery as specified, NBS L10',
  },
  {
    id: 10093,
    wbs_code: '03.02.06.01.01.0020',
    title: 'Opaque Glazed Panels as specified, NBS L40',
  },
  {
    id: 10094,
    wbs_code: '03.02.06.01.01.0030',
    title: 'Steel Framed Window as specified, NBS L10/312 Swing vent by Fendor Ltd',
  },
  {
    id: 10095,
    wbs_code: '03.02.06.01.01.0040',
    title: 'Glazed Curtain Wall as specified, NBS H11a',
  },
  {
    id: 10096,
    wbs_code: '03.02.06.01.01.0050',
    title: 'Ballistic Steel Curtain Wall as specified, NBS H11',
  },
  {
    id: 10097,
    wbs_code: '03.02.06.01.01.0060',
    title: 'Ballistic Steel Framed Windows as specified, NBS L10',
  },
  {
    id: 10098,
    wbs_code: '03.02.06.01.01.0070',
    title: 'Steel Framed Window inclusive of ironmongery as specified, NBS L10',
  },
  {
    id: 10099,
    wbs_code: '03.02.06.01.01.0080',
    title: 'External Security Airlocks as specified, NBS L20',
  },
  {
    id: 10100,
    wbs_code: '03.02.06.01.01.0090',
    title: 'Side windows as specified, NBS L10',
  },
  {
    id: 10101,
    wbs_code: '03.02.06.01.02',
    title: 'Louvers',
  },
  {
    id: 10102,
    wbs_code: '03.02.06.01.02.0010',
    title: 'Glass Louvers inclusive of ironmongery as specified, NBS H11',
  },
  {
    id: 10103,
    wbs_code: '03.02.06.01.02.0020',
    title: 'Shading Louvers as specified, NBS L10/680',
  },
  {
    id: 10104,
    wbs_code: '03.02.06.01.02.0030',
    title: 'Metal louvres, as specified, as specified, NBS L10',
  },
  {
    id: 10105,
    wbs_code: '03.02.06.01.02.0040',
    title: 'Window lintels in accordance to client schedule',
  },
  {
    id: 10106,
    wbs_code: '03.02.06.02',
    title: 'External doors Definition: Doors and openings in external enclosing walls',
  },
  {
    id: 10107,
    wbs_code: '03.02.06.02.01',
    title: 'External doors',
  },
  {
    id: 10108,
    wbs_code: '03.02.06.02.01.0010',
    title: 'Steel Doors Type B inclusive of ironmongery as specified, NBS L20/422',
  },
  {
    id: 10109,
    wbs_code: '03.02.06.02.01.0020',
    title: 'Steel Doors Type K inclusive of ironmongery as specified, NBS L20/422',
  },
  {
    id: 10110,
    wbs_code: '03.02.06.02.01.0030',
    title: 'Metal clad vehicle gate as specified, NBS L20',
  },
  {
    id: 10111,
    wbs_code: '03.02.06.02.01.0040',
    title: 'Vehicle gate with cladding as specified, NBS L20',
  },
  {
    id: 10112,
    wbs_code: '03.02.06.02.01.0050',
    title: 'Steel Doors Type M inclusive of ironmongery as specified, NBS L20/422',
  },
  {
    id: 10113,
    wbs_code: '03.02.06.02.01.0060',
    title: 'Steel Doors Type L inclusive of ironmongery as specified, NBS L20/422',
  },
  {
    id: 10114,
    wbs_code: '03.02.06.02.01.0070',
    title: 'Steel Doors Type G inclusive of ironmongery as specified, NBS L20/422',
  },
  {
    id: 10115,
    wbs_code: '03.02.06.02.01.0080',
    title: 'Steel Doors Type E inclusive of ironmongery as specified, NBS L20/422',
  },
  {
    id: 10116,
    wbs_code: '03.02.06.02.01.0090',
    title: 'Steel Doors Type D inclusive of ironmongery as specified, NBS L20/422',
  },
  {
    id: 10117,
    wbs_code: '03.02.06.02.01.0100',
    title: 'Door lintels',
  },
  {
    id: 10118,
    wbs_code: '03.02.06.02.01.0110',
    title: 'Weldmesh cladding to gate as specified, NBS Q40',
  },
  {
    id: 10119,
    wbs_code: '03.02.06.02.02',
    title: 'Revolving doors',
  },
  {
    id: 10120,
    wbs_code: '03.02.06.02.02.0010',
    title: 'Semi automatic revolving door DG001 as specified, NBS L20/495',
  },
  {
    id: 10121,
    wbs_code: '03.02.07',
    title: 'Internal Walls and Partitions',
  },
  {
    id: 10122,
    wbs_code: '03.02.07.01',
    title: 'Walls and Partitions',
  },
  {
    id: 10123,
    wbs_code: '03.02.07.01.01',
    title: 'Metal Stud Partition Type 4 as specified, NBS K10',
  },
  {
    id: 10124,
    wbs_code: '03.02.07.01.02',
    title: 'Metal Stud Partition Type 5 as specified, NBS K10',
  },
  {
    id: 10125,
    wbs_code: '03.02.07.01.03',
    title: 'Metal Stud Partition Type 6 as specified, NBS K10',
  },
  {
    id: 10126,
    wbs_code: '03.02.07.01.04',
    title: 'Concrete blockwork 7 N/mm2 around concrete areas as specified, NBS F10B',
  },
  {
    id: 10127,
    wbs_code: '03.02.07.01.05',
    title: 'Precast Concrete Wall 150mm as specified, NBS E60',
  },
  {
    id: 10128,
    wbs_code: '03.02.07.04',
    title: 'Cubicles Definition: Proprietary prefinished panels, assembled to form cubicles, complete with doors',
  },
  {
    id: 10129,
    wbs_code: '03.02.07.04.01',
    title: 'Toilet partition as specified, NBS K10A',
  },
  {
    id: 10130,
    wbs_code: '03.02.07.04.02',
    title: 'Opaque Glazed Panels as specified, NBS L40',
  },
  {
    id: 10131,
    wbs_code: '03.02.07.04.03',
    title: 'Semi Opaque Screen as specified, NBS',
  },
  {
    id: 10132,
    wbs_code: '03.02.08',
    title: 'Internal Doors',
  },
  {
    id: 10133,
    wbs_code: '03.02.08.01',
    title: 'Internal doors',
  },
  {
    id: 10134,
    wbs_code: '03.02.08.01.01',
    title: 'Internal timber door included ironmongery, as specified, NBS L20A',
  },
  {
    id: 10135,
    wbs_code: '03.02.08.01.02',
    title: 'Internal double door including ironmongery, as specified, NBS L20A',
  },
  {
    id: 10136,
    wbs_code: '03.02.08.01.03',
    title: 'Internal Security Airlocks Spec including ironmongery NBS L20',
  },
  {
    id: 10137,
    wbs_code: '03.03',
    title: '03 - Internal Finishes',
  },
  {
    id: 10138,
    wbs_code: '03.03.01',
    title: 'Wall finishes',
  },
  {
    id: 10139,
    wbs_code: '03.03.01.01',
    title: 'All rooms',
  },
  {
    id: 10140,
    wbs_code: '03.03.01.01.01',
    title: 'Wall Finish',
  },
  {
    id: 10141,
    wbs_code: '03.03.01.01.02',
    title: 'Skirting Finish',
  },
  {
    id: 10142,
    wbs_code: '03.03.02',
    title: 'Floor finishes',
  },
  {
    id: 10143,
    wbs_code: '03.03.02.01',
    title: 'All rooms',
  },
  {
    id: 10144,
    wbs_code: '03.03.02.01.01',
    title: 'Floor Finish',
  },
  {
    id: 10145,
    wbs_code: '03.03.03',
    title: 'Ceiling finishes',
  },
  {
    id: 10146,
    wbs_code: '03.03.03.01',
    title: 'All rooms',
  },
  {
    id: 10147,
    wbs_code: '03.03.03.01.01',
    title: 'Ceiling Finish',
  },
  {
    id: 10148,
    wbs_code: '03.04',
    title: '04 - Furniture, Fittings and Equipment (FFE)',
  },
  {
    id: 10149,
    wbs_code: '03.04.01',
    title: 'Fittings, furnishings and equipment',
  },
  {
    id: 10150,
    wbs_code: '03.04.01.01',
    title: 'All rooms',
  },
  {
    id: 10151,
    wbs_code: '03.04.01.01.01',
    title: 'FFE (Fittings, Furnishings and Equipment)',
  },
  {
    id: 10152,
    wbs_code: '03.05',
    title: '05 - Services',
  },
  {
    id: 10153,
    wbs_code: '03.05.01',
    title: 'Sanitary installations',
  },
  {
    id: 10154,
    wbs_code: '03.05.01.01',
    title: 'All rooms',
  },
  {
    id: 10155,
    wbs_code: '03.05.01.01.01',
    title: 'Sanitary Appliances',
  },
  {
    id: 10156,
    wbs_code: '03.05.02',
    title: 'Lift and Conveyor Installations',
  },
  {
    id: 10157,
    wbs_code: '03.05.02.01',
    title:
      'Electromechanical or electrohydraulic installations for the conveyance of persons, goods or equipment from one level to another in a vertical plane',
  },
  {
    id: 10158,
    wbs_code: '03.05.02.01.01',
    title: 'Passenger lift',
  },
]

export const CESMM4BS = [
  {
    id: 100,
    cesmm4_code: '01',
    title: 'BUILDING NAME',
  },
  {
    id: 101,
    cesmm4_code: '01.00',
    title: 'Facilitating Works',
  },
  {
    id: 102,
    cesmm4_code: '01.00.01',
    title: 'Toxic/hazardous/contaminated material treatment',
  },
  {
    id: 103,
    cesmm4_code: '01.00.01.01',
    title: 'Toxic or hazardous material removal',
  },
  {
    id: 104,
    cesmm4_code: '01.00.01.02',
    title: 'Contaminated land',
  },
  {
    id: 105,
    cesmm4_code: '01.00.01.03',
    title: 'Eradication of plant growth',
  },
  {
    id: 106,
    cesmm4_code: '01.00.02',
    title: 'Major demolition works',
  },
  {
    id: 107,
    cesmm4_code: '01.00.02.01',
    title: 'Demolition works',
  },
  {
    id: 108,
    cesmm4_code: '01.00.02.02',
    title: 'Soft strip works',
  },
  {
    id: 109,
    cesmm4_code: '01.00.03',
    title: 'Temporary support to adjacent structures',
  },
  {
    id: 110,
    cesmm4_code: '01.00.03.01',
    title: 'Temporary support to adjacent structures',
  },
  {
    id: 111,
    cesmm4_code: '01.00.04',
    title: 'Specialist groundworks',
  },
  {
    id: 112,
    cesmm4_code: '01.00.04.01',
    title: 'Site dewatering and pumping',
  },
  {
    id: 113,
    cesmm4_code: '01.00.04.02',
    title: 'Soil stabilisation measures',
  },
  {
    id: 114,
    cesmm4_code: '01.00.04.03',
    title: 'Ground gas venting measures',
  },
  {
    id: 115,
    cesmm4_code: '01.00.05',
    title: 'Temporary diversion works',
  },
  {
    id: 116,
    cesmm4_code: '01.00.05.01',
    title: 'Temporary diversion works',
  },
  {
    id: 117,
    cesmm4_code: '01.00.06',
    title: 'Extraordinary site investigation works',
  },
  {
    id: 118,
    cesmm4_code: '01.00.06.01',
    title: 'Archaeological investigation',
  },
  {
    id: 119,
    cesmm4_code: '01.00.06.02',
    title: 'Reptile/wildlife mitigation measures',
  },
  {
    id: 120,
    cesmm4_code: '01.00.06.03',
    title: 'Other extraordinary site investigation works',
  },
  {
    id: 121,
    cesmm4_code: '01.01',
    title: 'Substructure',
  },
  {
    id: 122,
    cesmm4_code: '01.01.01',
    title: 'Substructure',
  },
  {
    id: 123,
    cesmm4_code: '01.01.01.01',
    title: 'Standard foundations',
  },
  {
    id: 124,
    cesmm4_code: '01.01.01.02',
    title: 'Specialist foundations',
  },
  {
    id: 125,
    cesmm4_code: '01.01.01.03',
    title: 'Lowest floor construction',
  },
  {
    id: 126,
    cesmm4_code: '01.01.01.04',
    title: 'Basement excavation',
  },
  {
    id: 127,
    cesmm4_code: '01.01.01.05',
    title: 'Basement retaining walls',
  },
  {
    id: 128,
    cesmm4_code: '01.02',
    title: 'Superstructure',
  },
  {
    id: 129,
    cesmm4_code: '01.02.01',
    title: 'Frame',
  },
  {
    id: 130,
    cesmm4_code: '01.02.01.01',
    title: 'Steel frames',
  },
  {
    id: 131,
    cesmm4_code: '01.02.01.02',
    title: 'Space frames/decks',
  },
  {
    id: 132,
    cesmm4_code: '01.02.01.03',
    title: 'Concrete casings to steel frames',
  },
  {
    id: 133,
    cesmm4_code: '01.02.01.04',
    title: 'Concrete frames',
  },
  {
    id: 134,
    cesmm4_code: '01.02.01.05',
    title: 'Timber frames',
  },
  {
    id: 135,
    cesmm4_code: '01.02.01.06',
    title: 'Specialist frames',
  },
  {
    id: 136,
    cesmm4_code: '01.02.02',
    title: 'Upper floors',
  },
  {
    id: 137,
    cesmm4_code: '01.02.02.01',
    title: 'Floors',
  },
  {
    id: 138,
    cesmm4_code: '01.02.02.02',
    title: 'Balconies',
  },
  {
    id: 139,
    cesmm4_code: '01.02.02.03',
    title: 'Drainage to balconies',
  },
  {
    id: 140,
    cesmm4_code: '01.02.03',
    title: 'Roofs',
  },
  {
    id: 141,
    cesmm4_code: '01.02.03.01',
    title: 'Roof structure',
  },
  {
    id: 142,
    cesmm4_code: '01.02.03.02',
    title: 'Roof coverings',
  },
  {
    id: 143,
    cesmm4_code: '01.02.03.03',
    title: 'Specialist roof systems',
  },
  {
    id: 144,
    cesmm4_code: '01.02.03.04',
    title: 'Roof drainage',
  },
  {
    id: 145,
    cesmm4_code: '01.02.03.05',
    title: 'Rooflights, skylights and openings',
  },
  {
    id: 146,
    cesmm4_code: '01.02.03.06',
    title: 'Roof features',
  },
  {
    id: 147,
    cesmm4_code: '01.02.04',
    title: 'Stairs and ramps',
  },
  {
    id: 148,
    cesmm4_code: '01.02.04.01',
    title: 'Stair/ramp structures',
  },
  {
    id: 149,
    cesmm4_code: '01.02.04.02',
    title: 'Stair/ramp finishes',
  },
  {
    id: 150,
    cesmm4_code: '01.02.04.03',
    title: 'Stair/ramp balustrades and handrails',
  },
  {
    id: 151,
    cesmm4_code: '01.02.04.04',
    title: 'Ladders/chutes/slides',
  },
  {
    id: 152,
    cesmm4_code: '01.02.05',
    title: 'External walls',
  },
  {
    id: 153,
    cesmm4_code: '01.02.05.01',
    title: 'External enclosing walls above ground level',
  },
  {
    id: 154,
    cesmm4_code: '01.02.05.02',
    title: 'External enclosing walls below ground level',
  },
  {
    id: 155,
    cesmm4_code: '01.02.05.03',
    title: 'Solar/rain screening',
  },
  {
    id: 156,
    cesmm4_code: '01.02.05.04',
    title: 'External soffits',
  },
  {
    id: 157,
    cesmm4_code: '01.02.05.05',
    title: 'Subsidiary walls, balustrades and proprietary balconies',
  },
  {
    id: 158,
    cesmm4_code: '01.02.05.06',
    title: 'Façade',
  },
  {
    id: 159,
    cesmm4_code: '01.02.06',
    title: 'Windows and external doors',
  },
  {
    id: 160,
    cesmm4_code: '01.02.06.01',
    title: 'External windows',
  },
  {
    id: 161,
    cesmm4_code: '01.02.06.02',
    title: 'External doors',
  },
  {
    id: 162,
    cesmm4_code: '01.02.07',
    title: 'Internal walls and partitions',
  },
  {
    id: 163,
    cesmm4_code: '01.02.07.01',
    title: 'Walls and partitions',
  },
  {
    id: 164,
    cesmm4_code: '01.02.07.02',
    title: 'Balustrades and handrails',
  },
  {
    id: 165,
    cesmm4_code: '01.02.07.03',
    title: 'Moveable room dividers',
  },
  {
    id: 166,
    cesmm4_code: '01.02.07.04',
    title: 'Cubicles',
  },
  {
    id: 167,
    cesmm4_code: '01.02.08',
    title: 'Internal doors',
  },
  {
    id: 168,
    cesmm4_code: '01.02.08.01',
    title: 'Internal doors',
  },
  {
    id: 169,
    cesmm4_code: '01.03',
    title: 'Internal finishes',
  },
  {
    id: 170,
    cesmm4_code: '01.03.01',
    title: 'Wall finishes',
  },
  {
    id: 171,
    cesmm4_code: '01.03.01.01',
    title: 'Wall finishes',
  },
  {
    id: 172,
    cesmm4_code: '01.03.02',
    title: 'Floor finishes',
  },
  {
    id: 173,
    cesmm4_code: '01.03.02.01',
    title: 'Finishes to floors',
  },
  {
    id: 174,
    cesmm4_code: '01.03.02.02',
    title: 'Raised access floors',
  },
  {
    id: 175,
    cesmm4_code: '01.03.03',
    title: 'Ceiling finishes',
  },
  {
    id: 176,
    cesmm4_code: '01.03.03.01',
    title: 'Finishes to ceilings',
  },
  {
    id: 177,
    cesmm4_code: '01.03.03.02',
    title: 'False ceilings',
  },
  {
    id: 178,
    cesmm4_code: '01.03.03.03',
    title: 'Demountable suspended ceilings',
  },
  {
    id: 179,
    cesmm4_code: '01.04',
    title: 'Fittings, furnishings and equipment',
  },
  {
    id: 180,
    cesmm4_code: '01.04.01',
    title: 'Fittings, furnishings and equipment',
  },
  {
    id: 181,
    cesmm4_code: '01.04.01.01',
    title: 'General fittings, furnishings and equipment',
  },
  {
    id: 182,
    cesmm4_code: '01.04.01.02',
    title: 'Domestic kitchen fittings and equipment',
  },
  {
    id: 183,
    cesmm4_code: '01.04.01.03',
    title: 'Special purpose fittings, furnishings and equipment',
  },
  {
    id: 184,
    cesmm4_code: '01.04.01.04',
    title: 'Signs/notices',
  },
  {
    id: 185,
    cesmm4_code: '01.04.01.05',
    title: 'Works of art',
  },
  {
    id: 186,
    cesmm4_code: '01.04.01.06',
    title: 'Non-mechanical and non-electrical equipment',
  },
  {
    id: 187,
    cesmm4_code: '01.04.01.07',
    title: 'Internal planting',
  },
  {
    id: 188,
    cesmm4_code: '01.04.01.08',
    title: 'Bird and vermin control',
  },
  {
    id: 189,
    cesmm4_code: '01.05',
    title: 'Services',
  },
  {
    id: 190,
    cesmm4_code: '01.05.01',
    title: 'Sanitary installations',
  },
  {
    id: 191,
    cesmm4_code: '01.05.01.01',
    title: 'Sanitary appliances',
  },
  {
    id: 192,
    cesmm4_code: '01.05.01.02',
    title: 'Sanitary ancillaries',
  },
  {
    id: 193,
    cesmm4_code: '01.05.02',
    title: 'Services equipment',
  },
  {
    id: 194,
    cesmm4_code: '01.05.02.01',
    title: 'Services equipment',
  },
  {
    id: 195,
    cesmm4_code: '01.05.03',
    title: 'Disposal installations',
  },
  {
    id: 196,
    cesmm4_code: '01.05.03.01',
    title: 'Foul drainage above ground',
  },
  {
    id: 197,
    cesmm4_code: '01.05.03.02',
    title: 'Chemical, toxic and industrial liquid waste drainage',
  },
  {
    id: 198,
    cesmm4_code: '01.05.03.03',
    title: 'Refuse disposal',
  },
  {
    id: 199,
    cesmm4_code: '01.05.04',
    title: 'Water installations',
  },
  {
    id: 200,
    cesmm4_code: '01.05.04.01',
    title: 'Mains water supply',
  },
  {
    id: 201,
    cesmm4_code: '01.05.04.02',
    title: 'Cold water distribution',
  },
  {
    id: 202,
    cesmm4_code: '01.05.04.03',
    title: 'Hot water distribution',
  },
  {
    id: 203,
    cesmm4_code: '01.05.04.04',
    title: 'Local hot water distribution',
  },
  {
    id: 204,
    cesmm4_code: '01.05.04.05',
    title: 'Steam and condensate distribution',
  },
  {
    id: 205,
    cesmm4_code: '01.05.05',
    title: 'Heat source',
  },
  {
    id: 206,
    cesmm4_code: '01.05.05.01',
    title: 'Heat source',
  },
  {
    id: 207,
    cesmm4_code: '01.05.06',
    title: 'Space heating and air conditioning',
  },
  {
    id: 208,
    cesmm4_code: '01.05.06.01',
    title: 'Central heating',
  },
  {
    id: 209,
    cesmm4_code: '01.05.06.02',
    title: 'Local heating',
  },
  {
    id: 210,
    cesmm4_code: '01.05.06.03',
    title: 'Central cooling',
  },
  {
    id: 211,
    cesmm4_code: '01.05.06.04',
    title: 'Local cooling',
  },
  {
    id: 212,
    cesmm4_code: '01.05.06.05',
    title: 'Central heating and cooling',
  },
  {
    id: 213,
    cesmm4_code: '01.05.06.06',
    title: 'Local heating and cooling',
  },
  {
    id: 214,
    cesmm4_code: '01.05.06.07',
    title: 'Central air conditioning',
  },
  {
    id: 215,
    cesmm4_code: '01.05.06.08',
    title: 'Local air conditioning',
  },
  {
    id: 216,
    cesmm4_code: '01.05.07',
    title: 'Ventilation',
  },
  {
    id: 217,
    cesmm4_code: '01.05.07.01',
    title: 'Central ventilation',
  },
  {
    id: 218,
    cesmm4_code: '01.05.07.02',
    title: 'Local and special ventilation',
  },
  {
    id: 219,
    cesmm4_code: '01.05.07.03',
    title: 'Smoke extract/control',
  },
  {
    id: 220,
    cesmm4_code: '01.05.08',
    title: 'Electrical installations',
  },
  {
    id: 221,
    cesmm4_code: '01.05.08.01',
    title: 'Electrical mains and sub-mains distribution',
  },
  {
    id: 222,
    cesmm4_code: '01.05.08.02',
    title: 'Power installations',
  },
  {
    id: 223,
    cesmm4_code: '01.05.08.03',
    title: 'Lighting installations',
  },
  {
    id: 224,
    cesmm4_code: '01.05.08.04',
    title: 'Specialist lighting installations',
  },
  {
    id: 225,
    cesmm4_code: '01.05.08.05',
    title: 'Local electricity generation systems',
  },
  {
    id: 226,
    cesmm4_code: '01.05.08.06',
    title: 'Earthing and bonding systems',
  },
  {
    id: 227,
    cesmm4_code: '01.05.09',
    title: 'Fuel installations',
  },
  {
    id: 228,
    cesmm4_code: '01.05.09.01',
    title: 'Fuel storage',
  },
  {
    id: 229,
    cesmm4_code: '01.05.09.02',
    title: 'Fuel distribution systems',
  },
  {
    id: 230,
    cesmm4_code: '01.05.10',
    title: 'Lift and conveyor installations',
  },
  {
    id: 231,
    cesmm4_code: '01.05.10.01',
    title: 'Lifts and enclosed hoists',
  },
  {
    id: 232,
    cesmm4_code: '01.05.10.02',
    title: 'Escalators',
  },
  {
    id: 233,
    cesmm4_code: '01.05.10.03',
    title: 'Moving pavements',
  },
  {
    id: 234,
    cesmm4_code: '01.05.10.04',
    title: 'Powered stairlifts',
  },
  {
    id: 235,
    cesmm4_code: '01.05.10.05',
    title: 'Conveyors',
  },
  {
    id: 236,
    cesmm4_code: '01.05.10.06',
    title: 'Dock levellers and scissor lifts',
  },
  {
    id: 237,
    cesmm4_code: '01.05.10.07',
    title: 'Cranes and unenclosed hoists',
  },
  {
    id: 238,
    cesmm4_code: '01.05.10.08',
    title: 'Car lifts, car stacking, turntables and the like',
  },
  {
    id: 239,
    cesmm4_code: '01.05.10.09',
    title: 'Document handling systems',
  },
  {
    id: 240,
    cesmm4_code: '01.05.10.10',
    title: 'Other lift and conveyor installations',
  },
  {
    id: 241,
    cesmm4_code: '01.05.11',
    title: 'Fire and lighting protection',
  },
  {
    id: 242,
    cesmm4_code: '01.05.11.01',
    title: 'Fire fighting systems',
  },
  {
    id: 243,
    cesmm4_code: '01.05.11.02',
    title: 'Fire suppression systems',
  },
  {
    id: 244,
    cesmm4_code: '01.05.11.03',
    title: 'Lighting protection',
  },
  {
    id: 245,
    cesmm4_code: '01.05.12',
    title: 'Communication, security and control systems',
  },
  {
    id: 246,
    cesmm4_code: '01.05.12.01',
    title: 'Communication systems',
  },
  {
    id: 247,
    cesmm4_code: '01.05.12.02',
    title: 'Security systems',
  },
  {
    id: 248,
    cesmm4_code: '01.05.12.03',
    title: 'Central control/building management systems',
  },
  {
    id: 249,
    cesmm4_code: '01.05.13',
    title: 'Specialist installations',
  },
  {
    id: 250,
    cesmm4_code: '01.05.13.01',
    title: 'Specialist piped supply installations',
  },
  {
    id: 251,
    cesmm4_code: '01.05.13.02',
    title: 'Specialist refrigeration systems',
  },
  {
    id: 252,
    cesmm4_code: '01.05.13.03',
    title: 'Specialist mechanical instalations',
  },
  {
    id: 253,
    cesmm4_code: '01.05.13.04',
    title: 'Specialist electrical/ electronic installations',
  },
  {
    id: 254,
    cesmm4_code: '01.05.13.05',
    title: 'Water features',
  },
  {
    id: 255,
    cesmm4_code: '01.05.14',
    title: "Builder's work in connection with services",
  },
  {
    id: 256,
    cesmm4_code: '01.05.14.01',
    title: "Builder's work in connection with services",
  },
  {
    id: 257,
    cesmm4_code: '01.06',
    title: 'Prefabricated buildings and building units',
  },
  {
    id: 258,
    cesmm4_code: '01.06.01',
    title: 'Prefabricated buildings and building units',
  },
  {
    id: 259,
    cesmm4_code: '01.06.01.01',
    title: 'Complete buildings',
  },
  {
    id: 260,
    cesmm4_code: '01.06.01.02',
    title: 'Building units',
  },
  {
    id: 261,
    cesmm4_code: '01.06.01.03',
    title: 'Pods',
  },
  {
    id: 262,
    cesmm4_code: '01.07',
    title: 'Works to existing buildings',
  },
  {
    id: 263,
    cesmm4_code: '01.07.01',
    title: 'Minor demolition works and alteration works',
  },
  {
    id: 264,
    cesmm4_code: '01.07.01.01',
    title: 'Minor demolition works and alteration works',
  },
  {
    id: 265,
    cesmm4_code: '01.07.02',
    title: 'Repairs to existing services',
  },
  {
    id: 266,
    cesmm4_code: '01.07.02.01',
    title: 'Repairs to existing services',
  },
  {
    id: 267,
    cesmm4_code: '01.07.03',
    title: 'Damp-proof courses/ fungus and beetle eradication',
  },
  {
    id: 268,
    cesmm4_code: '01.07.03.01',
    title: 'Damp-proof courses',
  },
  {
    id: 269,
    cesmm4_code: '01.07.03.02',
    title: 'Fungus/ beetle eradication',
  },
  {
    id: 270,
    cesmm4_code: '01.07.04',
    title: 'Façade retention',
  },
  {
    id: 271,
    cesmm4_code: '01.07.04.01',
    title: 'Façade retention',
  },
  {
    id: 272,
    cesmm4_code: '01.07.05',
    title: 'Cleaning existing surfaces',
  },
  {
    id: 273,
    cesmm4_code: '01.07.05.01',
    title: 'Cleaning',
  },
  {
    id: 274,
    cesmm4_code: '01.07.05.02',
    title: 'Protective coatings',
  },
  {
    id: 275,
    cesmm4_code: '01.07.06',
    title: 'Renovation works',
  },
  {
    id: 276,
    cesmm4_code: '01.07.06.01',
    title: 'Masonry repairs',
  },
  {
    id: 277,
    cesmm4_code: '01.07.06.02',
    title: 'Concrete repairs',
  },
  {
    id: 278,
    cesmm4_code: '01.07.06.03',
    title: 'Metal repairs',
  },
  {
    id: 279,
    cesmm4_code: '01.07.06.04',
    title: 'Timber repairs',
  },
  {
    id: 280,
    cesmm4_code: '01.07.06.05',
    title: 'Plastic repairs',
  },
  {
    id: 281,
    cesmm4_code: '01.08',
    title: 'External works',
  },
  {
    id: 282,
    cesmm4_code: '01.08.01',
    title: 'Site preperation works',
  },
  {
    id: 283,
    cesmm4_code: '01.08.02',
    title: 'Roads, paths, pavings and surfacings',
  },
  {
    id: 284,
    cesmm4_code: '01.08.03',
    title: 'Soft landscaping, planting and irrigation systems',
  },
  {
    id: 285,
    cesmm4_code: '01.08.04',
    title: 'Fencing, railings and  walls',
  },
  {
    id: 286,
    cesmm4_code: '01.08.05',
    title: 'External fixtures',
  },
  {
    id: 287,
    cesmm4_code: '01.08.06',
    title: 'External drainage',
  },
  {
    id: 288,
    cesmm4_code: '01.08.07',
    title: 'External services',
  },
  {
    id: 289,
    cesmm4_code: '01.08.08',
    title: 'Minor building works and ancillary buildings',
  },
  {
    id: 290,
    cesmm4_code: '01.09',
    title: 'Main contractor’s preliminaries',
  },
  {
    id: 291,
    cesmm4_code: '01.09.01',
    title: 'Employer’s requirements',
  },
  {
    id: 292,
    cesmm4_code: '01.09.02',
    title: 'Main contractor’s cost items',
  },
  {
    id: 293,
    cesmm4_code: '01.10',
    title: 'Main contractor’s overheads and profit',
  },
  {
    id: 294,
    cesmm4_code: '01.10.01',
    title: 'Main contractor’s overheads',
  },
  {
    id: 295,
    cesmm4_code: '01.11',
    title: 'Project/ design team fees',
  },
  {
    id: 296,
    cesmm4_code: '01.11.01',
    title: 'Consultants’ fees',
  },
  {
    id: 297,
    cesmm4_code: '01.11.02',
    title: 'Main contractor’s pre-construction fees',
  },
  {
    id: 298,
    cesmm4_code: '01.11.03',
    title: 'Main contractor’s design fees',
  },
  {
    id: 299,
    cesmm4_code: '01.12',
    title: 'Other development/ project costs',
  },
  {
    id: 300,
    cesmm4_code: '01.12.01',
    title: 'Other development/ project costs',
  },
  {
    id: 301,
    cesmm4_code: '01.13',
    title: 'Risks',
  },
  {
    id: 302,
    cesmm4_code: '01.13.01',
    title: 'Design development risks',
  },
  {
    id: 303,
    cesmm4_code: '01.13.02',
    title: 'Construction risks',
  },
  {
    id: 304,
    cesmm4_code: '01.13.03',
    title: 'Employer change risks',
  },
  {
    id: 305,
    cesmm4_code: '01.13.04',
    title: 'Employer other risks',
  },
  {
    id: 306,
    cesmm4_code: '01.14',
    title: 'Inflation',
  },
  {
    id: 307,
    cesmm4_code: '01.14.01',
    title: 'Tender inflation',
  },
  {
    id: 308,
    cesmm4_code: '01.14.02',
    title: 'Construction inflation',
  },
]
