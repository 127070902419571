import { OpenWindowsState } from './OpenWindows.state'
import { OpenWindowsAction, SET_OPEN_WINDOW_STATE } from './OpenWindows.actions'

const initialState: OpenWindowsState = {
  windows: new Map<string, boolean>(),
}

export function openWindowsReducer(
  state: OpenWindowsState = initialState,
  action: OpenWindowsAction
): OpenWindowsState {
  if (action.type === SET_OPEN_WINDOW_STATE) {
    const { windowId, value } = action
    const newWindowState = new Map(state.windows)
    newWindowState.set(windowId, value)
    return Object.assign({}, state, {
      windows: newWindowState,
    })
  } else {
    return state
  }
}
