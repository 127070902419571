import React, { Key, useState } from 'react'
import { Button, Divider, Dropdown, Menu, Space, Modal, Tooltip } from 'antd'
import { MoreOutlined, PartitionOutlined, UploadOutlined } from '@ant-design/icons/lib'
import { RootState } from '../../../../../context/reducer'
import { useDispatch, useSelector } from 'react-redux'
import {
  setVisibleColumns,
  setLockers,
  addLayout,
} from '../../../../../context/columns-visibility/ColumnsVisibility.actions'
import { portfolioTableId } from '../PortfolioTable'
import AddProjectForm from '../../../add-project/AddProjectForm'
import { ColumnsLayout } from '../../../../../model/ColumnLayouts'
import ColumnsLayoutManager from '../../../../basic/table/columns-layout-manager/ColumnsLayoutManager'

interface PortfolioTableHeaderProps {
  columns: any[]
}

const selectVisibleColumnsKeys = (state: RootState): Key[] =>
  state.columnsVisibility.visibleColumnsKeys.get(portfolioTableId) || []

const selectLockerKeys = (state: RootState): Key[] =>
  state.columnsVisibility.lockerStateKeys.get(portfolioTableId) || []

function PortfolioTableHeader({ columns }: PortfolioTableHeaderProps): JSX.Element {
  const addProjectMenu = (
    <Menu>
      <Menu.Item key="1">Add project</Menu.Item>
      <Menu.Item key="2">Import Project</Menu.Item>
    </Menu>
  )
  const visibleColumns = useSelector(selectVisibleColumnsKeys)
  const lockers = useSelector(selectLockerKeys)

  const [modalVisible, setModalVisibility] = useState(false)
  const showModal = () => setModalVisibility(true)
  const hideModal = () => setModalVisibility(false)

  const dispatch = useDispatch()
  const applySelection = (checkedColumns: Key[]) => dispatch(setVisibleColumns(portfolioTableId, checkedColumns))
  const applyLocker = (checkedLocker: Key[]) => dispatch(setLockers(portfolioTableId, checkedLocker))
  const onApply = (checkedColumns: Key[], checkedLocker: Key[]) => {
    applySelection(checkedColumns)
    applyLocker(checkedLocker)
  }
  const saveLayout = (layout: ColumnsLayout) => dispatch(addLayout(layout, portfolioTableId))

  const permanentlyLocked = columns.filter((item) => item.hasOwnProperty('fixed'))

  return (
    <div style={{ display: 'flex', padding: '8px' }}>
      <Space style={{ flex: 10 }}>
        <ColumnsLayoutManager
          columns={columns}
          visibleColumns={visibleColumns}
          onApply={onApply}
          lockers={lockers}
          permanentlyLocked={permanentlyLocked}
          onLayoutsSaved={saveLayout}
          tableId={portfolioTableId}
        />
        <Divider type={'vertical'} />
        <Tooltip title="Upload project">
          <Button type="text" shape="circle" icon={<UploadOutlined />} />
        </Tooltip>
      </Space>
      <Space size={0} style={{ flex: 1 }}>
        <Dropdown.Button
          onClick={showModal}
          overlay={addProjectMenu}
          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          type={'primary'}
          icon={<MoreOutlined />}
        >
          Add project
        </Dropdown.Button>
      </Space>
      <Modal width={'75%'} visible={modalVisible} destroyOnClose={false} onCancel={hideModal} footer={null}>
        <AddProjectForm closeModal={hideModal} />
      </Modal>
    </div>
  )
}

export default PortfolioTableHeader
