export const setsIntersection = <T>(set1: Set<T>, set2: Set<T>): Set<T> =>
  new Set([...set1].filter((id) => set2.has(id)))

export const setsDifference = <T>(set1: Set<T>, set2: Set<T>): Set<T> =>
  new Set([...set1].filter((id) => !set2.has(id)))

const basicSetsUnion = <T>(set1: Set<T>, set2: Set<T>): Set<T> => new Set([...set1, ...set2])

export const setsUnion = <T>(...sets: Set<T>[]): Set<T> =>
  sets.reduce((previousValue, currentValue) => basicSetsUnion(previousValue, currentValue), new Set())
