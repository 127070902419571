import { EditOutlined, EyeOutlined, StopOutlined } from '@ant-design/icons'
import { DatePicker, Input, InputNumber, Select } from 'antd'
import React from 'react'
import countries from 'typed-countries'
import { compareByKey } from '../../../../utils/comparators/ArrayComparators'
import { Currencies } from '../../../../utils/currency/Currencies'
import { Timezones } from '../../../../utils/datetime/Timezones'

const { Option } = Select
const { RangePicker } = DatePicker

const rounding = ['Down', 'Half Down', 'Half Up', 'Up']
const viewer = ['Low', 'Medium', 'High']
const metric = ['Metric', 'Imperial']
const lenMetrics = ['mm', 'cm', 'm']
const areaMetrics = ['m2', 'cm2']
const volumeMetrics = ['m3', 'liter']
const weightMetrics = ['kg', 't']

const administrationInputStyle = {
  width: 180,
}

export const DropdownMaker = ({ placeholder }: { placeholder: string }) => {
  return (
    <Select style={administrationInputStyle} placeholder={'Select'}>
      <Select.Option key={placeholder} value={placeholder}>
        {placeholder}
      </Select.Option>
    </Select>
  )
}

export const BasicDropdown = ({
  defaultVal,
  placeholder,
  values,
}: {
  defaultVal?: string
  placeholder: string
  values: string[]
}) => {
  return (
    <Select style={administrationInputStyle} defaultValue={defaultVal} placeholder={'Select'}>
      {values.map((c) => (
        <Select.Option key={c} value={c}>
          {c}
        </Select.Option>
      ))}
    </Select>
  )
}

export const CurrencyDropdown = ({ defaultVal }: { defaultVal: string }) => {
  return (
    <Select style={administrationInputStyle} defaultValue={defaultVal} placeholder={'Select Main Currency'}>
      {Currencies.map((curr) => (
        <Select.Option key={curr.code} value={curr.code}>
          {`${curr.code} (${curr.name})`}
        </Select.Option>
      ))}
    </Select>
  )
}

export const CountryDropdown = () => {
  return (
    <Select style={administrationInputStyle} defaultValue={'United Kingdom'} placeholder={'Select Main Currency'}>
      {countries.map((c) => (
        <Select.Option key={c.name} value={c.name}>
          {`${c.name}`}
        </Select.Option>
      ))}
    </Select>
  )
}

export const TimezoneDropdown = () => {
  return (
    <Select style={administrationInputStyle} defaultValue={'Europe/London (GMT)(UTC)'} placeholder={'Select timezone'}>
      {Timezones.sort(compareByKey('group', true)).map((tz) => (
        <Select.Option key={tz.label} value={tz.label}>
          {`${tz.label} (${tz.group})`}
        </Select.Option>
      ))}
    </Select>
  )
}

export const ReviewsTableElement = ({ name, element }: { name: string; element: React.ReactNode }) => {
  return (
    <tr>
      <td style={{ paddingRight: '10px', paddingBottom: '5px' }}>{name}</td>
      <td style={{ textAlign: 'left', paddingBottom: '5px' }}>{element}</td>
    </tr>
  )
}

export const RenderCompanyInformation = () => {
  return (
    <>
      <ReviewsTableElement name={'General Contractor: '} element={<Input defaultValue="Skanska" />} />
      <ReviewsTableElement
        name={'Company Logo: '}
        element={<Input defaultValue="https://cdn.worldvectorlogo.com/logos/skanska.svg" />}
      />
      <ReviewsTableElement name={'Country'} element={<CountryDropdown />} />
      <ReviewsTableElement name={'Headquater postcode: '} element={'NW2 6XB'} />
      <ReviewsTableElement name={'Time Zone'} element={<TimezoneDropdown />} />
    </>
  )
}
export const RenderCurrencyInformation = () => {
  return (
    <>
      <ReviewsTableElement
        name={'Primary currency: '}
        element={<CurrencyDropdown defaultVal={'GBP (British Pound Sterling)'} />}
      />
      <ReviewsTableElement name={'Secondary currency: '} element={<CurrencyDropdown defaultVal={'EUR (Euro)'} />} />
      <ReviewsTableElement
        name={'Exchange rate: '}
        element={<InputNumber style={administrationInputStyle} defaultValue={1.4} />}
      />
    </>
  )
}
export const RenderRounding = () => {
  return (
    <>
      <ReviewsTableElement
        name={'Rounding precision: '}
        element={<InputNumber style={administrationInputStyle} defaultValue={2} />}
      />
      <ReviewsTableElement
        name={'Rounding mode: '}
        element={<BasicDropdown defaultVal={'Down'} placeholder={'down'} values={rounding} />}
      />
      <ReviewsTableElement
        name={'Decimal display: '}
        element={<InputNumber style={administrationInputStyle} defaultValue={2} />}
      />
    </>
  )
}
export const RenderViewer = () => {
  return (
    <>
      <ReviewsTableElement
        name={'BIM rendering: '}
        element={<BasicDropdown defaultVal={'Low'} placeholder={'select'} values={viewer} />}
      />
      <ReviewsTableElement
        name={'2D rendering: '}
        element={<BasicDropdown defaultVal={'Low'} placeholder={'select'} values={viewer} />}
      />
    </>
  )
}

export const RenderUnits = () => {
  return (
    <>
      <ReviewsTableElement
        name={'System: '}
        element={<BasicDropdown defaultVal={'Metric'} placeholder={'select'} values={metric} />}
      />
      <ReviewsTableElement
        name={'Lenght: '}
        element={<BasicDropdown defaultVal={'mm'} placeholder={'select'} values={lenMetrics} />}
      />
      <ReviewsTableElement
        name={'Area: '}
        element={<BasicDropdown defaultVal={'m2'} placeholder={'select'} values={areaMetrics} />}
      />
      <ReviewsTableElement
        name={'Volume: '}
        element={<BasicDropdown defaultVal={'m3'} placeholder={'select'} values={volumeMetrics} />}
      />
      <ReviewsTableElement
        name={'Weight: '}
        element={<BasicDropdown defaultVal={'kg'} placeholder={'select'} values={weightMetrics} />}
      />
    </>
  )
}

export const RenderPermissions = () => {
  return (
    <Select defaultValue="read" style={{ width: 120 }}>
      <Option value="read">
        <EyeOutlined style={{ color: '#FAAD14' }} /> Read
      </Option>

      <Option value="edit">
        <EditOutlined style={{ color: '#5B8C00' }} /> Edit
      </Option>

      <Option value="access">
        <StopOutlined style={{ color: '#CF1322' }} /> No access
      </Option>
    </Select>
  )
}

export const RenderGeneralInformation = () => {
  return (
    <>
      <ReviewsTableElement name={'Name: '} element={<Input defaultValue={'Trafalgar Sq.'} />} />
      <ReviewsTableElement name={'Code: '} element={<Input defaultValue={'OF123456'} />} />
      <ReviewsTableElement name={'Date: '} element={<RangePicker />} />
      <ReviewsTableElement name={'Type: '} element={<DropdownMaker placeholder={'Building'} />} />
      <ReviewsTableElement name={'Sub-type: '} element={<DropdownMaker placeholder={'Office'} />} />
      <ReviewsTableElement name={'GIFA: '} element={<Input defaultValue={'10 000 M2'} />} />
    </>
  )
}
