import React, { FunctionComponent, useEffect } from 'react'
import { Form } from 'antd'
import { useIsMount } from '../../../hooks/useIsMount'

export interface FormPartProps {
  nextClicked: any // just reacting to changes
  proceedToNext: (formPart: any) => void
  prevClicked: any // just reacting to changes
  proceedToPrev: () => void
}

const FormPart: FunctionComponent<FormPartProps> = ({
  nextClicked,
  prevClicked,
  proceedToPrev,
  proceedToNext,
  children,
}) => {
  const [form] = Form.useForm()

  const isMount = useIsMount()

  useEffect(() => {
    if (!isMount) {
      form.validateFields().then(
        () => {
          form.submit()
          proceedToNext(form.getFieldsValue() as any)
        },
        () => {
          // purposely silent when form invalid
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextClicked])

  useEffect(() => {
    if (!isMount) {
      proceedToPrev()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevClicked])

  return (
    <div>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { form })
        }
        return child
      })}
    </div>
  )
}

export default FormPart
