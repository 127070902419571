import { Button, Col, Divider, Empty, Form, List, Row, Space } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { LinkOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons/lib'
import React, { FunctionComponent, Key } from 'react'

interface BoQDetailsDescriptionPane {
  selectedIds: Key[]
  data: any[]
  height: number
}

const links = ['http://www.buildersengineer.info/2015/10/basement-excavations-open.html']
const attachments = ['Detailed_specification.pdf', 'Method_statement.pdf']

const BoQDetailsDescriptionPane: FunctionComponent<BoQDetailsDescriptionPane> = ({ selectedIds, data, height }) => {
  if (selectedIds.length === 1) {
    // TODO: Change when data uploaded
    const item = data.find((d) => d.id === selectedIds[0])
    return (
      <div style={{ padding: '0 8px 0 16px', height, width: '100%', overflow: 'scroll' }}>
        <h4>{item?.title}</h4>
        <Divider style={{ marginTop: '8px' }} />
        <Form>
          <Row>
            <Col span={8}>
              <Form.Item label={'Quantity'}>
                {item?.quantity} {item?.unit || ''}
              </Form.Item>
              <Form.Item label={'Base Total'}>£ {item?.base_total} </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item label={'Description'}>
                <TextArea
                  autoSize={{ minRows: 4, maxRows: 4 }}
                  placeholder={'Enter Description'}
                  value={'Open excavation with no temporary support. Excavation sides slope 1:1 (45 deg). '}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item label={'Links'} style={{ padding: 8 }}>
                <Button style={{ marginTop: 4 }} ghost={true} type={'primary'} size={'small'}>
                  Add link
                </Button>
                <List>
                  {links.map((l) => (
                    <List.Item key={l}>
                      <Space>
                        <LinkOutlined />
                        <a href={l}>{l}</a>
                      </Space>
                    </List.Item>
                  ))}
                </List>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={'Attachements'} style={{ padding: 8 }}>
                <Button style={{ marginTop: 4 }} ghost={true} type={'primary'} size={'small'} icon={<UploadOutlined />}>
                  Upload
                </Button>
                <List>
                  {attachments.map((l) => (
                    <List.Item key={l}>
                      <Space>
                        <PaperClipOutlined />
                        <a href={l}>{l}</a>
                      </Space>
                    </List.Item>
                  ))}
                </List>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    )
  } else {
    if (selectedIds.length === 1) return <Empty description={'No data for this cost item'} />
    return <Empty description={selectedIds.length > 1 ? 'Select only one item' : 'Select an item'} />
  }
}

export default BoQDetailsDescriptionPane
