import { ExtendedColumnType } from '../components/basic/table/sterling-table/SterlingTable'

export const enchanceForFiltering = (
  columns: ExtendedColumnType[],
  getColumnSearchProps: (dataIndex: string) => any
) => {
  return columns.map((val) => {
    return { ...val, ...getColumnSearchProps((val.dataIndex as string) || '') }
  })
}
