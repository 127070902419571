import React, { useState } from 'react'
import AdministrationTemplate from './template/AdministrationTemplate'
import Search from 'antd/lib/input/Search'
import Select from 'antd/lib/select'
import Collapse from 'antd/lib/collapse/Collapse'
import { administrationColumns } from './Administration.columns'
import { administrationData } from './Administration.data'
import { Button, Table } from 'antd'
import Users from '../../../data/Users.data'
import { portfolioData } from '../../../data/Portfolio.data'
import { PlusSquareOutlined } from '@ant-design/icons'
import AdministrationModal from './helpers/AdministrationModal'
import './Administration.scss'
import BreadcrumbPanel from '../../basic/breadcrumb/BreadcrumbPanel'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../context/reducer'
import { setOpenWindowState } from '../../../context/open-windows/OpenWindows.actions'
import ReplacementPane from '../../basic/new-window/ReplacementPane'
import NewWindow from '../../basic/new-window/NewWindow'
import { newWindowHeight, newWindowWidth } from '../../../constants/sizes'

const Panel = Collapse.Panel
const { Option } = Select

function PermissionManagement(): JSX.Element {
  return (
    <AdministrationTemplate selectedKey={'permission-management'}>
      <PermissionManagementPart />
    </AdministrationTemplate>
  )
}

const RenderUpperPanel = ({ handleSearch }: { handleSearch: any }) => {
  return (
    <>
      <div key={''} className="title" style={{ fontSize: '14px', fontWeight: 'bolder' }}>
        Permission Management
      </div>
      <div className="dropdown-wrapper" style={{ display: 'flex', marginTop: '35px', marginBottom: '25px' }}>
        <Search
          key={''}
          placeholder="Type a name or email address"
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: 350, marginRight: '48px' }}
        />
        <div key={''} style={{ display: 'flex' }}>
          <Select defaultValue="All" style={{ width: 120 }}>
            {portfolioData.map(({ name }) => (
              <Option key={name} value={name}>
                {name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </>
  )
}

const RenderCollapse = ({ searchName, showModal }: { searchName: string; showModal: () => void }) => {
  return (
    <div>
      <Collapse key={''} className="site-collapse-custom-admin-panel">
        {Users.filter((c) =>
          c
            ? c.name.toLowerCase().includes(searchName.toLowerCase()) ||
              c.surname.toLowerCase().includes(searchName.toLowerCase())
            : true
        ).map(({ name, surname, email }) => (
          <Panel
            forceRender={true}
            header={`${name} ${surname} (${email})`}
            key=""
            className="site-collapse-custom-admin-panel"
          >
            <Table key={''} dataSource={administrationData} columns={administrationColumns} pagination={false} />
            <Button
              onClick={showModal}
              key={name}
              type="primary"
              icon={<PlusSquareOutlined />}
              style={{ marginTop: '15px', marginBottom: '10px' }}
            >
              Add project
            </Button>
          </Panel>
        ))}
      </Collapse>
    </div>
  )
}

function PermissionManagementPart(): JSX.Element {
  const [administrationModalVisible, setAdministrationModalVisibility] = useState(false)
  const [searchName, setSearchName] = useState('')

  const handleSearch = (event: string) => setSearchName(event)
  const showModal = () => setAdministrationModalVisibility(true)
  const hideModal = () => setAdministrationModalVisibility(false)

  const dispatch = useDispatch()
  const getOpenWindow = (id: string) => (state: RootState) => state.openWindows.windows.get(id) || false
  const permissionManagementWindowId = 'permissionManagement'
  const ispermissionManagementWindowOpen = useSelector(getOpenWindow(permissionManagementWindowId))

  return (
    <>
      <BreadcrumbPanel
        moveTo={'/administration/permission-management'}
        displayName={'Permission Management'}
        categoryName={'Administration'}
        openWindow={() => dispatch(setOpenWindowState(permissionManagementWindowId, true))}
      />
      <NewWindow
        height={newWindowHeight}
        width={newWindowWidth}
        isOpen={ispermissionManagementWindowOpen}
        onPortalClose={() => dispatch(setOpenWindowState(permissionManagementWindowId, false))}
        replacementPane={
          <ReplacementPane
            onHide={() => dispatch(setOpenWindowState(permissionManagementWindowId, false))}
            onWindowShow={() => {}}
            style={{ width: '100%', height: 450, backgroundColor: '#fff' }}
          />
        }
      >
        <RenderUpperPanel key={'1'} handleSearch={handleSearch} />
        <RenderCollapse key={'2'} searchName={searchName} showModal={showModal} />
        <AdministrationModal key={'3'} visibility={administrationModalVisible} hideModal={hideModal} />
      </NewWindow>
    </>
  )
}

export default PermissionManagement
