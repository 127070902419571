import React, { useState } from 'react'
import { Button, Popover, Space, Divider, Tooltip } from 'antd'
import { ColumnHeightOutlined } from '@ant-design/icons/lib'

export interface TableRowsExpansionManagerProps {
  levelsNumber: number
  onExpandToLevel: (level: number) => void
}

export default function TableRowsExpansionManager({ levelsNumber, onExpandToLevel }: TableRowsExpansionManagerProps) {
  const [visible, setVisible] = useState(false)
  return (
    <Popover
      visible={visible}
      content={
        <RowsExpandPopoverContent
          levelsNumber={levelsNumber}
          onExpandToLevel={onExpandToLevel}
          hide={() => setVisible(false)}
        />
      }
    >
      <Tooltip title="Expand rows">
        <Button type="text" shape="circle" icon={<ColumnHeightOutlined />} onClick={() => setVisible(true)} />
      </Tooltip>
    </Popover>
  )
}

interface TableRowsExpansionManagerContentProps extends TableRowsExpansionManagerProps {
  hide: () => void
}

function RowsExpandPopoverContent({
  levelsNumber,
  onExpandToLevel,
  hide,
}: TableRowsExpansionManagerContentProps): JSX.Element {
  const levels = [...Array(levelsNumber).keys()].map((x) => x + 1)
  const executeAndHide = (fun: () => void) => () => {
    fun()
    hide()
  }
  return (
    <Space direction={'vertical'}>
      <Button type="link" block={true} onClick={executeAndHide(() => onExpandToLevel(0))}>
        Collapse all
      </Button>
      <Button type="link" block={true} onClick={executeAndHide(() => onExpandToLevel(levelsNumber))}>
        Expand all
      </Button>
      <Divider type={'horizontal'} style={{ margin: 0 }} />
      Expand to:
      {levels.map((level) => {
        return (
          <Button key={level} type="link" block={true} onClick={executeAndHide(() => onExpandToLevel(level))}>
            Level {level}
          </Button>
        )
      })}
    </Space>
  )
}
