import { ModelNode } from '../../components/domain/forge-viewer/ForgeViewer.model'
import { parseTakeOffData, TakeOffItem } from '../../components/domain/take-off/helpers/TakeOffDataParser'
import { convertToTableItem } from '../../components/domain/take-off/helpers/TakeOffConverter'
import { TakeOffTableItem } from '../../components/domain/take-off/TakeOffTables.model'

export const ModelFFEData: ModelNode = {
  id: 1,
  name: 'Model',
  children: [
    {
      id: 3306,
      name: 'Furniture',
      children: [
        {
          id: 3307,
          name: 'Chair (2)',
          children: [
            {
              id: 3309,
              name: 'G.027 - Chair - Meeting',
              children: [
                {
                  id: 3310,
                  name: 'Chair (2) [200392]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3311,
                  name: 'Chair (2) [200398]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3312,
                  name: 'Chair (2) [200401]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3313,
                  name: 'Chair (2) [200407]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3318,
                  name: 'Chair (2) [200458]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3319,
                  name: 'Chair (2) [200507]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.027 - Chair - Meeting',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair (2)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3315,
              name: 'G.027 - Chair - Stacking, Staff/Visitors',
              children: [
                {
                  id: 3316,
                  name: 'Chair (2) [200410]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3320,
                  name: 'Chair (2) [200524]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.027 - Chair - Stacking, Staff/Visitors',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair (2)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3398,
              name: '03.04.20.002.01_G.028 - Chair - Plastic With Arms-401 Entrance Building',
              children: [
                {
                  id: 3399,
                  name: 'Chair (2) [202625]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3400,
                  name: 'Chair (2) [202648]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.20.002.01_G.028 - Chair - Plastic With Arms-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair (2)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3412,
              name: '03.04.20.001.01_G.029 - Chair - Plastic With Arms-401 Entrance Building',
              children: [
                {
                  id: 3413,
                  name: 'Chair (2) [202696]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3414,
                  name: 'Chair (2) [202697]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.20.001.01_G.029 - Chair - Plastic With Arms-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair (2)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3662,
              name: '03.04.28.002.01_G.006 - Chair - Stacking, Staff/Visitors-401 Entrance Building',
              children: [
                {
                  id: 3663,
                  name: 'Chair (2) [211632]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3664,
                  name: 'Chair (2) [211635]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.002.01_G.006 - Chair - Stacking, Staff/Visitors-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair (2)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3730,
              name: '03.04.28.004.01_G.018 - Chair - Plastic With Arms-401 Entrance Building',
              children: [
                {
                  id: 3731,
                  name: 'Chair (2) [213390]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.004.01_G.018 - Chair - Plastic With Arms-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair (2)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3775,
              name: '03.04.28.005.01_G.019 - Chair - moulded plastic,-401 Entrance Building',
              children: [
                {
                  id: 3776,
                  name: 'Chair (2) [214698]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3777,
                  name: 'Chair (2) [214701]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3778,
                  name: 'Chair (2) [214704]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3779,
                  name: 'Chair (2) [214707]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3780,
                  name: 'Chair (2) [214710]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3781,
                  name: 'Chair (2) [214713]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3782,
                  name: 'Chair (2) [214716]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3783,
                  name: 'Chair (2) [214719]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.005.01_G.019 - Chair - moulded plastic,-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair (2)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3874,
              name: '03.04.28.007.01_G.023 - Chair - stacking, staff/visitors-401 Entrance Building',
              children: [
                {
                  id: 3875,
                  name: 'Chair (2) [217192]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3876,
                  name: 'Chair (2) [217195]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.007.01_G.023 - Chair - stacking, staff/visitors-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair (2)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3907,
              name: '03.04.28.008.01_G.024 - Chair - Plastic With Arms-401 Entrance Building',
              children: [
                {
                  id: 3908,
                  name: 'Chair (2) [217965]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.008.01_G.024 - Chair - Plastic With Arms-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair (2)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4383,
              name: '03.04.30.010.01_1.014 - Chair - with writing tablet-401 Entrance Building',
              children: [
                {
                  id: 4384,
                  name: 'Chair (2) [226222]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4385,
                  name: 'Chair (2) [226235]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4386,
                  name: 'Chair (2) [226246]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4387,
                  name: 'Chair (2) [226247]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4388,
                  name: 'Chair (2) [226261]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4389,
                  name: 'Chair (2) [226273]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4390,
                  name: 'Chair (2) [226274]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4391,
                  name: 'Chair (2) [226275]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4392,
                  name: 'Chair (2) [226276]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4393,
                  name: 'Chair (2) [226277]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4394,
                  name: 'Chair (2) [226287]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4395,
                  name: 'Chair (2) [226288]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4396,
                  name: 'Chair (2) [226289]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4397,
                  name: 'Chair (2) [226290]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4398,
                  name: 'Chair (2) [226291]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4399,
                  name: 'Chair (2) [226301]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4400,
                  name: 'Chair (2) [226302]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4401,
                  name: 'Chair (2) [226303]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4402,
                  name: 'Chair (2) [226304]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4403,
                  name: 'Chair (2) [226305]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4404,
                  name: 'Chair (2) [226315]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4405,
                  name: 'Chair (2) [226316]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4406,
                  name: 'Chair (2) [226317]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4407,
                  name: 'Chair (2) [226318]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4408,
                  name: 'Chair (2) [226319]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4409,
                  name: 'Chair (2) [226329]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4410,
                  name: 'Chair (2) [226330]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4411,
                  name: 'Chair (2) [226331]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4412,
                  name: 'Chair (2) [226332]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4413,
                  name: 'Chair (2) [226333]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.30.010.01_1.014 - Chair - with writing tablet-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair (2)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4498,
              name: '03.04.26.002.01_1.022 - Chair - Meeting-401 Entrance Building',
              children: [
                {
                  id: 4499,
                  name: 'Chair (2) [227970]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4500,
                  name: 'Chair (2) [227973]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4501,
                  name: 'Chair (2) [227976]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4502,
                  name: 'Chair (2) [227979]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4503,
                  name: 'Chair (2) [227982]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4504,
                  name: 'Chair (2) [227985]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4505,
                  name: 'Chair (2) [227988]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4506,
                  name: 'Chair (2) [227991]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.26.002.01_1.022 - Chair - Meeting-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair (2)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4527,
              name: '03.04.32.001.01(1)_1.026 - Chair - dining, staff use-401 Entrance Building',
              children: [
                {
                  id: 4528,
                  name: 'Chair (2) [228293]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4529,
                  name: 'Chair (2) [228296]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.32.001.01(1)_1.026 - Chair - dining, staff use-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair (2)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4557,
              name: '03.04.06.001.01_1.029 - Chair - meeting-401 Entrance Building',
              children: [
                {
                  id: 4558,
                  name: 'Chair (2) [228566]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4559,
                  name: 'Chair (2) [228569]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4560,
                  name: 'Chair (2) [228572]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4561,
                  name: 'Chair (2) [228615]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4562,
                  name: 'Chair (2) [228628]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4563,
                  name: 'Chair (2) [228629]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4564,
                  name: 'Chair (2) [228640]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4565,
                  name: 'Chair (2) [228641]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4566,
                  name: 'Chair (2) [228652]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4567,
                  name: 'Chair (2) [228653]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4568,
                  name: 'Chair (2) [228682]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4569,
                  name: 'Chair (2) [228693]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4570,
                  name: 'Chair (2) [228694]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4571,
                  name: 'Chair (2) [228705]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4572,
                  name: 'Chair (2) [228706]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4573,
                  name: 'Chair (2) [228717]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4574,
                  name: 'Chair (2) [228718]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4575,
                  name: 'Chair (2) [228746]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4576,
                  name: 'Chair (2) [228756]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4577,
                  name: 'Chair (2) [228757]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.06.001.01_1.029 - Chair - meeting-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair (2)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4580,
              name: '03.04.06.001.01_1.029 - Chair - stacking, staff/visitors-401 Entrance Building',
              children: [
                {
                  id: 4581,
                  name: 'Chair (2) [228770]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4582,
                  name: 'Chair (2) [228790]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4583,
                  name: 'Chair (2) [228800]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4584,
                  name: 'Chair (2) [228810]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4585,
                  name: 'Chair (2) [228820]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4586,
                  name: 'Chair (2) [228830]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.06.001.01_1.029 - Chair - stacking, staff/visitors-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair (2)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Chair (2)',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Furniture',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3351,
          name: 'Table',
          children: [
            {
              id: 3353,
              name: 'G.027 - Table - Meeting 1600x800',
              children: [
                {
                  id: 3354,
                  name: 'Table [201178]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.027 - Table - Meeting 1600x800',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Table',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 1600,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 3796,
              name: '03.04.28.005.01_G.019 - Table - search, 1800 x 800-401 Entrance Building',
              children: [
                {
                  id: 3797,
                  name: 'Table [215571]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.005.01_G.019 - Table - search, 1800 x 800-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Table',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 2000,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4067,
              name: '03.04.33.001.01_G.004 - Table - search, 1800 x 800-401 Entrance Building',
              children: [
                {
                  id: 4068,
                  name: 'Table [220872]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4069,
                  name: 'Table [220875]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4070,
                  name: 'Table [220878]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4071,
                  name: 'Table [221305]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.33.001.01_G.004 - Table - search, 1800 x 800-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Table',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 1800,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4216,
              name: '1.008 - Table - dining, staff, 800 x 800',
              children: [
                {
                  id: 4217,
                  name: 'Table [223803]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 900,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4222,
                  name: 'Table [223836]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 900,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '1.008 - Table - dining, staff, 800 x 800',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Table',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 900,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4509,
              name: '03.04.26.002.01_1.022 - Table - meeting, 2000 x 1000-401 Entrance Building',
              children: [
                {
                  id: 4510,
                  name: 'Table [228058]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4511,
                  name: 'Table [228082]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.26.002.01_1.022 - Table - meeting, 2000 x 1000-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Table',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 2000,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4540,
              name: '03.04.32.001.01_1.026 - Table - dining, staff, 800 x 800-401 Entrance Building',
              children: [
                {
                  id: 4541,
                  name: 'Table [228358]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.32.001.01_1.026 - Table - dining, staff, 800 x 800-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Table',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 800,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4589,
              name: '03.04.06.001.01_1.029 - Table - meeting, 2000 x 1000-401 Entrance Building',
              children: [
                {
                  id: 4590,
                  name: 'Table [228870]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4591,
                  name: 'Table [228873]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4592,
                  name: 'Table [228915]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4593,
                  name: 'Table [228936]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.06.001.01_1.029 - Table - meeting, 2000 x 1000-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Table',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 2000,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4600,
              name: '03.04.06.001.01_1.029 - Table - meeting, 1600 x 800-401 Entrance Building',
              children: [
                {
                  id: 4601,
                  name: 'Table [228999]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.06.001.01_1.029 - Table - meeting, 1600 x 800-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Table',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 1600,
                  displayCategory: 'Dimensions',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Table',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Furniture',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3378,
          name: 'Table - Round (1)',
          children: [
            {
              id: 3380,
              name: '03.04.20.002.01_G.028 - Table - Coffee, Staff/Visitors-401 Entrance Building',
              children: [
                {
                  id: 3381,
                  name: 'Table - Round (1) [201912]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 760.0000000000001,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.20.002.01_G.028 - Table - Coffee, Staff/Visitors-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Table - Round (1)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 760.0000000000001,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 3402,
              name: '03.04.20.001.01_G.029 - Table - Coffee, Staff/Visitors-401 Entrance Building',
              children: [
                {
                  id: 3403,
                  name: 'Table - Round (1) [202660]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 760.0000000000001,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.20.001.01_G.029 - Table - Coffee, Staff/Visitors-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Table - Round (1)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 760.0000000000001,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 3837,
              name: '03.04.43.002.01_G.021 - Table - coffee, staff/visitors-401 Entrance Building',
              children: [
                {
                  id: 3838,
                  name: 'Table - Round (1) [216601]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 760.0000000000001,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3839,
                  name: 'Table - Round (1) [216604]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 760.0000000000001,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3840,
                  name: 'Table - Round (1) [216607]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 760.0000000000001,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3841,
                  name: 'Table - Round (1) [216610]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 760.0000000000001,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.43.002.01_G.021 - Table - coffee, staff/visitors-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Table - Round (1)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 760.0000000000001,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4230,
              name: '1.008 - Table - coffee, staff/visitors',
              children: [
                {
                  id: 4231,
                  name: 'Table - Round (1) [223908]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 760.0000000000001,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '1.008 - Table - coffee, staff/visitors',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Table - Round (1)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 760.0000000000001,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4374,
              name: '03.04.30.010.01_1.014 - Table - coffee, staff/visitors-401 Entrance Building',
              children: [
                {
                  id: 4375,
                  name: 'Table - Round (1) [226197]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 760.0000000000001,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4376,
                  name: 'Table - Round (1) [226200]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 760.0000000000001,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4377,
                  name: 'Table - Round (1) [226203]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 760.0000000000001,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4378,
                  name: 'Table - Round (1) [226206]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 760.0000000000001,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4379,
                  name: 'Table - Round (1) [226209]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 760.0000000000001,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4380,
                  name: 'Table - Round (1) [226212]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 760.0000000000001,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.30.010.01_1.014 - Table - coffee, staff/visitors-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Table - Round (1)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 760.0000000000001,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4536,
              name: '03.04.32.001.01_1.026 - Table - coffee, staff/visitors-401 Entrance Building',
              children: [
                {
                  id: 4537,
                  name: 'Table - Round (1) [228348]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 760.0000000000001,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.32.001.01_1.026 - Table - coffee, staff/visitors-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Table - Round (1)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 760.0000000000001,
                  displayCategory: 'Dimensions',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Table - Round (1)',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Furniture',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3389,
          name: 'Chair - Design (1)',
          children: [
            {
              id: 3391,
              name: '03.04.20.002.01_G.028 - Chair - Easy Armchair-401 Entrance Building',
              children: [
                {
                  id: 3392,
                  name: 'Chair - Design (1) [202577]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3393,
                  name: 'Chair - Design (1) [202580]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3394,
                  name: 'Chair - Design (1) [202583]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3395,
                  name: 'Chair - Design (1) [202586]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.20.002.01_G.028 - Chair - Easy Armchair-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair - Design (1)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 670,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 700,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 3405,
              name: '03.04.20.001.01_G.029 - Chair - Easy Armchair-401 Entrance Building',
              children: [
                {
                  id: 3406,
                  name: 'Chair - Design (1) [202661]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3407,
                  name: 'Chair - Design (1) [202662]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3408,
                  name: 'Chair - Design (1) [202663]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3409,
                  name: 'Chair - Design (1) [202664]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.20.001.01_G.029 - Chair - Easy Armchair-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair - Design (1)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 670,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 700,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 3462,
              name: '03.04.43.001.01_G.002 - Chair - Waiting, Visitor-401 Entrance Building',
              children: [
                {
                  id: 3463,
                  name: 'Chair - Design (1) [205155]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3464,
                  name: 'Chair - Design (1) [205189]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3465,
                  name: 'Chair - Design (1) [205199]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3466,
                  name: 'Chair - Design (1) [205219]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3467,
                  name: 'Chair - Design (1) [205220]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3468,
                  name: 'Chair - Design (1) [205221]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.43.001.01_G.002 - Chair - Waiting, Visitor-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair - Design (1)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 670,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 800,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4209,
              name: '03.04.32.002.01_1.008 - Chair - dining, staff use-401 Entrance Building',
              children: [
                {
                  id: 4210,
                  name: 'Chair - Design (1) [223756]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4211,
                  name: 'Chair - Design (1) [223759]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4212,
                  name: 'Chair - Design (1) [223762]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4213,
                  name: 'Chair - Design (1) [223765]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4218,
                  name: 'Chair - Design (1) [223832]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4219,
                  name: 'Chair - Design (1) [223833]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4220,
                  name: 'Chair - Design (1) [223834]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4221,
                  name: 'Chair - Design (1) [223835]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.32.002.01_1.008 - Chair - dining, staff use-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair - Design (1)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 670,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 700,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4225,
              name: '1.008 - Chair - Easy, Staff',
              children: [
                {
                  id: 4226,
                  name: 'Chair - Design (1) [223865]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 500,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4227,
                  name: 'Chair - Design (1) [223868]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 500,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '1.008 - Chair - Easy, Staff',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair - Design (1)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 670,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 500,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4532,
              name: '03.04.32.001.01(1)_1.026 - Chair - easy, staff-401 Entrance Building',
              children: [
                {
                  id: 4533,
                  name: 'Chair - Design (1) [228325]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 670,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.32.001.01(1)_1.026 - Chair - easy, staff-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair - Design (1)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 670,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 700,
                  displayCategory: 'Dimensions',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Chair - Design (1)',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Furniture',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3421,
          name: 'Shelving',
          children: [
            {
              id: 3423,
              name:
                '03.04.00.001.01_G.003 - Display Cabinet - Patient Produced Goods, Lockable N10/316-401 Entrance Building',
              children: [
                {
                  id: 3424,
                  name: 'Shelving [203272]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1830,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3425,
                  name: 'Shelving [203310]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1830,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3426,
                  name: 'Shelving [203320]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1830,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.00.001.01_G.003 - Display Cabinet - Patient Produced Goods, Lockable N10/316-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Shelving',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 1830,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 2100,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4098,
              name: '03.04.30.004.01_G.038 - Racking for 12 PPE kit bags N10/158-401 Entrance Building',
              children: [
                {
                  id: 4099,
                  name: 'Shelving [221520]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2134,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.30.004.01_G.038 - Racking for 12 PPE kit bags N10/158-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Shelving',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 2134,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 1200,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4103,
              name:
                '03.04.30.004.01_G.038 - Racking for storage of shield operational bags N10/158-401 Entrance Building',
              children: [
                {
                  id: 4104,
                  name: 'Shelving [221559]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2134,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1000,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.30.004.01_G.038 - Racking for storage of shield operational bags N10/158-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Shelving',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 2134,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 1000,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4106,
              name:
                '03.04.30.004.01_G.038 - G.038 - Racking for storage of shield operational bags N10/158-401 Entrance Building',
              children: [
                {
                  id: 4107,
                  name: 'Shelving [221589]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2134,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.30.004.01_G.038 - G.038 - Racking for storage of shield operational bags N10/158-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Shelving',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 2134,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 2400,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4110,
              name: '03.04.30.004.01_G.038 - Racking to store decontamination equipment N10/158-401 Entrance Building',
              children: [
                {
                  id: 4111,
                  name: 'Shelving [221624]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2134,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.30.004.01_G.038 - Racking to store decontamination equipment N10/158-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Shelving',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 2134,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 1600,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4114,
              name: '03.04.30.004.01_G.038 - Racking to store paper suits x 40 N10/158-401 Entrance Building',
              children: [
                {
                  id: 4115,
                  name: 'Shelving [221658]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2134,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1000,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.30.004.01_G.038 - Racking to store paper suits x 40 N10/158-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Shelving',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 2134,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 1000,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4118,
              name: '03.04.30.004.01_G.038 - Storage - lockable for MoE equipment N10/227-401 Entrance Building',
              children: [
                {
                  id: 4119,
                  name: 'Shelving [221691]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2134,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1000,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.30.004.01_G.038 - Storage - lockable for MoE equipment N10/227-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Shelving',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 2134,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 1000,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4311,
              name: '03.04.30.009.01_1.012 - Racking for 20 anti stab vests N10/158-401 Entrance Building',
              children: [
                {
                  id: 4312,
                  name: 'Shelving [225381]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2134,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.30.009.01_1.012 - Racking for 20 anti stab vests N10/158-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Shelving',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 2134,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 1200,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4315,
              name: '03.04.30.009.01_1.012 - Racking for 20 boxed helmets and boots N10/158-401 Entrance Building',
              children: [
                {
                  id: 4316,
                  name: 'Shelving [225413]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2134,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.30.009.01_1.012 - Racking for 20 boxed helmets and boots N10/158-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Shelving',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 2134,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 1200,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4319,
              name: '03.04.30.009.01_1.012 - Racking for 40 surplus overalls N10/158-401 Entrance Building',
              children: [
                {
                  id: 4320,
                  name: 'Shelving [225441]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1000,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.30.009.01_1.012 - Racking for 40 surplus overalls N10/158-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Shelving',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 1000,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 1000,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4333,
              name:
                '03.04.30.009.01_1.012 - Racking for 70 kit bags to suit layout of room N10/158-401 Entrance Building',
              children: [
                {
                  id: 4334,
                  name: 'Shelving [225670]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4335,
                  name: 'Shelving [225701]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4336,
                  name: 'Shelving [225711]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4337,
                  name: 'Shelving [225721]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4338,
                  name: 'Shelving [225753]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4339,
                  name: 'Shelving [225763]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4340,
                  name: 'Shelving [225796]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4341,
                  name: 'Shelving [225797]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4342,
                  name: 'Shelving [225814]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4343,
                  name: 'Shelving [225831]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4344,
                  name: 'Shelving [225850]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4345,
                  name: 'Shelving [225871]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4346,
                  name: 'Shelving [225888]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4347,
                  name: 'Shelving [225889]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4348,
                  name: 'Shelving [225890]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4349,
                  name: 'Shelving [225891]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4350,
                  name: 'Shelving [225892]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4351,
                  name: 'Shelving [225893]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.30.009.01_1.012 - Racking for 70 kit bags to suit layout of room N10/158-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Shelving',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 2000,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 800,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4369,
              name: '03.04.30.009.01_1.012 - Racking for drying helmets N10/158-401 Entrance Building',
              children: [
                {
                  id: 4370,
                  name: 'Shelving [226169]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1000,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.30.009.01_1.012 - Racking for drying helmets N10/158-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Shelving',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 1000,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 1000,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4544,
              name: '03.04.06.001.01_1.029 - Shelves - to suit layout N10/160-401 Entrance Building',
              children: [
                {
                  id: 4545,
                  name: 'Shelving [228390]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2134,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2500,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.06.001.01_1.029 - Shelves - to suit layout N10/160-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Shelving',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 2134,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 2500,
                  displayCategory: 'Dimensions',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Shelving',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Furniture',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3440,
          name: 'Locker x3 Row',
          children: [
            {
              id: 3442,
              name: '03.04.32.001.01_G.033 - Staff Locker, 213 No  (Various Size) N10/221-401 Entrance Building',
              children: [
                {
                  id: 3443,
                  name: 'Locker x3 Row [204948]',
                  children: [
                    {
                      id: 3447,
                      name: 'Lockers x 3 [204950]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Furniture',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 400,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 3448,
                      name: 'Lockers x 3 [204951]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Furniture',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 400,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 3449,
                      name: 'Lockers x 3 [204952]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Furniture',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 400,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 3450,
                      name: 'Lockers x 3 [204953]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Furniture',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 400,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                  ],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.32.001.01_G.033 - Staff Locker, 213 No  (Various Size) N10/221-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Locker x3 Row',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3454,
              name: '03.04.43.001.01_G.002 - Visitor Lockers, 80 No, Visitor Size  N10/220-401 Entrance Building',
              children: [
                {
                  id: 3455,
                  name: 'Locker x3 Row [205124]',
                  children: [
                    {
                      id: 3456,
                      name: 'Lockers x 3 [205126]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Furniture',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 400,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 3457,
                      name: 'Lockers x 3 [205127]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Furniture',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 400,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 3458,
                      name: 'Lockers x 3 [205128]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Furniture',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 400,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 3459,
                      name: 'Lockers x 3 [205129]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Furniture',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 400,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                  ],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.43.001.01_G.002 - Visitor Lockers, 80 No, Visitor Size  N10/220-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Locker x3 Row',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4074,
              name: '03.04.30.002_G.034 - Mini Lockers N10/224 - 40 No-401 Entrance Building',
              children: [
                {
                  id: 4075,
                  name: 'Locker x3 Row [221331]',
                  children: [
                    {
                      id: 4076,
                      name: 'Lockers x 3 [221333]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Furniture',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1000,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4077,
                      name: 'Lockers x 3 [221334]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Furniture',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1000,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4078,
                      name: 'Lockers x 3 [221335]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Furniture',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1000,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4079,
                      name: 'Lockers x 3 [221336]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Furniture',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1000,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                  ],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4080,
                  name: 'Locker x3 Row [221378]',
                  children: [
                    {
                      id: 4081,
                      name: 'Lockers x 3 [221379]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Furniture',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1000,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4082,
                      name: 'Lockers x 3 [221380]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Furniture',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1000,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4083,
                      name: 'Lockers x 3 [221381]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Furniture',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1000,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4084,
                      name: 'Lockers x 3 [221382]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Furniture',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1000,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                  ],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.30.002_G.034 - Mini Lockers N10/224 - 40 No-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Locker x3 Row',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Locker x3 Row',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Furniture',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3444,
          name: 'Lockers x 3',
          children: [
            {
              id: 4249,
              name: '03.04.32.003.01_1.009 - Locker - full height - 1 door N10/223-401 Entrance Building',
              children: [
                {
                  id: 4250,
                  name: 'Lockers x 3 [224564]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4251,
                  name: 'Lockers x 3 [224632]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4252,
                  name: 'Lockers x 3 [224659]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4253,
                  name: 'Lockers x 3 [224660]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4254,
                  name: 'Lockers x 3 [224678]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4255,
                  name: 'Lockers x 3 [224679]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4256,
                  name: 'Lockers x 3 [224680]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4257,
                  name: 'Lockers x 3 [224681]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4258,
                  name: 'Lockers x 3 [224702]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4259,
                  name: 'Lockers x 3 [224703]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.32.003.01_1.009 - Locker - full height - 1 door N10/223-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Lockers x 3',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4261,
              name: '1.010 - Locker - full height - 1 door N10/223',
              children: [
                {
                  id: 4262,
                  name: 'Lockers x 3 [224720]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4263,
                  name: 'Lockers x 3 [224721]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4264,
                  name: 'Lockers x 3 [224722]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4265,
                  name: 'Lockers x 3 [224723]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4266,
                  name: 'Lockers x 3 [224724]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4267,
                  name: 'Lockers x 3 [224725]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4268,
                  name: 'Lockers x 3 [224726]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4269,
                  name: 'Lockers x 3 [224727]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4289,
                  name: 'Lockers x 3 [225096]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4290,
                  name: 'Lockers x 3 [225097]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '1.010 - Locker - full height - 1 door N10/223',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Lockers x 3',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4272,
              name: '03.04.32.003.01_1.009 - Locker - half height - 2 door N10/223-401 Entrance Building',
              children: [
                {
                  id: 4273,
                  name: 'Lockers x 3 [224755]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4274,
                  name: 'Lockers x 3 [224756]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4275,
                  name: 'Lockers x 3 [224757]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4276,
                  name: 'Lockers x 3 [224758]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4277,
                  name: 'Lockers x 3 [224759]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4278,
                  name: 'Lockers x 3 [224760]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4280,
                  name: 'Lockers x 3 [224793]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4281,
                  name: 'Lockers x 3 [224794]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4282,
                  name: 'Lockers x 3 [224812]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4283,
                  name: 'Lockers x 3 [224813]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4284,
                  name: 'Lockers x 3 [224846]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4285,
                  name: 'Lockers x 3 [224847]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4286,
                  name: 'Lockers x 3 [224868]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 239.7431966588716,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4287,
                  name: 'Lockers x 3 [224963]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4288,
                  name: 'Lockers x 3 [225029]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250.40141241122467,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.32.003.01_1.009 - Locker - half height - 2 door N10/223-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Lockers x 3',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4292,
              name: '1.010 - Locker - half height - 2 door N10/223',
              children: [
                {
                  id: 4293,
                  name: 'Lockers x 3 [225171]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4294,
                  name: 'Lockers x 3 [225172]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250.40141241122467,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4295,
                  name: 'Lockers x 3 [225197]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4297,
                  name: 'Lockers x 3 [225229]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4298,
                  name: 'Lockers x 3 [225230]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4299,
                  name: 'Lockers x 3 [225231]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 299.6899147953747,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4300,
                  name: 'Lockers x 3 [225295]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4301,
                  name: 'Lockers x 3 [225296]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4302,
                  name: 'Lockers x 3 [225297]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 299.6899147953747,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4303,
                  name: 'Lockers x 3 [225313]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4304,
                  name: 'Lockers x 3 [225314]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4305,
                  name: 'Lockers x 3 [225315]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 299.6899147953747,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4306,
                  name: 'Lockers x 3 [225335]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4307,
                  name: 'Lockers x 3 [225336]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4308,
                  name: 'Lockers x 3 [225337]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 249.92259362896837,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '1.010 - Locker - half height - 2 door N10/223',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Lockers x 3',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Lockers x 3',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Furniture',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3496,
          name: 'Cabinet File - Lateral 4 Drawer',
          children: [
            {
              id: 3498,
              name: '03.04.30.001.01_G.005 - Cabinet - filing, 4 drawer, lockable-401 Entrance Building',
              children: [
                {
                  id: 3499,
                  name: 'Cabinet File - Lateral 4 Drawer [207202]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 550,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 760.0000000000001,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.30.001.01_G.005 - Cabinet - filing, 4 drawer, lockable-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cabinet File - Lateral 4 Drawer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 550,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 760.0000000000001,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 3529,
              name: '03.04.30.001.03_G.005 - Desk pedestal - under desk-401 Entrance Building',
              children: [
                {
                  id: 3530,
                  name: 'Cabinet File - Lateral 4 Drawer [208912]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 850,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3531,
                  name: 'Cabinet File - Lateral 4 Drawer [209001]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 850,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3532,
                  name: 'Cabinet File - Lateral 4 Drawer [209011]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 850,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3533,
                  name: 'Cabinet File - Lateral 4 Drawer [209021]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 850,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3534,
                  name: 'Cabinet File - Lateral 4 Drawer [209031]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 850,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.30.001.03_G.005 - Desk pedestal - under desk-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cabinet File - Lateral 4 Drawer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 400,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 850,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 3610,
              name:
                '03.04.22.001.01_G.010 - Cupboard - kitchen, wall , floor and drawers, to suit layout N11/310, 320-401 Entrance Building',
              children: [
                {
                  id: 3611,
                  name: 'Cabinet File - Lateral 4 Drawer [210603]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.22.001.01_G.010 - Cupboard - kitchen, wall , floor and drawers, to suit layout N11/310, 320-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cabinet File - Lateral 4 Drawer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 400,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 600,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 3613,
              name:
                '03.04.22.001.01_G.010 - Cupboard - kitchen, wall , floor and drawers, to suit layout N11/310, 320 - 1-401 Entrance Building',
              children: [
                {
                  id: 3614,
                  name: 'Cabinet File - Lateral 4 Drawer [210616]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.22.001.01_G.010 - Cupboard - kitchen, wall , floor and drawers, to suit layout N11/310, 320 - 1-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cabinet File - Lateral 4 Drawer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 400,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 600,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 3652,
              name: '03.04.28.002.01_G.006 - Cabinet - filing, 4 drawer, lockable-401 Entrance Building',
              children: [
                {
                  id: 3653,
                  name: 'Cabinet File - Lateral 4 Drawer [211538]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3654,
                  name: 'Cabinet File - Lateral 4 Drawer [211568]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.002.01_G.006 - Cabinet - filing, 4 drawer, lockable-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cabinet File - Lateral 4 Drawer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 400,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 450,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 3706,
              name: '03.04.28.004.01_G.018 - Tambour unit - with hanging rails-401 Entrance Building',
              children: [
                {
                  id: 3707,
                  name: 'Cabinet File - Lateral 4 Drawer [213075]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1000,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.004.01_G.018 - Tambour unit - with hanging rails-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cabinet File - Lateral 4 Drawer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 400,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 1000,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 3722,
              name: '03.04.28.004.01_G.018 - Desl Pedestal - Side of Desk-401 Entrance Building',
              children: [
                {
                  id: 3723,
                  name: 'Cabinet File - Lateral 4 Drawer [213331]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 690,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.004.01_G.018 - Desl Pedestal - Side of Desk-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cabinet File - Lateral 4 Drawer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 400,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 690,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 3862,
              name: '03.04.28.007.01_G.023 - Cabinet - filing, 4 drawer, lockable-401 Entrance Building',
              children: [
                {
                  id: 3863,
                  name: 'Cabinet File - Lateral 4 Drawer [217088]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3864,
                  name: 'Cabinet File - Lateral 4 Drawer [217109]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3865,
                  name: 'Cabinet File - Lateral 4 Drawer [217119]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3866,
                  name: 'Cabinet File - Lateral 4 Drawer [217129]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.007.01_G.023 - Cabinet - filing, 4 drawer, lockable-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cabinet File - Lateral 4 Drawer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 400,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 600,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 3889,
              name:
                '03.04.28.007.01_G.023 - Office furniture (open/modular) to include desk, side pedestal, tackable desk screen 2-401 Entrance Building',
              children: [
                {
                  id: 3890,
                  name: 'Cabinet File - Lateral 4 Drawer [217737]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 550,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3891,
                  name: 'Cabinet File - Lateral 4 Drawer [217780]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 550,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.28.007.01_G.023 - Office furniture (open/modular) to include desk, side pedestal, tackable desk screen 2-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cabinet File - Lateral 4 Drawer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 550,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 600,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 3924,
              name: '03.04.28.008.01_G.024 - Desk pedestal - side of desk-401 Entrance Building',
              children: [
                {
                  id: 3925,
                  name: 'Cabinet File - Lateral 4 Drawer [218240]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.008.01_G.024 - Desk pedestal - side of desk-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cabinet File - Lateral 4 Drawer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 400,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 600,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 3970,
              name: '03.04.28.008.01_G.024 - Cabinet - filing, 4 drawer, lockable-401 Entrance Building',
              children: [
                {
                  id: 3971,
                  name: 'Cabinet File - Lateral 4 Drawer [218717]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3972,
                  name: 'Cabinet File - Lateral 4 Drawer [218744]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3973,
                  name: 'Cabinet File - Lateral 4 Drawer [218761]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.008.01_G.024 - Cabinet - filing, 4 drawer, lockable-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cabinet File - Lateral 4 Drawer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 400,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 600,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 3997,
              name: '03.04.28.009.01_G.026 - Desk pedestal - under desk-401 Entrance Building',
              children: [
                {
                  id: 3998,
                  name: 'Cabinet File - Lateral 4 Drawer [219192]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 650,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.009.01_G.026 - Desk pedestal - under desk-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cabinet File - Lateral 4 Drawer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 400,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 650,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4169,
              name:
                '03.04.00.002.01_1.004 - Cabinet - filing, 4 drawer, lockable, max to suit layout, 25 No-401 Entrance Building',
              children: [
                {
                  id: 4170,
                  name: 'Cabinet File - Lateral 4 Drawer [223174]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4171,
                  name: 'Cabinet File - Lateral 4 Drawer [223187]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4172,
                  name: 'Cabinet File - Lateral 4 Drawer [223203]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4173,
                  name: 'Cabinet File - Lateral 4 Drawer [223222]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4174,
                  name: 'Cabinet File - Lateral 4 Drawer [223238]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4175,
                  name: 'Cabinet File - Lateral 4 Drawer [223257]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4176,
                  name: 'Cabinet File - Lateral 4 Drawer [223273]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4177,
                  name: 'Cabinet File - Lateral 4 Drawer [223289]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4178,
                  name: 'Cabinet File - Lateral 4 Drawer [223306]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4179,
                  name: 'Cabinet File - Lateral 4 Drawer [223323]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4180,
                  name: 'Cabinet File - Lateral 4 Drawer [223341]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4181,
                  name: 'Cabinet File - Lateral 4 Drawer [223358]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4182,
                  name: 'Cabinet File - Lateral 4 Drawer [223375]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.00.002.01_1.004 - Cabinet - filing, 4 drawer, lockable, max to suit layout, 25 No-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cabinet File - Lateral 4 Drawer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 400,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 450,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4194,
              name: '1.008 - Cupboard - kitchen, wall , floor and drawers, to suit layout N11/310, 320',
              children: [
                {
                  id: 4195,
                  name: 'Cabinet File - Lateral 4 Drawer [223553]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '1.008 - Cupboard - kitchen, wall , floor and drawers, to suit layout N11/310, 320',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cabinet File - Lateral 4 Drawer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 400,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 450,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4425,
              name: '03.04.28.010.01_1.015 - Cabinet - filing, 4 drawer, lockable-401 Entrance Building',
              children: [
                {
                  id: 4426,
                  name: 'Cabinet File - Lateral 4 Drawer [226581]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 550,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.010.01_1.015 - Cabinet - filing, 4 drawer, lockable-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cabinet File - Lateral 4 Drawer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 550,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 700,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4446,
              name:
                '03.04.28.010.01_1.015 - Office furniture (open/modular) to include desk, side pedestal, tackable desk screen 2-401 Entrance Building',
              children: [
                {
                  id: 4447,
                  name: 'Cabinet File - Lateral 4 Drawer [227064]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4448,
                  name: 'Cabinet File - Lateral 4 Drawer [227067]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.28.010.01_1.015 - Office furniture (open/modular) to include desk, side pedestal, tackable desk screen 2-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cabinet File - Lateral 4 Drawer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 400,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 450,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4514,
              name: '1.026 - Cupboard - kitchen, wall , floor and drawers, to suit layout N11/310, 320',
              children: [
                {
                  id: 4515,
                  name: 'Cabinet File - Lateral 4 Drawer [228104]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '1.026 - Cupboard - kitchen, wall , floor and drawers, to suit layout N11/310, 320',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cabinet File - Lateral 4 Drawer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 400,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 400,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4613,
              name: '03.04.28.012.01_1.030 - Cabinet - filing, 4 drawer, lockable-401 Entrance Building',
              children: [
                {
                  id: 4614,
                  name: 'Cabinet File - Lateral 4 Drawer [229361]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 550,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 650,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.012.01_1.030 - Cabinet - filing, 4 drawer, lockable-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cabinet File - Lateral 4 Drawer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 550,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 650,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4634,
              name: '1.030 - Office furniture (open/modular) to include desk, side pedestal, tackable desk screen',
              children: [
                {
                  id: 4635,
                  name: 'Cabinet File - Lateral 4 Drawer [229865]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4636,
                  name: 'Cabinet File - Lateral 4 Drawer [229868]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '1.030 - Office furniture (open/modular) to include desk, side pedestal, tackable desk screen',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cabinet File - Lateral 4 Drawer',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 400,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 450,
                  displayCategory: 'Dimensions',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Cabinet File - Lateral 4 Drawer',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Furniture',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3506,
          name: 'Chair - Desk (4)',
          children: [
            {
              id: 3508,
              name: '03.04.30.001.02_G.005 - Chair - operator with arms-401 Entrance Building',
              children: [
                {
                  id: 3509,
                  name: 'Chair - Desk (4) [207970]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3510,
                  name: 'Chair - Desk (4) [207973]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3511,
                  name: 'Chair - Desk (4) [207980]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3512,
                  name: 'Chair - Desk (4) [207987]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3513,
                  name: 'Chair - Desk (4) [207990]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3514,
                  name: 'Chair - Desk (4) [207993]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3515,
                  name: 'Chair - Desk (4) [207996]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.30.001.02_G.005 - Chair - operator with arms-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair - Desk (4)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3657,
              name: '03.04.28.002.01_G.006 - Chair - operator with arms-401 Entrance Building',
              children: [
                {
                  id: 3658,
                  name: 'Chair - Desk (4) [211589]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3659,
                  name: 'Chair - Desk (4) [211592]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.002.01_G.006 - Chair - operator with arms-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair - Desk (4)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3726,
              name: '03.04.28.004.01_G.018 - Chair - operator with arms-401 Entrance Building',
              children: [
                {
                  id: 3727,
                  name: 'Chair - Desk (4) [213373]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.004.01_G.018 - Chair - operator with arms-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair - Desk (4)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3869,
              name: '03.04.28.007.01_G.023 - Chair - operator with arms-401 Entrance Building',
              children: [
                {
                  id: 3870,
                  name: 'Chair - Desk (4) [217164]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 3871,
                  name: 'Chair - Desk (4) [217167]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.007.01_G.023 - Chair - operator with arms-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair - Desk (4)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3903,
              name: '03.04.28.008.01_G.024 - Chair - operator with arms-401 Entrance Building',
              children: [
                {
                  id: 3904,
                  name: 'Chair - Desk (4) [217953]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.008.01_G.024 - Chair - operator with arms-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair - Desk (4)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3993,
              name: '03.04.28.009.01_G.026 - Chair - operator with arms-401 Entrance Building',
              children: [
                {
                  id: 3994,
                  name: 'Chair - Desk (4) [219173]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.009.01_G.026 - Chair - operator with arms-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair - Desk (4)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4062,
              name: '03.04.33.001.01_G.004 - Chair - operator with arms-401 Entrance Building',
              children: [
                {
                  id: 4063,
                  name: 'Chair - Desk (4) [220832]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4064,
                  name: 'Chair - Desk (4) [220835]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.33.001.01_G.004 - Chair - operator with arms-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair - Desk (4)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4429,
              name: '03.04.28.010.01_1.015 - Chair - operator with arms-401 Entrance Building',
              children: [
                {
                  id: 4430,
                  name: 'Chair - Desk (4) [226615]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4431,
                  name: 'Chair - Desk (4) [226618]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.010.01_1.015 - Chair - operator with arms-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair - Desk (4)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4617,
              name: '1.030 - Chair - Operator With Arms',
              children: [
                {
                  id: 4618,
                  name: 'Chair - Desk (4) [229401]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4619,
                  name: 'Chair - Desk (4) [229404]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '1.030 - Chair - Operator With Arms',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Chair - Desk (4)',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Chair - Desk (4)',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Furniture',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3522,
          name: 'Desk',
          children: [
            {
              id: 3524,
              name:
                '03.04.30.001.04_G.005 - Office furniture (open/modular) to include desk, side pedestal, tackable desk screen-401 Entrance Building',
              children: [
                {
                  id: 3525,
                  name: 'Desk [208840]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 761.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3526,
                  name: 'Desk [208865]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 761.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1450,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.30.001.04_G.005 - Office furniture (open/modular) to include desk, side pedestal, tackable desk screen-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Desk',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 761.9999999999999,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 1450,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4023,
              name: 'G.004 - Desk - security podium N10/117',
              children: [
                {
                  id: 4024,
                  name: 'Desk [220027]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 761.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1400,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.004 - Desk - security podium N10/117',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Desk',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 761.9999999999999,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 1400,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4596,
              name: '03.04.06.001.01_1.029 - Desk pedestal - side of desk-401 Entrance Building',
              children: [
                {
                  id: 4597,
                  name: 'Desk [228958]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 761.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.06.001.01_1.029 - Desk pedestal - side of desk-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Desk',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 761.9999999999999,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 2000,
                  displayCategory: 'Dimensions',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Desk',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Furniture',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3758,
          name: 'Cabinet - Storage',
          children: [
            {
              id: 3760,
              name: '03.04.28.005.01_G.019 - Cupboard - built in secure N10/113-401 Entrance Building',
              children: [
                {
                  id: 3761,
                  name: 'Cabinet - Storage [214536]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 800,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.005.01_G.019 - Cupboard - built in secure N10/113-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Cabinet - Storage',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 800,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 1200,
                  displayCategory: 'Dimensions',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Cabinet - Storage',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Furniture',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3790,
          name: 'Bed - Hospital',
          children: [
            {
              id: 3792,
              name: '03.04.28.005.01_G.019 -  Examination couch inc side rail-401 Entrance Building',
              children: [
                {
                  id: 3793,
                  name: 'Bed - Hospital [215529]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 813,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.005.01_G.019 -  Examination couch inc side rail-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Bed - Hospital',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Length',
                  displayValue: 2000,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 813,
                  displayCategory: 'Dimensions',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Bed - Hospital',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Furniture',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 4237,
          name: 'Bench - Locker Room',
          children: [
            {
              id: 4239,
              name: '03.04.32.003.01_1.009 - Bench seat - to suit layout N10/170-401 Entrance Building',
              children: [
                {
                  id: 4240,
                  name: 'Bench - Locker Room [224455]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 355.99999999999994,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2900,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.32.003.01_1.009 - Bench seat - to suit layout N10/170-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Bench - Locker Room',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 355.99999999999994,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 2900,
                  displayCategory: 'Dimensions',
                },
              ],
            },
            {
              id: 4243,
              name: '03.04.32.004.01_1.010 - Bench seat - to suit layout N10/170-401 Entrance Building',
              children: [
                {
                  id: 4244,
                  name: 'Bench - Locker Room [224493]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Furniture',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 355.99999999999994,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2600,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.32.004.01_1.010 - Bench seat - to suit layout N10/170-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Bench - Locker Room',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Furniture',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 355.99999999999994,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 2600,
                  displayCategory: 'Dimensions',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Bench - Locker Room',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Furniture',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Furniture',
          displayCategory: '__category__',
        },
      ],
    },
    {
      id: 3357,
      name: 'Structural Foundations',
      children: [
        {
          id: 3358,
          name: 'Foundation Slab',
          children: [
            {
              id: 3360,
              name: 'Turnstile L20/700',
              children: [
                {
                  id: 3361,
                  name: 'Foundation Slab [201201]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.3432454888364855,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9402718421855358,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1609.0548508150923,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 914.8038004423099,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'Turnstile L20/700',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3569,
              name: 'G.011"on / off" rollers - xray machine N10/363',
              children: [
                {
                  id: 3570,
                  name: 'Foundation Slab [209817]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.0190059947628358,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.20380119895257384,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1587.3337625874572,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 641.9607638798019,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4850,
                  name: 'Foundation Slab [230866]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.8009893009437304,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1601978601887513,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 710.2075886480743,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1185.2126541333912,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.011"on / off" rollers - xray machine N10/363',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3580,
              name: 'G.011 - X-ray search machine N10/362',
              children: [
                {
                  id: 3581,
                  name: 'Foundation Slab [210199]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.9390538187481602,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.18781076374963818,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1167.5756123267968,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 804.2766642554191,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.011 - X-ray search machine N10/362',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3591,
              name: 'G.011 - Security Portal N10/360',
              children: [
                {
                  id: 3592,
                  name: 'Foundation Slab [210339]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.4293496371104623,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.3005447459773222,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 500.8816531011646,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 857.1877896748331,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.011 - Security Portal N10/360',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3711,
              name: 'G.018 - Safe - large - 2000x1000x1000',
              children: [
                {
                  id: 3712,
                  name: 'Foundation Slab [213104]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.957996051792442,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6705972362547062,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 998.2966868236534,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 959.6306032433877,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.018 - Safe - large - 2000x1000x1000',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3735,
              name: 'G.019 "on / off" rollers - xray machine N10/363',
              children: [
                {
                  id: 3736,
                  name: 'Foundation Slab [213404]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.4452499999999995,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.08905000000000281,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 685.0000000000077,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 650.0000000000018,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4808,
                  name: 'Foundation Slab [230642]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7864994686613339,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.15729989373227188,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1185.1201735935967,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 687.9286461882245,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.019 "on / off" rollers - xray machine N10/363',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3746,
              name: 'G.019 - X-ray search machine N10/362',
              children: [
                {
                  id: 3747,
                  name: 'Foundation Slab [213857]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.9319999999999866,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1864000000000034,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1165.000000000032,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 799.9999999999939,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.019 - X-ray search machine N10/362',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3765,
              name: 'G.019 - Security Portal N10/360',
              children: [
                {
                  id: 3766,
                  name: 'Foundation Slab [214567]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.42212208027450154,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2954854561921497,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 500.89887677477674,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 860.6841954237836,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.019 - Security Portal N10/360',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3801,
              name: 'G.021 - Drinks vending machine - hot and cold beverages and chilled water N10/346',
              children: [
                {
                  id: 3802,
                  name: 'Foundation Slab [215607]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.39620098735081827,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2773406911455716,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 720.0000000000001,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 550.2791490983609,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.021 - Drinks vending machine - hot and cold beverages and chilled water N10/346',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3812,
              name: 'G.021 - Water cooler - staff/visitor, plumbed N12/466',
              children: [
                {
                  id: 3813,
                  name: 'Foundation Slab [215729]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.1588810568548575,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.11121673979839976,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 398.59025535808223,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 398.6074790316297,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.021 - Water cooler - staff/visitor, plumbed N12/466',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3823,
              name: 'G.021 - Chair - upholstered linear seating - 20 No',
              children: [
                {
                  id: 3824,
                  name: 'Foundation Slab [215848]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.188708577444778,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.8377417154889828,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 3521.523684712333,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1876.0150230825245,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4791,
                  name: 'Foundation Slab [230574]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.393527368201573,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.2787054736403562,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 3395.628868308071,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3605.1042123357183,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.021 - Chair - upholstered linear seating - 20 No',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3938,
              name: 'G.024 - Safe - small - 630x540x540',
              children: [
                {
                  id: 3939,
                  name: 'Foundation Slab [218385]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.2914307584974959,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.05828615169950107,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 539.6865898102109,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 540.0000000000358,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.024 - Safe - small - 630x540x540',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3949,
              name: 'G.024 - Safe - large 1796x903x777',
              children: [
                {
                  id: 3950,
                  name: 'Foundation Slab [218494]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.3584559120507371,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.07169118241014975,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 472.6520520065583,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 758.3927976806153,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.024 - Safe - large 1796x903x777',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3960,
              name: 'G.024 - Safe - large - 1800x760x470',
              children: [
                {
                  id: 3961,
                  name: 'Foundation Slab [218599]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7139474523282251,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.14278949046564965,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 776.0298394871982,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 920.0000000000092,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.024 - Safe - large - 1800x760x470',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4002,
              name: 'G.004 "on / off" rollers - xray machine N10/363',
              children: [
                {
                  id: 4003,
                  name: 'Foundation Slab [219217]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.9346316738356949,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1869263347671451,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1463.8744699417002,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 638.4643581310148,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4747,
                  name: 'Foundation Slab [230420]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.9398003995401208,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.18796007990803032,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1463.8744699417002,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 641.9952112270591,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4756,
                  name: 'Foundation Slab [230468]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.896953318528483,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.37938451019629915,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1499.1485535548386,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2102.3388280726995,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4768,
                  name: 'Foundation Slab [230518]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.8917845928239612,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.3783569185648046,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2102.338828072659,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1499.148553554771,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.004 "on / off" rollers - xray machine N10/363',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4013,
              name: 'G.004 - X-ray search machine N10/362',
              children: [
                {
                  id: 4014,
                  name: 'Foundation Slab [219684]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.0170138558623638,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.20340277117247937,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1199.8854553065726,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 868.1864905549035,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4737,
                  name: 'Foundation Slab [230385]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.9772068638500081,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.19544137277000798,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1202.7439687173312,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 844.339555065142,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 200,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.004 - X-ray search machine N10/362',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4028,
              name: 'G.004 - Security Portal N10/360',
              children: [
                {
                  id: 4029,
                  name: 'Foundation Slab [220056]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.0012587069807257,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.7008810948865042,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 11164.24746350917,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 607.2255591512046,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.004 - Security Portal N10/360',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4052,
              name: 'G.004 - turnstile L20/700',
              children: [
                {
                  id: 4053,
                  name: 'Foundation Slab [220478]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.1156478616548073,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.7809535031583615,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 876.1154743740292,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1273.4027582972778,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4703,
                  name: 'Foundation Slab [230229]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.9619912676183048,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6733938873328101,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 803.3249339084808,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1199.9953936638985,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4712,
                  name: 'Foundation Slab [230266]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.9533018808026982,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6673113165618858,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1148.9455431527708,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 839.5589279633649,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4721,
                  name: 'Foundation Slab [230305]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.9093351139655076,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6365345797758524,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 903.800365938977,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1033.635778178833,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.004 - turnstile L20/700',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4088,
              name: 'G.034 - Electronic searching equipment (motion detector), 999 No',
              children: [
                {
                  id: 4089,
                  name: 'Foundation Slab [221393]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7291057885663454,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5103740519964394,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 911.3822357079533,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 800.0000000000092,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: 'G.034 - Electronic searching equipment (motion detector), 999 No',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4465,
              name: '1.016 - Safe - medium - 790x640x660',
              children: [
                {
                  id: 4466,
                  name: 'Foundation Slab [227409]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.4091999999999992,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.28643999999999814,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 660.0000000000041,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 620.0000000000188,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 700,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '1.016 - Safe - medium - 790x640x660',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Foundation Slab',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Structural Foundations',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Structural Foundations',
          displayCategory: '__category__',
        },
      ],
    },
    {
      id: 3471,
      name: 'Floors',
      children: [
        {
          id: 3472,
          name: 'Floor',
          children: [
            {
              id: 3474,
              name: '03.04.30.001.05_G.005 - Shelves - to suit layout N10/160-401 Entrance Building',
              children: [
                {
                  id: 3475,
                  name: 'Floor [205275]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.43999999999999817,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.04399999999999743,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.30.001.05_G.005 - Shelves - to suit layout N10/160-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3483,
              name: '03.04.30.001.06_G.005 - Worktop desking to suit layout N10/202-401 Entrance Building',
              children: [
                {
                  id: 3484,
                  name: 'Floor [205414]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.090523141683554,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.20905253479604938,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3537,
                  name: 'Floor [209140]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.796922836585301,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6796910498994234,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4863,
                  name: 'Floor [230922]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 7.013247891156665,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.7013376244725292,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.30.001.06_G.005 - Worktop desking to suit layout N10/202-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3602,
              name: '03.04.33.002.01_G.011 - Worktop - to suit layout N10/202-401 Entrance Building',
              children: [
                {
                  id: 3603,
                  name: 'Floor [210477]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.004380407917902,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.20043804079177932,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.33.002.01_G.011 - Worktop - to suit layout N10/202-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3619,
              name: '03.04.22.001.01_G.010 - Worktop - to suit layout N10/202-401 Entrance Building',
              children: [
                {
                  id: 3620,
                  name: 'Floor [210641]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.7097192717409568,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.17097192717408644,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.22.001.01_G.010 - Worktop - to suit layout N10/202-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3631,
              name: '03.04.28.002.01_G.006 - Shelves - 2 over desk, to suit layout N10/161-401 Entrance Building',
              children: [
                {
                  id: 3632,
                  name: 'Floor [210947]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.0124772135062408,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.10124772135061848,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.28.002.01_G.006 - Shelves - 2 over desk, to suit layout N10/161-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3644,
              name: '03.04.28.002.01_G.006 - Shelves - 2 over desk, to suit layout N10/161 2-401 Entrance Building',
              children: [
                {
                  id: 3645,
                  name: 'Floor [211242]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5098603776674838,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.05098603776674562,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4889,
                  name: 'Floor [230994]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.50261683583856,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.050261683583853266,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.28.002.01_G.006 - Shelves - 2 over desk, to suit layout N10/161 2-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3668,
              name:
                '03.04.28.002.01_G.006 - Office furniture (open/modular) to include desk, side pedestal, tackable desk screen-401 Entrance Building',
              children: [
                {
                  id: 3669,
                  name: 'Floor [211656]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.22572770099718095,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.02257277009971688,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4821,
                  name: 'Floor [230697]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.749932116440097,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.17499302241125878,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4832,
                  name: 'Floor [230742]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.2390184019124283,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.023901840191241537,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4839,
                  name: 'Floor [230801]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.6949980089336623,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1694996776599727,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.28.002.01_G.006 - Office furniture (open/modular) to include desk, side pedestal, tackable desk screen-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3677,
              name: '03.04.28.004.01_G.018 - Shelves - 2 over desk, to suit layout N10/161-401 Entrance Building',
              children: [
                {
                  id: 3678,
                  name: 'Floor [212437]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.38663358182042856,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.038663358182040766,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.28.004.01_G.018 - Shelves - 2 over desk, to suit layout N10/161-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3686,
              name: '03.04.28.004.01_G.018 - Shelves - 2 over desk, to suit layout N10/161 2-401 Entrance Building',
              children: [
                {
                  id: 3687,
                  name: 'Floor [212571]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.3866335818204949,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.038663358182047414,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.28.004.01_G.018 - Shelves - 2 over desk, to suit layout N10/161 2-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3695,
              name: '03.04.28.004.01_G.018 - Desk-401 Entrance Building',
              children: [
                {
                  id: 3696,
                  name: 'Floor [212699]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.4060195742636405,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.14060099074879145,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.004.01_G.018 - Desk-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3845,
              name: '03.04.28.007.01_G.023 - Shelves - 2 over desk, to suit layout N10/161-401 Entrance Building',
              children: [
                {
                  id: 3846,
                  name: 'Floor [216617]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.3651225452576859,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.18256127262884572,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 500,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3879,
                  name: 'Floor [217217]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.2567435346037361,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6283707715525385,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 500,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4684,
                  name: 'Floor [230150]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.3651225452576859,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.18256127262884572,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 500,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4691,
                  name: 'Floor [230184]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.355479494887467,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6777388591748502,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 500,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.28.007.01_G.023 - Shelves - 2 over desk, to suit layout N10/161-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3854,
              name: '03.04.28.007.01_G.023 - Shelves - 2 over desk, to suit layout N10/161 2-401 Entrance Building',
              children: [
                {
                  id: 3855,
                  name: 'Floor [216894]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.36511993051628067,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.18255996525814314,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 500,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4677,
                  name: 'Floor [230115]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.47126224870656713,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.23563112435328712,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 500,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.28.007.01_G.023 - Shelves - 2 over desk, to suit layout N10/161 2-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3895,
              name: '03.04.28.008.01_G.024 - Shelves - to suit layout N10/160-401 Entrance Building',
              children: [
                {
                  id: 3896,
                  name: 'Floor [217799]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.4842167333122407,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.04842167333122146,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.008.01_G.024 - Shelves - to suit layout N10/160-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3912,
              name: '03.04.28.008.01_G.024 - Desk-401 Entrance Building',
              children: [
                {
                  id: 3913,
                  name: 'Floor [217988]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.3313210310302848,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1331315167381883,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.008.01_G.024 - Desk-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3929,
              name: '03.04.28.008.01_G.024 - Work bench-401 Entrance Building',
              children: [
                {
                  id: 3930,
                  name: 'Floor [218275]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.165579955473485,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.11655799554734218,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.008.01_G.024 - Work bench-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3977,
              name: '03.04.28.009.01_G.026 - Worktop desking to suit layout N10/202-401 Entrance Building',
              children: [
                {
                  id: 3978,
                  name: 'Floor [218772]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.8658554389989288,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.18658529389137635,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.28.009.01_G.026 - Worktop desking to suit layout N10/202-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4043,
              name: '03.04.33.001.01_G.004 - Tray roller N10/363-401 Entrance Building',
              children: [
                {
                  id: 4044,
                  name: 'Floor [220266]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.8603893582076361,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.08603893582075896,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4730,
                  name: 'Floor [230353]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.30413018001907,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.13041301800189997,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.33.001.01_G.004 - Tray roller N10/363-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4123,
              name: '03.04.30.008.01_1.001 - Worktop desking to suit layout N10/202-401 Entrance Building',
              children: [
                {
                  id: 4124,
                  name: 'Floor [221714]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 8.731568895717771,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.8730111798580653,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.30.008.01_1.001 - Worktop desking to suit layout N10/202-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4150,
              name: '03.04.30.008.01_1.001 - Worktop desking to suit layout N10/202 2-401 Entrance Building',
              children: [
                {
                  id: 4151,
                  name: 'Floor [222826]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.0979277957786917,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.30979277957785245,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.30.008.01_1.001 - Worktop desking to suit layout N10/202 2-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4161,
              name: '03.04.00.002.01_1.004 - Shelves - to suit layout N10/160-401 Entrance Building',
              children: [
                {
                  id: 4162,
                  name: 'Floor [223030]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.513126546014435,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2513126546014299,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.00.002.01_1.004 - Shelves - to suit layout N10/160-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4186,
              name: '03.04.33.003.01_1.006 - Shelves - to suit layout N10/160-401 Entrance Building',
              children: [
                {
                  id: 4187,
                  name: 'Floor [223390]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.7002245755188077,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.27002245755186605,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.33.003.01_1.006 - Shelves - to suit layout N10/160-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4199,
              name: '1.008 - Worktop - to suit layout N10/202',
              children: [
                {
                  id: 4200,
                  name: 'Floor [223577]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.46299283764716,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.24629928376470273,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '1.008 - Worktop - to suit layout N10/202',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4325,
              name: '1.012 - Racking for 40 towels N10/158',
              children: [
                {
                  id: 4326,
                  name: 'Floor [225472]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.3350602499830094,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.033506024998299126,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '1.012 - Racking for 40 towels N10/158',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4355,
              name: '03.04.30.009.01_1.012 - Racking for drying boots N10/158-401 Entrance Building',
              children: [
                {
                  id: 4356,
                  name: 'Floor [225911]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7236824817231216,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.07236824817230823,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4363,
                  name: 'Floor [226035]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7185868801222376,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.07185868801221988,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.30.009.01_1.012 - Racking for drying boots N10/158-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4417,
              name: '03.04.28.010.01_1.015 - Shelves - 2 over desk, to suit layout N10/161-401 Entrance Building',
              children: [
                {
                  id: 4418,
                  name: 'Floor [226344]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7581959893277339,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0758195989327693,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4651,
                  name: 'Floor [229995]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7226650563336464,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.07226650563336075,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.28.010.01_1.015 - Shelves - 2 over desk, to suit layout N10/161-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4435,
              name:
                '03.04.28.010.01_1.015 - Office furniture (open/modular) to include desk, side pedestal, tackable desk screen-401 Entrance Building',
              children: [
                {
                  id: 4436,
                  name: 'Floor [226639]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.7722248981414557,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.17722233826277892,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4639,
                  name: 'Floor [229951]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.7497239517518641,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1749727439677896,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.04.28.010.01_1.015 - Office furniture (open/modular) to include desk, side pedestal, tackable desk screen-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4452,
              name: '03.04.30.011.01_1.016 - Shelves - to suit layout N10/160-401 Entrance Building',
              children: [
                {
                  id: 4453,
                  name: 'Floor [227129]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.3529633419395073,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.23529633419393803,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.30.011.01_1.016 - Shelves - to suit layout N10/160-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4476,
              name: '03.04.05.001.01_1.021 - Shelves - to suit layout N10/160-401 Entrance Building',
              children: [
                {
                  id: 4477,
                  name: 'Floor [227561]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5648302351528618,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.05648302351528311,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.05.001.01_1.021 - Shelves - to suit layout N10/160-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4487,
              name: '03.04.05.001.01_1.021 - Shelves - to suit layout N10/160 2-401 Entrance Building',
              children: [
                {
                  id: 4488,
                  name: 'Floor [227766]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5648307320314364,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.05648307320314059,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.05.001.01_1.021 - Shelves - to suit layout N10/160 2-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4519,
              name: '03.04.32.001.01_1.026 - Worktop - to suit layout N10/202-401 Entrance Building',
              children: [
                {
                  id: 4520,
                  name: 'Floor [228152]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.22941710422757,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.22294171042274485,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.04.32.001.01_1.026 - Worktop - to suit layout N10/202-401 Entrance Building',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4549,
              name: '1.029 - Shelves - to suit layout N10/160',
              children: [
                {
                  id: 4550,
                  name: 'Floor [228434]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.8736000000000499,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.08736000000000027,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '1.029 - Shelves - to suit layout N10/160',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4605,
              name: '1.030 - Shelves - 2 over desk, to suit layout N10/161',
              children: [
                {
                  id: 4606,
                  name: 'Floor [229038]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.4165809503372179,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.041658095033719526,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4658,
                  name: 'Floor [230027]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.3959846206602461,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.03959846206602247,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '1.030 - Shelves - 2 over desk, to suit layout N10/161',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4623,
              name: '1.030 - Office furniture (open/modular) to include desk, side pedestal, tackable desk screen',
              children: [
                {
                  id: 4624,
                  name: 'Floor [229426]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.738316485498128,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1738320351530251,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4665,
                  name: 'Floor [230068]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.6344926786894038,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1634497142112022,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '1.030 - Office furniture (open/modular) to include desk, side pedestal, tackable desk screen',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Floor',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Floors',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Floors',
          displayCategory: '__category__',
        },
      ],
    },
  ],
  properties: [
    {
      displayName: 'Category',
      displayValue: 'Revit Document',
      displayCategory: '__category__',
    },
  ],
}

export const FFETakeOffData: TakeOffItem[] = parseTakeOffData(ModelFFEData)

export const FFETakeOffTableData: TakeOffTableItem[] = FFETakeOffData.map((t) =>
  convertToTableItem(t, 'BHR-R5D-401-00-M3-X-0008-D1-03_Entrance Building - FFE.rvt')
)
