import {
  SterlingTableHeader,
  SterlingTableHeaderProps,
} from '../../basic/table/sterling-table/header/SterlingTableHeader'
import React, { FunctionComponent } from 'react'
import { ExportOutlined, ImportOutlined, PlusSquareOutlined } from '@ant-design/icons/lib'
import { SpellCheckCustom, TextWrappingCustom } from '../../../assets/icons/icons'
import { Divider, Button } from 'antd'
import { valuations } from './Valuations.pdf'

interface ValuationsHeaderProps {
  tableId: string
  onValuationAdd: () => void
  sterlingHeaderProps: SterlingTableHeaderProps
}

const ValuationsHeader: FunctionComponent<ValuationsHeaderProps> = ({ sterlingHeaderProps, onValuationAdd }) => {
  return (
    <SterlingTableHeader {...sterlingHeaderProps}>
      <Divider type={'vertical'} />
      <Button type="text" shape="circle" icon={<ImportOutlined />} />
      <Button type="text" shape="circle" icon={<ExportOutlined />} />
      <Divider type={'vertical'} />
      <Button onClick={onValuationAdd} type="text" shape="circle" icon={<PlusSquareOutlined />} />
      <Button type="text" shape="circle" icon={<TextWrappingCustom />} />
      <Button type="text" shape="circle" icon={<SpellCheckCustom />} />
      <Button type="ghost" download={'Valuations.pdf'} href={valuations}>
        Generate Payment Application
      </Button>
    </SterlingTableHeader>
  )
}

export default ValuationsHeader
