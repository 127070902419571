import React from 'react'
import { useDrag, useDrop } from 'react-dnd'

export const DraggableBodyRow = ({
  index,
  record,
  moveRow,
  className,
  style,
  dndType,
  ...restProps
}: {
  index: number
  record: any
  moveRow: (a: number, b: any) => void
  className: string
  style: React.CSSProperties
  dndType: string
}) => {
  const ref = React.useRef<HTMLTableRowElement | null>(null)
  const [, drag] = useDrag({
    item: { type: dndType, key: record.key, record, moveRow },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      }
    },
  })
  drag(ref)
  return (
    <>
      <tr ref={ref} className={className} style={{ cursor: 'move', ...style }} {...restProps} />
    </>
  )
}

export const DroppableBodyRow = ({
  index,
  moveRow,
  className,
  record,
  style,
  dndType,
  ...restProps
}: {
  index: number
  moveRow: (a: number, b: number) => void
  className: string
  record: any
  style: React.CSSProperties
  dndType: string
}) => {
  const ref = React.useRef<HTMLTableRowElement | null>(null)
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: dndType,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {}
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      }
    },
    drop: (item) => {
      if (moveRow) {
        // @ts-ignore
        item.moveRow(item.key, [item.record, record])
        // @ts-ignore
        moveRow(record?.key, [item?.record, record])
      }
    },
  })
  drop(ref)
  return (
    <>
      <tr
        style={{ backgroundColor: isOver ? 'rgba(3,0,255,0.4)' : 'white', ...style }}
        ref={ref}
        className={`${className} ${isOver ? dropClassName : ''}`}
        // @ts-ignore
        {...restProps}
      />
    </>
  )
}
