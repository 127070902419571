import { Radio } from 'antd'
import React from 'react'
import { Entry } from '../../basic/table/sterling-table/SterlingTable'

export interface SettingsEntry {
  key: number
  id: number
  cost_plan: string
  documentation: string
  take_off: string
  schedule: string | number | undefined
  valuations: string
  libraries: string
}

export const settingsData: SettingsEntry[] = [
  {
    key: 2,
    id: 2,
    cost_plan: '',
    take_off: '',
    documentation: '',
    schedule: '',
    valuations: '',
    libraries: '',
  },
]

export interface settingsRevisionsItems extends Entry {
  id: number
  key: number
  parent: undefined
  children: undefined
  revision_number: string
  revision_name: string
  note: string
  project_value: string
  creator: string
  creation_date: string
  editor: string
  last_update: string
}

export const settingsRevisionsData: settingsRevisionsItems[] = [
  {
    id: 1,
    key: 1,
    parent: undefined,
    children: undefined,
    revision_number: 'ProjectCode_RevisionNumber RD123464_01',
    revision_name: 'ProjectName_RevisionNumber M69 Bramcote Bridge_01',
    note: 'This is an option with 900 mm piles',
    project_value: '£13 000 000.00',
    creator: 'admin',
    creation_date: '12-Jun-2020',
    editor: 'admin',
    last_update: '12-Jun-2020',
  },
  {
    id: 2,
    key: 2,
    parent: undefined,
    children: undefined,
    revision_number: 'ProjectCode_RevisionNumber RD123464_02',
    revision_name: 'ProjectName_RevisionNumber M69 Bramcote Bridge_02',
    note: 'This is an option with 900 mm piles',
    project_value: '£15 000 000.00',
    creator: 'admin',
    creation_date: '20-Jun-2020',
    editor: 'admin',
    last_update: '20-Jun-2020',
  },
  {
    id: 3,
    key: 3,
    parent: undefined,
    children: undefined,
    revision_number: 'ProjectCode_RevisionNumber RD123464_03',
    revision_name: 'ProjectName_RevisionNumber M69 Bramcote Bridge_03',
    note: 'This is an option with 900 mm piles',
    project_value: '£17 000 000.00',
    creator: 'admin',
    creation_date: '25-Jun-2020',
    editor: 'admin',
    last_update: '25-Jun-2020',
  },
]
