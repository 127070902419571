import URLSearchParams from 'url-search-params'

class ForgeAuthServiceClass {
  getAccessToken(callback: (token: string, expiresIn: number) => void) {
    const searchParams = new URLSearchParams()
    const params = {
      scope: 'code:all data:write data:read bucket:create bucket:delete bucket:read',
      grant_type: 'client_credentials',
      client_id: 'q1x8BcOe1Jt4SOGWeBkwFawgep39enh3',
      client_secret: 'GNGeDGtfxJw1dxTy',
    }
    const devParams = {
      // needed for storybook
      scope: 'code:all data:write data:read bucket:create bucket:delete bucket:read',
      grant_type: 'client_credentials',
      client_id: 'OVytTmEgHtKmK1cKYhfHPj3SlPoApQGX',
      client_secret: 'KVi8Oyo7OMuq2JZ1',
    }
    for (const [key, value] of Object.entries(params)) {
      searchParams.set(key, value)
    }
    fetch('https://developer.api.autodesk.com/authentication/v1/authenticate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: searchParams,
    })
      .then((r) => r.json())
      .then((d) => {
        callback(d.access_token, d.expires_in)
      })
  }
}

export const ForgeAuthService = new ForgeAuthServiceClass()
