import { NodePropertiesLoader } from './NodePropertiesLoader'
import { ModelNode, ModelNodeProperty } from '../ForgeViewer.model'

export class ModelTreeLoader {
  tree: Autodesk.Viewing.InstanceTree
  propertiesLoader: NodePropertiesLoader
  nodesCount: number = 0
  modelNodes: { [id: number]: ModelNode } = {}
  onModelTreeLoaded?: (root: ModelNode) => void

  constructor(tree: Autodesk.Viewing.InstanceTree, propertiesLoader: NodePropertiesLoader) {
    this.tree = tree
    this.propertiesLoader = propertiesLoader
    this.loadNode = this.loadNode.bind(this)
    this.load = this.load.bind(this)
    this.loadNodeDetails = this.loadNodeDetails.bind(this)
  }

  private readonly loadNode = (id: number) => {
    this.propertiesLoader.loadProperties(
      id,
      (properties, guid) => this.loadNodeDetails(id, properties, guid),
      (err) => {
        // tslint:disable-next-line:no-console
        console.error('Error while loading model' + err)
      }
    )
  }

  private readonly loadNodeDetails = (id: number, properties: ModelNodeProperty[], guid: string | undefined): void => {
    // @ts-ignore
    const name = this.tree.getNodeName(id)
    const parentId = this.tree.getNodeParentId(id)
    const node = {
      id,
      name,
      children: [],
      properties,
      guid,
    }
    this.modelNodes[id] = node
    if (parentId) this.modelNodes[parentId].children.push(node)

    this.nodesCount -= 1 // one node loaded
    this.nodesCount += this.tree.getChildCount(id) // child nodes need to be loaded
    this.tree.enumNodeChildren(id, (childId: number) => this.loadNode(childId), false)

    if (this.nodesCount === 0 && this.onModelTreeLoaded) {
      this.onModelTreeLoaded(this.modelNodes[this.tree.getRootId()])
    }
  }

  load = (onModelTreeLoaded: (root: ModelNode) => void) => {
    this.nodesCount = 0
    this.modelNodes = {}
    this.onModelTreeLoaded = onModelTreeLoaded
    this.nodesCount += 1 // root node
    this.loadNode(this.tree.getRootId())
  }
}
