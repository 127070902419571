import React from 'react'
import { ExtendedColumnType } from '../../../basic/table/sterling-table/SterlingTable'
import { currency, renderDecimal } from '../../../../utils/rendering/Rendering'
import { identity } from '../../../../utils/FunctionalUtils'
import Tag from 'antd/lib/tag'
import { numberSorter, stringSorter } from '../../../../utils/Sorters'

export const WorkPackagesDetailsColumnsFor = (quote_id: number): ExtendedColumnType[] => {
  return [
    {
      title: 'Qty',
      dataIndex: `quantity`,
      key: `quantity`,
      width: 75,
      sorter: (a, b) => numberSorter(a.quantity, b.quantity),
      sortDirections: ['ascend', 'descend'],
      editable: false,
      render: renderDecimal,
    },
    {
      title: 'Unit',
      dataIndex: `unit`,
      key: `unit`,
      width: 75,
      sorter: (a, b) => stringSorter(a.unit, b.unit),
      sortDirections: ['ascend', 'descend'],
      editable: false,
      render: identity,
    },
    {
      title: 'Prop Rate',
      dataIndex: `proposed_rate_${quote_id}`,
      key: `proposed_rate_${quote_id}`,
      width: 75,
      sorter: (a, b) => numberSorter(a[`proposed_rate_${quote_id}`], b[`proposed_rate_${quote_id}`]),
      sortDirections: ['ascend', 'descend'],
      editable: true,
      render: currency,
    },
    {
      title: 'Prop Total',
      dataIndex: `proposed_total_${quote_id}`,
      key: `proposed_total_${quote_id}`,
      width: 75,
      sorter: (a, b) => numberSorter(a[`proposed_total_${quote_id}`], b[`proposed_total_${quote_id}`]),
      sortDirections: ['ascend', 'descend'],
      editable: false,
      render: currency,
    },
    {
      title: 'Total Cost Difference',
      dataIndex: `total_cost_difference_${quote_id}`,
      key: `total_cost_difference_${quote_id}`,
      width: 100,
      sorter: (a, b) => numberSorter(a[`total_cost_difference_${quote_id}`], b[`total_cost_difference_${quote_id}`]),
      sortDirections: ['ascend', 'descend'],
      editable: false,
      render: (t) => (t ? <Tag color={t >= 0 ? 'green' : 'red'}>{currency(t)}</Tag> : currency(t)),
    },
  ]
}
