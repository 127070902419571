const unit = 'm'
const precisionIndex = 3

export class MeasurementsReader {
  extProvider: () => any

  constructor(extProvider: () => any) {
    this.extProvider = extProvider
  }

  getCurrentMeasurement = () => this.extProvider().getMeasurement(unit, precisionIndex)

  getMeasurementList = () => this.extProvider().getMeasurementList(unit, precisionIndex)
}
