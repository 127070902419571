import { TakeOffDetailsItem, TakeOffItem } from './TakeOffDataParser'
import { TakeOffDetailsTableItem, TakeOffTableItem } from '../TakeOffTables.model'

export const convertToTableItem = (
  item: TakeOffItem,
  sourceFilename: string,
  takeOffExternalType?: string
): TakeOffTableItem => {
  return {
    id: item.id,
    key: item.id,
    code: item.code,
    name: item.name,
    takeOffType: item.takeOffType,
    takeOffExternalType: takeOffExternalType || 'BIM',
    count: item.children.length,
    mappedToBoQ: 'Yes',
    sourceDocument: sourceFilename,
    unit: '',
    documentType: '',
    filename: sourceFilename,
    publishedBy: 'Admin',
    publishDate: '01.01.2020',
    updatedBy: 'Trevor Sharp',
    updateDate: '01.04.2020',
    parent: undefined,
    children: undefined,
  }
}

export const convertToTableDetailsItem = (item: TakeOffDetailsItem): TakeOffDetailsTableItem => {
  return Object.assign({}, item, {
    id: item.id,
    key: item.id,
    parent: undefined,
    children: undefined,
  })
}
