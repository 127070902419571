import { SyncOutlined, MoreOutlined, PlusOutlined, PlusSquareOutlined, ArrowsAltOutlined } from '@ant-design/icons'
import React from 'react'
import { Button, Divider, Tooltip } from 'antd'
import {
  SterlingTableHeaderProps,
  SterlingTableHeader,
} from '../../../basic/table/sterling-table/header/SterlingTableHeader'
import { GroupByCustom, TextWrappingCustom } from '../../../../assets/icons/icons'

export interface DocumentationHeaderProps {
  documentationData: any[]
  sterlingTableHeaderProps: SterlingTableHeaderProps
  children?: any[]
  openWindow?: () => void
}

export const DocumentationHeader = ({
  documentationData,
  children,
  sterlingTableHeaderProps,
  openWindow,
}: DocumentationHeaderProps) => {
  return (
    <SterlingTableHeader {...sterlingTableHeaderProps} columnsLayoutManagerVisibility={true}>
      <Tooltip title="Group by">
        <Button type="text" shape="circle" icon={<GroupByCustom />} />
      </Tooltip>
      <Tooltip title="Refresh">
        <Button type="text" shape="circle" icon={<SyncOutlined />} />
      </Tooltip>
      <Divider type="vertical" />
      <Tooltip title="Wrap text">
        <Button type="text" shape="circle" icon={<TextWrappingCustom />} />
      </Tooltip>
      <Tooltip title="Add row">
        <Button type="text" shape="circle" icon={<PlusSquareOutlined />} />
      </Tooltip>
      <Divider type="vertical" />
      <Tooltip title="More">
        <Button type="text" shape="circle" icon={<MoreOutlined />} />
      </Tooltip>
      {children}
    </SterlingTableHeader>
  )
}
