import React from 'react'
import Modal from 'antd/lib/modal/Modal'
import { Button, Row, Col, Select } from 'antd'
import { portfolioData } from '../../../../data/Portfolio.data'

const { Option } = Select

export interface AdministrationModalProps {
  visibility: boolean
  hideModal: () => void
  handleClick?: () => void // () => addNewProjectToList
}

export default function AdministrationModal({ visibility, hideModal, handleClick }: AdministrationModalProps) {
  return (
    <Modal
      width={'500px'}
      title="Add project"
      visible={visibility}
      destroyOnClose={false}
      onCancel={hideModal}
      footer={[
        <Button key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => {}}>
          Add
        </Button>,
      ]}
    >
      <div className="modal-selector-wrapper" style={{ width: '100%', display: 'block' }}>
        <Row>
          <Col>
            <p style={{ paddingRight: '10px' }}>Project: </p>
          </Col>
          <Col>
            <Select defaultValue="Select" style={{ width: 120 }}>
              {portfolioData.map(({ name }) => (
                <Option key={name} value={`${name}`}>{`${name}`}</Option>
              ))}
            </Select>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}
