import React from 'react'

import { Tabs } from 'antd'
import ProjectTemplate from '../project/ProjectTemplate'
import WorkPackagesOverview from './parts/WorkPackagesOverview'
import DragSortingTable from './parts/WorkPackagesMapping'
import WorkPackagesDetails from './parts/WorkPackagesDetails'
import BreadcrumbPanel from '../../basic/breadcrumb/BreadcrumbPanel'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../context/reducer'
import NewWindow from '../../basic/new-window/NewWindow'
import ReplacementPane from '../../basic/new-window/ReplacementPane'
import { setOpenWindowState } from '../../../context/open-windows/OpenWindows.actions'
import { newWindowHeight, newWindowWidth } from '../../../constants/sizes'

const TabPane = Tabs.TabPane

function WorkPackages(): JSX.Element {
  return (
    <ProjectTemplate selectedKey={'work-packages'}>
      <WorkPackagesPart />
    </ProjectTemplate>
  )
}

function WorkPackagesPart(): JSX.Element {
  const dispatch = useDispatch()

  const getOpenWindow = (id: string) => (state: RootState) => state.openWindows.windows.get(id) || false
  const workPackagesWindowId = 'workPackages'
  const isworkPackagesWindowOpen = useSelector(getOpenWindow(workPackagesWindowId))

  return (
    <>
      <BreadcrumbPanel
        moveTo={'work-packages'}
        displayName={'Work Packages'}
        categoryName={'Sample Project'}
        openWindow={() => dispatch(setOpenWindowState(workPackagesWindowId, true))}
      />
      <NewWindow
        height={newWindowHeight}
        width={newWindowWidth}
        isOpen={isworkPackagesWindowOpen}
        onPortalClose={() => dispatch(setOpenWindowState(workPackagesWindowId, false))}
        replacementPane={
          <ReplacementPane
            onHide={() => dispatch(setOpenWindowState(workPackagesWindowId, false))}
            onWindowShow={() => {}}
            style={{ width: '100%', height: 450, backgroundColor: '#fff' }}
          />
        }
      >
        <Tabs defaultActiveKey="overview">
          <TabPane tab={'Overview'} key="overview">
            <WorkPackagesOverview />
          </TabPane>
          <TabPane tab={'Mapping'} key="mapping">
            <DragSortingTable />
          </TabPane>
          <TabPane tab={'Details'} key="details">
            <WorkPackagesDetails />
          </TabPane>
        </Tabs>
      </NewWindow>
    </>
  )
}

export default WorkPackages
