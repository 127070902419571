import { numberSorter, stringSorter } from '../../../../../utils/Sorters'
import { ExtendedColumnType } from '../../../../basic/table/sterling-table/SterlingTable'
import { currency } from '../../../../../utils/rendering/Rendering'

export const subcontractorsColumns: ExtendedColumnType[] = [
  {
    title: 'Item Code',
    dataIndex: 'itemCode',
    key: 'itemCode',
    width: 240,
    fixed: 'left',
    sorter: (a, b) => stringSorter(a.itemCode, b.itemCode),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'ascend',
    editable: true,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    fixed: 'left',
    width: 240,
    sorter: (a, b) => stringSorter(a.title, b.title),
    sortDirections: ['ascend', 'descend'],
    editable: true,
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    width: 130,
    sorter: (a, b) => stringSorter(a.notes, b.notes),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    width: 130,
    sorter: (a, b) => stringSorter(a.unit, b.unit),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Unit Rate',
    dataIndex: 'unitRate',
    key: 'unitRate',
    width: 130,
    sorter: (a, b) => numberSorter(a.unitRate, b.unitRate),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    align: 'right',
    render: currency,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    width: 130,
    sorter: (a, b) => stringSorter(a.currency, b.currency),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Company',
    dataIndex: 'company',
    key: 'company',
    width: 130,
    sorter: (a, b) => stringSorter(a.company, b.company),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    width: 130,
    sorter: (a, b) => stringSorter(a.country, b.country),
    sortDirections: ['ascend', 'descend'],
  },
]
