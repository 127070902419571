import { Entry } from '../SterlingTable'

interface TableInputData {
  id: number
}

export function parseData<T extends TableInputData>(data: T[], codeExtractor: (el: T) => string): Entry[] {
  const entries = createEntriesWithoutRelationships(data)

  const codeToEntryIdMap: Map<string, number> = new Map()
  const idToEntryMap: Map<number, Entry> = new Map()
  const idToParentIdMap: Map<number, number> = new Map()
  entries.forEach((el) => {
    idToEntryMap.set(el.id, el)
    codeToEntryIdMap.set(codeExtractor(el) || el.id.toString(), el.id)
  })

  entries.forEach((el) => {
    const parentId = codeToEntryIdMap.get(extractParentCode(codeExtractor(el)))
    if (parentId) {
      idToParentIdMap.set(el.id, parentId)
    }
  })

  data.forEach((el) => {
    const parentId = idToParentIdMap.get(el.id)
    if (parentId) {
      const node = idToEntryMap.get(el.id)
      const parent = idToEntryMap.get(parentId)
      if (node && parent) {
        addRelationship(parent, node)
      }
    }
  })

  return entries
}

function createEntriesWithoutRelationships<T extends TableInputData>(data: T[]): (Entry & T)[] {
  return data.map((el) =>
    Object.assign(
      {},
      {
        key: el.id,
        id: el.id,
        data: el,
        parent: undefined,
        children: undefined,
      },
      el
    )
  )
}

function addRelationship(parent: Entry, children: Entry): void {
  if (parent.children) {
    parent.children.push(children)
  } else {
    parent.children = [children]
  }
  children.parent = parent
}

function extractParentCode(itemCode: string): string {
  const split = (itemCode || '').split('.')
  return split.slice(0, split.length - 1).join('.')
}
