import React from 'react'
import Modal from 'antd/lib/modal/Modal'
import { Button, Row, Col, Select } from 'antd'

export interface DocumentationModalProps {
  visibility: boolean
  hideModal: () => void
  handleClick: () => void
  activeDocument: string
  revisions: any[]
}

export default function DocumentationModal({
  visibility,
  hideModal,
  handleClick,
  activeDocument,
  revisions,
}: DocumentationModalProps) {
  return (
    <Modal
      width={'500px'}
      title="Compare Revisions"
      visible={visibility}
      destroyOnClose={false}
      onCancel={hideModal}
      footer={[
        <Button key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleClick}>
          Compare
        </Button>,
      ]}
    >
      <Row>
        <p>Document name: {activeDocument}</p>
      </Row>
      <div className="modal-selector-wrapper" style={{ width: '100%', display: 'block' }}>
        <Row>
          <Col>
            <p style={{ paddingRight: '10px' }}>Select revisions:</p>
          </Col>
          <Col>
            <Select
              placeholder={'select 1st revision'}
              style={{ width: '124px', marginRight: '10px', marginLeft: '10px' }}
              size="small"
            >
              {revisions.map((c, idx) => (
                <Select.Option key={idx} value={c}>
                  {c}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <p>-</p>
          <Col>
            <Select
              style={{ width: '124px', marginRight: '10px', marginLeft: '10px' }}
              size="small"
              placeholder={'select 2nd revision'}
            >
              {revisions.map((c, idx) => (
                <Select.Option key={idx} value={c}>
                  {c}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}
