import { ExtendedColumnType } from '../../../basic/table/sterling-table/SterlingTable'
import { numberSorter, stringSorter } from '../../../../utils/Sorters'
import { renderDecimal } from '../../../../utils/rendering/Rendering'

export const TakeOffDetailsTableColumns: ExtendedColumnType[] = [
  {
    title: 'Take-Off Object Name',
    dataIndex: 'name',
    key: 'name',
    width: 130,
    fixed: 'left',
    sorter: (a, b) => stringSorter(a.code, b.code),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Type',
    dataIndex: 'takeOffType',
    key: 'takeOffType',
    width: 50,
    sorter: (a, b) => stringSorter(a.takeOffType, b.takeOffType),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Take-off Type',
    dataIndex: 'takeOffExternalType',
    key: 'takeOffExternalType',
    width: 70,
    sorter: (a, b) => stringSorter(a.takeOffExternalType, b.takeOffExternalType),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Count',
    dataIndex: 'count',
    key: 'count',
    width: 50,
    align: 'right',
    sorter: (a, b) => numberSorter(a.count, b.count),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Volume',
    dataIndex: 'volume',
    key: 'volume',
    width: 50,
    align: 'right',
    sorter: (a, b) => numberSorter(a.volume, b.volume),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: (value) => renderDecimal(value),
  },
  {
    title: 'Top Area',
    dataIndex: 'topArea',
    key: 'topArea',
    width: 50,
    align: 'right',
    sorter: (a, b) => numberSorter(a.topArea, b.topArea),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: (value) => renderDecimal(value),
  },
  {
    title: 'Bottom Area',
    dataIndex: 'bottomArea',
    key: 'bottomArea',
    width: 50,
    align: 'right',
    sorter: (a, b) => numberSorter(a.bottomArea, b.bottomArea),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: (value) => renderDecimal(value),
  },
  {
    title: 'Sides Areas',
    dataIndex: 'sideAreas',
    key: 'sideAreas',
    width: 50,
    align: 'right',
    sorter: (a, b) => numberSorter(a.sideAreas, b.sideAreas),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: (value) => renderDecimal(value),
  },
  {
    title: 'Edge Area',
    dataIndex: 'edgeArea',
    key: 'edgeArea',
    width: 50,
    align: 'right',
    sorter: (a, b) => numberSorter(a.edgeArea, b.edgeArea),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: (value) => renderDecimal(value),
  },
  {
    title: 'Edge Length',
    dataIndex: 'edgeLength',
    key: 'edgeLength',
    width: 50,
    align: 'right',
    sorter: (a, b) => numberSorter(a.edgeLength, b.edgeLength),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: (value) => renderDecimal(value),
  },
  {
    title: 'Width',
    dataIndex: 'width',
    key: 'width',
    width: 50,
    align: 'right',
    sorter: (a, b) => numberSorter(a.width, b.width),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: (value) => renderDecimal(value),
  },
  {
    title: 'Height',
    dataIndex: 'height',
    key: 'height',
    width: 50,
    align: 'right',
    sorter: (a, b) => numberSorter(a.height, b.height),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: (value) => renderDecimal(value),
  },
  {
    title: 'Length',
    dataIndex: 'length',
    key: 'length',
    width: 50,
    align: 'right',
    sorter: (a, b) => numberSorter(a.length, b.length),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: (value) => renderDecimal(value),
  },
  {
    title: 'Diameter',
    dataIndex: 'diameter',
    key: 'diameter',
    width: 50,
    align: 'right',
    sorter: (a, b) => numberSorter(a.diameter, b.diameter),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: (value) => renderDecimal(value),
  },
]
