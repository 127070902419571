import React from 'react'
import { Button, Divider, Tooltip } from 'antd'

import { ExportOutlined, ImportOutlined, MoreOutlined, PlusSquareOutlined, SyncOutlined } from '@ant-design/icons/lib'
import {
  SterlingTableHeader,
  SterlingTableHeaderProps,
} from '../../../../basic/table/sterling-table/header/SterlingTableHeader'
import { GroupByCustom, SpellCheckCustom, TextWrappingCustom } from '../../../../../assets/icons/icons'
import { CSVLink } from 'react-csv'

interface LibraryTableHeaderProps {
  baseHeaderProps: SterlingTableHeaderProps
  dataToDownload?: any
  headersToDownload?: any
  filename?: string
}

export function LibraryTableHeader({
  baseHeaderProps,
  dataToDownload,
  headersToDownload,
  filename,
}: LibraryTableHeaderProps) {

  return (
    <SterlingTableHeader {...baseHeaderProps}>
      <Tooltip title="Group by">
        <Button type="text" shape="circle" icon={<GroupByCustom />} />
      </Tooltip>
      <Tooltip title="Refresh">
        <Button type="text" shape="circle" icon={<SyncOutlined />} />
      </Tooltip>
      <Divider type={'vertical'} />
      <Tooltip title="Import">
        <Button type="text" shape="circle" icon={<ImportOutlined />} />
      </Tooltip>
      <Tooltip title="Export">
        <CSVLink headers={headersToDownload} data={dataToDownload} filename={`${filename}.csv`} separator={';'}>
          <Button type="text" shape="circle" icon={<ExportOutlined />} />
        </CSVLink>
      </Tooltip>
      <Divider type={'vertical'} />
      <Tooltip title="Wrap text">
        <Button type="text" shape="circle" icon={<TextWrappingCustom />} />
      </Tooltip>
      <Tooltip title="Check spelling">
        <Button type="text" shape="circle" icon={<SpellCheckCustom />} />
      </Tooltip>
      <Tooltip title="Add row">
        <Button type="text" shape="circle" icon={<PlusSquareOutlined />} />
      </Tooltip>
      <Divider type={'vertical'} />
      <Tooltip title="More">
        <Button type="text" shape="circle" icon={<MoreOutlined />} />
      </Tooltip>
    </SterlingTableHeader>
  )
}
