import { ProjectsState } from './Projects.state'
import { portfolioData } from '../../data/Portfolio.data'
import { ADD_PROJECT, AddProjectAction } from './Projects.actions'

const initialState: ProjectsState = {
  projects: portfolioData,
}

export function projectsReducer(state: ProjectsState = initialState, action: AddProjectAction): ProjectsState {
  switch (action.type) {
    case ADD_PROJECT:
      const newProject = (action as AddProjectAction).project
      return Object.assign({}, state, {
        projects: [newProject].concat(state.projects),
      })
    default:
      return state
  }
}
