import { combineReducers } from 'redux'
import { columnsVisibilityReducer } from './columns-visibility/ColumnsVisibility.reducer'
import { projectsReducer } from './projects/Projects.reducer'
import { sterlingTableReducer } from './resource-library/SterlingTable.reducer'
import { viewSettingsReducer } from './view-settings/ViewSettings.reducer'
import { layoutsReducer } from './layouts/Layouts.reducer'
import { openWindowsReducer } from './open-windows/OpenWindowsReducer'
import { documentationReducer } from './documentation/Documentation.reducer'
import { takeOffReducer } from './take-off/TakeOff.reducer'

export type RootState = ReturnType<typeof rootReducer>

export const rootReducer = combineReducers({
  columnsVisibility: columnsVisibilityReducer,
  documentationSection: documentationReducer,
  projects: projectsReducer,
  sterlingTable: sterlingTableReducer,
  viewSettings: viewSettingsReducer,
  layoutsReducer,
  openWindows: openWindowsReducer,
  takeOffs: takeOffReducer,
})
