import React from 'react'
import { ExtendedColumnType } from '../../../basic/table/sterling-table/SterlingTable'
import { numberSorter, stringSorter } from '../../../../utils/Sorters'
import { Button } from 'antd'
import { EyeFilled, EyeTwoTone } from '@ant-design/icons'
import { identity } from '../../../../utils/FunctionalUtils'
import { SelectConfig } from '../../../basic/table/editable-cell/types/EditableCellTypes'
import { TakeOffTypes } from '../helpers/TakeOffDataParser'

export const TakeOffTableColumnsWithVisibilityManagement = (
  visibleItems: Set<number>,
  showItem: (id: number) => void,
  hideItem: (id: number) => void,
  excludedItems: number[]
): ExtendedColumnType[] =>
  [
    {
      title: '',
      dataIndex: 'eye',
      key: 'eye',
      width: 100,
      fixed: 'left',
      align: 'center',
      editable: false,
      formattingRule: undefined,
      render: (value, record) =>
        !excludedItems.includes(record.id) &&
        (visibleItems.has(record.id) ? (
          <Button type="text" shape="circle" icon={<EyeTwoTone />} onClick={() => hideItem(record.id)} />
        ) : (
          <Button type="text" shape="circle" icon={<EyeFilled />} onClick={() => showItem(record.id)} />
        )),
    } as ExtendedColumnType,
  ].concat(TakeOffTableColumns)

export const TakeOffTableColumns: ExtendedColumnType[] = [
  {
    title: 'Take-Off Code',
    dataIndex: 'code',
    key: 'code',
    width: 180,
    fixed: 'left',
    sorter: (a: any, b: any) => stringSorter(a.code, b.code),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Take-Off Group Name',
    dataIndex: 'name',
    fixed: 'left',
    key: 'name',
    width: 300,
    sorter: (a: any, b: any) => stringSorter(a.name, b.name),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Type',
    dataIndex: 'takeOffType',
    key: 'takeOffType',
    width: 100,
    sorter: (a: any, b: any) => stringSorter(a.takeOffType, b.takeOffType),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    editionConfig: {
      tag: 'select',
      options: TakeOffTypes,
      optionRenderer: identity,
    } as SelectConfig,
  },
  {
    title: 'Take-off Type',
    dataIndex: 'takeOffExternalType',
    key: 'takeOffExternalType',
    width: 120,
    sorter: (a: any, b: any) => stringSorter(a.takeOffExternalType, b.takeOffExternalType),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Count',
    dataIndex: 'count',
    key: 'count',
    width: 70,
    align: 'right',
    sorter: (a: any, b: any) => numberSorter(a.count, b.count),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: (value) => (parseInt(value, 10) !== 0 ? value : ''),
  },
  {
    title: 'Mapped to BoQ',
    dataIndex: 'mappedToBoQ',
    key: 'mappedToBoQ',
    width: 80,
    sorter: (a: any, b: any) => stringSorter(a.mappedToBoQ, b.mappedToBoQ),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Document Type',
    dataIndex: 'documentType',
    key: 'documentType',
    width: 100,
    sorter: (a: any, b: any) => stringSorter(a.documentType, b.documentType),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Source Document',
    dataIndex: 'sourceDocument',
    key: 'sourceDocument',
    width: 300,
    sorter: (a: any, b: any) => stringSorter(a.sourceDocument, b.sourceDocument),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },

  {
    title: 'Filename',
    dataIndex: 'filename',
    key: 'filename',
    width: 300,
    sorter: (a: any, b: any) => stringSorter(a.filename, b.filename),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },

  {
    title: 'Published By',
    dataIndex: 'publishedBy',
    key: 'publishedBy',
    width: 130,
    sorter: (a: any, b: any) => stringSorter(a.publishedBy, b.publishedBy),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },

  {
    title: 'Publish Date',
    dataIndex: 'publishDate',
    key: 'publishDate',
    width: 130,
    sorter: (a: any, b: any) => stringSorter(a.publishDate, b.publishDate),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Updated By',
    dataIndex: 'updatedBy',
    key: 'updatedBy',
    width: 130,
    sorter: (a: any, b: any) => stringSorter(a.updatedBy, b.updatedBy),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Update Date',
    dataIndex: 'updateDate',
    key: 'updateDate',
    width: 130,
    sorter: (a: any, b: any) => stringSorter(a.updateDate, b.updateDate),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },
]

export const TakeOffTableColumnsDisabledEdition: ExtendedColumnType[] = TakeOffTableColumns.map((c) =>
  Object.assign({}, c, { editable: false })
)
