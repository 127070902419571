import { Entry } from '../../components/basic/table/sterling-table/SterlingTable'

export function copyAndFilter<T>(array: T[], predicate: (el: T) => boolean): T[] {
  return Object.assign([], array).filter(predicate)
}

export function copyAndSwap<T extends Entry>(array: T[], item: T): T[] {
  const arrayCopy = [...array]
  const foundIndex = array.findIndex((d) => d && d.key === item.key)
  if (foundIndex >= 0) {
    arrayCopy[foundIndex] = item
    return arrayCopy
  }
  return arrayCopy
}

export const arraysIntersection = <T>(list1: T[], list2: T[]): T[] => list1.filter((x) => list2.includes(x))

export const arraysDifference = <T>(list1: T[], list2: T[]): T[] => list1.filter((x) => !list2.includes(x))

const arraysBasicSum = <T>(array1: T[], array2: T[]): T[] => array1.concat(array2)

export const arraysSum = <T>(...arrays: T[][]): T[] =>
  arrays.reduce((previousValue, currentValue) => arraysBasicSum(previousValue, currentValue), [])

export const arrayItemsSum = (array: number[]): number => array.reduce((sum, current) => sum + current, 0)
