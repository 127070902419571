import React, { Key } from 'react'
import { LibraryTableHeader } from './header/LibraryTableHeader'
import { useDispatch } from 'react-redux'

import {
  addEntry,
  deleteEntries,
  duplicateEntries,
  updateEntry,
} from '../../../../context/resource-library/SterlingTable.actions'
import { ColumnType } from 'antd/lib/table/interface'
import SterlingTable, { Entry } from '../../../basic/table/sterling-table/SterlingTable'
import { ItemsManagementToolbar } from '../../../basic/table/table-selection-toolbar/ItemsManagementToolbar'

interface LibraryTableProps {
  columns: ColumnType<any>[]
  dataSource: Entry[]
  dataToDownload?: any
  headersToDownload?: any
  tableId: string
  emptyItemProvider: () => any
  onSelect?: (rows: Key[]) => void
  tableHeight?: number
  pagination?: false | undefined
  filename?: string
}

export default function LibraryTable({
  columns,
  dataSource,
  dataToDownload,
  headersToDownload,
  filename,
  tableId,
  emptyItemProvider,
  onSelect,
  tableHeight,
  pagination,
}: LibraryTableProps): JSX.Element {
  const dispatch = useDispatch()

  return (
    <div style={{ width: '100%' }}>
      <SterlingTable
        columns={columns}
        dataSource={dataSource}
        tableId={tableId}
        renderHeader={(props) => (
          <LibraryTableHeader
            baseHeaderProps={props}
            dataToDownload={dataToDownload || []}
            headersToDownload={headersToDownload || []}
            filename={filename}
          />
        )}
        renderSelectionToolbar={(props) => (
          <ItemsManagementToolbar
            baseProps={props}
            onAdd={() => dispatch(addEntry(tableId, emptyItemProvider()))}
            onDuplicate={(selectedRows) =>
              dispatch(
                duplicateEntries(
                  tableId,
                  selectedRows.map((key) => key as number)
                )
              )
            }
            onDelete={(selectedRows) =>
              dispatch(
                deleteEntries(
                  tableId,
                  selectedRows.map((key) => key as number)
                )
              )
            }
          />
        )}
        onUpdate={(id, updates) => {
          dispatch(updateEntry(tableId, id, updates))
        }}
        onSelect={onSelect}
        tableHeight={tableHeight}
        defaultPagination={pagination}
        size={'middle'}
      />
    </div>
  )
}
