import { LayoutsState } from './Layouts.state'
import { SET_LAYOUTS, SetLayoutsAction } from './Layouts.actions'

const initialMap = new Map([
  ['cost-plan', { bottomComponentOpened: true, rightSideComponentOpened: true }],
  ['take-off', { bottomComponentOpened: true, rightSideComponentOpened: true }],
  ['activities', { bottomComponentOpened: true, rightSideComponentOpened: true }],
])

const initialState: LayoutsState = {
  layouts: initialMap,
}

export function layoutsReducer(state: LayoutsState = initialState, action: SetLayoutsAction): LayoutsState {
  switch (action.type) {
    case SET_LAYOUTS:
      const { layoutKey, layoutState } = action as SetLayoutsAction
      const mapToUpdate = new Map(state.layouts)
      mapToUpdate.set(layoutKey, layoutState)
      return Object.assign({}, state, {
        layouts: mapToUpdate,
      })
    default:
      return state
  }
}
