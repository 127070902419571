import React, { useState } from 'react'
import { countries } from 'typed-countries'
import { Col, Form, Input, Row, Select } from 'antd'
import { compareByKey } from '../../../../utils/comparators/ArrayComparators'
import AddProjectFormPart from './AddProjectFormPart'
import cities from 'cities.json'

const Location: AddProjectFormPart = ({ form }) => {
  const pureCities = [...new Set(cities as any[])]
  const [countryCode, setCountryCode] = useState('')

  const findCountryCodeByName = (name: any) => setCountryCode(countries.filter((c: any) => c.name === name)[0].iso)

  return (
    <Form form={form} layout={'vertical'}>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item name={'country'} label={'Country'} rules={[{ required: true, message: 'Select country!' }]}>
            <Select showSearch={true} placeholder={'Select country'} onSelect={findCountryCodeByName}>
              {countries.map((c) => (
                <Select.Option key={c.name} value={c.name}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'city'} label={'City'} rules={[{ required: true, message: 'Enter project name!' }]}>
            <Select showSearch={true} placeholder={'Select city'}>
              {pureCities
                .filter((c) => c.country === countryCode)
                .sort(compareByKey('name', true))
                .map((c) => (
                  <Select.Option key={`${c.city}${c.lat}${c.country}`} value={c.name}>
                    {c.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item name={'postcode'} label={'Postcode'} rules={[{ required: true, message: 'Enter postcode!' }]}>
            <Input placeholder={'Enter postcode'} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'county'} label={'County'} rules={[{ required: false }]}>
            <Input placeholder={'Enter county'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={24}>
          <Form.Item name={'address'} label={'Address'} rules={[{ required: false }]}>
            <Input placeholder={'Enter address'} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default Location
