import { TakeOffCostItem } from '../TakeOffCostItem'
import { TakeOffDetailsItem } from '../../../helpers/TakeOffDataParser'
import { arrayItemsSum } from '../../../../../../utils/collections/ArraysUtils'
import { InSituConcreteWallFields } from '../../parts/InSituConcreteWallForm'

export const calculateTakeOffCostItems = (
  data: InSituConcreteWallFields,
  items: TakeOffDetailsItem[]
): TakeOffCostItem[] => {
  const volumeSum = arrayItemsSum(items.map((i) => i.volume || 0))
  const sideAreasSum = arrayItemsSum(items.map((i) => i.sideAreas || 0))

  return [
    createItem(data.location, createItemBasedOnProvision, volumeSum, data.provision),
    createItem(data.location, createItemBasedOnPlacing, volumeSum, data.placing),
    createItem(data.location, createItemBasedOnReinfRatio, volumeSum, data.reinfRatio),
    createItem(data.location, createItemBasedOnFormwork, sideAreasSum, data.formwork),
    createItem(data.location, createItemBasedOnFinishing, sideAreasSum, data.concreteFinishing),
  ].filter((i): i is TakeOffCostItem => i !== undefined)
}

const createItem = (
  location: string,
  func: (data: string, location: string, value: number) => TakeOffCostItem,
  value: number,
  data?: string
) => {
  if (data === undefined) {
    return undefined
  }
  return func(data, location || 'Location', value)
}

const createItemBasedOnProvision = (provision: string, location: string, volume: number): TakeOffCostItem => {
  const rate = provisionToRate[provision]

  return {
    code: provisionToCode[provision],
    title: location + '; In-situ Wall; 250 mm; Prov of conc; ' + provision,
    quantity: volume,
    unit: 'M3',
    currency: 'GBP',
    rate,
    total: rate * volume,
  }
}

const provisionToRate: { [provision: string]: number } = {
  'C20/25': 95,
  'C25/30': 100,
  'C28/35': 105,
  'C30/37': 110,
  'C32/40': 115,
  'C35/45': 120,
  'C40/50': 125,
}

const provisionToCode: { [provision: string]: string } = {
  'C20/25': 'F.2.1.2',
  'C25/30': 'F.2.2.2',
  'C28/35': 'F.2.3.2',
  'C30/37': 'F.2.4.2',
  'C32/40': 'F.2.5.2',
  'C35/45': 'F.2.6.2',
  'C40/50': 'F.2.7.2',
}

const createItemBasedOnPlacing = (placing: string, location: string, volume: number): TakeOffCostItem => {
  const rate = placingToRate[placing]
  return {
    code: placingToCode[placing],
    title: location + '; In-situ Wall; 250 mm; Placing of conc; ' + placing,
    quantity: volume,
    unit: 'M3',
    currency: 'GBP',
    rate,
    total: rate * volume,
  }
}

const placingToRate: { [placing: string]: number } = {
  "T'ness: n.e. 150mm": 35,
  "T'ness: 150-300mm": 32.5,
  "T'ness: 300-500mm": 30,
  "T'ness: > 500mm": 27.5,
}

const placingToCode: { [placing: string]: string } = {
  "T'ness: n.e. 150mm": 'F.6.3.1',
  "T'ness: 150-300mm": 'F.6.3.2',
  "T'ness: 300-500mm": 'F.6.3.3',
  "T'ness: > 500mm": 'F.6.3.4',
}

const createItemBasedOnReinfRatio = (reinfRatio: string, location: string, volume: number): TakeOffCostItem => {
  const reinfRatioNumber = parseInt(reinfRatio, 10)
  const quantity = (volume * reinfRatioNumber) / 1000
  return {
    code: 'G.5.2.5',
    title: location + '; In-situ Wall; 250 mm; Reinf; ' + reinfRatio + ' kg/m3',
    quantity,
    unit: 'T',
    currency: 'GBP',
    rate: 900,
    total: 900 * quantity,
  }
}

const createItemBasedOnFormwork = (formwork: string, location: string, sideAreas: number): TakeOffCostItem => {
  const rate = formworkToRate[formwork]
  return {
    code: formworkToCode[formwork],
    title: location + '; In-situ Wall; 250 mm; Fmwk; ' + formwork,
    quantity: sideAreas,
    unit: 'M2',
    currency: 'GBP',
    rate,
    total: rate * sideAreas,
  }
}

const formworkToRate: { [formwork: string]: number } = {
  'Width: 0.2-0.4m': 75,
  'Width: 0.4-1.22m': 70,
  'Width: > 1.22m': 65,
}

const formworkToCode: { [formwork: string]: string } = {
  'Width: 0.2-0.4m': 'G.2.4.3',
  'Width: 0.4-1.22m': 'G.2.4.4',
  'Width: > 1.22m': 'G.2.4.5',
}

const createItemBasedOnFinishing = (finishing: string, location: string, sideAreas: number): TakeOffCostItem => {
  return {
    code: finishingToCode[finishing],
    title: location + '; In-situ Wall; 250 mm; Fmwk; ' + finishing,
    quantity: sideAreas,
    unit: 'M2',
    currency: 'GBP',
    rate: 65,
    total: 65 * sideAreas,
  }
}

const finishingToCode: { [formwork: string]: string } = {
  'Agg. exposure using retarder': 'G.8.2.1',
  'Bush hammering': 'G.8.2.2',
  'Other stated surface treatment carried out after striking formwork': 'G.8.2.3',
}
