import { ModelNodeProperty } from '../ForgeViewer.model'

type Property = Autodesk.Viewing.Property
type PropertyResult = Autodesk.Viewing.PropertyResult

type PropertiesProvider = (
  dbId: number,
  successCallback: (r: PropertyResult) => void,
  errorCallback: (err: any) => void
) => void

const AllowedPropertiesDisplayNames = [
  'Category',
  '_RC',
  '_RFN',
  '_RFT',
  'Width',
  'Fire Rating',
  'Thickness',
  'Height',
  'Length',
  'Area',
  'Volume',
  'Number',
]

export class NodePropertiesLoader {
  propertiesProvider: PropertiesProvider

  constructor(propertiesProvider: PropertiesProvider) {
    this.propertiesProvider = propertiesProvider
  }

  loadProperties = (
    dbId: number,
    successCallback: (properties: ModelNodeProperty[], guid: string | undefined) => void,
    errorCallback: (err: any) => void
  ): void => {
    this.propertiesProvider(
      dbId,
      (r: PropertyResult) => successCallback(this.processProperties(r.properties), this.findGuid(r.properties)),
      (err: any) => errorCallback(err)
    )
  }

  private readonly processProperties = (properties: Property[]): ModelNodeProperty[] => {
    return properties
      .filter((p) => AllowedPropertiesDisplayNames.includes(p.displayName))
      .map((p) => {
        return {
          displayName: p.displayName,
          displayValue: p.displayValue,
          displayCategory: p.displayCategory,
        }
      })
  }

  private readonly findGuid = (properties: Property[]): string | undefined => {
    const guidProperty = properties.find((p) => p.displayName === 'GUID')
    return guidProperty !== undefined ? guidProperty.displayValue : undefined
  }
}
