export interface NRM1Item {
  id: number
  itemCode: string
  title: string
  notes?: string
  description?: string
}

export function emptyNRM1Item() {
  return { id: -1, itemCode: '', title: '', notes: '', description: '' }
}

export const nrm1Data: NRM1Item[] = [
  {
    id: 100,
    itemCode: '01',
    title: 'BUILDING NAME',
    notes: '',
    description: '',
  },
  {
    id: 101,
    itemCode: '01.00',
    title: 'Facilitating Works',
    notes: '',
    description: '',
  },
  {
    id: 102,
    itemCode: '01.00.01',
    title: 'Toxic/hazardous/contaminated material treatment',
    notes: '',
    description: '',
  },
  {
    id: 103,
    itemCode: '01.00.01.01',
    title: 'Toxic or hazardous material removal',
    notes: '',
    description: '',
  },
  {
    id: 104,
    itemCode: '01.00.01.02',
    title: 'Contaminated land',
    notes: '',
    description: '',
  },
  {
    id: 105,
    itemCode: '01.00.01.03',
    title: 'Eradication of plant growth',
    notes: '',
    description: '',
  },
  {
    id: 106,
    itemCode: '01.00.02',
    title: 'Major demolition works',
    notes: '',
    description: '',
  },
  {
    id: 107,
    itemCode: '01.00.02.01',
    title: 'Demolition works',
    notes: '',
    description: '',
  },
  {
    id: 108,
    itemCode: '01.00.02.02',
    title: 'Soft strip works',
    notes: '',
    description: '',
  },
  {
    id: 109,
    itemCode: '01.00.03',
    title: 'Temporary support to adjacent structures',
    notes: '',
    description: '',
  },
  {
    id: 110,
    itemCode: '01.00.03.01',
    title: 'Temporary support to adjacent structures',
    notes: '',
    description: '',
  },
  {
    id: 111,
    itemCode: '01.00.04',
    title: 'Specialist groundworks',
    notes: '',
    description: '',
  },
  {
    id: 112,
    itemCode: '01.00.04.01',
    title: 'Site dewatering and pumping',
    notes: '',
    description: '',
  },
  {
    id: 113,
    itemCode: '01.00.04.02',
    title: 'Soil stabilisation measures',
    notes: '',
    description: '',
  },
  {
    id: 114,
    itemCode: '01.00.04.03',
    title: 'Ground gas venting measures',
    notes: '',
    description: '',
  },
  {
    id: 115,
    itemCode: '01.00.05',
    title: 'Temporary diversion works',
    notes: '',
    description: '',
  },
  {
    id: 116,
    itemCode: '01.00.05.01',
    title: 'Temporary diversion works',
    notes: '',
    description: '',
  },
  {
    id: 117,
    itemCode: '01.00.06',
    title: 'Extraordinary site investigation works',
    notes: '',
    description: '',
  },
  {
    id: 118,
    itemCode: '01.00.06.01',
    title: 'Archaeological investigation',
    notes: '',
    description: '',
  },
  {
    id: 119,
    itemCode: '01.00.06.02',
    title: 'Reptile/wildlife mitigation measures',
    notes: '',
    description: '',
  },
  {
    id: 120,
    itemCode: '01.00.06.03',
    title: 'Other extraordinary site investigation works',
    notes: '',
    description: '',
  },
  {
    id: 121,
    itemCode: '01.01',
    title: 'Substructure',
    notes: '',
    description: '',
  },
  {
    id: 122,
    itemCode: '01.01.01',
    title: 'Substructure',
    notes: '',
    description: '',
  },
  {
    id: 123,
    itemCode: '01.01.01.01',
    title: 'Standard foundations',
    notes: '',
    description: '',
  },
  {
    id: 124,
    itemCode: '01.01.01.02',
    title: 'Specialist foundations',
    notes: '',
    description: '',
  },
  {
    id: 125,
    itemCode: '01.01.01.03',
    title: 'Lowest floor construction',
    notes: '',
    description: '',
  },
  {
    id: 126,
    itemCode: '01.01.01.04',
    title: 'Basement excavation',
    notes: '',
    description: '',
  },
  {
    id: 127,
    itemCode: '01.01.01.05',
    title: 'Basement retaining walls',
    notes: '',
    description: '',
  },
  {
    id: 128,
    itemCode: '01.02',
    title: 'Superstructure',
    notes: '',
    description: '',
  },
  {
    id: 129,
    itemCode: '01.02.01',
    title: 'Frame',
    notes: '',
    description: '',
  },
  {
    id: 130,
    itemCode: '01.02.01.01',
    title: 'Steel frames',
    notes: '',
    description: '',
  },
  {
    id: 131,
    itemCode: '01.02.01.02',
    title: 'Space frames/decks',
    notes: '',
    description: '',
  },
  {
    id: 132,
    itemCode: '01.02.01.03',
    title: 'Concrete casings to steel frames',
    notes: '',
    description: '',
  },
  {
    id: 133,
    itemCode: '01.02.01.04',
    title: 'Concrete frames',
    notes: '',
    description: '',
  },
  {
    id: 134,
    itemCode: '01.02.01.05',
    title: 'Timber frames',
    notes: '',
    description: '',
  },
  {
    id: 135,
    itemCode: '01.02.01.06',
    title: 'Specialist frames',
    notes: '',
    description: '',
  },
  {
    id: 136,
    itemCode: '01.02.02',
    title: 'Upper floors',
    notes: '',
    description: '',
  },
  {
    id: 137,
    itemCode: '01.02.02.01',
    title: 'Floors',
    notes: '',
    description: '',
  },
  {
    id: 138,
    itemCode: '01.02.02.02',
    title: 'Balconies',
    notes: '',
    description: '',
  },
  {
    id: 139,
    itemCode: '01.02.02.03',
    title: 'Drainage to balconies',
    notes: '',
    description: '',
  },
  {
    id: 140,
    itemCode: '01.02.03',
    title: 'Roofs',
    notes: '',
    description: '',
  },
  {
    id: 141,
    itemCode: '01.02.03.01',
    title: 'Roof structure',
    notes: '',
    description: '',
  },
  {
    id: 142,
    itemCode: '01.02.03.02',
    title: 'Roof coverings',
    notes: '',
    description: '',
  },
  {
    id: 143,
    itemCode: '01.02.03.03',
    title: 'Specialist roof systems',
    notes: '',
    description: '',
  },
  {
    id: 144,
    itemCode: '01.02.03.04',
    title: 'Roof drainage',
    notes: '',
    description: '',
  },
  {
    id: 145,
    itemCode: '01.02.03.05',
    title: 'Rooflights, skylights and openings',
    notes: '',
    description: '',
  },
  {
    id: 146,
    itemCode: '01.02.03.06',
    title: 'Roof features',
    notes: '',
    description: '',
  },
  {
    id: 147,
    itemCode: '01.02.04',
    title: 'Stairs and ramps',
    notes: '',
    description: '',
  },
  {
    id: 148,
    itemCode: '01.02.04.01',
    title: 'Stair/ramp structures',
    notes: '',
    description: '',
  },
  {
    id: 149,
    itemCode: '01.02.04.02',
    title: 'Stair/ramp finishes',
    notes: '',
    description: '',
  },
  {
    id: 150,
    itemCode: '01.02.04.03',
    title: 'Stair/ramp balustrades and handrails',
    notes: '',
    description: '',
  },
  {
    id: 151,
    itemCode: '01.02.04.04',
    title: 'Ladders/chutes/slides',
    notes: '',
    description: '',
  },
  {
    id: 152,
    itemCode: '01.02.05',
    title: 'External walls',
    notes: '',
    description: '',
  },
  {
    id: 153,
    itemCode: '01.02.05.01',
    title: 'External enclosing walls above ground level',
    notes: '',
    description: '',
  },
  {
    id: 154,
    itemCode: '01.02.05.02',
    title: 'External enclosing walls below ground level',
    notes: '',
    description: '',
  },
  {
    id: 155,
    itemCode: '01.02.05.03',
    title: 'Solar/rain screening',
    notes: '',
    description: '',
  },
  {
    id: 156,
    itemCode: '01.02.05.04',
    title: 'External soffits',
    notes: '',
    description: '',
  },
  {
    id: 157,
    itemCode: '01.02.05.05',
    title: 'Subsidiary walls, balustrades and proprietary balconies',
    notes: '',
    description: '',
  },
  {
    id: 158,
    itemCode: '01.02.05.06',
    title: 'Façade',
    notes: '',
    description: '',
  },
  {
    id: 159,
    itemCode: '01.02.06',
    title: 'Windows and external doors',
    notes: '',
    description: '',
  },
  {
    id: 160,
    itemCode: '01.02.06.01',
    title: 'External windows',
    notes: '',
    description: '',
  },
  {
    id: 161,
    itemCode: '01.02.06.02',
    title: 'External doors',
    notes: '',
    description: '',
  },
  {
    id: 162,
    itemCode: '01.02.07',
    title: 'Internal walls and partitions',
    notes: '',
    description: '',
  },
  {
    id: 163,
    itemCode: '01.02.07.01',
    title: 'Walls and partitions',
    notes: '',
    description: '',
  },
  {
    id: 164,
    itemCode: '01.02.07.02',
    title: 'Balustrades and handrails',
    notes: '',
    description: '',
  },
  {
    id: 165,
    itemCode: '01.02.07.03',
    title: 'Moveable room dividers',
    notes: '',
    description: '',
  },
  {
    id: 166,
    itemCode: '01.02.07.04',
    title: 'Cubicles',
    notes: '',
    description: '',
  },
  {
    id: 167,
    itemCode: '01.02.08',
    title: 'Internal doors',
    notes: '',
    description: '',
  },
  {
    id: 168,
    itemCode: '01.02.08.01',
    title: 'Internal doors',
    notes: '',
    description: '',
  },
  {
    id: 169,
    itemCode: '01.03',
    title: 'Internal finishes',
    notes: '',
    description: '',
  },
  {
    id: 170,
    itemCode: '01.03.01',
    title: 'Wall finishes',
    notes: '',
    description: '',
  },
  {
    id: 171,
    itemCode: '01.03.01.01',
    title: 'Wall finishes',
    notes: '',
    description: '',
  },
  {
    id: 172,
    itemCode: '01.03.02',
    title: 'Floor finishes',
    notes: '',
    description: '',
  },
  {
    id: 173,
    itemCode: '01.03.02.01',
    title: 'Finishes to floors',
    notes: '',
    description: '',
  },
  {
    id: 174,
    itemCode: '01.03.02.02',
    title: 'Raised access floors',
    notes: '',
    description: '',
  },
  {
    id: 175,
    itemCode: '01.03.03',
    title: 'Ceiling finishes',
    notes: '',
    description: '',
  },
  {
    id: 176,
    itemCode: '01.03.03.01',
    title: 'Finishes to ceilings',
    notes: '',
    description: '',
  },
  {
    id: 177,
    itemCode: '01.03.03.02',
    title: 'False ceilings',
    notes: '',
    description: '',
  },
  {
    id: 178,
    itemCode: '01.03.03.03',
    title: 'Demountable suspended ceilings',
    notes: '',
    description: '',
  },
  {
    id: 179,
    itemCode: '01.04',
    title: 'Fittings, furnishings and equipment',
    notes: '',
    description: '',
  },
  {
    id: 180,
    itemCode: '01.04.01',
    title: 'Fittings, furnishings and equipment',
    notes: '',
    description: '',
  },
  {
    id: 181,
    itemCode: '01.04.01.01',
    title: 'General fittings, furnishings and equipment',
    notes: '',
    description: '',
  },
  {
    id: 182,
    itemCode: '01.04.01.02',
    title: 'Domestic kitchen fittings and equipment',
    notes: '',
    description: '',
  },
  {
    id: 183,
    itemCode: '01.04.01.03',
    title: 'Special purpose fittings, furnishings and equipment',
    notes: '',
    description: '',
  },
  {
    id: 184,
    itemCode: '01.04.01.04',
    title: 'Signs/notices',
    notes: '',
    description: '',
  },
  {
    id: 185,
    itemCode: '01.04.01.05',
    title: 'Works of art',
    notes: '',
    description: '',
  },
  {
    id: 186,
    itemCode: '01.04.01.06',
    title: 'Non-mechanical and non-electrical equipment',
    notes: '',
    description: '',
  },
  {
    id: 187,
    itemCode: '01.04.01.07',
    title: 'Internal planting',
    notes: '',
    description: '',
  },
  {
    id: 188,
    itemCode: '01.04.01.08',
    title: 'Bird and vermin control',
    notes: '',
    description: '',
  },
  {
    id: 189,
    itemCode: '01.05',
    title: 'Services',
    notes: '',
    description: '',
  },
  {
    id: 190,
    itemCode: '01.05.01',
    title: 'Sanitary installations',
    notes: '',
    description: '',
  },
  {
    id: 191,
    itemCode: '01.05.01.01',
    title: 'Sanitary appliances',
    notes: '',
    description: '',
  },
  {
    id: 192,
    itemCode: '01.05.01.02',
    title: 'Sanitary ancillaries',
    notes: '',
    description: '',
  },
  {
    id: 193,
    itemCode: '01.05.02',
    title: 'Services equipment',
    notes: '',
    description: '',
  },
  {
    id: 194,
    itemCode: '01.05.02.01',
    title: 'Services equipment',
    notes: '',
    description: '',
  },
  {
    id: 195,
    itemCode: '01.05.03',
    title: 'Disposal installations',
    notes: '',
    description: '',
  },
  {
    id: 196,
    itemCode: '01.05.03.01',
    title: 'Foul drainage above ground',
    notes: '',
    description: '',
  },
  {
    id: 197,
    itemCode: '01.05.03.02',
    title: 'Chemical, toxic and industrial liquid waste drainage',
    notes: '',
    description: '',
  },
  {
    id: 198,
    itemCode: '01.05.03.03',
    title: 'Refuse disposal',
    notes: '',
    description: '',
  },
  {
    id: 199,
    itemCode: '01.05.04',
    title: 'Water installations',
    notes: '',
    description: '',
  },
  {
    id: 200,
    itemCode: '01.05.04.01',
    title: 'Mains water supply',
    notes: '',
    description: '',
  },
  {
    id: 201,
    itemCode: '01.05.04.02',
    title: 'Cold water distribution',
    notes: '',
    description: '',
  },
  {
    id: 202,
    itemCode: '01.05.04.03',
    title: 'Hot water distribution',
    notes: '',
    description: '',
  },
  {
    id: 203,
    itemCode: '01.05.04.04',
    title: 'Local hot water distribution',
    notes: '',
    description: '',
  },
  {
    id: 204,
    itemCode: '01.05.04.05',
    title: 'Steam and condensate distribution',
    notes: '',
    description: '',
  },
  {
    id: 205,
    itemCode: '01.05.05',
    title: 'Heat source',
    notes: '',
    description: '',
  },
  {
    id: 206,
    itemCode: '01.05.05.01',
    title: 'Heat source',
    notes: '',
    description: '',
  },
  {
    id: 207,
    itemCode: '01.05.06',
    title: 'Space heating and air conditioning',
    notes: '',
    description: '',
  },
  {
    id: 208,
    itemCode: '01.05.06.01',
    title: 'Central heating',
    notes: '',
    description: '',
  },
  {
    id: 209,
    itemCode: '01.05.06.02',
    title: 'Local heating',
    notes: '',
    description: '',
  },
  {
    id: 210,
    itemCode: '01.05.06.03',
    title: 'Central cooling',
    notes: '',
    description: '',
  },
  {
    id: 211,
    itemCode: '01.05.06.04',
    title: 'Local cooling',
    notes: '',
    description: '',
  },
  {
    id: 212,
    itemCode: '01.05.06.05',
    title: 'Central heating and cooling',
    notes: '',
    description: '',
  },
  {
    id: 213,
    itemCode: '01.05.06.06',
    title: 'Local heating and cooling',
    notes: '',
    description: '',
  },
  {
    id: 214,
    itemCode: '01.05.06.07',
    title: 'Central air conditioning',
    notes: '',
    description: '',
  },
  {
    id: 215,
    itemCode: '01.05.06.08',
    title: 'Local air conditioning',
    notes: '',
    description: '',
  },
  {
    id: 216,
    itemCode: '01.05.07',
    title: 'Ventilation',
    notes: '',
    description: '',
  },
  {
    id: 217,
    itemCode: '01.05.07.01',
    title: 'Central ventilation',
    notes: '',
    description: '',
  },
  {
    id: 218,
    itemCode: '01.05.07.02',
    title: 'Local and special ventilation',
    notes: '',
    description: '',
  },
  {
    id: 219,
    itemCode: '01.05.07.03',
    title: 'Smoke extract/control',
    notes: '',
    description: '',
  },
  {
    id: 220,
    itemCode: '01.05.08',
    title: 'Electrical installations',
    notes: '',
    description: '',
  },
  {
    id: 221,
    itemCode: '01.05.08.01',
    title: 'Electrical mains and sub-mains distribution',
    notes: '',
    description: '',
  },
  {
    id: 222,
    itemCode: '01.05.08.02',
    title: 'Power installations',
    notes: '',
    description: '',
  },
  {
    id: 223,
    itemCode: '01.05.08.03',
    title: 'Lighting installations',
    notes: '',
    description: '',
  },
  {
    id: 224,
    itemCode: '01.05.08.04',
    title: 'Specialist lighting installations',
    notes: '',
    description: '',
  },
  {
    id: 225,
    itemCode: '01.05.08.05',
    title: 'Local electricity generation systems',
    notes: '',
    description: '',
  },
  {
    id: 226,
    itemCode: '01.05.08.06',
    title: 'Earthing and bonding systems',
    notes: '',
    description: '',
  },
  {
    id: 227,
    itemCode: '01.05.09',
    title: 'Fuel installations',
    notes: '',
    description: '',
  },
  {
    id: 228,
    itemCode: '01.05.09.01',
    title: 'Fuel storage',
    notes: '',
    description: '',
  },
  {
    id: 229,
    itemCode: '01.05.09.02',
    title: 'Fuel distribution systems',
    notes: '',
    description: '',
  },
  {
    id: 230,
    itemCode: '01.05.10',
    title: 'Lift and conveyor installations',
    notes: '',
    description: '',
  },
  {
    id: 231,
    itemCode: '01.05.10.01',
    title: 'Lifts and enclosed hoists',
    notes: '',
    description: '',
  },
  {
    id: 232,
    itemCode: '01.05.10.02',
    title: 'Escalators',
    notes: '',
    description: '',
  },
  {
    id: 233,
    itemCode: '01.05.10.03',
    title: 'Moving pavements',
    notes: '',
    description: '',
  },
  {
    id: 234,
    itemCode: '01.05.10.04',
    title: 'Powered stairlifts',
    notes: '',
    description: '',
  },
  {
    id: 235,
    itemCode: '01.05.10.05',
    title: 'Conveyors',
    notes: '',
    description: '',
  },
  {
    id: 236,
    itemCode: '01.05.10.06',
    title: 'Dock levellers and scissor lifts',
    notes: '',
    description: '',
  },
  {
    id: 237,
    itemCode: '01.05.10.07',
    title: 'Cranes and unenclosed hoists',
    notes: '',
    description: '',
  },
  {
    id: 238,
    itemCode: '01.05.10.08',
    title: 'Car lifts, car stacking, turntables and the like',
    notes: '',
    description: '',
  },
  {
    id: 239,
    itemCode: '01.05.10.09',
    title: 'Document handling systems',
    notes: '',
    description: '',
  },
  {
    id: 240,
    itemCode: '01.05.10.10',
    title: 'Other lift and conveyor installations',
    notes: '',
    description: '',
  },
  {
    id: 241,
    itemCode: '01.05.11',
    title: 'Fire and lighting protection',
    notes: '',
    description: '',
  },
  {
    id: 242,
    itemCode: '01.05.11.01',
    title: 'Fire fighting systems',
    notes: '',
    description: '',
  },
  {
    id: 243,
    itemCode: '01.05.11.02',
    title: 'Fire suppression systems',
    notes: '',
    description: '',
  },
  {
    id: 244,
    itemCode: '01.05.11.03',
    title: 'Lighting protection',
    notes: '',
    description: '',
  },
  {
    id: 245,
    itemCode: '01.05.12',
    title: 'Communication, security and control systems',
    notes: '',
    description: '',
  },
  {
    id: 246,
    itemCode: '01.05.12.01',
    title: 'Communication systems',
    notes: '',
    description: '',
  },
  {
    id: 247,
    itemCode: '01.05.12.02',
    title: 'Security systems',
    notes: '',
    description: '',
  },
  {
    id: 248,
    itemCode: '01.05.12.03',
    title: 'Central control/building management systems',
    notes: '',
    description: '',
  },
  {
    id: 249,
    itemCode: '01.05.13',
    title: 'Specialist installations',
    notes: '',
    description: '',
  },
  {
    id: 250,
    itemCode: '01.05.13.01',
    title: 'Specialist piped supply installations',
    notes: '',
    description: '',
  },
  {
    id: 251,
    itemCode: '01.05.13.02',
    title: 'Specialist refrigeration systems',
    notes: '',
    description: '',
  },
  {
    id: 252,
    itemCode: '01.05.13.03',
    title: 'Specialist mechanical instalations',
    notes: '',
    description: '',
  },
  {
    id: 253,
    itemCode: '01.05.13.04',
    title: 'Specialist electrical/ electronic installations',
    notes: '',
    description: '',
  },
  {
    id: 254,
    itemCode: '01.05.13.05',
    title: 'Water features',
    notes: '',
    description: '',
  },
  {
    id: 255,
    itemCode: '01.05.14',
    title: "Builder's work in connection with services",
    notes: '',
    description: '',
  },
  {
    id: 256,
    itemCode: '01.05.14.01',
    title: "Builder's work in connection with services",
    notes: '',
    description: '',
  },
  {
    id: 257,
    itemCode: '01.06',
    title: 'Prefabricated buildings and building units',
    notes: '',
    description: '',
  },
  {
    id: 258,
    itemCode: '01.06.01',
    title: 'Prefabricated buildings and building units',
    notes: '',
    description: '',
  },
  {
    id: 259,
    itemCode: '01.06.01.01',
    title: 'Complete buildings',
    notes: '',
    description: '',
  },
  {
    id: 260,
    itemCode: '01.06.01.02',
    title: 'Building units',
    notes: '',
    description: '',
  },
  {
    id: 261,
    itemCode: '01.06.01.03',
    title: 'Pods',
    notes: '',
    description: '',
  },
  {
    id: 262,
    itemCode: '01.07',
    title: 'Works to existing buildings',
    notes: '',
    description: '',
  },
  {
    id: 263,
    itemCode: '01.07.01',
    title: 'Minor demolition works and alteration works',
    notes: '',
    description: '',
  },
  {
    id: 264,
    itemCode: '01.07.01.01',
    title: 'Minor demolition works and alteration works',
    notes: '',
    description: '',
  },
  {
    id: 265,
    itemCode: '01.07.02',
    title: 'Repairs to existing services',
    notes: '',
    description: '',
  },
  {
    id: 266,
    itemCode: '01.07.02.01',
    title: 'Repairs to existing services',
    notes: '',
    description: '',
  },
  {
    id: 267,
    itemCode: '01.07.03',
    title: 'Damp-proof courses/ fungus and beetle eradication',
    notes: '',
    description: '',
  },
  {
    id: 268,
    itemCode: '01.07.03.01',
    title: 'Damp-proof courses',
    notes: '',
    description: '',
  },
  {
    id: 269,
    itemCode: '01.07.03.02',
    title: 'Fungus/ beetle eradication',
    notes: '',
    description: '',
  },
  {
    id: 270,
    itemCode: '01.07.04',
    title: 'Façade retention',
    notes: '',
    description: '',
  },
  {
    id: 271,
    itemCode: '01.07.04.01',
    title: 'Façade retention',
    notes: '',
    description: '',
  },
  {
    id: 272,
    itemCode: '01.07.05',
    title: 'Cleaning existing surfaces',
    notes: '',
    description: '',
  },
  {
    id: 273,
    itemCode: '01.07.05.01',
    title: 'Cleaning',
    notes: '',
    description: '',
  },
  {
    id: 274,
    itemCode: '01.07.05.02',
    title: 'Protective coatings',
    notes: '',
    description: '',
  },
  {
    id: 275,
    itemCode: '01.07.06',
    title: 'Renovation works',
    notes: '',
    description: '',
  },
  {
    id: 276,
    itemCode: '01.07.06.01',
    title: 'Masonry repairs',
    notes: '',
    description: '',
  },
  {
    id: 277,
    itemCode: '01.07.06.02',
    title: 'Concrete repairs',
    notes: '',
    description: '',
  },
  {
    id: 278,
    itemCode: '01.07.06.03',
    title: 'Metal repairs',
    notes: '',
    description: '',
  },
  {
    id: 279,
    itemCode: '01.07.06.04',
    title: 'Timber repairs',
    notes: '',
    description: '',
  },
  {
    id: 280,
    itemCode: '01.07.06.05',
    title: 'Plastic repairs',
    notes: '',
    description: '',
  },
  {
    id: 281,
    itemCode: '01.08',
    title: 'External works',
    notes: '',
    description: '',
  },
  {
    id: 282,
    itemCode: '01.08.01',
    title: 'Site preperation works',
    notes: '',
    description: '',
  },
  {
    id: 283,
    itemCode: '01.08.02',
    title: 'Roads, paths, pavings and surfacings',
    notes: '',
    description: '',
  },
  {
    id: 284,
    itemCode: '01.08.03',
    title: 'Soft landscaping, planting and irrigation systems',
    notes: '',
    description: '',
  },
  {
    id: 285,
    itemCode: '01.08.04',
    title: 'Fencing, railings and  walls',
    notes: '',
    description: '',
  },
  {
    id: 286,
    itemCode: '01.08.05',
    title: 'External fixtures',
    notes: '',
    description: '',
  },
  {
    id: 287,
    itemCode: '01.08.06',
    title: 'External drainage',
    notes: '',
    description: '',
  },
  {
    id: 288,
    itemCode: '01.08.07',
    title: 'External services',
    notes: '',
    description: '',
  },
  {
    id: 289,
    itemCode: '01.08.08',
    title: 'Minor building works and ancillary buildings',
    notes: '',
    description: '',
  },
  {
    id: 290,
    itemCode: '01.09',
    title: 'Main contractor’s preliminaries',
    notes: '',
    description: '',
  },
  {
    id: 291,
    itemCode: '01.09.01',
    title: 'Employer’s requirements',
    notes: '',
    description: '',
  },
  {
    id: 292,
    itemCode: '01.09.02',
    title: 'Main contractor’s cost items',
    notes: '',
    description: '',
  },
  {
    id: 293,
    itemCode: '01.10',
    title: 'Main contractor’s overheads and profit',
    notes: '',
    description: '',
  },
  {
    id: 294,
    itemCode: '01.10.01',
    title: 'Main contractor’s overheads',
    notes: '',
    description: '',
  },
  {
    id: 295,
    itemCode: '01.11',
    title: 'Project/ design team fees',
    notes: '',
    description: '',
  },
  {
    id: 296,
    itemCode: '01.11.01',
    title: 'Consultants’ fees',
    notes: '',
    description: '',
  },
  {
    id: 297,
    itemCode: '01.11.02',
    title: 'Main contractor’s pre-construction fees',
    notes: '',
    description: '',
  },
  {
    id: 298,
    itemCode: '01.11.03',
    title: 'Main contractor’s design fees',
    notes: '',
    description: '',
  },
  {
    id: 299,
    itemCode: '01.12',
    title: 'Other development/ project costs',
    notes: '',
    description: '',
  },
  {
    id: 300,
    itemCode: '01.12.01',
    title: 'Other development/ project costs',
    notes: '',
    description: '',
  },
  {
    id: 301,
    itemCode: '01.13',
    title: 'Risks',
    notes: '',
    description: '',
  },
  {
    id: 302,
    itemCode: '01.13.01',
    title: 'Design development risks',
    notes: '',
    description: '',
  },
  {
    id: 303,
    itemCode: '01.13.02',
    title: 'Construction risks',
    notes: '',
    description: '',
  },
  {
    id: 304,
    itemCode: '01.13.03',
    title: 'Employer change risks',
    notes: '',
    description: '',
  },
  {
    id: 305,
    itemCode: '01.13.04',
    title: 'Employer other risks',
    notes: '',
    description: '',
  },
  {
    id: 306,
    itemCode: '01.14',
    title: 'Inflation',
    notes: '',
    description: '',
  },
  {
    id: 307,
    itemCode: '01.14.01',
    title: 'Tender inflation',
    notes: '',
    description: '',
  },
  {
    id: 308,
    itemCode: '01.14.02',
    title: 'Construction inflation',
    notes: '',
    description: '',
  },
]
