import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

function copyStyles(sourceDoc: Document, targetDoc: Document | null) {
  if (targetDoc !== null) {
    Array.from(sourceDoc.styleSheets).forEach((styleSheet) => {
      if (styleSheet instanceof CSSStyleSheet && styleSheet.cssRules) {
        // true for inline styles
        const newStyleEl = sourceDoc.createElement('style')

        Array.from(styleSheet.cssRules).forEach((cssRule) => {
          newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText))
        })

        targetDoc.head.appendChild(newStyleEl)
      } else if (styleSheet.href) {
        // true for stylesheets loaded from a URL
        const newLinkEl = sourceDoc.createElement('link')

        newLinkEl.rel = 'stylesheet'
        newLinkEl.href = styleSheet.href
        targetDoc.head.appendChild(newLinkEl)
      }
    })
  }
}

interface NewWindowProps {
  children: JSX.Element | React.ReactNode
  height: number
  width: number
  title?: string
  isOpen: boolean
  onPortalClose: () => void
  replacementPane: ReactNode
}

const NewWindow: FunctionComponent<NewWindowProps> = ({
  height,
  width,
  onPortalClose,
  children,
  isOpen,
  replacementPane,
}) => {
  const elRef = useRef<HTMLElement | null>(null)
  if (!elRef?.current) {
    elRef.current = document.createElement('body')
  }

  const [externalWindow, setExternalWindow] = useState<Window | null>(null)

  useEffect(() => {
    if (isOpen) {
      const newWindow = window.open('', '', `width=${width},height=${height},left=200,top=200`)
      newWindow?.document.write(
        '<!DOCTYPE HTML><html><head><title>Sterling window</title></head><body></body>'
      )
      newWindow?.document.body.appendChild(elRef?.current as HTMLElement)
      copyStyles(document, newWindow?.document || null)
      newWindow?.addEventListener('beforeunload', () => {
        onPortalClose()
      })
      setExternalWindow(newWindow)
    }
    return () => externalWindow?.close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <>
      {isOpen && createPortal(children, elRef.current)}
      {isOpen ? replacementPane : children}
    </>
  )
}

export default NewWindow
