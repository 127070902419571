import React from 'react'
import {
  SterlingTableHeader,
  SterlingTableHeaderProps,
} from '../../../basic/table/sterling-table/header/SterlingTableHeader'
import { Button, Divider, Tooltip } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import { TextWrappingCustom } from '../../../../assets/icons/icons'

interface TakeOffDetailsTableHeaderProps {
  sterlingTableHeaderProps: SterlingTableHeaderProps
  projectName?: string
  projectCode?: string
}

export const TakeOffDetailsTableHeader = ({
  sterlingTableHeaderProps,
  projectCode,
  projectName,
}: TakeOffDetailsTableHeaderProps) => {
  return (
    <SterlingTableHeader
      {...sterlingTableHeaderProps}
      columnsLayoutManagerVisibility={true}
      descriptionManagerVisibility={true}
      projectName={projectName || undefined}
      projectCode={projectCode || undefined}
    >
      <Tooltip title="Refresh">
        <Button type="text" shape="circle" icon={<SyncOutlined />} />
      </Tooltip>
      <Divider type="vertical" />
      <Tooltip title="Wrap text">
        <Button type="text" shape="circle" icon={<TextWrappingCustom />} />
      </Tooltip>
    </SterlingTableHeader>
  )
}
