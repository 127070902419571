import { Entry } from '../../../basic/table/sterling-table/SterlingTable'
import { parseData } from '../../../basic/table/sterling-table/helpers/TableDataParser'

export function groupByBreakdownStructure(
  parentsList: any[],
  boqElements: Entry[],
  codeExtractor: (el: any) => string
) {
  const parents: Entry[] = parseData(parentsList, codeExtractor)
  const parentsNameToEntryMap: { [key: string]: Entry } = {}
  if (parents === []) {
    return []
  }
  parents.forEach((entry) => {
    parentsNameToEntryMap[codeExtractor(entry)] = entry
  })

  boqElements.forEach((entry) => {
    const code = codeExtractor(entry)
    if (parentsNameToEntryMap[code]?.children === undefined) {
      parentsNameToEntryMap[code].children = []
    }
    // @ts-ignore
    parentsNameToEntryMap[code].children.push(entry)
  })
  return parents
}
