import { Task } from 'frappe-gantt-react'

export const activitiesData = [
  {
    key: 1,
    id: 1,
    activity_code: 'A1000',
    title: 'Excavation',
    start_date: '16/02/2018',
    end_date: '16/03/2018',
    responsible: 'Tom Stone',
    completion: 100.00,
    total_value_completed: '35 193,51',
    total_value: '35 193,51',
    succesor: 'A1010',
  },
  {
    key: 2,
    id: 2,
    activity_code: 'A1010',
    title: 'Piling',
    start_date: '10/03/2018',
    end_date: '31/05/2018',
    responsible: 'Tom Stone',
    completion: 100.00,
    total_value_completed: '1 084 975,15',
    total_value: '1 084 975,15',
    succesor: 'A1020',
  },
  {
    key: 3,
    id: 3,
    activity_code: 'A1020',
    title: 'Foundations',
    start_date: '15/05/2018',
    end_date: '12/06/2018',
    responsible: 'Tom Stone',
    completion: 100.00,
    total_value_completed: '389 064,58',
    total_value: '389 064,58',
    succesor: 'A1030',
  },
  {
    key: 5,
    id: 5,
    activity_code: 'A1030',
    title: 'Concrete frame',
    start_date: '28/05/2018',
    end_date: '06/08/2018',
    responsible: 'Tom Stone',
    completion: 100.00,
    total_value_completed: '679 111,01',
    total_value: '679 111,01',
    succesor: 'A1040',
  },
  {
    key: 4,
    id: 4,
    activity_code: 'A1040',
    title: 'Steel roof',
    start_date: '21/07/2018',
    end_date: '16/08/2018',
    responsible: 'Tom Stone',
    completion: 80.00,
    total_value_completed: '173 613,07',
    total_value: '217 016,34',
    succesor: 'A1050',
  },
  {
    key: 6,
    id: 6,
    activity_code: 'A1050',
    title: 'Roofing',
    start_date: '18/08/2018',
    end_date: '25/09/2018',
    responsible: 'Tom Stone',
    completion: 20.00,
    total_value_completed: '72 434,15',
    total_value: '362 170,75',
    succesor: 'A1060',
  },
  {
    key: 7,
    id: 7,
    activity_code: 'A1060',
    title: 'External cladding',
    start_date: '13/07/2018',
    end_date: '01/10/2018',
    responsible: 'Tom Stone',
    completion: 50.00,
    total_value_completed: '87 298,68',
    total_value: '174 597,36',
    succesor: 'A1070',
  },
  {
    key: 8,
    id: 8,
    activity_code: 'A1070',
    title: 'Windows',
    start_date: '21/08/2018',
    end_date: '18/09/2018',
    responsible: 'Tom Stone',
    completion: 20.00,
    total_value_completed: '65 791,47',
    total_value: '328 957,36',
    succesor: 'A1080',
  },
  {
    key: 9,
    id: 9,
    activity_code: 'A1080',
    title: 'External doors',
    start_date: '21/08/2018',
    end_date: '18/09/2018',
    responsible: 'Tom Stone',
    completion: 20.00,
    total_value_completed: '4 062,80',
    total_value: '20 314,00',
    succesor: 'A1090',
  },
  {
    key: 10,
    id: 10,
    activity_code: 'A1090',
    title: 'Partitions',
    start_date: '15/08/2018',
    end_date: '06/11/2018',
    responsible: 'Martha Green',
    completion: 30.00,
    total_value_completed: '65 556,71',
    total_value: '218 522,35',
    succesor: 'A1100',
  },
  {
    key: 11,
    id: 11,
    activity_code: 'A1100',
    title: 'Internal doors',
    start_date: '01/11/2018',
    end_date: '21/11/2018',
    responsible: 'Martha Green',
    completion: '-',
    total_value_completed: '-',
    total_value: '27 455,00',
    succesor: 'A1110',
  },
  {
    key: 12,
    id: 12,
    activity_code: 'A1110',
    title: 'Wall finishings',
    start_date: '01/10/2018',
    end_date: '06/12/2018',
    responsible: 'Martha Green',
    completion: '-',
    total_value_completed: '-',
    total_value: '138 299,96',
    succesor: 'A1120',
  },
  {
    key: 14,
    id: 14,
    activity_code: 'A1120',
    title: 'Ceilings',
    start_date: '15/12/2018',
    end_date: '24/01/2019',
    responsible: 'Martha Green',
    completion: '-',
    total_value_completed: '-',
    total_value: '56 647,34',
    succesor: 'A1130',
  },
  {
    key: 13,
    id: 13,
    activity_code: 'A1130',
    title: 'Flooring',
    start_date: '15/11/2018',
    end_date: '06/01/2019',
    responsible: 'Martha Green',
    completion: '-',
    total_value_completed: '-',
    total_value: '57 523,61',
    succesor: 'A1140',
  },
  {
    key: 16,
    id: 16,
    activity_code: 'A1140',
    title: 'Sanitary',
    start_date: '03/11/2018',
    end_date: '16/12/2018',
    responsible: 'Martha Green',
    completion: '-',
    total_value_completed: '-',
    total_value: '8 055,00',
    succesor: 'A1150',
  },
  {
    key: 17,
    id: 17,
    activity_code: 'A1150',
    title: 'Lift',
    start_date: '15/01/2019',
    end_date: '06/02/2019',
    responsible: 'Martha Green',
    completion: '-',
    total_value_completed: '-',
    total_value: '45 000,00',
    succesor: 'A1160',
  },
  {
    key: 15,
    id: 15,
    activity_code: 'A1160',
    title: 'Furniture and equiplent',
    start_date: '06/02/2019',
    end_date: '06/04/2019',
    responsible: 'Martha Green',
    completion: '-',
    total_value_completed: '-',
    total_value: '122 434,00',
    succesor: '',
  },
]

export const ActivitiesTasks = activitiesData.map((val, i) => {
  // @ts-ignore
  return {
    id: val.activity_code,
    name: val.title,
    start: val.start_date.split('/').reverse().join('-'),
    end: val.end_date.split('/').reverse().join('-'),
    progress: val.completion,
    dependencies: val.succesor,
  } as Task
})