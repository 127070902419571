import { Action } from 'redux'

export const SET_OPEN_WINDOW_STATE = 'SET_OPEN_WINDOW_STATE'

export interface OpenWindowsAction extends Action<string> {
  windowId: string
  value: boolean
}

export const setOpenWindowState = (windowId: string, value: boolean) => {
  return {
    type: SET_OPEN_WINDOW_STATE,
    windowId,
    value,
  }
}
