export interface ContactsExternalItem {
  id: number
  itemCode: string
  title: string
  notes: string
  description: string
  contactPerson: string
  role: string
  type: string
  phoneNumber: string
  mobileNumber: string
  email: string
  address: string
  cityResidence: string
  stateProvince: string
  postCode: string
  country: string
  url: string
  creationDate: string
  lastEdited: string
  creator: string
  lastEditor: string
}

export function emptyContactsExternalItem() {
  return {
    id: -1,
    itemCode: '',
    title: '',
    notes: '',
    description: '',
    contactPerson: '',
    role: '',
    type: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    address: '',
    cityResidence: '',
    stateProvince: '',
    postCode: '',
    country: '',
    url: '',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  }
}

export const contactsExternalData: ContactsExternalItem[] = [
  {
    id: 1,
    itemCode: 'SUB',
    title: 'Subcontractor',
    notes: '',
    description: '',
    contactPerson: '',
    role: '',
    type: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    address: '',
    cityResidence: '',
    stateProvince: '',
    postCode: '',
    country: '',
    url: '',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  },
  {
    id: 2,
    itemCode: 'SUB.CON',
    title: 'Concrete works',
    notes: '',
    description: '',
    contactPerson: '',
    role: '',
    type: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    address: '',
    cityResidence: '',
    stateProvince: '',
    postCode: '',
    country: '',
    url: '',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  },
  {
    id: 3,
    itemCode: 'SUB.CON.010',
    title: 'Cemex Greater London',
    notes: '',
    description: '',
    contactPerson: 'Marta Hunter',
    role: 'Contract Manager',
    type: 'Subcontractor',
    phoneNumber: '',
    mobileNumber: '+44 1234123456',
    email: 'marta@cemex.co.uk',
    address: '',
    cityResidence: '',
    stateProvince: '',
    postCode: 'NW2 6SB',
    country: 'UNITED KINGDOM',
    url: 'https://www.cemex.co.uk/',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  },
  {
    id: 4,
    itemCode: 'SUB.CON.020',
    title: 'Cemex London City',
    notes: '',
    description: '',
    contactPerson: 'Maciej Kindler',
    role: 'Sales Manager',
    type: 'Subcontractor',
    phoneNumber: '',
    mobileNumber: '+44 1234123456',
    email: 'maciej@cemex.co.uk',
    address: '',
    cityResidence: '',
    stateProvince: '',
    postCode: 'NW2 6SB',
    country: 'UNITED KINGDOM',
    url: 'https://www.cemex.co.uk/',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  },
  {
    id: 5,
    itemCode: 'SUB.CON.030',
    title: 'Easy Mix Concrete South',
    notes: '',
    description: '',
    contactPerson: 'Steven Jones',
    role: 'Sales Director',
    type: 'Subcontractor',
    phoneNumber: '',
    mobileNumber: '+44 1234123456',
    email: 'steven@easymix.co.uk',
    address: '',
    cityResidence: '',
    stateProvince: '',
    postCode: 'NW2 6SB',
    country: 'UNITED KINGDOM',
    url: 'https://www.easymixconcrete.com/',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  },
  {
    id: 6,
    itemCode: 'SUB.CON.040',
    title: 'Easy Mix Concrete East',
    notes: '',
    description: '',
    contactPerson: 'Jane Smith',
    role: 'Contract Manager',
    type: 'Subcontractor',
    phoneNumber: '',
    mobileNumber: '+44 1234123456',
    email: 'jane@easymix.co.uk',
    address: '',
    cityResidence: '',
    stateProvince: '',
    postCode: 'NW2 6SB',
    country: 'UNITED KINGDOM',
    url: 'https://www.easymixconcrete.com/',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  },
  {
    id: 7,
    itemCode: 'CLT',
    title: 'Clients',
    notes: '',
    description: '',
    contactPerson: '',
    role: '',
    type: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    address: '',
    cityResidence: '',
    stateProvince: '',
    postCode: '',
    country: '',
    url: '',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  },
  {
    id: 8,
    itemCode: 'CLT.RIL',
    title: 'Rail',
    notes: '',
    description: '',
    contactPerson: '',
    role: '',
    type: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    address: '',
    cityResidence: '',
    stateProvince: '',
    postCode: '',
    country: '',
    url: '',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  },
  {
    id: 9,
    itemCode: 'CLT.RIL.010',
    title: 'Network Rail East',
    notes: '',
    description: '',
    contactPerson: 'Joyce Grey',
    role: 'Contract Manager',
    type: 'Subcontractor',
    phoneNumber: '',
    mobileNumber: '+44 1234123456',
    email: 'joyce@cemex.co.uk',
    address: '',
    cityResidence: '',
    stateProvince: '',
    postCode: 'NW2 6SB',
    country: 'UNITED KINGDOM',
    url: 'https://www.cemex.co.uk/',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  },
  {
    id: 10,
    itemCode: 'CLT.RIL.020',
    title: 'Network Rail South',
    notes: '',
    description: '',
    contactPerson: 'Rajesh Hassan',
    role: 'Sales Manager',
    type: 'Subcontractor',
    phoneNumber: '',
    mobileNumber: '+44 1234123456',
    email: 'rajesh@cemex.co.uk',
    address: '',
    cityResidence: '',
    stateProvince: '',
    postCode: 'NW2 6SB',
    country: 'UNITED KINGDOM',
    url: 'https://www.cemex.co.uk/',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  },
  {
    id: 11,
    itemCode: 'CLT.RIL.030',
    title: 'Network Rail Midlands',
    notes: '',
    description: '',
    contactPerson: 'Roberto Enriquez',
    role: 'Sales Director',
    type: 'Subcontractor',
    phoneNumber: '',
    mobileNumber: '+44 1234123456',
    email: 'roberto@easymix.co.uk',
    address: '',
    cityResidence: '',
    stateProvince: '',
    postCode: 'NW2 6SB',
    country: 'UNITED KINGDOM',
    url: 'https://www.easymixconcrete.com/',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  },
  {
    id: 12,
    itemCode: 'CLT.RIL.040',
    title: 'HS2 Main Works',
    notes: '',
    description: '',
    contactPerson: 'Marta Hunter',
    role: 'Contract Manager',
    type: 'Subcontractor',
    phoneNumber: '',
    mobileNumber: '+44 1234123456',
    email: 'marta@easymix.co.uk',
    address: '',
    cityResidence: '',
    stateProvince: '',
    postCode: 'NW2 6SB',
    country: 'UNITED KINGDOM',
    url: 'https://www.easymixconcrete.com/',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  },
]
