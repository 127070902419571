import { ExtendedColumnType } from '../../../../basic/table/sterling-table/SterlingTable'
import { numberSorter, stringSorter } from '../../../../../utils/Sorters'
import { currency, renderDecimal } from '../../../../../utils/rendering/Rendering'

export const complexResourcesDetailsColumns: ExtendedColumnType[] = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    width: 130,
    sorter: (a, b) => stringSorter(a.id, b.id),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: '',
  },
  {
    title: 'Item code',
    dataIndex: 'itemCode',
    key: 'item_code',
    fixed: 'left',
    width: 130,
    sorter: (a, b) => stringSorter(a.itemCode, b.itemCode),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'ascend',
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    width: 130,
    fixed: 'left',
    sorter: (a, b) => stringSorter(a.title, b.title),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 130,
    sorter: (a, b) => stringSorter(a.description, b.description),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    width: 130,
    sorter: (a, b) => stringSorter(a.unit, b.unit),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Manhours/Unit',
    dataIndex: 'manhours_unit',
    key: 'manhours_unit',
    width: 130,
    sorter: (a, b) => numberSorter(a.manhours_unit, b.manhours_unit),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: renderDecimal,
  },
  {
    title: 'Plant Hours/Unit',
    dataIndex: 'plant_hours_unit',
    key: 'plant_hours_unit',
    width: 130,
    sorter: (a, b) => numberSorter(a.plant_hours_unit, b.plant_hours_unit),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: renderDecimal,
  },
  {
    title: 'Material Consumption/Unit',
    dataIndex: 'material_consumption_unit',
    key: 'material_consumption_unit',
    width: 130,
    sorter: (a, b) => numberSorter(a.material_consumption_unit, b.material_consumption_unit),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: renderDecimal,
  },
  {
    title: 'Labour Rate',
    dataIndex: 'labour_rate',
    key: 'labour_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.labour_rate, b.labour_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: currency,
  },
  {
    title: 'Material Rate',
    dataIndex: 'material_rate',
    key: 'material_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.material_rate, b.material_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: currency,
  },
  {
    title: 'Plant Rate',
    dataIndex: 'plant_rate',
    key: 'plant_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.plant_rate, b.plant_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: currency,
  },
  {
    title: 'Subcontractor Rate',
    dataIndex: 'subcontractor_rate',
    key: 'subcontractor_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.subcontractor_rate, b.subcontractor_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: currency,
  },
  {
    title: 'Total Rate',
    dataIndex: 'total_rate',
    key: 'total_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_rate, b.total_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: currency,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    width: 130,
    sorter: (a, b) => stringSorter(a.currency, b.currency),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'CO2 Labour Rate',
    dataIndex: 'co2_labour_rate',
    key: 'co2_labour_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.co2_labour_rate, b.co2_labour_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'CO2 Material Rate',
    dataIndex: 'co2_material_rate',
    key: 'co2_material_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.co2_material_rate, b.co2_material_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: currency,
  },
  {
    title: 'CO2 Plant Rate',
    dataIndex: 'co2_plant_rate',
    key: 'co2_plant_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.co2_plant_rate, b.co2_plant_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: currency,
  },
  {
    title: 'CO2 Subcontractor Rate',
    dataIndex: 'co2_subcontractor_rate',
    key: 'co2_subcontractor_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.co2_subcontractor_rate, b.co2_subcontractor_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: currency,
  },
  {
    title: 'CO2 Unit Rate',
    dataIndex: 'co2_unit_rate',
    key: 'co2_unit_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.co2_unit_rate, b.co2_unit_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: currency,
  },
  {
    title: 'Creation Date',
    dataIndex: 'creation_date',
    key: 'creation_date',
    width: 130,
    sorter: (a, b) => stringSorter(a.creation_date, b.creation_date),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Last Edited',
    dataIndex: 'last_edited',
    key: 'last_edited',
    width: 130,
    sorter: (a, b) => stringSorter(a.last_edited, b.last_edited),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Creator',
    dataIndex: 'creator',
    key: 'creator',
    width: 130,
    sorter: (a, b) => stringSorter(a.creator, b.creator),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Last Editor',
    dataIndex: 'last_editor',
    key: 'last_editor',
    width: 130,
    sorter: (a, b) => stringSorter(a.last_editor, b.last_editor),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
]
