import { Key } from 'react'
import { ColumnsLayout } from '../../../model/ColumnLayouts'
import { createLayout } from '../../basic/table/columns-layout-manager/helpers/LayoutHelpers'

const TakeOffLayoutColumns = [
  'wbs_code',
  'title',
  'status',
  'location',
  'quantity',
  'unit',
  'estimated_quantity',
  'take_off_quantity',
  'quantity_difference',
]

const CostShortColumns = [
  'wbs_code',
  'title',
  'status',
  'quantity',
  'unit',
  'total_manhours',
  'total_plant_hours',
  'total_rate',
  'base_total',
  'base_mark_up_percent',
  'net_total',
  'net_mark_up_percent',
  'gross_total',
]

const CostDetailedColumns = [
  'wbs_code',
  'title',
  'status',
  'quantity',
  'unit',
  'total_manhours',
  'total_plant_hours',
  'labour_rate',
  'material_rate',
  'plant_rate',
  'subcontractor_rate',
  'total_rate',
  'total_labour_cost',
  'total_material_cost',
  'total_plant_cost',
  'total_subcontractor_cost',
  'base_total',
  'base_mark_up_percent',
  'net_total',
  'net_mark_up_percent',
  'gross_total',
]

const CarbonColumns = [
  'wbs_code',
  'title',
  'status',
  'quantity',
  'unit',
  'total_manhours',
  'total_plant_hours',
  'base_total',
  'labour_co2_rate',
  'material_co2_rate',
  'plant_co2_rate',
  'subcontract_co2_rate',
  'total_co2_rate',
  'total_labour_co2',
  'total_material_co2',
  'total_plant_co2',
  'total_subcontract_co2',
  'total_co2',
]

const VarianceColumns = [
  'wbs_code',
  'title',
  'status',
  'quantity',
  'unit',
  'total_rate',
  'base_total',
  'cost___least',
  'cost___likely',
  'cost___most',
  'total_co2_rate',
  'total_co2',
  'co2___least',
  'co2___likely',
  'co2___most',
]

const MarkupsColumns = [
  'wbs_code',
  'title',
  'status',
  'quantity',
  'unit',
  'total_rate',
  'base_total',
  'base_mark_up_type',
  'base_mark_up_value',
  'base_mark_up_percent',
  'net_total',
  'net_mark_up_type',
  'net_mark_up_value',
  'net_mark_up_percent',
  'gross_total',
]

const BidColumns = [
  'wbs_code',
  'title',
  'status',
  'quantity',
  'unit',
  'total_rate',
  'base_total',
  'awarded_labour_rate',
  'awarded_material_rate',
  'awarded_plant_rate',
  'awarded_subcontract_rate',
  'awarded_total_rate',
  'rate_difference',
  'awarded_labour_cost',
  'awarded_material_cost',
  'awarded_plant_cost',
  'awarded_subcontract_cost',
  'awarded_total_cost',
  'total_cost_difference',
]


export const BillOfQuantitiesDefaultLayouts: ColumnsLayout[] = [
  createLayout('Take-off', TakeOffLayoutColumns),
  createLayout('Cost short', CostShortColumns),
  createLayout('Cost detailed', CostDetailedColumns),
  createLayout('Carbon', CarbonColumns),
  createLayout('Variance', VarianceColumns),
  createLayout('Mark-ups', MarkupsColumns),
  createLayout('Bid', BidColumns),
]
