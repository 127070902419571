import React from 'react'
import { ForgeAuthService } from '../../../services/forge/ForgeAuthService'
import { ModelTreeLoader } from './helpers/ModelTreeLoader'
import './ForgeViewer.css'
// @ts-ignore
import Script from 'react-load-script'
import { NodePropertiesLoader } from './helpers/NodePropertiesLoader'
import { Vector3, Matrix4, Color } from 'three'
import { Model, ModelNode } from './ForgeViewer.model'
import { MeasurementsReader } from './controls/MeasurementsReader'
import { arraysDifference } from '../../../utils/collections/ArraysUtils'

type InstanceTree = Autodesk.Viewing.InstanceTree
type ErrorCodes = Autodesk.Viewing.ErrorCodes
type AutodeskModel = Autodesk.Viewing.Model

export interface SelectedNodes {
  model: Model
  nodeIds: number[]
}

export interface NodesVisibility {
  model: Model
  nodeIds: number[]
}

export interface ModelDocument {
  urn: string
  placementTransform?: Vector3
}

export interface DiffProperties {
  mainModel: Model
  diffModel: Model
}

interface ForgeViewerProps {
  documents: ModelDocument[]
  visibleModelsUrns: string[]
  onModelLoaded?: (model: Model) => void
  selectedNodes?: SelectedNodes[]
  nodesVisibility?: NodesVisibility[] // by default when prop is missing all nodes are visible
  diff?: DiffProperties
  onMeasurementReaderInitialized?: (measurementReader: MeasurementsReader) => void
}

const extensionToLoad = ['Autodesk.DocumentBrowser', 'Autodesk.ModelStructure', 'Autodesk.PDF', 'Autodesk.Measure']

export class ForgeViewer extends React.Component<ForgeViewerProps, {}> {
  viewer?: Autodesk.Viewing.GuiViewer3D
  loadedAutodeskModels: { [key: number]: AutodeskModel } = {}
  loadedModelsNodes: { [key: number]: number[] } = {}
  urnToLoadedModel: { [urn: string]: AutodeskModel } = {}

  initializeViewer = (): void => {
    const htmlDiv = document.getElementById('forgeViewer')
    if (htmlDiv) {
      this.viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv)
      const startedCode = this.viewer.start()
      if (startedCode > 0) {
        // tslint:disable-next-line:no-console
        console.error('Failed to create a Viewer: WebGL not supported.')
        return
      }

      Promise.all(extensionToLoad.map((extension) => this.viewer?.loadExtension(extension))).then(() => {
        if (this.props.onMeasurementReaderInitialized)
          this.props.onMeasurementReaderInitialized(
            new MeasurementsReader(() => this.viewer?.getExtension('Autodesk.Measure'))
          )
        // tslint:disable-next-line:no-console
        console.log('Initialization complete, loading a model next...')
        this.loadDocuments()
      })
    }
  }

  loadDocuments = (): void => {
    this.props.documents.forEach((document) =>
      Autodesk.Viewing.Document.load(
        document.urn,
        this.onDocumentLoadSuccess(document),
        this.onDocumentLoadFailure(document.urn)
      )
    )
  }

  onDocumentLoadSuccess = (document: ModelDocument) => (viewerDocument: Autodesk.Viewing.Document): void => {
    const defaultModel = viewerDocument.getRoot().getDefaultGeometry()
    if (this.viewer) {
      this.viewer
        .loadDocumentNode(viewerDocument, defaultModel, {
          placementTransform: new Matrix4().setPosition(
            document.placementTransform ? document.placementTransform : new Vector3(0, 0, 0)
          ),
          keepCurrentModels: true,
          globalOffset: { x: 0, y: 0, z: 0 },
        })
        .then((model) => this.processLoadedModel(model, document))
    }
  }

  processLoadedModel = (model: AutodeskModel, document: ModelDocument): void => {
    this.loadedAutodeskModels[model.id] = model
    this.urnToLoadedModel[document.urn] = model

    model.getObjectTree(
      (tree: InstanceTree) => {
        new ModelTreeLoader(
          tree,
          new NodePropertiesLoader((dbId, successCallback, errorCallback) =>
            // @ts-ignore
            model.getProperties(dbId, successCallback, errorCallback)
          )
        ).load((rootNode) => {
          this.loadedModelsNodes[model.id] = flattenToIds([rootNode])
          if (this.props.onModelLoaded)
            this.props.onModelLoaded({
              documentUrn: document.urn,
              modelId: model.id,
              root: rootNode,
            })

          if (this.props.selectedNodes) {
            this.props.selectedNodes.filter((n) => n.model.modelId === model.id).map(this.selectItems)
          }
        })
      },
      () => {
        // we assume it means model does not have object tree (for example it is pdf)
        this.loadedModelsNodes[model.id] = []
        if (this.props.onModelLoaded)
          this.props.onModelLoaded({
            documentUrn: document.urn,
            modelId: model.id,
          })
      }
    )
    if (!this.props.visibleModelsUrns.includes(document.urn)) {
      this.viewer?.hideModel(model)
    }
  }

  onDocumentLoadFailure = (documentUrn: string) => (errorCode: ErrorCodes, errorMsg: string): void => {
    // tslint:disable-next-line:no-console
    console.error(JSON.stringify(errorCode) + errorMsg + ' for ' + documentUrn)
  }

  loadViewer = () => {
    const options = {
      env: 'AutodeskProduction',
      getAccessToken: (onGetAccessToken: any) => ForgeAuthService.getAccessToken(onGetAccessToken),
      api: 'derivativeV2',
    }
    Autodesk.Viewing.Initializer(options, this.initializeViewer)
  }

  componentWillUnmount() {
    if (this.viewer) this.viewer.finish()
    this.viewer = undefined
    Autodesk.Viewing.shutdown()
  }

  selectItems = (selection: SelectedNodes) => {
    if (this.props.visibleModelsUrns.includes(selection.model.documentUrn)) {
      selection.nodeIds.forEach((id) =>
        this.viewer?.toggleSelect(
          id,
          this.loadedAutodeskModels[selection.model.modelId],
          Autodesk.Viewing.SelectionMode.OVERLAYED
        )
      )
      this.viewer?.fitToView(selection.nodeIds, this.loadedAutodeskModels[selection.model.modelId])
    }
  }

  componentDidUpdate(prevProps: Readonly<ForgeViewerProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (this.props.diff && this.props.diff !== prevProps.diff) {
      this.handleDiffChange()
    }
    this.handleModelsVisibilityChange(this.props.visibleModelsUrns, prevProps.visibleModelsUrns)
    this.handleNodesVisibilityChange(this.props.nodesVisibility, prevProps.nodesVisibility)
    if (this.props.selectedNodes && this.props.selectedNodes !== prevProps.selectedNodes) {
      this.viewer?.clearSelection()
      this.props.selectedNodes.forEach(this.selectItems)
    }
  }

  handleDiffChange = () => {
    if (this.props.diff) {
      const mainModel = this.loadedAutodeskModels[this.props.diff.mainModel.modelId]
      const diffModel = this.loadedAutodeskModels[this.props.diff.diffModel.modelId]

      const extensionConfig = {
        mimeType: 'application/vnd.autodesk.revit',
        primaryModels: [mainModel],
        diffModels: [diffModel],
        diffMode: 'overlay',
        versionA: '2',
        versionB: '1',
      }
      this.viewer
        ?.loadExtension('Autodesk.DiffTool', extensionConfig)
        .then(() => {
          // tslint:disable-next-line:no-console
          console.log('Loaded diff tool')
        })
        .catch((err) => {
          // tslint:disable-next-line:no-console
          console.error(err)
        })
    } else {
      this.viewer?.deactivateExtension('Autodesk.DiffTool')
    }
  }

  handleModelsVisibilityChange = (currentlyVisibleUrns: string[], prevVisibleUrns: string[]) => {
    if (currentlyVisibleUrns !== prevVisibleUrns) {
      const modelsToHide = arraysDifference(prevVisibleUrns, currentlyVisibleUrns)
      const modelsToShow = arraysDifference(currentlyVisibleUrns, prevVisibleUrns)
      modelsToHide.forEach((m) => this.viewer?.hideModel(this.urnToLoadedModel[m]))
      modelsToShow.forEach((m) => this.viewer?.showModel(this.urnToLoadedModel[m], true))
    }
  }

  handleNodesVisibilityChange = (
    currentVisibility: NodesVisibility[] | undefined,
    prevVisibility: NodesVisibility[] | undefined
  ) => {
    if (currentVisibility === undefined && prevVisibility === undefined) {
      return
    }
    if (currentVisibility === undefined) {
      this.viewer?.showAll()
    } else if (prevVisibility === undefined) {
      Object.values(this.loadedAutodeskModels).forEach((autodeskModel) => {
        this.loadedModelsNodes[autodeskModel.id].forEach((id) => this.hideNode(id, autodeskModel))
      })
      currentVisibility.forEach((visibleNodes) => {
        const autodeskModel = this.loadedAutodeskModels[visibleNodes.model.modelId]
        visibleNodes.nodeIds.forEach((id) => this.showNode(id, autodeskModel))
      })
    } else {
      const prevVisibleModelsIds = new Set(prevVisibility.map((item) => item.model.modelId))
      const modelsToDiff = currentVisibility.filter((item) => prevVisibleModelsIds.has(item.model.modelId))
      modelsToDiff.forEach((newVisibility) => {
        const previouslyVisibleModelNodes = prevVisibility.find((x) => x.model.modelId === newVisibility.model.modelId)
        if (previouslyVisibleModelNodes) {
          const nodesToHide = arraysDifference(previouslyVisibleModelNodes.nodeIds, newVisibility.nodeIds)
          const nodesToShow = arraysDifference(newVisibility.nodeIds, previouslyVisibleModelNodes.nodeIds)
          const autodeskModel = this.loadedAutodeskModels[newVisibility.model.modelId]
          nodesToHide.forEach((id) => this.hideNode(id, autodeskModel))
          nodesToShow.forEach((id) => this.showNode(id, autodeskModel))
        }
      })

      const newModels = arraysDifference(currentVisibility, prevVisibility)
      newModels.forEach((item) => {
        const autodeskModel = this.loadedAutodeskModels[item.model.modelId]
        this.loadedModelsNodes[item.model.modelId].forEach((id) => this.hideNode(id, autodeskModel)) // make sure none unexpected node is visible
        item.nodeIds.forEach((id) => this.showNode(id, autodeskModel))
      })
    }
  }

  showNode = (id: number, autodeskModel: AutodeskModel): void => {
    // @ts-ignore // it is needed, because in newer versions forge viewer requires two parameters, unfortunately types are not updated yet
    this.viewer?.show(id, autodeskModel)
  }

  hideNode = (id: number, autodeskModel: AutodeskModel): void => {
    // @ts-ignore // it is needed, because in newer versions forge viewer requires two parameters, unfortunately types are not updated yet
    this.viewer?.hide(id, autodeskModel)
  }

  render() {
    return (
      <>
        <div id="forgeViewer" />
        <Script
          url={'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.28/viewer3D.min.js'}
          onLoad={this.loadViewer}
        />
      </>
    )
  }
}

const flattenToIds = (entries: ModelNode[]): number[] => {
  const currentIds = entries.map((e) => e.id)
  const childrenIds = entries
    .filter((e) => e.children)
    .map((e) => e.children)
    .flatMap((es) => (es ? flattenToIds(es) : []))
  return currentIds.concat(childrenIds)
}

export const createDocument = (urn: string): ModelDocument => {
  return {
    urn,
  }
}
