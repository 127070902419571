import { ExtendedColumnType } from '../../../../basic/table/sterling-table/SterlingTable'
import { numberSorter, stringSorter } from '../../../../../utils/Sorters'
import { currency, renderDecimal } from '../../../../../utils/rendering/Rendering'

export const complexResourcesColumns: ExtendedColumnType[] = [
  {
    title: 'Item Code',
    dataIndex: 'itemCode',
    key: 'item_code',
    fixed: 'left',
    width: 130,
    sorter: (a, b) => stringSorter(a.itemCode, b.itemCode),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: '',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    fixed: 'left',
    key: 'title',
    width: 130,
    sorter: (a, b) => stringSorter(a.title, b.title),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'ascend',
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    width: 130,
    sorter: (a, b) => stringSorter(a.notes, b.notes),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 130,
    sorter: (a, b) => stringSorter(a.description, b.description),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    width: 130,
    sorter: (a, b) => stringSorter(a.unit, b.unit),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Labour Rate',
    dataIndex: 'labour_rate',
    key: 'labour_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.labour_rate, b.labour_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: currency,
  },
  {
    title: 'Material Rate',
    dataIndex: 'material_rate',
    key: 'material_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.material_rate, b.material_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: currency,
  },
  {
    title: 'Plant Rate',
    dataIndex: 'plant_rate',
    key: 'plant_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.plant_rate, b.plant_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: currency,
  },
  {
    title: 'Subcontractor Rate',
    dataIndex: 'subcontractor_rate',
    key: 'subcontractor_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.subcontractor_rate, b.subcontractor_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: currency,
  },
  {
    title: 'Total Unit Rate',
    dataIndex: 'total_unit_rate',
    key: 'total_unit_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_unit_rate, b.total_unit_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: currency,
  },
  {
    title: 'CO2 Plant Rate',
    dataIndex: 'co2_plant_rate',
    key: 'co2_plant_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.co2_plant_rate, b.co2_plant_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: renderDecimal,
  },
  {
    title: 'CO2 Labour Rate',
    dataIndex: 'co2_labour_rate',
    key: 'co2_labour_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.co2_labour_rate, b.co2_labour_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: renderDecimal,
  },
  {
    title: 'CO2 Material Rate',
    dataIndex: 'co2_material_rate',
    key: 'co2_material_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.co2_material_rate, b.co2_material_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: renderDecimal,
  },
  {
    title: 'CO2 Subcontract Rate',
    dataIndex: 'co2_subcontract_rate',
    key: 'co2_subcontract_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.co2_subcontract_rate, b.co2_subcontract_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: renderDecimal,
  },
  {
    title: 'CO2 Unit Rate',
    dataIndex: 'co2_unit_rate',
    key: 'co2_unit_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.co2_unit_rate, b.co2_unit_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: renderDecimal,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    width: 130,
    sorter: (a, b) => stringSorter(a.currency, b.currency),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Productivity',
    dataIndex: 'productivity',
    key: 'productivity',
    width: 130,
    sorter: (a, b) => numberSorter(a.productivity, b.productivity),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: renderDecimal,
  },
  {
    title: 'Manhours/Unit',
    dataIndex: 'manhours_unit',
    key: 'manhours_unit',
    width: 130,
    sorter: (a, b) => numberSorter(a.manhours_unit, b.manhours_unit),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
    render: renderDecimal,
  },
  {
    title: 'Company',
    dataIndex: 'company',
    key: 'company',
    width: 130,
    sorter: (a, b) => stringSorter(a.company, b.company),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Contact Person',
    dataIndex: 'contact_person',
    key: 'contact_person',
    width: 130,
    sorter: (a, b) => stringSorter(a.contact_person, b.contact_person),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Phone Number',
    dataIndex: 'phone_number',
    key: 'phone_number',
    width: 130,
    sorter: (a, b) => stringSorter(a.phone_number, b.phone_number),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Mobile Number',
    dataIndex: 'mobile_number',
    key: 'mobile_number',
    width: 130,
    sorter: (a, b) => stringSorter(a.mobile_number, b.mobile_number),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'E-Mail',
    dataIndex: 'e_mail',
    key: 'e_mail',
    width: 130,
    sorter: (a, b) => stringSorter(a.e_mail, b.e_mail),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    width: 130,
    sorter: (a, b) => stringSorter(a.address, b.address),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'City/Residence',
    dataIndex: 'city_residence',
    key: 'city_residence',
    width: 130,
    sorter: (a, b) => stringSorter(a.city_residence, b.city_residence),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'State / Province',
    dataIndex: 'state_province',
    key: 'state_province',
    width: 130,
    sorter: (a, b) => stringSorter(a.state_province, b.state_province),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Post Code',
    dataIndex: 'post_code',
    key: 'post_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.post_code, b.post_code),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    width: 130,
    sorter: (a, b) => stringSorter(a.country, b.country),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Uniclass Ss Code',
    dataIndex: 'uniclass_ss_code',
    key: 'uniclass_ss_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.uniclass_ss_code, b.uniclass_ss_code),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Uniclass Pr Code',
    dataIndex: 'uniclass_pr_code',
    key: 'uniclass_pr_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.uniclass_pr_code, b.uniclass_pr_code),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'WBS Code',
    dataIndex: 'wbs_code',
    key: 'wbs_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.wbs_code, b.wbs_code),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'ICMS Code',
    dataIndex: 'icms_code',
    key: 'icms_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.icms_code, b.icms_code),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'NRM Code',
    dataIndex: 'nrm_code',
    key: 'nrm_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.nrm_code, b.nrm_code),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'RMM Code',
    dataIndex: 'rmm_code',
    key: 'rmm_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.rmm_code, b.rmm_code),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'CESMM4 Code',
    dataIndex: 'cesmm4_code',
    key: 'cesmm4_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.cesmm4_code, b.cesmm4_code),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Company Code',
    dataIndex: 'company_code',
    key: 'company_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.company_code, b.company_code),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Creation Date',
    dataIndex: 'creation_date',
    key: 'creation_date',
    width: 130,
    sorter: (a, b) => stringSorter(a.creation_date, b.creation_date),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Last Edited',
    dataIndex: 'last_edited',
    key: 'last_edited',
    width: 130,
    sorter: (a, b) => stringSorter(a.last_edited, b.last_edited),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Creator',
    dataIndex: 'creator',
    key: 'creator',
    width: 130,
    sorter: (a, b) => stringSorter(a.creator, b.creator),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
  {
    title: 'Last Editor',
    dataIndex: 'last_editor',
    key: 'last_editor',
    width: 130,
    sorter: (a, b) => stringSorter(a.last_editor, b.last_editor),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: 'undefined',
  },
]
