import React, { FunctionComponent, Key } from 'react'
import SterlingTable from '../../../basic/table/sterling-table/SterlingTable'
import { Empty } from 'antd'
import { SterlingTableHeader } from '../../../basic/table/sterling-table/header/SterlingTableHeader'

/* tslint:disable:no-empty */

interface BoQDetailsTablePaneProps {
  selectedIds: Key[]
  columns: any[]
  data: any[]
  id: string
  tableHeight: number
}

type Props = BoQDetailsTablePaneProps

const BoQDetailsTablePane: FunctionComponent<Props> = ({ columns, data, id, selectedIds, tableHeight }) => {
  if (selectedIds.length === 1) {
    const content = data.filter((entry) => selectedIds.includes(entry.boqid))
    if (content.length > 0) {
      return (
        <SterlingTable
          onUpdate={() => {}}
          columns={columns}
          defaultPagination={false}
          dataSource={content}
          tableId={id}
          size={'small'}
          renderHeader={(props) => <SterlingTableHeader {...props} />}
          tableHeight={tableHeight}
        />
      )
    } else {
      return <Empty description={'No data for this cost item'} />
    }
  } else {
    return <Empty description={selectedIds.length > 1 ? 'Select only one item' : 'Select an item'} />
  }
}

export default BoQDetailsTablePane
