import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../context/reducer'
import { parseTakeOffTableDataFiles } from './helpers/TakeOffTableDataParser'
import { TakeOffFiles } from '../../../data/take-off/TakeOff.data'
import { TakeOffTableColumnsDisabledEdition } from './columns/TakeOffTable.columns'
import { filterRecursively, replaceRecursively } from '../../../utils/FunctionalUtils'
import SterlingTable from '../../basic/table/sterling-table/SterlingTable'
import { Col, Row } from 'antd'
import { createDndContext, DndProvider } from 'react-dnd'
import WorkPackagesBoQ from '../work-packages/headers/WorkPackagesBoQ'
import { DraggableBodyRow, DroppableBodyRow } from '../work-packages/drag-drop/DragDropRows'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { HTML5Backend } from 'react-dnd-html5-backend'

const RNDContext = createDndContext(HTML5Backend)

export const TakeOffMapping = () => {
  const takeOff2DItems = useSelector((rootState: RootState) => rootState.takeOffs.takeOff2DItems)
  const data = parseTakeOffTableDataFiles(TakeOffFiles, takeOff2DItems)
  const billOfQuantities = useSelector((state: RootState) => state.sterlingTable.parsedData.billOfQuantities)

  const [draggableData, setDraggableData] = useState(data)
  const [droppableData, setDroppableData] = useState(billOfQuantities)
  const rowHeight = useWindowDimensions().height / 8
  const manager = useRef(RNDContext)

  const dragRow = (dragItemKey: number, [dragItem]: any[]) => {
    if (!dragItem || !dragItemKey) return
    const draggableDataCopy = draggableData.slice()
    setDraggableData(filterRecursively(draggableDataCopy, (item) => item.key !== dragItemKey))
  }

  const dropRow = (dropItemKey: number, [dragItem, dropItem]: any[]) => {
    if (!dropItem || !dropItemKey) return
    const newItem = Object.assign(dropItem, {
      externalData: dropItem.externalData ? [...dropItem.externalData, dragItem] : [dragItem],
    })
    const droppableDataCopy = droppableData.slice()
    replaceRecursively(droppableDataCopy, newItem)
    setDroppableData(droppableDataCopy)
  }

  return (
    <DndProvider manager={manager.current.dragDropManager!}>
      <Row gutter={24}>
        <Col span={12}>
          <SterlingTable
            columns={TakeOffTableColumnsDisabledEdition}
            dataSource={draggableData}
            tableId={'take-off-mapping-items'}
            size={'middle'}
            tableHeight={rowHeight * 6}
            row={(props: any) => <DraggableBodyRow {...props} dndType={'TakeOffMappingDnD'} />}
            onRow={(record, index) => {
              return {
                index,
                record,
                moveRow: dragRow,
              }
            }}
            defaultPagination={false}
          />
        </Col>
        <Col span={12}>
          <WorkPackagesBoQ
            data={droppableData}
            rowHeight={rowHeight}
            tableId={'take-off-mapping-boq'}
            row={(props: any) => <DroppableBodyRow {...props} dndType={'TakeOffMappingDnD'} />}
            onRow={(record, index) => {
              return {
                index,
                record,
                moveRow: dropRow,
              }
            }}
            pixelPerfectHeightBonus={16}
          />
        </Col>
      </Row>
    </DndProvider>
  )
}
