import React from 'react'
import { EditOutlined, EyeOutlined, StopOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import { ExtendedColumnType } from '../../basic/table/sterling-table/SterlingTable'
import { RenderGeneralInformation, RenderRounding, RenderUnits, RenderViewer } from '../administration/utils/Renders'
import { stringSorter } from '../../../utils/Sorters'

const { Option } = Select

const RenderPermissions = () => {
  return (
    <Select defaultValue="read" style={{ width: 120 }}>
      <Option value="read">
        <EyeOutlined style={{ color: '#FAAD14' }} /> Read
      </Option>
      <Option value="edit">
        <EditOutlined style={{ color: '#5B8C00' }} /> Edit
      </Option>
      <Option value="access">
        <StopOutlined style={{ color: '#CF1322' }} /> No access
      </Option>
    </Select>
  )
}

export const settingsUsersColumns: ExtendedColumnType[] = [
  {
    title: 'Cost Plan',
    dataIndex: 'cost_plan',
    key: 'cost_plan',
    width: 130,
    render: RenderPermissions,
  },

  {
    title: 'Documentation',
    dataIndex: 'documentation',
    key: 'documentation',
    width: 130,
    render: RenderPermissions,
  },
  {
    title: 'Take-off',
    dataIndex: 'take_off',
    key: 'take_off',
    width: 130,
    render: RenderPermissions,
  },
  {
    title: 'Work Packages',
    dataIndex: 'work_packages',
    key: 'work_packages',
    width: 130,
    render: RenderPermissions,
  },
  {
    title: 'Schedule',
    dataIndex: 'schedule',
    key: 'schedule',
    width: 130,
    render: RenderPermissions,
  },
  {
    title: 'Valuations',
    dataIndex: 'valuations',
    key: 'valuations',
    width: 130,
    render: RenderPermissions,
  },
  {
    title: 'Libraries',
    dataIndex: 'libraries',
    key: 'libraries',
    width: 130,
    render: RenderPermissions,
  },
]

export const settingsProjectPropertiesData = [
  {
    title: 'General Information',
    dataIndex: 'general_information',
    key: 'general_information',
    width: 130,
    render: <RenderGeneralInformation />,
  },
  {
    title: 'Client',
    dataIndex: 'client',
    key: 'client',
    width: 130,
    // render: <RenderCurrencyInformation />,
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    width: 130,
    // render: <RenderRounding />,
  },
  {
    title: 'Properties',
    dataIndex: 'properties',
    key: 'properties',
    width: 130,
    // render: <RenderRounding />,
  },
  {
    title: 'Rounding - cash',
    dataIndex: 'rounding_cash',
    key: 'rounding_cash',
    width: 130,
    render: <RenderRounding />,
  },
  {
    title: 'Rounding - carbon',
    dataIndex: 'rounding_carbon',
    key: 'rounding_carbon',
    width: 130,
    render: <RenderRounding />,
  },
  {
    title: 'Rounding - take-off',
    dataIndex: 'rounding_take_off',
    key: 'rounding_take_off',
    width: 130,
    render: <RenderRounding />,
  },
  {
    title: 'Units',
    dataIndex: 'units',
    key: 'units',
    width: 130,
    render: <RenderUnits />,
  },
  {
    title: 'Viewer',
    dataIndex: 'viewer',
    key: 'viewer',
    width: 130,
    render: <RenderViewer />,
  },
]

export const settingsRevisionsColumns: ExtendedColumnType[] = [
  {
    title: 'Revision Number',
    dataIndex: 'revision_number',
    key: 'revision_number',
    width: 130,
    sorter: (a, b) => stringSorter(a.revision_number, b.revision_number),
  },
  {
    title: 'Revision Name',
    dataIndex: 'revision_name',
    key: 'revision_name',
    width: 130,
    defaultSortOrder: 'descend',
    sorter: (a, b) => stringSorter(a.revision_name, b.revision_name),
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    width: 130,
    sorter: (a, b) => stringSorter(a.note, b.note),
  },
  {
    title: 'Project value',
    dataIndex: 'project_value',
    key: 'project_value',
    width: 130,
    sorter: (a, b) => stringSorter(a.project_value, b.project_value),
  },
  {
    title: 'Creator',
    dataIndex: 'creator',
    key: 'creator',
    width: 130,
    sorter: (a, b) => stringSorter(a.creator, b.creator),
  },
  {
    title: 'Creation Data',
    dataIndex: 'creation_date',
    key: 'creation_date',
    width: 130,
    sorter: (a, b) => stringSorter(a.creation_date, b.creation_date),
  },
  {
    title: 'Editor',
    dataIndex: 'editor',
    key: 'editor',
    width: 130,
    sorter: (a, b) => stringSorter(a.editor, b.editor),
  },
  {
    title: 'Last Update',
    dataIndex: 'last_update',
    key: 'last_update',
    width: 130,
    sorter: (a, b) => stringSorter(a.last_update, b.last_update),
  },
]
