export interface ComplexResourcesItem {
  id: number
  itemCode: string
  title: string
  notes: string
  description: string
  unit: string
  labour_rate: number
  material_rate: number
  plant_rate: number
  subcontractor_rate: number
  total_unit_rate: number
  co2_plant_rate: number
  co2_labour_rate: number
  co2_material_rate: number
  co2_subcontract_rate: number
  co2_unit_rate: number
  currency: string
  productivity: string
  manhours_unit: number
  company: string
  contact_person: string
  phone_number: string
  mobile_number: string
  e_mail: string
  address: string
  city_residence: string
  state_province: string
  post_code: string
  country: string
  uniclass_ss_code: string
  uniclass_pr_code: string
  wbs_code: string
  icms_code: string
  nrm_code: string
  rmm_code: string
  cesmm4_code: string
  company_code: string
  creation_date: string
  last_edited: string
  creator: string
  last_editor: string
}

export function emptyComplexResourcesItem() {
  return {
    id: -1,
    itemCode: '',
    title: '',
    notes: '',
    description: '',
    unit: '',
    labour_rate: '',
    material_rate: '',
    plant_rate: '',
    subcontractor_rate: '',
    total_unit_rate: '',
    co2_plant_rate: '',
    co2_labour_rate: '',
    co2_material_rate: '',
    co2_subcontract_rate: '',
    co2_unit_rate: '',
    currency: '',
    productivity: '',
    manhours_unit: '',
    company: '',
    contact_person: '',
    phone_number: '',
    mobile_number: '',
    e_mail: '',
    address: '',
    city_residence: '',
    state_province: '',
    post_code: '',
    country: '',
    uniclass_ss_code: '',
    uniclass_pr_code: '',
    wbs_code: '',
    icms_code: '',
    nrm_code: '',
    rmm_code: '',
    cesmm4_code: '',
    company_code: '',
    creation_date: '',
    last_edited: '',
    creator: '',
    last_editor: '',
  }
}

export const complexResourcesData = [
  {
    id: 2,
    itemCode: 'F',
    title: 'Class F: In Situ Concrete',
    notes: '',
    description: '',
    unit: '',
    labour_rate: '',
    material_rate: '',
    plant_rate: '',
    subcontractor_rate: '',
    total_unit_rate: '',
    co2_plant_rate: '',
    co2_labour_rate: '',
    co2_material_rate: '',
    co2_subcontract_rate: '',
    co2_unit_rate: '',
    currency: '',
    productivity: '',
    manhours_unit: '',
    company: '',
    contact_person: '',
    phone_number: '',
    mobile_number: '',
    e_mail: '',
    address: '',
    city_residence: '',
    state_province: '',
    post_code: '',
    country: '',
    uniclass_ss_code: '',
    uniclass_pr_code: '',
    wbs_code: '',
    icms_code: '',
    nrm_code: '',
    rmm_code: '',
    cesmm4_code: '',
    company_code: '',
    creation_date: '',
    last_edited: '',
    creator: '',
    last_editor: '',
  },
  {
    id: 3,
    itemCode: 'F.01',
    title: 'Designated concrete',
    notes: '',
    description: '',
    unit: 'M3',
    labour_rate: 0,
    material_rate: 104.5,
    plant_rate: 0,
    subcontractor_rate: 0,
    total_unit_rate: 104.5,
    co2_plant_rate: 0,
    co2_labour_rate: 0,
    co2_material_rate: 0,
    co2_subcontract_rate: 0,
    co2_unit_rate: 0.28,
    currency: 'GBP - Great Britain Pound',
    productivity: 1,
    manhours_unit: 1,
    company: '',
    contact_person: '',
    phone_number: '',
    mobile_number: '',
    e_mail: '',
    address: '',
    city_residence: '',
    state_province: '',
    post_code: '',
    country: '',
    uniclass_ss_code: '',
    uniclass_pr_code: '',
    wbs_code: '',
    icms_code: '',
    nrm_code: '',
    rmm_code: '',
    cesmm4_code: '',
    company_code: '',
    creation_date: '',
    last_edited: '',
    creator: '',
    last_editor: '',
  },
  {
    id: 4,
    itemCode: 'F.01.01',
    title: 'Strength: RC25/30',
    notes: '',
    description: '',
    unit: 'M3',
    labour_rate: 0,
    material_rate: 96.5,
    plant_rate: 0,
    subcontractor_rate: 0,
    total_unit_rate: 96.5,
    co2_plant_rate: 0,
    co2_labour_rate: 0,
    co2_material_rate: 0,
    co2_subcontract_rate: 0,
    co2_unit_rate: 0.28,
    currency: 'GBP - Great Britain Pound',
    productivity: 1,
    manhours_unit: 1,
    company: 'Cemex London',
    contact_person: '',
    phone_number: '',
    mobile_number: '',
    e_mail: '',
    address: '',
    city_residence: '',
    state_province: '',
    post_code: 'NW2 6SB',
    country: 'UNITED KINGDOM',
    uniclass_ss_code: '',
    uniclass_pr_code: '',
    wbs_code: '',
    icms_code: '',
    nrm_code: '',
    rmm_code: '',
    cesmm4_code: '',
    company_code: '',
    creation_date: '',
    last_edited: '',
    creator: '',
    last_editor: '',
  },
  {
    id: 5,
    itemCode: 'F.01.01.01',
    title: 'Maximum aggregate size 10 mm',
    notes: '',
    description: '',
    unit: 'M3',
    labour_rate: 0,
    material_rate: 126.28,
    plant_rate: 0,
    subcontractor_rate: 0,
    total_unit_rate: 126.28,
    co2_plant_rate: 0,
    co2_labour_rate: 0,
    co2_material_rate: 0,
    co2_subcontract_rate: 0,
    co2_unit_rate: 0.28,
    currency: 'GBP - Great Britain Pound',
    productivity: 1,
    manhours_unit: 1,
    company: 'Cemex London',
    contact_person: '',
    phone_number: '',
    mobile_number: '',
    e_mail: '',
    address: '',
    city_residence: '',
    state_province: '',
    post_code: 'NW2 6SB',
    country: 'UNITED KINGDOM',
    uniclass_ss_code: '',
    uniclass_pr_code: '',
    wbs_code: '',
    icms_code: '',
    nrm_code: '',
    rmm_code: '',
    cesmm4_code: '',
    company_code: '',
    creation_date: '',
    last_edited: '',
    creator: '',
    last_editor: '',
  },
  {
    id: 7,
    itemCode: 'F.01.01.02',
    title: 'Maximum aggregate size 20 mm',
    notes: '',
    description: '',
    unit: 'M3',
    labour_rate: 0,
    material_rate: 126.28,
    plant_rate: 0,
    subcontractor_rate: 0,
    total_unit_rate: 126.28,
    co2_plant_rate: 0,
    co2_labour_rate: 0,
    co2_material_rate: 0,
    co2_subcontract_rate: 0,
    co2_unit_rate: 0.28,
    currency: 'GBP - Great Britain Pound',
    productivity: 1,
    manhours_unit: 1,
    company: 'Cemex London',
    contact_person: '',
    phone_number: '',
    mobile_number: '',
    e_mail: '',
    address: '',
    city_residence: '',
    state_province: '',
    post_code: 'NW2 6SB',
    country: 'UNITED KINGDOM',
    uniclass_ss_code: '',
    uniclass_pr_code: '',
    wbs_code: '',
    icms_code: '',
    nrm_code: '',
    rmm_code: '',
    cesmm4_code: '',
    company_code: '',
    creation_date: '',
    last_edited: '',
    creator: '',
    last_editor: '',
  },
  {
    id: 8,
    itemCode: 'F.01.01.03',
    title: 'Maximum aggregate size 40 mm',
    notes: '',
    description: '',
    unit: 'M3',
    labour_rate: 0,
    material_rate: 126.28,
    plant_rate: 0,
    subcontractor_rate: 0,
    total_unit_rate: 126.28,
    co2_plant_rate: 0,
    co2_labour_rate: 0,
    co2_material_rate: 0,
    co2_subcontract_rate: 0,
    co2_unit_rate: 0.28,
    currency: 'GBP - Great Britain Pound',
    productivity: 1,
    manhours_unit: 1,
    company: 'Cemex London',
    contact_person: '',
    phone_number: '',
    mobile_number: '',
    e_mail: '',
    address: '',
    city_residence: '',
    state_province: '',
    post_code: 'NW2 6SB',
    country: 'UNITED KINGDOM',
    uniclass_ss_code: '',
    uniclass_pr_code: '',
    wbs_code: '',
    icms_code: '',
    nrm_code: '',
    rmm_code: '',
    cesmm4_code: '',
    company_code: '',
    creation_date: '',
    last_edited: '',
    creator: '',
    last_editor: '',
  },
  {
    id: 9,
    itemCode: 'F.01.02',
    title: 'Strength: RC28/35',
    notes: '',
    description: '',
    unit: 'M3',
    labour_rate: 0,
    material_rate: 101.5,
    plant_rate: 0,
    subcontractor_rate: 0,
    total_unit_rate: 101.5,
    co2_plant_rate: 0,
    co2_labour_rate: 0,
    co2_material_rate: 0,
    co2_subcontract_rate: 0,
    co2_unit_rate: 0.28,
    currency: 'GBP - Great Britain Pound',
    productivity: 1,
    manhours_unit: 1,
    company: 'Cemex London',
    contact_person: '',
    phone_number: '',
    mobile_number: '',
    e_mail: '',
    address: '',
    city_residence: '',
    state_province: '',
    post_code: 'NW2 6SB',
    country: 'UNITED KINGDOM',
    uniclass_ss_code: '',
    uniclass_pr_code: '',
    wbs_code: '',
    icms_code: '',
    nrm_code: '',
    rmm_code: '',
    cesmm4_code: '',
    company_code: '',
    creation_date: '',
    last_edited: '',
    creator: '',
    last_editor: '',
  },
  {
    id: 10,
    itemCode: 'F.01.02.01',
    title: 'Maximum aggregate size 10 mm',
    notes: '',
    description: '',
    unit: 'M3',
    labour_rate: 0,
    material_rate: 133.69,
    plant_rate: 0,
    subcontractor_rate: 0,
    total_unit_rate: 133.69,
    co2_plant_rate: 0,
    co2_labour_rate: 0,
    co2_material_rate: 0,
    co2_subcontract_rate: 0,
    co2_unit_rate: 0.28,
    currency: 'GBP - Great Britain Pound',
    productivity: 1,
    manhours_unit: 1,
    company: 'Cemex London',
    contact_person: '',
    phone_number: '',
    mobile_number: '',
    e_mail: '',
    address: '',
    city_residence: '',
    state_province: '',
    post_code: 'NW2 6SB',
    country: 'UNITED KINGDOM',
    uniclass_ss_code: '',
    uniclass_pr_code: '',
    wbs_code: '',
    icms_code: '',
    nrm_code: '',
    rmm_code: '',
    cesmm4_code: '',
    company_code: '',
    creation_date: '',
    last_edited: '',
    creator: '',
    last_editor: '',
  },
  {
    id: 11,
    itemCode: 'F.01.02.02',
    title: 'Maximum aggregate size 20 mm',
    notes: '',
    description: '',
    unit: 'M3',
    labour_rate: 0,
    material_rate: 133.69,
    plant_rate: 0,
    subcontractor_rate: 0,
    total_unit_rate: 133.69,
    co2_plant_rate: 0,
    co2_labour_rate: 0,
    co2_material_rate: 0,
    co2_subcontract_rate: 0,
    co2_unit_rate: 0.28,
    currency: 'GBP - Great Britain Pound',
    productivity: 1,
    manhours_unit: 1,
    company: 'Cemex London',
    contact_person: '',
    phone_number: '',
    mobile_number: '',
    e_mail: '',
    address: '',
    city_residence: '',
    state_province: '',
    post_code: 'NW2 6SB',
    country: 'UNITED KINGDOM',
    uniclass_ss_code: '',
    uniclass_pr_code: '',
    wbs_code: '',
    icms_code: '',
    nrm_code: '',
    rmm_code: '',
    cesmm4_code: '',
    company_code: '',
    creation_date: '',
    last_edited: '',
    creator: '',
    last_editor: '',
  },
  {
    id: 12,
    itemCode: 'F.01.02.03',
    title: 'Maximum aggregate size 40 mm',
    notes: '',
    description: '',
    unit: 'M3',
    labour_rate: 0,
    material_rate: 133.69,
    plant_rate: 0,
    subcontractor_rate: 0,
    total_unit_rate: 133.69,
    co2_plant_rate: 0,
    co2_labour_rate: 0,
    co2_material_rate: 0,
    co2_subcontract_rate: 0,
    co2_unit_rate: 0.28,
    currency: 'GBP - Great Britain Pound',
    productivity: 1,
    manhours_unit: 1,
    company: 'Cemex London',
    contact_person: '',
    phone_number: '',
    mobile_number: '',
    e_mail: '',
    address: '',
    city_residence: '',
    state_province: '',
    post_code: 'NW2 6SB',
    country: 'UNITED KINGDOM',
    uniclass_ss_code: '',
    uniclass_pr_code: '',
    wbs_code: '',
    icms_code: '',
    nrm_code: '',
    rmm_code: '',
    cesmm4_code: '',
    company_code: '',
    creation_date: '',
    last_edited: '',
    creator: '',
    last_editor: '',
  },
]
