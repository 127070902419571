import React, { useState, useRef, FunctionComponent } from 'react'
import { Col, Row } from 'antd'
import { DndProvider, createDndContext } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import WorkPackagesOverviewTable from '../tables/WorkPackagesOverviewTable'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import WorkPackagesBoQ from '../headers/WorkPackagesBoQ'
import { useStore } from 'react-redux'
import { RootState } from '../../../../context/reducer'
import { WorkPackagesColumns } from '../WorkPackages.columns'
import { WorkPackagesData } from '../../../../data/WorkPackages.data'
import { DraggableBodyRow, DroppableBodyRow } from '../drag-drop/DragDropRows'
import { replaceRecursively } from '../../../../utils/FunctionalUtils'

const RNDContext = createDndContext(HTML5Backend)

const DragSortingTable: FunctionComponent = () => {
  // @ts-ignore
  const state: RootState = useStore().getState() // Yes, I know this can cause cancer.

  const [draggableData, setDraggableData] = useState([...state.sterlingTable.parsedData.billOfQuantities])
  const [droppableData, setDroppableData] = useState(WorkPackagesData)

  const dragRow = (dragItemKey: number, [dragItem]: any[]) => {
    if (!dragItem || !dragItemKey) return
    const newItem = Object.assign(dragItem, { disabled: true })
    const draggableDataCopy = draggableData.slice()
    setDraggableData(draggableDataCopy.map((item) => (item.key === dragItemKey ? newItem : item)))
  }

  const dropRow = (dropItemKey: number, [dragItem, dropItem]: any[]) => {
    if (!dropItem || !dropItemKey) return
    const newItem = Object.assign(dropItem, {
      externalData: dropItem.externalData ? [...dropItem.externalData, dragItem] : [dragItem],
    })
    const droppableDataCopy = droppableData.slice()
    replaceRecursively(droppableDataCopy, newItem)
    setDroppableData(droppableDataCopy)
  }

  const manager = useRef(RNDContext)
  const rowHeight = useWindowDimensions().height / 8

  return (
    <DndProvider manager={manager.current.dragDropManager!}>
      <Row gutter={24}>
        <Col span={12}>
          <WorkPackagesOverviewTable
            rowHeight={rowHeight}
            forMapping={true}
            row={(props: any) => <DroppableBodyRow {...props} dndType={'WPBoQMapping'} />}
            data={droppableData}
            columns={WorkPackagesColumns.map((x) => ({ ...x, fixed: false }))}
            onRow={(record, index) => {
              return {
                index,
                record,
                moveRow: dropRow,
              }
            }}
          />
        </Col>
        <Col span={12}>
          <WorkPackagesBoQ
            data={draggableData}
            rowHeight={rowHeight}
            row={(props: any) => <DraggableBodyRow {...props} dndType={'WPBoQMapping'} />}
            onRow={(record, index) => {
              return {
                index,
                record,
                moveRow: dragRow,
              }
            }}
            tableId={'wpMappingBoQ'}
            pixelPerfectHeightBonus={41}
          />
        </Col>
      </Row>
    </DndProvider>
  )
}

export default DragSortingTable
