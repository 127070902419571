import { Action } from 'redux'
import { Key } from 'react'
import { ColumnsLayout } from '../../model/ColumnLayouts'

export const SET_VISIBLE_COLUMNS = 'SET_VISIBLE_COLUMNS'
export const SET_LOCKERS = 'SET_LOCKERS'
export const SET_COLUMN_LAYOUT = 'SET_COLUMN_LAYOUT'
export const ADD_LAYOUT = 'ADD_LAYOUT'

export interface SetLockersAction extends Action<string> {
  tableKey: string
  lockerStateKeys: Key[]
}
export const setLockers = (tableKey: string, lockerStateKeys: Key[]) => {
  return {
    type: SET_LOCKERS,
    tableKey,
    lockerStateKeys,
  }
}

export interface SetVisibleColumnsAction extends Action<string> {
  tableKey: string
  visibleColumnsKeys: Key[]
}

export const setVisibleColumns = (tableKey: string, visibleColumnsKeys: Key[]) => {
  return {
    type: SET_VISIBLE_COLUMNS,
    tableKey,
    visibleColumnsKeys,
  }
}

export interface AddLayoutAction extends Action<string> {
  id: string
  layout: ColumnsLayout
}

export const addLayout = (layout: ColumnsLayout, id: string) => {
  return {
    type: ADD_LAYOUT,
    id,
    layout,
  }
}
