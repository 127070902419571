function convertFromSexagesimal(coordinate: string): number {
  const parts = coordinate.split(/[^\d]+/)
  const hours = parts[0]
  const minutes = parts[1]
  const seconds = parts[2]
  return parseInt(hours, 10) + parseInt(minutes, 10) / 60 + parseInt(seconds, 10) / 3600
}

function convertFromSexagesimalNW(lng: string, lat: string): number[] {
  return [-convertFromSexagesimal(lng), convertFromSexagesimal(lat)] // (minus, plus) because coordinates are N, W
}

export function recognizeFormatAndConvertToMapboxReadable(lng: string, lat: string): number[] {
  if (lat.includes("'")) {
    return convertFromSexagesimalNW(lng, lat)
  }
  return [Number(lng), Number(lat)]
}
