import React, { useState, FunctionComponent } from 'react'
import LibraryTable from './table/LibraryTable'
import { Tabs, Empty } from 'antd'
import { subcontractorsColumns } from './table/columns/Subcontractors.columns'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../context/reducer'
import { labourColumns } from './table/columns/Labour.columns'
import { emptySubcontractorItem, subcontractorsData } from '../../../data/library/Subcontractors.data'
import { emptyLabourItem, labourData } from '../../../data/library/Labour.data'

import LibrariesTemplate from './template/LibrariesTemplate'
import { complexResourcesColumns } from './table/columns/ComplexResources.columns'
import { emptyComplexResourcesItem } from '../../../data/library/ComplexResources.data'
import { complexResourcesDetailsColumns } from './table/columns/ComplexResourcesDetails.columns'
import { emptyComplexResourcesDetailsItem } from '../../../data/library/ComplexResourcesDetails.data'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import NewWindow from '../../basic/new-window/NewWindow'
import ReplacementPane from '../../basic/new-window/ReplacementPane'
import { setOpenWindowState } from '../../../context/open-windows/OpenWindows.actions'
import BreadcrumbPanel from '../../basic/breadcrumb/BreadcrumbPanel'
import { newWindowHeight, newWindowWidth } from '../../../constants/sizes'
import { calculateHeightForWholePageTable } from '../../../constants/dimensions'
import { generateCSVHeader } from '../../../utils/FunctionalUtils'

const TabPane = Tabs.TabPane

const ResourceLibrary: FunctionComponent = () => {
  return (
    <LibrariesTemplate selectedKey={'resources'}>
      <ResourceLibraryPart />
    </LibrariesTemplate>
  )
}

function ResourceLibraryPart(): JSX.Element {
  const dispatch = useDispatch()

  const subcontractors = useSelector((state: RootState) => state.sterlingTable.parsedData.subcontractors)
  const labour = useSelector((state: RootState) => state.sterlingTable.parsedData.labour)

  const getOpenWindow = (id: string) => (state: RootState) => state.openWindows.windows.get(id) || false
  const subcontractorsWindowId = 'subcontractorsWindow'
  const isSubcontractorsWindowOpen = useSelector(getOpenWindow(subcontractorsWindowId))

  const { height } = useWindowDimensions()
  const tableHeight = calculateHeightForWholePageTable(height)

  const labourHeader = generateCSVHeader(labourColumns)
  const subcontractorsHeader = generateCSVHeader(subcontractorsColumns)

  return (
    <div className="table-wrapper">
      <BreadcrumbPanel
        moveTo={'libraries/resources'}
        displayName={'Resources'}
        categoryName={'Libraries'}
        openWindow={() => dispatch(setOpenWindowState(subcontractorsWindowId, true))}
      />
      <NewWindow
        height={newWindowHeight}
        width={newWindowWidth}
        isOpen={isSubcontractorsWindowOpen}
        onPortalClose={() => dispatch(setOpenWindowState(subcontractorsWindowId, false))}
        replacementPane={
          <ReplacementPane
            onHide={() => dispatch(setOpenWindowState(subcontractorsWindowId, false))}
            onWindowShow={() => {}}
            style={{ width: '100%', height: 450, backgroundColor: '#fff' }}
          />
        }
      >
        <Tabs>
          <TabPane tab={'Labour'} key={'labour'}>
            <LibraryTable
              headersToDownload={labourHeader}
              dataToDownload={labourData}
              filename={'labourData'}
              columns={labourColumns}
              dataSource={labour}
              tableId={'labour'}
              emptyItemProvider={emptyLabourItem}
              tableHeight={tableHeight}
              pagination={false}
            />
          </TabPane>
          <TabPane tab={'Plant'} key={'plant'}>
            In progress
          </TabPane>
          <TabPane tab={'Materials'} key={'materials'}>
            In progress
          </TabPane>
          <TabPane tab={'Subcontractors'} key={'subcontractors'}>
            <LibraryTable
              headersToDownload={subcontractorsHeader}
              dataToDownload={subcontractorsData}
              filename={'SubcontractorsData'}
              columns={subcontractorsColumns}
              dataSource={subcontractors}
              tableId={'subcontractors'}
              emptyItemProvider={emptySubcontractorItem}
              tableHeight={tableHeight}
              pagination={false}
            />
          </TabPane>
          <TabPane tab={'Complex Resources'} key={'complex-resources'}>
            <ComplexResourcesTabContent />
          </TabPane>
        </Tabs>
      </NewWindow>
    </div>
  )
}

function ComplexResourcesTabContent() {
  const { height } = useWindowDimensions()
  const complexResources = useSelector((state: RootState) => state.sterlingTable.parsedData.complexResources)
  const complexResourcesDetails = useSelector(
    (state: RootState) => state.sterlingTable.parsedData.complexResourcesDetails
  )
  const [selectedComplexResourceId, setSelectedComplexResourceId] = useState<number | undefined>(undefined)

  const detailsData = complexResourcesDetails.filter((item: any) => item.crs_id === selectedComplexResourceId)
  const renderDetails = () => {
    if (detailsData.length > 0) {
      return (
        <LibraryTable
          columns={complexResourcesDetailsColumns}
          dataSource={detailsData}
          tableId={'complexResourcesDetails'}
          emptyItemProvider={
            // tslint:disable-next-line:no-empty
            selectedComplexResourceId ? emptyComplexResourcesDetailsItem(selectedComplexResourceId) : () => {}
          }
          tableHeight={(height - 350) / 2}
          pagination={false}
        />
      )
    } else {
      return (
        <Empty
          style={{ height: (height - 350) / 2, backgroundColor: 'white', paddingTop: 32 }}
          description={
            selectedComplexResourceId !== undefined ? 'There is no data for selected item' : 'Select an item'
          }
        />
      )
    }
  }
  return (
    <>
      <div style={{ height: height / 2 }}>
        <LibraryTable
          columns={complexResourcesColumns}
          dataSource={complexResources}
          tableId={'complexResources'}
          emptyItemProvider={emptyComplexResourcesItem}
          onSelect={(rows) => setSelectedComplexResourceId(rows.length === 1 ? (rows[0] as number) : undefined)}
          tableHeight={(height - 150) / 2}
          pagination={false}
        />
      </div>
      {renderDetails()}
    </>
  )
}

export default ResourceLibrary
