import React from 'react'
import { Tabs } from 'antd'

import LibrariesTemplate from './template/LibrariesTemplate'
import LibraryTable from './table/LibraryTable'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../context/reducer'
import { breakdownStructureColumns } from './table/columns/BreakdownStructureColumns'
import { emptyCESMM4Item } from '../../../data/library/CESMM4.data'
import { emptyNRM1Item } from '../../../data/library/NRM1.data'
import { emptyNRM2Item } from '../../../data/library/NRM2.data'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { calculateHeightForWholePageTable } from '../../../constants/dimensions'
import { emptyProjectWBSItem } from '../../../data/library/WBS.data'
import NewWindow from '../../basic/new-window/NewWindow'
import ReplacementPane from '../../basic/new-window/ReplacementPane'
import { setOpenWindowState } from '../../../context/open-windows/OpenWindows.actions'
import BreadcrumbPanel from '../../basic/breadcrumb/BreadcrumbPanel'
import { newWindowHeight, newWindowWidth } from '../../../constants/sizes'

const TabPane = Tabs.TabPane

function BreakdownStructures(): JSX.Element {
  return (
    <LibrariesTemplate selectedKey={'breakdown-structures'}>
      <BreakdownStructuresPart />
    </LibrariesTemplate>
  )
}

function BreakdownStructuresPart(): JSX.Element {
  const dispatch = useDispatch()

  const getOpenWindow = (id: string) => (state: RootState) => state.openWindows.windows.get(id) || false
  const breakdownStructuresWindowId = 'breakdownStructures'
  const isBreakdownStructuresWindowOpen = useSelector(getOpenWindow(breakdownStructuresWindowId))

  const { height } = useWindowDimensions()
  const tableHeight = calculateHeightForWholePageTable(height)
  const cesmm4 = useSelector((state: RootState) => state.sterlingTable.parsedData.cesmm4)
  const nrm1 = useSelector((state: RootState) => state.sterlingTable.parsedData.nrm1)
  const nrm2 = useSelector((state: RootState) => state.sterlingTable.parsedData.nrm2)
  const projectWBS = useSelector((state: RootState) => state.sterlingTable.parsedData.projectWBS)

  return (
    <>
      <BreadcrumbPanel
        moveTo={'libraries/breakdown-structures'}
        displayName={'Breakdown Structures'}
        categoryName={'Libraries'}
        openWindow={() => dispatch(setOpenWindowState(breakdownStructuresWindowId, true))}
      />
      <NewWindow
        height={newWindowHeight}
        width={newWindowWidth}
        isOpen={isBreakdownStructuresWindowOpen}
        onPortalClose={() => dispatch(setOpenWindowState(breakdownStructuresWindowId, false))}
        replacementPane={
          <ReplacementPane
            onHide={() => dispatch(setOpenWindowState(breakdownStructuresWindowId, false))}
            onWindowShow={() => {}}
            style={{ width: '100%', height: 450, backgroundColor: '#fff' }}
          />
        }
      >
        <Tabs>
          <TabPane tab={'Project WBS'} key={'projectWBS'}>
            <LibraryTable
              tableId={'projectWBS'}
              dataSource={projectWBS}
              columns={breakdownStructureColumns}
              emptyItemProvider={emptyProjectWBSItem}
              tableHeight={tableHeight}
            />
          </TabPane>
          <TabPane tab={'ICMS'} key={'icms'}>
            IN PROGRESS
          </TabPane>
          <TabPane tab={'NRM1'} key={'nrm1'}>
            <LibraryTable
              tableId={'nrm1'}
              dataSource={nrm1}
              columns={breakdownStructureColumns}
              emptyItemProvider={emptyNRM1Item}
              tableHeight={tableHeight}
            />
          </TabPane>
          <TabPane tab={'NRM2'} key={'nrm2'}>
            <LibraryTable
              tableId={'nrm2'}
              dataSource={nrm2}
              columns={breakdownStructureColumns}
              emptyItemProvider={emptyNRM2Item}
              tableHeight={tableHeight}
            />
          </TabPane>
          <TabPane tab={'CESMM4'} key={'cesmm4'}>
            <LibraryTable
              tableId={'cesmm4'}
              dataSource={cesmm4}
              columns={breakdownStructureColumns}
              emptyItemProvider={emptyCESMM4Item}
              tableHeight={tableHeight}
            />
          </TabPane>
          <TabPane tab={'RMM1'} key={'rmm1'}>
            IN PROGRESS
          </TabPane>
          <TabPane tab={'HMM'} key={'hmm'}>
            IN PROGRESS
          </TabPane>
          <TabPane tab={'Uniclass Ss'} key={'uniclassss'}>
            IN PROGRESS
          </TabPane>
          <TabPane tab={'Uniclass Pr'} key={'uniclasspr'}>
            IN PROGRESS
          </TabPane>
          <TabPane tab={'HS2 WBS'} key={'EA WBS'}>
            IN PROGRESS
          </TabPane>
        </Tabs>
      </NewWindow>
    </>
  )
}

export default BreakdownStructures
