export interface LabourItem {
  id: number
  itemCode: string
  title: string
  notes: string
  description: string
  unit: string
  totalUnitRate?: number | string
  co2UnitRate?: number | string
  currency: string
  company: string
  contactPerson: string
  phoneNumber: string
  mobileNumber: string
  email: string
  address: string
  cityResidence: string
  stateProvince: string
  country: string
  creationDate: string
  lastEdited: string
  creator: string
  lastEditor: string
}

export function emptyLabourItem() {
  return {
    id: -1,
    itemCode: '',
    title: '',
    notes: '',
    description: '',
    unit: '',
    totalUnitRate: '',
    co2UnitRate: '',
    currency: '',
    company: '',
    contactPerson: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    address: '',
    cityResidence: '',
    stateProvince: '',
    country: '',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  }
}

export const labourData: LabourItem[] = [
  {
    id: 2,
    itemCode: 'LAB',
    title: 'Labour',
    notes: '',
    description: '',
    unit: '',
    totalUnitRate: '',
    co2UnitRate: '',
    currency: '',
    company: '',
    contactPerson: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    address: '',
    cityResidence: '',
    stateProvince: '',
    country: '',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  },
  {
    id: 3,
    itemCode: 'LAB.SIT',
    title: 'Site labour',
    notes: '',
    description: '',
    unit: '',
    totalUnitRate: '',
    co2UnitRate: '',
    currency: '',
    company: '',
    contactPerson: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    address: '',
    cityResidence: '',
    stateProvince: '',
    country: '',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  },
  {
    id: 4,
    itemCode: 'LAB.SIT.ELC',
    title: 'Electrical labour',
    notes: '',
    description: '',
    unit: '',
    totalUnitRate: '',
    co2UnitRate: '',
    currency: '',
    company: '',
    contactPerson: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    address: '',
    cityResidence: '',
    stateProvince: '',
    country: '',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  },
  {
    id: 5,
    itemCode: 'LAB.SIT.ELC.010',
    title: 'Electrician',
    notes: '',
    description: '',
    unit: 'HOUR',
    totalUnitRate: 30.0,
    co2UnitRate: 1,
    currency: 'GBP',
    company: '',
    contactPerson: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    address: '',
    cityResidence: '',
    stateProvince: '',
    country: 'UNITED KINGDOM',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  },
  {
    id: 6,
    itemCode: 'LAB.SIT.ELC.020',
    title: 'Electricians Mate',
    notes: '',
    description: '',
    unit: 'HOUR',
    totalUnitRate: 30.0,
    co2UnitRate: 1,
    currency: 'GBP',
    company: '',
    contactPerson: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    address: '',
    cityResidence: '',
    stateProvince: '',
    country: 'UNITED KINGDOM',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  },
  {
    id: 7,
    itemCode: 'LAB.SIT.ELC.030',
    title: 'Working Foreman - MEICA',
    notes: '',
    description: '',
    unit: 'HOUR',
    totalUnitRate: 40.0,
    co2UnitRate: 1,
    currency: 'GBP',
    company: '',
    contactPerson: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    address: '',
    cityResidence: '',
    stateProvince: '',
    country: 'UNITED KINGDOM',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  },
  {
    id: 8,
    itemCode: 'LAB.SIT.ELC.040',
    title: 'Electrical Installation Engineer',
    notes: '',
    description: '',
    unit: 'HOUR',
    totalUnitRate: 40.0,
    co2UnitRate: 1,
    currency: 'GBP',
    company: '',
    contactPerson: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    address: '',
    cityResidence: '',
    stateProvince: '',
    country: 'UNITED KINGDOM',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  },
  {
    id: 9,
    itemCode: 'LAB.SIT.ELC.050',
    title: 'Apprentice Electrician',
    notes: '',
    description: '',
    unit: 'HOUR',
    totalUnitRate: 30.0,
    co2UnitRate: 1,
    currency: 'GBP',
    company: '',
    contactPerson: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    address: '',
    cityResidence: '',
    stateProvince: '',
    country: 'UNITED KINGDOM',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  },
  {
    id: 10,
    itemCode: 'LAB.SIT.ELC.060',
    title: 'Cable puller',
    notes: '',
    description: '',
    unit: 'HOUR',
    totalUnitRate: 31.0,
    co2UnitRate: 1,
    currency: 'GBP',
    company: '',
    contactPerson: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    address: '',
    cityResidence: '',
    stateProvince: '',
    country: 'UNITED KINGDOM',
    creationDate: '',
    lastEdited: '',
    creator: '',
    lastEditor: '',
  },
]
