import React from 'react'
import { ThreePartsLayout } from '../../../basic/layout/three-parts-layout/ThreePartsLayout'
import ActivitiesTable from './ActivitiesTable'
import { FrappeGantt, ViewMode } from 'frappe-gantt-react'
import { ActivitiesTasks } from '../../../../data/Activities.data'

function ActivitiesCashFlowDash() {
  return (
    <iframe
      width={'100%'}
      height={'100%'}
      src="https://app.powerbi.com/view?r=eyJrIjoiMWY1OTQzMDUtZThjOS00YmM0LThkZmUtMDEzYTJkOGUyNTVmIiwidCI6IjE4OWExNmU1LTlkM2YtNDEwNC1iZTAyLTQ4NDYxMmQ0ZjY0ZiJ9"
      frameBorder="0"
      allowFullScreen={true}
    />
  )
}

export default function ActivitiesCashFlow() {
  return (
    <ThreePartsLayout
      layoutId={'activities'}
      leftSideComponent={(height: number) => <ActivitiesTable tableHeight={height} />}
      rightSideComponent={() => (
        <div style={{ maxHeight: 400, overflowY: 'scroll' }}>
          <FrappeGantt tasks={ActivitiesTasks} viewMode={ViewMode.Month} />
        </div>
      )}
      bottomPanelComponent={ActivitiesCashFlowDash}
    />
  )
}
