import React, { FunctionComponent } from 'react'
import { Button, Col, Row } from 'antd'
import ProjectTemplate from '../project/ProjectTemplate'
import { createDocument, ForgeViewer } from '../forge-viewer/ForgeViewer'
import {
  ApartmentOutlined,
  GatewayOutlined,
  LineChartOutlined,
  PoundOutlined,
  ProfileOutlined,
  WalletOutlined,
} from '@ant-design/icons/lib'
import { LoadingRectangle } from '../../../assets/icons/icons'

const projectTitle = 'Trafalgar Square'
const firstRowData = [
  ['Division', 'Buildings'],
  ['Type', 'Office'],
  ['Region', 'London'],
  ['Stage', 'Tender'],
  ['Budget', '£ 4,950,000'],
  ['Client', 'X_Fund'],
  ['Manager', 'Adam Smith'],
]

const numbersData = [
  ['Balance', '£ 443,423'],
  ['Total Direct Cost', '£ 3,965,337'],
  ['Total Overhead', '£ 541,240'],
  ['Gross Total', '£ 4,506,577'],
  ['Awarded Total', '£ 1,963,200'],
  ['Total Embedded CO2', 'kg 1,654,519'],
]

const sections = [
  {
    icon: <WalletOutlined />,
    title: 'Cost Plan',
    lastEdit: 'admin',
    lastEditDate: '14.08.2020',
    label: 'Gross Total',
    value: '£ 3,965,337',
    previousValue: '£ 3,549,661',
  },
  {
    icon: <ProfileOutlined />,
    title: 'Documentation',
    lastEdit: 't.brown',
    lastEditDate: '04.08.2020',
    label: 'No of documents',
    value: '45',
    previousValue: '42',
  },
  {
    icon: <GatewayOutlined />,
    title: 'Take-off',
    lastEdit: 'j.clark',
    lastEditDate: '12.08.2020',
    label: 'No of items',
    value: '1294',
    previousValue: '1289',
  },
  {
    icon: <ApartmentOutlined />,
    title: 'Work Packages',
    lastEdit: 'admin',
    lastEditDate: '14.08.2020',
    label: 'Total contracted value',
    value: '£ 1,963,200',
    previousValue: '£ 1,040,500',
  },
  {
    icon: <LineChartOutlined />,
    title: 'Schedule',
    lastEdit: 'admin',
    lastEditDate: '28.07.2020',
    label: 'End date',
    value: '24.11.2023',
    previousValue: '03.11.2023',
  },
  {
    icon: <PoundOutlined />,
    title: 'Valuations',
    lastEdit: 'admin',
    lastEditDate: '14.08.2020',
    label: 'Total reported value',
    value: '£ 596,056',
    previousValue: '£ 0',
  },
]

const FrontPagePart: FunctionComponent = () => {
  const DashLeft: FunctionComponent = () => (
    <iframe
      title={'DashLeft'}
      width="100%"
      height="400"
      src="https://app.powerbi.com/view?r=eyJrIjoiMmFhMjc5OGItYjM4OS00YTA3LTk5ODgtZDEzMjllMTkzZDVlIiwidCI6IjE4OWExNmU1LTlkM2YtNDEwNC1iZTAyLTQ4NDYxMmQ0ZjY0ZiJ9"
      frameBorder="0"
      allowFullScreen={true}
    />
  )

  const DashRight: FunctionComponent = () => (
    <iframe
      title={'DashRight'}
      width="100%"
      height="400"
      src="https://app.powerbi.com/view?r=eyJrIjoiMjdjYmM2YzctNDhjMy00MzVmLTk0ZmItNzhhYjNjNTFmNDE0IiwidCI6IjE4OWExNmU1LTlkM2YtNDEwNC1iZTAyLTQ4NDYxMmQ0ZjY0ZiJ9"
      allowFullScreen={true}
      frameBorder="0"
    />
  )

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginTop: 0 }}>
        <Col className="gutter-row" span={24}>
          <p style={{ fontSize: '14px' }}>{projectTitle}</p>
        </Col>
        <Col className="gutter-row" span={6}>
          <Row
            style={{
              background: '#fff',
              padding: '16px 16px 0px 16px',
              borderTop: 'solid',
              borderBottom: 'solid',
              borderColor: '#A0C1AC',
            }}
          >
            <Col key={0}>
              <p style={{ marginBottom: 0, color: '#8C8C8C' }}>RIBA STAGE</p>
              <p style={{ fontSize: 18, fontWeight: 'bold' }}>4: Technical Design</p>
            </Col>
            <Col style={{ padding: '0px 0px 10px 42px' }}>
              <Button type="text" shape="circle" icon={<LoadingRectangle />} />
            </Col>
          </Row>
        </Col>
        <Col className="gutter-row" span={18}>
          <Row
            style={{
              display: 'flex',
              background: '#fff',
              padding: '16px 16px 0px 50px',
              justifyContent: 'space-between',
            }}
          >
            {firstRowData.map((row) => (
              <Col span={3.5} key={row[0]} style={{ paddingLeft: '10px' }}>
                <p style={{ marginBottom: 0, color: '#8C8C8C' }}>{row[0].toUpperCase()}</p>
                <p style={{ fontSize: 18, fontWeight: 'bold' }}>{row[1]}</p>
              </Col>
            ))}
            <Col span={3} />
          </Row>
        </Col>
        <Col className="gutter-row" span={9} style={{ overflow: 'hidden' }}>
          <ForgeViewer
            documents={[
              createDocument(
                'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDMtRDEtMDlfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBTdWJzdHJ1Y3R1cmUucnZ0'
              ),
              createDocument(
                'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDQtRDEtMDhfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBTdXBlcnN0cnVjdHVyZS5ydnQ'
              ),
              createDocument(
                'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDUtRDEtMDVfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBFeHRlcmlvci5ydnQ'
              ),
            ]}
            visibleModelsUrns={[
              'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDMtRDEtMDlfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBTdWJzdHJ1Y3R1cmUucnZ0',
              'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDQtRDEtMDhfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBTdXBlcnN0cnVjdHVyZS5ydnQ',
              'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDUtRDEtMDVfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBFeHRlcmlvci5ydnQ',
            ]}
          />
        </Col>
        <Col className="gutter-row" span={4}>
          <div style={{ background: '#fff', padding: 16, height: '100%' }}>
            {numbersData.map((row, index) => (
              <>
                <p style={{ marginBottom: 0, color: '#8C8C8C' }}>{row[0].toUpperCase()}</p>
                {index === 0 ? (
                  <p style={{ fontSize: 18, marginBottom: 4, fontWeight: 'bold', color: '#52C41A' }}>{row[1]}</p>
                ) : (
                  <p style={{ fontSize: 18, marginBottom: 4, fontWeight: 'bold' }}>{row[1]}</p>
                )}
              </>
            ))}
          </div>
        </Col>
        <Col className="gutter-row" span={11}>
          <div style={{ backgroundColor: 'white', height: '100%', padding: 16 }}>
            {sections.map(({ icon, title, lastEdit, lastEditDate, label, value, previousValue }) => (
              <>
                <p style={{ margin: 0, color: '#BFBFBF' }}>
                  <span>{icon} </span>
                  <span style={{ marginLeft: 16, marginRight: 16 }}> {title.toUpperCase()} </span>
                  <span>
                    Last edited by: {lastEdit} {lastEditDate}
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 'bold', fontSize: 14 }}>{label}: </span>
                  <span style={{ fontSize: 14, marginLeft: 16, marginRight: 16 }}>{value} </span>
                  <span style={{ color: '#8C8C8C' }}>(previous: {previousValue}) </span>
                </p>
              </>
            ))}
          </div>
        </Col>
        <Col className="gutter-row" span={13}>
          <DashLeft />
        </Col>
        <Col className="gutter-row" span={11}>
          <DashRight />
        </Col>
      </Row>
    </>
  )
}

const FrontPage = () => (
  <ProjectTemplate selectedKey={'main'}>
    <FrontPagePart />
  </ProjectTemplate>
)

export default FrontPage
