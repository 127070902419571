import { ModelNode } from '../../components/domain/forge-viewer/ForgeViewer.model'
import { parseTakeOffData, TakeOffItem } from '../../components/domain/take-off/helpers/TakeOffDataParser'
import { convertToTableItem } from '../../components/domain/take-off/helpers/TakeOffConverter'
import { TakeOffTableItem } from '../../components/domain/take-off/TakeOffTables.model'

export const ModelSubstructureData: ModelNode = {
  id: 1,
  name: 'Model',
  children: [
    {
      id: 3628,
      name: 'Structural Foundations',
      children: [
        {
          id: 3629,
          name: 'Foundation Slab',
          children: [
            {
              id: 3631,
              name: '03.01.02.02.12.000_Pile Cap PC1 E05',
              children: [
                {
                  id: 3632,
                  name: 'Foundation Slab [764901]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5625000000000657,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2531250000000305,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.7500000000000583,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.7500000000000488,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 0.45,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3643,
                  name: 'Foundation Slab [765459]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5625000000000798,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.25312500000003696,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.7500000000000581,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.7500000000000774,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 0.45,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3652,
                  name: 'Foundation Slab [765477]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5625000000000657,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2531250000000305,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.7500000000000583,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.7500000000000488,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 0.45,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3661,
                  name: 'Foundation Slab [765498]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5625000000000798,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.25312500000003696,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.7500000000000581,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.7500000000000774,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 0.45,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3670,
                  name: 'Foundation Slab [765517]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5625000000000657,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2531250000000305,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.7500000000000583,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.7500000000000488,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 0.45,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3679,
                  name: 'Foundation Slab [765537]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5625000000000798,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.25312500000003696,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.7500000000000581,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.7500000000000774,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 0.45,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3771,
                  name: 'Foundation Slab [766349]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5625000000000657,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2531250000000305,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.7500000000000583,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.7500000000000488,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 0.45,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3780,
                  name: 'Foundation Slab [766422]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5625000000000798,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.25312500000003696,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.7500000000000581,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.7500000000000774,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 0.45,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.01.02.02.12.000_Pile Cap PC1 E05',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3689,
              name: '03.01.02.02.12.0100_Pile Cap PC2 E05',
              children: [
                {
                  id: 3690,
                  name: 'Foundation Slab [765568]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.5750000000000668,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.732500000000062,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.100000000000007,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.7500000000000293,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3699,
                  name: 'Foundation Slab [765714]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.5750000000000666,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.732500000000062,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.100000000000007,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.7500000000000293,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3708,
                  name: 'Foundation Slab [765812]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.5750000000000315,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.7325000000000228,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.0999999999999974,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.7500000000000678,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3717,
                  name: 'Foundation Slab [765897]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.5750000000000315,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.7325000000000228,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.0999999999999974,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.7500000000000678,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3726,
                  name: 'Foundation Slab [765919]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.5750000000000666,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.732500000000062,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.100000000000007,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.7500000000000293,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3789,
                  name: 'Foundation Slab [766448]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.5750000000000315,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.7325000000000228,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.0999999999999974,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.7500000000000678,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3808,
                  name: 'Foundation Slab [766540]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.5750000000000104,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.732499999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.099999999999969,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.750000000000087,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3817,
                  name: 'Foundation Slab [766618]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.5750000000000104,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.732499999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.099999999999969,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.750000000000087,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3826,
                  name: 'Foundation Slab [766801]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.574999999999978,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.7324999999999633,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.099999999999969,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.7500000000000139,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3835,
                  name: 'Foundation Slab [766847]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.574999999999978,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.7324999999999633,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.099999999999969,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.7500000000000139,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.01.02.02.12.0100_Pile Cap PC2 E05',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3736,
              name: '03.01.02.02.12.030_Pile Cap PC4 E05',
              children: [
                {
                  id: 3737,
                  name: 'Foundation Slab [766085]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.41000000000002,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 4.850999999999988,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.1000000000000267,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2.100000000000007,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3751,
                  name: 'Foundation Slab [766145]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.41000000000002,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 4.850999999999988,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.1000000000000267,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2.100000000000007,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3761,
                  name: 'Foundation Slab [766168]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.41000000000002,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 4.850999999999988,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.1000000000000267,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2.100000000000007,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3798,
                  name: 'Foundation Slab [766488]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.41000000000002,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 4.850999999999988,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.1000000000000267,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2.100000000000007,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.01.02.02.12.030_Pile Cap PC4 E05',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3845,
              name: '03.01.02.02.12.020_Pile Cap PC3 E05',
              children: [
                {
                  id: 3846,
                  name: 'Foundation Slab [767273]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.838599996311611,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427506,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.099999997271315,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687919,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3857,
                  name: 'Foundation Slab [768266]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.8385999963115793,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427164,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.0999999972713055,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687397,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3868,
                  name: 'Foundation Slab [768295]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.8385999963116104,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427506,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.099999997271315,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687919,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3879,
                  name: 'Foundation Slab [768328]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.83859999631158,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427164,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.0999999972713055,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687397,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3890,
                  name: 'Foundation Slab [768355]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.838599996311611,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427506,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.099999997271315,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687919,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3901,
                  name: 'Foundation Slab [768389]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.8385999963115798,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427164,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.0999999972713055,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687397,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3912,
                  name: 'Foundation Slab [768424]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.8385999963116104,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427506,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.099999997271315,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687919,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3923,
                  name: 'Foundation Slab [768458]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.83859999631158,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427164,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.0999999972713055,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687397,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3934,
                  name: 'Foundation Slab [768488]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.8385999963116104,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427506,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.099999997271315,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687919,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3945,
                  name: 'Foundation Slab [768517]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.8385999963116104,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427506,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.099999997271315,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687919,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3956,
                  name: 'Foundation Slab [768558]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.8385999963116104,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427506,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.099999997271315,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687919,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3967,
                  name: 'Foundation Slab [768666]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.83859999631158,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427164,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.0999999972713055,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687397,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3978,
                  name: 'Foundation Slab [768694]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.8385999963116104,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427506,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.099999997271315,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687919,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3989,
                  name: 'Foundation Slab [768724]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.83859999631158,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427164,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.0999999972713055,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687397,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4000,
                  name: 'Foundation Slab [768758]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.8385999963116104,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427506,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.099999997271315,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687919,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4011,
                  name: 'Foundation Slab [768784]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.83859999631158,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427164,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.0999999972713055,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687397,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4022,
                  name: 'Foundation Slab [768810]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.8385999963116104,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427506,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.099999997271315,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687919,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4033,
                  name: 'Foundation Slab [768843]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.8385999963116104,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427506,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.099999997271315,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687919,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4044,
                  name: 'Foundation Slab [768878]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.8385999963115793,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427164,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.0999999972713055,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687397,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4055,
                  name: 'Foundation Slab [768903]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.8385999963116104,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427506,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.099999997271315,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687919,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4066,
                  name: 'Foundation Slab [768942]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.8385999963115793,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.122459995942716,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.0999999972713055,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687397,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4077,
                  name: 'Foundation Slab [768985]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.8385999963116104,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427506,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.099999997271315,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972687919,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4088,
                  name: 'Foundation Slab [769040]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.8385999963115887,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.1224599959427253,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2.0999999972713055,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.9190649972688083,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 1.1,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.01.02.02.12.020_Pile Cap PC3 E05',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4126,
              name: '03.01.03.01.03.010_750 Thk  Lift Pit Base E05',
              children: [
                {
                  id: 4127,
                  name: 'Foundation Slab [771317]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.89374997168938,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 8.170312478767173,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 4.174999994575035,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3.1249999959394237,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 0.75,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.01.03.01.03.010_750 Thk  Lift Pit Base E05',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4343,
              name: '03.01.03.01.01.010_250 Reinf Conc Slab E05',
              children: [
                {
                  id: 4344,
                  name: 'Foundation Slab [789035]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 976.5384675199642,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 244.1346168799862,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 29.950320667454942,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 36.574999999254906,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 0.25,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4357,
                  name: 'Foundation Slab [789586]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 143.55457823195633,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 35.88864455798833,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 6.025034196996294,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 25.300136568291446,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 0.25,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4405,
                  name: 'Foundation Slab [791724]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.6149999999993272,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.4037499999998239,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.9499999999997424,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.6999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 0.25,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4414,
                  name: 'Foundation Slab [791822]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.749999999999939,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9374999999999666,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1.5000000000000744,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2.499999999999932,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 0.25,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4423,
                  name: 'Foundation Slab [791851]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.8699999999999681,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.4674999999999828,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1.6999999999999769,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.1000000000001038,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 0.25,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.01.03.01.01.010_250 Reinf Conc Slab E05',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4367,
              name: '03.01.03.01.01.000_250 Reinf Conc Ground Bearing Slab E05',
              children: [
                {
                  id: 4368,
                  name: 'Foundation Slab [789881]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Structural Foundations',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 137.66190015291463,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 34.415475038227974,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 25.300132744361907,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5.601255543262636,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 0.25000000000000006,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.01.03.01.01.000_250 Reinf Conc Ground Bearing Slab E05',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Foundation Slab',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Structural Foundations',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Foundation Slab',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Structural Foundations',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Structural Foundations',
          displayCategory: '__category__',
        },
      ],
    },
    {
      id: 4097,
      name: 'Walls',
      children: [
        {
          id: 4098,
          name: 'Basic Wall',
          children: [
            {
              id: 4100,
              name: '03.01.02.02.13.010_Tie Beam TB2 E05',
              children: [
                {
                  id: 4101,
                  name: 'Basic Wall [769366]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5.3250000063344896,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.396250002850542,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.0783125012827401,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.45,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4102,
                  name: 'Basic Wall [769413]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5.250000006821861,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.3625000030698553,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.0631250013814695,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.45,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4103,
                  name: 'Basic Wall [769461]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5.250000006821861,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.3625000030698553,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.0631250013814695,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.45,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4104,
                  name: 'Basic Wall [769543]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3.0851604368241414,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.3883221965708776,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6247449884568613,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.45,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4105,
                  name: 'Basic Wall [769629]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3.0851604368241414,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.3883221965708776,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6247449884568613,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.45,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4151,
                  name: 'Basic Wall [773309]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3.8499761456102397,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.7324892655246185,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.7796201694860636,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.45,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.01.02.02.13.010_Tie Beam TB2 E05',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 0.45,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 4107,
              name: '03.01.02.02.13.050_Reinforced Concrete Tie Beam TB5 Spec NBS E05',
              children: [
                {
                  id: 4108,
                  name: 'Basic Wall [769868]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7.7503091489825096,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.375231861736916,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 4.7814238963027185,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.75,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4109,
                  name: 'Basic Wall [769971]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7.750309148982506,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.375231861736888,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 4.781423896302631,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.75,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4110,
                  name: 'Basic Wall [770166]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3.199979608700011,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.8374847065250337,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.37811352989374,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.75,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4111,
                  name: 'Basic Wall [770167]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3.199979608700011,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.8374847065250195,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.37811352989372,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.75,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4120,
                  name: 'Basic Wall [771064]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3.175105022991386,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.9438287672435446,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.2078715754326406,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.75,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4121,
                  name: 'Basic Wall [771189]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7.686159771918792,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.3052448289391005,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.9789336217042353,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.75,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4122,
                  name: 'Basic Wall [771238]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7.686159771918648,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.305244828939051,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.9789336217041784,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.75,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4144,
                  name: 'Basic Wall [772600]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5.574913998902164,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.743685499176683,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.557764124382459,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.75,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4145,
                  name: 'Basic Wall [772601]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5.574913998902196,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.74368549917667,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.5577641243824933,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.75,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4146,
                  name: 'Basic Wall [772602]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2.6000269818137034,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.387520236360162,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.040640177270148,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.75,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4147,
                  name: 'Basic Wall [772736]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2.6000269818135777,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.387520236360162,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.0406401772701532,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.75,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.01.02.02.13.050_Reinforced Concrete Tie Beam TB5 Spec NBS E05',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 0.75,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 4113,
              name: '03.01.02.02.13.000_Tie Beam TB1 E05',
              children: [
                {
                  id: 4114,
                  name: 'Basic Wall [770317]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2.49992580982677,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.5936943573701037,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9562166144220856,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4115,
                  name: 'Basic Wall [770624]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4.865000011272101,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.6261195475348456,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.1030712114741648,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4116,
                  name: 'Basic Wall [770717]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4.519999999999983,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.314613079342218,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.8435643740121683,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4117,
                  name: 'Basic Wall [770819]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4.460000000000023,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.314613079342465,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.8435643740122858,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4118,
                  name: 'Basic Wall [770901]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4.840000018938538,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.625993536924228,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.1029931656081446,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4119,
                  name: 'Basic Wall [771005]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2.500273406474482,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.593955054855873,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9563730329135326,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.01.02.02.13.000_Tie Beam TB1 E05',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 0.6,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 4139,
              name: '03.01.03.01.03.000_475 Thk  Lift Pit Wall E05',
              children: [
                {
                  id: 4140,
                  name: 'Basic Wall [771914]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3.9374999996913385,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.861886199284215,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.8343906250000228,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.475,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4141,
                  name: 'Basic Wall [771986]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2.649992982445846,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.0118685081914194,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9556375413909093,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.475,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4142,
                  name: 'Basic Wall [772105]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3.937499999691357,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.8618861992842604,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.834390624999985,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.475,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4143,
                  name: 'Basic Wall [772319]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2.649992982445846,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.0118685081914016,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.955637541390863,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.475,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.01.03.01.03.000_475 Thk  Lift Pit Wall E05',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 0.475,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 4149,
              name: '01.01.01.02.13.030_Tie Beam TB4 E05',
              children: [
                {
                  id: 4150,
                  name: 'Basic Wall [773132]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 12.024825240378908,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 12.926687133407308,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 9.695015350055534,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.7500000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4607,
                  name: 'Basic Wall [801933]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 12.024825240378902,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 12.926687133407308,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 9.695015350055534,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.7500000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '01.01.01.02.13.030_Tie Beam TB4 E05',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 0.7500000000000001,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 4153,
              name: '03.01.02.02.13.040_500x600mm Ground Beam',
              children: [
                {
                  id: 4154,
                  name: 'Basic Wall [773595]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5.919999999999991,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.9391212630569057,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.667565027646582,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4155,
                  name: 'Basic Wall [773969]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4.346129278952759,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.173064639476413,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.3038387836858787,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4156,
                  name: 'Basic Wall [774042]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4.4500648984780256,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.0375324492390336,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.2225194695434498,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4157,
                  name: 'Basic Wall [774131]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4.960000000000008,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.380476012341655,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.3254462722713607,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4158,
                  name: 'Basic Wall [774344]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4.8400000000000185,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.380630500888105,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.3255389653991951,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4159,
                  name: 'Basic Wall [774580]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5.040000000000017,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.4953668379290495,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.2723916965178355,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4160,
                  name: 'Basic Wall [774682]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4.960000000000008,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.3804760123417306,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.3254462722713856,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4161,
                  name: 'Basic Wall [774722]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4.960000000000008,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.380476012341815,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.325446272271422,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4162,
                  name: 'Basic Wall [774748]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5.125068230893122,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.527725876990779,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.4652173562452766,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4163,
                  name: 'Basic Wall [774859]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3.2400000000000317,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.6082900481983733,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9304708633149068,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4164,
                  name: 'Basic Wall [775031]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3.2400000000000317,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.6082900481983733,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9304708633149118,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4165,
                  name: 'Basic Wall [775066]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3.2400000000000317,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.6082900481983662,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9304708633149072,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4166,
                  name: 'Basic Wall [775132]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5.250000006821708,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.625000003410894,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.5750000020464983,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4167,
                  name: 'Basic Wall [775230]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5.250000006821708,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.625000003410894,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.5750000020464983,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4168,
                  name: 'Basic Wall [775280]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4.350000008121183,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.1750000040606245,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.3050000024364061,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4169,
                  name: 'Basic Wall [775451]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.2141352887856043,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.6070676443928115,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.3642405866356954,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4170,
                  name: 'Basic Wall [775521]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4.450108316051834,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.0375541580259373,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.2225324948155911,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4171,
                  name: 'Basic Wall [775618]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3.1998647154532445,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.4124323577266715,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.8474594146360239,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4172,
                  name: 'Basic Wall [775691]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4.350000008121183,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.1750000040606245,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.3050000024364061,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4173,
                  name: 'Basic Wall [775776]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.223042886628405,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.611521443314212,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.3669128659885183,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.6,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.01.02.02.13.040_500x600mm Ground Beam',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 0.6,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 4175,
              name: '03.01.02.02.13.020_Tie Beam TB3 E05',
              children: [
                {
                  id: 4176,
                  name: 'Basic Wall [776086]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3.9836779020425204,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.7885745314297514,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.254858539143351,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.45,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.01.02.02.13.020_Tie Beam TB3 E05',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 0.45,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 4435,
              name: '03.01.03.01.03.020_Reinf Conc Lift Pit Wall 250mm thk Spec NBS E05',
              children: [
                {
                  id: 4436,
                  name: 'Basic Wall [792275]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.2500000000000748,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.8750000000001084,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.4687499999999853,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.25000000000000006,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4437,
                  name: 'Basic Wall [792528]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.250000000000054,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.8750000000001084,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.46875000000000433,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.25000000000000006,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4438,
                  name: 'Basic Wall [792564]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2.2499999999998908,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.499999999999857,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.624999999999936,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.25000000000000006,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4439,
                  name: 'Basic Wall [792620]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2.2499999999998703,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.4999999999998566,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.624999999999936,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.25000000000000006,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4440,
                  name: 'Basic Wall [792659]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.4499999999999595,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.1249999999999525,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5312499999999643,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.25000000000000006,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4441,
                  name: 'Basic Wall [792660]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.4499999999999595,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.1249999999999885,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5312499999999795,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.25000000000000006,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4442,
                  name: 'Basic Wall [792661]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.8499999999999739,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7499999999999883,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.18749999999998634,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.25000000000000006,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4443,
                  name: 'Basic Wall [792662]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.8499999999999741,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.749999999999952,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1874999999999803,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.25000000000000006,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4444,
                  name: 'Basic Wall [792686]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.6999999999997732,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.1874999999997118,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2968749999999151,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.25000000000000006,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4445,
                  name: 'Basic Wall [792687]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 0.6999999999997732,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.187499999999712,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2968749999999152,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.25000000000000006,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4446,
                  name: 'Basic Wall [792688]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.4499999999998827,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.4999999999998201,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.3749999999999405,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.25000000000000006,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4447,
                  name: 'Basic Wall [792689]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1.4499999999998827,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.499999999999856,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.3749999999999467,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.25000000000000006,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.01.03.01.03.020_Reinf Conc Lift Pit Wall 250mm thk Spec NBS E05',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 0.25000000000000006,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 4609,
              name: '03.01.03.01.01.020_Reinf Conc Slab Edge Thickening 125x250 E05',
              children: [
                {
                  id: 4610,
                  name: 'Basic Wall [801949]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 11.724838622698627,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.8562096556746113,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.3570262069593002,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.12500000000000003,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4611,
                  name: 'Basic Wall [802351]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 11.724838622698627,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.8562096556746064,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.3570262069593087,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0.12500000000000003,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.01.03.01.01.020_Reinf Conc Slab Edge Thickening 125x250 E05',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 0.12500000000000003,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Basic Wall',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Walls',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Walls',
          displayCategory: '__category__',
        },
      ],
    },
    {
      id: 4181,
      name: 'Columns',
      children: [
        {
          id: 4182,
          name: 'Round Column',
          children: [
            {
              id: 4184,
              name: '03.01.02.02.04.000_Cast In-Situ Reinf Conc Piled Fndn Load bearing Fndn piles',
              children: [
                {
                  id: 4185,
                  name: 'Round Column [780144]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4186,
                  name: 'Round Column [780161]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4187,
                  name: 'Round Column [780181]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4188,
                  name: 'Round Column [780198]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4189,
                  name: 'Round Column [780215]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4190,
                  name: 'Round Column [780433]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4191,
                  name: 'Round Column [780434]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4192,
                  name: 'Round Column [780505]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4193,
                  name: 'Round Column [780528]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4194,
                  name: 'Round Column [780566]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4195,
                  name: 'Round Column [780595]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4196,
                  name: 'Round Column [780648]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4197,
                  name: 'Round Column [780673]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4198,
                  name: 'Round Column [780728]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4199,
                  name: 'Round Column [780803]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4200,
                  name: 'Round Column [780860]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4201,
                  name: 'Round Column [780914]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4202,
                  name: 'Round Column [780985]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4203,
                  name: 'Round Column [781056]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4204,
                  name: 'Round Column [781120]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4205,
                  name: 'Round Column [781155]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4206,
                  name: 'Round Column [781208]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4207,
                  name: 'Round Column [781242]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4208,
                  name: 'Round Column [781300]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4209,
                  name: 'Round Column [781329]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4210,
                  name: 'Round Column [781378]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4211,
                  name: 'Round Column [781393]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4212,
                  name: 'Round Column [781497]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4213,
                  name: 'Round Column [781570]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4214,
                  name: 'Round Column [781636]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4215,
                  name: 'Round Column [781696]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4216,
                  name: 'Round Column [781758]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4217,
                  name: 'Round Column [781821]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4218,
                  name: 'Round Column [781902]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4219,
                  name: 'Round Column [781960]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4220,
                  name: 'Round Column [782015]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4221,
                  name: 'Round Column [782066]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4222,
                  name: 'Round Column [782124]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4223,
                  name: 'Round Column [782232]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4224,
                  name: 'Round Column [782322]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4225,
                  name: 'Round Column [782384]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4226,
                  name: 'Round Column [782452]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4227,
                  name: 'Round Column [782508]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4228,
                  name: 'Round Column [782718]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4229,
                  name: 'Round Column [782765]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4230,
                  name: 'Round Column [782797]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4231,
                  name: 'Round Column [782829]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4232,
                  name: 'Round Column [782867]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4233,
                  name: 'Round Column [782916]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4234,
                  name: 'Round Column [782945]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4235,
                  name: 'Round Column [782980]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4236,
                  name: 'Round Column [783008]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4237,
                  name: 'Round Column [783057]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4238,
                  name: 'Round Column [783094]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4239,
                  name: 'Round Column [783118]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4240,
                  name: 'Round Column [783158]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4241,
                  name: 'Round Column [783185]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4242,
                  name: 'Round Column [783221]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4243,
                  name: 'Round Column [783280]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4244,
                  name: 'Round Column [783341]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4245,
                  name: 'Round Column [783391]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4246,
                  name: 'Round Column [783432]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4247,
                  name: 'Round Column [783473]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4248,
                  name: 'Round Column [783550]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4249,
                  name: 'Round Column [783594]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4250,
                  name: 'Round Column [783645]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4251,
                  name: 'Round Column [783670]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4252,
                  name: 'Round Column [783719]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4253,
                  name: 'Round Column [783761]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4254,
                  name: 'Round Column [783786]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4255,
                  name: 'Round Column [783829]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4256,
                  name: 'Round Column [783894]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4257,
                  name: 'Round Column [783953]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4258,
                  name: 'Round Column [784030]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4259,
                  name: 'Round Column [784095]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4260,
                  name: 'Round Column [784132]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4261,
                  name: 'Round Column [784211]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4262,
                  name: 'Round Column [784252]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4263,
                  name: 'Round Column [784287]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4264,
                  name: 'Round Column [784331]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4265,
                  name: 'Round Column [784376]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4266,
                  name: 'Round Column [784426]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4267,
                  name: 'Round Column [784486]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4268,
                  name: 'Round Column [784524]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4269,
                  name: 'Round Column [784558]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4270,
                  name: 'Round Column [784585]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4271,
                  name: 'Round Column [784626]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4272,
                  name: 'Round Column [784658]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4273,
                  name: 'Round Column [784684]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4274,
                  name: 'Round Column [784724]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4275,
                  name: 'Round Column [784751]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4276,
                  name: 'Round Column [784789]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4277,
                  name: 'Round Column [784828]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4278,
                  name: 'Round Column [784873]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4279,
                  name: 'Round Column [784907]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4280,
                  name: 'Round Column [784963]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4281,
                  name: 'Round Column [785022]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4282,
                  name: 'Round Column [785099]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4283,
                  name: 'Round Column [785158]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4284,
                  name: 'Round Column [785194]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4285,
                  name: 'Round Column [785228]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4286,
                  name: 'Round Column [785280]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4287,
                  name: 'Round Column [785332]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4288,
                  name: 'Round Column [785375]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4289,
                  name: 'Round Column [785435]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4290,
                  name: 'Round Column [785487]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4291,
                  name: 'Round Column [785536]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4292,
                  name: 'Round Column [785598]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4293,
                  name: 'Round Column [785647]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4294,
                  name: 'Round Column [785687]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4295,
                  name: 'Round Column [785740]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4296,
                  name: 'Round Column [785789]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4297,
                  name: 'Round Column [785852]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4298,
                  name: 'Round Column [785874]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4299,
                  name: 'Round Column [785895]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4300,
                  name: 'Round Column [785963]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4301,
                  name: 'Round Column [786002]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4302,
                  name: 'Round Column [786022]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4303,
                  name: 'Round Column [786064]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4304,
                  name: 'Round Column [786089]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4305,
                  name: 'Round Column [786146]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4306,
                  name: 'Round Column [786171]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4307,
                  name: 'Round Column [786213]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4308,
                  name: 'Round Column [786249]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4309,
                  name: 'Round Column [786281]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4310,
                  name: 'Round Column [786319]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4311,
                  name: 'Round Column [786338]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4317,
                  name: 'Round Column [786380]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4318,
                  name: 'Round Column [786401]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4319,
                  name: 'Round Column [786419]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4320,
                  name: 'Round Column [786541]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4321,
                  name: 'Round Column [786574]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4322,
                  name: 'Round Column [786610]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4323,
                  name: 'Round Column [786611]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4324,
                  name: 'Round Column [786638]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4325,
                  name: 'Round Column [786656]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4326,
                  name: 'Round Column [786681]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4327,
                  name: 'Round Column [786709]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4328,
                  name: 'Round Column [786734]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4329,
                  name: 'Round Column [786751]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4330,
                  name: 'Round Column [786998]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4331,
                  name: 'Round Column [787024]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4332,
                  name: 'Round Column [787918]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4333,
                  name: 'Round Column [787962]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4334,
                  name: 'Round Column [788020]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4335,
                  name: 'Round Column [788066]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4336,
                  name: 'Round Column [788204]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4337,
                  name: 'Round Column [788266]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4338,
                  name: 'Round Column [788284]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4339,
                  name: 'Round Column [788394]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4448,
                  name: 'Round Column [792762]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4449,
                  name: 'Round Column [792832]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
                {
                  id: 4612,
                  name: 'Round Column [803140]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Columns',
                      displayCategory: '__category__',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.01.02.02.04.000_Cast In-Situ Reinf Conc Piled Fndn Load bearing Fndn piles',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Round Column',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Columns',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Round Column',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Columns',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Columns',
          displayCategory: '__category__',
        },
      ],
    },
  ],
  properties: [
    {
      displayName: 'Category',
      displayValue: 'Revit Document',
      displayCategory: '__category__',
    },
  ],
}

export const SubstructureTakeOffData: TakeOffItem[] = parseTakeOffData(ModelSubstructureData)

export const SubstructureTakeOffTableData: TakeOffTableItem[] = SubstructureTakeOffData.map((t) =>
  convertToTableItem(t, 'BHR-R5D-401-00-M3-X-0003-D1-09_Entrance Building - Substructure.rvt')
)
