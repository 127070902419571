import { Entry } from '../../basic/table/sterling-table/SterlingTable'
import { TakeOffDetailsItem } from './helpers/TakeOffDataParser'

export interface TakeOffTableItem extends Entry {
  code: string
  name: string
  takeOffType: string
  count: number
  mappedToBoQ: string
  sourceDocument: string
  takeOffExternalType: string
  unit: string
  documentType: string
  filename: string
  publishedBy: string
  publishDate: string
  updatedBy: string
  updateDate: string
  urn?: string
}

export const emptyTakeOffTableItem = (
  idElement: number,
  name?: string,
  urn?: string,
  children?: TakeOffTableItem[],
  takeOffType?: string,
  code?: string
): TakeOffTableItem => {
  const item = {
    id: idElement,
    key: idElement,
    code: code || '',
    name: name || '',
    takeOffType: takeOffType || '',
    count: 0,
    mappedToBoQ: '',
    sourceDocument: '',
    takeOffExternalType: '',
    unit: '',
    documentType: '',
    filename: '',
    publishedBy: '',
    publishDate: '',
    updatedBy: '',
    updateDate: '',
    parent: undefined,
    children: children && children.length > 0 ? children : undefined,
    urn,
  }
  if (children) {
    children.map((c) => (c.parent = item))
  }
  return item
}

export interface TakeOffDetailsTableItem extends Entry, TakeOffDetailsItem {}
