import React, { FunctionComponent, Key, useEffect, useState } from 'react'
import { BoQColumns } from '../BillOfQuantities.columns'
import { BoQData, emptyBoQEntry } from '../../../../data/BillOfQuantities.data'
import BillOfQuantitiesHeader from '../headers/BillOfQuantitiesHeader'
import SterlingTable, { ExtendedColumnType } from '../../../basic/table/sterling-table/SterlingTable'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../context/reducer'
import {
  addEntry,
  deleteEntries,
  duplicateEntries,
  updateEntry,
} from '../../../../context/resource-library/SterlingTable.actions'
import { TableSelectionToolbarProps } from '../../../basic/table/table-selection-toolbar/TableSelectionToolbar'
import { ItemsManagementToolbar } from '../../../basic/table/table-selection-toolbar/ItemsManagementToolbar'
import { sortColumnsAccordingToGrouping } from '../../../basic/table/sterling-table/helpers/ColumnsHelpers'
import { generateCSVHeader } from '../../../../utils/FunctionalUtils'

interface BoQMainTableProps {
  onSelect: (rows: Key[]) => void
  tableHeight: number
  openWindow?: () => void
}

const BoQMainTable: FunctionComponent<BoQMainTableProps> = ({ onSelect, tableHeight, openWindow }) => {
  const tableId = 'billOfQuantities'

  const data = useSelector((state: RootState) => state.sterlingTable.parsedData.billOfQuantities)
  const dispatch = useDispatch()

  const activeBreakdownStructureColumn = useSelector(
    (state: RootState) => state.sterlingTable.groupedByExternal[tableId]
  )
  const activeGroupingColumn = useSelector((state: RootState) => state.sterlingTable.groupedBy[tableId])
  const activeColumn = activeBreakdownStructureColumn || activeGroupingColumn

  const columns = sortColumnsAccordingToGrouping(BoQColumns, activeColumn)

  const defaultExpansionLevel = activeBreakdownStructureColumn !== undefined ? 3 : undefined

  const costPlanHeader = generateCSVHeader(BoQColumns)

  return (
    <SterlingTable
      onSelect={onSelect}
      columns={columns}
      dataSource={data}
      tableId={tableId}
      size={'small'}
      renderHeader={(headerProps) => (
        <BillOfQuantitiesHeader
          headersToDownload={costPlanHeader}
          dataToDownload={BoQData}
          filename={'cost-plan'}
          data={BoQData}
          columns={BoQColumns}
          tableId={tableId}
          sterlingHeaderProps={headerProps}
          openWindow={openWindow}
        />
      )}
      renderSelectionToolbar={(props: TableSelectionToolbarProps) => (
        <ItemsManagementToolbar
          baseProps={props}
          onAdd={() => dispatch(addEntry(tableId, emptyBoQEntry))}
          onDuplicate={(selectedRows) =>
            dispatch(
              duplicateEntries(
                tableId,
                selectedRows.map((key) => key as number)
              )
            )
          }
          onDelete={(selectedRows) =>
            dispatch(
              deleteEntries(
                tableId,
                selectedRows.map((key) => key as number)
              )
            )
          }
        />
      )}
      onUpdate={(id, updates) => {
        dispatch(updateEntry(tableId, id, updates))
      }}
      tableHeight={tableHeight}
      defaultExpansionLevel={defaultExpansionLevel}
    />
  )
}

export default BoQMainTable
