import React from 'react'
import {
  SterlingTableHeader,
  SterlingTableHeaderProps,
} from '../../../basic/table/sterling-table/header/SterlingTableHeader'
import { Button, Divider, Tooltip } from 'antd'
import {
  ColumnConfiguration,
  GroupByCustom,
  LayoutBottomCustom,
  TextWrappingCustom,
} from '../../../../assets/icons/icons'
import { MoreOutlined, SyncOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../context/reducer'
import { setLayouts } from '../../../../context/layouts/Layouts.actions'

interface TakeOffTableHeaderProps {
  sterlingTableHeaderProps: SterlingTableHeaderProps
  openWindow?: () => void
  onReset?: () => void
}

export const TakeOffTableHeader = ({ sterlingTableHeaderProps, openWindow, onReset }: TakeOffTableHeaderProps) => {
  const { bottomComponentOpened, rightSideComponentOpened } = useSelector((state: RootState) =>
    state.layoutsReducer.layouts.get('take-off')
  )
  const dispatch = useDispatch()

  return (
    <SterlingTableHeader
      {...sterlingTableHeaderProps}
      columnsLayoutManagerVisibility={true}
      rightSideChildren={
        <Tooltip title="Show Details">
          <Button
            type="text"
            shape="circle"
            icon={<LayoutBottomCustom />}
            onClick={() => {
              dispatch(
                setLayouts('take-off', { rightSideComponentOpened, bottomComponentOpened: !bottomComponentOpened })
              )
            }}
          />
        </Tooltip>
      }
    >
      <Tooltip title="Group by">
        <Button type="text" shape="circle" icon={<GroupByCustom />} />
      </Tooltip>
      <Tooltip title="Refresh">
        <Button type="text" shape="circle" icon={<SyncOutlined />} onClick={onReset} />
      </Tooltip>
      <Divider type="vertical" />
      <Tooltip title="Wrap text">
        <Button type="text" shape="circle" icon={<TextWrappingCustom />} />
      </Tooltip>
      <Tooltip title="More">
        <Button type="text" shape="circle" icon={<MoreOutlined />} />
      </Tooltip>
    </SterlingTableHeader>
  )
}
