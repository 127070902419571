import React, { useState, useEffect, useRef } from 'react'
import { Input, Select } from 'antd'
import { InputConfig, SelectConfig } from './types/EditableCellTypes'

export const EditableCell = ({
  children,
  editable,
  record,
  dataIndex,
  handleSave,
  editionConfig,
  ...restProps
}: {
  children?: JSX.Element[]
  editable: boolean
  record: any
  dataIndex: string
  editionConfig?: InputConfig | SelectConfig
  handleSave: (id: number, updates: { [key: string]: any }) => void
}) => {
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState(record ? record[dataIndex] : '')
  const inputRef = useRef<Input>(null)
  const selectRef = useRef<Select>(null)
  useEffect(() => {
    if (editing) {
      if (inputRef?.current) {
        // @ts-ignore
        inputRef?.current.focus()
      }
      if (selectRef.current) {
        // @ts-ignore
        selectRef.current.focus()
      }
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
  }

  const save = (e: any) => {
    const castedValue =
      (typeof record[dataIndex] !== 'number' && record[dataIndex] !== undefined) || isNaN(value)
        ? value
        : parseFloat(value)
    toggleEdit()
    const updates = { [dataIndex]: castedValue }
    handleSave(record.id, updates)
  }

  if (editable) {
    if (editing) {
      if (editionConfig?.tag === 'select') {
        return (
          <td {...restProps}>
            <Select
              showSearch={true}
              style={{ width: '100%' }}
              value={value}
              ref={selectRef}
              onSelect={(event) => {
                setValue(event)
              }}
              onBlur={save}
            >
              {(editionConfig.options || []).map((o) => (
                <Select.Option key={editionConfig.optionRenderer(o)} value={editionConfig.optionRenderer(o)}>
                  {editionConfig.optionRenderer(o)}
                </Select.Option>
              ))}
            </Select>
          </td>
        )
      } else {
        return (
          <td {...restProps}>
            <Input
              value={value}
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              onChange={(event) => setValue(event.target.value)}
            />
          </td>
        )
      }
    } else {
      return (
        <td {...restProps}>
          <div style={{ height: value ? '100%' : '30px' }} className={'editable-cell-value-wrap'} onClick={toggleEdit}>
            {children}
          </div>
        </td>
      )
    }
  }
  return <td {...restProps}>{children}</td>
}
