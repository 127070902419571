import { ProjectWBS, CESMM4BS } from '../data/BillOfQuantitiesBreakdownStructures.data'

export type BoQBreakdownCode =
  | 'icms_code'
  | 'nrm1_code'
  | 'cesmm4_code'
  | 'rmm_code'
  | 'hmm_code'
  | 'uniclass_ss_code'
  | 'uniclass_pr_code'
  | 'ea_wbs_code'
  | 'wbs_code'

export interface BoQBreakdownType {
  name: string
  column: BoQBreakdownCode
  breakdownList?: any[]
}

// TODO - Add HS2 WBS - no data in excel
export const BoQBreakdowns: BoQBreakdownType[] = [
  {
    name: 'WBS Code',
    column: 'wbs_code',
    breakdownList: ProjectWBS,
  },
  {
    name: 'ICMS',
    column: 'icms_code',
  },
  {
    name: 'NRM1',
    column: 'nrm1_code',
  },
  {
    name: 'CESMM4',
    column: 'cesmm4_code',
    breakdownList: CESMM4BS,
  },
  {
    name: 'RMM1',
    column: 'rmm_code',
  },
  {
    name: 'HMM',
    column: 'hmm_code',
  },
  {
    name: 'Uniclass Ss',
    column: 'uniclass_ss_code',
  },
  {
    name: 'Uniclass Pr',
    column: 'uniclass_pr_code',
  },
  {
    name: 'EA WBS',
    column: 'ea_wbs_code',
  },
]

export const CostItemStatuses = ['To review', 'Under review', 'Completed']
export const MetricUnits = ['M', 'M2', 'M3', 'EACH', 'T']
export const MarkupTypes = ['Overhead']
export const NetMarkupTypes = ['Margin']
