import { numberSorter, stringSorter } from '../../../utils/Sorters'
import { ExtendedColumnType } from '../../basic/table/sterling-table/SterlingTable'
import { currency, renderDecimal, renderNumber } from '../../../utils/rendering/Rendering'
import { identity } from '../../../utils/FunctionalUtils'

export const WorkPackagesColumns: ExtendedColumnType[] = [
  {
    title: 'WP Code',
    dataIndex: 'wp_code',
    key: 'wp_code',
    width: 130,
    fixed: 'left',
    sorter: (a, b) => stringSorter(a.wp_code, b.wp_code),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: identity,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    width: 130,
    fixed: 'left',
    sorter: (a, b) => stringSorter(a.title, b.title),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: identity,
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    width: 130,
    sorter: (a, b) => stringSorter(a.note, b.note),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: identity,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 130,
    sorter: (a, b) => stringSorter(a.description, b.description),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: identity,
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    width: 130,
    sorter: (a, b) => stringSorter(a.location, b.location),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: identity,
  },
  {
    title: 'Cost Items',
    dataIndex: 'cost_items',
    key: 'cost_items',
    width: 130,
    sorter: (a, b) => numberSorter(a.cost_items, b.cost_items),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    align: 'right',
    render: (value, record) => renderNumber(record.cost_items ? record.cost_items.length : 0),
  },
  {
    title: 'Invited',
    dataIndex: 'invited',
    key: 'invited',
    width: 130,
    sorter: (a, b) => numberSorter(a.invited, b.invited),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: renderNumber,
  },
  {
    title: 'Accepted',
    dataIndex: 'accepted',
    key: 'accepted',
    width: 130,
    sorter: (a, b) => numberSorter(a.accepted, b.accepted),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: renderNumber,
  },
  {
    title: 'Declined',
    dataIndex: 'declined',
    key: 'declined',
    width: 130,
    sorter: (a, b) => numberSorter(a.declined, b.declined),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: renderNumber,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 130,
    sorter: (a, b) => stringSorter(a.status, b.status),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: identity,
  },
  {
    title: 'Responsible',
    dataIndex: 'responsible',
    key: 'responsible',
    width: 130,
    sorter: (a, b) => stringSorter(a.responsible, b.responsible),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: identity,
  },
  {
    title: 'Due date',
    dataIndex: 'due_date',
    key: 'due_date',
    width: 130,
    sorter: (a, b) => stringSorter(a.due_date, b.due_date),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: identity,
  },
  {
    title: 'Awarded',
    dataIndex: 'awarded',
    key: 'awarded',
    width: 130,
    sorter: (a, b) => stringSorter(a.awarded, b.awarded),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: identity,
  },
  {
    title: 'Total Manhours',
    dataIndex: 'total_manhours',
    key: 'total_manhours',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_manhours, b.total_manhours),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: renderDecimal,
  },
  {
    title: 'Total Plant Hours',
    dataIndex: 'total_plant_hours',
    key: 'total_plant_hours',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_plant_hours, b.total_plant_hours),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: renderDecimal,
  },
  {
    title: 'Total Labour Cost',
    dataIndex: 'total_labour_cost',
    key: 'total_labour_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_labour_cost, b.total_labour_cost),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: currency,
  },
  {
    title: 'Total Material Cost',
    dataIndex: 'total_material_cost',
    key: 'total_material_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_material_cost, b.total_material_cost),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: currency,
  },
  {
    title: 'Total Plant Cost',
    dataIndex: 'total_plant_cost',
    key: 'total_plant_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_plant_cost, b.total_plant_cost),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: currency,
  },
  {
    title: 'Total Subcontractor Cost',
    dataIndex: 'total_subcontractor_cost',
    key: 'total_subcontractor_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_subcontractor_cost, b.total_subcontractor_cost),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: currency,
  },
  {
    title: 'Base Total',
    dataIndex: 'base_total',
    key: 'base_total',
    width: 130,
    sorter: (a, b) => numberSorter(a.base_total, b.base_total),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: currency,
  },
  {
    title: 'Net Total',
    dataIndex: 'net_total',
    key: 'net_total',
    width: 130,
    sorter: (a, b) => numberSorter(a.net_total, b.net_total),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: currency,
  },
  {
    title: 'Gross Total',
    dataIndex: 'gross_total',
    key: 'gross_total',
    width: 130,
    sorter: (a, b) => numberSorter(a.gross_total, b.gross_total),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: currency,
  },
  {
    title: 'Awarded Labour Cost',
    dataIndex: 'awarded_labour_cost',
    key: 'awarded_labour_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.awarded_labour_cost, b.awarded_labour_cost),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: currency,
  },
  {
    title: 'Awarded Material Cost',
    dataIndex: 'awarded_material_cost',
    key: 'awarded_material_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.awarded_material_cost, b.awarded_material_cost),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: currency,
  },
  {
    title: 'Awarded Plant Cost',
    dataIndex: 'awarded_plant_cost',
    key: 'awarded_plant_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.awarded_plant_cost, b.awarded_plant_cost),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: currency,
  },
  {
    title: 'Awarded Subcontract Cost',
    dataIndex: 'awarded_subcontract_cost',
    key: 'awarded_subcontract_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.awarded_subcontract_cost, b.awarded_subcontract_cost),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: currency,
  },
  {
    title: 'Awarded Total Cost',
    dataIndex: 'awarded_total_cost',
    key: 'awarded_total_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.awarded_total_cost, b.awarded_total_cost),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: currency,
  },
  {
    title: 'Total Cost Difference',
    dataIndex: 'total_cost_difference',
    key: 'total_cost_difference',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_cost_difference, b.total_cost_difference),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: currency,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    width: 130,
    sorter: (a, b) => stringSorter(a.currency, b.currency),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: identity,
  },
  {
    title: 'Creator',
    dataIndex: 'creator',
    key: 'creator',
    width: 130,
    sorter: (a, b) => stringSorter(a.creator, b.creator),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: identity,
  },
  {
    title: 'Last Editor',
    dataIndex: 'last_editor',
    key: 'last_editor',
    width: 130,
    sorter: (a, b) => stringSorter(a.last_editor, b.last_editor),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: identity,
  },
  {
    title: 'Creation Date',
    dataIndex: 'creation_date',
    key: 'creation_date',
    width: 130,
    sorter: (a, b) => stringSorter(a.creation_date, b.creation_date),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: identity,
  },
  {
    title: 'Last Edited',
    dataIndex: 'last_edited',
    key: 'last_edited',
    width: 130,
    sorter: (a, b) => stringSorter(a.last_edited, b.last_edited),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    render: identity,
  },
]
