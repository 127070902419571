import { Drawings2DFilesIDs, FileEntry } from '../../../../data/take-off/TakeOff.data'
import { Entry } from '../../../basic/table/sterling-table/SterlingTable'
import { emptyTakeOffTableItem } from '../TakeOffTables.model'
import { TakeOffItem } from './TakeOffDataParser'
import { convertToTableItem } from './TakeOffConverter'

export const parseTakeOffTableDataFiles = (
  takeOffFiles: { [key: string]: FileEntry[] },
  drawings2DTakeOffItems?: { [fileId: number]: TakeOffItem[] }
): Entry[] => {
  return Object.entries(takeOffFiles).map((value, index) => {
    const [key, files] = value
    const mainEntry = emptyTakeOffTableItem(index, key)
    const children = files.map((f) => {
      if (Drawings2DFilesIDs.includes(f.id) && drawings2DTakeOffItems) {
        const drawingChildren = (drawings2DTakeOffItems[f.id] || []).map((t) => convertToTableItem(t, f.file, '2D'))
        return emptyTakeOffTableItem(f.id, f.file, f.urn, drawingChildren)
      } else {
        return emptyTakeOffTableItem(f.id, f.file, f.urn, f.children)
      }
    })
    children.forEach((c) => (c.parent = mainEntry))
    mainEntry.children = children
    return mainEntry
  })
}
