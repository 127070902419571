import React from 'react'
import { Breadcrumb, Row, Space, Tooltip } from 'antd'
import { ColumnsLayoutManagerProps } from '../../columns-layout-manager/ColumnsLayoutManager'
import ColumnsLayoutManager from '../../columns-layout-manager/ColumnsLayoutManager'
import TableRowsExpansionManager, {
  TableRowsExpansionManagerProps,
} from '../../managers/row-expansion/TableRowsExpansionManager'

export interface SterlingTableHeaderProps {
  columnsLayoutManager: ColumnsLayoutManagerProps
  rowsExpand: TableRowsExpansionManagerProps
  children?: React.ReactNode
  rightSideChildren?: React.ReactNode
  columnsLayoutManagerVisibility: boolean
  descriptionManagerVisibility: boolean
  projectName?: string
  projectCode?: string
}

export const SterlingTableHeader = ({
  columnsLayoutManager: { tableId, columns, visibleColumns, onApply, permanentlyLocked, lockers, onLayoutsSaved },
  columnsLayoutManagerVisibility,
  descriptionManagerVisibility,
  projectName,
  projectCode,
  rowsExpand: { onExpandToLevel, levelsNumber },
  children,
  rightSideChildren,
}: SterlingTableHeaderProps) => {
  return (
    <>
      {descriptionManagerVisibility && (
        <Row style={{ padding: '9px 0px 9px 12px' }}>
          <Breadcrumb separator="">
            <Breadcrumb.Item>Details</Breadcrumb.Item>
            {projectName !== undefined ? <Breadcrumb.Separator>-</Breadcrumb.Separator> : undefined}
            <Breadcrumb.Item>{projectName}</Breadcrumb.Item>
            {projectName !== undefined ? <Breadcrumb.Separator /> : undefined}
            <Breadcrumb.Item>{projectCode}</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      )}
      <Row>
        <div style={{ display: 'flex', flexDirection: 'row', padding: '8px', width: '100%' }}>
          <Space style={{ flex: 10 }}>
            {columnsLayoutManagerVisibility && (
              <ColumnsLayoutManager
                tableId={tableId}
                columns={columns}
                visibleColumns={visibleColumns}
                onApply={onApply}
                lockers={lockers}
                permanentlyLocked={permanentlyLocked}
                onLayoutsSaved={onLayoutsSaved}
              />
            )}
            {levelsNumber > 0 && (
              <TableRowsExpansionManager levelsNumber={levelsNumber} onExpandToLevel={onExpandToLevel} />
            )}
            {children}
          </Space>
          <Space style={{ flex: 1, textAlign: 'right' }}>{rightSideChildren}</Space>
        </div>
      </Row>
    </>
  )
}
