import React, { Key, useState } from 'react'
import TableSelectionToolbar, {
  TableSelectionToolbarProps,
} from '../../../basic/table/table-selection-toolbar/TableSelectionToolbar'
import { Button, Modal } from 'antd'
import { Drawings2DFilesIDs } from '../../../../data/take-off/TakeOff.data'
import { TakeOffItem } from '../helpers/TakeOffDataParser'
import { GenerateCostItemsForm } from '../form/GenerateCostItemsForm'

interface TakeOffSelectionToolbarProps {
  baseProps: TableSelectionToolbarProps
  onAddTakeOffItem: (parentId: number) => void
  onAddTakeOffDetailsItem: (id: number) => void
  drawings2DTakeOffItems: TakeOffItem[]
  allTakeOffItems: TakeOffItem[]
}
export const TakeOffSelectionToolbar = ({
  baseProps,
  onAddTakeOffItem,
  onAddTakeOffDetailsItem,
  drawings2DTakeOffItems,
  allTakeOffItems,
}: TakeOffSelectionToolbarProps) => {
  const [modalVisible, setModalVisibility] = useState(false)
  const hideModal = () => setModalVisibility(false)

  const renderAddTakeOffItem = () => {
    if (baseProps.selectedItemsNumber === 1) {
      const selectedItem = baseProps.selectedItems[0] as number
      if (Drawings2DFilesIDs.includes(selectedItem)) {
        return (
          <Button type="text" onClick={() => onAddTakeOffItem(selectedItem)}>
            Add take off
          </Button>
        )
      } else if (drawings2DTakeOffItems.map((i) => i.id).includes(selectedItem)) {
        return (
          <Button type="text" onClick={() => onAddTakeOffDetailsItem(selectedItem)}>
            Add take off details
          </Button>
        )
      }
    }
  }

  return (
    <TableSelectionToolbar {...baseProps}>
      {baseProps.selectedItems.some((key) => allTakeOffItems.some((t) => t.id === (key as number))) && (
        <Button type="text" onClick={() => setModalVisibility(true)}>
          Generate cost items
        </Button>
      )}
      {renderAddTakeOffItem()}
      <Modal width={'50%'} visible={modalVisible} destroyOnClose={true} onCancel={hideModal} footer={null}>
        <GenerateCostItemsForm
          closeModal={hideModal}
          takeOffDetailsItems={allTakeOffItems
            .filter((i) => baseProps.selectedItems.includes(i.id as Key))
            .flatMap((i) => i.children)}
        />
      </Modal>
    </TableSelectionToolbar>
  )
}
