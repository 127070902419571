import { ModelNode } from '../../components/domain/forge-viewer/ForgeViewer.model'
import { parseTakeOffData, TakeOffItem } from '../../components/domain/take-off/helpers/TakeOffDataParser'
import { convertToTableItem } from '../../components/domain/take-off/helpers/TakeOffConverter'
import { TakeOffTableItem } from '../../components/domain/take-off/TakeOffTables.model'

export const ModelExteriorData: ModelNode = {
  id: 1,
  name: 'Model',
  children: [
    {
      id: 3637,
      name: 'Walls',
      children: [
        {
          id: 3638,
          name: 'Basic Wall',
          children: [
            {
              id: 3640,
              name: '03.02.05.01.01.040_Engeneering brick plinth with 183mm air barrier Spec NBS F10/385',
              children: [
                {
                  id: 3641,
                  name: 'Basic Wall [803732]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 18665.002755159207,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 23.69450337509027,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 6.705544455150741,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 283,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3642,
                  name: 'Basic Wall [804373]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7070.030012744754,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 8.660786765612311,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.451002654668342,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 283,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3654,
                  name: 'Basic Wall [815251]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5289.996206744767,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.512748198210883,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.7111077400936751,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 283,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3945,
                  name: 'Basic Wall [845230]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 13715.007410914599,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 14.484877420940949,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 4.099220310126225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 283,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.05.01.01.040_Engeneering brick plinth with 183mm air barrier Spec NBS F10/385',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 283,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 3660,
              name: '03.02.05.01.01.030_Facing Brick with 183mm air barrier Spec NBS F10/110',
              children: [
                {
                  id: 3661,
                  name: 'Basic Wall [820043]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7070.037213422537,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 58.89340998780026,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 16.66683502654842,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 283,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3932,
                  name: 'Basic Wall [845222]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 37669.97659763945,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 237.55037577925407,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 67.22675634552918,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 283,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.05.01.01.030_Facing Brick with 183mm air barrier Spec NBS F10/110',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 283,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 3785,
              name: '03.02.05.01.01.000_Engeneering brick plinth with 150mm air barrier Spec NBS F10/385',
              children: [
                {
                  id: 3786,
                  name: 'Basic Wall [828973]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 25299.947463544777,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 30.992435642876526,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 7.748108910718744,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3805,
                  name: 'Basic Wall [834595]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 25299.98236775211,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 25.302235058897566,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 6.325558764724179,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.05.01.01.000_Engeneering brick plinth with 150mm air barrier Spec NBS F10/385',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 250,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 3788,
              name: '03.02.05.01.01.010_Facing Brick with 150mm air barrier Spec NBS F10/110',
              children: [
                {
                  id: 3789,
                  name: 'Basic Wall [829323]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6500.085500381459,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 54.145712218168846,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 13.536428054541586,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3790,
                  name: 'Basic Wall [829899]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 18799.861963163457,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 55.89195483128059,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 13.972988707819422,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3806,
                  name: 'Basic Wall [835098]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 25300.000032874756,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 168.83243584174008,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 42.208107370048204,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.05.01.01.010_Facing Brick with 150mm air barrier Spec NBS F10/110',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 250,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 3798,
              name: '03.02.01.01.04.120_Concrete Infill Panel B14A',
              children: [
                {
                  id: 3799,
                  name: 'Basic Wall [833474]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2525.007656385652,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.0300091876627495,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.45450137814935043,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3800,
                  name: 'Basic Wall [833603]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1760.000002287028,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.112000002744419,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.31680000041167117,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3801,
                  name: 'Basic Wall [833738]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1895.0391353228829,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.274046962387422,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.34110704435812234,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3802,
                  name: 'Basic Wall [833871]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5569.984290458089,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.683981148549588,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.0025971722824643,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3803,
                  name: 'Basic Wall [833975]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5503.98431309327,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.604781175711846,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9907171763568035,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3804,
                  name: 'Basic Wall [834080]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3401.745106948345,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.167137756016323,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6250706634024544,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4530,
                  name: 'Basic Wall [863048]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2069.996160114845,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.9497445281636656,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.44246167922448953,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4645,
                  name: 'Basic Wall [885640]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5492.519507764285,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 12.742084867299717,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.9113127300946962,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4651,
                  name: 'Basic Wall [886918]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5492.480541298815,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.146983377667643,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.5220475066501347,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.01.01.04.120_Concrete Infill Panel B14A',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 150,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 3839,
              name: '03.02.06.01.02.020_Metal Louvres L1 NBS L10/655',
              children: [
                {
                  id: 3840,
                  name: 'Basic Wall [837522]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1809.9999929442868,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.171999991533147,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5429999978832615,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3841,
                  name: 'Basic Wall [837585]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1585.0000020595014,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.4870000045287424,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.8717500011321454,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3885,
                  name: 'Basic Wall [839306]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3609.999948157195,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.620799933641223,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.155199983410252,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3886,
                  name: 'Basic Wall [839403]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 8560.000024083203,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 24.22480006815545,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 6.056200017038583,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.01.02.020_Metal Louvres L1 NBS L10/655',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 250,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 3888,
              name: '03.02.05.01.01.050_Engeneering brick plinth with 182mm air barrier Spec NBS F10/385',
              children: [
                {
                  id: 3889,
                  name: 'Basic Wall [840923]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7070.000466925408,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 8.660750571993171,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.4423316613021866,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 282,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3890,
                  name: 'Basic Wall [841479]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7070.007185152595,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 8.706198834807287,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.455148071415747,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 282,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3926,
                  name: 'Basic Wall [844251]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 274.7901378539051,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.2679203844079298,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.07555354840303972,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 282,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3931,
                  name: 'Basic Wall [844650]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6740.000000000091,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 8.25650000000921,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.328333000002622,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 282,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5526,
                  name: 'Basic Wall [932441]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3999.999999999996,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.833024999999977,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5169130500000193,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 282,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5527,
                  name: 'Basic Wall [932712]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5489.9215618557755,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.564962741881485,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.7233194932106173,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 282,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5528,
                  name: 'Basic Wall [932774]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5439.992812066765,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.562624999972213,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.722659287168488,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 282,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5529,
                  name: 'Basic Wall [932949]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4053.441892481907,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.9709848989289107,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5558177414979965,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 282,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5530,
                  name: 'Basic Wall [933042]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 11502.127428469932,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.3965355285231515,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.5218230190436004,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 282,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.05.01.01.050_Engeneering brick plinth with 182mm air barrier Spec NBS F10/385',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 282,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 3892,
              name: '03.02.05.01.01.020_Facing Brick with 182mm air barrier Spec NBS F10/110',
              children: [
                {
                  id: 3893,
                  name: 'Basic Wall [841816]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7070.000448551759,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 58.893103736426745,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 16.60785525367311,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 282,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3995,
                  name: 'Basic Wall [846242]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6739.999999999926,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 21.736500000027927,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 6.1296930000079435,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 282,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.05.01.01.020_Facing Brick with 182mm air barrier Spec NBS F10/110',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 282,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 3895,
              name: '03.02.05.01.01.100_Through colour render with 180mm air barrier NBS M20/160',
              children: [
                {
                  id: 3896,
                  name: 'Basic Wall [841943]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7344.838207179283,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 53.654916839624256,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 16.09647505188776,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3996,
                  name: 'Basic Wall [846450]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 18753.424750654427,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 79.27978211748751,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 23.783934635246823,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 300,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.05.01.01.100_Through colour render with 180mm air barrier NBS M20/160',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 300,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 3998,
              name: '03.02.05.01.01.090_Through colour render with 150mm air barrier NBS M20/160',
              children: [
                {
                  id: 3999,
                  name: 'Basic Wall [847599]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 18799.914979194727,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 86.39036596868549,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 23.325398811544073,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 270,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.05.01.01.090_Through colour render with 150mm air barrier NBS M20/160',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 270,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 4151,
              name: '03.02.01.01.03.000_Stone clad colonnade Vertical NBS F22/110',
              children: [
                {
                  id: 4152,
                  name: 'Basic Wall [851954]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 510.0128402032304,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.714625520555177,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.4243130523088119,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4153,
                  name: 'Basic Wall [852163]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 510.00000066270155,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.007315296105012,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.36065837664949285,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4154,
                  name: 'Basic Wall [852213]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 510.0000006627438,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.007315296104971,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.3606583766493937,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4155,
                  name: 'Basic Wall [852225]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 509.9608423880809,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.2998783686897903,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.29698580863305046,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4156,
                  name: 'Basic Wall [852292]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 510.0128402032304,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.714636943359987,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.42431408035338386,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4157,
                  name: 'Basic Wall [852293]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 510.00000066270155,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.007325005207091,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.36065925046867997,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4158,
                  name: 'Basic Wall [852294]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 510.0000006627438,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.00732500520705,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.3606592504685808,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4159,
                  name: 'Basic Wall [852295]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 509.9608423880809,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.2998783686897903,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.29698580863305046,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4160,
                  name: 'Basic Wall [852312]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 510.0128402032304,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.714636943359987,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.42431408035338386,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4161,
                  name: 'Basic Wall [852313]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 510.00000066270155,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.007325005207091,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.36065925046867997,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4162,
                  name: 'Basic Wall [852314]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 510.0000006627438,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.00732500520705,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.3606592504685808,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4163,
                  name: 'Basic Wall [852315]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 509.9608423880809,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.2998783686897903,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.29698580863305046,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4164,
                  name: 'Basic Wall [852620]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 600.0128395405819,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.714589463972675,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.42431305175753725,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4165,
                  name: 'Basic Wall [852621]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 554.9740011006834,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.007111005041701,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.3606399904537154,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4166,
                  name: 'Basic Wall [852622]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 134.97983916864032,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.0427192575777458,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.09384473318199635,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4167,
                  name: 'Basic Wall [852635]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 135.38031355378988,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.0965805397856998,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0986922485807087,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4168,
                  name: 'Basic Wall [853323]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 134.76857288230818,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.057933297126119,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.09521399674134993,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4169,
                  name: 'Basic Wall [853507]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 134.76857288235624,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.0579332971264985,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.09521399674137733,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4170,
                  name: 'Basic Wall [853532]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 600.0784459408038,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.71510496501344,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.42435944685120625,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4171,
                  name: 'Basic Wall [853734]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 134.76857488177944,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.0579333128219686,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.09521399815397641,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4172,
                  name: 'Basic Wall [853735]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 600.0392222489072,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.714796765601082,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.42433170890409394,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4173,
                  name: 'Basic Wall [853769]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 510.29669112780215,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.009656250536709,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.36086906254831635,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4174,
                  name: 'Basic Wall [853890]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 135.03748350786495,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.0600442455367398,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.09540398209830582,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4643,
                  name: 'Basic Wall [885254]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 665.000000000086,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.344124999996797,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.21097124999971006,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4644,
                  name: 'Basic Wall [885495]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 217.82288731168643,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7678256777725464,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.06910431099951819,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4646,
                  name: 'Basic Wall [885727]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 217.82288731168643,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7678256777724751,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.06910431099953233,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4648,
                  name: 'Basic Wall [886102]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 217.82288731168643,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7678256777725464,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.06910431099951819,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4649,
                  name: 'Basic Wall [886159]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 182.7057972179017,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.6440379351921064,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0579634141672891,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4709,
                  name: 'Basic Wall [888709]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 30460.59119212516,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.922417956690664,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.983017616102236,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4710,
                  name: 'Basic Wall [889034]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5445.021859365979,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.9306106173966524,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1737549555656974,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4761,
                  name: 'Basic Wall [890193]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3895.288872833944,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.4087282851103344,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1267855456599377,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4762,
                  name: 'Basic Wall [890374]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3895.262874162463,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.408720630486506,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.12678470911566356,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4763,
                  name: 'Basic Wall [890416]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5490.004639016369,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.9627828143936865,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1766503056673097,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4764,
                  name: 'Basic Wall [890525]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5529.987516324726,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.993165811056376,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1793847753669582,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4765,
                  name: 'Basic Wall [890576]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3895.288872833944,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.3765515717393921,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.12388964145655278,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4766,
                  name: 'Basic Wall [890577]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3895.262874162463,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.3765439169483225,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1238888048972488,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4767,
                  name: 'Basic Wall [890626]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3895.288872833944,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.376551571739399,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1238896414565313,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4768,
                  name: 'Basic Wall [890627]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4404.389874665335,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.5585648718815575,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.14027083846933902,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4769,
                  name: 'Basic Wall [891810]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4406.099192552968,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.559175985567441,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1403258387010779,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 90.00000000000001,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.01.01.03.000_Stone clad colonnade Vertical NBS F22/110',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 90.00000000000001,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 4532,
              name: '03.02.05.01.01.070_Metal cladding NBS H92/120',
              children: [
                {
                  id: 4533,
                  name: 'Basic Wall [863746]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4059.9586464719773,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 16.199234999423197,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.6199234999423622,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5085,
                  name: 'Basic Wall [915924]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4615.93764622592,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.908549978482403,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.4908546705822015,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5176,
                  name: 'Basic Wall [925835]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7822.324096405474,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.460758372256761,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6460758372256136,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5177,
                  name: 'Basic Wall [925918]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7822.324096405692,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.845210034589214,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5845210034589715,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5203,
                  name: 'Basic Wall [927402]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4615.937646225862,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.6154092654647,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.46154061315967704,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5293,
                  name: 'Basic Wall [929042]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4615.93764622592,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.908549978482413,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.49085467058219956,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5294,
                  name: 'Basic Wall [929043]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7822.324096405474,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.460758372256777,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.646075837225645,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5295,
                  name: 'Basic Wall [929044]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7822.324096405692,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.845210034589285,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5845210034587953,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5314,
                  name: 'Basic Wall [929070]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4615.937646225862,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.615409265464719,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.4615406131596453,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5409,
                  name: 'Basic Wall [929191]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4615.937646225795,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.9085499784822195,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.49085467058224647,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5410,
                  name: 'Basic Wall [929192]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7822.324096405444,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.460758372256769,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6460758372256767,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5411,
                  name: 'Basic Wall [929193]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 7822.324096405692,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.845210034589285,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5845210034588773,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5430,
                  name: 'Basic Wall [929212]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4615.937646225738,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.615409265464398,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.4615406131595881,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.05.01.01.070_Metal cladding NBS H92/120',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 100,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 4535,
              name: '03.02.06.02.01.040_Metal clad vehicle gate NBS L20/690 _ HOST WALL',
              children: [
                {
                  id: 4536,
                  name: 'Basic Wall [864719]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4059.9233695226985,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 21.2130996057616,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.21213099605777155,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 9.999999999999993,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.02.01.040_Metal clad vehicle gate NBS L20/690 _ HOST WALL',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 9.999999999999993,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 4542,
              name: '03.02.06.02.01.060_Vehicle Gate With Cladding NBS L20/691 _ HOST WALL',
              children: [
                {
                  id: 4543,
                  name: 'Basic Wall [865238]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4059.9233695226985,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 21.2130996057616,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.21213099605777155,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 9.999999999999993,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.02.01.060_Vehicle Gate With Cladding NBS L20/691 _ HOST WALL',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 9.999999999999993,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 4549,
              name: '03.02.06.02.01.160_Weldmesh cladding to gate NBS Q40/126',
              children: [
                {
                  id: 4550,
                  name: 'Basic Wall [871652]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4060.110800128264,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 16.565252064508233,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.981745371611116,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 180,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4555,
                  name: 'Basic Wall [876982]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4060.110800128264,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.150277000325081,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.8270498600582907,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 180,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.02.01.160_Weldmesh cladding to gate NBS Q40/126',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 180,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 4558,
              name: '03.02.05.01.01.060_Painted fair faced block work NBS M60/11',
              children: [
                {
                  id: 4559,
                  name: 'Basic Wall [877534]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5674.210425185167,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 53.35273591170386,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 13.338183977925324,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4560,
                  name: 'Basic Wall [877763]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 21599.936065643004,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 195.85941180391563,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 48.96485295097659,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4561,
                  name: 'Basic Wall [877837]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5675.107639126215,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 51.06099027996136,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 12.765247569989723,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4562,
                  name: 'Basic Wall [877923]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1024.3632643032072,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.430371118116996,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.107592779529286,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4563,
                  name: 'Basic Wall [877972]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1550.1465579334977,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.704383863070424,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.6760959657676269,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4564,
                  name: 'Basic Wall [878021]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1024.3632643028807,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.3491211181144367,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.8372802795286336,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4568,
                  name: 'Basic Wall [878422]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 19910.0875126157,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 176.50680511606444,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 44.12670127901405,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4571,
                  name: 'Basic Wall [878829]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 274.96045726598584,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.9699041088700263,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2424760272174813,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4572,
                  name: 'Basic Wall [878978]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1749.9999999999518,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.243749999999902,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.0609374999999692,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4573,
                  name: 'Basic Wall [879033]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 275.0250734010337,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.3638108029973115,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.09095270074932703,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4574,
                  name: 'Basic Wall [879090]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1077.989806826635,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 9.917506222805052,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.479376555701148,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4575,
                  name: 'Basic Wall [879176]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 641.1252987529402,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 4.601211371829285,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.1502881867104588,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4577,
                  name: 'Basic Wall [879457]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1499.9999999999302,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.162499999999529,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.5406250000000568,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4619,
                  name: 'Basic Wall [882656]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 2161.9226564926284,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 14.647025997737565,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 3.661756499434642,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.05.01.01.060_Painted fair faced block work NBS M60/11',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 250,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 4641,
              name: '03.03.02.01.01.000_Plaster Board K13/170',
              children: [
                {
                  id: 4642,
                  name: 'Basic Wall [884814]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5379.984935377002,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 11.203468364270503,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.8008670910676177,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4647,
                  name: 'Basic Wall [885827]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5500.006991337146,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 11.366663489242349,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.841669117500633,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.03.02.01.01.000_Plaster Board K13/170',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 250,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 4922,
              name: '03.02.03.02.01.010_Superstructure - Metal standing seam roofing vertical Spec NBS H31/125',
              children: [
                {
                  id: 4923,
                  name: 'Basic Wall [896881]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1709.8889011336853,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.34091092414860535,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.08522773103714723,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4924,
                  name: 'Basic Wall [897724]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 600.0335243320333,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.12033608970783334,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.030084022426956947,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4925,
                  name: 'Basic Wall [897925]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 600.0335243320333,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.12033608970783334,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.030084022426956947,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4926,
                  name: 'Basic Wall [898023]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 13659.962734825453,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.7374856129831904,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6843714032457656,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5022,
                  name: 'Basic Wall [902417]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 47473.876435997816,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 7.64059261740358,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.91014815435081,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5023,
                  name: 'Basic Wall [902673]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 47473.87643599781,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 7.640592617403582,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.9101481543508627,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5024,
                  name: 'Basic Wall [902720]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 24521.003200708266,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.8857876124335644,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9714469031083468,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5025,
                  name: 'Basic Wall [902847]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 24521.003200708263,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 3.885965011458016,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.971491252864475,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 250,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.02.03.02.01.010_Superstructure - Metal standing seam roofing vertical Spec NBS H31/125',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 250,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 5108,
              name: '03.02.03.02.05.020_Aluminium Fascia vertical Spec NBS H72/480',
              children: [
                {
                  id: 5109,
                  name: 'Basic Wall [920709]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6388.950260585597,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 6.538748339974007,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.13077496679943162,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 19.999999999999986,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5127,
                  name: 'Basic Wall [921382]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1124.3576802805685,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.361307175590008,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.027226143511799892,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 19.999999999999986,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5128,
                  name: 'Basic Wall [921609]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5500.633559556473,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.087199405103494,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.10174398810206951,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 19.999999999999986,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5146,
                  name: 'Basic Wall [921769]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 17499.993687106788,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 8.372546464203133,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.16745092928408636,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 19.999999999999986,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.03.02.05.020_Aluminium Fascia vertical Spec NBS H72/480',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 19.999999999999986,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 5229,
              name: '03.02.06.01.01.070_Side windows L10/31',
              children: [
                {
                  id: 5230,
                  name: 'Basic Wall [927904]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1871.9999999999422,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.0142819935782494,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2014281993578427,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5247,
                  name: 'Basic Wall [928249]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1871.9999999999422,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.0142819935782406,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.20142819935785294,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5332,
                  name: 'Basic Wall [929095]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1871.9999999999422,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.0142819935782494,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2014281993578427,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5345,
                  name: 'Basic Wall [929112]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1871.9999999999422,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.0142819935782406,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.20142819935785294,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5448,
                  name: 'Basic Wall [929230]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1871.9999999999422,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.0142819935782494,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2014281993578427,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5461,
                  name: 'Basic Wall [929243]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1871.9999999999422,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.0142819935782406,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.20142819935785294,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 100,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.01.01.070_Side windows L10/31',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 100,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Basic Wall',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Walls',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3703,
          name: 'Curtain Wall',
          children: [
            {
              id: 3705,
              name: '03.02.06.01.01.035_Ballistic Steel Curtain Wall WG 001 NBS H11/114',
              children: [
                {
                  id: 3706,
                  name: 'Curtain Wall [825106]',
                  children: [
                    {
                      id: 3711,
                      name: 'System Panel [825107]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.672031245860852,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 320.0148789813569,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2100.0000000000077,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 3717,
                      name: 'Rectangular Mullion [825224]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1674.985598699859,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3718,
                      name: 'Rectangular Mullion [825232]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 424.9999999993143,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3719,
                      name: 'Rectangular Mullion [825235]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1024.9999999999923,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3720,
                      name: 'Rectangular Mullion [825237]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 320.0148789813419,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3725,
                      name: 'System Panel [827550]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.6806305796168047,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1815.0148789814937,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 374.99999999930833,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 3726,
                      name: 'Rectangular Mullion [827557]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1150.0000000000157,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3727,
                      name: 'Rectangular Mullion [827558]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 399.99999999932,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3732,
                      name: 'Sgl Glass (5) [827761]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Doors',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1000.000000000059,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2100.0000000000077,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 0,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Fire Rating',
                          displayValue: '',
                          displayCategory: 'Identity Data',
                        },
                      ],
                    },
                    {
                      id: 3734,
                      name: 'Rectangular Mullion [827770]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 345.0148789813419,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3735,
                      name: 'Rectangular Mullion [827771]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1000.000000000049,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3737,
                      name: 'System Panel [827831]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.7770000000002184,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 370.00000000010266,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2100.0000000000077,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 3739,
                      name: 'Rectangular Mullion [827841]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1050.000000000049,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3740,
                      name: 'Rectangular Mullion [827842]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 370.00000000009265,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3744,
                      name: 'System Panel [828091]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.567483876264287,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1649.9830276466319,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 949.9999999999919,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 3745,
                      name: 'System Panel [828092]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.6374936353663075,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1699.9830276466225,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 374.99999999930833,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 3746,
                      name: 'Rectangular Mullion [828101]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1649.9830276466218,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3747,
                      name: 'Rectangular Mullion [828102]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 420.00000000009265,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3752,
                      name: 'System Panel [828243]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.81498133041134,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1649.9830276466319,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 1100.000000000027,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 3753,
                      name: 'Rectangular Mullion [828259]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1150.0000000000157,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3754,
                      name: 'Rectangular Mullion [828260]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1024.9999999999923,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3756,
                      name: 'System Panel [828387]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.567486318764867,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1649.9855986998743,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 949.9999999999919,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 3757,
                      name: 'System Panel [828388]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.8149841585699065,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1649.9855986998743,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 1100.000000000027,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 3758,
                      name: 'System Panel [828389]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.6281195995112921,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1674.9855986998687,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 374.99999999930833,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 3759,
                      name: 'Rectangular Mullion [828398]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1699.9830276466218,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3760,
                      name: 'Rectangular Mullion [828399]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1649.9830276466218,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3762,
                      name: 'System Panel [828709]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.6806305796168047,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1815.0148789814937,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 374.99999999930833,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 3765,
                      name: 'System Panel [828712]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.6374936353663075,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1699.9830276466225,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 374.99999999930833,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 3766,
                      name: 'System Panel [828713]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.6281195995112921,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1674.9855986998687,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 374.99999999930833,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 3767,
                      name: 'Rectangular Mullion [828724]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 399.99999999932,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3768,
                      name: 'Rectangular Mullion [828725]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 424.9999999993143,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3769,
                      name: 'Rectangular Mullion [828756]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1024.9999999999923,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3770,
                      name: 'Rectangular Mullion [828757]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1175.0000000000157,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3771,
                      name: 'Rectangular Mullion [828764]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 320.0148789813419,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3772,
                      name: 'Rectangular Mullion [828765]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1000.000000000049,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3773,
                      name: 'Rectangular Mullion [828766]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 370.00000000009265,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3774,
                      name: 'Rectangular Mullion [828767]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1649.9830276466218,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3775,
                      name: 'Rectangular Mullion [828768]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1649.9830276466218,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3776,
                      name: 'Rectangular Mullion [828784]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1024.9999999999923,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3777,
                      name: 'Rectangular Mullion [828785]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1175.0000000000157,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3778,
                      name: 'Rectangular Mullion [828792]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1175.0000000000157,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3779,
                      name: 'Rectangular Mullion [828798]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1024.9999999999923,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3780,
                      name: 'Rectangular Mullion [828803]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1649.9830276466218,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3781,
                      name: 'Rectangular Mullion [828809]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1649.9830276466218,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3782,
                      name: 'Rectangular Mullion [828814]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1024.9999999999923,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3783,
                      name: 'Rectangular Mullion [828820]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1175.0000000000157,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                  ],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '2',
                      displayCategory: 'Vertical Grid',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Horizontal Grid',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5289.983505327965,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 15.869950515976711,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3915,
                  name: 'Curtain Wall [843084]',
                  children: [
                    {
                      id: 3916,
                      name: 'System Panel [843085]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 2.478249999997059,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 2154.9999999999777,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 1149.999999998647,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 3917,
                      name: 'Rectangular Mullion [843330]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1249.999999998659,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3918,
                      name: 'Rectangular Mullion [843331]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1249.999999998659,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3919,
                      name: 'Rectangular Mullion [843332]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2154.9999999999627,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3920,
                      name: 'Rectangular Mullion [843333]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2154.9999999999723,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3922,
                      name: 'System Panel [843699]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 2.478249999997059,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 2154.9999999999777,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 1149.999999998647,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 3923,
                      name: 'Rectangular Mullion [843706]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2154.9999999999627,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3924,
                      name: 'Rectangular Mullion [843707]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2154.9999999999723,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 3925,
                      name: 'Rectangular Mullion [843745]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1249.999999998659,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                  ],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Vertical Grid',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Horizontal Grid',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4459.999999999923,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.574999999993938,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4003,
                  name: 'Curtain Wall [848029]',
                  children: [
                    {
                      id: 4004,
                      name: 'System Panel [848030]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 3.6182500000001605,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1765.0000000000769,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2050.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4005,
                      name: 'Rectangular Mullion [848031]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5.929957251800076,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4006,
                      name: 'Rectangular Mullion [848032]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1765.0000000000618,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4009,
                      name: 'Rectangular Mullion [848033]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2125.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4010,
                      name: 'Rectangular Mullion [848034]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1069.9999999999993,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4013,
                      name: 'System Panel [849125]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 3.628499999999953,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1769.9999999999757,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2050.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4014,
                      name: 'Rectangular Mullion [849132]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1765.0000000000618,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4015,
                      name: 'Rectangular Mullion [849133]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1769.9999999999661,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4017,
                      name: 'System Panel [849248]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 3.628499999999953,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1769.9999999999757,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2050.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4018,
                      name: 'Rectangular Mullion [849255]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1769.9999999999661,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4019,
                      name: 'Rectangular Mullion [849256]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1769.9999999999661,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4021,
                      name: 'System Panel [849291]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.6150000000000139,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 300.00000000000654,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2050.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4022,
                      name: 'Rectangular Mullion [849298]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1769.9999999999661,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4023,
                      name: 'Rectangular Mullion [849299]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 299.99999999999716,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4025,
                      name: 'System Panel [849326]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 3.7720000000000913,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1840.000000000043,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2050.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4026,
                      name: 'Rectangular Mullion [849333]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 299.99999999999716,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4027,
                      name: 'Rectangular Mullion [849334]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1840.0000000000327,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4029,
                      name: 'System Panel [849366]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.8404999999999139,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 409.99999999995765,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2050.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4030,
                      name: 'Rectangular Mullion [849373]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1840.0000000000327,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4031,
                      name: 'Rectangular Mullion [849374]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 409.9999999999482,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4033,
                      name: 'System Panel [849476]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 3.7720000000000913,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1840.000000000043,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2050.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4034,
                      name: 'Rectangular Mullion [849483]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 435.00000000014995,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4035,
                      name: 'Rectangular Mullion [849484]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1840.0000000000327,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4037,
                      name: 'System Panel [849514]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.012156412366214515,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 5.929957251811953,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2050.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4038,
                      name: 'Rectangular Mullion [849521]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1840.0000000000327,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4039,
                      name: 'Rectangular Mullion [849522]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5.929957251800076,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4041,
                      name: 'System Panel [849611]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.288450000000069,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1765.0000000000769,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 730.0000000000073,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4042,
                      name: 'System Panel [849612]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.2920999999999951,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1769.9999999999757,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 730.0000000000073,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4043,
                      name: 'System Panel [849613]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.2920999999999951,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1769.9999999999757,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 730.0000000000073,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4044,
                      name: 'System Panel [849614]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.21900000000000694,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 300.00000000000654,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 730.0000000000073,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4045,
                      name: 'System Panel [849615]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.3432000000000448,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1840.000000000043,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 730.0000000000073,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4046,
                      name: 'System Panel [849616]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.328600000000009,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1819.9999999999945,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 730.0000000000073,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4047,
                      name: 'System Panel [849619]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.3432000000000448,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1840.000000000043,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 730.0000000000073,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4048,
                      name: 'System Panel [849620]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.004328868793822769,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 5.929957251811953,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 730.0000000000073,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4049,
                      name: 'Rectangular Mullion [849639]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.9999999999959,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4050,
                      name: 'Rectangular Mullion [849640]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2125.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4057,
                      name: 'System Panel [849878]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.2985000000000062,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 300.00000000000654,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 994.999999999999,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4061,
                      name: 'System Panel [849884]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.005900307465552888,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 5.929957251811953,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 994.999999999999,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4062,
                      name: 'Rectangular Mullion [849903]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.9999999999959,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4063,
                      name: 'Rectangular Mullion [849904]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1069.9999999999993,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4065,
                      name: 'System Panel [850171]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.8404999999999139,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 409.99999999995765,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2050.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4068,
                      name: 'Rectangular Mullion [850184]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 409.9999999999482,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4069,
                      name: 'Rectangular Mullion [850185]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 949.9999999998864,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4071,
                      name: 'Sgl Glass (5) [850214]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Doors',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 899.9999999998971,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2100.0000000000077,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 0,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Fire Rating',
                          displayValue: '',
                          displayCategory: 'Identity Data',
                        },
                      ],
                    },
                    {
                      id: 4074,
                      name: 'Rectangular Mullion [850228]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 434.9999999999482,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4075,
                      name: 'Rectangular Mullion [850266]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1069.9999999999993,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4076,
                      name: 'Rectangular Mullion [850267]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.9999999999959,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4077,
                      name: 'Rectangular Mullion [850268]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1069.9999999999993,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4078,
                      name: 'Rectangular Mullion [850269]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.9999999999959,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4079,
                      name: 'Rectangular Mullion [850270]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1069.9999999999993,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4080,
                      name: 'Rectangular Mullion [850271]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.9999999999959,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4081,
                      name: 'Rectangular Mullion [850272]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1069.9999999999993,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4082,
                      name: 'Rectangular Mullion [850273]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.9999999999959,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4083,
                      name: 'Rectangular Mullion [850274]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1069.9999999999993,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4084,
                      name: 'Rectangular Mullion [850275]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.9999999999959,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4085,
                      name: 'Rectangular Mullion [850276]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1069.9999999999993,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4086,
                      name: 'Rectangular Mullion [850277]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.9999999999959,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4087,
                      name: 'Rectangular Mullion [850278]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.9999999999959,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4088,
                      name: 'Rectangular Mullion [850279]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1069.9999999999993,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4089,
                      name: 'Rectangular Mullion [850280]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2150.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4090,
                      name: 'Rectangular Mullion [850281]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2150.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4091,
                      name: 'Rectangular Mullion [850282]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 949.9999999998864,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4092,
                      name: 'Rectangular Mullion [850283]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 435.00000000014995,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4093,
                      name: 'Rectangular Mullion [850284]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5.929957251800076,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4094,
                      name: 'Rectangular Mullion [850285]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1839.9999999999845,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4095,
                      name: 'Rectangular Mullion [850286]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 434.9999999999482,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4096,
                      name: 'Rectangular Mullion [850287]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1839.9999999999845,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4097,
                      name: 'Rectangular Mullion [850288]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 299.99999999999716,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4098,
                      name: 'Rectangular Mullion [850289]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1769.9999999999852,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4099,
                      name: 'Rectangular Mullion [850290]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1769.9999999999852,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4100,
                      name: 'Rectangular Mullion [850291]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1765.0000000000618,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4101,
                      name: 'Rectangular Mullion [850292]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 899.9999999998864,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4102,
                      name: 'Rectangular Mullion [850293]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 410.00000000014995,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4103,
                      name: 'Rectangular Mullion [850294]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 5.929957251800076,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4104,
                      name: 'Rectangular Mullion [850295]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1839.9999999999845,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4105,
                      name: 'Rectangular Mullion [850296]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1839.9999999999845,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4106,
                      name: 'Rectangular Mullion [850297]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 410.00000000014995,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4107,
                      name: 'Rectangular Mullion [850298]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 299.99999999999716,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4108,
                      name: 'Rectangular Mullion [850299]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1769.9999999999852,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4109,
                      name: 'Rectangular Mullion [850300]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1769.9999999999852,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4110,
                      name: 'Rectangular Mullion [850301]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1765.0000000000618,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4111,
                      name: 'Rectangular Mullion [850302]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2125.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4112,
                      name: 'Rectangular Mullion [850303]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2125.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4113,
                      name: 'Rectangular Mullion [850304]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2125.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4114,
                      name: 'Rectangular Mullion [850305]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2125.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4115,
                      name: 'Rectangular Mullion [850306]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2125.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4116,
                      name: 'Rectangular Mullion [850307]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2125.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4117,
                      name: 'Rectangular Mullion [850308]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2125.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                  ],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Vertical Grid',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Horizontal Grid',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 11735.929957251814,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 46.650321580075925,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4127,
                  name: 'Curtain Wall [851414]',
                  children: [
                    {
                      id: 4128,
                      name: 'System Panel [851415]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.26787267916655894,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 100.13931931460219,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2674.999999999981,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4129,
                      name: 'Rectangular Mullion [851464]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 100.1393193145872,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4130,
                      name: 'Rectangular Mullion [851465]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 374.9999999999917,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4131,
                      name: 'Rectangular Mullion [851466]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1405.000000001361,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4132,
                      name: 'Rectangular Mullion [851467]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2500.000000000003,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4176,
                      name: 'System Panel [855111]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.9275000000001402,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 350.0000000000547,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2649.9999999999864,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4177,
                      name: 'Rectangular Mullion [855116]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 350.0000000000448,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4178,
                      name: 'Rectangular Mullion [855168]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2500.000000000003,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4180,
                      name: 'System Panel [855294]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.12016718317807654,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 100.13931931460219,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 1200.0000000055313,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4181,
                      name: 'System Panel [855295]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.414875000000001,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1154.9999999999857,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 1225.000000000016,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4182,
                      name: 'Rectangular Mullion [855303]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1275.0000000000045,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4183,
                      name: 'Rectangular Mullion [855304]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1275.0000000000045,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4186,
                      name: 'System Panel [855444]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 2.506349999999959,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1154.9999999999857,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2169.999999999992,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4187,
                      name: 'Rectangular Mullion [855452]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2219.9999999999804,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4188,
                      name: 'Rectangular Mullion [855453]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2219.9999999999804,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4192,
                      name: 'Rectangular Mullion [855609]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2219.9999999999804,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4193,
                      name: 'Rectangular Mullion [855610]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1405.000000001361,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4194,
                      name: 'Rectangular Mullion [855611]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1405.000000001361,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4196,
                      name: 'System Panel [855993]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.9275000000001402,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 350.0000000000547,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2649.9999999999864,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4200,
                      name: 'Rectangular Mullion [856011]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.999999999931,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4201,
                      name: 'Rectangular Mullion [856012]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 350.0000000000448,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4206,
                      name: 'Door -  Revolving [856077]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Doors',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 2949.441892481683,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2425.000000000003,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 0,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Fire Rating',
                          displayValue: '',
                          displayCategory: 'Identity Data',
                        },
                      ],
                    },
                    {
                      id: 4210,
                      name: 'Rectangular Mullion [856095]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.999999999931,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4211,
                      name: 'Rectangular Mullion [856096]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 375.000000000016,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4215,
                      name: 'System Panel [856218]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.5161523311842794,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 2949.441892481683,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 174.99999999999486,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4217,
                      name: 'Rectangular Mullion [856231]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 224.9999999999835,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4218,
                      name: 'Rectangular Mullion [856232]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 224.9999999999835,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4222,
                      name: 'System Panel [856401]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.414874999999942,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1154.9999999999375,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 1225.000000000016,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4223,
                      name: 'System Panel [856402]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 2.506349999999855,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1154.9999999999375,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2169.999999999992,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4225,
                      name: 'Rectangular Mullion [856421]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1339.4418924817824,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4226,
                      name: 'Rectangular Mullion [856422]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.999999999931,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4230,
                      name: 'System Panel [856454]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.6408163182902173,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1339.4418924817926,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 1225.000000000016,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4231,
                      name: 'System Panel [856455]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 2.906588906685479,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1339.4418924817926,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2169.999999999992,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4233,
                      name: 'Rectangular Mullion [856474]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1389.4418924817824,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4234,
                      name: 'Rectangular Mullion [856475]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.9999999999598,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4235,
                      name: 'Rectangular Mullion [856555]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1339.4418924817824,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4236,
                      name: 'Rectangular Mullion [856556]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.999999999931,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4237,
                      name: 'Rectangular Mullion [856557]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.9999999999598,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4238,
                      name: 'Rectangular Mullion [856558]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 374.9999999999917,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4239,
                      name: 'Rectangular Mullion [856559]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 375.000000000016,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4240,
                      name: 'Rectangular Mullion [856560]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1339.4418924817824,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4241,
                      name: 'Rectangular Mullion [856561]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.999999999931,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4242,
                      name: 'Rectangular Mullion [856562]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.9999999999598,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4243,
                      name: 'Rectangular Mullion [856563]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 374.9999999999917,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4244,
                      name: 'Rectangular Mullion [856564]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 375.000000000016,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4245,
                      name: 'Rectangular Mullion [856566]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 754.999999999931,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4246,
                      name: 'Rectangular Mullion [856567]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1339.4418924817824,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4247,
                      name: 'Rectangular Mullion [856568]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 349.9999999999917,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4248,
                      name: 'Rectangular Mullion [856569]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 754.999999999931,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4249,
                      name: 'Rectangular Mullion [856570]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 349.9999999999917,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4250,
                      name: 'Rectangular Mullion [856571]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 100.13931931461143,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4251,
                      name: 'Rectangular Mullion [856573]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1250.0000000055372,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4252,
                      name: 'Rectangular Mullion [856574]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1405.000000001361,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4253,
                      name: 'Rectangular Mullion [856575]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2219.9999999999804,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4254,
                      name: 'Rectangular Mullion [856576]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1300.0000000000045,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4255,
                      name: 'Rectangular Mullion [856577]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1405.000000001361,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4256,
                      name: 'Rectangular Mullion [856578]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2219.9999999999804,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4257,
                      name: 'Rectangular Mullion [856579]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1300.0000000000045,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4258,
                      name: 'Rectangular Mullion [856580]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 249.9999999999835,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4259,
                      name: 'Rectangular Mullion [856581]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 249.9999999999835,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4260,
                      name: 'Rectangular Mullion [856582]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2500.000000000003,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4261,
                      name: 'Rectangular Mullion [856584]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 224.9999999999835,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4262,
                      name: 'Rectangular Mullion [856585]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1389.4418924817824,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4263,
                      name: 'Rectangular Mullion [856586]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.9999999999598,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4264,
                      name: 'Rectangular Mullion [856587]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.999999999931,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4265,
                      name: 'Rectangular Mullion [856588]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2500.000000000003,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4266,
                      name: 'Rectangular Mullion [856589]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2500.000000000003,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                  ],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Vertical Grid',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Horizontal Grid',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 3999.4418924816555,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 29.948798224451533,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4302,
                  name: 'Curtain Wall [858281]',
                  children: [
                    {
                      id: 4303,
                      name: 'System Panel [858282]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 3.5464531922172564,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1729.9771669352454,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2050.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4304,
                      name: 'Rectangular Mullion [858348]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2125.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4305,
                      name: 'Rectangular Mullion [858349]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1405.000000001361,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4306,
                      name: 'Rectangular Mullion [858350]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669351244,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4307,
                      name: 'Rectangular Mullion [858351]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669352303,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4309,
                      name: 'System Panel [859082]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.2628833318627768,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1729.9771669352933,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 730.0000000000073,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4310,
                      name: 'Rectangular Mullion [859089]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.9999999999959,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4311,
                      name: 'Rectangular Mullion [859090]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2125.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4314,
                      name: 'Rectangular Mullion [859122]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.9999999999959,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4315,
                      name: 'Rectangular Mullion [859123]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1094.9999999999936,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4317,
                      name: 'System Panel [859146]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 3.7540504522494684,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1729.9771669352454,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2169.999999999992,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4318,
                      name: 'Rectangular Mullion [859153]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1094.9999999999936,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4319,
                      name: 'Rectangular Mullion [859154]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2219.9999999999804,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4322,
                      name: 'Rectangular Mullion [859192]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2219.9999999999804,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4323,
                      name: 'Rectangular Mullion [859193]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1405.000000001361,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4325,
                      name: 'System Panel [859959]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.7585000000000147,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 370.0000000000068,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2050.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4330,
                      name: 'Rectangular Mullion [859981]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 949.9999999998864,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4331,
                      name: 'Rectangular Mullion [859982]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 370.00000000009265,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4333,
                      name: 'Sgl Glass (5) [860014]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Doors',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 900.0000000000217,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2100.0000000000077,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 0,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Fire Rating',
                          displayValue: '',
                          displayCategory: 'Identity Data',
                        },
                      ],
                    },
                    {
                      id: 4338,
                      name: 'Rectangular Mullion [860037]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 395.00000000001586,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4340,
                      name: 'System Panel [860128]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 3.5464531922172564,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1729.9771669352454,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2050.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4341,
                      name: 'System Panel [860130]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.2628833318627768,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1729.9771669352933,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 730.0000000000073,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4343,
                      name: 'System Panel [860132]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 3.7540504522494684,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1729.9771669352454,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2169.999999999992,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4345,
                      name: 'Rectangular Mullion [860145]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 394.99999999999665,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4346,
                      name: 'Rectangular Mullion [860146]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669352303,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4348,
                      name: 'System Panel [860185]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.7585000000000147,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 370.0000000000068,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2050.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4349,
                      name: 'System Panel [860187]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.2702000000000375,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1740.000000000034,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 730.0000000000073,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4351,
                      name: 'System Panel [860189]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 3.7758000000000593,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1740.000000000034,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2169.999999999992,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4353,
                      name: 'Rectangular Mullion [860201]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 370.00000000009265,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4354,
                      name: 'Rectangular Mullion [860202]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669351244,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4355,
                      name: 'Rectangular Mullion [860242]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 394.99999999999665,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4356,
                      name: 'Rectangular Mullion [860243]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669352303,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4357,
                      name: 'Rectangular Mullion [860244]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 949.9999999998864,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4358,
                      name: 'Rectangular Mullion [860245]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669352303,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4359,
                      name: 'Rectangular Mullion [860246]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 394.99999999999665,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4360,
                      name: 'Rectangular Mullion [860247]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669352303,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4361,
                      name: 'Rectangular Mullion [860248]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 395.00000000001586,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4362,
                      name: 'Rectangular Mullion [860249]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669352303,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4363,
                      name: 'Rectangular Mullion [860250]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 370.00000000009265,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4364,
                      name: 'Rectangular Mullion [860251]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 949.9999999998864,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4365,
                      name: 'Rectangular Mullion [860252]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 899.9999999998864,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4366,
                      name: 'Rectangular Mullion [860253]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669352303,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4367,
                      name: 'Rectangular Mullion [860254]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 370.00000000009265,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4368,
                      name: 'Rectangular Mullion [860255]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2150.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4369,
                      name: 'Rectangular Mullion [860256]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669352303,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4370,
                      name: 'Rectangular Mullion [860257]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669352303,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4371,
                      name: 'Rectangular Mullion [860258]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2125.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4372,
                      name: 'Rectangular Mullion [860259]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2150.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4373,
                      name: 'Rectangular Mullion [860261]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2125.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4374,
                      name: 'Rectangular Mullion [860264]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 395.00000000001586,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4375,
                      name: 'Rectangular Mullion [860265]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669352303,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4376,
                      name: 'Rectangular Mullion [860266]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.9999999999959,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4377,
                      name: 'Rectangular Mullion [860268]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 395.00000000001586,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4378,
                      name: 'Rectangular Mullion [860269]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 949.9999999998864,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4379,
                      name: 'Rectangular Mullion [860270]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 394.99999999999665,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4380,
                      name: 'Rectangular Mullion [860272]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2219.9999999999804,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4381,
                      name: 'Rectangular Mullion [860273]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1094.9999999999936,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4382,
                      name: 'Rectangular Mullion [860274]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1094.9999999999936,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4383,
                      name: 'Rectangular Mullion [860275]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1405.000000001361,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4384,
                      name: 'Rectangular Mullion [860276]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2219.9999999999804,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4385,
                      name: 'Rectangular Mullion [860277]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.9999999999959,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4386,
                      name: 'Rectangular Mullion [860278]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1405.000000001361,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                  ],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Vertical Grid',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Horizontal Grid',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5399.954333870397,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 41.17465179576178,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4393,
                  name: 'Curtain Wall [860908]',
                  children: [
                    {
                      id: 4394,
                      name: 'System Panel [860909]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 3.5464531922172564,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1729.9771669352454,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2050.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4395,
                      name: 'Rectangular Mullion [860910]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2125.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4396,
                      name: 'Rectangular Mullion [860911]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1405.000000001361,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4397,
                      name: 'Rectangular Mullion [860912]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669351244,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4398,
                      name: 'Rectangular Mullion [860913]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669352303,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4400,
                      name: 'System Panel [860915]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.2628833318627768,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1729.9771669352933,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 730.0000000000073,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4401,
                      name: 'Rectangular Mullion [860916]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.9999999999959,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4402,
                      name: 'Rectangular Mullion [860917]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2125.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4405,
                      name: 'Rectangular Mullion [860920]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.9999999999959,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4406,
                      name: 'Rectangular Mullion [860921]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1094.9999999999936,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4408,
                      name: 'System Panel [860923]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 3.7540504522494684,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1729.9771669352454,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2169.999999999992,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4409,
                      name: 'Rectangular Mullion [860924]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1094.9999999999936,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4410,
                      name: 'Rectangular Mullion [860925]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2219.9999999999804,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4413,
                      name: 'Rectangular Mullion [860928]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2219.9999999999804,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4414,
                      name: 'Rectangular Mullion [860929]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1405.000000001361,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4416,
                      name: 'System Panel [860946]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 3.5464531922172564,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1729.9771669352454,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2050.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4417,
                      name: 'System Panel [860947]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.2628833318627768,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1729.9771669352933,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 730.0000000000073,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4419,
                      name: 'System Panel [860949]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 3.7540504522494684,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1729.9771669352454,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2169.999999999992,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4421,
                      name: 'Rectangular Mullion [860951]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1740.000000000024,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4422,
                      name: 'Rectangular Mullion [860952]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669352303,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4424,
                      name: 'System Panel [860954]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 3.5670000000000726,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1740.000000000034,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2050.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4425,
                      name: 'System Panel [860955]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.2702000000000375,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1740.000000000034,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 730.0000000000073,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4427,
                      name: 'System Panel [860957]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 3.7758000000000593,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1740.000000000034,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2169.999999999992,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4429,
                      name: 'Rectangular Mullion [860959]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1740.000000000024,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4430,
                      name: 'Rectangular Mullion [860960]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669351244,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4431,
                      name: 'Rectangular Mullion [860962]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669352303,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4432,
                      name: 'Rectangular Mullion [860964]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669352303,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4433,
                      name: 'Rectangular Mullion [860966]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669352303,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4434,
                      name: 'Rectangular Mullion [860967]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1740.000000000024,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4435,
                      name: 'Rectangular Mullion [860968]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669352303,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4436,
                      name: 'Rectangular Mullion [860972]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669352303,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4437,
                      name: 'Rectangular Mullion [860973]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1740.000000000024,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4438,
                      name: 'Rectangular Mullion [860975]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669352303,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4439,
                      name: 'Rectangular Mullion [860976]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669352303,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4440,
                      name: 'Rectangular Mullion [860977]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2125.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4441,
                      name: 'Rectangular Mullion [860979]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2125.000000000002,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4442,
                      name: 'Rectangular Mullion [860980]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1740.000000000024,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4443,
                      name: 'Rectangular Mullion [860981]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1729.9771669352303,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4444,
                      name: 'Rectangular Mullion [860982]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.9999999999959,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4445,
                      name: 'Rectangular Mullion [860983]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1740.000000000024,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4446,
                      name: 'Rectangular Mullion [860986]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2219.9999999999804,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4447,
                      name: 'Rectangular Mullion [860987]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1094.9999999999936,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4448,
                      name: 'Rectangular Mullion [860988]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1094.9999999999936,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4449,
                      name: 'Rectangular Mullion [860989]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1405.000000001361,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4450,
                      name: 'Rectangular Mullion [860990]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2219.9999999999804,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4451,
                      name: 'Rectangular Mullion [860991]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 779.9999999999959,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4452,
                      name: 'Rectangular Mullion [860992]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1405.000000001361,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                  ],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Vertical Grid',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Horizontal Grid',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 5399.954333870397,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 41.17465179576178,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4459,
                  name: 'Curtain Wall [861098]',
                  children: [
                    {
                      id: 4460,
                      name: 'System Panel [861099]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 2.246691047341643,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1231.0635875844614,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 1825.000000000001,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4461,
                      name: 'Rectangular Mullion [861100]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1900.0000000000011,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4462,
                      name: 'Rectangular Mullion [861101]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1405.000000001361,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4463,
                      name: 'Rectangular Mullion [861102]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1222.0635875844875,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4464,
                      name: 'Rectangular Mullion [861103]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1231.0635875844462,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4466,
                      name: 'System Panel [861105]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.1756657261431704,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1231.0635875844614,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 955.0000000000081,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4467,
                      name: 'Rectangular Mullion [861106]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1004.9999999999967,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4468,
                      name: 'Rectangular Mullion [861107]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1900.0000000000011,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4471,
                      name: 'Rectangular Mullion [861110]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1004.9999999999967,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4472,
                      name: 'Rectangular Mullion [861111]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1094.9999999999936,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4474,
                      name: 'System Panel [861113]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 2.671407985058271,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1231.0635875844614,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2169.999999999992,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4475,
                      name: 'Rectangular Mullion [861114]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1094.9999999999936,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4476,
                      name: 'Rectangular Mullion [861115]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2219.9999999999804,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4479,
                      name: 'Rectangular Mullion [861118]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2219.9999999999804,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4480,
                      name: 'Rectangular Mullion [861119]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1405.000000001361,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4481,
                      name: 'Rectangular Mullion [861136]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1231.0635875844462,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4482,
                      name: 'Rectangular Mullion [861137]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1231.0635875844462,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4483,
                      name: 'Rectangular Mullion [861138]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1231.0635875844462,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4484,
                      name: 'Rectangular Mullion [861143]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1231.0635875844462,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4488,
                      name: 'System Panel [861618]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 2.230266047341718,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1222.0635875845023,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 1825.000000000001,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4489,
                      name: 'System Panel [861619]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.1670707261432096,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1222.0635875845023,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 955.0000000000081,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4491,
                      name: 'System Panel [861621]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 2.65187798505836,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1222.0635875845023,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2169.999999999992,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4495,
                      name: 'Rectangular Mullion [861644]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1206.0635875844273,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4496,
                      name: 'Rectangular Mullion [861645]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1222.0635875844875,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4497,
                      name: 'Rectangular Mullion [861646]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1222.0635875844875,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4498,
                      name: 'Rectangular Mullion [861647]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1222.0635875844875,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4499,
                      name: 'Rectangular Mullion [861648]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1222.0635875844875,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4500,
                      name: 'Rectangular Mullion [861649]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1222.0635875844875,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4502,
                      name: 'System Panel [861707]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 2.201066047341599,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1206.0635875844373,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 1825.000000000001,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4503,
                      name: 'System Panel [861708]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.1517907261431473,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1206.0635875844373,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 955.0000000000081,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4505,
                      name: 'System Panel [861710]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 2.6171579850582187,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1206.0635875844373,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2169.999999999992,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4509,
                      name: 'Rectangular Mullion [861730]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1206.0635875844273,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4510,
                      name: 'Rectangular Mullion [861731]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1206.0635875844273,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4511,
                      name: 'Rectangular Mullion [861732]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1206.0635875844273,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4512,
                      name: 'Rectangular Mullion [861733]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1206.0635875844273,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4513,
                      name: 'Rectangular Mullion [861734]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1206.0635875844273,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4514,
                      name: 'Rectangular Mullion [861735]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1231.0635875844748,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4515,
                      name: 'Rectangular Mullion [861767]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1405.000000001361,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4516,
                      name: 'Rectangular Mullion [861768]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2219.9999999999804,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4517,
                      name: 'Rectangular Mullion [861769]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1094.9999999999936,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4518,
                      name: 'Rectangular Mullion [861770]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1004.9999999999967,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4519,
                      name: 'Rectangular Mullion [861771]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1405.000000001361,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4520,
                      name: 'Rectangular Mullion [861772]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2219.9999999999804,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4521,
                      name: 'Rectangular Mullion [861773]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1094.9999999999936,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4522,
                      name: 'Rectangular Mullion [861774]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1900.0000000000011,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4523,
                      name: 'Rectangular Mullion [861775]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1004.9999999999967,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4524,
                      name: 'Rectangular Mullion [861776]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1900.0000000000011,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                  ],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Vertical Grid',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Horizontal Grid',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 4000.1907627533533,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 29.42632956599438,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.01.01.035_Ballistic Steel Curtain Wall WG 001 NBS H11/114',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Curtain Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 4580,
              name: '03.02.06.01.01.030_Glazed Curtain Wall NBS H11/113',
              children: [
                {
                  id: 4581,
                  name: 'Curtain Wall [882237]',
                  children: [
                    {
                      id: 4582,
                      name: 'System Panel [882238]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.1024999999999647,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1049.9999999999627,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 1050.0000000000039,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4583,
                      name: 'Rectangular Mullion [882239]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1049.9999999999231,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4584,
                      name: 'Rectangular Mullion [882240]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1049.9999999999231,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4588,
                      name: 'L Corner Mullion [882241]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1299.9999999999989,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4608,
                      name: 'System Panel [882323]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 1.2862499999999528,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1049.9999999999627,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 1224.9999999999986,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4609,
                      name: 'L Corner Mullion [882330]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1125.000000000004,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4617,
                      name: 'Rectangular Mullion [882564]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1049.9999999999711,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                  ],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Vertical Grid',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Horizontal Grid',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1179.9999999999711,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.8614999999999333,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4589,
                  name: 'Curtain Wall [882242]',
                  children: [
                    {
                      id: 4590,
                      name: 'System Panel [882243]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.4934999999999878,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 469.9999999999866,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 1050.0000000000039,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4591,
                      name: 'Rectangular Mullion [882244]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 469.99999999998306,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4592,
                      name: 'L Corner Mullion [882245]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1299.9999999999989,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4593,
                      name: 'Rectangular Mullion [882246]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 469.99999999998306,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4594,
                      name: 'L Corner Mullion [882247]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1125.000000000004,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4611,
                      name: 'System Panel [882452]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.575749999999983,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 469.9999999999866,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 1224.9999999999986,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4612,
                      name: 'L Corner Mullion [882463]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1125.000000000004,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4613,
                      name: 'L Corner Mullion [882464]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 1299.9999999999989,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4618,
                      name: 'Rectangular Mullion [882570]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 469.99999999996953,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                  ],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Vertical Grid',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Horizontal Grid',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 599.9999999999694,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.454999999999928,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4595,
                  name: 'Curtain Wall [882248]',
                  children: [
                    {
                      id: 4600,
                      name: 'Rectangular Mullion [882252]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 469.99999999998306,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4601,
                      name: 'Rectangular Mullion [882253]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 469.99999999998306,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                    {
                      id: 4602,
                      name: 'L Corner Mullion [882254]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 2425.000000000003,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                  ],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Vertical Grid',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Horizontal Grid',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 600.000000000017,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.4550000000000431,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4598,
                  name: 'Curtain Wall [882250]',
                  children: [
                    {
                      id: 4599,
                      name: 'System Panel [882251]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.4934999999999878,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 469.9999999999866,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 1050.0000000000039,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4615,
                      name: 'System Panel [882539]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.575749999999983,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 469.9999999999866,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 1224.9999999999986,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                    {
                      id: 4616,
                      name: 'Rectangular Mullion [882560]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Wall Mullions',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Length',
                          displayValue: 470.00000000003405,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 149.99999999999997,
                          displayCategory: 'Construction',
                        },
                      ],
                    },
                  ],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Vertical Grid',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Horizontal Grid',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 470.00000000003405,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.092750000000075,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4603,
                  name: 'Curtain Wall [882255]',
                  children: [
                    {
                      id: 4604,
                      name: 'System Panel [882256]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.004112135887645472,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1.695726139235244,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2425.000000000003,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                  ],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Vertical Grid',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Horizontal Grid',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 66.69572613925875,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.1617371358877027,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4605,
                  name: 'Curtain Wall [882257]',
                  children: [
                    {
                      id: 4606,
                      name: 'System Panel [882258]',
                      children: [],
                      properties: [
                        {
                          displayName: 'Category',
                          displayValue: 'Revit Curtain Panels',
                          displayCategory: '__category__',
                        },
                        {
                          displayName: 'Area',
                          displayValue: 0.004119553223902021,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Width',
                          displayValue: 1.6987848345987693,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Height',
                          displayValue: 2425.000000000003,
                          displayCategory: 'Dimensions',
                        },
                        {
                          displayName: 'Thickness',
                          displayValue: 25,
                          displayCategory: 'Dimensions',
                        },
                      ],
                    },
                  ],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Vertical Grid',
                    },
                    {
                      displayName: 'Number',
                      displayValue: '4',
                      displayCategory: 'Horizontal Grid',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 66.69878483462229,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.16174455322395928,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.01.01.030_Glazed Curtain Wall NBS H11/113',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Curtain Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Walls',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Curtain Wall',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Walls',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Walls',
          displayCategory: '__category__',
        },
      ],
    },
    {
      id: 3645,
      name: 'Doors',
      children: [
        {
          id: 3646,
          name: 'Single-Flush_instance param',
          children: [
            {
              id: 3648,
              name: '03.02.06.02.01.140_Steel Doors Type D NBS L20/422',
              children: [
                {
                  id: 3649,
                  name: 'Single-Flush_instance param [808305]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1022.4999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.02.01.140_Steel Doors Type D NBS L20/422',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Single-Flush_instance param',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Doors',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 50.8,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 3815,
              name: '03.02.06.02.01.010_Steel Doors Type B NBS L20/422',
              children: [
                {
                  id: 3816,
                  name: 'Single-Flush_instance param [836491]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1021.949293954671,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3964,
                  name: 'Single-Flush_instance param [845249]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1022.4999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3968,
                  name: 'Single-Flush_instance param [845253]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1022.4999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3969,
                  name: 'Single-Flush_instance param [845254]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1043.0088765916785,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4569,
                  name: 'Single-Flush_instance param [878540]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2200,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4570,
                  name: 'Single-Flush_instance param [878663]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2200,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4576,
                  name: 'Single-Flush_instance param [879257]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2200,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1009.9999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.02.01.010_Steel Doors Type B NBS L20/422',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Single-Flush_instance param',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Doors',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 50.8,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 3934,
              name: '03.02.06.02.01.110_Steel Doors Type G NBS L20/422',
              children: [
                {
                  id: 3935,
                  name: 'Single-Flush_instance param [845223]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2100,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1022.4999999999999,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.02.01.110_Steel Doors Type G NBS L20/422',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Single-Flush_instance param',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Doors',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 50.8,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Single-Flush_instance param',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Doors',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3807,
          name: 'Double-Flush_instance param',
          children: [
            {
              id: 3809,
              name: '03.02.06.02.01.070_Steel Doors Type M NBS L20/422',
              children: [
                {
                  id: 3810,
                  name: 'Double-Flush_instance param [835175]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2200,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1360.0473954486724,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.02.01.070_Steel Doors Type M NBS L20/422',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Double-Flush_instance param',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Doors',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 50.8,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 3812,
              name: '03.02.06.02.01.020_Steel Doors Type K NBS L20/422',
              children: [
                {
                  id: 3813,
                  name: 'Double-Flush_instance param [836115]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2200,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1809.9999999999925,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.02.01.020_Steel Doors Type K NBS L20/422',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Double-Flush_instance param',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Doors',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 50.8,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 3818,
              name: '03.02.06.02.01.080_Steel Doors Type L NBS L20/422',
              children: [
                {
                  id: 3819,
                  name: 'Double-Flush_instance param [836724]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2200,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1810.012592427188,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.02.01.080_Steel Doors Type L NBS L20/422',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Double-Flush_instance param',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Doors',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 50.8,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 4566,
              name: '03.02.06.02.01.120_Steel Doors Type E NBS L20/422',
              children: [
                {
                  id: 4567,
                  name: 'Double-Flush_instance param [878183]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2200,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1300,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 50.8,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.02.01.120_Steel Doors Type E NBS L20/422',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Double-Flush_instance param',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Doors',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 50.8,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Double-Flush_instance param',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Doors',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 3936,
          name: 'Revolving',
          children: [
            {
              id: 3938,
              name: '03.02.06.01.01.060_External Security Airlocks L20/497',
              children: [
                {
                  id: 3939,
                  name: 'Revolving [845224]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 0,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 3940,
                  name: 'Revolving [845225]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 2400,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 0,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 0,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.01.01.060_External Security Airlocks L20/497',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Revolving',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Doors',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Height',
                  displayValue: 2400,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 0,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Width',
                  displayValue: 0,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Revolving',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Doors',
              displayCategory: '__category__',
            },
          ],
        },
        {
          id: 4537,
          name: 'Overhead-Rolling_Instance param',
          children: [
            {
              id: 4539,
              name: '03.02.06.02.01.040_Metal clad vehicle gate NBS L20/690',
              children: [
                {
                  id: 4540,
                  name: 'Overhead-Rolling_Instance param [864910]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 5315,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 4060,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 239.99999999999997,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.02.01.040_Metal clad vehicle gate NBS L20/690',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Overhead-Rolling_Instance param',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Doors',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 239.99999999999997,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
            {
              id: 4545,
              name: '03.02.06.02.01.060_Vehicle Gate With Cladding NBS L20/691',
              children: [
                {
                  id: 4546,
                  name: 'Overhead-Rolling_Instance param [865239]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Doors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 5000,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 4060,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 239.99999999999997,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.02.01.060_Vehicle Gate With Cladding NBS L20/691',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Overhead-Rolling_Instance param',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Doors',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Thickness',
                  displayValue: 239.99999999999997,
                  displayCategory: 'Dimensions',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Overhead-Rolling_Instance param',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Doors',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Doors',
          displayCategory: '__category__',
        },
      ],
    },
    {
      id: 3707,
      name: 'Curtain Panels',
      children: [
        {
          id: 4118,
          name: 'Basic Wall',
          children: [
            {
              id: 4120,
              name: '03.02.06.01.02.000_Glass Louvres NBS H11/140',
              children: [
                {
                  id: 4121,
                  name: 'Basic Wall [850737]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1765.0000000000769,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.7561750000000749,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.26342624999998654,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4122,
                  name: 'Basic Wall [850738]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1769.9999999999757,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.7611499999999742,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2641724999999989,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4123,
                  name: 'Basic Wall [850739]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1769.9999999999955,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.7611499999999936,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2641724999999744,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4124,
                  name: 'Basic Wall [850740]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1840.0000000000625,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.8308000000000884,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2746199999999972,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4125,
                  name: 'Basic Wall [850741]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1820.0000000000139,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.8109000000000315,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2716350000000186,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4126,
                  name: 'Basic Wall [850742]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1839.9999999999932,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.8307999999999922,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.27462000000000186,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4267,
                  name: 'Basic Wall [856658]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1154.9999999999857,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.5361500000015529,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.23042250000020148,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4268,
                  name: 'Basic Wall [856659]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1154.9999999999375,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.5361500000004482,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.23042250000003586,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4269,
                  name: 'Basic Wall [856660]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1339.4418924817926,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.781457717002027,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2672186575502676,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4387,
                  name: 'Basic Wall [860384]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1729.9771669352642,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.8078261394473805,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2711739209171207,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4388,
                  name: 'Basic Wall [860385]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1729.9771669352642,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.300869632023974,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.3451304448036135,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4389,
                  name: 'Basic Wall [860386]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1729.977166935139,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.8078261394472297,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.27117392091711695,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4390,
                  name: 'Basic Wall [860387]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1729.977166935139,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.3008696320237823,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.34513044480360866,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4391,
                  name: 'Basic Wall [860388]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1740.000000000053,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.8183000000000644,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2727449999999842,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4392,
                  name: 'Basic Wall [860389]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1740.000000000053,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.3142000000001173,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.34712999999998523,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4453,
                  name: 'Basic Wall [860993]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1729.977166935293,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.8078261394473905,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2711739209171114,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4454,
                  name: 'Basic Wall [860994]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1729.977166935293,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.3008696320239865,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.3451304448036016,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4455,
                  name: 'Basic Wall [860995]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1729.977166935111,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.8078261394472093,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.27117392091708425,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4456,
                  name: 'Basic Wall [860996]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1729.977166935111,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.3008696320237556,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.345130444803567,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4457,
                  name: 'Basic Wall [860997]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1740.000000000053,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.8183000000000644,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2727449999999842,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4458,
                  name: 'Basic Wall [860998]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1740.000000000053,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.3142000000001173,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.34712999999998523,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4485,
                  name: 'Basic Wall [861158]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1231.0635875844614,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.286461449025768,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.19296921735385314,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4486,
                  name: 'Basic Wall [861159]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1231.0635875844614,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.6373145714873665,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.24559718572308956,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4493,
                  name: 'Basic Wall [861642]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1222.0635875845023,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.2770564490258112,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.19155846735385965,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4494,
                  name: 'Basic Wall [861643]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1222.0635875845023,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.6253445714874208,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.24380168572309782,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4507,
                  name: 'Basic Wall [861728]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1206.0635875844373,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.260336449025743,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.18905046735384368,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 4508,
                  name: 'Basic Wall [861729]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Curtain Panels',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 1206.0635875844373,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.6040645714873338,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.24060968572307745,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5285,
                  name: 'Basic Wall [928879]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6872.324096405707,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.65897467352521,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.5988462010288242,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5376,
                  name: 'Basic Wall [929149]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6872.324096405707,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.65897467352521,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.5988462010288242,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
                {
                  id: 5492,
                  name: 'Basic Wall [929274]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Walls',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Length',
                      displayValue: 6872.324096405707,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 10.65897467352521,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.5988462010288242,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 150,
                      displayCategory: 'Construction',
                    },
                    {
                      displayName: 'Fire Rating',
                      displayValue: '',
                      displayCategory: 'Identity Data',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.01.02.000_Glass Louvres NBS H11/140',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Basic Wall',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Curtain Panels',
                  displayCategory: '__category__',
                },
                {
                  displayName: 'Width',
                  displayValue: 150,
                  displayCategory: 'Construction',
                },
                {
                  displayName: 'Fire Rating',
                  displayValue: '',
                  displayCategory: 'Identity Data',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Basic Wall',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Curtain Panels',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Curtain Panels',
          displayCategory: '__category__',
        },
      ],
    },
    {
      id: 3791,
      name: 'Windows',
      children: [
        {
          id: 3792,
          name: 'Fixed Window_Instance param',
          children: [
            {
              id: 3794,
              name: '03.02.06.01.01.000_Steel Framed Window NBS L20/314',
              children: [
                {
                  id: 3795,
                  name: 'Fixed Window_Instance param [830655]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600.0000000000022,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 3947.999999999982,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3796,
                  name: 'Fixed Window_Instance param [831017]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600.0000000000022,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 3947.999999999982,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3941,
                  name: 'Fixed Window_Instance param [845226]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1409.9999999999734,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3942,
                  name: 'Fixed Window_Instance param [845227]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1410.0000000000216,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3943,
                  name: 'Fixed Window_Instance param [845228]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600.0000000000196,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1242.5000000000002,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3944,
                  name: 'Fixed Window_Instance param [845229]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600.0000000000196,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1242.5000000000002,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3965,
                  name: 'Fixed Window_Instance param [845250]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 525.0000000013533,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1022.4999999999608,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3966,
                  name: 'Fixed Window_Instance param [845251]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600.0000000000196,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1242.5000000000002,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3967,
                  name: 'Fixed Window_Instance param [845252]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 600.0000000000196,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1242.5000000000002,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3970,
                  name: 'Fixed Window_Instance param [845255]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 525.0000000013533,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1022.4999999999513,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.01.01.000_Steel Framed Window NBS L20/314',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Fixed Window_Instance param',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Windows',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3972,
              name: '03.02.06.01.01.020_Metal Framed Window NBS L20/312',
              children: [
                {
                  id: 3973,
                  name: 'Fixed Window_Instance param [845256]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1200.0000000000043,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2409.999996868593,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3977,
                  name: 'Fixed Window_Instance param [845258]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1200.0000000000043,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1870.0000000000002,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3982,
                  name: 'Fixed Window_Instance param [845281]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1200.0000000000043,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1870.0000000000002,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3984,
                  name: 'Fixed Window_Instance param [845283]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1200.0000000000043,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 3680,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3987,
                  name: 'Fixed Window_Instance param [845286]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1200.0000000000043,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2425.024603974764,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3989,
                  name: 'Fixed Window_Instance param [845288]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1200.0000000000043,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 2379.999996907485,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4000,
                  name: 'Fixed Window_Instance param [847600]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1200.0000000000043,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 3768.000000000002,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4001,
                  name: 'Fixed Window_Instance param [847601]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1200.0000000000043,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 3768.000000000031,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.01.01.020_Metal Framed Window NBS L20/312',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Fixed Window_Instance param',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Windows',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 3975,
              name: '03.02.06.01.01.010_Opaque Glazed Panels O1 NBS L40/357',
              children: [
                {
                  id: 3976,
                  name: 'Fixed Window_Instance param [845257]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1199.999999999987,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 3576.0076502120774,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3983,
                  name: 'Fixed Window_Instance param [845282]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1199.999999999987,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1760,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3985,
                  name: 'Fixed Window_Instance param [845284]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1199.999999999987,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 500,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3986,
                  name: 'Fixed Window_Instance param [845285]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1199.999999999987,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 14319.99235326252,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 3988,
                  name: 'Fixed Window_Instance param [845287]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1199.999999999987,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 649.9753995009252,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4002,
                  name: 'Fixed Window_Instance param [847602]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1199.999999999987,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 450.0000000000348,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4529,
                  name: 'Fixed Window_Instance param [862819]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1425,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1940.0000053273477,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4657,
                  name: 'Fixed Window_Instance param [887136]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1425,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 1749.9999999999472,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.01.01.010_Opaque Glazed Panels O1 NBS L40/357',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Fixed Window_Instance param',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Windows',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4526,
              name: '03.02.06.01.01.040_Ballistic Steel Framed Windows NBS L10/314',
              children: [
                {
                  id: 4527,
                  name: 'Fixed Window_Instance param [862054]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1425.0000000000052,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 5390.032629924479,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4528,
                  name: 'Fixed Window_Instance param [862483]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1425.0000000000052,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 3450.0326299245908,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4650,
                  name: 'Fixed Window_Instance param [886315]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1425.0000000000052,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 3584.98493537702,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4656,
                  name: 'Fixed Window_Instance param [886989]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Windows',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Height',
                      displayValue: 1425.0000000000052,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Width',
                      displayValue: 5406.0199168643385,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.01.01.040_Ballistic Steel Framed Windows NBS L10/314',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Fixed Window_Instance param',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Windows',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Fixed Window_Instance param',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Windows',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Windows',
          displayCategory: '__category__',
        },
      ],
    },
    {
      id: 4622,
      name: 'Floors',
      children: [
        {
          id: 4623,
          name: 'Floor',
          children: [
            {
              id: 4625,
              name: '03.02.05.01.01.080_Metal cladding H31A',
              children: [
                {
                  id: 4626,
                  name: 'Floor [883933]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 162.09603950228566,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 31.608727702946894,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 195,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.05.01.01.080_Metal cladding H31A',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4703,
              name: '03.02.01.01.03.010_Stone clad colonnade Horizontal NBS F22/110',
              children: [
                {
                  id: 4704,
                  name: 'Floor [888657]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.3010128440332323,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2070911559629837,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 90,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4718,
                  name: 'Floor [889785]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.6587601355695587,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1492884122012551,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 90,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4725,
                  name: 'Floor [889806]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.6587601355695587,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1492884122012551,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 90,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4734,
                  name: 'Floor [890041]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.6587601355695587,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.14928841220125508,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 90,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4743,
                  name: 'Floor [890083]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.338734275900584,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.21048608483104517,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 90,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4754,
                  name: 'Floor [890127]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 2.3177838161696123,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.20860054345525786,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 90,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4772,
                  name: 'Floor [892043]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7306370504438856,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.06575733453994742,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 90,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4780,
                  name: 'Floor [892077]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.7306370504437572,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.06575733453993585,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 90,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4787,
                  name: 'Floor [892138]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 0.5209437475953211,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.0468849372835773,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 90,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.01.01.03.010_Stone clad colonnade Horizontal NBS F22/110',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4828,
              name: '03.02.03.02.05.000_Aluminium soffit planks H72/480',
              children: [
                {
                  id: 4829,
                  name: 'Floor [892753]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 40.25186680486234,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.0062966701213305,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 25,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5096,
                  name: 'Floor [919898]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 114.13765704745143,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 2.8534414261856367,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 25,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.03.02.05.000_Aluminium soffit planks H72/480',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4846,
              name: '03.02.03.02.05.010_Aluminium Fascia pitched Spec NBS H72/480',
              children: [
                {
                  id: 4847,
                  name: 'Floor [893292]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 25.429499685190585,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.6357374921295655,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 25,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4855,
                  name: 'Floor [894000]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 7.341297883758522,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.18353244709393832,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 25,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4929,
                  name: 'Floor [898373]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 38.8176561010728,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9704414025265204,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 25,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4937,
                  name: 'Floor [898381]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 11.004588701813518,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.275114717545292,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 25,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4945,
                  name: 'Floor [898579]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 38.81906800189114,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.9704767000469784,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 25,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4953,
                  name: 'Floor [898587]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 11.005037446016567,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.2751259361503724,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 25,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4961,
                  name: 'Floor [898689]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 20.992783296069526,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.524819582401575,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 25,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4969,
                  name: 'Floor [898697]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.858705669760808,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.14646764174400187,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 25,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4977,
                  name: 'Floor [899290]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 20.996385314784035,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.5249096328694371,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 25,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4985,
                  name: 'Floor [899298]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.859745544841684,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.1464936386210213,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 25,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.03.02.05.010_Aluminium Fascia pitched Spec NBS H72/480',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4864,
              name: '03.02.03.02.01.000_Superstructure - Metal standing seam roofing pitched Spec NBS H31/125',
              children: [
                {
                  id: 4865,
                  name: 'Floor [895733]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 5.133234138091018,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.283308534522785,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4873,
                  name: 'Floor [896214]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.8123783233207604,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.4530945808301973,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4881,
                  name: 'Floor [896244]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 1.8123783233207604,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 0.45309458083019905,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4889,
                  name: 'Floor [896277]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 41.22137819074496,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 10.305344547686481,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4993,
                  name: 'Floor [899816]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 255.79871225093504,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 63.949678062733774,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5000,
                  name: 'Floor [900759]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 255.95857949218325,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 63.98964487304582,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5007,
                  name: 'Floor [900815]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 123.20724805531961,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 30.801812013830627,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5015,
                  name: 'Floor [900833]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 113.31484085921099,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 28.328710214802754,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5035,
                  name: 'Floor [905072]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 71.08083289035174,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 17.770208222587936,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5042,
                  name: 'Floor [905162]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 71.08219095170583,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 17.77054773792646,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5049,
                  name: 'Floor [905169]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 34.716904334505685,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 8.679226083626423,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5055,
                  name: 'Floor [905290]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 34.1252268990047,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 8.531306724751177,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5065,
                  name: 'Floor [905771]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 146.60637460513476,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 36.65159365128298,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5078,
                  name: 'Floor [905851]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 40.7624082009446,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 10.19060205023631,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5288,
                  name: 'Floor [929037]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 40.76240820094451,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 10.190602050236288,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 5404,
                  name: 'Floor [929186]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 40.76240820094451,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 10.190602050236288,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 250,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue:
                    '03.02.03.02.01.000_Superstructure - Metal standing seam roofing pitched Spec NBS H31/125',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
            {
              id: 4898,
              name: '03.02.06.01.02.010_Shading Louvres NBS L10/680',
              children: [
                {
                  id: 4899,
                  name: 'Floor [896309]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 16.307150546594954,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.6307150546595344,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4907,
                  name: 'Floor [896421]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 16.306925940142136,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.6306925940142525,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
                {
                  id: 4915,
                  name: 'Floor [896441]',
                  children: [],
                  properties: [
                    {
                      displayName: 'Category',
                      displayValue: 'Revit Floors',
                      displayCategory: '__category__',
                    },
                    {
                      displayName: 'Area',
                      displayValue: 16.306925940142136,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Volume',
                      displayValue: 1.6306925940142525,
                      displayCategory: 'Dimensions',
                    },
                    {
                      displayName: 'Thickness',
                      displayValue: 100,
                      displayCategory: 'Dimensions',
                    },
                  ],
                },
              ],
              properties: [
                {
                  displayName: 'Category',
                  displayValue: 'Revit Family Type',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFT',
                  displayValue: '03.02.06.01.02.010_Shading Louvres NBS L10/680',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RFN',
                  displayValue: 'Floor',
                  displayCategory: '__category__',
                },
                {
                  displayName: '_RC',
                  displayValue: 'Floors',
                  displayCategory: '__category__',
                },
              ],
            },
          ],
          properties: [
            {
              displayName: 'Category',
              displayValue: 'Revit Family Name',
              displayCategory: '__category__',
            },
            {
              displayName: '_RFN',
              displayValue: 'Floor',
              displayCategory: '__category__',
            },
            {
              displayName: '_RC',
              displayValue: 'Floors',
              displayCategory: '__category__',
            },
          ],
        },
      ],
      properties: [
        {
          displayName: 'Category',
          displayValue: 'Revit Category',
          displayCategory: '__category__',
        },
        {
          displayName: '_RC',
          displayValue: 'Floors',
          displayCategory: '__category__',
        },
      ],
    },
  ],
  properties: [
    {
      displayName: 'Category',
      displayValue: 'Revit Document',
      displayCategory: '__category__',
    },
  ],
}

export const ExteriorTakeOffData: TakeOffItem[] = parseTakeOffData(ModelExteriorData)

export const ExteriorTakeOffTableData: TakeOffTableItem[] = ExteriorTakeOffData.map((t) =>
  convertToTableItem(t, 'BHR-R5D-401-00-M3-X-0005-D1-05_Entrance Building - Exterior.rvt')
)
