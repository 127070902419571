/* eslint-disable */
import React from 'react'
import ReactMapboxGl, { Marker, Popup } from 'react-mapbox-gl'
import { recognizeFormatAndConvertToMapboxReadable } from '../helpers/CoordinatesConverter'
import { Tooltip } from 'antd'
import './PortfolioMaps.scss'
import classNames from 'classnames'
import { ProjectTypeToColor } from '../../../../model/Projects'

const accessToken = 'pk.eyJ1IjoiemR6YXJza2kiLCJhIjoiY2tjbjF5YzAwMDcydjJzbG1vNjhyMjg5aCJ9.skEgIrsSz_N_ojno0XiNjw'
const Map = ReactMapboxGl({ accessToken })
const mapboxStyle = 'mapbox://styles/mapbox/light-v9'

export interface PortfolioMapsProps {
  data: any[]
  selectedProjectKey?: number
}

function PortfolioMaps({ data, selectedProjectKey }: PortfolioMapsProps): JSX.Element {
  return (
    <Map
      style={mapboxStyle}
      zoom={[4]}
      containerStyle={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      <>
        {renderNotSelectedMarkers(data.filter((project) => project.key !== selectedProjectKey))}
        {renderSelectedMarkers(data.filter((project) => project.key === selectedProjectKey))}
      </>
    </Map>
  )
}

function renderNotSelectedMarkers(records: any[]) {
  return renderMarkers(records, false)
}

function renderSelectedMarkers(records: any[]) {
  return (
    <>
      {records.map((project) => (
        <Popup
          coordinates={recognizeFormatAndConvertToMapboxReadable(project.lng, project.lat)}
          className={'popup-selected-project'}
          key={`popup-${project.name}`}
          offset={10}
        >
          {project.name}
        </Popup>
      ))}
      {renderMarkers(records, true)}
    </>
  )
}
// eslint-enable

function renderMarkers(records: any[], selected: boolean) {
  return records.map((project) => (
    <>
      <Tooltip title={project.name} arrowPointAtCenter={true}>
        <Marker coordinates={recognizeFormatAndConvertToMapboxReadable(project.lng, project.lat)}>
          <Dot color={ProjectTypeToColor(project.type) as string} large={selected} />
        </Marker>
      </Tooltip>
    </>
  ))
}

function Dot({ color, large }: { color: string; large?: boolean }): JSX.Element {
  return <span className={classNames('dot', { 'dot-large': large })} style={{ backgroundColor: color }} />
}

export default PortfolioMaps
