export interface NRM2Item {
  id: number
  itemCode: string
  title: string
  notes?: string
  description?: string
}

export function emptyNRM2Item() {
  return { id: -1, itemCode: '', title: '', notes: '', description: '' }
}

export const nrm2Data: NRM2Item[] = [
  {
    id: 100,
    itemCode: '03',
    title: 'Demolition',
    notes: '',
    description: '',
  },
  {
    id: 101,
    itemCode: '03.01',
    title: 'Demolition',
    notes: '',
    description: '',
  },
  {
    id: 102,
    itemCode: '03.03',
    title: 'Temporary works',
    notes: '',
    description: '',
  },
  {
    id: 103,
    itemCode: '04',
    title: 'Alterations, Repairs and Conservation',
    notes: '',
    description: '',
  },
  {
    id: 104,
    itemCode: '04.02',
    title: 'Removing',
    notes: '',
    description: '',
  },
  {
    id: 105,
    itemCode: '04.03',
    title: 'Cutting or forming openings',
    notes: '',
    description: '',
  },
  {
    id: 106,
    itemCode: '04.06',
    title: 'Filling in openings',
    notes: '',
    description: '',
  },
  {
    id: 107,
    itemCode: '04.08',
    title: 'Removing existing and replacing',
    notes: '',
    description: '',
  },
  {
    id: 108,
    itemCode: '04.10',
    title: 'Repairing',
    notes: '',
    description: '',
  },
  {
    id: 109,
    itemCode: '04.12',
    title: 'Repointing',
    notes: '',
    description: '',
  },
  {
    id: 110,
    itemCode: '04.17',
    title: 'Damp-proof course insertion',
    notes: '',
    description: '',
  },
  {
    id: 111,
    itemCode: '04.24',
    title: 'Temporary works',
    notes: '',
    description: '',
  },
  {
    id: 112,
    itemCode: '05',
    title: 'Excavating and Filling',
    notes: '',
    description: '',
  },
  {
    id: 113,
    itemCode: '05.02',
    title: 'Removing trees',
    notes: '',
    description: '',
  },
  {
    id: 114,
    itemCode: '05.02.01',
    title: 'Grith 500mm to 1500 mm',
    notes: '',
    description: '',
  },
  {
    id: 115,
    itemCode: '05.02.02',
    title: 'Grith 1500mm to 3000 mm',
    notes: '',
    description: '',
  },
  {
    id: 116,
    itemCode: '05.02.03',
    title: 'Grith over 3000 mm',
    notes: '',
    description: '',
  },
  {
    id: 117,
    itemCode: '05.03',
    title: 'Removing tree stumps',
    notes: '',
    description: '',
  },
  {
    id: 118,
    itemCode: '05.03.01',
    title: 'Grith 500mm to 1500 mm',
    notes: '',
    description: '',
  },
  {
    id: 119,
    itemCode: '05.03.02',
    title: 'Grith 1500mm to 3000 mm',
    notes: '',
    description: '',
  },
  {
    id: 120,
    itemCode: '05.03.03',
    title: 'Grith over 3000 mm',
    notes: '',
    description: '',
  },
  {
    id: 121,
    itemCode: '05.04',
    title: 'Site clearance',
    notes: '',
    description: '',
  },
  {
    id: 122,
    itemCode: '05.04.01',
    title: 'Clear site of all vegetation and other growth and dispose off site',
    notes: '',
    description: '',
  },
  {
    id: 123,
    itemCode: '05.05',
    title: 'Site preparation',
    notes: '',
    description: '',
  },
  {
    id: 124,
    itemCode: '05.05.03',
    title: 'Remove hard surface paving: thickness stated',
    notes: '',
    description: '',
  },
  {
    id: 125,
    itemCode: '05.06',
    title: 'Excavation',
    notes: '',
    description: '',
  },
  {
    id: 126,
    itemCode: '05.06.01',
    title: 'Bulk excavation',
    notes: '',
    description: '',
  },
  {
    id: 127,
    itemCode: '05.06.02',
    title: 'Foundation excavation',
    notes: '',
    description: '',
  },
  {
    id: 128,
    itemCode: '05.07',
    title: 'Extra over all types of excavation irrespective of depth',
    notes: '',
    description: '',
  },
  {
    id: 129,
    itemCode: '05.07.02',
    title: 'Breaking up',
    notes: '',
    description: '',
  },
  {
    id: 130,
    itemCode: '05.08',
    title: 'Support to face(s) of excavation where not at the discretion of the contractor',
    notes: '',
    description: '',
  },
  {
    id: 131,
    itemCode: '05.08.01',
    title: 'Maximum depth stated',
    notes: '',
    description: '',
  },
  {
    id: 132,
    itemCode: '05.09',
    title: 'Disposal',
    notes: '',
    description: '',
  },
  {
    id: 133,
    itemCode: '05.09.02',
    title: 'Excavated material off site',
    notes: '',
    description: '',
  },
  {
    id: 134,
    itemCode: '05.10',
    title: 'Retaining excavated material on site',
    notes: '',
    description: '',
  },
  {
    id: 135,
    itemCode: '05.10.02',
    title: 'All other excavated material',
    notes: '',
    description: '',
  },
  {
    id: 136,
    itemCode: '05.11',
    title: 'Filling obtained  from excavated material',
    notes: '',
    description: '',
  },
  {
    id: 137,
    itemCode: '05.11.02',
    title: 'Final thickness of filling exceeding 500mm deep',
    notes: '',
    description: '',
  },
  {
    id: 138,
    itemCode: '05.12',
    title: 'Imported filling',
    notes: '',
    description: '',
  },
  {
    id: 139,
    itemCode: '05.12.01',
    title: 'Blinding bed not exceeding 50mm thick, finished thickness stated',
    notes: '',
    description: '',
  },
  {
    id: 140,
    itemCode: '05.12.03',
    title: 'Beds exceeding 500mm  deep',
    notes: '',
    description: '',
  },
  {
    id: 141,
    itemCode: '07',
    title: 'Piling',
    notes: '',
    description: '',
  },
  {
    id: 142,
    itemCode: '07.02',
    title: 'Bored piles',
    notes: '',
    description: '',
  },
  {
    id: 143,
    itemCode: '07.02.01',
    title: 'Cast in place concrete piling; driven shell piles; rotary bored',
    notes: '',
    description: '',
  },
  {
    id: 144,
    itemCode: '07.04',
    title: 'Preformed Concrete Piles',
    notes: '',
    description: '',
  },
  {
    id: 145,
    itemCode: '07.04.01',
    title: 'Reinforced concrete piles',
    notes: '',
    description: '',
  },
  {
    id: 146,
    itemCode: '07.12',
    title: 'Tests',
    notes: '',
    description: '',
  },
  {
    id: 147,
    itemCode: '07.12.01',
    title: 'Pile tests',
    notes: '',
    description: '',
  },
  {
    id: 148,
    itemCode: '08',
    title: 'Underpinning',
    notes: '',
    description: '',
  },
  {
    id: 149,
    itemCode: '08.01',
    title: 'Insitu Underpinning',
    notes: '',
    description: '',
  },
  {
    id: 150,
    itemCode: '08.02',
    title: 'Concrete',
    notes: '',
    description: '',
  },
  {
    id: 151,
    itemCode: '08.03',
    title: 'Formwork',
    notes: '',
    description: '',
  },
  {
    id: 152,
    itemCode: '08.04',
    title: 'Reinforcement',
    notes: '',
    description: '',
  },
  {
    id: 153,
    itemCode: '08.05',
    title: 'Brickwork or blockwork',
    notes: '',
    description: '',
  },
  {
    id: 154,
    itemCode: '11',
    title: 'In-situ concrete works',
    notes: '',
    description: '',
  },
  {
    id: 155,
    itemCode: '11.01',
    title: 'Mass Concrete',
    notes: '',
    description: '',
  },
  {
    id: 156,
    itemCode: '11.01.01',
    title: 'Any thickness',
    notes: '',
    description: '',
  },
  {
    id: 157,
    itemCode: '11.02',
    title: 'Horizontal work',
    notes: '',
    description: '',
  },
  {
    id: 158,
    itemCode: '11.02.01',
    title: '≤ 300mm thick',
    notes: '',
    description: '',
  },
  {
    id: 159,
    itemCode: '11.02.02',
    title: '>300mm thick',
    notes: '',
    description: '',
  },
  {
    id: 160,
    itemCode: '11.03',
    title: 'Sloping work ≤ 15˚',
    notes: '',
    description: '',
  },
  {
    id: 161,
    itemCode: '11.03.01',
    title: '≤ 300mm thick',
    notes: '',
    description: '',
  },
  {
    id: 162,
    itemCode: '11.05',
    title: 'Vertical works',
    notes: '',
    description: '',
  },
  {
    id: 163,
    itemCode: '11.05.01',
    title: '≤ 300mm thick',
    notes: '',
    description: '',
  },
  {
    id: 164,
    itemCode: '11.05.02',
    title: '>300mm thick',
    notes: '',
    description: '',
  },
  {
    id: 165,
    itemCode: '11.08',
    title: 'Surface finishes to in-situ concrete: trowelling',
    notes: '',
    description: '',
  },
  {
    id: 166,
    itemCode: '11.08.01',
    title: 'To top surfaces',
    notes: '',
    description: '',
  },
  {
    id: 167,
    itemCode: '11.09',
    title: 'Surface finishes to in-situ concrete: power floating',
    notes: '',
    description: '',
  },
  {
    id: 168,
    itemCode: '11.09.01',
    title: 'To top surfaces',
    notes: '',
    description: '',
  },
  {
    id: 169,
    itemCode: '11.12',
    title: 'Surface finishes to in-situ concrete: any other surface treatment not left to discretion of the contractor',
    notes: '',
    description: '',
  },
  {
    id: 170,
    itemCode: '11.12.01',
    title: 'To top surfaces',
    notes: '',
    description: '',
  },
  {
    id: 171,
    itemCode: '11.13',
    title: 'Formwork: sides of foundations and bases',
    notes: '',
    description: '',
  },
  {
    id: 172,
    itemCode: '11.13.01',
    title: '≤ 500 high: width stated',
    notes: '',
    description: '',
  },
  {
    id: 173,
    itemCode: '11.13.02',
    title: '> 500 high',
    notes: '',
    description: '',
  },
  {
    id: 174,
    itemCode: '11.14',
    title: 'Formwork: edges of horizontal work',
    notes: '',
    description: '',
  },
  {
    id: 175,
    itemCode: '11.14.01',
    title: '≤ 500 high: width stated',
    notes: '',
    description: '',
  },
  {
    id: 176,
    itemCode: '11.14.02',
    title: '> 500 high',
    notes: '',
    description: '',
  },
  {
    id: 177,
    itemCode: '11.15',
    title: 'Formwork: soffits of horizontal work',
    notes: '',
    description: '',
  },
  {
    id: 178,
    itemCode: '11.15.01',
    title: 'for concrete ≤ 300 thick',
    notes: '',
    description: '',
  },
  {
    id: 179,
    itemCode: '11.15.02',
    title: 'for concrete > 450 thick',
    notes: '',
    description: '',
  },
  {
    id: 180,
    itemCode: '11.17',
    title: 'Sides and soffits of isolated beams',
    notes: '',
    description: '',
  },
  {
    id: 181,
    itemCode: '11.17.01',
    title: 'Regular: shape stated',
    notes: '',
    description: '',
  },
  {
    id: 182,
    itemCode: '11.18',
    title: 'Formwork: sides and soffits of attached beams',
    notes: '',
    description: '',
  },
  {
    id: 183,
    itemCode: '11.18.01',
    title: 'Regular: shape stated',
    notes: '',
    description: '',
  },
  {
    id: 184,
    itemCode: '11.19',
    title: 'Formwork: sides of upstand beams',
    notes: '',
    description: '',
  },
  {
    id: 185,
    itemCode: '11.19.01',
    title: 'Regular: shape stated',
    notes: '',
    description: '',
  },
  {
    id: 186,
    itemCode: '11.20',
    title: 'Formwork: sides of isolated columns, nr stated',
    notes: '',
    description: '',
  },
  {
    id: 187,
    itemCode: '11.20.01',
    title: 'Regular: shape stated',
    notes: '',
    description: '',
  },
  {
    id: 188,
    itemCode: '11.22',
    title: 'Formwork: faces of walls and other vertical work',
    notes: '',
    description: '',
  },
  {
    id: 189,
    itemCode: '11.22.01',
    title: 'Vertical',
    notes: '',
    description: '',
  },
  {
    id: 190,
    itemCode: '11.22.03',
    title: 'Battered both faces',
    notes: '',
    description: '',
  },
  {
    id: 191,
    itemCode: '11.23',
    title: 'Formwork: extra over',
    notes: '',
    description: '',
  },
  {
    id: 192,
    itemCode: '11.23.01',
    title: 'Openings for doors or the like, thickness of wall stated',
    notes: '',
    description: '',
  },
  {
    id: 193,
    itemCode: '11.24',
    title: 'Formwork: Wall ends, soffits and steps in walls',
    notes: '',
    description: '',
  },
  {
    id: 194,
    itemCode: '11.24.01',
    title: '≤ 500 wide, width stated',
    notes: '',
    description: '',
  },
  {
    id: 195,
    itemCode: '11.24.02',
    title: '> 500 wide',
    notes: '',
    description: '',
  },
  {
    id: 196,
    itemCode: '11.25',
    title: 'Formwork: Soffits of sloping work',
    notes: '',
    description: '',
  },
  {
    id: 197,
    itemCode: '11.25.01',
    title: 'Sloping one way',
    notes: '',
    description: '',
  },
  {
    id: 198,
    itemCode: '11.26',
    title: 'Staircase strings and the like',
    notes: '',
    description: '',
  },
  {
    id: 199,
    itemCode: '11.26.01',
    title: 'Maximum width stated',
    notes: '',
    description: '',
  },
  {
    id: 200,
    itemCode: '11.27',
    title: 'Staircase risers and the like',
    notes: '',
    description: '',
  },
  {
    id: 201,
    itemCode: '11.27.01',
    title: 'Maximum width stated',
    notes: '',
    description: '',
  },
  {
    id: 202,
    itemCode: '11.28',
    title: 'Formwork: sloping top surfaces',
    notes: '',
    description: '',
  },
  {
    id: 203,
    itemCode: '11.28.01',
    title: '≤15º',
    notes: '',
    description: '',
  },
  {
    id: 204,
    itemCode: '11.29',
    title: 'Formwork: steps in top surfaces',
    notes: '',
    description: '',
  },
  {
    id: 205,
    itemCode: '11.29.01',
    title: '≤ 500 high: width stated',
    notes: '',
    description: '',
  },
  {
    id: 206,
    itemCode: '11.29.02',
    title: '> 500 high',
    notes: '',
    description: '',
  },
  {
    id: 207,
    itemCode: '11.30',
    title: 'Formwork: steps in soffits',
    notes: '',
    description: '',
  },
  {
    id: 208,
    itemCode: '11.30.01',
    title: '≤ 500 high: width stated',
    notes: '',
    description: '',
  },
  {
    id: 209,
    itemCode: '11.30.02',
    title: '> 500 high',
    notes: '',
    description: '',
  },
  {
    id: 210,
    itemCode: '11.31',
    title: 'Formwork: Complex shapes',
    notes: '',
    description: '',
  },
  {
    id: 211,
    itemCode: '11.31.01',
    title: 'Dimensioned description or diagram',
    notes: '',
    description: '',
  },
  {
    id: 212,
    itemCode: '11.32',
    title: 'Permanent formwork',
    notes: '',
    description: '',
  },
  {
    id: 213,
    itemCode: '11.32.01',
    title: 'Super Holorib galvanised steel permanent formwork',
    notes: '',
    description: '',
  },
  {
    id: 214,
    itemCode: '11.33',
    title: 'Reinforcement: mild steel bars',
    notes: '',
    description: '',
  },
  {
    id: 215,
    itemCode: '11.33.01',
    title: 'Nominal size stated',
    notes: '',
    description: '',
  },
  {
    id: 216,
    itemCode: '11.34',
    title: 'Reinforcement: High yield steel bars',
    notes: '',
    description: '',
  },
  {
    id: 217,
    itemCode: '11.34.01',
    title: 'Nominal size stated',
    notes: '',
    description: '',
  },
  {
    id: 218,
    itemCode: '11.37',
    title: 'Reinforcement: Mesh',
    notes: '',
    description: '',
  },
  {
    id: 219,
    itemCode: '11.37.01',
    title: 'Weight per m2 stated',
    notes: '',
    description: '',
  },
  {
    id: 220,
    itemCode: '11.38',
    title: 'Designed joints in in-situ concrete: Plain',
    notes: '',
    description: '',
  },
  {
    id: 221,
    itemCode: '11.38.01',
    title: 'Dimensioned description',
    notes: '',
    description: '',
  },
  {
    id: 222,
    itemCode: '11.39',
    title: 'Designed joints in in-situ concrete: Formed',
    notes: '',
    description: '',
  },
  {
    id: 223,
    itemCode: '11.39.01',
    title: 'Dimensioned description',
    notes: '',
    description: '',
  },
  {
    id: 224,
    itemCode: '11.41',
    title: 'Accessories cast into in-situ concrete: type or proprietary reference stated',
    notes: '',
    description: '',
  },
  {
    id: 225,
    itemCode: '11.41.01',
    title: 'Dimensioned description',
    notes: '',
    description: '',
  },
  {
    id: 226,
    itemCode: '12',
    title: 'Precast/composite concrete',
    notes: '',
    description: '',
  },
  {
    id: 227,
    itemCode: '12.01',
    title: 'Composite concrete work',
    notes: '',
    description: '',
  },
  {
    id: 228,
    itemCode: '12.01.01',
    title: 'Nature of work described',
    notes: '',
    description: '',
  },
  {
    id: 229,
    itemCode: '13',
    title: 'Precast concrete',
    notes: '',
    description: '',
  },
  {
    id: 230,
    itemCode: '13.01',
    title: 'Precast concrete goods',
    notes: '',
    description: '',
  },
  {
    id: 231,
    itemCode: '13.01.01',
    title: 'Dimensioned description or dimensioned diagram',
    notes: '',
    description: '',
  },
  {
    id: 232,
    itemCode: '14',
    title: 'Masonry',
    notes: '',
    description: '',
  },
  {
    id: 233,
    itemCode: '14.01',
    title: 'Walls; overall thickness stated',
    notes: '',
    description: '',
  },
  {
    id: 234,
    itemCode: '14.01.01',
    title: 'Brickwork',
    notes: '',
    description: '',
  },
  {
    id: 235,
    itemCode: '14.01.02',
    title: 'Blockwork',
    notes: '',
    description: '',
  },
  {
    id: 236,
    itemCode: '14.04',
    title: 'Isolated piers; isolated casings; chimney stacks; columns',
    notes: '',
    description: '',
  },
  {
    id: 237,
    itemCode: '14.04.01',
    title: 'Dimensioned descrption or dimensioned diagram',
    notes: '',
    description: '',
  },
  {
    id: 238,
    itemCode: '14.06',
    title: 'Arches (number stated)',
    notes: '',
    description: '',
  },
  {
    id: 239,
    itemCode: '14.06.01',
    title: 'Height of face, width of soffit and shape of arch stated',
    notes: '',
    description: '',
  },
  {
    id: 240,
    itemCode: '14.07',
    title: 'Bands; dimensioned description',
    notes: '',
    description: '',
  },
  {
    id: 241,
    itemCode: '14.07.02',
    title: 'Sunk: Depth of set back stated',
    notes: '',
    description: '',
  },
  {
    id: 242,
    itemCode: '14.07.03',
    title: 'Projecting: Depth of set forward stated',
    notes: '',
    description: '',
  },
  {
    id: 243,
    itemCode: '14.09',
    title: 'Flue linings',
    notes: '',
    description: '',
  },
  {
    id: 244,
    itemCode: '14.09.01',
    title: 'Dimensioned description',
    notes: '',
    description: '',
  },
  {
    id: 245,
    itemCode: '14.11',
    title: 'Extra over walls for perimeters and abutments, details stated.',
    notes: '',
    description: '',
  },
  {
    id: 246,
    itemCode: '14.11.01',
    title: 'Dimensioned description',
    notes: '',
    description: '',
  },
  {
    id: 247,
    itemCode: '14.12',
    title: 'Extra over walls for opening perimeters, details stated.',
    notes: '',
    description: '',
  },
  {
    id: 248,
    itemCode: '14.12.01',
    title: 'Dimensioned description',
    notes: '',
    description: '',
  },
  {
    id: 249,
    itemCode: '14.14',
    title: 'Forming cavity',
    notes: '',
    description: '',
  },
  {
    id: 250,
    itemCode: '14.14.01',
    title: 'Width and method of forming',
    notes: '',
    description: '',
  },
  {
    id: 251,
    itemCode: '14.15',
    title: 'Cavity insulation',
    notes: '',
    description: '',
  },
  {
    id: 252,
    itemCode: '14.15.01',
    title: 'Type and thickness',
    notes: '',
    description: '',
  },
  {
    id: 253,
    itemCode: '14.16',
    title: 'Damp proof courses ≤ 300mm wide',
    notes: '',
    description: '',
  },
  {
    id: 254,
    itemCode: '14.16.01',
    title: 'Gauge or thickness',
    notes: '',
    description: '',
  },
  {
    id: 255,
    itemCode: '14.17',
    title: 'Damp proof courses > 300mm wide',
    notes: '',
    description: '',
  },
  {
    id: 256,
    itemCode: '14.17.01',
    title: 'Gauge or thickness',
    notes: '',
    description: '',
  },
  {
    id: 257,
    itemCode: '14.19',
    title: 'Joint reinforcement',
    notes: '',
    description: '',
  },
  {
    id: 258,
    itemCode: '14.19.01',
    title: 'Width stated',
    notes: '',
    description: '',
  },
  {
    id: 259,
    itemCode: '14.21',
    title: 'Pointing',
    notes: '',
    description: '',
  },
  {
    id: 260,
    itemCode: '14.21.01',
    title: 'Width and depth of joint to be pointed',
    notes: '',
    description: '',
  },
  {
    id: 261,
    itemCode: '14.22',
    title: 'Joints',
    notes: '',
    description: '',
  },
  {
    id: 262,
    itemCode: '14.22.01',
    title: 'Width and thickness stated',
    notes: '',
    description: '',
  },
  {
    id: 263,
    itemCode: '14.24',
    title: 'Creasing',
    notes: '',
    description: '',
  },
  {
    id: 264,
    itemCode: '14.24.01',
    title: 'Width stated',
    notes: '',
    description: '',
  },
  {
    id: 265,
    itemCode: '14.25',
    title: 'Proprietary and individual spot items',
    notes: '',
    description: '',
  },
  {
    id: 266,
    itemCode: '14.25.01',
    title: 'Dimensioned description or dimensioned diagram',
    notes: '',
    description: '',
  },
  {
    id: 267,
    itemCode: '15',
    title: 'Structural metalwork',
    notes: '',
    description: '',
  },
  {
    id: 268,
    itemCode: '15.01',
    title: 'Framed members, framing and fabrication',
    notes: '',
    description: '',
  },
  {
    id: 269,
    itemCode: '15.01.02',
    title: 'Lengths over 1m but not exceeding 9m',
    notes: '',
    description: '',
  },
  {
    id: 270,
    itemCode: '15.02',
    title: 'Framed members, permanent erection on site',
    notes: '',
    description: '',
  },
  {
    id: 271,
    itemCode: '15.02.02',
    title: 'Lengths over 1m but not exceeding 9m',
    notes: '',
    description: '',
  },
  {
    id: 272,
    itemCode: '15.1',
    title: 'Holding down bolts or assemblies',
    notes: '',
    description: '',
  },
  {
    id: 273,
    itemCode: '15.10.01',
    title: 'Type and diameter stated',
    notes: '',
    description: '',
  },
  {
    id: 274,
    itemCode: '15.13',
    title: 'Trial erection',
    notes: '',
    description: '',
  },
  {
    id: 275,
    itemCode: '15.13.01',
    title: 'Details and location stated',
    notes: '',
    description: '',
  },
  {
    id: 276,
    itemCode: '15.15',
    title: 'Surface treatments',
    notes: '',
    description: '',
  },
  {
    id: 277,
    itemCode: '15.15.01',
    title: 'Galvanising',
    notes: '',
    description: '',
  },
  {
    id: 278,
    itemCode: '15.15.03',
    title: 'Painting',
    notes: '',
    description: '',
  },
  {
    id: 279,
    itemCode: '16',
    title: 'Carpentry',
    notes: '',
    description: '',
  },
  {
    id: 280,
    itemCode: '16.01',
    title: 'Primary or structural timbers',
    notes: '',
    description: '',
  },
  {
    id: 281,
    itemCode: '16.02',
    title: 'Engineered or prefabricated members/items',
    notes: '',
    description: '',
  },
  {
    id: 282,
    itemCode: '16.03',
    title: 'Backing and other first fix timbers',
    notes: '',
    description: '',
  },
  {
    id: 283,
    itemCode: '16.04',
    title:
      'Boarding, flooring, sheeting, decking, casings, linings, sarkings, fascias, bargeboards, soffits and the like',
    notes: '',
    description: '',
  },
  {
    id: 284,
    itemCode: '16.06',
    title: 'Metal fixings, fastenings and fittings',
    notes: '',
    description: '',
  },
  {
    id: 285,
    itemCode: '17',
    title: 'Sheet roof coverings',
    notes: '',
    description: '',
  },
  {
    id: 286,
    itemCode: '17.01',
    title: 'Coverings > 500mm wide',
    notes: '',
    description: '',
  },
  {
    id: 287,
    itemCode: '17.01.01',
    title: 'Horizontal',
    notes: '',
    description: '',
  },
  {
    id: 288,
    itemCode: '17.01.02',
    title: 'Sloping; pitch stated.',
    notes: '',
    description: '',
  },
  {
    id: 289,
    itemCode: '17.01.03',
    title: 'Vertical',
    notes: '',
    description: '',
  },
  {
    id: 290,
    itemCode: '17.02',
    title: 'Coverings ≤ 500mm wide',
    notes: '',
    description: '',
  },
  {
    id: 291,
    itemCode: '17.02.01',
    title: 'Horizontal',
    notes: '',
    description: '',
  },
  {
    id: 292,
    itemCode: '17.04',
    title: 'Boundary work, location and method of forming described',
    notes: '',
    description: '',
  },
  {
    id: 293,
    itemCode: '17.04.01',
    title: 'Net girth stated',
    notes: '',
    description: '',
  },
  {
    id: 294,
    itemCode: '17.05',
    title: 'Flashings',
    notes: '',
    description: '',
  },
  {
    id: 295,
    itemCode: '17.05.01',
    title: 'Net girth stated',
    notes: '',
    description: '',
  },
  {
    id: 296,
    itemCode: '17.09',
    title: 'Fittings',
    notes: '',
    description: '',
  },
  {
    id: 297,
    itemCode: '17.09.01',
    title: 'Dimensioned description',
    notes: '',
    description: '',
  },
  {
    id: 298,
    itemCode: '18',
    title: 'Tile and slate roof and walls coverings',
    notes: '',
    description: '',
  },
  {
    id: 299,
    itemCode: '18.01',
    title: 'Roof coverings',
    notes: '',
    description: '',
  },
  {
    id: 300,
    itemCode: '18.01.01',
    title: 'Pitch stated',
    notes: '',
    description: '',
  },
  {
    id: 301,
    itemCode: '18.02',
    title: 'Wall coverings',
    notes: '',
    description: '',
  },
  {
    id: 302,
    itemCode: '18.02.01',
    title: 'Vertical',
    notes: '',
    description: '',
  },
  {
    id: 303,
    itemCode: '18.03',
    title: 'Boundary work: location and method of forming described',
    notes: '',
    description: '',
  },
  {
    id: 304,
    itemCode: '18.03.01',
    title: 'Dimensioned description stating net girth',
    notes: '',
    description: '',
  },
  {
    id: 305,
    itemCode: '19',
    title: 'Waterproofing',
    notes: '',
    description: '',
  },
  {
    id: 306,
    itemCode: '19.01',
    title: 'Covering > 500mm wide',
    notes: '',
    description: '',
  },
  {
    id: 307,
    itemCode: '19.01.01',
    title: 'Horizontal',
    notes: '',
    description: '',
  },
  {
    id: 308,
    itemCode: '19.01.02',
    title: 'Sloping; pitch stated.',
    notes: '',
    description: '',
  },
  {
    id: 309,
    itemCode: '19.01.03',
    title: 'Vertical',
    notes: '',
    description: '',
  },
  {
    id: 310,
    itemCode: '19.02',
    title: 'Coverings ≤ 500mm wide',
    notes: '',
    description: '',
  },
  {
    id: 311,
    itemCode: '19.02.01',
    title: 'Horizontal',
    notes: '',
    description: '',
  },
  {
    id: 312,
    itemCode: '19.02.03',
    title: 'Vertical',
    notes: '',
    description: '',
  },
  {
    id: 313,
    itemCode: '19.03',
    title: 'Skirtings',
    notes: '',
    description: '',
  },
  {
    id: 314,
    itemCode: '19.03.01',
    title: 'Net girth on face',
    notes: '',
    description: '',
  },
  {
    id: 315,
    itemCode: '19.1',
    title: 'Spot items',
    notes: '',
    description: '',
  },
  {
    id: 316,
    itemCode: '19.10.01',
    title: 'Dimensioned diagram or dimensioned description',
    notes: '',
    description: '',
  },
  {
    id: 317,
    itemCode: '20',
    title: 'Proprietary linings and partitions',
    notes: '',
    description: '',
  },
  {
    id: 318,
    itemCode: '20.01',
    title: 'Proprietary metal framed system to form walls',
    notes: '',
    description: '',
  },
  {
    id: 319,
    itemCode: '20.01.01',
    title: 'Finished thickness stated (Rate per m, SMM7 measurement rule)',
    notes: '',
    description: '',
  },
  {
    id: 320,
    itemCode: '20.1',
    title: 'Proprietary linings to walls',
    notes: '',
    description: '',
  },
  {
    id: 321,
    itemCode: '20.10.01',
    title: 'Over 300mm wide on face',
    notes: '',
    description: '',
  },
  {
    id: 322,
    itemCode: '20.11',
    title: 'Proprietary linings to ceilings',
    notes: '',
    description: '',
  },
  {
    id: 323,
    itemCode: '20.11.01',
    title: 'Over 300mm wide on face',
    notes: '',
    description: '',
  },
  {
    id: 324,
    itemCode: '20.12',
    title: 'Proprietary linings to columns',
    notes: '',
    description: '',
  },
  {
    id: 325,
    itemCode: '20.12.02',
    title: 'Girth 300-600mm, nr of faces stated',
    notes: '',
    description: '',
  },
  {
    id: 326,
    itemCode: '20.12.04',
    title: 'Thereafter in 300mm stages, nr of faces stated',
    notes: '',
    description: '',
  },
  {
    id: 327,
    itemCode: '20.13',
    title: 'Proprietary linings to beams',
    notes: '',
    description: '',
  },
  {
    id: 328,
    itemCode: '20.13.02',
    title: 'Girth 300-600mm, nr of faces stated',
    notes: '',
    description: '',
  },
  {
    id: 329,
    itemCode: '20.13.04',
    title: 'Thereafter in 300mm stages, nr of faces stated',
    notes: '',
    description: '',
  },
  {
    id: 330,
    itemCode: '20.2',
    title: 'Beads, function stated',
    notes: '',
    description: '',
  },
  {
    id: 331,
    itemCode: '20.20.01',
    title: 'Dimensioned description or proprietary reference',
    notes: '',
    description: '',
  },
  {
    id: 332,
    itemCode: '21',
    title: 'Cladding and covering',
    notes: '',
    description: '',
  },
  {
    id: 333,
    itemCode: '21.01',
    title: 'Walls',
    notes: '',
    description: '',
  },
  {
    id: 334,
    itemCode: '21.01.01',
    title: 'Not exceeding 600 wide',
    notes: '',
    description: '',
  },
  {
    id: 335,
    itemCode: '21.01.02',
    title: 'Exceeding 600 wide',
    notes: '',
    description: '',
  },
  {
    id: 336,
    itemCode: '21.01.03',
    title: 'Isolated area not exceeding 1m2, irrespective of width',
    notes: '',
    description: '',
  },
  {
    id: 337,
    itemCode: '21.02',
    title: 'Floors',
    notes: '',
    description: '',
  },
  {
    id: 338,
    itemCode: '21.02.01',
    title: 'Not exceeding 600 wide',
    notes: '',
    description: '',
  },
  {
    id: 339,
    itemCode: '21.03',
    title: 'Ceilings',
    notes: '',
    description: '',
  },
  {
    id: 340,
    itemCode: '21.03.01',
    title: 'Not exceeding 600 wide',
    notes: '',
    description: '',
  },
  {
    id: 341,
    itemCode: '21.03.03',
    title: 'Isolated area not exceeding 1m2, irrespective of width',
    notes: '',
    description: '',
  },
  {
    id: 342,
    itemCode: '21.04',
    title: 'Roofs',
    notes: '',
    description: '',
  },
  {
    id: 343,
    itemCode: '21.04.02',
    title: 'Exceeding 600 wide',
    notes: '',
    description: '',
  },
  {
    id: 344,
    itemCode: '21.06',
    title: 'Sides and soffits of beams',
    notes: '',
    description: '',
  },
  {
    id: 345,
    itemCode: '21.06.01',
    title: 'Not exceeding 600 wide (Rate per m2, SMM7 measurement rule)',
    notes: '',
    description: '',
  },
  {
    id: 346,
    itemCode: '21.06.02',
    title: 'Exceeding 600 wide',
    notes: '',
    description: '',
  },
  {
    id: 347,
    itemCode: '21.07',
    title: 'Sides of columns',
    notes: '',
    description: '',
  },
  {
    id: 348,
    itemCode: '21.07.01',
    title: 'Not exceeding 600 wide (Rate per m2, SMM7 measurement rule)',
    notes: '',
    description: '',
  },
  {
    id: 349,
    itemCode: '21.07.02',
    title: 'Exceeding 600 wide',
    notes: '',
    description: '',
  },
  {
    id: 350,
    itemCode: '21.08',
    title: 'Items extra over the work in which they occur',
    notes: '',
    description: '',
  },
  {
    id: 351,
    itemCode: '21.08.01',
    title: 'Dimensioned description',
    notes: '',
    description: '',
  },
  {
    id: 352,
    itemCode: '21.09',
    title: 'Boundary work',
    notes: '',
    description: '',
  },
  {
    id: 353,
    itemCode: '21.09.02',
    title: 'Proprietary reference',
    notes: '',
    description: '',
  },
  {
    id: 354,
    itemCode: '22',
    title: 'General joinery',
    notes: '',
    description: '',
  },
  {
    id: 355,
    itemCode: '22.01',
    title: 'Skirtings, picture rails',
    notes: '',
    description: '',
  },
  {
    id: 356,
    itemCode: '22.02',
    title: 'Architraves and the like',
    notes: '',
    description: '',
  },
  {
    id: 357,
    itemCode: '22.03',
    title: 'Cover fillets, stops, trims, beads, nosings and the like',
    notes: '',
    description: '',
  },
  {
    id: 358,
    itemCode: '22.04',
    title: 'Isolated shelves and worktops',
    notes: '',
    description: '',
  },
  {
    id: 359,
    itemCode: '22.05',
    title: 'Window boards',
    notes: '',
    description: '',
  },
  {
    id: 360,
    itemCode: '22.06',
    title: 'Isolated handrails and grab rails',
    notes: '',
    description: '',
  },
  {
    id: 361,
    itemCode: '22.09',
    title: 'Shelves',
    notes: '',
    description: '',
  },
  {
    id: 362,
    itemCode: '22.16',
    title: 'Cubicle partition sets',
    notes: '',
    description: '',
  },
  {
    id: 363,
    itemCode: '22.22',
    title: 'Ironmongery: type of item, unit or set stated',
    notes: '',
    description: '',
  },
  {
    id: 364,
    itemCode: '23',
    title: 'Windows, screens and lights',
    notes: '',
    description: '',
  },
  {
    id: 365,
    itemCode: '23.01',
    title: 'Windows and window frames',
    notes: '',
    description: '',
  },
  {
    id: 366,
    itemCode: '23.01.01',
    title: 'Dimensioned description or diagram',
    notes: '',
    description: '',
  },
  {
    id: 367,
    itemCode: '23.03',
    title: 'Sun shades',
    notes: '',
    description: '',
  },
  {
    id: 368,
    itemCode: '23.03.01',
    title: 'Dimensioned description or diagram',
    notes: '',
    description: '',
  },
  {
    id: 369,
    itemCode: '23.04',
    title: 'Rooflights, skylights and lanternlights',
    notes: '',
    description: '',
  },
  {
    id: 370,
    itemCode: '23.04.01',
    title: 'Dimensioned description or diagram',
    notes: '',
    description: '',
  },
  {
    id: 371,
    itemCode: '23.05',
    title: 'Screens, borrowed lights and frames',
    notes: '',
    description: '',
  },
  {
    id: 372,
    itemCode: '23.05.01',
    title: 'Dimensioned description or diagram',
    notes: '',
    description: '',
  },
  {
    id: 373,
    itemCode: '23.1',
    title: 'Ironmongery: Type of item, unit or set stated',
    notes: '',
    description: '',
  },
  {
    id: 374,
    itemCode: '23.10.01',
    title: 'Method of fixing',
    notes: '',
    description: '',
  },
  {
    id: 375,
    itemCode: '24',
    title: 'Doors, shutters and hatches',
    notes: '',
    description: '',
  },
  {
    id: 376,
    itemCode: '24.01',
    title: 'Door sets',
    notes: '',
    description: '',
  },
  {
    id: 377,
    itemCode: '24.01.01',
    title: 'Detailed description of set',
    notes: '',
    description: '',
  },
  {
    id: 378,
    itemCode: '24.02',
    title: 'Doors',
    notes: '',
    description: '',
  },
  {
    id: 379,
    itemCode: '24.02.01',
    title: 'Dimensioned description or diagram',
    notes: '',
    description: '',
  },
  {
    id: 380,
    itemCode: '24.03',
    title: 'Roller shutters',
    notes: '',
    description: '',
  },
  {
    id: 381,
    itemCode: '24.03.01',
    title: 'Dimensioned description or diagram',
    notes: '',
    description: '',
  },
  {
    id: 382,
    itemCode: '24.06',
    title: 'Hatches',
    notes: '',
    description: '',
  },
  {
    id: 383,
    itemCode: '24.06.01',
    title: 'Dimensioned description or diagram',
    notes: '',
    description: '',
  },
  {
    id: 384,
    itemCode: '24.09',
    title: 'Door frames',
    notes: '',
    description: '',
  },
  {
    id: 385,
    itemCode: '24.09.01',
    title: 'Dimensioned overall cross section description',
    notes: '',
    description: '',
  },
  {
    id: 386,
    itemCode: '24.16',
    title: 'Ironmongery: Type of item, unit or set stated',
    notes: '',
    description: '',
  },
  {
    id: 387,
    itemCode: '24.16.01',
    title: 'Method of fixing',
    notes: '',
    description: '',
  },
  {
    id: 388,
    itemCode: '25',
    title: 'Stairs, walkways and balustrades',
    notes: '',
    description: '',
  },
  {
    id: 389,
    itemCode: '25.01',
    title: 'Staircase: type stated',
    notes: '',
    description: '',
  },
  {
    id: 390,
    itemCode: '25.01.01',
    title: 'Dimensioned description',
    notes: '',
    description: '',
  },
  {
    id: 391,
    itemCode: '25.07',
    title: 'Balustrades',
    notes: '',
    description: '',
  },
  {
    id: 392,
    itemCode: '25.07.01',
    title: 'Dimensioned description',
    notes: '',
    description: '',
  },
  {
    id: 393,
    itemCode: '25.08',
    title: 'Handrails',
    notes: '',
    description: '',
  },
  {
    id: 394,
    itemCode: '25.08.01',
    title: 'Dimensioned description',
    notes: '',
    description: '',
  },
  {
    id: 395,
    itemCode: '27',
    title: 'Glazing',
    notes: '',
    description: '',
  },
  {
    id: 396,
    itemCode: '27.01',
    title: 'Glass, type stated',
    notes: '',
    description: '',
  },
  {
    id: 397,
    itemCode: '27.01.01',
    title: 'Thickness of glass or overall thickness of sealed unit  (Rate per m2, SMM7 measurement rule)',
    notes: '',
    description: '',
  },
  {
    id: 398,
    itemCode: '27.02',
    title: 'Sealed glazed units, type of glass stated',
    notes: '',
    description: '',
  },
  {
    id: 399,
    itemCode: '27.02.01',
    title: 'Thickness of glass or overall thickness of sealed unit',
    notes: '',
    description: '',
  },
  {
    id: 400,
    itemCode: '28',
    title: 'Floor, wall, ceiling and roof finishes',
    notes: '',
    description: '',
  },
  {
    id: 401,
    itemCode: '28.01',
    title: 'Screeds, beds and toppings, thickness and number of coats stated',
    notes: '',
    description: '',
  },
  {
    id: 402,
    itemCode: '28.01.01',
    title: '≤ 600mm wide',
    notes: '',
    description: '',
  },
  {
    id: 403,
    itemCode: '28.01.02',
    title: '> 600mm wide',
    notes: '',
    description: '',
  },
  {
    id: 404,
    itemCode: '28.02',
    title: 'Finish to floors, type of finish and overall thickness stated',
    notes: '',
    description: '',
  },
  {
    id: 405,
    itemCode: '28.02.01',
    title: '≤ 600mm wide',
    notes: '',
    description: '',
  },
  {
    id: 406,
    itemCode: '28.02.02',
    title: '> 600mm wide',
    notes: '',
    description: '',
  },
  {
    id: 407,
    itemCode: '28.07',
    title: 'Finish to walls, type of finish and overall thickness stated',
    notes: '',
    description: '',
  },
  {
    id: 408,
    itemCode: '28.07.01',
    title: '≤ 600mm wide',
    notes: '',
    description: '',
  },
  {
    id: 409,
    itemCode: '28.08',
    title: 'Finish to isolated columns, type of finish and overall thickness stated',
    notes: '',
    description: '',
  },
  {
    id: 410,
    itemCode: '28.08.01',
    title: '≤ 600mm wide',
    notes: '',
    description: '',
  },
  {
    id: 411,
    itemCode: '28.09',
    title: 'Finish to ceilings, type of finish and overall thickness stated',
    notes: '',
    description: '',
  },
  {
    id: 412,
    itemCode: '28.09.01',
    title: '≤ 600mm wide',
    notes: '',
    description: '',
  },
  {
    id: 413,
    itemCode: '28.1',
    title: 'Finish to isolated beams, type of finish and overall thickness stated',
    notes: '',
    description: '',
  },
  {
    id: 414,
    itemCode: '28.10.01',
    title: '≤ 600mm wide',
    notes: '',
    description: '',
  },
  {
    id: 415,
    itemCode: '28.11',
    title: 'Finish to treads',
    notes: '',
    description: '',
  },
  {
    id: 416,
    itemCode: '28.11.01',
    title: 'Net width stated',
    notes: '',
    description: '',
  },
  {
    id: 417,
    itemCode: '28.12',
    title: 'Finish to risers',
    notes: '',
    description: '',
  },
  {
    id: 418,
    itemCode: '28.12.01',
    title: 'Net width stated',
    notes: '',
    description: '',
  },
  {
    id: 419,
    itemCode: '28.14',
    title: 'Skirtings, net height stated',
    notes: '',
    description: '',
  },
  {
    id: 420,
    itemCode: '28.14.01',
    title: 'Net height stated',
    notes: '',
    description: '',
  },
  {
    id: 421,
    itemCode: '28.17',
    title: 'Coves',
    notes: '',
    description: '',
  },
  {
    id: 422,
    itemCode: '28.17.01',
    title: 'Girth and shape stated',
    notes: '',
    description: '',
  },
  {
    id: 423,
    itemCode: '28.27',
    title: 'Dividing strips',
    notes: '',
    description: '',
  },
  {
    id: 424,
    itemCode: '28.27.01',
    title: 'Dimensioned description',
    notes: '',
    description: '',
  },
  {
    id: 425,
    itemCode: '28.28',
    title: 'Beads, function stated',
    notes: '',
    description: '',
  },
  {
    id: 426,
    itemCode: '28.28.01',
    title: 'Dimensioned description',
    notes: '',
    description: '',
  },
  {
    id: 427,
    itemCode: '28.29',
    title: 'Nosings',
    notes: '',
    description: '',
  },
  {
    id: 428,
    itemCode: '28.29.01',
    title: 'Dimensioned description',
    notes: '',
    description: '',
  },
  {
    id: 429,
    itemCode: '28.31',
    title: 'Metal mesh lathing, details stated',
    notes: '',
    description: '',
  },
  {
    id: 430,
    itemCode: '28.31.01',
    title: 'To walls',
    notes: '',
    description: '',
  },
  {
    id: 431,
    itemCode: '28.31.02',
    title: 'To ceilings',
    notes: '',
    description: '',
  },
  {
    id: 432,
    itemCode: '28.31.05',
    title: 'To isolated beams.',
    notes: '',
    description: '',
  },
  {
    id: 433,
    itemCode: '28.31.06',
    title: 'To isolated columns.',
    notes: '',
    description: '',
  },
  {
    id: 434,
    itemCode: '29',
    title: 'Decoration',
    notes: '',
    description: '',
  },
  {
    id: 435,
    itemCode: '29.01',
    title: 'Painting to general surfaces',
    notes: '',
    description: '',
  },
  {
    id: 436,
    itemCode: '29.01.01',
    title: '≤ 300mm girth',
    notes: '',
    description: '',
  },
  {
    id: 437,
    itemCode: '29.01.02',
    title: '> 300mm girth',
    notes: '',
    description: '',
  },
  {
    id: 438,
    itemCode: '29.02',
    title: 'Painting to glazed surfaces irrespective of pane size',
    notes: '',
    description: '',
  },
  {
    id: 439,
    itemCode: '29.02.01',
    title: '≤ 300mm girth',
    notes: '',
    description: '',
  },
  {
    id: 440,
    itemCode: '29.02.02',
    title: '> 300mm girth',
    notes: '',
    description: '',
  },
  {
    id: 441,
    itemCode: '29.03',
    title: 'Painting structural metalwork',
    notes: '',
    description: '',
  },
  {
    id: 442,
    itemCode: '29.03.01',
    title: '≤ 300mm girth',
    notes: '',
    description: '',
  },
  {
    id: 443,
    itemCode: '29.03.02',
    title: '> 300mm girth',
    notes: '',
    description: '',
  },
  {
    id: 444,
    itemCode: '29.03.03',
    title: 'Isolated areas ≤ 1m2 irrespective of location or girth',
    notes: '',
    description: '',
  },
  {
    id: 445,
    itemCode: '29.04',
    title: 'Painting radiators, type stated',
    notes: '',
    description: '',
  },
  {
    id: 446,
    itemCode: '29.04.01',
    title: '≤ 300mm girth',
    notes: '',
    description: '',
  },
  {
    id: 447,
    itemCode: '29.04.02',
    title: '> 300mm girth',
    notes: '',
    description: '',
  },
  {
    id: 448,
    itemCode: '29.04.03',
    title: 'Isolated areas ≤ 1m2 irrespective of location or girth',
    notes: '',
    description: '',
  },
  {
    id: 449,
    itemCode: '29.05',
    title: 'Painting gutters',
    notes: '',
    description: '',
  },
  {
    id: 450,
    itemCode: '29.05.01',
    title: '≤ 300mm girth',
    notes: '',
    description: '',
  },
  {
    id: 451,
    itemCode: '29.05.02',
    title: '> 300mm girth',
    notes: '',
    description: '',
  },
  {
    id: 452,
    itemCode: '29.07',
    title: 'Painting services, type stated',
    notes: '',
    description: '',
  },
  {
    id: 453,
    itemCode: '29.07.01',
    title: '≤ 300mm girth',
    notes: '',
    description: '',
  },
  {
    id: 454,
    itemCode: '29.07.02',
    title: '> 300mm girth',
    notes: '',
    description: '',
  },
  {
    id: 455,
    itemCode: '29.07.03',
    title: 'Isolated areas ≤ 1m2 irrespective of location or girth',
    notes: '',
    description: '',
  },
  {
    id: 456,
    itemCode: '29.08',
    title: 'Painting railings, fences and gates',
    notes: '',
    description: '',
  },
  {
    id: 457,
    itemCode: '29.08.01',
    title: '≤ 300mm girth',
    notes: '',
    description: '',
  },
  {
    id: 458,
    itemCode: '29.08.02',
    title: '> 300mm girth',
    notes: '',
    description: '',
  },
  {
    id: 459,
    itemCode: '29.09',
    title: 'Decorative papers or fabrics: Walls and columns',
    notes: '',
    description: '',
  },
  {
    id: 460,
    itemCode: '29.09.01',
    title: 'Areas ≤ 1m2',
    notes: '',
    description: '',
  },
  {
    id: 461,
    itemCode: '29.09.02',
    title: 'Areas > 1m2',
    notes: '',
    description: '',
  },
  {
    id: 462,
    itemCode: '29.1',
    title: 'Decorative papers or fabrics: Ceilings and beams',
    notes: '',
    description: '',
  },
  {
    id: 463,
    itemCode: '29.10.01',
    title: 'Areas ≤ 1m2',
    notes: '',
    description: '',
  },
  {
    id: 464,
    itemCode: '29.10.02',
    title: 'Areas > 1m2',
    notes: '',
    description: '',
  },
  {
    id: 465,
    itemCode: '30',
    title: 'Suspended ceilings',
    notes: '',
    description: '',
  },
  {
    id: 466,
    itemCode: '30.01',
    title: 'Ceilings',
    notes: '',
    description: '',
  },
  {
    id: 467,
    itemCode: '30.01.02',
    title: 'Depth of suspension 150-500mm',
    notes: '',
    description: '',
  },
  {
    id: 468,
    itemCode: '30.01.03',
    title: 'Depth thereafter in 500mm stages',
    notes: '',
    description: '',
  },
  {
    id: 469,
    itemCode: '30.08',
    title: 'Edge trims',
    notes: '',
    description: '',
  },
  {
    id: 470,
    itemCode: '30.08.01',
    title: 'Dimensioned description',
    notes: '',
    description: '',
  },
  {
    id: 471,
    itemCode: '31',
    title: 'Insulation, fire stopping and fire protection',
    notes: '',
    description: '',
  },
  {
    id: 472,
    itemCode: '31.01',
    title: 'Boards',
    notes: '',
    description: '',
  },
  {
    id: 473,
    itemCode: '31.01.01',
    title: 'Thickness stated',
    notes: '',
    description: '',
  },
  {
    id: 474,
    itemCode: '31.02',
    title: 'Sheets',
    notes: '',
    description: '',
  },
  {
    id: 475,
    itemCode: '31.02.01',
    title: 'Thickness stated',
    notes: '',
    description: '',
  },
  {
    id: 476,
    itemCode: '31.03',
    title: 'Quilts',
    notes: '',
    description: '',
  },
  {
    id: 477,
    itemCode: '31.03.01',
    title: 'Thickness stated',
    notes: '',
    description: '',
  },
  {
    id: 478,
    itemCode: '31.04',
    title: 'Loose',
    notes: '',
    description: '',
  },
  {
    id: 479,
    itemCode: '31.04.01',
    title: 'Thickness stated',
    notes: '',
    description: '',
  },
  {
    id: 480,
    itemCode: '31.05',
    title: 'Sprayed',
    notes: '',
    description: '',
  },
  {
    id: 481,
    itemCode: '31.05.01',
    title: 'Thickness stated',
    notes: '',
    description: '',
  },
  {
    id: 482,
    itemCode: '31.06',
    title: 'Filling cavities',
    notes: '',
    description: '',
  },
  {
    id: 483,
    itemCode: '31.06.01',
    title: 'Thickness stated',
    notes: '',
    description: '',
  },
  {
    id: 484,
    itemCode: '31.07',
    title: 'Fire stops, type stated',
    notes: '',
    description: '',
  },
  {
    id: 485,
    itemCode: '31.07.01',
    title: 'Dimensioned description',
    notes: '',
    description: '',
  },
  {
    id: 486,
    itemCode: '32',
    title: 'Furniture, fittings and equipment',
    notes: '',
    description: '',
  },
  {
    id: 487,
    itemCode: '32.01',
    title: 'Fixtures, fittings or equipment without services',
    notes: '',
    description: '',
  },
  {
    id: 488,
    itemCode: '32.01.01',
    title: 'Component drawing reference',
    notes: '',
    description: '',
  },
  {
    id: 489,
    itemCode: '32.02',
    title: 'Fixtures, fittings or equipment with services',
    notes: '',
    description: '',
  },
  {
    id: 490,
    itemCode: '32.02.01',
    title: 'Component drawing reference',
    notes: '',
    description: '',
  },
  {
    id: 491,
    itemCode: '32.03',
    title: 'Ancillary items not provided with the item of equipment',
    notes: '',
    description: '',
  },
  {
    id: 492,
    itemCode: '32.03.01',
    title: 'Type, size and method of jointing stated',
    notes: '',
    description: '',
  },
  {
    id: 493,
    itemCode: '32.05',
    title: 'Signwriting',
    notes: '',
    description: '',
  },
  {
    id: 494,
    itemCode: '32.05.01',
    title: 'Dimensioned description',
    notes: '',
    description: '',
  },
  {
    id: 495,
    itemCode: '33',
    title: 'Drainage above ground',
    notes: '',
    description: '',
  },
  {
    id: 496,
    itemCode: '33.01',
    title: 'Pipework',
    notes: '',
    description: '',
  },
  {
    id: 497,
    itemCode: '33.02',
    title: 'Pipework ancillaries',
    notes: '',
    description: '',
  },
  {
    id: 498,
    itemCode: '33.05',
    title: 'Gutters',
    notes: '',
    description: '',
  },
  {
    id: 499,
    itemCode: '33.06',
    title: 'Gutter ancillaries',
    notes: '',
    description: '',
  },
  {
    id: 500,
    itemCode: '34',
    title: 'Drainage below ground',
    notes: '',
    description: '',
  },
  {
    id: 501,
    itemCode: '34.01',
    title: 'Drain runs',
    notes: '',
    description: '',
  },
  {
    id: 502,
    itemCode: '34.02',
    title: 'Items extra over drain runs irrespective of depth or pipe size',
    notes: '',
    description: '',
  },
  {
    id: 503,
    itemCode: '34.03',
    title: 'Pipe fittings',
    notes: '',
    description: '',
  },
  {
    id: 504,
    itemCode: '34.04',
    title: 'Accessories',
    notes: '',
    description: '',
  },
  {
    id: 505,
    itemCode: '34.06',
    title: 'Manholes',
    notes: '',
    description: '',
  },
  {
    id: 506,
    itemCode: '34.07',
    title: 'Inspection chambers',
    notes: '',
    description: '',
  },
  {
    id: 507,
    itemCode: '34.08',
    title: 'Soakaways',
    notes: '',
    description: '',
  },
  {
    id: 508,
    itemCode: '34.1',
    title: 'Septic tanks',
    notes: '',
    description: '',
  },
  {
    id: 509,
    itemCode: '34.13',
    title: 'Sundries',
    notes: '',
    description: '',
  },
  {
    id: 510,
    itemCode: '34.14',
    title: 'Covers and frames',
    notes: '',
    description: '',
  },
  {
    id: 511,
    itemCode: '34.16',
    title: 'Connections',
    notes: '',
    description: '',
  },
  {
    id: 512,
    itemCode: '35',
    title: 'Site Works',
    notes: '',
    description: '',
  },
  {
    id: 513,
    itemCode: '35.01',
    title: 'Kerbs',
    notes: '',
    description: '',
  },
  {
    id: 514,
    itemCode: '35.02',
    title: 'Edgings',
    notes: '',
    description: '',
  },
  {
    id: 515,
    itemCode: '35.03',
    title: 'Channels',
    notes: '',
    description: '',
  },
  {
    id: 516,
    itemCode: '35.05',
    title: 'Extra over for:',
    notes: '',
    description: '',
  },
  {
    id: 517,
    itemCode: '35.06',
    title: 'In-situ concrete',
    notes: '',
    description: '',
  },
  {
    id: 518,
    itemCode: '35.07',
    title: 'Formwork',
    notes: '',
    description: '',
  },
  {
    id: 519,
    itemCode: '35.08',
    title: 'Reinforcement',
    notes: '',
    description: '',
  },
  {
    id: 520,
    itemCode: '35.09',
    title: 'Joints',
    notes: '',
    description: '',
  },
  {
    id: 521,
    itemCode: '35.1',
    title: 'Worked finishes',
    notes: '',
    description: '',
  },
  {
    id: 522,
    itemCode: '35.12',
    title: 'Coated macadam and asphalt',
    notes: '',
    description: '',
  },
  {
    id: 523,
    itemCode: '35.13',
    title: 'Gravel, hoggin and woodchip',
    notes: '',
    description: '',
  },
  {
    id: 524,
    itemCode: '35.14',
    title: 'Interlocking brick and blocks, slabs, bricks, blocks, setts and cobbles',
    notes: '',
    description: '',
  },
  {
    id: 525,
    itemCode: '35.16',
    title: 'Accessories',
    notes: '',
    description: '',
  },
  {
    id: 526,
    itemCode: '36',
    title: 'Fencing',
    notes: '',
    description: '',
  },
  {
    id: 527,
    itemCode: '36.01',
    title: 'Fencing, type stated',
    notes: '',
    description: '',
  },
  {
    id: 528,
    itemCode: '36.02',
    title: 'Extra over for special supports',
    notes: '',
    description: '',
  },
  {
    id: 529,
    itemCode: '36.05',
    title: 'Gates',
    notes: '',
    description: '',
  },
  {
    id: 530,
    itemCode: '37',
    title: 'Soft landscaping',
    notes: '',
    description: '',
  },
  {
    id: 531,
    itemCode: '37.01',
    title: 'Cultivating',
    notes: '',
    description: '',
  },
  {
    id: 532,
    itemCode: '37.02',
    title: 'Surface applications',
    notes: '',
    description: '',
  },
  {
    id: 533,
    itemCode: '37.03',
    title: 'Seeding',
    notes: '',
    description: '',
  },
  {
    id: 534,
    itemCode: '37.04',
    title: 'Turfing',
    notes: '',
    description: '',
  },
  {
    id: 535,
    itemCode: '37.05',
    title: 'Trees',
    notes: '',
    description: '',
  },
  {
    id: 536,
    itemCode: '37.06',
    title: 'Young nursery stock trees',
    notes: '',
    description: '',
  },
  {
    id: 537,
    itemCode: '37.07',
    title: 'Shrubs',
    notes: '',
    description: '',
  },
  {
    id: 538,
    itemCode: '37.08',
    title: 'Hedge plants',
    notes: '',
    description: '',
  },
  {
    id: 539,
    itemCode: '40',
    title: 'Transportation',
    notes: '',
    description: '',
  },
  {
    id: 540,
    itemCode: '40.01',
    title: 'System',
    notes: '',
    description: '',
  },
  {
    id: 541,
    itemCode: '41',
    title:
      "Water Installations, Builder's work in connection with mechanical, electrical and transportation installations",
    notes: '',
    description: '',
  },
  {
    id: 542,
    itemCode: '41.01',
    title: 'Plumbing and General builder’s work in connection with',
    notes: '',
    description: '',
  },
  {
    id: 543,
    itemCode: '41.08',
    title: 'Cutting holes through existing structures',
    notes: '',
    description: '',
  },
  {
    id: 544,
    itemCode: '41.09',
    title: 'Cutting mortices and sinkings in existing structures',
    notes: '',
    description: '',
  },
  {
    id: 545,
    itemCode: '41.1',
    title: 'Cutting chases through existing structures',
    notes: '',
    description: '',
  },
  {
    id: 546,
    itemCode: '41.13',
    title: 'Underground service runs',
    notes: '',
    description: '',
  },
  {
    id: 547,
    itemCode: '41.15',
    title: 'Pipe duct fittings',
    notes: '',
    description: '',
  },
  {
    id: 548,
    itemCode: '41.17',
    title: 'Manholes',
    notes: '',
    description: '',
  },
  {
    id: 549,
    itemCode: '41.22',
    title: 'Stopcock pits',
    notes: '',
    description: '',
  },
]
