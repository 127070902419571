import { SubstructureTakeOffTableData } from './ModelSubstructure.data'
import { TakeOffTableItem } from '../../components/domain/take-off/TakeOffTables.model'
import { SuperstructureTakeOffTableData } from './ModelSuperstructure.data'
import { ExteriorTakeOffTableData } from './ModelExterior.data'
import { TakeOffItem } from '../../components/domain/take-off/helpers/TakeOffDataParser'
import { MEPTakeOffTableData } from './ModelMEP.data'
import { FFETakeOffTableData } from './ModelFFE.data'
import { InternalTakeOffTableData } from './ModelInternal.data'

export interface FileEntry {
  id: number // should be unique for all file entries
  file: string
  urn: string
  children?: TakeOffTableItem[]
}
export const TakeOffFiles: { [key: string]: FileEntry[] } = {
  '3D Models': [
    {
      id: 100000001,
      file: 'BHR-R5D-401-00-M3-X-0003-D1-09_Entrance Building - Substructure.rvt',
      urn:
        'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDMtRDEtMDlfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBTdWJzdHJ1Y3R1cmUucnZ0',
      children: SubstructureTakeOffTableData,
    },
    {
      id: 100000002,
      file: 'BHR-R5D-401-00-M3-X-0004-D1-08_Entrance Building - Superstructure.ifc',
      urn:
        'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDQtRDEtMDhfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBTdXBlcnN0cnVjdHVyZS5ydnQ',
      children: SuperstructureTakeOffTableData,
    },
    {
      id: 100000003,
      file: 'BHR-R5D-401-00-M3-X-0005-D1-05_Entrance Building - Exterior.rvt',
      urn:
        'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDUtRDEtMDVfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBFeHRlcmlvci5ydnQ',
      children: ExteriorTakeOffTableData,
    },
    {
      id: 100000004,
      file: 'BHR-R5D-401-00-M3-X-0008-D1-03_Entrance Building - FFE.rvt',
      urn:
        'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDgtRDEtMDNfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBGRkUucnZ0',
      children: FFETakeOffTableData,
    },
    {
      id: 100000005,
      file: 'BHR-R5D-401-00-M3-X-0007-D1-03_Entrance Building - Internal.rvt',
      urn:
        'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDctRDEtMDNfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBJbnRlcm5hbC5ydnQ',
      children: InternalTakeOffTableData,
    },
    {
      id: 100000006,
      file: 'BHR-R5D-401-00-M3-X-0006-D1-03_Entrance Building - MEP.rvt',
      urn:
        'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDYtRDEtMDNfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBNRVAucnZ0',
      children: MEPTakeOffTableData,
    },
  ],
  '2D Drawings': [
    {
      id: 110000001,
      file: '09100-401-A-SE-120-B_Entrance building - Sections - Security strategy.pdf',
      urn:
        'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy8wOTEwMC00MDEtQS1TRS0xMjAtQl9FbnRyYW5jZSUyMGJ1aWxkaW5nJTIwLSUyMFNlY3Rpb25zJTIwLSUyMFNlY3VyaXR5JTIwc3RyYXRlZ3kucGRm',
    },
    {
      id: 110000002,
      file: '09100-401-S-BG-001-E_Entrance building - Foundations - General layout.pdf',
      urn:
        'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy8wOTEwMC00MDEtUy1CRy0wMDEtRV9FbnRyYW5jZSUyMGJ1aWxkaW5nJTIwLSUyMEZvdW5kYXRpb25zJTIwLSUyMEdlbmVyYWwlMjBsYXlvdXQucGRm',
    },
    // commented because we want to limit models loaded to forge
    // {
    //   id: 110000003,
    //   file: '09100-401-S-FF-001-J_Entrance building - First floor - General layout.pdf',
    //   urn:
    //     'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy8wOTEwMC00MDEtUy1GRi0wMDEtSl9FbnRyYW5jZSUyMGJ1aWxkaW5nJTIwLSUyMEZpcnN0JTIwZmxvb3IlMjAtJTIwR2VuZXJhbCUyMGxheW91dC5wZGY',
    // },
    // {
    //   id: 110000004,
    //   file: '09100-401-A-SE-002-C_Entrance building - Sections - CC and DD.pdf',
    //   urn:
    //     'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy8wOTEwMC00MDEtQS1TRS0wMDItQ19FbnRyYW5jZSUyMGJ1aWxkaW5nJTIwLSUyMFNlY3Rpb25zJTIwLSUyMENDJTIwYW5kJTIwREQucGRm',
    // },
    // {
    //   id: 110000005,
    //   file: '09100-401-C-BG-001-D_Entrance Building - Below ground - Drainage layout.pdf',
    //   urn:
    //     'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy8wOTEwMC00MDEtQy1CRy0wMDEtRF9FbnRyYW5jZSUyMEJ1aWxkaW5nJTIwLSUyMEJlbG93JTIwZ3JvdW5kJTIwLSUyMERyYWluYWdlJTIwbGF5b3V0LnBkZg',
    // },
    // {
    //   id: 110000006,
    //   file: '09100-401-A-GF-001-T_Entrance building - Ground floor - General layout.pdf',
    //   urn:
    //     'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy8wOTEwMC00MDEtQS1HRi0wMDEtVF9FbnRyYW5jZSUyMGJ1aWxkaW5nJTIwLSUyMEdyb3VuZCUyMGZsb29yJTIwLSUyMEdlbmVyYWwlMjBsYXlvdXQucGRm',
    // },
    // {
    //   id: 110000007,
    //   file: '09100-401-A-FF-001-Q_Entrance building - First floor - General layout.pdf',
    //   urn:
    //     'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy8wOTEwMC00MDEtQS1GRi0wMDEtUV9FbnRyYW5jZSUyMGJ1aWxkaW5nJTIwLSUyMEZpcnN0JTIwZmxvb3IlMjAtJTIwR2VuZXJhbCUyMGxheW91dC5wZGY',
    // },
    // {
    //   id: 110000008,
    //   file: '09100-401-A-EL-001-D_Entrance building - Elevations - Front and side.pdf',
    //   urn:
    //     'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy8wOTEwMC00MDEtQS1FTC0wMDEtRF9FbnRyYW5jZSUyMGJ1aWxkaW5nJTIwLSUyMEVsZXZhdGlvbnMlMjAtJTIwRnJvbnQlMjBhbmQlMjBzaWRlLnBkZg',
    // },
    {
      id: 110000009,
      file: '09100-401-M-GF-910-T_Entrance Building - Ground floor - MEP Engineering Systems - BWIC.dwfx',
      urn:
        'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy8wOTEwMC00MDEtTS1HRi05MTAtVF9FbnRyYW5jZSUyMEJ1aWxkaW5nJTIwLSUyMEdyb3VuZCUyMGZsb29yJTIwLSUyME1FUCUyMEVuZ2luZWVyaW5nJTIwU3lzdGVtcyUyMC0lMjBCV0lDLmR3Zng',
    },
  ],
}

export const Models3DFiles = TakeOffFiles['3D Models']
export const Models3DFilesIDs = TakeOffFiles['3D Models'].map((f) => f.id)
export const Drawings2DFiles = TakeOffFiles['2D Drawings']
export const Drawings2DFilesIDs = TakeOffFiles['2D Drawings'].map((f) => f.id)

export const InitialTakeOff2DItems: { [key: number]: TakeOffItem[] } = {
  110000001: [
    {
      id: 550000050,
      code: '20.123',
      name: 'Sample Take-Off',
      takeOffType: 'Wall',
      children: [
        {
          id: 5000000011,
          name: 'Sample Take-Off',
          takeOffType: 'Wall',
          takeOffExternalType: '2D',
          count: 1,
          topArea: 500,
        },
        {
          id: 5000000012,
          name: 'Sample Take-Off',
          takeOffType: 'Wall',
          takeOffExternalType: '2D',
          count: 1,
          topArea: 100,
        },
      ],
    },
  ],
}
