import React from 'react'
import { Layout } from 'antd'
import {
  ApartmentOutlined,
  GatewayOutlined,
  LineChartOutlined,
  PoundOutlined,
  ProfileOutlined,
  RocketOutlined,
  SettingOutlined,
  WalletOutlined,
} from '@ant-design/icons/lib'
import { MenuSider, MenuSiderItems } from '../../basic/menu/MenuSider'

const { Content } = Layout

const MenuItems: MenuSiderItems = {
  head: {
    key: 'main',
    icon: <RocketOutlined />,
    title: 'Trafalgar Square',
    address: 'main',
  },
  items: [
    {
      key: 'cost-plan',
      icon: <WalletOutlined />,
      title: 'Cost Plan',
      address: 'cost-plan',
    },
    {
      key: 'documentation',
      icon: <ProfileOutlined />,
      title: 'Documentation',
      address: 'documentation',
    },
    {
      key: 'take-off',
      icon: <GatewayOutlined />,
      title: 'Take-off',
      address: 'take-off',
    },
    {
      key: 'work-packages',
      icon: <ApartmentOutlined />,
      title: 'Work Packages',
      address: 'work-packages',
    },
    {
      key: 'activities',
      icon: <LineChartOutlined />,
      title: 'Activities',
      address: 'activities',
    },
    {
      key: 'valuations',
      icon: <PoundOutlined />,
      title: 'Valuations',
      address: 'valuations',
    },
    {
      key: 'settings',
      icon: <SettingOutlined />,
      title: 'Settings',
      address: 'settings',
    },
  ],
}

function ProjectTemplate({ children, selectedKey }: { children: React.ReactNode; selectedKey: string }): JSX.Element {
  return (
    <>
      <Layout>
        <MenuSider disableHead={false} head={MenuItems.head} items={MenuItems.items} selectedKey={selectedKey} />
        <Content style={{ paddingLeft: '50px', paddingRight: '50px' }}>
          <div>{children}</div>
        </Content>
      </Layout>
    </>
  )
}

export default ProjectTemplate
