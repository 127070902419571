import AddProjectFormPart from './AddProjectFormPart'
import { Col, Form, Row, Select, Tag } from 'antd'
import Users from '../../../../data/Users.data'
import { ProjectStatuses, statusToTagColor } from '../../../../model/Projects'
import { Breakdowns, Layouts } from '../../../../data/Projects.data'
import { Currencies } from '../../../../utils/currency/Currencies'
import { Timezones } from '../../../../utils/datetime/Timezones'
import { compareByKey } from '../../../../utils/comparators/ArrayComparators'
import React from 'react'

const Properties: AddProjectFormPart = ({ form }) => {
  return (
    <Form form={form} layout={'vertical'}>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item name={'manager'} label={'Manager'} rules={[{ required: true, message: 'Select manager' }]}>
            <Select showSearch={true} placeholder={'Select manager'}>
              {Users.map((u) => (
                <Select.Option key={`${u.name} ${u.surname}`} value={`${u.name} ${u.surname}`}>
                  {`${u.name} ${u.surname}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'stage'} label={'Stage'} rules={[{ required: true, message: 'Select Stage' }]}>
            <Select showSearch={true} placeholder={'Select stage'}>
              {ProjectStatuses.map((s) => (
                <Select.Option key={s} value={s}>
                  <Tag color={statusToTagColor(s)}> {s} </Tag>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item
            name={'projectLayout'}
            label={'Project Layout'}
            rules={[{ required: true, message: 'Select project layout!' }]}
          >
            <Select showSearch={true} placeholder={'Select layout'}>
              {Layouts.map((s) => (
                <Select.Option key={s} value={s}>
                  {s}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={'projectBreakdown'}
            label={'Project Breakdown'}
            rules={[{ required: true, message: 'Select breakdown!' }]}
          >
            <Select showSearch={true} placeholder={'Select manager'}>
              {Breakdowns.map((s) => (
                <Select.Option key={s} value={s}>
                  {s}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={8}>
          <Form.Item
            name={'mainCurrency'}
            label={'Main Currency'}
            rules={[{ required: true, message: 'Select Main Currency' }]}
          >
            <Select showSearch={true} placeholder={'Select Main Currency'}>
              {Currencies.map((curr) => (
                <Select.Option key={curr.code} value={curr.code}>
                  {`${curr.code} (${curr.name})`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'secondCurrency'} label={'Second Currency'} rules={[{ required: false }]}>
            <Select showSearch={true} placeholder={'Select Second Currency'}>
              {Currencies.map((curr) => (
                <Select.Option key={curr.code} value={curr.code}>
                  {`${curr.code} (${curr.name})`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'timezone'} label={'Timezone'} rules={[{ required: true, message: 'Select Timezone' }]}>
            <Select showSearch={true} placeholder={'Select timezone'}>
              {Timezones.sort(compareByKey('group', true)).map((tz) => (
                <Select.Option key={tz.label} value={tz.label}>
                  {`${tz.label} (${tz.group})`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default Properties
