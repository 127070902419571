import { Button, Collapse, Table } from 'antd'
import React, { useState } from 'react'
import Users from '../../../../data/Users.data'
import Search from 'antd/lib/input/Search'
import { settingsUsersColumns } from '../Settings.columns'
import { settingsData } from '../Settings.data'
import SettingsModal from '../helpers/SettingsModal'
const Panel = Collapse.Panel

export default function SettingsUsers() {
  const [searchName, setSearchName] = useState('')
  const [settingsModalVisible, setSettingsModalVisibility] = useState(false)

  const handleSearch = (event: string) => setSearchName(event)
  const showModal = () => setSettingsModalVisibility(true)
  const hideModal = () => setSettingsModalVisibility(false)

  return (
    <div>
      <div className="dropdown-wrapper" style={{ display: 'flex', marginTop: '15px', marginBottom: '25px' }}>
        <Search
          placeholder="Type a name or email address"
          onSearch={() => {}}
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: 350, height: 35, marginRight: '48px', marginTop: '14px' }}
        />
        <div style={{ display: 'flex' }}>
          <Button key={'add'} type="primary" style={{ marginTop: '16px', marginBottom: '10px' }} onClick={showModal}>
            Add user
          </Button>
        </div>
      </div>
      <div>
        <Collapse className="site-collapse-custom-settings-panel">
          {Users.filter((c) =>
            c
              ? c.name.toLowerCase().includes(searchName.toLowerCase()) ||
                c.surname.toLowerCase().includes(searchName.toLowerCase())
              : true
          ).map(({ name, surname, email }) => (
            <Panel
              forceRender={true}
              header={`${name} ${surname} (${email})`}
              key=""
              className="site-collapse-custom-settings-panel"
            >
              <Table size={'small'} dataSource={settingsData} columns={settingsUsersColumns} pagination={false} />
            </Panel>
          ))}
        </Collapse>
      </div>
      <SettingsModal visibility={settingsModalVisible} hideModal={hideModal} />
    </div>
  )
}
