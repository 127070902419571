import React, { Key, useState } from 'react'
import { FunctionComponent } from 'react'
import { Tabs } from 'antd'

import {
  BoQActivitiesColumns,
  BoQResourcesColumns,
  BoQTakeoffsColumns,
  BoQWorkPackagesColumns,
} from './BillOfQuantities.columns'
import {
  BoQActivitiesData,
  BoQData,
  BoQResourcesData,
  BoQTakeOffData,
  BoQWorkPackagesData,
} from '../../../data/BillOfQuantities.data'
import { createDocument, ForgeViewer, SelectedNodes } from '../forge-viewer/ForgeViewer'
import BoQMainTable from './table/BoQMainTable'
import BoQDetailsTablePane from './details/BoQDetailsTablePane'
import BoQDetailsDescriptionPane from './details/BoQDetailsDescriptionPane'
import { Model, ModelNode } from '../forge-viewer/ForgeViewer.model'
import { ThreePartsLayout } from '../../basic/layout/three-parts-layout/ThreePartsLayout'
import ProjectTemplate from '../project/ProjectTemplate'
import { BoQToTakeOffMapping, BoQToTakeOffTableDataMapping } from '../../../data/BoQTakeOffMapping'
import { RootState } from '../../../context/reducer'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenWindowState } from '../../../context/open-windows/OpenWindows.actions'
import NewWindow from '../../basic/new-window/NewWindow'
import ReplacementPane from '../../basic/new-window/ReplacementPane'
import BreadcrumbPanel from '../../basic/breadcrumb/BreadcrumbPanel'
import { newWindowHeight, newWindowWidth } from '../../../constants/sizes'

const { TabPane } = Tabs

const BillOfQuantitiesPart: FunctionComponent = () => {
  const BoQIdsEntries = BoQData.map(({ key, id }) => {
    return {
      key,
      id,
    }
  }) // due to optimisation on 79 field JSONs

  const [boqIds, setBoqIds] = useState<number[]>([])
  const [loadedModels, setLoadedModels] = useState<Model[]>([])
  const [isBOQNewWindowOpen, setIsBOQNewWindowOpen] = useState(false)

  const handleBoqItemSelection = (rows: Key[]) => {
    setBoqIds(BoQIdsEntries.filter(({ key }) => rows.indexOf(key) !== -1).map(({ id }) => id))
  }

  const onModelLoaded = (model: Model) => setLoadedModels(loadedModels.concat(model))
  const dispatch = useDispatch()

  const getOpenWindow = (id: string) => (state: RootState) => state.openWindows.windows.get(id) || false
  const boqBreakdownWindowId = 'boqBreakdown'
  const boqInfoPanelWindowId = 'boqInfoPanel'
  const isBoQBreakdownWindowOpen = useSelector(getOpenWindow(boqBreakdownWindowId))
  const isboqInfoPanelWindowWindowOpen = useSelector(getOpenWindow(boqInfoPanelWindowId))

  const getAllPiles = (node: ModelNode, pilesIds: number[]): number[] => {
    if (node.children.length === 1 && node.name.toLowerCase() === 'scj-pile') {
      return [node.id, ...pilesIds]
    }
    return node.children.map((ch) => getAllPiles(ch, pilesIds)).flat()
  }

  const selectedNodes = (): SelectedNodes[] => {
    const selectedItems = boqIds
      .filter((id) => BoQToTakeOffMapping[id] !== undefined)
      .flatMap((id) => BoQToTakeOffMapping[id])
      .flatMap((t) => t.children)
      .map((t) => t.id)
    const model = loadedModels.find(
      (m) =>
        m.documentUrn ===
        'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDMtRDEtMDlfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBTdWJzdHJ1Y3R1cmUucnZ0'
    )
    if (model) {
      return [
        {
          nodeIds: [...new Set(selectedItems)],
          model,
        },
      ]
    }
    return []
  }

  const calculateTakeOffDetailsData = () => {
    if (boqIds.length === 1 && BoQToTakeOffTableDataMapping[boqIds[0]] !== undefined) {
      return BoQToTakeOffTableDataMapping[boqIds[0]]
    }
    return []
  }

  const mainTable = (tableHeight: number) => {
    return (
      <NewWindow
        height={newWindowHeight}
        width={newWindowWidth}
        isOpen={isboqInfoPanelWindowWindowOpen}
        onPortalClose={() => {
          dispatch(setOpenWindowState(boqInfoPanelWindowId, false))
          setIsBOQNewWindowOpen(false)
        }}
        replacementPane={
          <ReplacementPane
            onHide={() => {
              dispatch(setOpenWindowState(boqInfoPanelWindowId, false))
              setIsBOQNewWindowOpen(false)
            }}
            onWindowShow={() => {}}
            style={{ width: '100%', height: 450, backgroundColor: '#fff' }}
          />
        }
      >
        <BoQMainTable
          openWindow={() => {
            dispatch(setOpenWindowState(boqInfoPanelWindowId, true))
            setIsBOQNewWindowOpen(true)
          }}
          tableHeight={isBOQNewWindowOpen ? 600 : tableHeight}
          onSelect={handleBoqItemSelection}
        />
      </NewWindow>
    )
  }

  const forgeViewer = () => {
    return (
      <ForgeViewer
        onModelLoaded={onModelLoaded}
        selectedNodes={selectedNodes()}
        documents={[
          createDocument(
            'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDMtRDEtMDlfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBTdWJzdHJ1Y3R1cmUucnZ0'
          ),
          createDocument(
            'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDQtRDEtMDhfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBTdXBlcnN0cnVjdHVyZS5ydnQ'
          ),
          createDocument(
            'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDUtRDEtMDVfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBFeHRlcmlvci5ydnQ'
          ),
        ]}
        visibleModelsUrns={[
          'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDMtRDEtMDlfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBTdWJzdHJ1Y3R1cmUucnZ0',
          'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDQtRDEtMDhfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBTdXBlcnN0cnVjdHVyZS5ydnQ',
          'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDUtRDEtMDVfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBFeHRlcmlvci5ydnQ',
        ]}
      />
    )
  }

  const detailsPanel = (rowHeight: number) => {
    return (
      <Tabs tabBarStyle={{ paddingLeft: 16 }}>
        <TabPane tab={'Description'} key={'description'}>
          <BoQDetailsDescriptionPane selectedIds={boqIds} data={BoQData} height={rowHeight * 3.5} />
        </TabPane>
        <TabPane tab={'Resources'} key={'resources'}>
          <BoQDetailsTablePane
            selectedIds={boqIds}
            columns={BoQResourcesColumns}
            data={BoQResourcesData}
            id={'boq-resources'}
            tableHeight={rowHeight * 3.5}
          />
        </TabPane>
        <TabPane tab={'Take-off'} key={'take-off'}>
          <BoQDetailsTablePane
            selectedIds={boqIds}
            columns={BoQTakeoffsColumns}
            data={calculateTakeOffDetailsData()}
            id={'boq-take-off'}
            tableHeight={rowHeight * 3.5}
          />
        </TabPane>
        <TabPane tab={'Work Packages'} key={'work-packages'}>
          <BoQDetailsTablePane
            selectedIds={boqIds}
            columns={BoQWorkPackagesColumns}
            data={BoQWorkPackagesData}
            id={'boq-work-packages'}
            tableHeight={rowHeight * 3.5}
          />
        </TabPane>
        <TabPane tab={'Activities'} key={'activities'}>
          <BoQDetailsTablePane
            selectedIds={boqIds}
            columns={BoQActivitiesColumns}
            data={BoQActivitiesData}
            id={'boq-activities'}
            tableHeight={rowHeight * 3.5}
          />
        </TabPane>
      </Tabs>
    )
  }

  return (
    <>
      <BreadcrumbPanel
        moveTo={'cost-plan'}
        displayName={'Cost Plan'}
        categoryName={'Sample Project'}
        openWindow={() => dispatch(setOpenWindowState(boqBreakdownWindowId, true))}
      />
      <NewWindow
        height={newWindowHeight}
        width={newWindowWidth}
        isOpen={isBoQBreakdownWindowOpen}
        onPortalClose={() => dispatch(setOpenWindowState(boqBreakdownWindowId, false))}
        replacementPane={
          <ReplacementPane
            onHide={() => dispatch(setOpenWindowState(boqBreakdownWindowId, false))}
            onWindowShow={() => {}}
            style={{ width: '100%', height: 450, backgroundColor: '#fff' }}
          />
        }
      >
        <ThreePartsLayout
          layoutId={'cost-plan'}
          leftSideComponent={mainTable}
          rightSideComponent={forgeViewer}
          bottomPanelComponent={detailsPanel}
        />
      </NewWindow>
    </>
  )
}

function BillOfQuantities(): JSX.Element {
  return (
    <ProjectTemplate selectedKey={'cost-plan'}>
      <BillOfQuantitiesPart />
    </ProjectTemplate>
  )
}

export default BillOfQuantities
