import { ExtendedColumnType } from '../SterlingTable'
import { BoQColumns, BoQResourcesColumns } from '../../../../domain/bill-of-quantities/BillOfQuantities.columns'
import { Key } from 'react'
import { currency, renderDecimal, renderNumber } from '../../../../../utils/rendering/Rendering'
import { numberSorter } from '../../../../../utils/Sorters'

export const enhanceColumnsWithOnCellProp = (columns: ExtendedColumnType[]): any[] => {
  return columns.map((col) => {
    return {
      ...col,
      onCell: (record: any) => ({
        editable: col.editable,
        editionConfig: col.editionConfig,
        record,
        dataIndex: col.dataIndex,
      }),
    }
  })
}

export const alignNumberColumns = (columns: ExtendedColumnType[]): any[] => {
  return columns.map((col) => {
    if (col.render === renderDecimal || col.render === currency || col.render === renderNumber) {
      return {
        ...col,
        align: 'right',
      }
    } else {
      return col
    }
  })
}

export const sortColumnsAccordingToGrouping = (
  columns: ExtendedColumnType[],
  activeColumn: Key
): ExtendedColumnType[] =>
  activeColumn !== undefined
    ? columns
        .filter((c) => c.key === activeColumn)
        .map((c) => Object.assign({}, c, { fixed: 'left' }) as ExtendedColumnType)
        .concat(BoQColumns.filter((c) => c.key !== activeColumn))
    : columns

export const filterColumns = (columns: ExtendedColumnType[], excluded: string[]): Key[] =>
  columns.map((c) => c.key as Key).filter((c) => !excluded.includes(c as string))
