import { numberSorter, stringSorter } from '../../../utils/Sorters'
import { ExtendedColumnType } from '../../basic/table/sterling-table/SterlingTable'
import { SelectConfig } from '../../basic/table/editable-cell/types/EditableCellTypes'
import { CostItemStatuses, MarkupTypes, MetricUnits, NetMarkupTypes } from '../../../model/BillOfQuantities'
import { identity } from '../../../utils/FunctionalUtils'
import { Currencies, Currency } from '../../../utils/currency/Currencies'
import { currency, renderDecimal, percent, renderNumber } from '../../../utils/rendering/Rendering'
import { filterColumns } from '../../basic/table/sterling-table/helpers/ColumnsHelpers'

const numberSumming = (children: any[], key: string): number => {
  if (children.length === 0) {
    return 0
  } else {
    return children.reduce((value, child) => value + (child[key] || 0), 0)
  }
}

export const BoQColumns: ExtendedColumnType[] = [
  {
    title: 'WBS Code',
    dataIndex: 'wbs_code',
    key: 'wbs_code',
    width: 240,
    fixed: 'left',
    sorter: (a, b) => stringSorter(a.wbs_code, b.wbs_code),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    width: 400,
    fixed: 'left',
    sorter: (a, b) => stringSorter(a.title, b.title),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    width: 130,
    sorter: (a, b) => stringSorter(a.note, b.note),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 130,
    sorter: (a, b) => stringSorter(a.description, b.description),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    width: 130,
    sorter: (a, b) => stringSorter(a.location, b.location),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 130,
    sorter: (a, b) => stringSorter(a.status, b.status),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    editionConfig: {
      tag: 'select',
      options: CostItemStatuses,
      optionRenderer: identity,
    } as SelectConfig,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    width: 130,
    sorter: (a, b) => stringSorter(a.currency, b.currency),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    editionConfig: {
      tag: 'select',
      options: Currencies,
      optionRenderer: (c: Currency) => c.code,
    } as SelectConfig,
    formattingRule: undefined,
    groupingRule: (_) => 'GBP', // TODO: Change when availability of multiple currencies occur.
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    width: 130,
    sorter: (a, b) => stringSorter(a.unit, b.unit),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    editionConfig: {
      tag: 'select',
      options: MetricUnits,
      optionRenderer: identity,
    } as SelectConfig,
    formattingRule: undefined,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    width: 130,
    sorter: (a, b) => numberSorter(a.quantity, b.quantity),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Estimated Quantity',
    dataIndex: 'estimated_quantity',
    key: 'estimated_quantity',
    width: 130,
    sorter: (a, b) => numberSorter(a.estimated_quantity, b.estimated_quantity),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Take-off Quantity',
    dataIndex: 'take_off_quantity',
    key: 'take_off_quantity',
    width: 130,
    sorter: (a, b) => numberSorter(a.take_off_quantity, b.take_off_quantity),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Quantity Difference',
    dataIndex: 'quantity_difference',
    key: 'quantity_difference',
    width: 130,
    sorter: (a, b) => numberSorter(a.quantity_difference, b.quantity_difference),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Total Manhours',
    dataIndex: 'total_manhours',
    key: 'total_manhours',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_manhours, b.total_manhours),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'total_manhours'),
    render: renderDecimal,
  },
  {
    title: 'Total Plant Hours',
    dataIndex: 'total_plant_hours',
    key: 'total_plant_hours',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_plant_hours, b.total_plant_hours),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'total_plant_hours'),
    render: renderDecimal,
  },
  {
    title: 'Labour Rate',
    dataIndex: 'labour_rate',
    key: 'labour_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.labour_rate, b.labour_rate),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Material Rate',
    dataIndex: 'material_rate',
    key: 'material_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.material_rate, b.material_rate),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Plant Rate',
    dataIndex: 'plant_rate',
    key: 'plant_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.plant_rate, b.plant_rate),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Subcontractor Rate',
    dataIndex: 'subcontractor_rate',
    key: 'subcontractor_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.subcontractor_rate, b.subcontractor_rate),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Total Rate',
    dataIndex: 'total_rate',
    key: 'total_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_rate, b.total_rate),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Total Labour Cost',
    dataIndex: 'total_labour_cost',
    key: 'total_labour_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_labour_cost, b.total_labour_cost),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'total_labour_cost'),
    render: currency,
  },
  {
    title: 'Total Material Cost',
    dataIndex: 'total_material_cost',
    key: 'total_material_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_material_cost, b.total_material_cost),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'total_material_cost'),
    render: currency,
  },
  {
    title: 'Total Plant Cost',
    dataIndex: 'total_plant_cost',
    key: 'total_plant_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_plant_cost, b.total_plant_cost),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'total_plant_cost'),
    render: currency,
  },
  {
    title: 'Total Subcontractor Cost',
    dataIndex: 'total_subcontractor_cost',
    key: 'total_subcontractor_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_subcontractor_cost, b.total_subcontractor_cost),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'total_subcontractor_cost'),
    render: currency,
  },
  {
    title: 'Base Total',
    dataIndex: 'base_total',
    key: 'base_total',
    width: 130,
    sorter: (a, b) => numberSorter(a.base_total, b.base_total),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'base_total'),
    render: currency,
  },
  {
    title: 'Base Mark-up Type',
    dataIndex: 'base_mark_up_type',
    key: 'base_mark_up_type',
    width: 130,
    sorter: (a, b) => stringSorter(a.base_mark_up_type, b.base_mark_up_type),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    editionConfig: {
      tag: 'select',
      options: MarkupTypes,
      optionRenderer: identity,
    },
    formattingRule: undefined,
  },
  {
    title: 'Base Mark-up Value',
    dataIndex: 'base_mark_up_value',
    key: 'base_mark_up_value',
    width: 130,
    sorter: (a, b) => numberSorter(a.base_mark_up_value, b.base_mark_up_value),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'base_mark_up_value'),
    render: currency,
  },
  {
    title: 'Base Mark-up %',
    dataIndex: 'base_mark_up_percent',
    key: 'base_mark_up_percent',
    width: 130,
    sorter: (a, b) => stringSorter(a.base_mark_up_percent, b.base_mark_up_percent),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    groupingRule: (_) => '15', // TODO: wrong data
    render: percent,
  },
  {
    title: 'Net Total',
    dataIndex: 'net_total',
    key: 'net_total',
    width: 130,
    sorter: (a, b) => numberSorter(a.net_total, b.net_total),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'net_total'),
    render: currency,
  },
  {
    title: 'Net Mark-up Type',
    dataIndex: 'net_mark_up_type',
    key: 'net_mark_up_type',
    width: 130,
    sorter: (a, b) => stringSorter(a.net_mark_up_type, b.net_mark_up_type),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    editionConfig: {
      tag: 'select',
      options: NetMarkupTypes,
      optionRenderer: identity,
    },
    formattingRule: undefined,
  },
  {
    title: 'Net Mark-up Value',
    dataIndex: 'net_mark_up_value',
    key: 'net_mark_up_value',
    width: 130,
    sorter: (a, b) => numberSorter(a.net_mark_up_value, b.net_mark_up_value),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Net Mark-up %',
    dataIndex: 'net_mark_up_percent',
    key: 'net_mark_up_percent',
    width: 130,
    sorter: (a, b) => stringSorter(a.net_mark_up_percent, b.net_mark_up_percent),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    groupingRule: (_) => '5', // TODO
    render: percent,
  },
  {
    title: 'Gross Total',
    dataIndex: 'gross_total',
    key: 'gross_total',
    width: 130,
    sorter: (a, b) => numberSorter(a.gross_total, b.gross_total),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'gross_total'),
    render: currency,
  },
  {
    title: 'Inspection allowance',
    dataIndex: 'inspection_allowance',
    key: 'inspection_allowance',
    width: 130,
    sorter: (a, b) => numberSorter(a.inspection_allowance, b.inspection_allowance),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Replacement allowance',
    dataIndex: 'replacement_allowance',
    key: 'replacement_allowance',
    width: 130,
    sorter: (a, b) => numberSorter(a.replacement_allowance, b.replacement_allowance),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Servicing allowance',
    dataIndex: 'servicing_allowance',
    key: 'servicing_allowance',
    width: 130,
    sorter: (a, b) => numberSorter(a.servicing_allowance, b.servicing_allowance),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Labour CO2 Rate',
    dataIndex: 'labour_co2_rate',
    key: 'labour_co2_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.labour_co2_rate, b.labour_co2_rate),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Material CO2 Rate',
    dataIndex: 'material_co2_rate',
    key: 'material_co2_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.material_co2_rate, b.material_co2_rate),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Plant CO2 Rate',
    dataIndex: 'plant_co2_rate',
    key: 'plant_co2_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.plant_co2_rate, b.plant_co2_rate),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Subcontract CO2 Rate',
    dataIndex: 'subcontract_co2_rate',
    key: 'subcontract_co2_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.subcontract_co2_rate, b.subcontract_co2_rate),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Total CO2 Rate',
    dataIndex: 'total_co2_rate',
    key: 'total_co2_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_co2_rate, b.total_co2_rate),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'total_co2_rate'),
    render: renderDecimal,
  },
  {
    title: 'Total Labour CO2',
    dataIndex: 'total_labour_co2',
    key: 'total_labour_co2',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_labour_co2, b.total_labour_co2),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Total Material CO2',
    dataIndex: 'total_material_co2',
    key: 'total_material_co2',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_material_co2, b.total_material_co2),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'total_material_co2'),
    render: renderDecimal,
  },
  {
    title: 'Total Plant CO2',
    dataIndex: 'total_plant_co2',
    key: 'total_plant_co2',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_plant_co2, b.total_plant_co2),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'total_plant_co2'),
    render: renderDecimal,
  },
  {
    title: 'Total Subcontract CO2',
    dataIndex: 'total_subcontract_co2',
    key: 'total_subcontract_co2',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_subcontract_co2, b.total_subcontract_co2),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'total_subcontract_co2'),
    render: renderDecimal,
  },
  {
    title: 'Total CO2',
    dataIndex: 'total_co2',
    key: 'total_co2',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_co2, b.total_co2),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'total_co2'),
    render: renderDecimal,
  },
  {
    title: 'Cost - Least',
    dataIndex: 'cost___least',
    key: 'cost___least',
    width: 130,
    sorter: (a, b) => numberSorter(a.cost___least, b.cost___least),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'cost___least'),
    render: currency,
  },
  {
    title: 'Cost - Likely',
    dataIndex: 'cost___likely',
    key: 'cost___likely',
    width: 130,
    sorter: (a, b) => numberSorter(a.cost___likely, b.cost___likely),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'cost___likely'),
    render: currency,
  },
  {
    title: 'Cost - Most',
    dataIndex: 'cost___most',
    key: 'cost___most',
    width: 130,
    sorter: (a, b) => numberSorter(a.cost___most, b.cost___most),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'cost___most'),
    render: currency,
  },
  {
    title: 'CO2 - Least',
    dataIndex: 'co2___least',
    key: 'co2___least',
    width: 130,
    sorter: (a, b) => numberSorter(a.co2___least, b.co2___least),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'co2___least'),
    render: renderDecimal,
  },
  {
    title: 'CO2 - Likely',
    dataIndex: 'co2___likely',
    key: 'co2___likely',
    width: 130,
    sorter: (a, b) => numberSorter(a.co2___likely, b.co2___likely),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'co2___likely'),
    render: renderDecimal,
  },
  {
    title: 'CO2 - Most',
    dataIndex: 'co2___most',
    key: 'co2___most',
    width: 130,
    sorter: (a, b) => numberSorter(a.co2___most, b.co2___most),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'co2___most'),
    render: renderDecimal,
  },
  {
    title: 'Awarded Labour Rate',
    dataIndex: 'awarded_labour_rate',
    key: 'awarded_labour_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.awarded_labour_rate, b.awarded_labour_rate),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Awarded Material Rate',
    dataIndex: 'awarded_material_rate',
    key: 'awarded_material_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.awarded_material_rate, b.awarded_material_rate),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Awarded Plant Rate',
    dataIndex: 'awarded_plant_rate',
    key: 'awarded_plant_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.awarded_plant_rate, b.awarded_plant_rate),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Awarded Subcontract Rate',
    dataIndex: 'awarded_subcontract_rate',
    key: 'awarded_subcontract_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.awarded_subcontract_rate, b.awarded_subcontract_rate),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Awarded Total Rate',
    dataIndex: 'awarded_total_rate',
    key: 'awarded_total_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.awarded_total_rate, b.awarded_total_rate),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Rate Difference',
    dataIndex: 'rate_difference',
    key: 'rate_difference',
    width: 130,
    sorter: (a, b) => numberSorter(a.rate_difference, b.rate_difference),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Awarded Labour Cost',
    dataIndex: 'awarded_labour_cost',
    key: 'awarded_labour_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.awarded_labour_cost, b.awarded_labour_cost),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'awarded_labour_cost'),
    render: currency,
  },
  {
    title: 'Awarded Material Cost',
    dataIndex: 'awarded_material_cost',
    key: 'awarded_material_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.awarded_material_cost, b.awarded_material_cost),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'awarded_material_cost'),
    render: currency,
  },
  {
    title: 'Awarded Plant Cost',
    dataIndex: 'awarded_plant_cost',
    key: 'awarded_plant_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.awarded_plant_cost, b.awarded_plant_cost),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'awarded_plant_cost'),
    render: currency,
  },
  {
    title: 'Awarded Subcontract Cost',
    dataIndex: 'awarded_subcontract_cost',
    key: 'awarded_subcontract_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.awarded_subcontract_cost, b.awarded_subcontract_cost),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'awarded_subcontract_cost'),
    render: currency,
  },
  {
    title: 'Awarded Total Cost',
    dataIndex: 'awarded_total_cost',
    key: 'awarded_total_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.awarded_total_cost, b.awarded_total_cost),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'awarded_total_cost'),
    render: currency,
  },
  {
    title: 'Total Cost Difference',
    dataIndex: 'total_cost_difference',
    key: 'total_cost_difference',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_cost_difference, b.total_cost_difference),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
    groupingRule: (children) => numberSumming(children, 'total_cost_difference'),
    render: currency,
  },
  {
    title: 'Estimator',
    dataIndex: 'estimator',
    key: 'estimator',
    width: 130,
    sorter: (a, b) => stringSorter(a.estimator, b.estimator),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Creator',
    dataIndex: 'creator',
    key: 'creator',
    width: 130,
    sorter: (a, b) => stringSorter(a.creator, b.creator),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Last Editor',
    dataIndex: 'last_editor',
    key: 'last_editor',
    width: 130,
    sorter: (a, b) => stringSorter(a.last_editor, b.last_editor),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Creation Date',
    dataIndex: 'creation_date',
    key: 'creation_date',
    width: 130,
    sorter: (a, b) => stringSorter(a.creation_date, b.creation_date),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Last Edited',
    dataIndex: 'last_edited',
    key: 'last_edited',
    width: 130,
    sorter: (a, b) => stringSorter(a.last_edited, b.last_edited),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'EA WBS Code',
    dataIndex: 'ea_wbs_code',
    key: 'ea_wbs_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.ea_wbs_code, b.ea_wbs_code),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'ICMS Code',
    dataIndex: 'icms_code',
    key: 'icms_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.icms_code, b.icms_code),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'NRM1 Code',
    dataIndex: 'nrm1_code',
    key: 'nrm1_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.nrm1_code, b.nrm1_code),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'RMM Code',
    dataIndex: 'rmm_code',
    key: 'rmm_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.rmm_code, b.rmm_code),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'HMM Code',
    dataIndex: 'hmm_code',
    key: 'hmm_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.hmm_code, b.hmm_code),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'CESMM4 Code',
    dataIndex: 'cesmm4_code',
    key: 'cesmm4_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.cesmm4_code, b.cesmm4_code),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Uniclass Ss Code',
    dataIndex: 'uniclass_ss_code',
    key: 'uniclass_ss_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.uniclass_ss_code, b.uniclass_ss_code),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Uniclass Pr Code',
    dataIndex: 'uniclass_pr_code',
    key: 'uniclass_pr_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.uniclass_pr_code, b.uniclass_pr_code),
    sortDirections: ['ascend', 'descend'],
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Is assigned to WP',
    dataIndex: 'is_assigned_to_wp',
    key: 'is_assigned_to_wp',
    width: 130,
    sorter: (a, b) => stringSorter(a.is_assigned_to_wp, b.is_assigned_to_wp),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Is assigned to Activity',
    dataIndex: 'is_assigned_to_activity',
    key: 'is_assigned_to_activity',
    width: 130,
    sorter: (a, b) => stringSorter(a.is_assigned_to_activity, b.is_assigned_to_activity),
    sortDirections: ['ascend', 'descend'],
    editable: false,
    formattingRule: undefined,
  },
]

export const BoQResourcesColumns: ExtendedColumnType[] = [
  {
    title: 'Title',
    dataIndex: 'title__1',
    key: 'title__1',
    width: 350,
    sorter: (a, b) => stringSorter(a.title, b.title),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'CESMM 4 Code',
    dataIndex: 'cesmm4_code',
    key: 'cesmm4_code',
    width: 200,
    sorter: (a, b) => stringSorter(a.cesmm4_code, b.cesmm4_code),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: false,
    formattingRule: undefined,
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    width: 130,
    sorter: (a, b) => stringSorter(a.note, b.note),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Manufacture',
    dataIndex: 'manufacture',
    key: 'manufacture',
    width: 130,
    sorter: (a, b) => stringSorter(a.manufacture, b.manufacture),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Product Code',
    dataIndex: 'product_code',
    key: 'product_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.product_code, b.product_code),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: 130,
    sorter: (a, b) => stringSorter(a.type, b.type),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Company',
    dataIndex: 'company',
    key: 'company',
    width: 130,
    sorter: (a, b) => stringSorter(a.company, b.company),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'BoQ Qty',
    dataIndex: 'boq_qty',
    key: 'boq_qty',
    width: 130,
    sorter: (a, b) => stringSorter(a.boq_qty, b.boq_qty),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'BoQ Unit',
    dataIndex: 'boq_unit',
    key: 'boq_unit',
    width: 130,
    sorter: (a, b) => stringSorter(a.boq_unit, b.boq_unit),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    width: 130,
    sorter: (a, b) => numberSorter(a.currency, b.currency),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Productivity',
    dataIndex: 'productivity',
    key: 'productivity',
    width: 130,
    sorter: (a, b) => stringSorter(a.productivity, b.productivity),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Quantity/Unit',
    dataIndex: 'quantity_unit',
    key: 'quantity_unit',
    width: 130,
    sorter: (a, b) => stringSorter(a.quantity_unit, b.quantity_unit),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Quantity/Unit Formula',
    dataIndex: 'quantity_unit_formula',
    key: 'quantity_unit_formula',
    width: 130,
    sorter: (a, b) => stringSorter(a.quantity_unit_formula, b.quantity_unit_formula),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Resource Qty 1',
    dataIndex: 'resource_qty_1',
    key: 'resource_qty_1',
    width: 130,
    sorter: (a, b) => numberSorter(a.resource_qty_1, b.resource_qty_1),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Unit 1',
    dataIndex: 'unit_1',
    key: 'unit_1',
    width: 130,
    sorter: (a, b) => stringSorter(a.unit_1, b.unit_1),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Resource Qty 2',
    dataIndex: 'resource_qty_2',
    key: 'resource_qty_2',
    width: 130,
    sorter: (a, b) => stringSorter(a.resource_qty_2, b.resource_qty_2),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Unit 2',
    dataIndex: 'unit_2',
    key: 'unit_2',
    width: 130,
    sorter: (a, b) => numberSorter(a.unit_2, b.unit_2),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Total Manhours',
    dataIndex: 'total_manhours',
    key: 'total_manhours',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_manhours, b.total_manhours),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Total Plant Hours',
    dataIndex: 'total_plant_hours',
    key: 'total_plant_hours',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_plant_hours, b.total_plant_hours),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Exchange Rate',
    dataIndex: 'exchange_rate',
    key: 'exchange_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.exchange_rate, b.exchange_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Resource Rate',
    dataIndex: 'resource_rate',
    key: 'resource_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.resource_rate, b.resource_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'CO2 Rate',
    dataIndex: 'co2_rate',
    key: 'co2_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.co2_rate, b.co2_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Gang Size',
    dataIndex: 'gang_size',
    key: 'gang_size',
    width: 130,
    sorter: (a, b) => numberSorter(a.gang_size, b.gang_size),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Waste Factor',
    dataIndex: 'waste_factor',
    key: 'waste_factor',
    width: 130,
    sorter: (a, b) => numberSorter(a.waste_factor, b.waste_factor),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Location Factor',
    dataIndex: 'location_factor',
    key: 'location_factor',
    width: 130,
    sorter: (a, b) => numberSorter(a.location_factor, b.location_factor),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Factor 1',
    dataIndex: 'factor_1',
    key: 'factor_1',
    width: 130,
    sorter: (a, b) => numberSorter(a.factor_1, b.factor_1),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Factor 2',
    dataIndex: 'factor_2',
    key: 'factor_2',
    width: 130,
    sorter: (a, b) => numberSorter(a.factor_2, b.factor_2),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Build-up Rate',
    dataIndex: 'build_up_rate',
    key: 'build_up_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.build_up_rate, b.build_up_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'CO2 Build-up Rate',
    dataIndex: 'co2_build_up_rate',
    key: 'co2_build_up_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.co2_build_up_rate, b.co2_build_up_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'BoQ Rate',
    dataIndex: 'boq_rate',
    key: 'boq_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.boq_rate, b.boq_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'CO2 BoQ Rate',
    dataIndex: 'co2_boq_rate',
    key: 'co2_boq_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.co2_boq_rate, b.co2_boq_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Total Build-up Cost',
    dataIndex: 'total_build_up_cost',
    key: 'total_build_up_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_build_up_cost, b.total_build_up_cost),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    width: 130,
    sorter: (a, b) => numberSorter(a.currency, b.currency),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Total CO2 Build-up Cost',
    dataIndex: 'total_co2_build_up_cost',
    key: 'total_co2_build_up_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_co2_build_up_cost, b.total_co2_build_up_cost),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Inspection allowance',
    dataIndex: 'inspection_allowance',
    key: 'inspection_allowance',
    width: 130,
    sorter: (a, b) => numberSorter(a.inspection_allowance, b.inspection_allowance),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Replacement allowance',
    dataIndex: 'replacement_allowance',
    key: 'replacement_allowance',
    width: 130,
    sorter: (a, b) => numberSorter(a.replacement_allowance, b.replacement_allowance),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Servicing allowance',
    dataIndex: 'servicing_allowance',
    key: 'servicing_allowance',
    width: 130,
    sorter: (a, b) => numberSorter(a.servicing_allowance, b.servicing_allowance),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Creator',
    dataIndex: 'creator',
    key: 'creator',
    width: 130,
    sorter: (a, b) => stringSorter(a.creator, b.creator),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Last Editor',
    dataIndex: 'last_editor',
    key: 'last_editor',
    width: 130,
    sorter: (a, b) => stringSorter(a.last_editor, b.last_editor),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Creation Date',
    dataIndex: 'creation_date',
    key: 'creation_date',
    width: 130,
    sorter: (a, b) => stringSorter(a.creation_date, b.creation_date),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Last Edited',
    dataIndex: 'last_edited',
    key: 'last_edited',
    width: 130,
    sorter: (a, b) => stringSorter(a.last_edited, b.last_edited),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
]

export const DefaultVisibleBoQResourcesColumns = filterColumns(BoQResourcesColumns, [
  'note',
  'manufacture',
  'company',
  'last_edited',
  'last_editor',
  'creation_date',
  'creator',
  'servicing_allowance',
  'replacement_allowance',
  'inspection_allowance',
])

export const BoQTakeoffsColumns: ExtendedColumnType[] = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    width: 260,
    sorter: (a, b) => stringSorter(a.title, b.title),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    width: 130,
    sorter: (a, b) => stringSorter(a.note, b.note),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Take-off Type',
    dataIndex: 'take_off_type',
    key: 'take_off_type',
    width: 130,
    sorter: (a, b) => stringSorter(a.take_off_type, b.take_off_type),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Source Document Name',
    dataIndex: 'source_document_name',
    key: 'source_document_name',
    width: 300,
    sorter: (a, b) => stringSorter(a.source_document_name, b.source_document_name),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Qty 1 type',
    dataIndex: 'qty_1_type',
    key: 'qty_1_type',
    width: 130,
    sorter: (a, b) => stringSorter(a.qty_1_type, b.qty_1_type),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Qty 1 value',
    dataIndex: 'qty_1_value',
    key: 'qty_1_value',
    width: 130,
    sorter: (a, b) => numberSorter(a.qty_1_value, b.qty_1_value),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Unit 1',
    dataIndex: 'unit_1',
    key: 'unit_1',
    width: 130,
    sorter: (a, b) => stringSorter(a.unit_1, b.unit_1),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Qty 2 type',
    dataIndex: 'qty_2_type',
    key: 'qty_2_type',
    width: 130,
    sorter: (a, b) => stringSorter(a.qty_2_type, b.qty_2_type),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Qty 2 value',
    dataIndex: 'qty_2_value',
    key: 'qty_2_value',
    width: 130,
    sorter: (a, b) => numberSorter(a.qty_2_value, b.qty_2_value),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Unit 2',
    dataIndex: 'unit_2',
    key: 'unit_2',
    width: 130,
    sorter: (a, b) => stringSorter(a.unit_2, b.unit_2),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Qty 3 type',
    dataIndex: 'qty_3_type',
    key: 'qty_3_type',
    width: 130,
    sorter: (a, b) => stringSorter(a.qty_3_type, b.qty_3_type),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Qty 3 value',
    dataIndex: 'qty_3_value',
    key: 'qty_3_value',
    width: 130,
    sorter: (a, b) => numberSorter(a.qty_3_value, b.qty_3_value),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Unit 3',
    dataIndex: 'unit_3',
    key: 'unit_3',
    width: 130,
    sorter: (a, b) => stringSorter(a.unit_3, b.unit_3),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Selected Qty',
    dataIndex: 'selected_qty',
    key: 'selected_qty',
    width: 130,
    sorter: (a, b) => stringSorter(a.selected_qty, b.selected_qty),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Qty type',
    dataIndex: 'qty_type',
    key: 'qty_type',
    width: 130,
    sorter: (a, b) => stringSorter(a.qty_type, b.qty_type),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    width: 130,
    sorter: (a, b) => stringSorter(a.quantity, b.quantity),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Factor 1',
    dataIndex: 'factor_1',
    key: 'factor_1',
    width: 130,
    sorter: (a, b) => numberSorter(a.factor_1, b.factor_1),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Factor 2',
    dataIndex: 'factor_2',
    key: 'factor_2',
    width: 130,
    sorter: (a, b) => numberSorter(a.factor_2, b.factor_2),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Factor 3',
    dataIndex: 'factor_3',
    key: 'factor_3',
    width: 130,
    sorter: (a, b) => numberSorter(a.factor_3, b.factor_3),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Final Qty',
    dataIndex: 'final_qty',
    key: 'final_qty',
    width: 130,
    sorter: (a, b) => numberSorter(a.final_qty, b.final_qty),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    width: 130,
    sorter: (a, b) => stringSorter(a.unit, b.unit),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Creator',
    dataIndex: 'creator',
    key: 'creator',
    width: 130,
    sorter: (a, b) => stringSorter(a.creator, b.creator),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Last Editor',
    dataIndex: 'last_editor',
    key: 'last_editor',
    width: 130,
    sorter: (a, b) => stringSorter(a.last_editor, b.last_editor),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Creation Date',
    dataIndex: 'creation_date',
    key: 'creation_date',
    width: 130,
    sorter: (a, b) => stringSorter(a.creation_date, b.creation_date),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Last Edited',
    dataIndex: 'last_edited',
    key: 'last_edited',
    width: 130,
    sorter: (a, b) => stringSorter(a.last_edited, b.last_edited),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
]

export const DefaultVisibleBoQTakeoffsColumns = filterColumns(BoQTakeoffsColumns, [
  'note',
  'last_edited',
  'last_editor',
  'creation_date',
  'creator',
])

export const BoQWorkPackagesColumns: ExtendedColumnType[] = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    width: 130,
    sorter: (a, b) => stringSorter(a.title, b.title),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    width: 130,
    sorter: (a, b) => stringSorter(a.note, b.note),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'BoQ Qty',
    dataIndex: 'boq_qty',
    key: 'boq_qty',
    width: 130,
    sorter: (a, b) => numberSorter(a.boq_qty, b.boq_qty),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'BoQ Unit',
    dataIndex: 'boq_unit',
    key: 'boq_unit',
    width: 130,
    sorter: (a, b) => stringSorter(a.boq_unit, b.boq_unit),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Resource Qty',
    dataIndex: 'resource_qty',
    key: 'resource_qty',
    width: 130,
    sorter: (a, b) => numberSorter(a.resource_qty, b.resource_qty),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Resource Unit',
    dataIndex: 'resource_unit',
    key: 'resource_unit',
    width: 130,
    sorter: (a, b) => stringSorter(a.resource_unit, b.resource_unit),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'BoQ Rate',
    dataIndex: 'boq_rate',
    key: 'boq_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.boq_rate, b.boq_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Resource Rate',
    dataIndex: 'resource_rate',
    key: 'resource_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.resource_rate, b.resource_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Total Cost',
    dataIndex: 'total_cost',
    key: 'total_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_cost, b.total_cost),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 130,
    sorter: (a, b) => stringSorter(a.id, b.id),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    width: 130,
    sorter: (a, b) => stringSorter(a.title, b.title),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    width: 130,
    sorter: (a, b) => stringSorter(a.note, b.note),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    width: 130,
    sorter: (a, b) => stringSorter(a.country, b.country),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    width: 130,
    sorter: (a, b) => stringSorter(a.currency, b.currency),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'No of bids',
    dataIndex: 'no_of_bids',
    key: 'no_of_bids',
    width: 130,
    sorter: (a, b) => numberSorter(a.no_of_bids, b.no_of_bids),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Awarded bid',
    dataIndex: 'awarded_bid',
    key: 'awarded_bid',
    width: 130,
    sorter: (a, b) => stringSorter(a.awarded_bid, b.awarded_bid),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Awarded BoQ Rate',
    dataIndex: 'awarded_boq_rate',
    key: 'awarded_boq_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.awarded_boq_rate, b.awarded_boq_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Rate Difference',
    dataIndex: 'rate_difference',
    key: 'rate_difference',
    width: 130,
    sorter: (a, b) => numberSorter(a.rate_difference, b.rate_difference),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Awarded Total Cost',
    dataIndex: 'awarded_total_cost',
    key: 'awarded_total_cost',
    width: 130,
    sorter: (a, b) => numberSorter(a.awarded_total_cost, b.awarded_total_cost),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Total Cost Difference',
    dataIndex: 'total_cost_difference',
    key: 'total_cost_difference',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_cost_difference, b.total_cost_difference),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Creator',
    dataIndex: 'creator',
    key: 'creator',
    width: 130,
    sorter: (a, b) => stringSorter(a.creator, b.creator),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Last Editor',
    dataIndex: 'last_editor',
    key: 'last_editor',
    width: 130,
    sorter: (a, b) => stringSorter(a.last_editor, b.last_editor),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Creation Date',
    dataIndex: 'creation_date',
    key: 'creation_date',
    width: 130,
    sorter: (a, b) => stringSorter(a.creation_date, b.creation_date),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Last Edited',
    dataIndex: 'last_edited',
    key: 'last_edited',
    width: 130,
    sorter: (a, b) => stringSorter(a.last_edited, b.last_edited),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
]

export const BoQActivitiesColumns: ExtendedColumnType[] = [
  {
    title: 'WBS Code',
    dataIndex: 'wbs_code',
    key: 'wbs_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.wbs_code, b.wbs_code),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    width: 420,
    sorter: (a, b) => stringSorter(a.title, b.title),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    width: 130,
    sorter: (a, b) => stringSorter(a.note, b.note),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 130,
    sorter: (a, b) => stringSorter(a.description, b.description),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    width: 130,
    sorter: (a, b) => stringSorter(a.location, b.location),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 130,
    sorter: (a, b) => stringSorter(a.status, b.status),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    width: 130,
    sorter: (a, b) => stringSorter(a.currency, b.currency),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    width: 130,
    sorter: (a, b) => stringSorter(a.unit, b.unit),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    width: 130,
    sorter: (a, b) => numberSorter(a.quantity, b.quantity),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Total Rate',
    dataIndex: 'total_rate',
    key: 'total_rate',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_rate, b.total_rate),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Gross Total',
    dataIndex: 'gross_total',
    key: 'gross_total',
    width: 130,
    sorter: (a, b) => numberSorter(a.gross_total, b.gross_total),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 130,
    sorter: (a, b) => stringSorter(a.id, b.id),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Activity Code',
    dataIndex: 'activity_code',
    key: 'activity_code',
    width: 130,
    sorter: (a, b) => stringSorter(a.activity_code, b.activity_code),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Qty split',
    dataIndex: 'qty_split',
    key: 'qty_split',
    width: 130,
    sorter: (a, b) => stringSorter(a.qty_split, b.qty_split),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: percent
  },
  {
    title: 'Qty Value',
    dataIndex: 'qty_value',
    key: 'qty_value',
    width: 130,
    sorter: (a, b) => numberSorter(a.qty_value, b.qty_value),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: renderDecimal,
  },
  {
    title: 'Total Value',
    dataIndex: 'total_value',
    key: 'total_value',
    width: 130,
    sorter: (a, b) => numberSorter(a.total_value, b.total_value),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
    render: currency,
  },
  {
    title: 'Start Date',
    dataIndex: 'start_date',
    key: 'start_date',
    width: 130,
    sorter: (a, b) => stringSorter(a.start_date, b.start_date),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'End Date',
    dataIndex: 'end_date',
    key: 'end_date',
    width: 130,
    sorter: (a, b) => stringSorter(a.end_date, b.end_date),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Creator',
    dataIndex: 'creator',
    key: 'creator',
    width: 130,
    sorter: (a, b) => stringSorter(a.creator, b.creator),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Last Editor',
    dataIndex: 'last_editor',
    key: 'last_editor',
    width: 130,
    sorter: (a, b) => stringSorter(a.last_editor, b.last_editor),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Creation Date',
    dataIndex: 'creation_date',
    key: 'creation_date',
    width: 130,
    sorter: (a, b) => stringSorter(a.creation_date, b.creation_date),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
  {
    title: 'Last Edited',
    dataIndex: 'last_edited',
    key: 'last_edited',
    width: 130,
    sorter: (a, b) => stringSorter(a.last_edited, b.last_edited),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    editable: true,
    formattingRule: undefined,
  },
]
