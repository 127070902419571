import { Action } from 'redux'
import { Project } from '../../model/Projects'

export const ADD_PROJECT = 'ADD_PROJECT'

export interface AddProjectAction extends Action<string> {
  project: Project
}

export const addProject = (project: Project) => {
  return {
    type: ADD_PROJECT,
    project,
  }
}
