import React, { useState } from 'react'
import { ForgeViewer, NodesVisibility, SelectedNodes } from '../forge-viewer/ForgeViewer'
import { Drawings2DFiles, Drawings2DFilesIDs, Models3DFiles, TakeOffFiles } from '../../../data/take-off/TakeOff.data'
import SterlingTable, { Entry } from '../../basic/table/sterling-table/SterlingTable'
import { TakeOffTableColumnsWithVisibilityManagement } from './columns/TakeOffTable.columns'
import { parseTakeOffTableDataFiles } from './helpers/TakeOffTableDataParser'
import { convertToTableDetailsItem } from './helpers/TakeOffConverter'
import { ThreePartsLayout } from '../../basic/layout/three-parts-layout/ThreePartsLayout'
import { TakeOffDetailsTableColumns } from './columns/TakeOffDetailsTable.columns'
import { TakeOffTableHeader } from './headers/TakeOffTableHeader'
import { TakeOffDetailsTableHeader } from './headers/TakeOffDetailsTableHeader'
import { Model } from '../forge-viewer/ForgeViewer.model'
import { setsDifference, setsIntersection, setsUnion } from '../../../utils/collections/SetUtils'
import { identity } from '../../../utils/FunctionalUtils'
import { SubstructureTakeOffData } from '../../../data/take-off/ModelSubstructure.data'
import { arraysSum } from '../../../utils/collections/ArraysUtils'
import { SuperstructureTakeOffData } from '../../../data/take-off/ModelSuperstructure.data'
import { ExteriorTakeOffData } from '../../../data/take-off/ModelExterior.data'
import TableSelectionToolbar from '../../basic/table/table-selection-toolbar/TableSelectionToolbar'
import { TakeOffSelectionToolbar } from './selection-toolbars/TakeOffSelectionToolbar'
import { MeasurementsReader } from '../forge-viewer/controls/MeasurementsReader'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../context/reducer'
import { AddTakeOff, AddTakeOffDetailsItem, UpdateTakeOff } from '../../../context/take-off/TakeOff.actions'
import { MEPTakeOffData } from '../../../data/take-off/ModelMEP.data'
import { FFETakeOffData } from '../../../data/take-off/ModelFFE.data'
import { InternalTakeOffData } from '../../../data/take-off/ModelInternal.data'
import BreadcrumbPanel from '../../basic/breadcrumb/BreadcrumbPanel'
import { setOpenWindowState } from '../../../context/open-windows/OpenWindows.actions'
import NewWindow from '../../basic/new-window/NewWindow'
import ReplacementPane from '../../basic/new-window/ReplacementPane'
import ProjectTemplate from '../project/ProjectTemplate'
import { newWindowHeight, newWindowWidth } from '../../../constants/sizes'

interface ModelEntry {
  model: Model
  itemsIds: Set<number>
  fileNodeNumber: number
}

export function TakeOff(): JSX.Element {
  const takeOff2DItems = useSelector((rootState: RootState) => rootState.takeOffs.takeOff2DItems)
  const dispatch = useDispatch()

  const getOpenWindow = (id: string) => (state: RootState) => state.openWindows.windows.get(id) || false
  const takeOffLeftWindowId = 'takeOffLeft'
  const istakeOffLeftWindowOpen = useSelector(getOpenWindow(takeOffLeftWindowId))
  const takeOffBottomWindowId = 'takeOffBottom'
  const istakeOffBottomWindowOpen = useSelector(getOpenWindow(takeOffBottomWindowId))

  const [measurementsReader, setMeasurementsReader] = useState<MeasurementsReader | undefined>(undefined)
  const [loadedModels, setLoadedModels] = useState<ModelEntry[]>([])

  const data = parseTakeOffTableDataFiles(TakeOffFiles, takeOff2DItems)
  const takeOffItems = arraysSum(
    SubstructureTakeOffData,
    SuperstructureTakeOffData,
    ExteriorTakeOffData,
    MEPTakeOffData,
    FFETakeOffData,
    InternalTakeOffData,
    Object.values(takeOff2DItems).flatMap(identity)
  )

  const [selectedItems, setSelectedItems] = useState<number[]>([])
  const [visibleNodes, setVisibleNodes] = useState<Set<number>>(
    setsDifference(new Set(flattenToIds(data)), new Set(Drawings2DFilesIDs))
  )
  const [visibleModelsUrns, setVisibleModelsUrns] = useState<string[]>(Models3DFiles.map((entry) => entry.urn))

  const showNode = (node: number) => {
    const alreadyVisible2DDrawing = Drawings2DFiles.find((f) => visibleNodes.has(f.id))
    const newVisible2DDrawing = Drawings2DFiles.find((f) => f.id === node)
    if (alreadyVisible2DDrawing) {
      const prevVisibleIdsWithout2DDrawing = setsDifference(visibleNodes, new Set([alreadyVisible2DDrawing.id]))
      const model3DForCurrentNode = loadedModels.find((e) => e.itemsIds.has(node))
      recalculateVisibility(
        setsUnion(
          prevVisibleIdsWithout2DDrawing,
          new Set([node]),
          model3DForCurrentNode ? new Set([model3DForCurrentNode.fileNodeNumber]) : new Set()
        )
      )
    } else if (newVisible2DDrawing) {
      recalculateVisibility(new Set([node]))
    } else {
      const model3DForCurrentNode = loadedModels.find((e) => e.itemsIds.has(node))
      recalculateVisibility(
        setsUnion(
          visibleNodes,
          new Set([node]),
          model3DForCurrentNode ? new Set([model3DForCurrentNode.fileNodeNumber]) : new Set()
        )
      )
    }
  }

  const hideNode = (node: number) => {
    recalculateVisibility(setsDifference(visibleNodes, new Set([node])))
  }

  const recalculateVisibility = (visibleIds: Set<number>): void => {
    const visibleDrawings2D = Drawings2DFiles.filter((f) => visibleIds.has(f.id))
    if (visibleDrawings2D.length === 1) {
      setVisibleModelsUrns(visibleDrawings2D.map((f) => f.urn))
    } else {
      setVisibleModelsUrns(Models3DFiles.filter((f) => visibleIds.has(f.id)).map((f) => f.urn))
    }
    const nodesInVisibleModels = new Set(
      loadedModels
        .filter((model) => visibleIds.has(model.fileNodeNumber))
        .flatMap((m) => [...m.itemsIds].concat(m.fileNodeNumber))
    )
    setVisibleNodes(setsIntersection(visibleIds, nodesInVisibleModels))
  }

  const calculateVisibleNodes = (): NodesVisibility[] => {
    const visibleModels = loadedModels.filter((model) => visibleNodes.has(model.fileNodeNumber))
    return visibleModels.map((model) => {
      return {
        model: model.model,
        nodeIds: [...setsIntersection(model.itemsIds, visibleNodes)],
      }
    })
  }

  const calculateSelectedNodes = (): SelectedNodes[] => {
    const selectedTakeOffs = takeOffItems.filter((t) => selectedItems.includes(t.id))
    const allSelectedAndVisibleTakeOffs = selectedTakeOffs.filter((t) => visibleNodes.has(t.id))

    return loadedModels
      .filter((m) => visibleModelsUrns.includes(m.model.documentUrn))
      .map((model) => {
        const selectedTakeOffsForModel = allSelectedAndVisibleTakeOffs.filter((t) => model.itemsIds.has(t.id))
        const childrenIds = selectedTakeOffsForModel.flatMap((t) => t.children).map((t) => t.id)
        return {
          model: model.model,
          nodeIds: childrenIds,
        }
      })
      .filter((selectedNodes) => selectedNodes.nodeIds.length > 0)
  }

  const table = (tableHeight: number) => {
    return (
      <NewWindow
        height={newWindowHeight}
        width={newWindowWidth}
        isOpen={istakeOffLeftWindowOpen}
        onPortalClose={() => dispatch(setOpenWindowState(takeOffLeftWindowId, false))}
        replacementPane={
          <ReplacementPane
            onHide={() => dispatch(setOpenWindowState(takeOffLeftWindowId, false))}
            onWindowShow={() => {}}
            style={{ width: '100%', height: 450, backgroundColor: '#fff' }}
          />
        }
      >
        <SterlingTable
          columns={TakeOffTableColumnsWithVisibilityManagement(
            visibleNodes,
            (id) => showNode(id),
            (id) => hideNode(id),
            [0, 1].concat(
              Object.values(takeOff2DItems)
                .flatMap(identity)
                .map((i) => i.id)
            )
          )}
          dataSource={data}
          tableId={'take-off-items'}
          tableHeight={tableHeight}
          onSelect={(rows) => setSelectedItems(rows.map((r) => r as number))}
          renderHeader={(props) => (
            <TakeOffTableHeader
              openWindow={() => dispatch(setOpenWindowState(takeOffLeftWindowId, true))}
              sterlingTableHeaderProps={props}
              onReset={() =>
                recalculateVisibility(setsDifference(new Set(flattenToIds(data)), new Set(Drawings2DFilesIDs)))
              }
            />
          )}
          renderSelectionToolbar={(props) => (
            <TakeOffSelectionToolbar
              baseProps={props}
              drawings2DTakeOffItems={Object.values(takeOff2DItems).flatMap(identity)}
              allTakeOffItems={takeOffItems}
              onAddTakeOffItem={(id) => dispatch(AddTakeOff(id))}
              onAddTakeOffDetailsItem={(id) => {
                const takeOffItem = Object.values(takeOff2DItems)
                  .flatMap(identity)
                  .find((i) => i.id === id)
                if (takeOffItem) {
                  dispatch(AddTakeOffDetailsItem(takeOffItem, measurementsReader?.getCurrentMeasurement()))
                }
              }}
            />
          )}
          onUpdate={(id, updates) => dispatch(UpdateTakeOff(id, updates))}
          expandIconColumnIndex={2}
          size={'middle'}
          defaultPagination={false}
        />
      </NewWindow>
    )
  }

  const viewer = () => {
    return (
      <ForgeViewer
        documents={Object.values(TakeOffFiles)
          .flatMap(identity)
          .map((doc) => {
            return {
              urn: doc.urn,
            }
          })}
        visibleModelsUrns={visibleModelsUrns}
        onModelLoaded={(model) => {
          const file = Object.values(TakeOffFiles)
            .flatMap((f) => f)
            .find((f) => f.urn === model.documentUrn)
          if (file) {
            if (model.root) {
              setLoadedModels(
                loadedModels.concat([
                  { model, itemsIds: new Set(flattenToIds(file.children || [])), fileNodeNumber: file.id },
                ])
              )
            } else {
              setLoadedModels(loadedModels.concat([{ model, itemsIds: new Set(), fileNodeNumber: file.id }]))
            }
          }
        }}
        nodesVisibility={calculateVisibleNodes()}
        onMeasurementReaderInitialized={(reader) => setMeasurementsReader(reader)}
        selectedNodes={calculateSelectedNodes()}
      />
    )
  }

  const takeOffDetailsTable = (rowHeight: number) => {
    const dataSource =
      selectedItems.length === 1
        ? takeOffItems.find((i) => i.id === selectedItems[0])?.children.map(convertToTableDetailsItem)
        : []

    const elementSelected = takeOffItems.find((i) => i.id === selectedItems[0])

    return (
      <NewWindow
        height={newWindowHeight}
        width={newWindowWidth}
        isOpen={istakeOffBottomWindowOpen}
        onPortalClose={() => dispatch(setOpenWindowState(takeOffBottomWindowId, false))}
        replacementPane={
          <ReplacementPane
            onHide={() => dispatch(setOpenWindowState(takeOffBottomWindowId, false))}
            onWindowShow={() => {}}
            style={{ width: '100%', height: 450, backgroundColor: '#fff' }}
          />
        }
      >
        <SterlingTable
          columns={TakeOffDetailsTableColumns}
          dataSource={dataSource || []}
          tableId={'take-off-details-items'}
          size={'small'}
          tableHeight={rowHeight * 4.5}
          renderHeader={(props) => (
            <TakeOffDetailsTableHeader
              sterlingTableHeaderProps={props}
              projectName={elementSelected !== undefined ? elementSelected.name : ''}
              projectCode={elementSelected !== undefined ? elementSelected.code : ''}
            />
          )}
          renderSelectionToolbar={(props) => <TableSelectionToolbar {...props} />}
        />
      </NewWindow>
    )
  }

  return (
    <>
      <ThreePartsLayout
        layoutId={'take-off'}
        leftSideComponent={table}
        rightSideComponent={viewer}
        bottomPanelComponent={takeOffDetailsTable}
      />
    </>
  )
}

const flattenToIds = (items: Entry[]): number[] => {
  const currentIds = items.map((e) => e.id)
  const childrenIds = items
    .filter((e) => e.children)
    .map((e) => e.children)
    .flatMap((es) => (es ? flattenToIds(es) : []))
  return currentIds.concat(childrenIds)
}
