import React, { Dispatch, Key, SetStateAction, useState, useEffect } from 'react'
import { Input, Button, Checkbox, Popover, Divider, Space, Tabs, Col, Select, Alert, Tooltip } from 'antd'
import { EyeOutlined, UnlockOutlined, LockTwoTone } from '@ant-design/icons/lib'
import { ColumnsLayout } from '../../../../model/ColumnLayouts'
import { RootState } from '../../../../context/reducer'
import { useSelector } from 'react-redux'
import { ColumnConfiguration } from '../../../../assets/icons/icons'
import './ColumnsLayoutManager.scss'

const { TabPane } = Tabs
const { Search } = Input

export interface ColumnsLayoutManagerProps {
  columns: any[]
  visibleColumns: Key[]
  lockers: any[]
  permanentlyLocked: any[]
  onApply: (checkedRows: Key[], checkedLockers: Key[]) => void
  onLayoutsSaved: (layout: ColumnsLayout) => void
  tableId: string
}

export default function ColumnsLayoutManager({
  columns,
  visibleColumns,
  lockers,
  onApply,
  permanentlyLocked,
  onLayoutsSaved,
  tableId,
}: ColumnsLayoutManagerProps) {
  const [visible, setVisible] = useState(false)
  const [isLayoutChecked, setIsLayoutChecked] = useState(false)
  const [isLayoutExist, setIsLayoutExist] = useState(false)
  const [name, setName] = useState('')

  const selectLayouts = (state: RootState) => state.columnsVisibility.layouts.get(tableId)
  const layouts = useSelector(selectLayouts) || []
  const builtinLayouts = useSelector((state: RootState) => state.columnsVisibility.builtinLayouts.get(tableId)) || []

  const apply = (checkedRows: Key[], checkedLockers: Key[]) => {
    const isNameExist = layouts.some((item) => item.name === name)
    setIsLayoutExist(isNameExist)

    if (!isNameExist && isLayoutChecked) {
      const layout = { name, visibleColumns: checkedRows, lockedColumns: checkedLockers }
      onLayoutsSaved(layout)
    }
    setName('')
    onApply(checkedRows, checkedLockers)
  }

  return (
    <Popover
      title={'Columns'}
      visible={visible}
      content={
        <Col>
          <Tabs style={{ width: 250 }}>
            <TabPane tab="Filter" key="1">
              <ColumnsLayoutManagerContent
                columns={columns}
                hidePopover={() => setVisible(false)}
                visibleColumns={visibleColumns}
                lockers={lockers}
                onApply={apply}
                permanentlyLocked={permanentlyLocked}
                onLayoutsSaved={onLayoutsSaved}
                tableId={tableId}
              >
                <Checkbox key={1} checked={isLayoutChecked} onChange={() => setIsLayoutChecked(!isLayoutChecked)}>
                  Save as layout
                </Checkbox>
                <Input
                  placeholder="Enter name of layout:"
                  disabled={!isLayoutChecked}
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  style={isLayoutExist ? { width: 250, color: 'red' } : { width: 250 }}
                />
                {isLayoutExist ? <Alert message="This name already exist" type="error" showIcon={true} /> : ''}
              </ColumnsLayoutManagerContent>
            </TabPane>
            {builtinLayouts.length > 0 && (
              <TabPane tab="Layouts" key="2">
                {
                  <ColumnsLayoutTabContent
                    columns={columns}
                    hidePopover={() => setVisible(false)}
                    onApply={onApply}
                    permanentlyLocked={permanentlyLocked}
                    onLayoutsSaved={onLayoutsSaved}
                    tableId={tableId}
                    layouts={builtinLayouts}
                  />
                }
              </TabPane>
            )}
            <TabPane tab="My Layouts" key="3">
              {
                <ColumnsLayoutTabContent
                  columns={columns}
                  hidePopover={() => setVisible(false)}
                  onApply={onApply}
                  permanentlyLocked={permanentlyLocked}
                  onLayoutsSaved={onLayoutsSaved}
                  tableId={tableId}
                  layouts={layouts}
                />
              }
            </TabPane>
          </Tabs>
        </Col>
      }
    >
      <Tooltip title="Column configuration">
        <Button type="text" shape="circle" icon={<ColumnConfiguration />} onClick={() => setVisible(true)} />
      </Tooltip>
    </Popover>
  )
}

interface ColumnsLayoutPopoverContentProps extends ColumnsLayoutManagerProps {
  hidePopover: Dispatch<SetStateAction<any>>
  children?: React.ReactNode
  isLocked?: boolean
}

function ColumnsLayoutManagerContent({
  columns,
  hidePopover,
  visibleColumns,
  lockers,
  onApply,
  permanentlyLocked,
  children,
  isLocked,
}: ColumnsLayoutPopoverContentProps): JSX.Element {
  const [checkedRows, setCheckedRows] = useState<Key[]>(visibleColumns)
  const [defaultCheckedRows, setDefaultCheckedRows] = useState<Key[]>(visibleColumns)
  const [checkedLockers, setCheckedLockers] = useState<Key[]>(lockers)
  const [defaultCheckedLockers, setDefaultCheckedLockers] = useState<Key[]>(lockers)
  const [colName, setColName] = useState<string | undefined>(undefined)

  const cancel = (e: any) => {
    hidePopover(e)
    setCheckedRows(visibleColumns)
    setCheckedLockers(lockers)
  }
  const apply = (e: React.MouseEvent<HTMLElement>) => {
    onApply(checkedRows, checkedLockers)
    hidePopover(e)
  }

  const changeCheckedColsState = (currentKey: Key) => () => {
    if (checkedRows.includes(currentKey)) {
      setCheckedRows(checkedRows.filter((key) => key !== currentKey))
    } else {
      setCheckedRows(checkedRows.concat(currentKey))
    }
  }

  const changeCheckedLockerState = (currentKey: Key) => {
    if (checkedLockers.includes(currentKey)) {
      setCheckedLockers(checkedLockers.filter((key) => key !== currentKey))
    } else if (checkedLockers.length < 5) {
      setCheckedLockers(checkedLockers.concat(currentKey))
    }
  }

  const restoreDefault = () => {
    setCheckedLockers(defaultCheckedLockers)
    setCheckedRows(defaultCheckedRows)
  }

  useEffect(() => {
    setCheckedRows(visibleColumns)
  }, [visibleColumns])
  useEffect(() => {
    setCheckedLockers(lockers)
  }, [lockers])

  return (
    <Space direction={'vertical'}>
      <Search placeholder="Search column" onChange={(e) => setColName(e.target.value)} style={{ width: 250 }} />
      <Button type="default" onClick={() => restoreDefault()}>
        Restore default
      </Button>
      <Space direction={'vertical'} style={{ width: 250, height: 400, overflow: 'scroll', overflowX: 'hidden' }}>
        {columns
          .filter((col) => col.title !== undefined && col.title !== '')
          .filter((col) => (colName ? col.title.toLowerCase().includes(colName.toLowerCase()) : true))
          .map((c) => (
            <Space key={c.key} direction={'horizontal'}>
              <Button
                style={{ outline: 'none' }}
                icon={
                  checkedLockers.includes(c.key) ? (
                    <LockTwoTone style={{ outline: 'none' }} />
                  ) : (
                    <UnlockOutlined style={{ outline: 'none' }} />
                  )
                }
                disabled={
                  permanentlyLocked.find((element) => element.key === c.key && element.fixed === 'left') || isLocked
                }
                key={c.key}
                shape="circle"
                size="small"
                onClick={() => changeCheckedLockerState(c.key)}
              />
              <Divider type="vertical" />
              <Checkbox
                disabled={
                  permanentlyLocked.find((element) => element.key === c.key && element.fixed === 'left') || isLocked
                }
                key={c.key}
                checked={checkedRows.includes(c.key)}
                onChange={changeCheckedColsState(c.key)}
              >
                {c.title}
              </Checkbox>
            </Space>
          ))}
      </Space>
      {children}
      <Space style={{ padding: '16px' }}>
        <Button onClick={cancel}>Cancel</Button>
        <Button onClick={apply} type={'primary'}>
          Apply
        </Button>
      </Space>
    </Space>
  )
}

interface ColumnsLayoutTabContentProps {
  columns: any[]
  hidePopover: Dispatch<SetStateAction<any>>
  layouts: ColumnsLayout[]
  tableId: string
  permanentlyLocked: any[]
  onApply: (checkedRows: Key[], checkedLockers: Key[]) => void
  onLayoutsSaved: (layout: ColumnsLayout) => void
}

function ColumnsLayoutTabContent({
  columns,
  hidePopover,
  onApply,
  permanentlyLocked,
  layouts,
  tableId,
  onLayoutsSaved,
}: ColumnsLayoutTabContentProps): JSX.Element {
  const [selectedLayoutObject, setSelectedLayoutObject] = useState()
  return (
    <>
      <div style={{ width: 250 }}>
        <Divider type="horizontal" style={{ margin: '5px 0px' }} />
        <Select
          defaultValue="Select layout"
          style={{ width: 250 }}
          onChange={(value) => setSelectedLayoutObject(layouts.find((item: any) => item.name === value))}
        >
          {layouts.map((item: any) => {
            return (
              <Select.Option key={item.name} value={item.name}>
                {item.name}
              </Select.Option>
            )
          })}
        </Select>
        <Divider type="horizontal" style={{ margin: '5px 0px' }} />
      </div>
      {selectedLayoutObject && (
        <ColumnsLayoutManagerContent
          columns={columns}
          hidePopover={hidePopover}
          visibleColumns={selectedLayoutObject.visibleColumns}
          lockers={selectedLayoutObject.lockedColumns}
          onApply={onApply}
          permanentlyLocked={permanentlyLocked}
          onLayoutsSaved={onLayoutsSaved}
          tableId={tableId}
          isLocked={true}
        />
      )}
    </>
  )
}
