import { Entry } from '../components/basic/table/sterling-table/SterlingTable'

export interface WorkPackagesEntry extends Entry {
  key: number
  id: number
  wp_code: string
  title: string
  note: string
  description?: string
  location?: string
  cost_items?: number[]
  invited?: number
  accepted?: number
  declined?: number
  status: string
  responsible?: string
  due_date: string
  awarded: string
  total_manhours?: number
  total_plant_hours?: number
  total_labour_cost?: number
  total_material_cost?: number
  total_plant_cost?: number
  total_subcontractor_cost?: number
  base_total?: number
  net_total?: number
  gross_total?: number
  awarded_labour_cost?: number
  awarded_material_cost?: number
  awarded_plant_cost?: number
  awarded_subcontract_cost?: number
  awarded_total_cost?: number
  total_cost_difference?: number
  currency: string
  creator?: string
  last_editor?: string
  creation_date?: string
  last_edited?: string
}

export const emptyWorkPackagesEntry: (key: number) => WorkPackagesEntry = (key) => {
  return {
    key: key + 100000,
    id: key + 100000,
    wp_code: '',
    title: '',
    note: '',
    description: '',
    location: '',
    status: '',
    responsible: '',
    due_date: '',
    awarded: '',
    currency: '',
    creator: '',
    last_editor: '',
    creation_date: '',
    last_edited: '',
    children: undefined,
    parent: undefined,
  }
}

export const WorkPackagesData: WorkPackagesEntry[] = [
  {
    id: 2,
    key: 2,
    children: undefined,
    parent: undefined,
    wp_code: 'WP.01',
    title: 'Excavation',
    note: '',
    invited: 4,
    accepted: 3,
    declined: 1,
    status: 'Awarded',
    responsible: 'Mark Brown',
    due_date: '06/04/2018',
    awarded: 'Groundworks UK',
    base_total: 35193.51,
    net_total: 0,
    gross_total: 0,
    awarded_total_cost: 40500.0,
    total_cost_difference: -5306.49,
    currency: 'GBP',
  },
  {
    id: 3,
    key: 3,
    children: undefined,
    parent: undefined,
    wp_code: 'WP.02',
    title: 'Piling',
    note: '',
    cost_items: [],
    invited: 7,
    accepted: 7,
    declined: 0,
    status: 'Awarded',
    responsible: 'Mark Brown',
    due_date: '06/04/2018',
    awarded: 'Pro-Piling UK',
    base_total: 1084975.15,
    net_total: 0,
    gross_total: 0,
    awarded_total_cost: 1000000.0,
    total_cost_difference: 84975.15,
    currency: 'GBP',
  },
  {
    id: 4,
    key: 4,
    children: undefined,
    parent: undefined,
    wp_code: 'WP.03',
    title: 'In-situ Concrete',
    note: '',
    cost_items: [],
    invited: 4,
    accepted: 4,
    declined: 0,
    status: 'Awarded',
    responsible: 'Mark Brown',
    due_date: '06/04/2018',
    awarded: 'Construct LND',
    base_total: 932066.43,
    net_total: 0,
    gross_total: 0,
    awarded_total_cost: 922700.0,
    total_cost_difference: 9366.43,
    currency: 'GBP',
  },
  {
    id: 5,
    key: 5,
    children: undefined,
    parent: undefined,
    wp_code: 'WP.04',
    title: 'Steel works',
    note: '',
    cost_items: [],
    invited: 3,
    accepted: 3,
    declined: 0,
    status: 'Under review',
    responsible: 'Mark Brown',
    due_date: '06/04/2018',
    awarded: '',
    base_total: 0,
    net_total: 0,
    gross_total: 0,
    awarded_total_cost: 0,
    total_cost_difference: 0,
    currency: '',
  },
  {
    id: 6,
    key: 6,
    children: undefined,
    parent: undefined,
    wp_code: 'WP.05',
    title: 'Cladding',
    note: '',
    cost_items: [],
    invited: 3,
    accepted: 3,
    declined: 0,
    status: 'Closed to Bid',
    responsible: 'James Black',
    due_date: '10/05/2018',
    awarded: '',
    base_total: 0,
    net_total: 0,
    gross_total: 0,
    awarded_total_cost: 0,
    total_cost_difference: 0,
    currency: '',
  },
  {
    id: 7,
    key: 7,
    children: undefined,
    parent: undefined,
    wp_code: 'WP.06',
    title: 'Precast',
    note: '',
    cost_items: [],
    invited: 5,
    accepted: 3,
    declined: 2,
    status: 'Closed to Bid',
    responsible: 'James Black',
    due_date: '10/05/2018',
    awarded: '',
    base_total: 0,
    net_total: 0,
    gross_total: 0,
    awarded_total_cost: 0,
    total_cost_difference: 0,
    currency: '',
  },
  {
    id: 8,
    key: 8,
    children: undefined,
    parent: undefined,
    wp_code: 'WP.07',
    title: 'Insulation',
    note: '',
    cost_items: [],
    invited: 11,
    accepted: 8,
    declined: 3,
    status: 'Open to Bid',
    responsible: 'James Black',
    due_date: '10/05/2018',
    awarded: '',
    base_total: 0,
    net_total: 0,
    gross_total: 0,
    awarded_total_cost: 0,
    total_cost_difference: 0,
    currency: '',
  },
  {
    id: 9,
    key: 9,
    children: undefined,
    parent: undefined,
    wp_code: 'WP.08',
    title: 'Roof',
    note: '',
    cost_items: [],
    invited: 6,
    accepted: 3,
    declined: 3,
    status: 'Open to Bid',
    responsible: 'Mark Brown',
    due_date: '10/05/2018',
    awarded: '',
    base_total: 0,
    net_total: 0,
    gross_total: 0,
    awarded_total_cost: 0,
    total_cost_difference: 0,
    currency: '',
  },
  {
    id: 10,
    key: 10,
    children: undefined,
    parent: undefined,
    wp_code: 'WP.09',
    title: 'Barriers',
    note: '',
    cost_items: [],
    invited: 5,
    accepted: 5,
    declined: 0,
    status: 'Upcoming',
    responsible: 'Mark Brown',
    due_date: '10/05/2018',
    awarded: '',
    base_total: 0,
    net_total: 0,
    gross_total: 0,
    awarded_total_cost: 0,
    total_cost_difference: 0,
    currency: '',
  },
  {
    id: 11,
    key: 11,
    children: undefined,
    parent: undefined,
    wp_code: 'WP.10',
    title: 'Windows',
    note: '',
    cost_items: [],
    invited: 4,
    accepted: 3,
    declined: 1,
    status: 'Upcoming',
    responsible: 'James Black',
    due_date: '10/05/2018',
    awarded: '',
    base_total: 0,
    net_total: 0,
    gross_total: 0,
    awarded_total_cost: 0,
    total_cost_difference: 0,
    currency: '',
  },
  {
    id: 12,
    key: 12,
    children: undefined,
    parent: undefined,
    wp_code: 'WP.11',
    title: 'Doors',
    note: '',
    cost_items: [],
    invited: 7,
    accepted: 7,
    declined: 0,
    status: 'Upcoming',
    responsible: 'James Black',
    due_date: '10/05/2018',
    awarded: '',
    base_total: 0,
    net_total: 0,
    gross_total: 0,
    awarded_total_cost: 0,
    total_cost_difference: 0,
    currency: '',
  },
  {
    id: 13,
    key: 13,
    children: undefined,
    parent: undefined,
    wp_code: 'WP.12',
    title: 'Partitions',
    note: '',
    cost_items: [],
    invited: 4,
    accepted: 4,
    declined: 0,
    status: 'Closed to Bid',
    responsible: 'James Black',
    due_date: '17/06/2018',
    awarded: '',
    base_total: 0,
    net_total: 0,
    gross_total: 0,
    awarded_total_cost: 0,
    total_cost_difference: 0,
    currency: '',
  },
  {
    id: 14,
    key: 14,
    children: undefined,
    parent: undefined,
    wp_code: 'WP.13',
    title: 'Walls',
    note: '',
    cost_items: [],
    invited: 3,
    accepted: 3,
    declined: 0,
    status: 'Closed to Bid',
    responsible: 'James Black',
    due_date: '17/06/2018',
    awarded: '',
    base_total: 0,
    net_total: 0,
    gross_total: 0,
    awarded_total_cost: 0,
    total_cost_difference: 0,
    currency: '',
  },
  {
    id: 15,
    key: 15,
    children: undefined,
    parent: undefined,
    wp_code: 'WP.14',
    title: 'Flooring',
    note: '',
    cost_items: [],
    invited: 7,
    accepted: 7,
    declined: 0,
    status: 'Open to Bid',
    responsible: 'Mark Brown',
    due_date: '17/06/2018',
    awarded: '',
    base_total: 0,
    net_total: 0,
    gross_total: 0,
    awarded_total_cost: 0,
    total_cost_difference: 0,
    currency: '',
  },
  {
    id: 16,
    key: 16,
    children: undefined,
    parent: undefined,
    wp_code: 'WP.15',
    title: 'Ceilings',
    note: '',
    cost_items: [],
    invited: 4,
    accepted: 4,
    declined: 0,
    status: 'Open to Bid',
    responsible: 'Mark Brown',
    due_date: '17/06/2018',
    awarded: '',
    base_total: 0,
    net_total: 0,
    gross_total: 0,
    awarded_total_cost: 0,
    total_cost_difference: 0,
    currency: '',
  },
  {
    id: 17,
    key: 17,
    children: undefined,
    parent: undefined,
    wp_code: 'WP.16',
    title: 'FFE',
    note: '',
    cost_items: [],
    invited: 7,
    accepted: 7,
    declined: 0,
    status: 'Upcoming',
    responsible: 'James Black',
    due_date: '21/07/2018',
    awarded: '',
    base_total: 0,
    net_total: 0,
    gross_total: 0,
    awarded_total_cost: 0,
    total_cost_difference: 0,
    currency: '',
  },
  {
    id: 18,
    key: 18,
    children: undefined,
    parent: undefined,
    wp_code: 'WP.17',
    title: 'Sanitary',
    note: '',
    cost_items: [],
    invited: 4,
    accepted: 4,
    declined: 0,
    status: 'Upcoming',
    responsible: 'James Black',
    due_date: '21/07/2018',
    awarded: '',
    base_total: 0,
    net_total: 0,
    gross_total: 0,
    awarded_total_cost: 0,
    total_cost_difference: 0,
    currency: '',
  },
  {
    id: 19,
    key: 19,
    children: undefined,
    parent: undefined,
    wp_code: 'WP.18',
    title: 'Lift',
    note: '',
    cost_items: [],
    invited: 5,
    accepted: 5,
    declined: 0,
    status: 'Upcoming',
    responsible: 'James Black',
    due_date: '21/07/2018',
    awarded: '',
    base_total: 0,
    net_total: 0,
    gross_total: 0,
    awarded_total_cost: 0,
    total_cost_difference: 0,
    currency: '',
  },
]
