import { TakeOffItem } from '../components/domain/take-off/helpers/TakeOffDataParser'
import { SubstructureTakeOffData } from './take-off/ModelSubstructure.data'
import { arrayItemsSum, arraysSum } from '../utils/collections/ArraysUtils'
import { renderDecimal } from '../utils/rendering/Rendering'

const CastInSituReinf = SubstructureTakeOffData.filter((t) => t.id === 4184)
const PileCapPC1 = SubstructureTakeOffData.filter((t) => t.id === 3631)
const PileCapPC3 = SubstructureTakeOffData.filter((t) => t.id === 3845)
const PileCapPC4 = SubstructureTakeOffData.filter((t) => t.id === 3736)
const PileCapPC2 = SubstructureTakeOffData.filter((t) => t.id === 3689)
const PileCaps = arraysSum(PileCapPC1, PileCapPC2, PileCapPC3, PileCapPC4)
const ReinfConcSlab = SubstructureTakeOffData.filter((t) => t.id === 4343)
const ReinfConcGround = SubstructureTakeOffData.filter((t) => t.id === 4367)

const qty2AsMain = (t: any, factor1: number, unit: string): any => {
  return Object.assign({}, t, {
    factor_1: factor1,
    selected_qty: 'Qty2',
    qty_type: 'Volume',
    quantity: t.qty_2_value,
    unit,
    final_qty: t.qty_2_value,
  })
}

const qty3AsMain = (t: any): any => {
  return Object.assign({}, t, {
    factor_1: 1,
    selected_qty: 'Qty3',
    qty_type: 'Area',
    quantity: t.qty_3_value,
    unit: 'M2',
    final_qty: t.qty_3_value,
  })
}

const mapTakeOffToTableData = (item: TakeOffItem, boqid: number): any => {
  // tslint:disable-next-line:variable-name
  const qty2_value = arrayItemsSum(
    item.children
      .map((t) => t.volume)
      .filter((t) => t !== undefined)
      .map((t) => t as number)
  )

  // tslint:disable-next-line:variable-name
  const qty3_value = arrayItemsSum(
    item.children
      .map((t) => t.topArea)
      .filter((t) => t !== undefined)
      .map((t) => t as number)
  )

  return {
    boqid,
    id: item.id + boqid,
    key: item.id + boqid,
    title: item.name,
    note: '',
    source_document_name: 'BHR-R5D-401-00-M3-X-0003-D1-09_Entrance Building - Substructure.rvt',
    take_off_type: item.takeOffType,
    qty_1_type: 'Count',
    qty_1_value: item.children.length,
    unit_1: 'Each',
    qty_2_type: 'Volume',
    // @ts-ignore
    qty_2_value: isNaN(qty2_value) ? 0 : renderDecimal(qty2_value),
    unit_2: 'M3',
    qty_3_type: 'Area',
    // @ts-ignore
    qty_3_value: isNaN(qty3_value) ? 0 : renderDecimal(qty3_value),
    unit_3: 'M2',
    factor_2: 1,
    factor_3: 1,
    creator: 'Admin',
    last_editor: 'Admin',
    creation_date: '06/11/2018',
    last_edited: '06/09/2020',
  }
}

const mapTakeOffsToTableData = (items: TakeOffItem[], boqid: number): any => {
  return items.map((t) => mapTakeOffToTableData(t, boqid))
}

export const BoQToTakeOffTableDataMapping: { [boqId: number]: TakeOffItem[] } = {
  32: mapTakeOffsToTableData(CastInSituReinf, 32),
  34: mapTakeOffsToTableData(CastInSituReinf, 34),
  35: mapTakeOffsToTableData(CastInSituReinf, 35),
  37: mapTakeOffsToTableData(CastInSituReinf, 37),
  42: mapTakeOffsToTableData(PileCaps, 42).map((t: any) => qty3AsMain(t)),
  43: mapTakeOffsToTableData(PileCaps, 43).map((t: any) => qty2AsMain(t, 0.175, 'T')),
  44: mapTakeOffsToTableData(PileCaps, 44).map((t: any) => qty2AsMain(t, 1, 'M3')),
  45: mapTakeOffsToTableData(PileCaps, 45).map((t: any) => qty3AsMain(t)),
  46: mapTakeOffsToTableData(PileCaps, 46).map((t: any) => qty3AsMain(t)),
  65: mapTakeOffsToTableData(ReinfConcSlab, 65),
  66: mapTakeOffsToTableData(ReinfConcSlab, 66),
  67: mapTakeOffsToTableData(ReinfConcSlab, 67),
  68: mapTakeOffsToTableData(ReinfConcSlab, 68),
  69: mapTakeOffsToTableData(ReinfConcSlab, 69),
  70: mapTakeOffsToTableData(ReinfConcSlab, 70),
  58: mapTakeOffsToTableData(ReinfConcGround, 58),
  59: mapTakeOffsToTableData(ReinfConcGround, 59),
  60: mapTakeOffsToTableData(ReinfConcGround, 60),
  61: mapTakeOffsToTableData(ReinfConcGround, 61),
  62: mapTakeOffsToTableData(ReinfConcGround, 62),
  63: mapTakeOffsToTableData(ReinfConcGround, 63),
}

export const BoQToTakeOffMapping: { [boqId: number]: TakeOffItem[] } = {
  32: CastInSituReinf,
  34: CastInSituReinf,
  35: CastInSituReinf,
  37: CastInSituReinf,
  42: PileCaps,
  43: PileCaps,
  44: PileCaps,
  45: PileCaps,
  46: PileCaps,
  65: ReinfConcSlab,
  66: ReinfConcSlab,
  67: ReinfConcSlab,
  68: ReinfConcSlab,
  69: ReinfConcSlab,
  70: ReinfConcSlab,
  58: ReinfConcGround,
  59: ReinfConcGround,
  60: ReinfConcGround,
  61: ReinfConcGround,
  62: ReinfConcGround,
  63: ReinfConcGround,
}
