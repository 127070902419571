import { SyncOutlined, AlignRightOutlined, MoreOutlined, ImportOutlined, ColumnHeightOutlined } from '@ant-design/icons'
import React from 'react'
import { Space, Button, Divider, Tooltip } from 'antd'
import {
  SterlingTableHeader,
  SterlingTableHeaderProps,
} from '../../../basic/table/sterling-table/header/SterlingTableHeader'
import { GroupByCustom, SpellCheckCustom, TextWrappingCustom } from '../../../../assets/icons/icons'

export interface ActivitiesHeaderProps {
  sterlingTableHeaderProps: SterlingTableHeaderProps
  children?: any
}

export const ActivitiesHeader = ({ children, sterlingTableHeaderProps }: ActivitiesHeaderProps) => {
  return (
    <SterlingTableHeader {...sterlingTableHeaderProps} columnsLayoutManagerVisibility={true}>
      <Space>
        <Tooltip title="Expand rows">
          <Button type="text" shape="circle" icon={<ColumnHeightOutlined />} />
        </Tooltip>
        <Tooltip title="Group by">
          <Button type="text" shape="circle" icon={<GroupByCustom />} />
        </Tooltip>
        <Tooltip title="Refresh">
          <Button type="text" shape="circle" icon={<SyncOutlined />} />
        </Tooltip>
        <Divider type="vertical" />
        <Tooltip title="Import">
          <Button type="text" shape="circle" icon={<ImportOutlined />} />
        </Tooltip>
        <Divider type="vertical" />
        <Tooltip title="Wrap text">
          <Button type="text" shape="circle" icon={<TextWrappingCustom />} />
        </Tooltip>
        <Tooltip title="Check spelling">
          <Button type="text" shape="circle" icon={<SpellCheckCustom />} />
        </Tooltip>
        <Tooltip title="More">
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Tooltip>
        {children}
      </Space>
    </SterlingTableHeader>
  )
}
