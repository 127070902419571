import React from 'react'
import { stringSorter } from '../../../utils/Sorters'
import { ExtendedColumnType } from '../../basic/table/sterling-table/SterlingTable'
import {
  RenderCompanyInformation,
  RenderCurrencyInformation,
  RenderPermissions,
  RenderRounding,
  RenderUnits,
  RenderViewer,
} from './utils/Renders'

export const globalPropertiesData = [
  {
    title: 'Company Information',
    dataIndex: 'company_information',
    key: 'company_information',
    width: 130,
    render: <RenderCompanyInformation />,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    width: 130,
    render: <RenderCurrencyInformation />,
  },
  {
    title: 'Rounding - cash',
    dataIndex: 'rounding_cash',
    key: 'rounding_cash',
    width: 130,
    render: <RenderRounding />,
  },
  {
    title: 'Rounding - carbon',
    dataIndex: 'rounding_carbon',
    key: 'rounding_carbon',
    width: 130,
    render: <RenderRounding />,
  },
  {
    title: 'Rounding - take-off',
    dataIndex: 'rounding_take_off',
    key: 'rounding_take_off',
    width: 130,
    render: <RenderRounding />,
  },
  {
    title: 'Units',
    dataIndex: 'units',
    key: 'units',
    width: 130,
    render: <RenderUnits />,
  },
  {
    title: 'Viewer',
    dataIndex: 'viewer',
    key: 'viewer',
    width: 130,
    render: <RenderViewer />,
  },
]

export const administrationColumns: ExtendedColumnType[] = [
  {
    title: 'Project',
    dataIndex: 'project',
    key: 'project',
    width: 130,
  },
  {
    title: 'Cost Plan',
    dataIndex: 'cost_plan',
    key: 'cost_plan',
    width: 130,
    render: RenderPermissions,
  },
  {
    title: 'Documentation',
    dataIndex: 'documentation',
    key: 'documentation',
    width: 130,
    render: RenderPermissions,
  },
  {
    title: 'Take-off',
    dataIndex: 'take_off',
    key: 'take_off',
    width: 130,
    render: RenderPermissions,
  },
  {
    title: 'Work Packages',
    dataIndex: 'work_packages',
    key: 'work_packages',
    width: 130,
    render: RenderPermissions,
  },
  {
    title: 'Schedule',
    dataIndex: 'schedule',
    key: 'schedule',
    width: 130,
    render: RenderPermissions,
  },
  {
    title: 'Valuations',
    dataIndex: 'valuations',
    key: 'valuations',
    width: 130,
    render: RenderPermissions,
  },
  {
    title: 'Libraries',
    dataIndex: 'libraries',
    key: 'libraries',
    width: 130,
    render: RenderPermissions,
  },
]

export const portfolioBreakdownColumns: ExtendedColumnType[] = [
  {
    title: 'Group Code',
    dataIndex: 'groupCode',
    key: 'groupCode',
    width: 240,
    sorter: (a, b) => stringSorter(a.groupCode, b.groupCode),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'ascend',
    editable: true,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    sorter: (a, b) => stringSorter(a.title, b.title),
    width: 240,
    sortDirections: ['ascend', 'descend'],
    editable: true,
  },
]
