import React, { Key, useEffect, useRef, useState } from 'react'
import { Button, Input, Space, Table as AntdTable } from 'antd'
import { RootState } from '../../../../context/reducer'
import { useDispatch, useSelector } from 'react-redux'
import {
  setVisibleColumns,
  setLockers,
  addLayout,
} from '../../../../context/columns-visibility/ColumnsVisibility.actions'
import { copyAndFilter } from '../../../../utils/collections/ArraysUtils'
import './Table.scss'

import { ColumnType, FilterDropdownProps } from 'antd/lib/table/interface'
import { TableSelectionToolbarProps } from '../table-selection-toolbar/TableSelectionToolbar'
import { EditableCell } from '../editable-cell/EditableCell'
import { SterlingTableHeaderProps } from './header/SterlingTableHeader'
import { findMaxExpandLevel, keysToHaveExpanded } from './helpers/ExpandHelpers'
import { alignNumberColumns, enhanceColumnsWithOnCellProp } from './helpers/ColumnsHelpers'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { InputConfig, SelectConfig } from '../editable-cell/types/EditableCellTypes'
import { ColumnsLayout } from '../../../../model/ColumnLayouts'
import { safeComparator } from '../../../../utils/Sorters'
import { SearchOutlined } from '@ant-design/icons'
import { enchanceForFiltering } from '../../../../utils/Filters'
import Highlighter from 'react-highlight-words'
import { sterlingTableHeader } from '../../../../constants/dimensions'

const paginationHeight = 54
const topRowAssumedHeight = 69

export interface ExtendedColumnType extends ColumnType<any> {
  editable?: boolean
  editionConfig?: InputConfig | SelectConfig
  formattingRule?: any
  groupingRule?: (children: Entry[]) => any
  supportTitle?: string
  children?: ExtendedColumnType[]
}

export interface Entry {
  key: number
  id: number
  parent: Entry | undefined
  children: Entry[] | undefined
}

interface SterlingTableProps {
  columns: ExtendedColumnType[]
  row?: any
  bordered?: boolean
  scrollWidth?: number
  dataSource: Entry[]
  tableId: string
  renderHeader?: (sterlingTableHeaderProps: SterlingTableHeaderProps) => JSX.Element
  renderSelectionToolbar?: (tableSelectionToolbarProps: TableSelectionToolbarProps) => JSX.Element
  onUpdate?: (id: number, updates: { [key: string]: any }) => void
  onSelect?: (rows: Key[]) => void
  onRow?: (index: number, record: any) => any
  expandedRowRender?: (record: any) => JSX.Element
  rowExpandable?: (record: any) => boolean
  tableHeight?: number
  size?: SizeType
  defaultPagination?: false | undefined
  expandIconColumnIndex?: number
  disableSelection?: boolean
  defaultExpansionLevel?: number
}

export default function SterlingTable({
  columns,
  dataSource,
  defaultPagination,
  tableId,
  bordered,
  scrollWidth,
  renderHeader,
  renderSelectionToolbar,
  onSelect,
  onUpdate,
  tableHeight,
  onRow,
  row,
  expandedRowRender,
  rowExpandable,
  size,
  expandIconColumnIndex,
  disableSelection,
  defaultExpansionLevel,
}: SterlingTableProps): JSX.Element {
  const dispatch = useDispatch()

  // @ts-ignore
  const columnsChildren = columns.map((c) => c.children).flat(1)

  const onFilter = (dataIndex: string) => (value: any, record: any): boolean => {
    const doesCurrentRecordMatch =
      record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    if (!record.children || record.children.length === 0) {
      return doesCurrentRecordMatch
    }
    return doesCurrentRecordMatch || record.children.some((c: any) => onFilter(dataIndex)(value, c))
  }
  const getColumnSearchProps = (tableId: string) => (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${columns.find((x) => x.dataIndex === dataIndex)?.title || dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              handleSearch(selectedKeys, confirm, dataIndex)
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters)
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: onFilter(dataIndex),
    render: (text: string, record: any, index: number) => {
      if (searchedColumn === dataIndex) {
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            textToHighlight={text ? text.toString() : ''}
          />
        )
      } else {
        // @ts-ignore
        const column = columns.flatMap((c) => [c].concat(c?.children || [])).find((f) => f.dataIndex === dataIndex)
        return column && column.render ? column?.render(text, record, index) : text
      }
    },
  })

  const rawVisibleColumnsKeys = useSelector((state: RootState) =>
    state.columnsVisibility.visibleColumnsKeys.get(tableId)
  )
  const rawLockersKeys = useSelector((state: RootState) => state.columnsVisibility.lockerStateKeys.get(tableId))

  if (!rawVisibleColumnsKeys) {
    dispatch(
      setVisibleColumns(
        tableId,
        columns.map((c) => c.key as Key)
      )
    )
  }

  if (!rawLockersKeys) {
    dispatch(
      setLockers(
        tableId,
        columns.filter(({ fixed }) => fixed).map(({ key }) => key as Key)
      )
    )
  }

  const topLevelEntries = dataSource.filter((el) => el.parent === undefined)

  // @ts-ignore
  const enhancedColumns = (columns: ExtendedColumnType[]) => {
    const c1 = enchanceForFiltering(
      alignNumberColumns(enhanceColumnsWithOnCellProp(columns)),
      getColumnSearchProps(tableId)
    )
    const c2 = c1.map((c) => ({
      ...c,
      children: c.children
        ? enchanceForFiltering(
            // @ts-ignore
            alignNumberColumns(enhanceColumnsWithOnCellProp(c.children)),
            getColumnSearchProps(tableId)
          )
        : undefined,
    }))
    return c2 as ExtendedColumnType[]
  }

  const visibleColumnsKeys: Key[] = rawVisibleColumnsKeys || []
  const lockerKeys: Key[] = rawLockersKeys || []
  const permanentlyLocked = columns.filter((item) => item.hasOwnProperty('fixed'))

  const visibleColumns = enhancedColumns(columns)
    .filter((column) => column.key && visibleColumnsKeys.includes(column.key))
    .map((column) => {
      if (lockerKeys.includes(column.key as Key)) {
        return {
          ...column,
          fixed: lockerKeys.includes(column.key as Key) ? ('left' as 'left') : undefined,
        }
      }
      return column
    })
    .sort((a, b) => safeComparator(a?.fixed, b?.fixed, (x: any, y: any) => 0))

  const saveLayout = (layout: ColumnsLayout) => dispatch(addLayout(layout, tableId))

  columns.forEach((item) => {
    if (item.hasOwnProperty('fixed')) {
      permanentlyLocked.push(item)
    }
  })

  const [expandedRows, setExpandedRows] = useState<Key[]>([])

  useEffect(() => {
    if (defaultExpansionLevel !== undefined) {
      setExpandedRows(keysToHaveExpanded(topLevelEntries, defaultExpansionLevel))
    }
  }, [defaultExpansionLevel])

  const [selectedRows, setSelectedRows] = useState<Key[]>([])
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters: any) => {
    clearFilters()
    setSearchText('')
  }

  const renderTopPanel = () => {
    const headerProps: SterlingTableHeaderProps = {
      columnsLayoutManager: {
        columns: enhancedColumns(columns),
        lockers: lockerKeys,
        visibleColumns: visibleColumnsKeys,
        onApply: (checkedRows, checkedLockers) => {
          dispatch(setVisibleColumns(tableId, checkedRows))
          dispatch(setLockers(tableId, checkedLockers))
        },
        tableId,
        permanentlyLocked,
        onLayoutsSaved: saveLayout,
      },
      rowsExpand: {
        levelsNumber: findMaxExpandLevel(dataSource),
        onExpandToLevel: (level: any) => setExpandedRows(keysToHaveExpanded(topLevelEntries, level)),
      },
      columnsLayoutManagerVisibility: true,
      descriptionManagerVisibility: false,
    }

    const toolbarProps: TableSelectionToolbarProps = {
      selectedItemsNumber: selectedRows.length,
      selectedItems: selectedRows,
    }

    return (
      <>
        {renderHeader !== undefined && renderHeader(headerProps)}
        <span className={'sterling-table-toolbar'}>
          {renderSelectionToolbar !== undefined && renderSelectionToolbar(toolbarProps)}
        </span>
      </>
    )
  }

  return (
    <AntdTable
      components={{
        body: {
          cell: (props: any) => <EditableCell {...props} handleSave={onUpdate} />,
          row,
        },
      }}
      // @ts-ignore
      columns={visibleColumns}
      dataSource={topLevelEntries}
      title={renderTopPanel}
      pagination={defaultPagination}
      size={size}
      bordered={bordered || false}
      expandedRowKeys={expandedRows}
      expandedRowRender={expandedRowRender}
      rowExpandable={rowExpandable}
      onExpand={(expandedValue, record) => {
        // @ts-ignore
        if (expandedValue) {
          // @ts-ignore
          setExpandedRows(expandedRows.concat(record.id as Key))
        } else {
          // @ts-ignore
          setExpandedRows(copyAndFilter(expandedRows, (el) => el !== (record.id as Key)))
        }
      }}
      expandIconColumnIndex={expandIconColumnIndex}
      // @ts-ignore
      onRow={onRow || undefined}
      // @ts-ignore
      rowClassName={(r: any) => `${r.disabled ? 'disabled-row' : ''} editable-row`}
      rowSelection={
        disableSelection !== undefined && disableSelection
          ? undefined
          : {
              selectedRowKeys: selectedRows,
              onChange: (selectedRowKeys) => {
                setSelectedRows(selectedRowKeys)
                if (onSelect) onSelect(selectedRowKeys)
              },
            }
      }
      scroll={{
        // @ts-ignore
        x: scrollWidth || visibleColumns.map((c) => c.width).reduce((sum, current) => sum + current.total, 0),
        y: tableHeight
          ? tableHeight -
            (renderHeader !== undefined ? sterlingTableHeader : 0) -
            topRowAssumedHeight -
            (defaultPagination === undefined ? paginationHeight : 0) -
            (selectedRows.length > 0 && renderSelectionToolbar !== undefined ? 44 : 0)
          : undefined,
      }}
    />
  )
}
