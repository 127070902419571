import React from 'react'
import { TakeOffCostItem } from './TakeOffCostItem'
import SterlingTable, { Entry } from '../../../../basic/table/sterling-table/SterlingTable'
import { TakeOffCostItemsColumns } from './TakeOffCostItemsTable.columns'

export const TakeOffCostItemsTable = ({ dataSource }: { dataSource: TakeOffCostItem[] }) => {
  return (
    <SterlingTable
      columns={TakeOffCostItemsColumns}
      dataSource={convertToEntry(dataSource)}
      tableId={'take-off-cost-items-form'}
      tableHeight={250}
      size={'small'}
      defaultPagination={false}
      disableSelection={true}
    />
  )
}

const convertToEntry = (dataSource: TakeOffCostItem[]): Entry[] => {
  let currentId = 70000000
  return dataSource.map((item) => {
    const entry = Object.assign({}, item, { id: currentId, key: currentId, parent: undefined, children: undefined })
    currentId = currentId + 1
    return entry
  })
}
