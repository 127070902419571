import { SterlingTableState } from './SterlingTable.state'
import {
  ADD_ENTRY,
  AddEntryAction,
  DELETE_ENTRIES,
  DeleteEntriesAction,
  DUPLICATE_ENTRIES,
  DuplicateEntries,
  GROUP_BY_COLUMN,
  GROUP_BY_EXTERNAL_DEPENDENCY,
  GroupByColumnAction,
  GroupByExternalDependency,
  SterlingTableAction,
  UPDATE_ENTRY,
  UpdateEntryAction,
} from './SterlingTable.actions'
import { subcontractorsData } from '../../data/library/Subcontractors.data'
import { copyAndFilter, copyAndSwap } from '../../utils/collections/ArraysUtils'
import { labourData } from '../../data/library/Labour.data'
import { cesmm4Data } from '../../data/library/CESMM4.data'
import { contactsExternalData } from '../../data/library/ContactsExternal.data'
import { contactsInternalData } from '../../data/library/ContactsInternal.data'
import { parseData } from '../../components/basic/table/sterling-table/helpers/TableDataParser'
import { BoQData } from '../../data/BillOfQuantities.data'
import _ from 'lodash'
import { complexResourcesData } from '../../data/library/ComplexResources.data'
import { complexResourcesDetailsData } from '../../data/library/ComplexResourcesDetails.data'
import { WorkPackagesData } from '../../data/WorkPackages.data'
import { portfolioBreakdownData } from '../../components/domain/administration/Administration.data'
import { activitiesData } from '../../data/Activities.data'
import { nrm1Data } from '../../data/library/NRM1.data'
import { nrm2Data } from '../../data/library/NRM2.data'
import { wbsData } from '../../data/library/WBS.data'
import { Entry } from '../../components/basic/table/sterling-table/SterlingTable'
import { Key } from 'react'
import { QuoteItems } from '../../data/WorkPackagesDetailsData'
import { EmptyValuationFor, ValuationEntries, ValuationsData } from '../../data/Valuations.data'
import Valuations from '../../components/domain/valuations/Valuations'

const itemCodeExtractor = (item: any) => item.itemCode
const groupCodeExtractor = (item: any) => item.groupCode
const activityCodeExtractor = (item: any) => item.activityCode

function assignQuotesToBoQItem(x: any) {
  const objects = QuoteItems.filter((b) => b.boqId === x.id)
  if (objects.length > 0) {
    objects.forEach((el) => Object.assign(x, el))
  }
  return x
}

function assignValuationsToBoQItems(x: any) {
  const objects = ValuationEntries.filter((b) => b.boqId === x.id)
  if (objects.length > 0) {
    objects.forEach((el) => Object.assign(x, el))
  }
  return x
}

const initialState: SterlingTableState = {
  rawData: {
    // resource-library
    subcontractors: subcontractorsData,
    labour: labourData,
    cesmm4: cesmm4Data,
    nrm1: nrm1Data,
    nrm2: nrm2Data,
    projectWBS: wbsData,
    contractsExternal: contactsExternalData,
    contractsInternal: contactsInternalData,
    complexResources: complexResourcesData,
    complexResourcesDetails: complexResourcesDetailsData,
    // bill-of-quantities
    billOfQuantities: BoQData,
    // work-packages
    workPackages: WorkPackagesData,
    wpDetails: BoQData.map((x) => assignQuotesToBoQItem(x)),
    // administration
    breakdown: portfolioBreakdownData,
    valuations: BoQData.map((x) => assignValuationsToBoQItems(x)),
  },
  parsedData: {
    subcontractors: parseData(subcontractorsData, itemCodeExtractor),
    labour: parseData(labourData, itemCodeExtractor),
    cesmm4: parseData(cesmm4Data, itemCodeExtractor),
    nrm1: parseData(nrm1Data, itemCodeExtractor),
    nrm2: parseData(nrm2Data, itemCodeExtractor),
    projectWBS: parseData(wbsData, itemCodeExtractor),
    contractsExternal: parseData(contactsExternalData, itemCodeExtractor),
    contractsInternal: parseData(contactsInternalData, itemCodeExtractor),
    complexResources: parseData(complexResourcesData, itemCodeExtractor),
    complexResourcesDetails: parseData(complexResourcesDetailsData, itemCodeExtractor),
    // bill-of-quantities
    billOfQuantities: BoQData,
    // work-packages
    workPackages: parseData(WorkPackagesData, (el) => el.wp_code),
    wpDetails: BoQData.map((x) => assignQuotesToBoQItem(x)),
    // administration
    breakdown: parseData(portfolioBreakdownData, groupCodeExtractor),
    activities: parseData(activitiesData, activityCodeExtractor),
    // valuations
    valuations: BoQData.map((x) => assignValuationsToBoQItems(x)),
  },
  groupedBy: {},
  groupedByExternal: {},
}

export function sterlingTableReducer(
  state: SterlingTableState = initialState,
  action: SterlingTableAction
): SterlingTableState {
  switch (action.type) {
    case UPDATE_ENTRY: {
      const actionObject = action as UpdateEntryAction
      const codeExtractor = action.codeExtractor
      const currentData = state.rawData[actionObject.tableId] || []
      const itemToUpdate = currentData.find((item) => item.id === actionObject.id)
      const updatedItem = Object.assign({}, itemToUpdate, actionObject.updates)
      const updatedData = copyAndSwap(currentData, updatedItem)

      return prepareRefreshedState(actionObject.tableId, state, updatedData, codeExtractor || itemCodeExtractor)
    }
    case DELETE_ENTRIES: {
      const actionObject = action as DeleteEntriesAction
      const codeExtractor = action.codeExtractor
      const filteredData = copyAndFilter(
        state.rawData[actionObject.tableId],
        (item) => !actionObject.entriesIds.includes(item.id)
      )
      return prepareRefreshedState(actionObject.tableId, state, filteredData, codeExtractor || itemCodeExtractor)
    }
    case ADD_ENTRY: {
      const actionObject = action as AddEntryAction
      const codeExtractor = action.codeExtractor
      const rawData = state.rawData[actionObject.tableId]
      actionObject.entry.id = Math.max(...rawData.map((item) => item.id)) + 10
      actionObject.entry.key = actionObject.entry.id
      const updatedData = rawData.concat(actionObject.entry)
      return prepareRefreshedState(actionObject.tableId, state, updatedData, codeExtractor || itemCodeExtractor)
    }
    case DUPLICATE_ENTRIES: {
      const actionObject = action as DuplicateEntries
      const codeExtractor = action.codeExtractor
      const rawData = state.rawData[actionObject.tableId]
      let highestId = Math.max(...rawData.map((item) => item.id))

      const duplicatedEntries = rawData
        .filter((item) => actionObject.entriesIds.includes(item.id))
        .map((item) => {
          highestId += 10
          return Object.assign({}, item, { id: highestId, key: highestId })
        })

      const updatedData = rawData.concat(duplicatedEntries)
      return prepareRefreshedState(actionObject.tableId, state, updatedData, codeExtractor || itemCodeExtractor)
    }
    case GROUP_BY_EXTERNAL_DEPENDENCY: {
      const {
        codeExtractor,
        parentsList,
        tableId,
        grouper,
        columnGroupingRules,
        column,
      } = action as GroupByExternalDependency
      const raw = state.rawData[tableId]
      const updatedData =
        parentsList.length > 0 ? grouper(parentsList, raw, codeExtractor).filter((p) => p.parent === undefined) : []

      const calculatedData = updatedData.map((e) => calculateForBreakdown(e, columnGroupingRules))
      return prepareUnmodifiedState(
        tableId,
        state,
        calculatedData.length === 0 ? raw : calculatedData,
        undefined,
        column
      )
    }
    case GROUP_BY_COLUMN: {
      const {
        tableId,
        columnKey,
        emptyElementGenerator,
        columnGroupingRules,
        codeExtractor,
      } = action as GroupByColumnAction
      const rawData = state.rawData[tableId]
      if (!columnKey) {
        const data = codeExtractor ? parseData(rawData, codeExtractor) : rawData
        return prepareUnmodifiedState(tableId, state, data, columnKey, undefined)
      }
      const parsedData = _.groupBy(rawData, columnKey)
      const groupedData = Object.keys(parsedData).map((uniqueValue, i) => {
        const builder = emptyElementGenerator(i) as any
        builder[columnKey] = uniqueValue !== 'undefined' ? uniqueValue : ''
        builder.children = parsedData[uniqueValue]
        Object.keys(columnGroupingRules).forEach((k) => {
          builder[k] = columnGroupingRules[k](parsedData[uniqueValue])
        })
        return builder
      })
      return prepareUnmodifiedState(tableId, state, groupedData, columnKey, undefined)
    }
    default:
      return state
  }
}

function prepareUnmodifiedState(
  tableId: string,
  state: SterlingTableState,
  updatedData: any[],
  groupedBy: Key | undefined,
  groupedByExternal: Key | undefined
) {
  const updateGrouped: { [key: string]: Key | undefined } = {}
  updateGrouped[tableId] = groupedBy
  const updatedGroupedBy = Object.assign({}, state.groupedBy, updateGrouped)
  const updateExternal: { [key: string]: Key | undefined } = {}
  updateExternal[tableId] = groupedByExternal
  const updatedGroupedByExternal = Object.assign({}, state.groupedByExternal, updateExternal)

  const grouped = Object.assign({}, state.parsedData, {
    [tableId]: updatedData,
  })
  return Object.assign({}, state, {
    parsedData: grouped,
    groupedBy: updatedGroupedBy,
    groupedByExternal: updatedGroupedByExternal,
  })
}

function prepareRefreshedState(
  tableId: string,
  state: SterlingTableState,
  updatedData: any[],
  codeExtractor: (el: any) => string
) {
  const copiedRawMap = Object.assign({}, state.rawData, {
    [tableId]: updatedData,
  })
  const copiedParsedMap = Object.assign({}, state.parsedData, {
    [tableId]: parseData(updatedData, codeExtractor),
  })

  return Object.assign({}, state, {
    rawData: copiedRawMap,
    parsedData: copiedParsedMap,
  })
}

const calculateForBreakdown = (
  entry: Entry,
  columnGroupingRules: { [key: string]: (children: Entry[]) => string }
): Entry => {
  if (entry.children === undefined || entry.children.length === 0) {
    return entry
  }
  const calculatedChildren = entry.children.map((e) => calculateForBreakdown(e, columnGroupingRules))

  const groupingValuesObject: { [key: string]: any } = {}
  Object.keys(columnGroupingRules).forEach((k) => {
    groupingValuesObject[k] = columnGroupingRules[k](calculatedChildren)
  })
  return Object.assign({}, entry, groupingValuesObject, { children: calculatedChildren })
}
