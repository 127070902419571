import React, { useState } from 'react'
import { Row, Col, Tabs, Button } from 'antd'

import './Portfolio.scss'
import PortfolioTable from './table/PortfolioTable'
import { ArrowsAltOutlined } from '@ant-design/icons/lib'
import PortfolioMaps from './maps/PortfolioMaps'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { headerHeight } from '../../../constants/dimensions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../context/reducer'
import NewWindow from '../../basic/new-window/NewWindow'
import ReplacementPane from '../../basic/new-window/ReplacementPane'
import { setOpenWindowState } from '../../../context/open-windows/OpenWindows.actions'
import { newWindowHeight, newWindowWidth } from '../../../constants/sizes'

const { TabPane } = Tabs

function Portfolio(): JSX.Element {
  const { height } = useWindowDimensions()
  const [selectedRow, setSelectedRow] = useState<number | undefined>(undefined)
  const projectsData = useSelector((state: RootState) => state.projects.projects)
  const [isPortfolioNewWindowOpen, setIsPortfolioNewWindowOpen] = useState(false)

  const getOpenWindow = (id: string) => (state: RootState) => state.openWindows.windows.get(id) || false
  const portfolioWindowId = 'portfolioWindow'

  const dispatch = useDispatch()
  const isPortfolioTableWindowOpen = useSelector(getOpenWindow(portfolioWindowId))

  return (
    <span className={'portfolio-content'}>
      <Row>
        <Col className={'padding-xs'} span={18}>
          <div className={'table-wrapper'}>
            <NewWindow
              height={newWindowHeight}
              width={newWindowWidth}
              isOpen={isPortfolioTableWindowOpen}
              onPortalClose={() => {
                dispatch(setOpenWindowState(portfolioWindowId, false))
                setIsPortfolioNewWindowOpen(false)
              }}
              replacementPane={
                <ReplacementPane
                  onHide={() => {
                    dispatch(setOpenWindowState(portfolioWindowId, false))
                    setIsPortfolioNewWindowOpen(false)
                  }}
                  onWindowShow={() => {}}
                  style={{ width: '100%', height: 450, backgroundColor: '#fff' }}
                />
              }
            >
              <Tabs
                tabBarExtraContent={
                  <Button
                    type={'text'}
                    onClick={() => {
                      dispatch(setOpenWindowState(portfolioWindowId, true))
                      setIsPortfolioNewWindowOpen(true)
                    }}
                    style={{ marginRight: '16px' }}
                    icon={<ArrowsAltOutlined />}
                  />
                }
                defaultActiveKey="active"
                tabBarStyle={{ margin: 0, paddingLeft: '16px', background: '#fff' }}
              >
                <TabPane tab={<span>Active ({projectsData.length})</span>} key="active">
                  <PortfolioTable
                    data={projectsData}
                    selectedRows={selectedRow !== undefined ? [selectedRow] : []}
                    onSelect={setSelectedRow}
                    isInNewWindow={isPortfolioNewWindowOpen}
                  />
                </TabPane>
                <TabPane tab={<span>Archive ({[].length})</span>} key="archive">
                  <PortfolioTable data={[]} />
                </TabPane>
              </Tabs>
            </NewWindow>
          </div>
        </Col>
        <Col span={6} className={'padding-xs'}>
          <PortfolioMaps data={projectsData} selectedProjectKey={selectedRow} />
        </Col>
      </Row>
      <Row>
        <Col style={{ backgroundColor: '#fff' }} span={24} className={'padding-xs'}>
          <Tabs
            tabBarExtraContent={<Button type={'text'} style={{ marginRight: '16px' }} icon={<ArrowsAltOutlined />} />}
            defaultActiveKey="active"
            tabBarStyle={{ margin: 0, paddingLeft: '16px', background: '#fff' }}
          >
            <iframe
              title={'portfolio-dash'}
              width="100%"
              height={(height - headerHeight - 265) / 2}
              src="https://app.powerbi.com/view?r=eyJrIjoiYjZjY2M0ZjEtMmI5NC00YmU2LTg5YmUtYTc5Y2VkMmI3ZmFmIiwidCI6IjE4OWExNmU1LTlkM2YtNDEwNC1iZTAyLTQ4NDYxMmQ0ZjY0ZiJ9&pageName=ReportSection85dc90700a1b16a6a3b8"
              frameBorder="0"
              allowFullScreen={true}
            />
          </Tabs>
        </Col>
      </Row>
    </span>
  )
}

export default Portfolio
