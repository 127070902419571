import React, { FunctionComponent } from 'react'
import { WorkPackagesColumns } from '../WorkPackages.columns'
import { emptyWorkPackagesEntry, WorkPackagesEntry } from '../../../../data/WorkPackages.data'
import WorkPackagesHeader from '../headers/WorkPackagesHeader'
import SterlingTable from '../../../basic/table/sterling-table/SterlingTable'
import { useDispatch, useSelector } from 'react-redux'
import {
  addEntry,
  deleteEntries,
  duplicateEntries,
  updateEntry,
} from '../../../../context/resource-library/SterlingTable.actions'
import { RootState } from '../../../../context/reducer'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import WorkPackagesDashboard from './WorkPackagesDashboard'
import { ItemsManagementToolbar } from '../../../basic/table/table-selection-toolbar/ItemsManagementToolbar'
import { sortColumnsAccordingToGrouping } from '../../../basic/table/sterling-table/helpers/ColumnsHelpers'
import { BoQColumns } from '../../bill-of-quantities/BillOfQuantities.columns'
import NewWindow from '../../../basic/new-window/NewWindow'
import { newWindowHeight, newWindowWidth } from '../../../../constants/sizes'
import { setOpenWindowState } from '../../../../context/open-windows/OpenWindows.actions'
import ReplacementPane from '../../../basic/new-window/ReplacementPane'

const tableId = 'workPackages'

const WorkPackagesOverview: FunctionComponent = () => {
  const dispatch = useDispatch()
  const itemCodeExtractor = (item: WorkPackagesEntry) => item.wp_code
  const data = useSelector((state: RootState) => state.sterlingTable.parsedData[tableId])
  const rowHeight = useWindowDimensions().height / 12
  const activeGroupingColumn = useSelector((state: RootState) => state.sterlingTable.groupedBy[tableId])
  const columns = sortColumnsAccordingToGrouping(WorkPackagesColumns, activeGroupingColumn)

  const getOpenWindow = (id: string) => (state: RootState) => state.openWindows.windows.get(id) || false
  const workPackagesMainWindowId = 'workPackagesMain'
  const isworkPackagesMainWindowOpen = useSelector(getOpenWindow(workPackagesMainWindowId))

  return (
    <>
      <NewWindow
        height={newWindowHeight}
        width={newWindowWidth}
        isOpen={isworkPackagesMainWindowOpen}
        onPortalClose={() => dispatch(setOpenWindowState(workPackagesMainWindowId, false))}
        replacementPane={
          <ReplacementPane
            onHide={() => dispatch(setOpenWindowState(workPackagesMainWindowId, false))}
            onWindowShow={() => {}}
            style={{ width: '100%', height: 450, backgroundColor: '#fff' }}
          />
        }
      >
        <SterlingTable
          columns={columns}
          dataSource={data}
          tableId={tableId}
          tableHeight={rowHeight * 5.5}
          renderHeader={(props) => (
            <WorkPackagesHeader
              openWindow={() => dispatch(setOpenWindowState(workPackagesMainWindowId, true))}
              data={data}
              columns={WorkPackagesColumns}
              tableId={tableId}
              sterlingHeaderProps={props}
            />
          )}
          renderSelectionToolbar={(props) => (
            <ItemsManagementToolbar
              baseProps={props}
              onAdd={() => dispatch(addEntry(tableId, emptyWorkPackagesEntry(0), itemCodeExtractor))}
              onDuplicate={(selectedRows) =>
                dispatch(
                  duplicateEntries(
                    tableId,
                    selectedRows.map((key) => key as number),
                    itemCodeExtractor
                  )
                )
              }
              onDelete={(selectedRows) =>
                dispatch(
                  deleteEntries(
                    tableId,
                    selectedRows.map((key) => key as number),
                    itemCodeExtractor
                  )
                )
              }
            />
          )}
          size={'small'}
          onUpdate={(id, updates) => {
            dispatch(updateEntry(tableId, id, updates, itemCodeExtractor))
          }}
          defaultPagination={false}
        />
        <div style={{ height: rowHeight * 3.5, background: 'white', marginTop: 16 }}>
          <WorkPackagesDashboard />
        </div>
      </NewWindow>
    </>
  )
}

export default WorkPackagesOverview
