import { DocumentationEntry } from '../model/Documentation'

export const emptyDocumentationEntry = (name: string, id: number): DocumentationEntry => {
  return {
    key: id,
    id,
    parent: undefined,
    children: undefined,
    document_name: name,
    internal_doc_reference_code: '',
    document_title: '',
    file_type: '',
    file_size: undefined,
    file_name: '',
    folder: '',
    note: '',
    revision: '',
    revisions: {},
    status: '',
    location: '',
    riba_stage: '',
    discipline: '',
    design_package: '',
    author: '',
    organisation: '',
    document_type: '',
    role: '',
    published_by: '',
    publish_date: '',
    updated_by: '',
    update_date: '',
  }
}

export const documentationData: DocumentationEntry[] = [
  {
    key: 2,
    id: 2,
    parent: undefined,
    children: undefined,
    document_name: 'BHR-R5D-401-00-M3-X-0003-D1-09',
    internal_doc_reference_code: 'ABC-132-000001',
    document_title: 'Entrance Building - Substructure',
    file_type: '.rvt',
    file_size: 812900,
    file_name: 'BHR-R5D-401-00-M3-X-0003-D1-09_Entrance Building - Substructure.rvt',
    folder: '3D models',
    note: '',
    revision: '02',
    revisions: {
      '01':
        'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDMtRDEtMDlfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBTdWJzdHJ1Y3R1cmUucnZ0',
      '02':
        'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDMtRDEtMDlfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBTdWJzdHJ1Y3R1cmVfdjIucnZ0',
    },
    status: 'For Take-off',
    location: '',
    riba_stage: '4 - Technical Design',
    discipline: 'C - Civil Engineering',
    design_package: 'C - Highways',
    author: 'John Brown',
    organisation: 'ArcDesign',
    document_type: 'CM',
    role: 'C',
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },
  {
    key: 3,
    id: 3,
    parent: undefined,
    children: undefined,
    document_name: 'BHR-R5D-401-00-M3-X-0004-D1-08',
    internal_doc_reference_code: 'ABC-132-000002',
    document_title: 'Entrance Building - Superstructure',
    file_type: '.ifc',
    file_size: 2,
    file_name: 'BHR-R5D-401-00-M3-X-0004-D1-08_Entrance Building - Superstructure.ifc',
    folder: '3D models',
    note: 'Sample',
    revision: '01',
    revisions: {
      '01':
        'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDQtRDEtMDhfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBTdXBlcnN0cnVjdHVyZS5ydnQ',
    },
    status: 'For Take-off',
    location: '',
    riba_stage: '4 - Technical Design',
    discipline: 'C - Civil Engineering',
    design_package: 'C - Highways',
    author: 'John Brown',
    organisation: 'ArcDesign',
    document_type: 'CM',
    role: 'C',
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },
  {
    key: 4,
    id: 4,
    parent: undefined,
    children: undefined,
    document_name: 'BHR-R5D-401-00-M3-X-0005-D1-05',
    internal_doc_reference_code: 'ABC-132-000003',
    document_title: 'Entrance Building - Exterior',
    file_type: '.rvt',
    file_size: 3,
    file_name: 'BHR-R5D-401-00-M3-X-0005-D1-05_Entrance Building - Exterior.rvt',
    folder: '3D models',
    note: 'Sample',
    revision: '01',
    revisions: {
      '01':
        'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDUtRDEtMDVfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBFeHRlcmlvci5ydnQ',
    },
    status: 'For Take-off',
    location: '',
    riba_stage: '4 - Technical Design',
    discipline: 'C - Civil Engineering',
    design_package: 'C - Highways',
    author: 'John Brown',
    organisation: 'ArcDesign',
    document_type: 'CM',
    role: 'C',
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },
  {
    key: 44,
    id: 44,
    parent: undefined,
    children: undefined,
    document_name: 'BHR-R5D-401-00-M3-X-0008-D1-03',
    internal_doc_reference_code: 'ABC-132-000003',
    document_title: 'Entrance Building - FFE',
    file_type: '.rvt',
    file_size: 3,
    file_name: 'BHR-R5D-401-00-M3-X-0008-D1-03_Entrance Building - FFE.rvt',
    folder: '3D models',
    note: 'Sample',
    revision: '01',
    revisions: {
      '01':
        'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDgtRDEtMDNfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBGRkUucnZ0',
    },
    status: 'For Take-off',
    location: '',
    riba_stage: '4 - Technical Design',
    discipline: 'C - Civil Engineering',
    design_package: 'C - Highways',
    author: 'John Brown',
    organisation: 'ArcDesign',
    document_type: 'CM',
    role: 'C',
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },

  {
    key: 45,
    id: 45,
    parent: undefined,
    children: undefined,
    document_name: 'BHR-R5D-401-00-M3-X-0007-D1-03',
    internal_doc_reference_code: 'ABC-132-000003',
    document_title: 'Entrance Building - Internal',
    file_type: '.rvt',
    file_size: 3,
    file_name: 'BHR-R5D-401-00-M3-X-0007-D1-03_Entrance Building - Internal.rvt',
    folder: '3D models',
    note: 'Sample',
    revision: '01',
    revisions: {
      '01':
        'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDctRDEtMDNfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBJbnRlcm5hbC5ydnQ',
    },
    status: 'For Take-off',
    location: '',
    riba_stage: '4 - Technical Design',
    discipline: 'C - Civil Engineering',
    design_package: 'C - Highways',
    author: 'John Brown',
    organisation: 'ArcDesign',
    document_type: 'CM',
    role: 'C',
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },

  {
    key: 46,
    id: 46,
    parent: undefined,
    children: undefined,
    document_name: 'BHR-R5D-401-00-M3-X-0006-D1-03',
    internal_doc_reference_code: 'ABC-132-000003',
    document_title: 'Entrance Building - MEP',
    file_type: '.rvt',
    file_size: 3,
    file_name: 'BHR-R5D-401-00-M3-X-0006-D1-03_Entrance Building - MEP.rvt',
    folder: '3D models',
    note: 'Sample',
    revision: '01',
    revisions: {
      '01':
        'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy9CSFItUjVELTQwMS0wMC1NMy1YLTAwMDYtRDEtMDNfRW50cmFuY2UlMjBCdWlsZGluZyUyMC0lMjBNRVAucnZ0',
    },
    status: 'For Take-off',
    location: '',
    riba_stage: '4 - Technical Design',
    discipline: 'C - Civil Engineering',
    design_package: 'C - Highways',
    author: 'John Brown',
    organisation: 'ArcDesign',
    document_type: 'CM',
    role: 'C',
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },
  {
    key: 5,
    id: 5,
    parent: undefined,
    children: undefined,
    document_name: '09100-401-A-SE-120-B',
    internal_doc_reference_code: 'ABC-132-000004',
    document_title: 'Entrance building - Sections - Security strategy',
    file_type: '.pdf',
    file_size: 12,
    file_name: '09100-401-A-SE-120-B_Entrance building - Sections - Security strategy.pdf',
    folder: '2D Drawings',
    note: 'Sample',
    revision: '01',
    revisions: {
      '01':
        'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy8wOTEwMC00MDEtQS1TRS0xMjAtQl9FbnRyYW5jZSUyMGJ1aWxkaW5nJTIwLSUyMFNlY3Rpb25zJTIwLSUyMFNlY3VyaXR5JTIwc3RyYXRlZ3kucGRm',
    },
    status: 'For Information',
    location: '',
    riba_stage: '4 - Technical Design',
    discipline: 'C - Civil Engineering',
    design_package: 'C - Highways',
    author: 'John Brown',
    organisation: 'ArcDesign',
    document_type: 'M3',
    role: 'C',
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },
  {
    key: 6,
    id: 6,
    parent: undefined,
    children: undefined,
    document_name: '09100-401-S-BG-001-E',
    internal_doc_reference_code: 'ABC-132-000005',
    document_title: 'Entrance building - Foundations - General layout',
    file_type: '.pdf',
    file_size: 8,
    file_name: '09100-401-S-BG-001-E_Entrance building - Foundations - General layout.pdf',
    folder: '2D Drawings',
    note: 'Sample',
    revision: '01',
    revisions: {
      '01':
        'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy8wOTEwMC00MDEtUy1CRy0wMDEtRV9FbnRyYW5jZSUyMGJ1aWxkaW5nJTIwLSUyMEZvdW5kYXRpb25zJTIwLSUyMEdlbmVyYWwlMjBsYXlvdXQucGRm',
    },
    status: 'For Take-off',
    location: '',
    riba_stage: '4 - Technical Design',
    discipline: 'C - Civil Engineering',
    design_package: 'C - Highways',
    author: 'John Brown',
    organisation: 'ArcDesign',
    document_type: 'M3',
    role: 'C',
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },
  // commented because we want to limit models loaded to forge
  // {
  //   key: 7,
  //   id: 7,
  //   parent: undefined,
  //   children: undefined,
  //   document_name: '09100-401-S-FF-001-J',
  //   internal_doc_reference_code: 'ABC-132-000006',
  //   document_title: 'Entrance building - First floor - General layout',
  //   file_type: '.pdf',
  //   file_size: 5,
  //   file_name: '09100-401-S-FF-001-J_Entrance building - First floor - General layout.pdf',
  //   folder: '2D Drawings',
  //   note: 'Sample',
  //   revision: '01',
  //   revisions: {
  //     '01':
  //       'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy8wOTEwMC00MDEtUy1CRy0wMDEtRV9FbnRyYW5jZSUyMGJ1aWxkaW5nJTIwLSUyMEZvdW5kYXRpb25zJTIwLSUyMEdlbmVyYWwlMjBsYXlvdXQucGRm',
  //   },
  //   status: 'For Information',
  //   location: '',
  //   riba_stage: '4 - Technical Design',
  //   discipline: 'C - Civil Engineering',
  //   design_package: 'C - Highways',
  //   author: 'John Brown',
  //   organisation: 'ArcDesign',
  //   document_type: 'M3',
  //   role: 'C',
  //   published_by: 'Admin',
  //   publish_date: '01.01.2020',
  //   updated_by: 'Trevor Sharp',
  //   update_date: '01.04.2020',
  // },
  {
    key: 8,
    id: 8,
    parent: undefined,
    children: undefined,
    document_name: '09100-401-M-GF-910-T',
    internal_doc_reference_code: 'ABC-132-000007',
    document_title: 'Entrance Building - Ground floor - MEP Engineering Systems - BWIC.dwfx',
    file_type: '.dwfx',
    file_size: 19,
    file_name: '09100-401-M-GF-910-T_Entrance Building - Ground floor - MEP Engineering Systems - BWIC.dwfx',
    folder: '2D Drawings',
    note: 'Sample',
    revision: '01',
    revisions: {
      '01':
        'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3RlcmxpbmctcHJvdG90eXBlLW1vZGVscy8wOTEwMC00MDEtTS1HRi05MTAtVF9FbnRyYW5jZSUyMEJ1aWxkaW5nJTIwLSUyMEdyb3VuZCUyMGZsb29yJTIwLSUyME1FUCUyMEVuZ2luZWVyaW5nJTIwU3lzdGVtcyUyMC0lMjBCV0lDLmR3Zng',
    },
    status: 'For Take-off',
    location: '',
    riba_stage: '4 - Technical Design',
    discipline: 'S - Structure',
    design_package: 'S - Bridge',
    author: 'John Brown',
    organisation: 'ArcDesign',
    document_type: 'M3',
    role: 'S',
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },
  {
    key: 11,
    id: 11,
    parent: undefined,
    children: undefined,
    document_name: 'M25_ARC_DR_R3_PP_D_100109',
    internal_doc_reference_code: 'ABC-132-000010',
    document_title: 'Schedule of signage',
    file_type: '.pdf',
    file_size: 12,
    file_name: 'M25_ARC_DR_R3_PP_D_100109_Schedule of signage_.pdf',
    folder: 'Documentation',
    note: 'Sample',
    revision: '03',
    revisions: {},
    status: 'For Information',
    location: '',
    riba_stage: '4 - Technical Design',
    discipline: 'C - Civil Engineering',
    design_package: 'C - Highways',
    author: 'John Brown',
    organisation: 'ArcDesign',
    document_type: 'DR',
    role: 'D',
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },
  {
    key: 12,
    id: 12,
    parent: undefined,
    children: undefined,
    document_name: 'M25_ARC_DR_V1_PP_D_100110',
    internal_doc_reference_code: 'ABC-132-000011',
    document_title: 'Road specification',
    file_type: '.pdf',
    file_size: 19,
    file_name: 'M25_ARC_DR_V1_PP_D_100110_Road specification_.pdf',
    folder: 'Documentation',
    note: 'Sample',
    revision: '04',
    revisions: {},
    status: 'For Take-off',
    location: '',
    riba_stage: '4 - Technical Design',
    discipline: 'S - Structure',
    design_package: 'S - Bridge',
    author: 'John Brown',
    organisation: 'ArcDesign',
    document_type: 'DR',
    role: 'D',
    published_by: 'Admin',
    publish_date: '01.01.2020',
    updated_by: 'Trevor Sharp',
    update_date: '01.04.2020',
  },
]
