export function safeComparator(a: any, b: any, f: (x: any, y: any) => number): number {
  if (a === undefined && b === undefined) {
    return 0
  } else if (a === undefined) {
    return 1
  } else if (b === undefined) {
    return -1
  }
  return f(a, b)
}

export function stringSorter(a: string, b: string): number {
  return safeComparator(a, b, (x: string, y: string) => x.toLowerCase().localeCompare(y.toLowerCase()))
}

export function numberSorter(a: number, b: number): number {
  return safeComparator(a, b, (x, y) => x - y)
}
