import { Tabs } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { newWindowHeight, newWindowWidth } from '../../../constants/sizes'
import { setOpenWindowState } from '../../../context/open-windows/OpenWindows.actions'
import { RootState } from '../../../context/reducer'
import BreadcrumbPanel from '../../basic/breadcrumb/BreadcrumbPanel'
import NewWindow from '../../basic/new-window/NewWindow'
import ReplacementPane from '../../basic/new-window/ReplacementPane'
import ProjectTemplate from '../project/ProjectTemplate'
import SettingsProjectProperties from './parts/SettingsProjectProperties'
import SettingsRevisions from './parts/SettingsRevisions'
import SettingsUsers from './parts/SettingsUsers'

import './Settings.scss'

const TabPane = Tabs.TabPane

function Settings(): JSX.Element {
  return (
    <ProjectTemplate selectedKey={'settings'}>
      <SettingsPart />
    </ProjectTemplate>
  )
}

function SettingsPart(): JSX.Element {
  const dispatch = useDispatch()

  const getOpenWindow = (id: string) => (state: RootState) => state.openWindows.windows.get(id) || false
  const settingsWindowId = 'settingsWindow'
  const isSettingsWindowOpen = useSelector(getOpenWindow(settingsWindowId))

  return (
    <div>
      <BreadcrumbPanel
        moveTo={'settings'}
        displayName={'Settings'}
        categoryName={'Sample Project'}
        openWindow={() => dispatch(setOpenWindowState(settingsWindowId, true))}
      />
      <NewWindow
        height={newWindowHeight}
        width={newWindowWidth}
        isOpen={isSettingsWindowOpen}
        onPortalClose={() => dispatch(setOpenWindowState(settingsWindowId, false))}
        replacementPane={
          <ReplacementPane
            onHide={() => dispatch(setOpenWindowState(settingsWindowId, false))}
            onWindowShow={() => {}}
            style={{ width: '100%', height: 450, backgroundColor: '#fff' }}
          />
        }
      >
        <Tabs defaultActiveKey="manage">
          <TabPane tab={'Project Properties'} key="projectProperties">
            <SettingsProjectProperties />
          </TabPane>
          <TabPane tab={'Users'} key="users">
            <SettingsUsers />
          </TabPane>
          <TabPane tab={'Revisions'} key="revisions">
            <SettingsRevisions />
          </TabPane>
        </Tabs>
      </NewWindow>
    </div>
  )
}

export default Settings
