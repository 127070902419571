import React, { FunctionComponent, useState } from 'react'
import { Button, Popover, Radio, Divider, Space, Tooltip } from 'antd'
import { ApartmentOutlined } from '@ant-design/icons/lib'
import { Entry } from '../../sterling-table/SterlingTable'
import { ColumnType } from 'antd/lib/table/interface'
import { BoQBreakdownCode, BoQBreakdowns } from '../../../../../model/BillOfQuantities'
import { groupByBreakdownStructure } from '../../../../domain/bill-of-quantities/helpers/DataParser'
import { useDispatch, useSelector } from 'react-redux'
import { groupByExternalEntries } from '../../../../../context/resource-library/SterlingTable.actions'
import { RootState } from '../../../../../context/reducer'

interface TableBreakdownManagerContentProps {
  hide: () => void
  tableId: string
  groupingRules: any
}

const TableBreakdownManagerContent: FunctionComponent<TableBreakdownManagerContentProps> = ({
  tableId,
  hide,
  groupingRules,
}) => {
  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  }

  const dispatch = useDispatch()

  const dividerStyle = { padding: 4, margin: 4 }
  const savedKey = useSelector((state: RootState) => state.sterlingTable.groupedByExternal.tableId)

  const [selectedBreakdownCode, setSelectedBreakdownCode] = useState<BoQBreakdownCode | undefined>(
    savedKey as BoQBreakdownCode
  )

  const codeExtractor = (code?: BoQBreakdownCode) => (e: any) => (code ? e[code] : undefined)
  const breakdownStructuresList = (code?: BoQBreakdownCode) =>
    BoQBreakdowns.find((b) => b.column === code)?.breakdownList

  return (
    <div style={{ width: 280 }}>
      <Radio.Group onChange={({ target }) => setSelectedBreakdownCode(target.value)}>
        <Radio key={'none'} value={undefined} style={radioStyle} checked={true}>
          No breakdown
        </Radio>
        <Divider style={dividerStyle} />
        {BoQBreakdowns.map(({ name, column }) => (
          <Radio key={name.toLowerCase()} style={radioStyle} value={column}>
            {name}
          </Radio>
        ))}
      </Radio.Group>
      <Divider style={dividerStyle} />
      <Space direction={'horizontal'} align={'end'}>
        <Button
          onClick={() => {
            setSelectedBreakdownCode(savedKey as BoQBreakdownCode)
            hide()
          }}
        >
          Cancel
        </Button>
        <Button
          type={'primary'}
          onClick={() => {
            dispatch(
              groupByExternalEntries(
                tableId,
                breakdownStructuresList(selectedBreakdownCode) || [],
                codeExtractor(selectedBreakdownCode),
                groupByBreakdownStructure,
                groupingRules,
                selectedBreakdownCode
              )
            )
            hide()
          }}
        >
          Show
        </Button>
      </Space>
    </div>
  )
}

interface TableBreakdownManagerProps {
  data: Entry[]
  columns: ColumnType<any>[]
  tableId: string
  groupingRules: any
}

const TableBreakdownManager: FunctionComponent<TableBreakdownManagerProps> = ({ tableId, groupingRules }) => {
  const [visible, setVisible] = useState(false)

  return (
    <Popover
      visible={visible}
      title={'Project Breakdown Structure'}
      content={
        <TableBreakdownManagerContent tableId={tableId} hide={() => setVisible(false)} groupingRules={groupingRules} />
      }
    >
      <Tooltip title="Breakdown structure">
        <Button type="text" shape="circle" icon={<ApartmentOutlined />} onClick={() => setVisible(true)} />
      </Tooltip>
    </Popover>
  )
}

export default TableBreakdownManager
